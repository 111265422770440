import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { fromJS, Map } from 'immutable'
import { useResourceAction } from 'hooks/resources'
import { withStyles } from '@material-ui/core/styles'
import SLASettingForm from 'forms/actions/SLASettingForm'

import { serviceLevelAgreementSchema } from '@seekster/schemas'

const styles = (theme) => ({
  container: {
    padding: theme.spacing(4)
  },
  panelShadow: {
    boxShadow: '0 2px 10px 0 rgba(182,182,182,0.4)'
  },
  paper: {
    padding: '34px 55px 96px'
  }
})

const SLASettingPane = withStyles(styles)(({ classes }) => {
  const [slaSetting, setSlaSetting] = useState(Map())
  const {
    create: createSlaSetting,
    update: updateSlaSetting,
    fetch: fetchSlaSetting
  } = useResourceAction(serviceLevelAgreementSchema)

  useEffect(() => {
    fetchSlaSetting('').then((res) => setSlaSetting(fromJS(res.body)))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function handleUpdateSlaSetting(data) {
    return updateSlaSetting(slaSetting.last().get('id'), data)
  }

  function handleCreateSlaSetting(data) {
    return createSlaSetting(data)
  }

  return (
    <Grid container justify='center' className={classes.container}>
      <Grid item xs={12} md={12} lg={9} xl={6}>
        <SLASettingForm
          slaSetting={slaSetting.last()}
          onSubmit={
            slaSetting.isEmpty() ? handleCreateSlaSetting : handleUpdateSlaSetting
          }
        />
      </Grid>
    </Grid>
  )
})

export default SLASettingPane
