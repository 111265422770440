import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { EditRounded, Delete } from '@material-ui/icons'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import {
  PromoCodeCommentsPane,
  PromoCodeRequestsPane,
  PromoCodeOverviewPane
} from './panes'

export function PromoCodeShowPage ({
  promoCode,
  location,
  match,
  fetchComments,
  fetchPromoCode,
  setFlashMessage,
  push,
  destroy,
  t
}) {
  const { loading } = useData(() => fetchPromoCode(match.params.id))
  useData(() => fetchComments(match.params.id), [])

  function handleDestroy (e) {
    window.confirm(t('remove_confirmation_prompt')) &&
    destroy(match.params.id)
      .then(response => {
        push('/promo_codes')
      })
      .catch(error => {
        setFlashMessage('error', error.response.body.message)
      })
  }

  function actions () {
    return [{
      avatar: <EditRounded />,
      content: t('edit'),
      to: `/promo_codes/${match.params.id}/edit`
    },
    {
      avatar: <Delete />,
      content: t('delete'),
      onClick: handleDestroy
    }]
  }

  function subNavbar () {
    const path = `/promo_codes/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('common:navbar.overview')} to={path} />
        <NavigationMenuItem label={t('requests')} to={`${path}/requests`} />
        <NavigationMenuItem
          label={t('comments')}
          to={`${path}/comments`}
          badge={promoCode.get('comments', 0).size}
        />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs () {
    return [{
      text: t('title'),
      link: '/promo_codes'
    }, {
      text: promoCode.get('code')
    }]
  }

  return (
    <PageLayout
      title={promoCode.get('code')}
      actions={actions()}
      subNavbar={subNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/promo_codes/:id' component={PromoCodeOverviewPane} exact />
        <AuthenticatedRoute path='/promo_codes/:id/requests' component={PromoCodeRequestsPane} />
        <AuthenticatedRoute path='/promo_codes/:id/comments' component={PromoCodeCommentsPane} />
      </Switch>
    </PageLayout>
  )
}

PromoCodeShowPage.propTypes = {
  promoCode: ImmutablePropTypes.map,
  location: PropTypes.object,
  match: PropTypes.object,
  fetchComments: PropTypes.func,
  fetchPromoCode: PropTypes.func,
  setFlashMessage: PropTypes.func,
  push: PropTypes.func,
  destroy: PropTypes.func,
  t: PropTypes.func
}

PromoCodeShowPage.defaultProps = {
  promoCode: Map()
}

export default withTranslation(['promo_codes', 'actions'])(PromoCodeShowPage)
