import React, { useState } from 'react'
import { TableCell as MaterialTableCell, Popover, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: ({ error }) => error ? palette.error.light : null,
    color: ({ error }) => error ? palette.error.contrastText : null,
    cursor: ({ error }) => error ? 'help' : null
  },

  paper: {
    padding: spacing(2)
  }
}))

const TableCell = ({ children, error, ...props }) => {
  const classes = useStyles({ error })
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMouseEnter = (event) => {
    console.log(event.currentTarget)
    setAnchorEl(event.currentTarget)
  }

  const handleMouseLeave = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <MaterialTableCell
        classes={{ root: classes.root }}
        onMouseEnter={error ? handleMouseEnter : undefined}
        onMouseLeave={error ? handleMouseLeave : undefined}
        {...props}
      >
        {children}

        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          classes={{ paper: classes.paper }}
          onClose={handleMouseLeave}
        >
          <Typography color='error'>{error && error.join(', ')}</Typography>
        </Popover>
      </MaterialTableCell>
    </>
  )
}

export default TableCell
