import React from 'react'
import { Map, List } from 'immutable'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'

import CircularLoader from 'components/CircularLoader'

import { withQueryParser } from 'utils/withQueryParser'
import { Table, Column } from 'components/tables/Table'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import { StateTag, DateTag } from 'components/tags'
import { useCollectionChildren } from 'hooks/collections'
import { getResource } from 'selectors/resources'
import { inquirySchema, leadSchema } from '@seekster/schemas'

export const InquiryLeadsPane = ({ match, query }) => {
  const inquiry = useSelector(state => getResource(state, match.params.id, inquirySchema)) || Map()
  const [leads, { loading, totalCount, totalPages }] = useCollectionChildren(
    inquirySchema,
    match.params.id,
    leadSchema,
    { page: query.page },
    [inquiry.get('manual_distribution'), match.params.id, query.page]
  )

  return (
    <div style={{ padding: '14px 0' }}>
      <ResultsCountLabel value={totalCount} unit='lead' />

      {
        loading && <CircularLoader />
      }

      {
        !loading && totalCount !== 0 && (
          <>
            <Table collection={leads || List()} padding='dense'>
              <Column name='id' style={{ fontSize: 12 }} />
              <Column name='state' component={StateTag} />
              <Column name='created_at' component={DateTag} />
            </Table>

            <Grid item xs={12} container justify='center'>
              <Pagination totalPages={totalPages} />
            </Grid>
          </>
        )
      }
    </div>
  )
}

export default withQueryParser(InquiryLeadsPane)
