import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Button } from 'components/buttons'
import { withTranslation } from 'react-i18next'

import DateRangeField from 'components/fields/DateRangeField'
import MoneyRangeField from 'components/fields/MoneyRangeField'

export function PayoutsFilterForm ({ handleSubmit, onCloseModal, t }) {
  return (
    <Form>
      <DateRangeField name='created_at' />
      <MoneyRangeField name='amount_satangs' defaultCurrency='THB' />
      <div>
        <Button color='primary' onClick={handleSubmit}>{t('apply')}</Button>

        <Button onClick={onCloseModal}>{t('cancel')}</Button>
      </div>
    </Form>
  )
}

PayoutsFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  onCloseModal: PropTypes.func,
  t: PropTypes.func
}

export default withTranslation('filters')(PayoutsFilterForm)
