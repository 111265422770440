import { useMemo, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'
import { omitBy, isPlainObject, every, isNull } from 'lodash'

const filterQuery = (query) => {
  return omitBy(query, (queryValue) => (
    isNull(queryValue) || (isPlainObject(queryValue) && every(queryValue, isNull))
  ))
}

const useQuery = () => {
  const { pathname, search } = useLocation()
  const history = useHistory()

  const query = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search])

  const replaceQuery = useCallback((query) => {
    history.replace({
      pathname,
      search: qs.stringify({ ...filterQuery(query), page: undefined }, { addQueryPrefix: true })
    })
  }, [history, pathname])

  const pushQuery = useCallback((query) => {
    history.push({
      pathname,
      search: qs.stringify({ ...filterQuery(query), page: undefined }, { addQueryPrefix: true })
    })
  }, [history, pathname])

  return [query, { replaceQuery, pushQuery }]
}

export default useQuery
