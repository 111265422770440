import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardContent
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  Phone,
  EmailOutlined,
  AccountCircle,
  LocalOffer,
  Edit
} from '@material-ui/icons'
import { Link } from 'react-router-dom'

import PhoneNumberFormatter from 'components/formatters/PhoneNumberFormatter'
import MailFormatter from 'components/formatters/MailFormatter'
import Tag from 'components/Tag'
import UserTagForm from 'forms/actions/UserTagForm'

import { useResource, useResourceAction } from 'hooks/resources'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f4f4f4'
  },
  title: {
    marginBottom: 4
  },
  tag: {
    marginBottom: 4,
    paddingBottom: 6
  },
  icon: {
    margin: '0 6px -3px 0'
  },
  textContainer: {
    paddingBottom: 6
  },
  cardContent: {
    paddingBottom: 0
  },
  EditTagButton: {
    position: 'absolute',
    right: 30,
    transition: 'transform 1s',

    '&:hover': {
      transform: 'rotate(-90deg)',
      cursor: 'pointer',
      fill: 'green'
    }
  }
}))

export function UserCard ({
  userId,
  schema
}) {
  const classes = useStyles()
  const [isEditTag, setIsEditTag] = useState(false)
  const { update: updateUser } = useResourceAction(schema)
  const [user] = useResource(schema, userId, [userId])

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        <h3 className={classes.title}>Workforce details</h3>

        <div className={classes.tag}>
          <Tag value={user.get('type')} />
        </div>

        <div className={classes.textContainer}>
          <AccountCircle className={classes.icon} />
          <Link to={`/${user.get('type')}/${user.get('id')}`}>{user.get('display_name')}</Link>
        </div>

        <div className={classes.textContainer}>
          <Phone className={classes.icon} />
          <PhoneNumberFormatter value={user.get('phone_number')} />
        </div>

        <div className={classes.textContainer}>
          <EmailOutlined className={classes.icon} />
          <MailFormatter value={user.get('email') || '   -'} />
        </div>

        <div className={classes.textContainer}>
          {
            isEditTag ? <UserTagForm
              onCancel={() => setIsEditTag(false)}
              userId={userId}
              schema={schema}
              onSubmit={updateUser}
            /> : <>
              <LocalOffer className={classes.icon} />
              {
                user.get('tags') ? user.get('tags').map(tag =>
                  <Tag value={tag.get('name')} />
                )
                  : '-'
              }
              <Edit onClick={() => setIsEditTag(true)} className={classes.EditTagButton} />
            </>
          }
        </div>
      </CardContent>
    </Card>
  )
}

UserCard.propTypes = {
  userId: PropTypes.number,
  schema: PropTypes.object
}

export default UserCard
