import { withFormik } from 'formik'
import { compose } from 'redux'
import { Map } from 'immutable'

import withFlashMessage from 'hoc/withFlashMessage'
import TicketChatFrom from './TicketChatFrom'

const formikConfig = {
  mapPropsToValues: ({ agent = Map() }) => ({
    type: 'text',
    from: 'workforce',
    agent_name: agent.get('display_name')
  }),

  enableReinitialize: true,

  handleSubmit: (values, { props, resetForm }) => {
    let formData = {
      type: 'text',
      from: 'workforce',
      agent_name: values.agent_name
    }

    props.onSubmit(`tenants/${props.tenant}/tickets/${props.ticketId}/messages`, { created_at: Date.now(), ...values })
    resetForm(formData)
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(TicketChatFrom)
