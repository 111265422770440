import { Map } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import TenantMarketingConfigForm from './TenantMarketingConfigForm'

const formikConfig = {
  mapPropsToValues: ({ marketingConfig = Map() }) => ({
    customer_referral_reward_amount_satangs: marketingConfig.getIn(['customer_referral_reward_amount', 'fractional']) / 100 || '',
    customer_referral_reward_amount_currency: marketingConfig.getIn(['customer_referral_reward_amount', 'currency']),
    customer_referral_discount_amount_satangs: marketingConfig.getIn(['customer_referral_discount_amount', 'fractional']) / 100 || '',
    customer_referral_discount_amount_currency: marketingConfig.getIn(['customer_referral_discount_amount', 'currency']),
    provider_referral_reward_amount_satangs: marketingConfig.getIn(['provider_referral_reward_amount', 'fractional']) / 100 || '',
    provider_referral_reward_amount_currency: marketingConfig.getIn(['provider_referral_reward_amount', 'currency'])
  }),

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    customer_referral_reward_amount_satangs: Yup.number().required(),
    customer_referral_reward_amount_currency: Yup.string().required(),
    customer_referral_discount_amount_satangs: Yup.number().required(),
    customer_referral_discount_amount_currency: Yup.string().required(),
    provider_referral_reward_amount_satangs: Yup.number().required(),
    provider_referral_reward_amount_currency: Yup.string().required()
  }),

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    values = {
      ...values,
      customer_referral_reward_amount_satangs: parseInt((values.customer_referral_reward_amount_satangs * 100).toFixed(0)),
      customer_referral_discount_amount_satangs: parseInt((values.customer_referral_discount_amount_satangs * 100).toFixed(0)),
      provider_referral_reward_amount_satangs: parseInt((values.provider_referral_reward_amount_satangs * 100).toFixed(0)),
    }

    props.onSubmit(values)
      .catch(error => {
        setSubmitting(false)

        setErrors({ message: error.response.body.message })
      })
  }
}

export default withFormik(formikConfig)(TenantMarketingConfigForm)
