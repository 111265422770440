import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { TextField, FormControl } from '@material-ui/core'

import './NumericRangeField.scss'

export function NumericRangeField ({ name, label, ...rest }) {
  const { t } = useTranslation('fields')

  return (
    <Field
      name={name}
      render={({ field, form }) => (
        <div className='form-range-field'>
          <label>{label || t(`label.${name}`)}</label>

          <from noValidate autoComplete='off'>
            <FormControl className='number-range-input-field'>
              <TextField
                variant='outlined'
                placeholder={t(`placeholder.${name}`)}
                color='primary'
                type='number'
                {...field}
                value={get(field, ['value', 'min'], '')}
                onChange={
                  (e) => (
                    form.setFieldValue(
                      name,
                      { min: e.target.value, max: get(field, ['value', 'max'], undefined) }
                    )
                  )
                }
                {...rest}
              />
            </FormControl>

            <FormControl className='number-range-input-field'>
              <TextField
                variant='outlined'
                placeholder={t(`placeholder.${name}`)}
                color='primary'
                type='number'
                {...field}
                value={get(field, ['value', 'max'], '')}
                onChange={
                  (e) => (
                    form.setFieldValue(
                      name,
                      { min: get(field, ['value', 'min'], undefined), max: e.target.value }
                    )
                  )
                }
                {...rest}
              />
            </FormControl>
          </from>
        </div>
      )}
    />
  )
}

NumericRangeField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
}

export default NumericRangeField
