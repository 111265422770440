import { connect } from 'react-redux'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'
import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { jobSchema, reviewSchema } from '@seekster/schemas'

import JobReviewsPane from './JobReviewsPane'

const mapStateToProps = (state, { match }) => ({
  job: getResource(state, match.params.id, jobSchema)
})

const mapDispatchToProps = {
  fetchJobReviews: (id, options) => fetchChildren(jobSchema, id, reviewSchema, options)
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(JobReviewsPane)
