import { connect } from 'react-redux'

import { create } from 'actions/resources'
import { push } from 'react-router-redux'
import { providerStrengthSchema } from '@seekster/schemas'

import ProviderStrengthNewPage from './ProviderStrengthNewPage'

const mapDispatchToProps = {
  createProviderStrength: data => create(data, providerStrengthSchema),
  push
}

export default connect(null, mapDispatchToProps)(ProviderStrengthNewPage)
