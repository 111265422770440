import { connect } from 'react-redux'
import { getResource } from 'selectors/resources'

import AppOverviewPane from './AppOverviewPane'

import { appSchema } from '@seekster/schemas'

const mapStateToProps = (state, { match }) => ({
  app: getResource(state, match.params.id, appSchema)
})

export default connect(mapStateToProps)(AppOverviewPane)
