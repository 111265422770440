import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SketchPicker } from 'react-color'
import { ButtonBase, Popover } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './ColorPickerStyles.js'

export function ColorPicker ({ value, onChange, classes, ...rest }) {
  const [anchorEl, setAnchorEl] = useState(null)

  function handleOpenColorPicker (e) {
    setAnchorEl(e.currentTarget)
  }

  function handleCloseColorPicker () {
    setAnchorEl(null)
  }

  function handleChange ({ hex }) {
    onChange && onChange(hex)
  }

  return (
    <>
      <ButtonBase
        onClick={handleOpenColorPicker}
        className={classes.colorPicker}
      >
        <div
          style={{
            backgroundColor: value,
            border: value === '#ffffff' && '1px solid #dadada'
          }}
          className={classes.colorPreview}
        />
        <div>{value}</div>
      </ButtonBase>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseColorPicker}
      >
        <SketchPicker
          color={value}
          onChange={handleChange}
          {...rest}
        />
      </Popover>
    </>
  )
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  classes: PropTypes.object
}

ColorPicker.defaultProps = {
  value: '#000'
}

export default withStyles(styles)(ColorPicker)
