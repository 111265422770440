import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { TextareaAutosize } from '@material-ui/core'

import { Map, List } from 'immutable'
import { Formik, Form } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import * as Yup from 'yup'
import {
  tagSchema,
  feedbackSchema,
  feedbackTypeSchema,
  agentSchema
} from '@seekster/schemas'

import { getCurrentIdAttribute } from 'selectors/authentication'
import { setFlashMessage } from 'actions/interface'

import { Button } from 'components/buttons'
import { FastField } from 'components/fields/Field'
import ResourceCreatableSelect from 'components/inputs/ResourceCreatableSelect'
import ResourceSelect from 'components/inputs/ResourceSelect'

const useStyles = makeStyles(({ spacing, shape }) => ({
  textArea: {
    display: 'block',
    fontSize: 16,
    width: '100%',
    borderColor: '#cccccc',
    borderRadius: shape.borderRadius,
    padding: spacing(1),
    resize: 'none'
  },

  button: {
    float: 'right',
    marginBottom: spacing(2)
  },

  formContainer: {
    padding: 16
  }
}))

export function FeedbackForm({ feedback, onSubmit }) {
  const classes = useStyles()
  const { t } = useTranslation('feedback')
  const dispatch = useDispatch()
  const currentAgentId = useSelector((state) => getCurrentIdAttribute(state, agentSchema))

  const isCreatedByCurrentAgent =
    feedback.get('created_by_type') === 'Agent' &&
    feedback.getIn(['created_by', 'id']) === currentAgentId

  const feedbackType = feedback.get('feedback_type') || Map()

  const initialValues = {
    comment: feedback.get('comment', ''),
    tag_ids: feedback
      .get('tags', List())
      .map((tag) => tag.get('id'))
      .toJS(),
    feedback_type_id: feedbackType.get('id')
  }

  const validationSchema = Yup.object().shape({
    comment: Yup.string().required()
  })

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    onSubmit(values)
      .then(({ body }) => {
        dispatch(push(`/feedbacks/${body.id}`))
      })
      .catch((error) => {
        setSubmitting(false)

        dispatch(setFlashMessage('error', error.response.body.message))
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className={classes.formContainer}>
          <FastField
            name='comment'
            component={TextareaAutosize}
            rowsMin={3}
            className={classes.textArea}
            readOnly={!feedback.isEmpty() && !isCreatedByCurrentAgent}
          />

          <FastField
            name='tag_ids'
            component={ResourceCreatableSelect}
            schema={tagSchema}
            parentSchema={feedbackSchema}
            parentId={feedback.get('id')}
          />

          <FastField
            name='feedback_type_id'
            component={ResourceSelect}
            schema={feedbackTypeSchema}
          />

          <Button
            variant='contained'
            color='primary'
            type='submit'
            className={classes.button}
            loading={isSubmitting}
          >
            {t('save')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

FeedbackForm.propTypes = {
  feedback: ImmutablePropTypes.map,
  onSubmit: PropTypes.func
}

FeedbackForm.defaultProps = {
  feedback: Map()
}

export default FeedbackForm
