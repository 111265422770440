import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Card, CardHeader, CardContent, CardActions,
  Typography, withMobileDialog
} from '@material-ui/core'
import { compose } from 'redux'

import useStyles from './PanelStyles'

export function Panel ({
  avatar,
  title,
  subtitle,
  actions,
  children,
  footer,
  subtitleTypographyProps,
  cardContentProps,
  cardFooterProps,
  responsive,
  fullScreen: isMobile,
  noPadding,
  subheader,
  headerStyle,
  ...rest
}) {
  const classes = useStyles()
  const childrenRef = useRef({})
  const footerRef = useRef({})

  function getContentHeight () {
    let totalOffset = childrenRef.current.offsetTop

    if (noPadding) {
      totalOffset += 16
    }

    if (footer) {
      totalOffset += footerRef.current.offsetHeight - 16
    }

    return `calc(100vh - ${totalOffset}px)`
  }

  function getResponsiveStyles () {
    if (isMobile) {
      return {
        minHeight: getContentHeight()
      }
    }
    else {
      return {
        height: getContentHeight(),
        overflowY: 'auto'
      }
    }
  }

  return (
    <Card square className={classes.root} {...rest}>
      {
        title && (
          <CardHeader
            avatar={avatar}
            action={actions}
            title={title}
            style={{ fontWeight: 500, ...headerStyle }}
            className={classes.cardHeader}
            classes={{ action: classes.cardAction }}
            titleTypographyProps={{ variant: 'h5' }}
          />
        )
      }

      {
        subheader &&
          <div className={classes.subheaderContainer}>{subheader}</div>
      }

      {
        subtitle && (
          <Typography
            variant='caption'
            color='textSecondary'
            className={classes.subheader}
            {...subtitleTypographyProps}
          >
            {subtitle}
          </Typography>
        )
      }

      <CardContent
        {...cardContentProps}
        style={responsive && getResponsiveStyles()}
        classes={{ root: classes.content }}
      >
        <div ref={childrenRef}>{children}</div>
      </CardContent>

      {
        footer && (
          <CardActions {...cardFooterProps} className={classes.cardFooter}>
            <div ref={footerRef} className={classes.footer}>{footer}</div>
          </CardActions>
        )
      }
    </Card>
  )
}

Panel.propTypes = {
  avatar: PropTypes.node,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  actions: PropTypes.node,
  subheader: PropTypes.node,
  subtitleTypographyProps: PropTypes.object,
  cardContentProps: PropTypes.object,
  cardFooterProps: PropTypes.object,
  headerStyle: PropTypes.object,
  children: PropTypes.node,
  footer: PropTypes.node,
  responsive: PropTypes.bool,
  fullScreen: PropTypes.bool,
  noPadding: PropTypes.bool
}

export default compose(
  withMobileDialog()
)(Panel)
