import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import PageContent, { PageSection } from 'components/layouts/PageContent'

import CustomerCard from 'components/cards/CustomerCard'

import Details from '../components/Details'
// import Month from '../components/Month'
import QuestionsAndAnswers from '../components/QuestionsAndAnswers'

export function SubscriptionOverviewPane ({ subscription }) {
  return (
    <PageContent>
      <PageSection position='center' borderRight>
        <Details subscription={subscription} />

        <QuestionsAndAnswers subscription={subscription} />
      </PageSection>

      <PageSection position='right'>
        {/* <Month subscriptionMonth={subscription.get('current_subscription_month') || Map()} /> */}

        <CustomerCard title='Customer' customer={subscription.get('customer')} />
      </PageSection>
    </PageContent>
  )
}

SubscriptionOverviewPane.propTypes = {
  subscription: ImmutablePropTypes.map
}

SubscriptionOverviewPane.defaultProps = {
  subscription: Map()
}

export default SubscriptionOverviewPane
