import React, { useState } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Timeline, TimelineEvent } from 'react-event-timeline'
import { Link } from 'react-router-dom'
import { xor } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Avatar, Chip } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp, Computer } from '@material-ui/icons'

import { DateTag } from 'components/tags'

import './EventTimeline.scss'

const logo =
  'https://seekster-web.s3.amazonaws.com/staging/uploads/category/icon/27/default_icon.png'

function EventTimeline({ events }) {
  const { t } = useTranslation(['events', 'attributes'])
  const [expandedEventIds, setExpandedEventIds] = useState([])

  function handleToggleDetail(e, event) {
    setExpandedEventIds(xor(expandedEventIds, [event.get('id')]))
  }

  return (
    <Timeline className='timeline' lineStyle={{ display: 'none' }}>
      {events.map((event) => {
        const actor = event.get('actor') || Map()
        const destination = event.get('destination') || Map()

        const attributesDifferences = event.get('attributes_differences')
          ? event.get('attributes_differences').delete('updated_at')
          : null

        return (
          <TimelineEvent
            title={
              <div className='event-header' onClick={(e) => handleToggleDetail(e, event)}>
                <div className='actor-avatar'>
                  <Link to={`/${actor.get('type')}/${actor.get('id')}`}>
                    <Avatar src={actor.get('avatar_url') || logo} />
                  </Link>
                </div>

                <div className='event-description'>
                  <DateTag value={event.get('created_at')} />

                  <div className='event-title'>
                    <Link to={`/${actor.get('type')}/${actor.get('id')}`}>
                      {actor.get('display_name') || t('actor.system')}
                    </Link>
                    &nbsp;
                    {t(event.get('name'))}
                    &nbsp;
                    {destination.get('id') && (
                      <Link to={`/${destination.get('type')}/${destination.get('id')}`}>
                        {destination.get('display_name') || t('actor.system')}
                      </Link>
                    )}
                  </div>
                </div>

                <div className='expand-icon'>
                  {expandedEventIds.includes(event.get('id')) ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </div>
              </div>
            }
            key={event.get('id')}
            container='card'
            cardHeaderStyle={{ backgroundColor: '#eaeef2', color: '#000' }}
            iconColor='#a3b2b7'
            bubbleStyle={{ display: 'none' }}
          >
            {expandedEventIds.includes(event.get('id')) && (
              <div>
                {attributesDifferences && (
                  <div>
                    <div>{t('attributes_differences')}</div>

                    <div className='attributes'>
                      {attributesDifferences
                        .map((attributeDifferences, attributeName) => {
                          return (
                            <div key={attributeName}>
                              <Chip
                                variant='outlined'
                                color='primary'
                                size='small'
                                label={t(`attributes:${attributeName}`)}
                              />

                              <div className='attribute-differences'>
                                <span>{attributeDifferences.get(0)}</span>
                                &nbsp;&rArr;&nbsp;
                                <span>{attributeDifferences.get(1)}</span>
                              </div>
                            </div>
                          )
                        })
                        .toList()}
                    </div>
                  </div>
                )}

                <div className='client-details'>
                  <Computer size='large' color='grey' style={{ padding: 3 }} />
                  <div>
                    <div>{event.getIn(['client', 'name']) || t('actor.system')}</div>
                    <sup>{event.get('ip_address')}</sup>
                  </div>
                </div>
              </div>
            )}
          </TimelineEvent>
        )
      })}
    </Timeline>
  )
}

EventTimeline.propTypes = {
  events: ImmutablePropTypes.list
}

export default EventTimeline
