import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Card, CardContent, CardHeader, Typography, Grid, Avatar } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { LightboxImage } from 'components/images'

import AttributeLabel from 'components/labels/AttributeLabel'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import Tag from 'components/Tag'
import BooleanChip from 'components/BooleanChip'

import BankAccountActionsMenu from '../BankAccountActionsMenu'

import styles from './BankAccountListStyles'

export function BankAccountList ({
  bankAccounts,
  classes,
  parentSchema,
  parentId
}) {
  const { t } = useTranslation(['bank_accounts', 'attributes'])

  return (
    bankAccounts.map(bankAccount => (
      <Card key={bankAccount.get('id')} className={classes.card}>
        <CardHeader
          avatar={
            <Avatar
              src={bankAccount.getIn(['bank', 'logo_url'])}
              alt={bankAccount.getIn(['bank', 'name'])}
              className={classes.avatar}
            />
          }
          title={
            <>
              <Typography variant='caption' gutterBottom>
                {`${t('id')} ${bankAccount.get('id')}`}
              </Typography>

              <Typography variant='subtitle1'>
                {bankAccount.get('account_name')}
              </Typography>

              <Typography variant='h5'>
                {bankAccount.get('formatted_account_number')}
              </Typography>
            </>
          }
          subheader={bankAccount.getIn(['bank', 'name'])}
          action={
            <BankAccountActionsMenu
              bankAccountId={bankAccount.get('id')}
              parentSchema={parentSchema}
              parentId={parentId}
            />
          }
        />

        <CardContent className={classes.cardContent}>
          <AttributeLabel title={t('description')}>
            {bankAccount.get('description', '-')}
          </AttributeLabel>

          <Grid container className={classes.bottomMargin}>
            <Grid item xs={6}>
              <Tag
                value={bankAccount.get('state')}
                className='bank-account-states'
                i18nKey='bank_account_states'
              />
            </Grid>

            <Grid item xs={6}>
              <BooleanChip name='is_enabled' value={!bankAccount.get('disabled')} />
            </Grid>
          </Grid>

          <AttributeLabel title={t('book_bank_img')}>
            <LightboxImage
              source={{
                src: bankAccount.get('book_bank_url'),
                thumbnail: bankAccount.get('book_bank_url')
              }}
            />
          </AttributeLabel>

          <AttributeLabel title={t('created_at')}>
            <DateTimeFormatter value={bankAccount.get('created_at')} />
          </AttributeLabel>
        </CardContent>
      </Card>
    ))
  )
}

export default withStyles(styles)(BankAccountList)
