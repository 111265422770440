import request from 'utils/request'
import selectQueryToServer from 'utils/selectQueryToServer'

import { SEND_INDIVIDUAL_SMS_SUCCEEDED, SEND_MULTIPLE_SMS_SUCCEEDED } from 'constants/smsRatings'

const sendIndividualSMSSucceeded = (id, response, schema, childrenSchema, options) => ({
  type: SEND_INDIVIDUAL_SMS_SUCCEEDED,
  id,
  response,
  schema,
  childrenSchema,
  options
})

export const sendIndividualSMS = (schema, id, childrenSchema, options = {}) => (dispatch, getState) => {
  const type = options.type || schema._key
  const childrenType = options.childrenType || childrenSchema._key
  const locale = options.locale
  const accessToken = getState().getIn(['authentication', 'token'])

  return request
    .post(`/${type}/${id}/${childrenType}`)
    .locale(locale)
    .authentication(accessToken)
    .then(response => {
      dispatch(sendIndividualSMSSucceeded(id, response, schema, childrenSchema, options))

      return response
    })
}

const sendMultipleSMSSucceeded = (response, schema, childrenSchema, options) => ({
  type: SEND_MULTIPLE_SMS_SUCCEEDED,
  response,
  schema,
  childrenSchema,
  options
})

export const sendMultipleSMS = (schema, childrenSchema, options = {}) => (dispatch, getState) => {
  const type = options.type || schema._key
  const childrenType = options.childrenType || childrenSchema._key
  const locale = options.locale
  const accessToken = getState().getIn(['authentication', 'token'])
  const query = {
    query: selectQueryToServer(schema, options),
    ...options.queryParam,
    ...(options.search && { search: options.search }),
    ...(options.filter && {
      filter: selectQueryToServer(schema, { query: options.filter })
    })
  }

  return request
    .post(`/${type}/${childrenType}`)
    .locale(locale)
    .authentication(accessToken)
    .query(query)
    .then(response => {
      dispatch(sendMultipleSMSSucceeded(response, schema, childrenSchema, options))

      return response
    })
}
