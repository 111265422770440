import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import Field from 'components/fields/Field'
import DateTimeInputField from 'components/fields/DateTimeInputField'
import DropzoneInput from 'components/inputs/DropzoneInput'

import styles from './CardPaymentFormStyles'

export class CardPaymentForm extends React.Component {
  static propTypes = {
    invoice: ImmutablePropTypes.map,
    onCancel: PropTypes.func,
    classes: PropTypes.object,

    t: PropTypes.func
  }

  static defaultProps = {
    invoice: Map()
  }

  render() {
    const { invoice, classes, onCancel, t } = this.props

    return (
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <Field name='image' component={DropzoneInput} width={500} height={700} />
          </Grid>

          <Grid item xs={12} sm={5}>
            <Field
              name='amount_satangs'
              hint={`${t('outstanding_balance')}: ${invoice.getIn([
                'outstanding_balance',
                'display_value'
              ])}`}
            />

            <DateTimeInputField name='captured_at' />
          </Grid>

          <Grid item xs={12}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.button}
            >
              {this.props.t('save')}
            </Button>
            <Button variant='contained' onClick={onCancel} className={classes.button}>
              {t('cancel')}
            </Button>
          </Grid>
        </Grid>
      </Form>
    )
  }
}

export default compose(
  withStyles(styles),
  withTranslation(['payments', 'actions'])
)(CardPaymentForm)
