import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { List, Map } from 'immutable'
import { withTranslation } from 'react-i18next'
import { Grid, Button } from '@material-ui/core'

import Field from 'components/fields/Field'
import ResourceSelect from 'components/inputs/ResourceSelect'
import SelectInput from 'components/inputs/SelectInput'
import { providerSchema } from '@seekster/schemas'

export class FavoriteProviderForm extends React.Component {
  static propTypes = {
    onCancelForm: PropTypes.func,
    getProvider: PropTypes.func,
    fetchProvider: PropTypes.func,
    setFieldValue: PropTypes.func,
    values: PropTypes.object,

    t: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.handleFormCancel = this.handleFormCancel.bind(this)
  }

  componentDidUpdate (prevProps) {
    const { values, fetchProvider, setFieldValue } = this.props

    if (prevProps.values.provider_id !== values.provider_id && values.provider_id) {
      fetchProvider(values.provider_id)
      setFieldValue('service_id', undefined)
    }
  }

  handleFormCancel () {
    this.props.onCancelForm && this.props.onCancelForm()
  }

  render () {
    const provider = this.props.getProvider(this.props.values.provider_id) || Map()
    const options = provider.get('services', List()).map(service => ({
      value: service.get('id'), label: service.get('name')
    }))

    return (
      <Form>
        <Field
          name='provider_id'
          component={ResourceSelect}
          schema={providerSchema}
        />
        {
          this.props.values.provider_id && (
            <Field
              key={this.props.values.provider_id}
              name='service_id'
              component={SelectInput}
              options={options}
            />
          )
        }

        <Grid item xs={12} container justify='flex-end'>
          <Button
            variant='contained'
            type='button'
            onClick={this.handleFormCancel}
          >
            {this.props.t('cancel')}
          </Button>

          <Button
            variant='contained'
            color='primary'
            type='submit'
            style={{ marginLeft: 8 }}
          >
            {this.props.t('add')}
          </Button>
        </Grid>
      </Form>
    )
  }
}

export default withTranslation('actions')(FavoriteProviderForm)
