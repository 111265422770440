import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { IonTextarea } from '@ionic/react'
import { Grid, CardHeader, Avatar } from '@material-ui/core'
import { usePush } from 'hooks/utils'
import { fromJS, List, Map } from 'immutable'
import { getResource } from 'selectors/resources'
import { jobReportSchema, jobSchema, commentSchema } from '@seekster/schemas'
import { StateTag } from 'components/tags'
import { useCollectionChildren } from 'hooks/collections'
import { useResourceAction } from 'hooks/resources'
import QuestionsAndAnswers from 'components/lists/QuestionsAndAnswers'
import ButtonLoader from 'components/buttons/ButtonLoader'
import moment from 'moment'

import CommentCard from 'components/CommentCard'

import './ReportOverviewPane.scss'

const useStyles = makeStyles(() => ({
  organizationPage: {
    padding: 30
  },
  tableContainer: {
    border: '1px solid #e0e0e0'
  },
  table: {
    borderRight: '1px solid #e0e0e0'
  },
  button: {
    paddingTop: 16
  },
  attributeName: {
    width: '55%'
  },
  attributeValue: {
    width: '45%'
  },
  tableValue: {
    borderLeft: '1px solid #e0e0e0'
  },
  commentMore: {
    color: '#2D95F4',
    textDecoration: 'underline'
  },
  startDate: {
    color: '#5B8AC3',
    borderRadius: '50%',
    padding: '10px',
    border: '#5b8ac3 2px solid'
  },
  endDate: {
    color: '#FFFFFF',
    borderRadius: '50%',
    padding: '10px',
    border: '#5b8ac3 2px solid',
    backgroundColor: '#5b8ac3'
  },
  btnDanger: {
    backgroundColor: '#D0021B',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#9d0315'
    }
  }
}))

const ReportOverviewPane = ({
  match,
  fetchJobProviders,
  approveReport,
  rejectReport
}) => {
  const classes = useStyles()
  const push = usePush()
  const [jobProvider, setJobProvider] = useState(List())
  const [text, setText] = useState('')
  const { create: createComment } = useResourceAction(jobSchema)
  const { t, i18n } = useTranslation('reports')

  const report =
    useSelector((state) => getResource(state, match.params.id, jobReportSchema)) || Map()

  function handleCreateComment() {
    createComment({ content: text }, jobSchema, {
      type: `jobs/${report.getIn(['job', 'wfJobId'])}/comments`
    }).then(() => window.location.reload())
  }

  useEffect(() => {
    updateData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, fetchJobProviders])

  function updateData() {
    fetchJobProviders(report.getIn(['job', 'wfJobId'])).then((result) =>
      setJobProvider(fromJS(result.body))
    )
  }

  const [comments] = useCollectionChildren(
    jobSchema,
    report.getIn(['job', 'wfJobId']),
    commentSchema,
    {},
    []
  )

  const handleRejectReport = (id) => {
    return rejectReport(match.params.id, { reportId: match.params.id })
      .then(() => {
        window.location.reload(false)
      })
      .catch((error) => {
        alert(error.response.body.message)
      })
  }

  const handleApproveReport = (id) => {
    return approveReport(match.params.id, { reportId: match.params.id })
      .then(() => {
        updateData()
        window.location.reload(false)
      })
      .catch((error) => {
        alert(error.response.body.message)
      })
  }

  return (
    <div className={classes.organizationPage}>
      <CardHeader
        avatar={<Avatar src={report.getIn(['org', 'pictureUrl'])} alt='picture_org' />}
        title={
          <div>
            {report.getIn(['job', 'provider', 'fullName'])}{' '}
            <StateTag value={report.get('status')} />
          </div>
        }
        subheader={
          <>
            <div>
              {t('tel')}. {report.getIn(['job', 'provider', 'phoneNumber '])}
            </div>

            <div>{report.getIn(['org', 'name'])}</div>
          </>
        }
        style={{ padding: 0 }}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <h2>{t('orders')}</h2>
          {report.getIn(['job', 'orders']).map((order, key) => (
            <p key={key}>
              {`${order.get(`name${i18n.language.toUpperCase()}`)} x ${order.get('qty')}`}
            </p>
          ))}
          <h2>{t('contact_details')}</h2>
          <p>{report.getIn(['job', 'customer', 'fullName'])}</p>
          <p>
            {t('tel')}: {report.getIn(['job', 'customer', 'phoneNumber'])}
          </p>
          <p>
            {t('address')}: {report.getIn(['job', 'address'])}
          </p>
        </Grid>
        <Grid item xs={12} sm={2}>
          {t('started_at')}
          <CardHeader
            avatar={
              <div className={classes.startDate}>
                {moment(jobProvider.getIn(['0', 'start_time'])).format('DD')}
              </div>
            }
            subheader={
              <>
                <div>
                  {moment(jobProvider.getIn(['0', 'start_time'])).format('MMMM YYYY')}
                </div>

                <div>
                  {t('started_at')}{' '}
                  {moment(jobProvider.getIn(['0', 'start_time'])).format('HH:mm')}
                </div>
              </>
            }
            style={{ padding: 0 }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          {t('completed_at')}
          <CardHeader
            avatar={
              <div className={classes.endDate}>
                {moment(jobProvider.getIn(['0', 'end_time'])).format('DD')}
              </div>
            }
            subheader={
              <>
                <div>
                  {moment(jobProvider.getIn(['0', 'end_time'])).format('MMMM YYYY')}
                </div>

                <div>
                  {t('started_at')}{' '}
                  {moment(jobProvider.getIn(['0', 'end_time'])).format('HH:mm')}
                </div>
              </>
            }
            style={{ padding: 0 }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <h2>{t('question_completion')}</h2>
          {jobProvider?.getIn([0, 'answers'])?.size > 0 && (
            <QuestionsAndAnswers answers={jobProvider?.getIn([0, 'answers'])} />
          )}
          <h2>{t('customer_signature')}</h2>
          <div>
            {jobProvider.getIn(['0', 'signature', 'file_url']) !== undefined ? (
              <img
                style={{ height: 200, width: 200 }}
                src={jobProvider.getIn([0, 'signature', 'file_url'])}
                alt='answerImage'
              />
            ) : (
              '-'
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <h2>{t('comment')}</h2>

          {comments.size === 0 ? (
            <IonTextarea
              rows={7}
              cols={20}
              placeholder='เพิ่มความคิดเห็นที่นี่เกี่ยวกับงาน'
              value={text}
              style={{ border: '1px solid #EEEEEE', marginTop: 0 }}
              onIonChange={(e) => setText(e.detail.value)}
            />
          ) : (
            <CommentCard value={comments.get(0)?.toJS()} />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5}>
          <ButtonLoader
            color='secondary'
            variant='contained'
            disabled={text.length === 0 && comments.size === 0}
            onClick={() => {
              comments.size === 0
                ? handleCreateComment()
                : push(`/jobs/${report.getIn(['job', 'wfJobId'])}/comments`)
            }}
          >
            {t('add_comment')}
          </ButtonLoader>
        </Grid>
        {comments.size > 0 && (
          <Grid item xs={12} sm={2}>
            <div
              onClick={() => push('/jobs/509901/comments')}
              className={classes.commentMore}
              style={{ cursor: 'pointer' }}
            >
              +{comments.size} {t('comment')}
            </div>
          </Grid>
        )}
      </Grid>
      {report.get('status') === 'pending_review' && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} />
          <Grid item xs={12} sm={4}>
            <div className='button-group'>
              <ButtonLoader
                className={classes.btnDanger}
                variant='contained'
                onClick={() => handleRejectReport(report.get('_id'))}
              >
                {t('disapprove')}
              </ButtonLoader>
              <ButtonLoader
                color='secondary'
                variant='contained'
                onClick={() => handleApproveReport(report.get('_id'))}
              >
                {t('approve')}
              </ButtonLoader>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default ReportOverviewPane
