import { connect } from 'react-redux'

import { fetch } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { serviceSchema, packageSchema } from '@seekster/schemas'

import BookingOrdersFormSection from './BookingOrdersFormSection'

const mapStateToProps = (state) => ({
  getLineItems: id => {
    if (getResource(state, id, serviceSchema)) {
      return getResource(state, id, serviceSchema).get('packages')
    }
  },
  getItemType: id => {
    if (getResource(state, id, packageSchema)) {
      return getResource(state, id, packageSchema).get('item_type')
    }
  }
})

const mapDispatchToProps = {
  fetchService: id => fetch(id, serviceSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingOrdersFormSection)
