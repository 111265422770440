import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Button, Divider, Tooltip } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import Field from 'components/fields/Field'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import styles from './TenantSMSRatingConfigFormStyles'

export function TenantSMSRatingConfigForm({
  resetForm,
  dirty,
  classes,
  t,
}) {
  return (
    <Form>
      <div className={classes.container}>
        <div>{t('sms_rating_config.sub_title')}</div>
        <Divider className={classes.divider} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <div className={classes.setup}>{t('sms_rating_config.setup_info')}</div>
            <Tooltip title={t('sms_rating_config.setup_tooltip')} placement='right'>
              <InfoOutlinedIcon className={classes.infoIcon} />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              name='link_expire_day'
              type='number'
              step={1}
              placeholder='Days'
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Field
              name='link_expire_hour'
              type='number'
              step={1}
              placeholder='Hrs'
            />
          </Grid>

          {dirty && (
            <Grid item xs={12} container justify='flex-end'>
              <Button
                variant='contained'
                onClick={() => resetForm()}
                className={classes.button}
              >
                {t('cancel')}
              </Button>

              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={classes.button}
              >
                {t('save')}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </Form>
  )
}

TenantSMSRatingConfigForm.propTypes = {
  smsRatingConfig: ImmutablePropTypes.map,
  resetForm: PropTypes.func,
  classes: PropTypes.object,
  dirty: PropTypes.bool,

  t: PropTypes.func
}

TenantSMSRatingConfigForm.defaultProps = {
  smsRatingConfig: Map()
}

export default compose(
  withStyles(styles),
  withTranslation('actions'),
  withTranslation('settings')
)(TenantSMSRatingConfigForm)
