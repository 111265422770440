import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Map, fromJS } from 'immutable'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'

import { getCurrentResource } from 'selectors/authentication'
import withLocalizedFields from 'hoc/withLocalizedFields'
import TenantForm from 'forms/resources/TenantForm'
import PopularServiceForm from 'forms/resources/PopularServiceForm'
import { useResourceAction } from 'hooks/resources'
import Panel from 'components/containers/Panel'

import { tenantSchema, serviceSchema } from '@seekster/schemas'

export function GeneralSettingsPane({
  updateCurrentTenant,
  fetchCurrentTenant,
  fetchGeneralConfig,
  fetchPopularService,
  push,
  locale,
  setLocale
}) {
  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()
  const [generalConfig, setGeneralConfig] = useState(Map())
  const [popularService, setPopularService] = useState([])

  const { update: updateService } = useResourceAction(serviceSchema)

  function handleSubmit(data) {
    return updateService(data.id, data)
  }

  useEffect(() => {
    fetchGeneralConfig().then((response) => setGeneralConfig(fromJS(response.body)))
  }, [fetchGeneralConfig])

  useEffect(() => {
    fetchPopularService().then((response) => setPopularService(fromJS(response.body)))
  }, [fetchPopularService])

  function afterSubmit() {
    return fetchCurrentTenant().then(() =>
      fetchGeneralConfig().then((response) => setGeneralConfig(fromJS(response.body)))
    )
  }

  return (
    <Grid container spacing={24}>
      <Grid item xs={12} sm={12}>
        <TenantForm
          tenant={tenant}
          generalConfig={generalConfig}
          onSubmit={updateCurrentTenant}
          afterSubmit={afterSubmit}
          push={push}
          locale={locale}
          setLocale={setLocale}
        />
        <Panel title='Popular Service'>
          <Grid container spacing={24}>
            {popularService.map((service, index) => (
              <Grid item xs={12} sm={6} key={service.get('slug')}>
                <PopularServiceForm
                  popularService={service}
                  onSubmit={handleSubmit}
                  push={push}
                  index={index}
                />
              </Grid>
            ))}
          </Grid>
        </Panel>
      </Grid>
    </Grid>
  )
}

GeneralSettingsPane.propTypes = {
  updateCurrentTenant: PropTypes.func,
  fetchCurrentTenant: PropTypes.func,
  fetchGeneralConfig: PropTypes.func,
  fetchPopularService: PropTypes.func,
  push: PropTypes.func,
  locale: PropTypes.string,
  setLocale: PropTypes.func
}

export default compose(withLocalizedFields)(GeneralSettingsPane)
