import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import './ResultsCountLabel.scss'

export class ResultsCountLabel extends React.Component {
  static propTypes = {
    value: PropTypes.number,
    unit: PropTypes.string,
    t: PropTypes.func
  }

  render () {
    const { value, unit, t, ...rest } = this.props

    return (
      <div className='count-label' {...rest}>
        {
          value ? t(`result_count.${unit}`, { count: value }) : t('result_count.not_found')
        }
      </div>
    )
  }
}

export default withTranslation('labels')(ResultsCountLabel)
