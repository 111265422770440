import { withFormik } from 'formik'
import { pick } from 'lodash'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'
import withFormState from 'hoc/withFormState'
import keyIn from 'utils/keyIn'

import SubscriptionForm from './SubscriptionForm'

const formikConfig = {
  mapPropsToValues: ({ subscription, withoutAssociationIds, withoutJobs }) => !subscription ? {} : ({
    customer_id: subscription.getIn(['customer', 'id']),
    service_id: subscription.getIn(['service', 'id']) || subscription.get('service_id'),
    additional_information: subscription.get('additional_information'),

    start_date: !withoutJobs ? subscription.get('start_date', '') : undefined,
    wdays: !withoutJobs ? subscription.get('wdays').toJS() : undefined,

    answers_attributes: subscription.get('answers').toSeq().map(answer => answer.merge({
      question_id: answer.getIn(['question', 'id']),
      choice_id: answer.getIn(['choice', 'id']),
      choice_ids: answer.get('choices').map(choice => choice.get('id'))
    })).map(answer => answer.filter(keyIn(
      !withoutAssociationIds && 'id',
      'value',
      'question_id',
      'choice_id',
      'choice_ids'
    ))).toJS(),

    orders_attributes: subscription.get('orders').toSeq().map(order => order.merge({
      package_id: order.getIn(['package', 'id'])
    })).map(order => order.filter(keyIn(
      !withoutAssociationIds && 'id',
      'quantity',
      'package_id'
    ))).toJS(),

    location_attributes: subscription.get('location').filter(keyIn(
      'contact_name', 'country', 'district', 'floor', 'house_number',
      'landmark', 'latitude', 'longitude', 'name', 'phone_number',
      'project_name', 'province', 'street', 'sub_district', 'zip_code'
    )).toJS()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values)
      .then(response => {
        if (props.formState.rememberAddress) {
          const newAddress = pick(
            response.body.location,
            [
              'contact_name', 'country', 'district', 'floor', 'house_number',
              'landmark', 'latitude', 'longitude', 'name', 'phone_number',
              'project_name', 'province', 'street', 'sub_district', 'zip_code'
            ]
          )

          props.createCustomerAddress(newAddress, response.body.customer.id)
        }

        props.push(`/subscriptions/${response.body.id}`)

        return response
      })
      .catch(error => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(
  withFlashMessage,
  withFormState({ rememberAddress: false }),
  withFormik(formikConfig)
)(SubscriptionForm)
