import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Link } from 'react-router-dom'
import { Table, Column } from 'components/tables/Table'

import { DateTag } from 'components/tags'
import Panel from 'components/containers/Panel'

export function Payouts({ job }) {
  function payouts() {
    const payouts = job.get('payouts') || List()

    if (payouts.isEmpty()) {
      return <p>No Payouts.</p>
    } else {
      return (
        <Table collection={payouts}>
          <Column
            name='source'
            render={(_, payouts) => (
              <p>
                <Link to={`/providers/${payouts.get('provider_id')}/wallet`}>
                  Wallet Transaction
                </Link>
              </p>
            )}
          />
          <Column
            name='amount'
            render={(amount) => <p>{amount.get('display_value')}</p>}
          />
          <Column name='description' />
          <Column name='created_at' component={DateTag} />
        </Table>
      )
    }
  }

  return <Panel title='Payouts'>{payouts()}</Panel>
}

Payouts.propTypes = {
  job: ImmutablePropTypes.map
}

Payouts.defaultProps = {
  job: Map()
}

export default Payouts
