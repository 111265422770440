import React from 'react'
import PropTypes from 'prop-types'
import Cleave from 'cleave.js/react'
import moment from 'moment'
import Field from 'components/fields/Field'
import { TextField } from '@material-ui/core'

import { withTranslation } from 'react-i18next'

export class DateInputField extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,

    label: PropTypes.string,
    t: PropTypes.func,
    tReady: PropTypes.bool,
    i18n: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.handleDateInit = this.handleDateInit.bind(this)
    this.renderCleave = this.renderCleave.bind(this)

    this.state = {
      cleave: null
    }
  }

  handleDateInit(cleave) {
    this.setState({ cleave })
  }

  renderCleave(props) {
    const { name, field, form, placeholder, label, tReady, i18n, ...rest } = props

    return (
      <Cleave
        {...field}
        style={{ padding: '10.5px 14px' }}
        placeholder={placeholder || props.t([`placeholder.${name}`, name])}
        options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
        onInit={this.handleDateInit}
        value={field.value ? moment(field.value).format('DD-MM-YYYY') : ''}
        {...rest}
      />
    )
  }

  render() {
    const { name } = this.props

    return (
      <Field
        name={name}
        render={({ field, form }) => (
          <TextField
            fullWidth
            color='primary'
            variant='outlined'
            onChange={() =>
              this.state.cleave.getISOFormatDate() &&
              form.setFieldValue(name, this.state.cleave.getISOFormatDate())
            }
            inputProps={{ ...this.props, field, form }}
            InputProps={{
              inputComponent: this.renderCleave
            }}
          />
        )}
      />
    )
  }
}

export default withTranslation(['fields', 'attributes'])(DateInputField)
