import React from 'react'
import FilterSelectTags from './components/FilterSelectTags'
import FilterServiceTags from './components/FilterAssociationTags/components/FilterServiceTags'
import FilterCategoryTags from './components/FilterAssociationTags/components/FilterCategoryTags'
import FilterClientTags from './components/FilterAssociationTags/components/FilterClientTags'
import FilterRegionTags from './components/FilterAssociationTags/components/FilterRegionTags'
import FilterBadgeTags from './components/FilterAssociationTags/components/FilterBadgeTags'
import FilterCustomerTags from './components/FilterAssociationTags/components/FilterCustomerTags'
import FilterProviderTags from './components/FilterAssociationTags/components/FilterProviderTags'
import FilterDateRangeTags from './components/FilterDateRangeTags'
import FilterNumericRangeTags from './components/FilterNumericRangeTags'
import FilterMoneyRangeTags from './components/FilterMoneyRangeTags'
import FilterCreatedByTags from './components/FilterAssociationTags/components/FilterCreatedByTags'

import './FilterTags.scss'

export class FilterTags extends React.Component {
  render() {
    return (
      <div className='filter-tags'>
        <FilterSelectTags />
        <FilterServiceTags />
        <FilterCategoryTags />
        <FilterClientTags />
        <FilterRegionTags />
        <FilterBadgeTags />
        <FilterDateRangeTags />
        <FilterNumericRangeTags />
        <FilterMoneyRangeTags />
        <FilterCustomerTags />
        <FilterProviderTags />
        <FilterCreatedByTags />
      </div>
    )
  }
}

export default FilterTags
