import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { CSVReader } from 'react-papaparse'

export function CsvUpload({ t, onHandleDrop }) {
  const handleData = (readerResult) => {
    const keys = readerResult[0].data
    const resultData = readerResult.slice(1)

    const newArray = resultData.map((result, index) => {
      return result.data
    })

    const result = newArray.map((datum) => {
      const newRow = {}
      keys.forEach((header, i) => {
        newRow[header] = datum[i]
      })
      return newRow
    })

    return result
  }

  return (
    <CSVReader
      onDrop={(acceptedFiles) => onHandleDrop(handleData(acceptedFiles))}
      addRemoveButton
      removeButtonColor='#659cef'
    >
      <span>Drop CSV file here or click to upload.</span>
    </CSVReader>
  )
}

CsvUpload.propTypes = {
  onHandleDrop: PropTypes.func,
  t: PropTypes.func
}

export default withTranslation('inquiries')(CsvUpload)
