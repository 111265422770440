import React from 'react'
import { Map } from 'immutable'

import PropTypes from 'prop-types'
import { withMobileDialog } from '@material-ui/core'
import { useSelector } from 'react-redux'

import TenantTicketSettingForm from 'forms/actions/TenantTicketSettingForm'
import { useResourceAction, useResource } from 'hooks/firebase/resources'
import { settingSchema, tenantSchema } from '@seekster/schemas'
import { getCurrentResource } from 'selectors/authentication'

export function TicketSettingsPane ({
  fullScreen
}) {
  const tenant = useSelector(state => getCurrentResource(state, tenantSchema)) || Map()
  const { update: updateSetting } = useResourceAction(settingSchema)
  const [settings] = useResource(`tenants/${tenant.get('slug')}/settings`, 'ticketConfiguration', settingSchema)

  return (
    <TenantTicketSettingForm
      onSubmit={updateSetting}
      settings={settings}
      tenant={tenant.get('slug')}
    />
  )
}

TicketSettingsPane.propTypes = {
  fullScreen: PropTypes.bool
}

export default withMobileDialog()(TicketSettingsPane)
