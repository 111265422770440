import React from 'react'
import PropTypes from 'prop-types'

import './CodeTag.scss'

export class CodeTag extends React.Component {
  static propTypes = {
    value: PropTypes.node
  }

  render () {
    return (
      <span className='code-tag'>{this.props.value}</span>
    )
  }
}

export default CodeTag
