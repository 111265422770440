import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import ButtonLoader from 'components/buttons/ButtonLoader'
import { Grid, Avatar, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import { uniqBy } from 'lodash'
import { withTranslation } from 'react-i18next'
import Rating from 'components/Rating'
import CircularLoader from 'components/CircularLoader'

import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import ResourceCard from 'components/cards/ResourceCard'
import Modal from 'components/containers/Modal'
import FavoriteProviderForm from 'forms/resources/FavoriteProviderForm'
import { DateTag, StateTag, PhoneTag } from 'components/tags'

export class CustomerFavoriteProvidersPane extends React.Component {
  static propTypes = {
    customer: ImmutablePropTypes.map,
    match: PropTypes.object,
    fetchCustomerFavoriteProviders: PropTypes.func,
    createCustomerFavoriteProvider: PropTypes.func,
    destroyCustomerFavoriteProvider: PropTypes.func,
    query: PropTypes.object,
    t: PropTypes.func
  }

  static defaultProps = {
    customer: Map(),

    fetchCustomerFavoriteProviders: () => {},
    createCustomerFavoriteProvider: () => {},
    destroyCustomerFavoriteProvider: () => {}
  }

  constructor (props) {
    super(props)

    this.handleAddFavoriteProvider = this.handleAddFavoriteProvider.bind(this)
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)

    this.state = {
      modalOpen: false,
      loading: false,
      error: null,
      totalCount: 0,
      totalPages: 1
    }
  }

  componentDidMount () {
    this.handleUpdateData()
  }

  componentDidUpdate (prevProps, prevState) {
    this.props.query.page !== prevProps.query.page && this.handleUpdateDataOnPageChange()
  }

  handleUpdateData () {
    this.setState({ loading: true })

    this.props.fetchCustomerFavoriteProviders(this.props.match.params.id)
      .then(response => {
        this.setState({
          loading: false,
          totalCount: parseInt(response.headers['total'], 10),
          totalPages: Math.ceil(response.headers['total'] / response.headers['per-page'])
        })
      })
      .catch(error => {
        this.setState({ loading: false, error: error.message })
      })
  }

  handleUpdateDataOnPageChange () {
    const { page } = this.props.query

    this.setState({ loading: true })

    return this.props.fetchCustomerFavoriteProviders(this.props.match.params.id, { page })
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(error => {
        this.setState({ loading: false, error: error.message })
      })
  }

  handleAddFavoriteProvider (values) {
    return this.props.createCustomerFavoriteProvider(values, this.props.match.params.id)
      .then(() => {
        this.handleUpdateData()
      })
      .then(() => this.handleCloseModal())
  }

  handleOpenModal () {
    this.setState({ modalOpen: true })
  }

  handleCloseModal () {
    this.setState({ modalOpen: false })
  }

  renderProviderRating (rating) {
    return (
      <div>
        <Rating value={rating} />
      </div>
    )
  }

  renderPrimarySupportContent (state, email, phoneNumber) {
    return (
      <div>
        <StateTag value={state} />
        <div>{email}</div>
        <PhoneTag value={phoneNumber} />
      </div>
    )
  }

  renderSecondarySupportContent (favoritedAt) {
    return (
      <div>
        <div style={{ fontSize: '0.8rem', color: '#7c7c7c' }}>
          {this.props.t('favorited_at')}
        </div>
        <DateTag value={favoritedAt} />
      </div>
    )
  }

  renderActionButtons (id) {
    return (
      <ButtonLoader
        color='secondary'
        variant='contained'
        onClick={() => (
          window.confirm(this.props.t('remove_confirmation_prompt')) &&
          this.props.destroyCustomerFavoriteProvider(id)
            .then(() => this.handleUpdateData())
        )}
      >
        {this.props.t('remove')}
      </ButtonLoader>
    )
  }

  render () {
    const { loading, totalCount, totalPages } = this.state
    const preferredProviders = this.props.customer.get('preferred_providers') || List()
    const services = preferredProviders.map(preferredProvider => ({
      id: preferredProvider.getIn(['service', 'id']),
      name: preferredProvider.getIn(['service', 'name']),
      icon: preferredProvider.getIn(['service', 'icon_url'], '')
    })).toJS()

    return (
      <>
        <div style={{ padding: 7 }}>
          <Grid container spacing={2}>
            <Grid item xs={10} sm={11}>
              <ResultsCountLabel value={totalCount} unit='provider' />
            </Grid>

            <Grid item xs={2} sm={1}>
              <Modal
                isOpen={this.state.modalOpen}
                onModalClose={this.handleCloseModal}
                trigger={
                  <ButtonLoader
                    color='primary'
                    variant='contained'
                    onClick={this.handleOpenModal}
                  >
                    {this.props.t('add_provider')}
                  </ButtonLoader>
                }
              >
                <FavoriteProviderForm
                  onSubmit={this.handleAddFavoriteProvider}
                  onCancelForm={this.handleCloseModal}
                />
              </Modal>
            </Grid>
          </Grid>
        </div>

        {
          loading && <CircularLoader />
        }

        {
          !loading && totalCount !== 0 && (
            <>
              {
                uniqBy(services, 'id').map(service => {
                  return (
                    <div key={service.id} style={{ marginBottom: '28px' }}>
                      <ListItem key={service.id}>
                        <ListItemAvatar>
                          <Avatar src={service.icon} />
                        </ListItemAvatar>

                        <ListItemText primary={service.name} />
                      </ListItem>

                      {
                        preferredProviders.toJS()
                          .filter(preferredProvider => (
                            preferredProvider.service.id === service.id && preferredProvider.provider
                          ))
                          .map(preferredProvider => {
                            const {
                              display_name: displayName,
                              id,
                              avatar_url,
                              rating,
                              state,
                              email,
                              phone_number: phoneNumber
                            } = preferredProvider.provider

                            return (
                              <ResourceCard
                                key={id}
                                title={displayName}
                                resourceLink={`/providers/${id}`}
                                avatar={avatar_url}
                                subtitle={this.renderProviderRating(rating)}
                                primarySupportContent={this.renderPrimarySupportContent(state, email, phoneNumber)}
                                secondarySupportContent={this.renderSecondarySupportContent(preferredProvider.created_at)}
                                actionButtons={this.renderActionButtons(preferredProvider.id)}
                              />
                            )
                          })
                      }
                    </div>
                  )
                })
              }

              <Grid item xs={12} container justify='center'>
                <Pagination totalPages={totalPages} />
              </Grid>
            </>
          )
        }
      </>
    )
  }
}

export default withTranslation(['customers', 'attributes', 'common'])(CustomerFavoriteProvidersPane)
