import React from 'react'
import PropTypes from 'prop-types'

export class Switch extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    value: PropTypes.string
  }

  render () {
    return (
      React.Children.map(this.props.children, child => {
        if (child.props.value === this.props.value) {
          return child
        }
        else {
          return null
        }
      })
    )
  }
}

export default Switch
