import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { create } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { jobSchema, commentSchema } from '@seekster/schemas'

import JobCommentsPane from './JobCommentsPane'

const mapStateToProps = (state, { match }) => ({
  job: getResource(state, match.params.id, jobSchema)
})

const mapDispatchToProps = {
  loadComments: (id, options) => fetchChildren(jobSchema, id, commentSchema, options),
  create: (data, id, options) => create(data, jobSchema, { type: `jobs/${id}/comments` })
}

export default connect(mapStateToProps, mapDispatchToProps)(JobCommentsPane)
