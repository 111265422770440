import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import { DateTag, StateTag } from 'components/tags'
import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'

export function PaymentOverviewPane ({ payment, t }) {
  return (
    <Panel title={t('title')}>
      <AttributesTable resource={payment}>
        <Attribute name='id' />
        <Attribute
          name='amount'
          render={(amount, payment) => <p>{payment.getIn(['amount', 'full_display'])}</p>}
        />
        <Attribute name='state' component={StateTag} />
        <Attribute
          name='invoice'
          render={(invoice, payment) => (
            <Link to={`/invoices/${payment.getIn(['invoice', 'id'])}`}>
              {payment.getIn(['invoice', 'number'])}
            </Link>
          )}
        />
        <Attribute
          name='payment_method_id'
          render={(amount, payment) => <p>{payment.getIn(['payment_method', 'name'])}</p>}
        />
        <Attribute name='created_at' component={DateTag} />
      </AttributesTable>
    </Panel>
  )
}

PaymentOverviewPane.propTypes = {
  payment: ImmutablePropTypes.map,
  t: PropTypes.func
}

PaymentOverviewPane.defaultProps = {
  payment: Map()
}

export default withTranslation('payments')(PaymentOverviewPane)
