import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { paymentSchema } from '@seekster/schemas'

import PaymentOverviewPane from './PaymentOverviewPane'

const mapStateToProps = (state, { match }) => ({
  payment: getResource(state, match.params.id, paymentSchema)
})

export default connect(mapStateToProps)(PaymentOverviewPane)
