import moment from 'moment'
import { map } from 'lodash'

import { SMS_RATING_REASONS } from 'constants/states/smsRatingStates'

export const smsRatingsQuery = ({
  search,
  date = {},
  reason
}) => {
  const { dateList = [], from = '00:00', to = '23:59' } = date
  return {
    number_or_customer_first_name_or_customer_last_name_or_customer_phone_number_or_location_contact_name_or_location_phone_number_or_location_name_or_location_house_number_or_location_street_or_location_project_name_or_location_sub_district_or_location_district_or_location_province_or_providers_first_name_or_providers_last_name_cont: search,

    is_unrated_and_unsent: (reason === SMS_RATING_REASONS[0] ? true : null),

    end_time_gteq: dateList.length === 1
      ? moment(`${dateList[0]} ${from}`).toISOString(true)
      : undefined,
    end_time_lteq: dateList.length === 1
      ? moment(`${dateList[0]} ${to}`).toISOString(true)
      : undefined,

    end_time_at_eq: dateList.length > 1
      ? map(dateList, (d) => moment(d).startOf('day').toISOString(true)).join(',')
      : undefined
  }
}

export default smsRatingsQuery
