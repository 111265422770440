export default theme => ({
  commentContainer: {
    display: 'flex'
  },

  comment: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  feedbackGiver: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})
