export default (theme) => ({
  root: {
    marginTop: 16
  },

  image: {
    height: '120px',
    width: '120px',
    padding: theme.spacing(0.5)
  }
})
