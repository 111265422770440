import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import PromoCodeForm from 'forms/resources/PromoCodeForm'
import { callService } from 'utils/callService'

export class PromoCodeEditPage extends React.Component {
  static propTypes = {
    promoCode: ImmutablePropTypes.map,
    loadPromoCode: PropTypes.func,
    updatePromoCode: PropTypes.func,
    match: PropTypes.object,
    push: PropTypes.func,

    t: PropTypes.func
  }

  static defaultProps = {
    promoCode: Map()
  }

  componentDidMount() {
    this.props.loadPromoCode(this.props.match.params.id)
  }

  handleSubmit(data, options) {
    return this.props.updatePromoCode(this.props.match.params.id, data, options)
  }

  updatePromoCode = async (data) => {
    try {
      const response = await callService.patch(`${process.env.REACT_APP_BASE_API}/promo_codes/${this.props.match.params.id}`, data)
      if (response.status === 200) {
        this.props.push(`/promo_codes/${this.props.match.params.id}`)
      }

      return response
    } catch (error) {
      alert(`An error occurred while updating the Promo Code: ${error.message}`)
    }
  }

  render() {
    return (
      <PageLayout
        title={this.props.t('edit')}
        isLoading={this.props.promoCode.isEmpty()}
        back={`/promo_codes/${this.props.match.params.id}`}
      >
        <PromoCodeForm
          promoCode={this.props.promoCode}
          push={this.props.push}
          onSubmit={this.updatePromoCode.bind(this)}
        />
      </PageLayout>
    )
  }
}

export default withTranslation('promo_codes')(PromoCodeEditPage)
