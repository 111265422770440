import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Map, fromJS } from 'immutable'
import { usePush } from 'hooks/utils'

import { useResourceAction } from 'hooks/resources'
import PayrollSettingsForm from 'forms/actions/PayrollSettingsForm'
import { manualPayrollIntegrationSchema } from '@seekster/schemas'

const useStyles = makeStyles(() => ({
  root: {
    padding: '40px 26px'
  }
}))

export function PayrollSettingsPane () {
  const [payrollSetting, setPayrollSetting] = useState(Map())
  const { create: createPayrollSetting, update: updatePayrollSetting, fetch: fetchPayrollSetting } = useResourceAction(manualPayrollIntegrationSchema)
  const classes = useStyles()
  const push = usePush()

  useEffect(() => {
    fetchPayrollSetting('').then(response => setPayrollSetting(fromJS(response.body)))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function handleUpdatePayrollSetting (data) {
    return updatePayrollSetting('', data)
  }

  function handleCreatePayrollSetting (data) {
    return createPayrollSetting(data)
  }

  return (
    <div className={classes.root}>
      <PayrollSettingsForm
        payrollSetting={payrollSetting}
        isCreate={payrollSetting.isEmpty()}
        push={push}
        onSubmit={payrollSetting.isEmpty() ? handleCreatePayrollSetting : handleUpdatePayrollSetting}
      />
    </div>
  )
}

export default PayrollSettingsPane
