import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { compose } from 'redux'

import withQueryParser from 'utils/withQueryParser'
import {
  fetchCurrentTenantBySlug,
  acceptInvitationWithOauth,
  acceptInvitationWithEmail,
  updateCurrentDevice
} from 'actions/authentication'
import { fetch } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { getCurrentResource } from 'selectors/authentication'
import { tenantSchema, invitationSchema } from '@seekster/schemas'

import AcceptInvitationPage from './AcceptInvitationPage'

const mapStateToProps = (state, { query }) => ({
  tenant: getCurrentResource(state, tenantSchema),
  invitation: getResource(state, query.token, invitationSchema)
})

const mapDispatchToProps = {
  fetchInvitation: token => fetch(token, invitationSchema),
  fetchCurrentTenantBySlug: () => fetchCurrentTenantBySlug(tenantSchema),
  acceptInvitationWithOauth,
  acceptInvitationWithEmail,
  updateCurrentDevice,
  push
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(AcceptInvitationPage)
