import React from 'react'
import { InputBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing, shape, palette, transitions }) => ({
  root: {
    backgroundColor: 'white',
    padding: spacing(1, 2),
    border: '1px solid',
    borderColor: palette.grey[300],
    borderRadius: shape.borderRadius,
    transition: transitions.create(['border-color'])
  },
  disabled: {
    backgroundColor: '#E5E5E5'
  },
  focused: {
    borderColor: palette.primary.main
  }
}))
export function Input ({ ...props }) {
  const classes = useStyles()

  return (
    <InputBase
      color='primary'
      fullWidth
      classes={classes}
      {...props}
    />
  )
}

export default Input
