import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { useTranslation } from 'react-i18next'

import { useNodeResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'
import { organizationSchema } from '@seekster/schemas'

import PageLayout from 'components/layouts/PageLayout'
import OrganizationForm from 'forms/resources/OrganizationForm'
import Swal from 'sweetalert2'

export function OrganizationEditPage({ match }) {
  const { t } = useTranslation(['organizations', 'attributes'])
  const [organization, { loading }] = useNodeResource(organizationSchema, match.params.id)

  const { nodeUpdate: updateOrganization } = useResourceAction(organizationSchema)
  const push = usePush()

  function handleSubmit(data) {
    return updateOrganization(match.params.id, data).then(({ body }) => {
      Swal.fire({
        html:
          '<span>' +
          '<img style="border-radius: 50%;margin-top: 16px;" src="https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/Icon-awesome-check-circle.png" width="30" height="30">' +
          `<p style="margin-top:0;color: #1B4171;font-family:Roboto, Helvetica, Arial, sans-serif;">${t(
            'message.update-org',
            { name: body.name }
          )}` +
          '</span>',
        showConfirmButton: false,
        timer: 1000
      }).then(() => {
        push(`/organizations/${body._id}`)
      })
    })
  }

  return (
    <PageLayout
      title={t('edit')}
      isLoading={loading}
      back={`/organizations/${match.params.id}`}
    >
      <OrganizationForm organization={organization} onSubmit={handleSubmit} push={push} />
    </PageLayout>
  )
}

OrganizationEditPage.propTypes = {
  match: PropTypes.object
}

OrganizationEditPage.defaultProps = {
  job: Map()
}

export default OrganizationEditPage
