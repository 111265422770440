import React from 'react'
import { Grid, Paper } from '@material-ui/core'

import CircularLoader from 'components/CircularLoader'
import { Table, Column } from 'components/tables/Table'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import { useCollectionChildren } from 'hooks/collections'
import { companySchema, branchSchema } from '@seekster/schemas'
import { Link } from 'react-router-dom'

export function CompanyBranchesPane ({
  match,
  query
}) {
  const [branches, { loading, totalCount, totalPages }] = useCollectionChildren(
    companySchema,
    match.params.id,
    branchSchema,
    { page: query.page },
    [query.page, match.params.id]
  )

  return (
    loading ? (
      <CircularLoader />
    ) : (
      <>
        <Grid
          container
          direction='row'
          justify='space-between'
          style={{ marginBottom: 8 }}
        >
          <ResultsCountLabel value={totalCount} unit='branch' />
        </Grid>

        {
          totalCount !== 0 && (
            <>
              <Grid>
                <Paper>
                  <Table collection={branches}>
                    <Column name='id' />
                    <Column name='name' />
                    <Column
                      name='customers'
                      render={(customers) =>
                        <>
                          {
                            customers.map((customer) =>
                              <li key={customer.get('id')}>
                                <Link to={`/customers/${customer.get('id')}`}>{customer.get('display_name')}</Link>
                              </li>
                            )
                          }
                        </>}
                    />
                  </Table>
                </Paper>
              </Grid>

              <Grid container justify='center'>
                <Pagination totalPages={totalPages} />
              </Grid>
            </>
          )
        }
      </>
    )
  )
}

export default CompanyBranchesPane
