import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import { StateTag } from 'components/tags'
import { AttributesTable, Attribute } from 'components/tables/AttributesTable'

export class Request extends React.Component {
  static propTypes = {
    job: ImmutablePropTypes.map
  }

  static defaultProps = {
    job: Map()
  }

  render () {
    const { job } = this.props

    const inquiry = job.get('inquiry') || Map()

    return (
      <AttributesTable>
        <Attribute label='Request No.' association={inquiry} path='/inquiries' displayField='number' />
        <Attribute label='State' value={<StateTag value={inquiry.get('state')} />} />
      </AttributesTable>
    )
  }
}

export default Request
