import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, fromJS, List } from 'immutable'
import {
  Grid,
  Button,
  Chip,
  FormControlLabel,
  Checkbox,
  TextareaAutosize,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import { Phone, MailOutline, AddCircle, UnfoldMore } from '@material-ui/icons'
import { Form } from 'formik'
import { components } from 'react-select'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import CircularLoader from 'components/CircularLoader'
import { useAppConfigs } from 'hooks/configurations'
import { useEventId } from 'hooks/useEventId'

import DropzoneInput from 'components/inputs/DropzoneInput'
import ButtonLoader from 'components/buttons/ButtonLoader'
import Panel from 'components/containers/Panel'
import { Tabs, TabPane } from 'components/Tabs'
import { Field, FastField } from 'components/fields/Field'
import ResourceSelect from 'components/inputs/ResourceSelect'
import TimePickerToggleButton from 'components/buttons/TimePickerToggleButton'
import Item from 'components/Item'
import Tag from 'components/Tag'
import CapitalizeFormatter from 'components/formatters/CapitalizeFormatter'
import PhoneNumberFormatter from 'components/formatters/PhoneNumberFormatter'

import {
  serviceSchema,
  customerSchema,
  addressSchema,
  companySchema,
  branchSchema,
  routeSchema
} from '@seekster/schemas'
import { useResource, useResourceActions } from 'hooks/resources'

import AddressFormSection from 'modules/addresses/AddressFormSection'
import RequestJobsFormSection from 'modules/requests/RequestJobsFormSection'
import RequestPricingSection from 'modules/requests/RequestPricingSection'
import {
  BookingCompanyFormSection,
  BookingCustomerFormSection,
  BookingQuestionsFormSection,
  BookingOrdersFormSection,
  BookingBranchFromSection
} from 'modules/bookings'
import RequestMapSection from 'modules/requests/RequestMapSection'

import styles from './InquiryFormStyles'

export function InquiryForm({
  inquiry,
  fetchAddress,
  values,
  setFieldValue,
  setValues,
  isSubmitting,
  formState,
  setFormState,
  disabledOrdersSection,
  showReferenceValues,
  disabledJobsSection,
  disabledUserSection,
  classes,
  setFlashMessage,
  getLineItems,
  t
}) {
  const [{ timePickerType }] = useAppConfigs()
  const [addressFormSectionInstanceId, generateAddressFormSectionInstanceId] =
    useEventId()
  const [customerOperation, setCustomerOperation] = useState(false)
  const [companyOperation, setCompanyOperation] = useState(false)
  const [branchOperation, setBranchOperation] = useState(false)
  const [createRouteLoading, setCreateRouteLoading] = useState(false)
  const [tabValue, setTabValue] = useState(values.company_id ? 1 : 0)
  const selectCustomer = branchOperation !== 'add' && [
    { display_name: 'DISPLAY ALL CUSTOMER', id: 'show' }
  ]
  const [service, { loading }] = useResource(serviceSchema, values.service_id, [
    values.service_id
  ])
  const { create: createRoute } = useResourceActions(routeSchema)
  const [route, setRoute] = useState(values.route)
  const [routePrice, setRoutePrice] = useState(null)

  function handleAddressChange(addressId) {
    if (addressId) {
      fetchAddress(addressId).then(({ body }) => {
        setFieldValue('location_attributes', {
          ...values.location_attributes,
          latitude: body.latitude,
          longitude: body.longitude,
          name: body.name,
          contact_name: body.contact_name,
          phone_number: body.phone_number,
          house_number: body.house_number,
          project_name: body.project_name,
          street: body.street,
          sub_district: body.sub_district,
          district: body.district,
          province: body.province,
          country: body.country,
          zip_code: body.zip_code
        })
      })
    } else {
      setFieldValue('location_attributes', undefined)

      generateAddressFormSectionInstanceId()
    }
  }

  function handleRememberAddress() {
    setFormState({ rememberAddress: !formState.rememberAddress })
  }

  function handleCustomerChange(customerId) {
    if (customerId && customerId.id === 'add') {
      setCustomerOperation('add')
    } else if (customerId && customerId.id === 'show') {
      setCustomerOperation('show')
    } else {
      setCustomerOperation(false)
      setValues({
        ...values,
        customer_id: customerId,
        location_attributes: undefined,
        customer_attributes: undefined
      })

      !values.branch_id && generateAddressFormSectionInstanceId()
    }
  }

  function handleCompanyTabChange(event, newValue) {
    setTabValue(newValue)
    setBranchOperation(false)
    setCompanyOperation(false)
    setCustomerOperation(false)

    setValues({
      ...values,
      company_id: undefined,
      company_attributes: undefined,
      companyInfo: {},
      customer_id: undefined,
      branch_id: undefined,
      customer_attributes: undefined,
      location_attributes: undefined
    })

    generateAddressFormSectionInstanceId()
  }

  function handleCompanyChange(companyId) {
    if (companyId && companyId.id === 'add') {
      setCompanyOperation('add')
      setBranchOperation('add')
    } else {
      setCompanyOperation(false)
      setValues({
        ...values,
        company_id: companyId,
        branch_id: undefined,
        customer_id: undefined,
        location_attributes: undefined
      })

      generateAddressFormSectionInstanceId()
    }
  }

  function handleBranchChange(branchId) {
    if (branchId && branchId.id === 'add') {
      setBranchOperation('add')
    } else {
      setValues({
        ...values,
        branch_id: branchId
      })
    }
  }

  function handleCreateRoute() {
    setCreateRouteLoading(true)
    const checkNum = new RegExp('^[0-9]*$')

    if (
      !checkNum.test(values.destination_attributes.phone_number) ||
      values.destination_attributes.phone_number.length > 10
    ) {
      setCreateRouteLoading(false)
      return setFlashMessage('error', 'Destination phone number invalid')
    } else if (
      !checkNum.test(values.origin_attributes.phone_number) ||
      values.origin_attributes.phone_number.length > 10
    ) {
      setCreateRouteLoading(false)
      return setFlashMessage('error', 'Origin phone number invalid')
    } else {
      const data = {
        origin_attributes: values.origin_attributes,
        destination_attributes: values.destination_attributes
      }

      createRoute(data, { query: { service_id: values.service_id } })
        .then((response) => {
          setRoute(response.body.route)
          setFieldValue('route_id', response.body && response.body.route.id)
          setRoutePrice(response.body.price)
          setCreateRouteLoading(false)
        })
        .catch((err) => {
          setFlashMessage('error', err.response.body.message)
          setCreateRouteLoading(false)
        })
    }
  }

  function handleEditRoute() {
    setRoute()
    setRoutePrice(null)
  }

  const ordersAttributes = values.orders_attributes || []
  const lineItems = getLineItems(values.service_id) || List()

  useEffect(() => {
    window.onbeforeunload = () => {
      return window.confirm('Confirm refresh')
    }

    return () => (window.onbeforeunload = () => {})
  }, [])

  return (
    <Form>
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} sm={9}>
          <Panel title={t('form.service.title')}>
            <Field
              name='service_id'
              component={ResourceSelect}
              schema={serviceSchema}
              noLabel
              isDisabled={disabledOrdersSection}
              styles={{
                valueContainer: (base) => ({ ...base, height: 60, fontSize: 16 })
              }}
              components={{
                Option: ({ data, ...rest }) => (
                  <components.Option {...rest}>
                    <Item
                      name={
                        <>
                          {data.name}{' '}
                          <Tag
                            value={data.region && data.region.name}
                            formatter={CapitalizeFormatter}
                          />
                        </>
                      }
                      avatar={data.icon_url}
                    />
                  </components.Option>
                ),
                SingleValue: ({ data, children, ...rest }) => (
                  <components.SingleValue {...rest}>
                    <Item
                      name={
                        <>
                          {data.name}{' '}
                          <Tag
                            value={data.region && data.region.name}
                            formatter={CapitalizeFormatter}
                          />
                        </>
                      }
                      avatar={data.icon_url}
                    />
                  </components.SingleValue>
                )
              }}
            />

            {!loading && values.service_id && (
              <div className={classes.fieldContainer}>
                <Grid container spacing={5}>
                  <Grid
                    item
                    xs={12}
                    style={
                      disabledOrdersSection && { pointerEvents: 'none', opacity: 0.7 }
                    }
                  >
                    <BookingOrdersFormSection name='orders_attributes' />
                  </Grid>

                  <Grid item xs={12}>
                    <BookingQuestionsFormSection name='answers_attributes' />
                  </Grid>
                </Grid>
              </div>
            )}
          </Panel>

          {loading && values.service_id ? (
            <CircularLoader />
          ) : (
            <>
              {values.service_id && (
                <>
                  {service.get('logistics_pricings') &&
                  !service.get('logistics_pricings').isEmpty() ? (
                    <>
                      {route ? (
                        <>
                          <Panel
                            title={t('delivery_location')}
                            actions={
                              !disabledUserSection && (
                                <Button
                                  variant='outlined'
                                  size='small'
                                  onClick={() => handleEditRoute()}
                                  className={classes.toggleButton}
                                >
                                  {t('edit_route')}
                                </Button>
                              )
                            }
                          >
                            <RequestMapSection
                              origin={values.origin_attributes}
                              destination={values.destination_attributes}
                              route={fromJS(route) || Map()}
                              location={Map()}
                              isDelivery
                            />
                          </Panel>

                          <Panel
                            title={t('form.date_time.title')}
                            actions={
                              service.get('slot_enabled') ? null : (
                                <TimePickerToggleButton />
                              )
                            }
                            className={classes.customerSection}
                          >
                            <RequestJobsFormSection
                              name='jobs_attributes'
                              timePickerType={timePickerType}
                              readOnly={disabledJobsSection}
                              referenceJobs={showReferenceValues && inquiry.get('jobs')}
                              slotEnabled={service.get('slot_enabled')}
                              serviceId={service.get('id')}
                            />
                          </Panel>

                          <div className={classes.customerSection}>
                            <Tabs
                              onChange={handleCompanyTabChange}
                              contained
                              scrollButtons='off'
                              classes={{ flexContainer: classes.tabs }}
                              currentTab={tabValue}
                            >
                              <TabPane label={t('individual')} className={classes.tab}>
                                <Field
                                  name='customer_id'
                                  key={`${values.company_id}-${values.customer_id}`}
                                  noLabel
                                  component={ResourceSelect}
                                  isDisabled={disabledUserSection}
                                  schema={customerSchema}
                                  moreOptions={[
                                    { display_name: 'ADD NEW CUSTOMER', id: 'add' }
                                  ]}
                                  onChange={handleCustomerChange}
                                  styles={{
                                    valueContainer: (base) => ({
                                      ...base,
                                      height: 60,
                                      fontSize: 16
                                    })
                                  }}
                                  components={{
                                    Option: ({ data, ...rest }) => (
                                      <components.Option {...rest}>
                                        {data.display_name === 'ADD NEW CUSTOMER' ? (
                                          <ListItem button>
                                            <ListItemIcon>
                                              <AddCircle />
                                            </ListItemIcon>
                                            <ListItemText primary={data.display_name} />
                                          </ListItem>
                                        ) : (
                                          <Item
                                            name={`${data.display_name} ${
                                              data.company
                                                ? `(${t('form.company.title')}: ${
                                                    data.company.name
                                                  })`
                                                : ''
                                            }`}
                                            avatar={data.avatar_url}
                                            avatarProps={{
                                              className: classes.customerAvatar
                                            }}
                                          >
                                            <Chip
                                              icon={
                                                <MailOutline
                                                  color='disabled'
                                                  fontSize='small'
                                                />
                                              }
                                              label={data.email}
                                              variant='outlined'
                                              className={classes.chip}
                                            />

                                            <Chip
                                              icon={
                                                <Phone
                                                  color='disabled'
                                                  fontSize='small'
                                                />
                                              }
                                              label={
                                                <PhoneNumberFormatter
                                                  value={data.phone_number}
                                                />
                                              }
                                              variant='outlined'
                                              className={classes.chip}
                                            />
                                          </Item>
                                        )}
                                      </components.Option>
                                    ),
                                    SingleValue: ({ children, data, ...rest }) => (
                                      <components.SingleValue {...rest}>
                                        <Item
                                          name={data.display_name}
                                          avatar={data.avatar_url}
                                        >
                                          {data.company ? `(${data.company.name})` : ''}
                                        </Item>
                                      </components.SingleValue>
                                    )
                                  }}
                                />

                                {customerOperation && (
                                  <BookingCustomerFormSection
                                    attributeScope='customer_attributes'
                                    key={values.company_id}
                                  />
                                )}
                              </TabPane>
                              <TabPane
                                label={t('organization')}
                                className={classes.tab}
                                id='tab-1'
                                value={1}
                              >
                                <Field
                                  name='company_id'
                                  noLabel
                                  component={ResourceSelect}
                                  isDisabled={disabledUserSection}
                                  schema={companySchema}
                                  onChange={handleCompanyChange}
                                  moreOptions={[{ name: 'ADD NEW COMPANY', id: 'add' }]}
                                  styles={{
                                    valueContainer: (base) => ({
                                      ...base,
                                      height: 60,
                                      fontSize: 16
                                    })
                                  }}
                                  components={{
                                    Option: ({ data, ...rest }) => (
                                      <components.Option {...rest}>
                                        {data.name === 'ADD NEW COMPANY' ? (
                                          <ListItem button>
                                            <ListItemIcon>
                                              <AddCircle />
                                            </ListItemIcon>
                                            <ListItemText primary={data.name} />
                                          </ListItem>
                                        ) : (
                                          <Item
                                            name={
                                              <>
                                                {data.name}{' '}
                                                <Tag
                                                  value={data.state}
                                                  formatter={CapitalizeFormatter}
                                                />
                                              </>
                                            }
                                            avatar={data.avatar_url}
                                            avatarProps={{
                                              className: classes.customerAvatar
                                            }}
                                          >
                                            <Chip
                                              icon={
                                                <Phone
                                                  color='disabled'
                                                  fontSize='small'
                                                />
                                              }
                                              label={
                                                <PhoneNumberFormatter
                                                  value={data.phone_number}
                                                />
                                              }
                                              variant='outlined'
                                              className={classes.chip}
                                            />
                                          </Item>
                                        )}
                                      </components.Option>
                                    ),
                                    SingleValue: ({ children, data, ...rest }) => (
                                      <components.SingleValue {...rest}>
                                        <Item name={data.name} avatar={data.avatar_url} />
                                      </components.SingleValue>
                                    )
                                  }}
                                />

                                {companyOperation && (
                                  <>
                                    <BookingCompanyFormSection
                                      attributeScope='company_attributes'
                                      setFieldValue={setFieldValue}
                                      companyAttributes={values.company_attributes}
                                    />
                                  </>
                                )}

                                {values.company_id && (
                                  <Field
                                    key={`company-${values.company_id}`}
                                    name='branch_id'
                                    noLabel
                                    component={ResourceSelect}
                                    isDisabled={disabledUserSection}
                                    parentSchema={companySchema}
                                    parentId={values.company_id}
                                    schema={branchSchema}
                                    onChange={handleBranchChange}
                                    moreOptions={[{ name: 'ADD NEW BRANCH', id: 'add' }]}
                                    styles={{
                                      valueContainer: (base) => ({
                                        ...base,
                                        height: 60,
                                        fontSize: 16
                                      })
                                    }}
                                    components={{
                                      Option: ({ data, ...rest }) => (
                                        <components.Option {...rest}>
                                          <ListItem button>
                                            {data.name === 'ADD NEW BRANCH' && (
                                              <ListItemIcon>
                                                <AddCircle />
                                              </ListItemIcon>
                                            )}
                                            <ListItemText primary={data.name} />
                                          </ListItem>
                                        </components.Option>
                                      ),
                                      SingleValue: ({ children, data, ...rest }) => (
                                        <components.SingleValue {...rest}>
                                          <Item name={data.name} />
                                        </components.SingleValue>
                                      )
                                    }}
                                  />
                                )}
                                {branchOperation && (
                                  <BookingBranchFromSection attributeScope='branch_attributes' />
                                )}

                                {(branchOperation || values.branch_id) && (
                                  <>
                                    {customerOperation === 'show' ? (
                                      <Field
                                        name='customer_id'
                                        key={`${values.company_id}-${values.customer_id}`}
                                        noLabel
                                        component={ResourceSelect}
                                        isDisabled={disabledUserSection}
                                        schema={customerSchema}
                                        moreOptions={[
                                          { display_name: 'ADD NEW CUSTOMER', id: 'add' }
                                        ]}
                                        onChange={handleCustomerChange}
                                        styles={{
                                          valueContainer: (base) => ({
                                            ...base,
                                            height: 60,
                                            fontSize: 16
                                          })
                                        }}
                                        components={{
                                          Option: ({ data, ...rest }) => (
                                            <components.Option {...rest}>
                                              {data.display_name ===
                                              'ADD NEW CUSTOMER' ? (
                                                <ListItem button>
                                                  <ListItemIcon>
                                                    <AddCircle />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary={data.display_name}
                                                  />
                                                </ListItem>
                                              ) : (
                                                <Item
                                                  name={`${data.display_name} ${
                                                    data.company
                                                      ? `(${t('form.company.title')}: ${
                                                          data.company.name
                                                        })`
                                                      : ''
                                                  }`}
                                                  avatar={data.avatar_url}
                                                  avatarProps={{
                                                    className: classes.customerAvatar
                                                  }}
                                                >
                                                  <Chip
                                                    icon={
                                                      <MailOutline
                                                        color='disabled'
                                                        fontSize='small'
                                                      />
                                                    }
                                                    label={data.email}
                                                    variant='outlined'
                                                    className={classes.chip}
                                                  />

                                                  <Chip
                                                    icon={
                                                      <Phone
                                                        color='disabled'
                                                        fontSize='small'
                                                      />
                                                    }
                                                    label={
                                                      <PhoneNumberFormatter
                                                        value={data.phone_number}
                                                      />
                                                    }
                                                    variant='outlined'
                                                    className={classes.chip}
                                                  />
                                                </Item>
                                              )}
                                            </components.Option>
                                          ),
                                          SingleValue: ({ children, data, ...rest }) => (
                                            <components.SingleValue {...rest}>
                                              <Item
                                                name={data.display_name}
                                                avatar={data.avatar_url}
                                              >
                                                {data.company
                                                  ? `(${data.company.name})`
                                                  : ''}
                                              </Item>
                                            </components.SingleValue>
                                          )
                                        }}
                                      />
                                    ) : (
                                      <Field
                                        name='customer_id'
                                        key={values.branch_id}
                                        noLabel
                                        component={ResourceSelect}
                                        isDisabled={disabledUserSection}
                                        parentSchema={branchSchema}
                                        parentId={values.branch_id}
                                        schema={customerSchema}
                                        onChange={handleCustomerChange}
                                        moreOptions={[
                                          { display_name: 'ADD NEW CUSTOMER', id: 'add' }
                                        ]}
                                        lastOptions={selectCustomer}
                                        styles={{
                                          valueContainer: (base) => ({
                                            ...base,
                                            height: 60,
                                            fontSize: 16
                                          })
                                        }}
                                        components={{
                                          Option: ({ data, ...rest }) => (
                                            <components.Option {...rest}>
                                              {data.id === 'show' || data.id === 'add' ? (
                                                <ListItem button>
                                                  <ListItemIcon>
                                                    {data.id === 'show' ? (
                                                      <UnfoldMore />
                                                    ) : (
                                                      <AddCircle />
                                                    )}
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary={data.display_name}
                                                  />
                                                </ListItem>
                                              ) : (
                                                <Item
                                                  name={`${data.display_name} ${
                                                    data.company
                                                      ? `(${t('form.company.title')}: ${
                                                          data.company.name
                                                        })`
                                                      : ''
                                                  }`}
                                                  avatar={data.avatar_url}
                                                  avatarProps={{
                                                    className: classes.customerAvatar
                                                  }}
                                                >
                                                  <Chip
                                                    icon={
                                                      <MailOutline
                                                        color='disabled'
                                                        fontSize='small'
                                                      />
                                                    }
                                                    label={data.email}
                                                    variant='outlined'
                                                    className={classes.chip}
                                                  />

                                                  <Chip
                                                    icon={
                                                      <Phone
                                                        color='disabled'
                                                        fontSize='small'
                                                      />
                                                    }
                                                    label={
                                                      <PhoneNumberFormatter
                                                        value={data.phone_number}
                                                      />
                                                    }
                                                    variant='outlined'
                                                    className={classes.chip}
                                                  />
                                                </Item>
                                              )}
                                            </components.Option>
                                          ),
                                          SingleValue: ({ children, data, ...rest }) => (
                                            <components.SingleValue {...rest}>
                                              <Item
                                                name={data.display_name}
                                                avatar={data.avatar_url}
                                              >
                                                {data.company
                                                  ? `(${data.company.name})`
                                                  : ''}
                                              </Item>
                                            </components.SingleValue>
                                          )
                                        }}
                                      />
                                    )}

                                    {customerOperation === 'add' && (
                                      <BookingCustomerFormSection
                                        attributeScope='customer_attributes'
                                        key={values.company_id}
                                      />
                                    )}
                                  </>
                                )}
                              </TabPane>
                            </Tabs>
                          </div>

                          <Panel title={t('form.additional_information.title')}>
                            <div className={classes.fieldContainer}>
                              <FastField
                                name='additional_information'
                                component={TextareaAutosize}
                              />

                              <Field
                                name='attachments_attributes'
                                component={DropzoneInput}
                                isClearable
                                isMulti
                              />
                            </div>
                          </Panel>

                          <Panel title={t('form.remarks_for_provider')}>
                            <div className={classes.fieldContainer}>
                              <FastField name='remarks_for_provider' noLabel />
                            </div>
                          </Panel>

                          {routePrice !== null && (
                            <RequestPricingSection
                              ordersAttributes={ordersAttributes}
                              lineItems={lineItems}
                              routePrice={routePrice}
                            />
                          )}

                          <ButtonLoader
                            type='submit'
                            variant='contained'
                            color='primary'
                            style={{ float: 'right' }}
                            isLoading={isSubmitting}
                            className={classes.button}
                          >
                            {t('save')}
                          </ButtonLoader>
                        </>
                      ) : (
                        <>
                          <Panel title={t('start')}>
                            <AddressFormSection
                              name='origin_attributes'
                              key='origin_attributes'
                            />
                          </Panel>
                          {values.origin_attributes && (
                            <Panel title={t('destination')}>
                              <AddressFormSection
                                name='destination_attributes'
                                key='destination_attributes'
                              />
                            </Panel>
                          )}

                          <ButtonLoader
                            variant='contained'
                            color='primary'
                            style={{ float: 'right', marginTop: 8 }}
                            className={classes.button}
                            isLoading={createRouteLoading}
                            onClick={() => handleCreateRoute()}
                          >
                            {t('create_route')}
                          </ButtonLoader>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Panel
                        title={t('form.date_time.title')}
                        actions={
                          service.get('slot_enabled') ? null : <TimePickerToggleButton />
                        }
                        className={classes.customerSection}
                      >
                        <RequestJobsFormSection
                          name='jobs_attributes'
                          timePickerType={timePickerType}
                          readOnly={disabledJobsSection}
                          referenceJobs={showReferenceValues && inquiry.get('jobs')}
                          slotEnabled={service.get('slot_enabled')}
                          serviceId={service.get('id')}
                        />
                      </Panel>

                      <div className={classes.customerSection}>
                        <Tabs
                          onChange={handleCompanyTabChange}
                          contained
                          scrollButtons='off'
                          classes={{ flexContainer: classes.tabs }}
                          currentTab={tabValue}
                        >
                          <TabPane label={t('individual')} className={classes.tab}>
                            <Field
                              name='customer_id'
                              key={`${values.company_id}-${values.customer_id}`}
                              noLabel
                              component={ResourceSelect}
                              isDisabled={disabledUserSection}
                              schema={customerSchema}
                              moreOptions={[
                                { display_name: 'ADD NEW CUSTOMER', id: 'add' }
                              ]}
                              onChange={handleCustomerChange}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  height: 60,
                                  fontSize: 16
                                })
                              }}
                              components={{
                                Option: ({ data, ...rest }) => (
                                  <components.Option {...rest}>
                                    {data.display_name === 'ADD NEW CUSTOMER' ? (
                                      <ListItem button>
                                        <ListItemIcon>
                                          <AddCircle />
                                        </ListItemIcon>
                                        <ListItemText primary={data.display_name} />
                                      </ListItem>
                                    ) : (
                                      <Item
                                        name={`${data.display_name} ${
                                          data.company
                                            ? `(${t('form.company.title')}: ${
                                                data.company.name
                                              })`
                                            : ''
                                        }`}
                                        avatar={data.avatar_url}
                                        avatarProps={{
                                          className: classes.customerAvatar
                                        }}
                                      >
                                        <Chip
                                          icon={
                                            <MailOutline
                                              color='disabled'
                                              fontSize='small'
                                            />
                                          }
                                          label={data.email}
                                          variant='outlined'
                                          className={classes.chip}
                                        />

                                        <Chip
                                          icon={
                                            <Phone color='disabled' fontSize='small' />
                                          }
                                          label={
                                            <PhoneNumberFormatter
                                              value={data.phone_number}
                                            />
                                          }
                                          variant='outlined'
                                          className={classes.chip}
                                        />
                                      </Item>
                                    )}
                                  </components.Option>
                                ),
                                SingleValue: ({ children, data, ...rest }) => (
                                  <components.SingleValue {...rest}>
                                    <Item
                                      name={data.display_name}
                                      avatar={data.avatar_url}
                                    >
                                      {data.company ? `(${data.company.name})` : ''}
                                    </Item>
                                  </components.SingleValue>
                                )
                              }}
                            />

                            {customerOperation && (
                              <BookingCustomerFormSection
                                attributeScope='customer_attributes'
                                key={values.company_id}
                              />
                            )}
                          </TabPane>
                          <TabPane
                            label={t('organization')}
                            className={classes.tab}
                            id='tab-1'
                            value={1}
                          >
                            <Field
                              name='company_id'
                              noLabel
                              component={ResourceSelect}
                              isDisabled={disabledUserSection}
                              schema={companySchema}
                              onChange={handleCompanyChange}
                              moreOptions={[{ name: 'ADD NEW COMPANY', id: 'add' }]}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  height: 60,
                                  fontSize: 16
                                })
                              }}
                              components={{
                                Option: ({ data, ...rest }) => (
                                  <components.Option {...rest}>
                                    {data.name === 'ADD NEW COMPANY' ? (
                                      <ListItem button>
                                        <ListItemIcon>
                                          <AddCircle />
                                        </ListItemIcon>
                                        <ListItemText primary={data.name} />
                                      </ListItem>
                                    ) : (
                                      <Item
                                        name={
                                          <>
                                            {data.name}{' '}
                                            <Tag
                                              value={data.state}
                                              formatter={CapitalizeFormatter}
                                            />
                                          </>
                                        }
                                        avatar={data.avatar_url}
                                        avatarProps={{
                                          className: classes.customerAvatar
                                        }}
                                      >
                                        <Chip
                                          icon={
                                            <Phone color='disabled' fontSize='small' />
                                          }
                                          label={
                                            <PhoneNumberFormatter
                                              value={data.phone_number}
                                            />
                                          }
                                          variant='outlined'
                                          className={classes.chip}
                                        />
                                      </Item>
                                    )}
                                  </components.Option>
                                ),
                                SingleValue: ({ children, data, ...rest }) => (
                                  <components.SingleValue {...rest}>
                                    <Item name={data.name} avatar={data.avatar_url} />
                                  </components.SingleValue>
                                )
                              }}
                            />

                            {companyOperation && (
                              <>
                                <BookingCompanyFormSection
                                  attributeScope='company_attributes'
                                  setFieldValue={setFieldValue}
                                  companyAttributes={values.company_attributes}
                                />
                              </>
                            )}

                            {values.company_id && (
                              <Field
                                key={`company-${values.company_id}`}
                                name='branch_id'
                                noLabel
                                component={ResourceSelect}
                                isDisabled={disabledUserSection}
                                parentSchema={companySchema}
                                parentId={values.company_id}
                                schema={branchSchema}
                                onChange={handleBranchChange}
                                moreOptions={[{ name: 'ADD NEW BRANCH', id: 'add' }]}
                                styles={{
                                  valueContainer: (base) => ({
                                    ...base,
                                    height: 60,
                                    fontSize: 16
                                  })
                                }}
                                components={{
                                  Option: ({ data, ...rest }) => (
                                    <components.Option {...rest}>
                                      <ListItem button>
                                        {data.name === 'ADD NEW BRANCH' && (
                                          <ListItemIcon>
                                            <AddCircle />
                                          </ListItemIcon>
                                        )}
                                        <ListItemText primary={data.name} />
                                      </ListItem>
                                    </components.Option>
                                  ),
                                  SingleValue: ({ children, data, ...rest }) => (
                                    <components.SingleValue {...rest}>
                                      <Item name={data.name} />
                                    </components.SingleValue>
                                  )
                                }}
                              />
                            )}
                            {branchOperation && (
                              <BookingBranchFromSection attributeScope='branch_attributes' />
                            )}

                            {(branchOperation || values.branch_id) && (
                              <>
                                {customerOperation === 'show' ? (
                                  <Field
                                    name='customer_id'
                                    key={`${values.company_id}-${values.customer_id}`}
                                    noLabel
                                    component={ResourceSelect}
                                    isDisabled={disabledUserSection}
                                    schema={customerSchema}
                                    moreOptions={[
                                      { display_name: 'ADD NEW CUSTOMER', id: 'add' }
                                    ]}
                                    onChange={handleCustomerChange}
                                    styles={{
                                      valueContainer: (base) => ({
                                        ...base,
                                        height: 60,
                                        fontSize: 16
                                      })
                                    }}
                                    components={{
                                      Option: ({ data, ...rest }) => (
                                        <components.Option {...rest}>
                                          {data.display_name === 'ADD NEW CUSTOMER' ? (
                                            <ListItem button>
                                              <ListItemIcon>
                                                <AddCircle />
                                              </ListItemIcon>
                                              <ListItemText primary={data.display_name} />
                                            </ListItem>
                                          ) : (
                                            <Item
                                              name={`${data.display_name} ${
                                                data.company
                                                  ? `(${t('form.company.title')}: ${
                                                      data.company.name
                                                    })`
                                                  : ''
                                              }`}
                                              avatar={data.avatar_url}
                                              avatarProps={{
                                                className: classes.customerAvatar
                                              }}
                                            >
                                              <Chip
                                                icon={
                                                  <MailOutline
                                                    color='disabled'
                                                    fontSize='small'
                                                  />
                                                }
                                                label={data.email}
                                                variant='outlined'
                                                className={classes.chip}
                                              />

                                              <Chip
                                                icon={
                                                  <Phone
                                                    color='disabled'
                                                    fontSize='small'
                                                  />
                                                }
                                                label={
                                                  <PhoneNumberFormatter
                                                    value={data.phone_number}
                                                  />
                                                }
                                                variant='outlined'
                                                className={classes.chip}
                                              />
                                            </Item>
                                          )}
                                        </components.Option>
                                      ),
                                      SingleValue: ({ children, data, ...rest }) => (
                                        <components.SingleValue {...rest}>
                                          <Item
                                            name={data.display_name}
                                            avatar={data.avatar_url}
                                          >
                                            {data.company ? `(${data.company.name})` : ''}
                                          </Item>
                                        </components.SingleValue>
                                      )
                                    }}
                                  />
                                ) : (
                                  <Field
                                    name='customer_id'
                                    key={values.branch_id}
                                    noLabel
                                    component={ResourceSelect}
                                    isDisabled={disabledUserSection}
                                    parentSchema={branchSchema}
                                    parentId={values.branch_id}
                                    schema={customerSchema}
                                    onChange={handleCustomerChange}
                                    moreOptions={[
                                      { display_name: 'ADD NEW CUSTOMER', id: 'add' }
                                    ]}
                                    lastOptions={selectCustomer}
                                    styles={{
                                      valueContainer: (base) => ({
                                        ...base,
                                        height: 60,
                                        fontSize: 16
                                      })
                                    }}
                                    components={{
                                      Option: ({ data, ...rest }) => (
                                        <components.Option {...rest}>
                                          {data.id === 'show' || data.id === 'add' ? (
                                            <ListItem button>
                                              <ListItemIcon>
                                                {data.id === 'show' ? (
                                                  <UnfoldMore />
                                                ) : (
                                                  <AddCircle />
                                                )}
                                              </ListItemIcon>
                                              <ListItemText primary={data.display_name} />
                                            </ListItem>
                                          ) : (
                                            <Item
                                              name={`${data.display_name} ${
                                                data.company
                                                  ? `(${t('form.company.title')}: ${
                                                      data.company.name
                                                    })`
                                                  : ''
                                              }`}
                                              avatar={data.avatar_url}
                                              avatarProps={{
                                                className: classes.customerAvatar
                                              }}
                                            >
                                              <Chip
                                                icon={
                                                  <MailOutline
                                                    color='disabled'
                                                    fontSize='small'
                                                  />
                                                }
                                                label={data.email}
                                                variant='outlined'
                                                className={classes.chip}
                                              />

                                              <Chip
                                                icon={
                                                  <Phone
                                                    color='disabled'
                                                    fontSize='small'
                                                  />
                                                }
                                                label={
                                                  <PhoneNumberFormatter
                                                    value={data.phone_number}
                                                  />
                                                }
                                                variant='outlined'
                                                className={classes.chip}
                                              />
                                            </Item>
                                          )}
                                        </components.Option>
                                      ),
                                      SingleValue: ({ children, data, ...rest }) => (
                                        <components.SingleValue {...rest}>
                                          <Item
                                            name={data.display_name}
                                            avatar={data.avatar_url}
                                          >
                                            {data.company ? `(${data.company.name})` : ''}
                                          </Item>
                                        </components.SingleValue>
                                      )
                                    }}
                                  />
                                )}

                                {customerOperation === 'add' && (
                                  <BookingCustomerFormSection
                                    attributeScope='customer_attributes'
                                    key={values.company_id}
                                  />
                                )}
                              </>
                            )}
                          </TabPane>
                        </Tabs>
                      </div>

                      <Panel title={t('form.location.title')}>
                        <div className={classes.fieldContainer}>
                          {values.company_id ? (
                            <>
                              <label>{t('form.location.company_address')}</label>

                              <ResourceSelect
                                key={`company-${values.company_id}`}
                                schema={addressSchema}
                                parentSchema={companySchema}
                                parentId={values.company_id}
                                getOptionLabel={({ short_address: shortAddress }) =>
                                  shortAddress
                                }
                                onChange={handleAddressChange}
                              />
                            </>
                          ) : (
                            <>
                              <label>{t('form.location.customer_address')}</label>

                              <ResourceSelect
                                key={`customer-${values.customer_id}`}
                                schema={addressSchema}
                                parentSchema={customerSchema}
                                parentId={values.customer_id}
                                getOptionLabel={({ short_address: shortAddress }) =>
                                  shortAddress
                                }
                                onChange={handleAddressChange}
                              />
                            </>
                          )}
                        </div>
                        <br />

                        <AddressFormSection
                          name='location_attributes'
                          key={addressFormSectionInstanceId}
                        />

                        <FormControlLabel
                          label={
                            values.company_id
                              ? t('form.location.remember_company_address')
                              : t('form.location.remember_address')
                          }
                          className={classes.labelContainer}
                          control={
                            <Checkbox
                              checked={formState.rememberAddress}
                              onChange={handleRememberAddress}
                              color='primary'
                            />
                          }
                        />
                      </Panel>

                      <Panel title={t('form.additional_information.title')}>
                        <div className={classes.fieldContainer}>
                          <FastField
                            name='additional_information'
                            component={TextareaAutosize}
                          />

                          <Field
                            name='attachments_attributes'
                            component={DropzoneInput}
                            isClearable
                            isMulti
                          />
                        </div>
                      </Panel>

                      <Panel title={t('form.remarks_for_provider')}>
                        <div className={classes.fieldContainer}>
                          <FastField name='remarks_for_provider' noLabel />
                        </div>
                      </Panel>

                      <ButtonLoader
                        type='submit'
                        variant='contained'
                        color='primary'
                        style={{ float: 'right' }}
                        isLoading={isSubmitting}
                        className={classes.button}
                      >
                        {t('save')}
                      </ButtonLoader>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Form>
  )
}

InquiryForm.propTypes = {
  inquiry: ImmutablePropTypes.map,
  disabledJobsSection: PropTypes.bool,
  disabledOrdersSection: PropTypes.bool,
  disabledUserSection: PropTypes.bool,
  setFieldValue: PropTypes.func,
  setValues: PropTypes.func,
  setFormState: PropTypes.func,
  values: PropTypes.object,
  formState: PropTypes.object,
  fetchAddress: PropTypes.func,
  setFlashMessage: PropTypes.func,
  showReferenceValues: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  classes: PropTypes.object,
  getLineItems: PropTypes.func,
  t: PropTypes.func
}

InquiryForm.defaultProps = {
  inquiry: Map()
}

export default compose(
  withStyles(styles),
  withTranslation(['inquiries', 'common'])
)(InquiryForm)
