import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import { compose } from 'redux'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { withMobileDialog } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import Panel from 'components/containers/Panel'
import CircularLoader from 'components/CircularLoader'
import ProvidersList from 'components/lists/ProvidersList'

import styles from './AssignProvidersListPanelStyles'
import { callService } from 'utils/callService'
import Search from 'components/Search'
import ProviderSearch from './components/ProviderSearch'

export const AssignProvidersListPanelContext = React.createContext({ providers: [] })

export function AssignProvidersListPanel({
  fetchAvailableProviders,
  getAvailableProviders,
  match,
  classes,
  inquiry,
  fullScreen: isMobile,
  query,
  t
}) {
  // const { loading } = useData(() =>
  //   fetchAvailableProviders(match.params.id, { page: query.page, query })
  //   , [query.search])

  const [loading, setLoading] = React.useState(true)
  const [providers, setProviders] = React.useState([])
  const [search, setSearch] = React.useState('')

  React.useEffect(() => {
    const fetchResults = async () => {
      setLoading(true)
      try {
        const response = await callService.get(`${process.env.REACT_APP_BASE_API}/inquiries/${match.params.id}/potential_providers?query%5Bfirst_name_or_last_name_or_phone_number_or_email_or_alias_or_tags_name_cont%5D=${search}`);
        setProviders(response.data);
        setLoading(false)
      } catch (error) {
        console.error(error);
        setProviders([]);
      }
    };

    fetchResults();
  }, [search]);

  const useStyles = makeStyles({
    containerWithPadding: {
      margin: '20px'
    }
  })

  const styles = useStyles()

  return (
    <Panel
      title={t('title')}
      responsive
      noPadding
      className={styles.containerWithPadding}
    >
      <AssignProvidersListPanelContext.Provider value={{ providers, setProviders, search, setSearch }}>
        <div className={classes.searchContainer}>
          <ProviderSearch />
        </div>

        {
          loading
            ? <CircularLoader />
            : <ProvidersList match={match} inquiry={inquiry} />
        }
      </AssignProvidersListPanelContext.Provider>
    </Panel>
  )
}

AssignProvidersListPanel.propTypes = {
  inquiry: ImmutablePropTypes.map,
  fullScreen: PropTypes.bool,
  fetchAvailableProviders: PropTypes.func,
  getAvailableProviders: PropTypes.func,
  match: PropTypes.object,
  classes: PropTypes.object,
  query: PropTypes.object,

  t: PropTypes.func
}

AssignProvidersListPanel.defaultProps = {
  inquiry: Map()
}

export default compose(
  withStyles(styles),
  withMobileDialog(),
  withTranslation('assign_providers')
)(AssignProvidersListPanel)
