import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogContent, useMediaQuery } from '@material-ui/core'
import { FilterList } from '@material-ui/icons'

import theme from 'utils/theme'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  button: {
    height: '48px'
  },
  label: {
    [breakpoints.down('sm')]: {
      display: 'none'
    }
  },

  icon: {
    [breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}))

export function FilterModal({ icon, component: Component, ...props }) {
  const [modalOpen, setModalOpen] = useState(false)
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  function handleOpenModal() {
    setModalOpen(true)
  }

  function handleCloseModal() {
    setModalOpen(false)
  }

  return (
    <>
      {icon ? (
        <Button onClick={handleOpenModal}>
          <FilterList />
        </Button>
      ) : (
        <Button
          onClick={handleOpenModal}
          variant='outlined'
          color='primary'
          fullWidth
          className={classes.button}
        >
          <div className={classes.label}>Filters</div>
          <FilterList className={classes.icon} />
        </Button>
      )}

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        fullScreen={fullScreen}
        fullWidth
      >
        <DialogContent>
          <Component onCloseModal={handleCloseModal} {...props} />
        </DialogContent>
      </Dialog>
    </>
  )
}

FilterModal.propTypes = {
  component: PropTypes.func.isRequired,
  icon: PropTypes.bool
}

export default FilterModal
