import PropTypes from 'prop-types'
import { capitalize } from 'lodash'

export function CapitalizeFormatter ({
  value
}) {
  return capitalize(value)
}

CapitalizeFormatter.propTypes = {
  value: PropTypes.string.isRequired
}

export default CapitalizeFormatter
