import { connect } from 'react-redux'

import { fetch as fetchCollection, fetchChildren } from 'actions/collections'
import { fetch } from 'actions/resources'

import ResourceSelect from './ResourceSelect'

const mapDispatchToProps = {
  fetchOptions: (schema, query, parentSchema, parentId) => {
    if (parentSchema && parentId) {
      return fetchChildren(parentSchema, parentId, schema, {
        query: {
          search: query
        },
        queryParam: {
          disable_paginate: true
        }
      })
    } else {
      return fetchCollection(schema, {
        query: {
          search: query
        },
        queryParam: {
          disable_paginate: true
        }
      })
    }
  },
  fetchInitialValueWithIds: (schema, parentSchema, parentId, ids) => {
    if (parentSchema && parentId) {
      return fetchChildren(parentSchema, parentId, schema, {
        queryParam: {
          query: {
            'id_in][': ids
          }
        }
      })
    } else {
      return fetchCollection(schema, {
        queryParam: {
          query: {
            'id_in][': ids
          }
        }
      })
    }
  },
  fetchResource: (id, schema, options) => fetch(id, schema, options)
}

export default connect(null, mapDispatchToProps)(ResourceSelect)
