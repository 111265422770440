import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classNames from 'clsx'
import { List } from 'immutable'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { Phone } from '@material-ui/icons'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import { Table, Column } from 'components/tables/Table'
import Tag from 'components/Tag'
import {
  PhoneNumberFormatter,
  DateTimeFormatter,
  DateFormatter
} from 'components/formatters'

import styles from './RequestsTableStyles'

const subscriptionIcon = 'https://s3-ap-southeast-1.amazonaws.com/seekster-web/assets/images/logo_subscription.png'

export function RequestsTable ({ data, exclude, classes, t }) {
  return (
    <Table collection={data} padding='dense' style={{ fontSize: '0.875rem' }}>
      <Column
        name='number'
        render={(number, request) => (
          <Link className={classes.number} to={`/inquiries/${request.get('id')}`}>
            <span>{number}</span>
            {
              !!request.get('subscription_month') && (
                <img
                  src={subscriptionIcon}
                  alt='Subscription Logo'
                  className={classes.subscriptionIcon}
                />
              )
            }
          </Link>
        )}
      />

      {
        !exclude.customer ? (
          <Column
            name='customer'
            render={customer => (
              <>
                <Link to={`/customers/${customer.get('id')}`}>
                  <Typography>{customer.get('display_name')}</Typography>
                </Link>

                <Typography color='textSecondary' className={classes.phoneNumber}>
                  <Phone className={classes.phoneIcon} />

                  <PhoneNumberFormatter value={customer.get('phone_number')} />
                </Typography>
              </>
            )}
          />
        ) : <div style={{ display: 'none' }} />
      }

      <Column
        name='service'
        columnName={t('orders')}
        render={(service, request) => (
          <>
            {
              !exclude.service && (
                <div className={classes.service}>
                  <Link to={`/services/${service.get('id')}`}>
                    {service.get('name')}
                  </Link>
                  {
                    service.get('regional_slug') && (
                      <Tag
                        value={service.get('regional_slug').split('-').shift()}
                        className={classes.regionTag}
                      />
                    )
                  }
                </div>
              )
            }

            <ul
              className={classNames(classes.orders, {
                [classes.ordersAsSecondary]: !exclude.service
              })}
            >
              {
                request.get('orders', List()).map(order => (
                  <li key={order.get('id')}>
                    {order.get('description')}
                  </li>
                ))
              }
            </ul>
          </>
        )}
      />

      <Column
        name='first_job.start_time'
        columnName={t('booking_date')}
        render={(startTime, request) => {
          if (request.getIn(['first_job', 'id']) === request.getIn(['last_job', 'id'])) {
            return <DateFormatter value={startTime} />
          }
          else {
            const endTime = request.getIn(['last_job', 'end_time'])

            return (
              <>
                <DateFormatter value={startTime} />
                <div className={classes.bookingDatesDivider}>{t('to')}</div>
                <DateFormatter value={endTime} />
              </>
            )
          }
        }}
      />

      <Column
        name='location.short_address'
        render={shortAddress => (
          <Typography className={classes.address}>
            {shortAddress}
          </Typography>
        )}
      />

      <Column
        name='state'
        render={state => <Tag value={state} className='inquiry-states' />}
      />

      <Column name='created_at' component={DateTimeFormatter} />
    </Table>
  )
}

RequestsTable.propTypes = {
  data: ImmutablePropTypes.list,
  exclude: PropTypes.object,
  classes: PropTypes.object,

  t: PropTypes.func
}

RequestsTable.defaultProps = {
  exclude: {
    customer: false,
    service: false
  }
}

export default compose(
  withStyles(styles),
  withTranslation(['inquiries', 'requests', 'attributes'])
)(RequestsTable)
