import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import { useCollectionChildren } from 'hooks/collections'
import { companySchema, inquirySchema } from '@seekster/schemas'

import InquiriesTable from 'components/tables/InquiriesTable'
import CircularLoader from 'components/CircularLoader'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'

export function CompanyInquiriesPane ({ match, query }) {
  const [inquiries, { loading, totalCount, totalPages }] = useCollectionChildren(
    companySchema,
    match.params.id,
    inquirySchema,
    { page: query.page },
    [query.page]
  )

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ResultsCountLabel value={totalCount} unit='inquiry' />
      </Grid>

      {
        loading ? (
          <Grid item xs={12}>
            <CircularLoader />
          </Grid>
        ) : (
          !inquiries.isEmpty() && (
            <>
              <Grid item xs={12}>
                <InquiriesTable inquiries={inquiries} />
              </Grid>

              <Grid item xs={12} container justify='center'>
                <Pagination totalPages={totalPages} />
              </Grid>
            </>
          )
        )
      }
    </Grid>
  )
}

CompanyInquiriesPane.propTypes = {
  match: PropTypes.object,
  query: PropTypes.object
}

export default CompanyInquiriesPane
