export default (theme) => ({
  container: {
    padding: theme.spacing(4)
  },
  panelShadow: {
    boxShadow: '0 2px 10px 0 rgba(182,182,182,0.4)'
  },
  paper: {
    padding: '34px 55px 96px'
  },
  button: {
    margin: '80px 0 0 0',
    padding: '6px 40px'
  },
  subtitle: {
    margin: '0 0 15px 0'
  }
})
