import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import Field from 'components/fields/Field'

import styles from './TenantMarketingConfigFormStyles'

export function TenantMarketingConfigForm({
  marketingConfig,
  resetForm,
  dirty,
  classes,
  t
}) {
  return (
    <Form>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Field
              name='customer_referral_reward_amount_satangs'
              type='number'
              step={0.01}
              hint={marketingConfig.getIn([
                'customer_referral_reward_amount',
                'currency'
              ])}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              name='customer_referral_discount_amount_satangs'
              type='number'
              step={0.01}
              hint={marketingConfig.getIn([
                'customer_referral_discount_amount',
                'currency'
              ])}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              name='provider_referral_reward_amount_satangs'
              type='number'
              step={0.01}
              hint={marketingConfig.getIn([
                'customer_referral_reward_amount',
                'currency'
              ])}
            />
          </Grid>

          {dirty && (
            <Grid item xs={12} container justify='flex-end'>
              <Button
                variant='contained'
                onClick={() => resetForm()}
                className={classes.button}
              >
                {t('cancel')}
              </Button>

              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={classes.button}
              >
                {t('save')}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </Form>
  )
}

TenantMarketingConfigForm.propTypes = {
  marketingConfig: ImmutablePropTypes.map,
  resetForm: PropTypes.func,
  classes: PropTypes.object,
  dirty: PropTypes.bool,

  t: PropTypes.func
}

TenantMarketingConfigForm.defaultProps = {
  marketingConfig: Map()
}

export default compose(
  withStyles(styles),
  withTranslation('actions')
)(TenantMarketingConfigForm)
