import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Button } from 'components/buttons'
import { withTranslation } from 'react-i18next'

import FastField from 'components/fields/Field'
import DateRangeField from 'components/fields/DateRangeField'
import MoneyRangeField from 'components/fields/MoneyRangeField'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'
import { PAYMENT_STATES } from 'constants/states/paymentStates'

export function PaymentsFilterFrom({ handleSubmit, onCloseModal, t }) {
  return (
    <Form>
      <DateRangeField name='created_at' />
      <MoneyRangeField name='amount_satangs' defaultCurrency='THB' />
      <FastField
        name='state'
        component={SelectInput}
        options={arrayToOptions(PAYMENT_STATES, {
          i18nNamespace: 'invoice',
          i18nKey: 'states'
        })}
      />

      <div>
        <Button color='primary' onClick={handleSubmit}>
          {t('apply')}
        </Button>

        <Button onClick={onCloseModal}>{t('cancel')}</Button>
      </div>
    </Form>
  )
}

PaymentsFilterFrom.propTypes = {
  handleSubmit: PropTypes.func,
  onCloseModal: PropTypes.func,
  t: PropTypes.func
}

export default withTranslation('filters')(PaymentsFilterFrom)
