import { Map, List } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'

import ProviderForm from './ProviderForm'

const formikConfig = {
  mapPropsToValues: ({ provider = Map() }) => ({
    first_name: provider.get('first_name') || '',
    last_name: provider.get('last_name') || '',
    alias: provider.get('alias') || '',
    gender: provider.get('gender') || '',
    email: provider.get('email') || '',
    phone_number: provider.get('phone_number') || '',
    date_of_birth: provider.get('date_of_birth') || '',
    date_of_start: provider.get('date_of_start') || '',
    lastest_covid_result_date: provider.get('lastest_covid_result_date') || '',
    disable_leads: provider.get('disable_leads'),
    disable_money: provider.get('disable_money'),
    badge_ids: provider
      .get('badges', List())
      .map((badge) => badge.get('id'))
      .toJS(),
    service_ids: provider
      .get('services', List())
      .map((service) => service.get('id'))
      .toJS(),
    tag_ids: provider
      .get('tags', List())
      .map((tag) => tag.get('id'))
      .toJS(),
    area_ids: provider
      .get('areas', List())
      .map((area) => area.get('id'))
      .toJS()
  }),

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string(),
    email: Yup.string().email(),
    phone_number: Yup.string().matches(/(^0[23456789]\d{7,8}$)/, 'Invalid phone number')
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    // alert(JSON.stringify(values, null, 4))
    // setSubmitting(false)
    props
      .onSubmit(values)
      .then((response) => {
        props.push(`/providers/${response.body.id}`)
      })
      .catch((error) => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(withFlashMessage, withFormik(formikConfig))(ProviderForm)
