import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Map } from 'immutable'

import { setAppConfig, loadAppConfigs } from 'actions/configurations'
import { getAppConfigs } from 'selectors/configurations'

export const useAppConfigs = (effectDependencies = []) => {
  const dispatch = useDispatch()
  const configs = useSelector(state => getAppConfigs(state) || Map()).toJS()

  useEffect(() => {
    dispatch(loadAppConfigs())
  }, [...effectDependencies]) // eslint-disable-line react-hooks/exhaustive-deps

  const setConfig = config => dispatch(setAppConfig(config))

  return [configs, setConfig]
}
