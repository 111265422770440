import React, { cloneElement } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import { fromJS } from 'immutable'

import CircularLoader from 'components/CircularLoader'
import styles from './TableStyles'

function SelectedTable({
  classes,
  collection,
  schema,
  selectable,
  selectedValue,
  setFieldValue,
  loading,
  onSelect,
  ...rest
}) {
  const filteredChildren = rest.children.filter((child) => child instanceof Object)

  function checkSelected(id) {
    return (
      selectedValue.findIndex((item) => item.id === id || item.wfUserId === id) !== -1
    )
  }

  return (
    <div>
      <TableContainer>
        <Table className={classes.root} {...rest}>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align='center' colSpan={10}>
                  <CircularLoader />
                </TableCell>
              </TableRow>
            ) : (
              fromJS(collection).map((resource, index) => {
                const isItemSelected = checkSelected(resource.get('id'))

                return (
                  <TableRow
                    hover
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={resource.get('id')}
                    selected={isItemSelected}
                  >
                    {selectable && (
                      <TableCell padding='checkbox'>
                        <Checkbox
                          onClick={(event) => onSelect(event, resource)}
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': `enhanced-table-checkbox-${index}`
                          }}
                        />
                      </TableCell>
                    )}
                    {React.Children.map(filteredChildren, (child) => {
                      return cloneElement(child, {
                        resource,
                        className: classes.attributeValue,
                        index
                      })
                    })}
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default withStyles(styles)(SelectedTable)
