export default theme => ({
  error: {
    backgroundColor: '#d32f2ff0',
    maxWidth: '100%',
    width: 600,
    margin: 16
  },
  success: {
    backgroundColor: theme.palette.green,
    maxWidth: '100%',
    width: 600,
    margin: 16
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
})
