import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import UserLoginMethods from 'components/UserLoginMethods'
import UserDevices from 'components/UserDevices'

import { agentSchema } from '@seekster/schemas'

export function AgentTechPane ({ agent, match, t }) {
  return (
    <Fragment>
      <Panel title={t('login_methods')}>
        <UserLoginMethods schema={agentSchema} id={agent.get('id') || match.params.id} />
      </Panel>

      <Panel title={t('devices')}>
        <UserDevices schema={agentSchema} id={agent.get('id') || match.params.id} />
      </Panel>
    </Fragment>
  )
}

AgentTechPane.propTypes = {
  agent: ImmutablePropTypes.map,
  match: PropTypes.object,

  t: PropTypes.func
}

AgentTechPane.defaultProps = {
  agent: Map()
}

export default withTranslation()(AgentTechPane)
