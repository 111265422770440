import React from 'react'
import { Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useResource } from 'hooks/resources'

import { jobSchema } from '@seekster/schemas'

import AuthenticatedRoute from 'components/AuthenticatedRoute'
import { PageLayout } from 'components/layouts'
import {
  NavigationMenuBar,
  NavigationMenuItem
} from 'components/layouts/NavigationMenuBar'
import Tag from 'components/Tag'
import CapitalizeFormatter from 'components/formatters/CapitalizeFormatter'

import SLAPendingJobOverviewPane from './panes/SLAPendingJobOverviewPane'

const SLAPendingJobDetailPage = ({ match, location }) => {
  const { t } = useTranslation(['sla_pending_start', 'common'])
  const [job, { loading }] = useResource(jobSchema, match.params.id)

  function renderSubNavbar() {
    const path = `/sla_pending_start/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('navbar.overview')} to={path} replace />
      </NavigationMenuBar>
    )
  }

  function renderSubtitle() {
    return (
      <Tag
        value={job.get('state')}
        className='job-states'
        formatter={CapitalizeFormatter}
      />
    )
  }

  return (
    <PageLayout
      title={job.get('number')}
      subtitle={renderSubtitle()}
      subNavbar={renderSubNavbar()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute
          path='/sla_pending_start/:id'
          component={SLAPendingJobOverviewPane}
          exact
        />
      </Switch>
    </PageLayout>
  )
}

export default SLAPendingJobDetailPage
