import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'

import Comments from 'modules/comments/Comments'

export class JobCommentsPane extends React.Component {
  static propTypes = {
    comments: ImmutablePropTypes.list,
    job: ImmutablePropTypes.map,
    match: PropTypes.object,
    loadComments: PropTypes.func,
    create: PropTypes.func
  }

  static defaultProps = {
    comments: List(),
    job: Map()
  }

  render () {
    const comments = this.props.job.get('comments')

    return (
      <Comments id={this.props.match.params.id}
        loadComments={this.props.loadComments}
        create={this.props.create}
        comments={comments}
      />
    )
  }
}

export default JobCommentsPane
