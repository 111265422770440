import { Map } from 'immutable'

import {
  SET_APP_CONFIG_SUCCEEDED,
  LOAD_APP_CONFIGS_SUCCEEDED
} from 'constants/configurations'

const initialState = Map()

const configurationsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case SET_APP_CONFIG_SUCCEEDED:
      const getExistingConfigs = (value) => {
        try {
          return JSON.parse(value)
        }
        catch (e) {
          return {}
        }
      }

      const newConfigs = {
        ...getExistingConfigs(window.localStorage.getItem('appConfigs')),
        ...payload
      }

      window.localStorage.setItem('appConfigs', JSON.stringify(newConfigs))

      return state.merge(payload)

    case LOAD_APP_CONFIGS_SUCCEEDED:
      const configs = window.localStorage.getItem('appConfigs')

      return configs ? state.merge(JSON.parse(configs)) : state

    default:
      return state
  }
}

export default configurationsReducer
