import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './ResourceCard.scss'

export function ResourceCard({
  title,
  avatar,
  resourceLink,
  subtitle,
  primarySupportContent,
  secondarySupportContent,
  actionButtons
}) {
  return (
    <div className='card-container'>
      <div className='main-content'>
        <Link to={resourceLink}>
          <img src={avatar} alt='avatar' />
        </Link>
        <span>
          <div className='title'>
            <Link to={resourceLink}>{title}</Link>
          </div>
          {subtitle}
        </span>
      </div>

      <div className='primary-support-content'>{primarySupportContent}</div>

      <div className='secondary-support-content'>{secondarySupportContent}</div>

      <div className='actions-component'>{actionButtons}</div>
    </div>
  )
}

ResourceCard.propTypes = {
  title: PropTypes.string,
  avatar: PropTypes.string,
  resourceLink: PropTypes.string,
  subtitle: PropTypes.node,
  primarySupportContent: PropTypes.node,
  secondarySupportContent: PropTypes.node,
  actionButtons: PropTypes.node
}

ResourceCard.defaultProps = {
  subtitle: () => null,
  primarySupportContent: () => null,
  secondarySupportContent: () => null,
  actionButtons: () => null
}

export default ResourceCard
