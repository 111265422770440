export default ({ spacing }) => ({
  formActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(3)
  },

  cancelButton: {
    fontWeight: 600
  },

  saveButton: {
    marginLeft: spacing(1)
  }
})
