import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import {
  Grid,
  CircularProgress,
  Typography,
  IconButton,
  withMobileDialog,
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import WalletTransactionList from 'modules/wallets/WalletTransactionList'
import WalletTransactionMenu from 'modules/wallets/WalletTransactionMenu'
import BankAccountList from 'modules/bankAccounts/BankAccountList'
import Panel from 'components/containers/Panel'
import AttributeLabel from 'components/labels/AttributeLabel'
import { DateTimeFormatter } from 'components/formatters'
import Pagination from 'components/Pagination'
import BankAccountForm from 'forms/resources/BankAccountForm'
import { providerSchema } from '@seekster/schemas'

export class ProviderWalletPane extends React.Component {
  static propTypes = {
    provider: ImmutablePropTypes.map,
    query: PropTypes.object,
    match: PropTypes.object,
    fetchWallet: PropTypes.func,
    fetchWalletTransactions: PropTypes.func,
    fetchProviderBankAccounts: PropTypes.func,
    createProviderBankAccount: PropTypes.func,
    fullScreen: PropTypes.bool,
    fetchProviderJobs: PropTypes.func,
    fetchTransactionCodes: PropTypes.func,
    t: PropTypes.func
  }

  static defaultProps = {
    provider: Map()
  }

  constructor(props) {
    super(props)

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleCreateBankAccount = this.handleCreateBankAccount.bind(this)

    this.state = {
      loading: false,
      totalCount: 0,
      totalPages: 1,
      modalOpen: false
    }
  }

  componentDidMount() {
    this.handleUpdateData()

    this.props.fetchProviderBankAccounts(this.props.match.params.id)
  }

  componentDidUpdate(prevProps, prevState) {
    const { query, provider } = this.props

    if (prevProps.query.page !== query.page) {
      this.handleUpdateDataOnPageChange()
    } else if (
      prevProps.provider.get('wallet_transactions', List()).size !==
      provider.get('wallet_transactions', List()).size
    ) {
      this.handleUpdateData()
    }
  }

  handleUpdateData() {
    const { page } = this.props.query

    this.setState({ loading: true })

    this.props.fetchWallet(this.props.match.params.id)

    this.props
      .fetchWalletTransactions(this.props.match.params.id, { page })
      .then((response) => {
        this.setState({
          loading: false,
          totalCount: parseInt(response.headers.total, 10),
          totalPages: Math.ceil(response.headers.total / response.headers['per-page'])
        })
      })
      .catch((error) => {
        this.setState({ loading: false, error: error.message })
      })
  }

  handleUpdateDataOnPageChange() {
    const { page } = this.props.query

    this.setState({ loading: true })

    this.props
      .fetchWalletTransactions(this.props.match.params.id, { page })
      .then(() => {
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false, error: error.message })
      })
  }

  handleOpenModal() {
    this.setState({ modalOpen: true })
  }

  handleCloseModal() {
    this.setState({ modalOpen: false })
  }

  handleCreateBankAccount(data) {
    const { createProviderBankAccount, fetchProviderBankAccounts, match } = this.props

    return createProviderBankAccount(match.params.id, data)
      .then(() => fetchProviderBankAccounts(match.params.id))
      .then(this.handleCloseModal)
  }

  renderWalletTransactionMenu() {
    const menuProps = {
      fetchJobs: this.props.fetchProviderJobs,
      fetchTransactionCodes: this.props.fetchTransactionCodes
    }
    return (
      <WalletTransactionMenu
        resource={this.props.provider}
        parentSchema={providerSchema}
        isProvider
        {...menuProps}
      />
    )
  }

  renderWalletTransactionList() {
    const transactions = this.props.provider.get('wallet_transactions', List())

    if (this.state.loading) {
      return <CircularProgress color='secondary' />
    } else if (transactions.isEmpty()) {
      return (
        <Typography color='textSecondary'>
          {this.props.t('transactions.no_transaction')}
        </Typography>
      )
    } else {
      return (
        <>
          <WalletTransactionList transactions={transactions} isProvider />

          <Pagination totalPages={this.state.totalPages} />
        </>
      )
    }
  }

  renderBankAccountList() {
    const bankAccounts = this.props.provider.get('bank_accounts', List())

    if (this.state.loading) {
      return (
        <CircularProgress
          color='secondary'
          style={{ position: 'relative', left: '45%' }}
        />
      )
    } else if (bankAccounts.isEmpty()) {
      return (
        <Typography color='textSecondary'>{this.props.t('no_bank_account')}</Typography>
      )
    } else {
      return (
        <BankAccountList
          bankAccounts={bankAccounts}
          parentSchema={providerSchema}
          parentId={this.props.match.params.id}
        />
      )
    }
  }

  render() {
    const { provider, fullScreen, t } = this.props

    return (
      <Grid container direction='row-reverse'>
        <Grid item sm={4} container direction='column'>
          <Grid item>
            <Panel title={t('wallet.title')}>
              <div style={{ padding: '14px 14px 0' }}>
                <AttributeLabel title={t('wallet.total')}>
                  <Typography variant='h4'>
                    {provider.getIn(['wallet', 'total_balance', 'display_value'], '')}
                  </Typography>
                </AttributeLabel>

                <AttributeLabel title={t('wallet.available')}>
                  <Typography variant='h4'>
                    {provider.getIn(['wallet', 'available_balance', 'display_value'], '')}
                  </Typography>
                </AttributeLabel>

                <AttributeLabel title={t('wallet.kbank_processing.title')}>
                  <ul>
                    {provider
                      .getIn(['wallet', 'kbank_processing_balances'], List())
                      .map((cycle) => (
                        <li key={cycle.get('cycle_start_date')}>
                          <Typography variant='caption' color='textSecondary'>
                            {t('wallet.kbank_processing.amount')}
                          </Typography>
                          <Typography variant='h5' gutterBottom>
                            {cycle.getIn(['processing_balance', 'display_value'])}
                          </Typography>

                          <Typography variant='caption' color='textSecondary'>
                            {t('wallet.kbank_processing.payout_date')}
                          </Typography>
                          <Typography gutterBottom>
                            <DateTimeFormatter value={cycle.get('payout_date')} simple />
                          </Typography>

                          <Typography variant='caption' color='textSecondary'>
                            {t('wallet.kbank_processing.cycle')}
                          </Typography>
                          <Typography>
                            <DateTimeFormatter
                              value={cycle.get('cycle_start_date')}
                              simple
                            />
                            {` — `}
                            <DateTimeFormatter
                              value={cycle.get('cycle_end_date')}
                              simple
                            />
                          </Typography>
                        </li>
                      ))}
                  </ul>
                </AttributeLabel>
              </div>
            </Panel>
          </Grid>

          <Grid item>
            <Panel
              title={t('title')}
              actions={
                <IconButton onClick={this.handleOpenModal}>
                  <AddCircle />
                </IconButton>
              }
            >
              {this.renderBankAccountList()}

              <Dialog
                fullWidth
                maxWidth='xs'
                fullScreen={fullScreen}
                open={this.state.modalOpen}
                onClose={this.handleCloseModal}
              >
                <DialogTitle>{t('form_title.new')}</DialogTitle>
                <DialogContent>
                  <BankAccountForm
                    onSubmit={this.handleCreateBankAccount}
                    onCancel={this.handleCloseModal}
                  />
                </DialogContent>
              </Dialog>
            </Panel>
          </Grid>
        </Grid>

        <Grid item sm={8} xs={12}>
          <Panel
            title={t('transactions.title')}
            subtitle={`${this.state.totalCount} total`}
            actions={this.renderWalletTransactionMenu()}
            cardContentProps={{ style: { textAlign: 'center' } }}
          >
            {this.renderWalletTransactionList()}
          </Panel>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withMobileDialog(),
  withTranslation(['wallets', 'bank_accounts', 'providers'])
)(ProviderWalletPane)
