import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { create } from 'actions/resources'
import { fetch } from 'actions/collections'
import { getCollection } from 'selectors/collections'
import { invoiceSchema, customerSchema } from '@seekster/schemas'

import InvoiceNewPage from './InvoiceNewPage'

const mapStateToProps = (state, ownProps) => ({
  customers: getCollection(state, customerSchema)
})

const mapDispatchToProps = {
  loadCustomers: (options) => fetch(customerSchema, options),
  create: (data, options) => create(data, invoiceSchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceNewPage)
