import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import { useDropzone } from 'react-dropzone'
import { IconButton, Typography } from '@material-ui/core'
import { AddPhotoAlternateRounded, ClearRounded } from '@material-ui/icons'
import classNames from 'clsx'
import { update } from 'lodash'
import { withTranslation } from 'react-i18next'

import './DropzoneInput.scss'

export function DropzoneInput ({
  value,
  onChange,
  isMulti,
  isClearable,
  keepPreviewWhenUnmount,
  width,
  height,
  t,
  ...rest
}) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: handleFilesDrop,
    onFileDialogCancel: () => setSelectedThumbnail(null),
    multiple: Boolean(isMulti),
    maxSize: 20000000,
    ...rest
  })

  useEffect(() => () => {
    if (value && isMulti && !keepPreviewWhenUnmount) {
      value.forEach(({ file = {} }) => (
        file.preview && URL.revokeObjectURL(file.preview)
      ))
    }
    else if (value && !keepPreviewWhenUnmount) {
      value.preview && URL.revokeObjectURL(value.preview)
    }
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedThumbnail, setSelectedThumbnail] = useState(null)
  const inputEl = useRef(null)

  function handleFilesDrop (acceptedFiles) {
    const acceptedFilesWithPreview = acceptedFiles.map(file => (
      Object.assign(file, { preview: URL.createObjectURL(file) })
    ))

    let newValue

    if (isMulti && selectedThumbnail) {
      newValue = update(value, selectedThumbnail, attachment => ({
        ...attachment,
        file: acceptedFilesWithPreview[0]
      }))

      setSelectedThumbnail(null)
    }
    else if (isMulti) {
      const existingValue = value || []

      newValue = [
        ...existingValue,
        ...acceptedFilesWithPreview.map(file => ({ file }))
      ]
    }
    else {
      newValue = acceptedFilesWithPreview[0]
    }

    onChange && onChange(newValue)
  }

  function handleFileRemove (removingIndex) {
    let remainingValue

    if (isMulti) {
      if (value[removingIndex].id) {
        remainingValue = update(value, removingIndex, attachment => ({
          ...attachment,
          _destroy: true
        }))
      }
      else {
        remainingValue = value.filter((attachment, i) => (
          i !== removingIndex
        ))
      }
    }
    else {
      remainingValue = null
    }

    onChange && onChange(remainingValue)
  }

  function handleThumbnailClick (i) {
    inputEl.current.click()

    if (isMulti) {
      setSelectedThumbnail(i.toString())
    }
  }

  function renderThumbnails () {
    if (isMulti) {
      return (
        value && value.map((data, i) => (
          !data._destroy && (
            <div className='thumbnail-multi'>
              <LazyLoad once offset={200}>
                <img
                  src={data.file_url || data.remote_file_url || data.file.preview}
                  alt='thumbnail'
                  className='thumbnail-multi__img'
                  onClick={() => handleThumbnailClick(i)}
                />
              </LazyLoad>
              {
                isClearable && (
                  <IconButton
                    className='thumbnail-multi__button-remove'
                    onClick={() => handleFileRemove(i)}
                  >
                    <ClearRounded className='thumbnail-multi__icon-remove' />
                  </IconButton>
                )
              }
            </div>
          )
        ))
      )
    }
    else {
      return (
        <div className='thumbnail-single'>
          <div
            className='thumbnail-single_outer'
            style={(width && height) ? ({
              paddingTop: `calc(${height} / ${width} * 100%)`,
              maxHeight: height
            }) : ({
              minHeight: 90
            })}
            onClick={handleThumbnailClick}
          >
            <div className='thumbnail-single_inner'>
              <div className='thumbnail-single_flexbox-centering'>
                <img
                  src={value.preview || value}
                  alt='thumbnail'
                  className='thumbnail-single__img'
                />
              </div>
            </div>
          </div>
          {
            isClearable && (
              <IconButton
                className='thumbnail-single__button-remove'
                onClick={handleFileRemove}
              >
                <ClearRounded className='thumbnail-single__icon-remove' />
              </IconButton>
            )
          }
        </div>
      )
    }
  }

  return (
    <div
      className='dropzone_container'
      style={!isMulti ? { display: 'block' } : {}}
    >
      {value && value.length !== 0 && renderThumbnails()}

      <div
        {...getRootProps({
          className: classNames('dropzone', {
            [`dropzone--multi--fullwidth`]: !value || value.length === 0,
            [`dropzone--single--hidden`]: !isMulti && value
          })
        })}
        ref={inputEl}
        style={!isMulti ? ({
          paddingTop: `calc(${height} / ${width} * 100%)`,
          maxHeight: height
        }) : ({})}
      >
        <input {...getInputProps()} />

        <div className='dropzone__placeholder-content'>
          <AddPhotoAlternateRounded
            fontSize='large'
            className='dropzone__icon-add'
          />
          {
            (!value || value.length === 0) && (
              <Typography variant='caption' align='center'>
                {t(`placeholder.dropzone${isMulti ? '_multi' : ''}`)}
              </Typography>
            )
          }
          {
            !isMulti && width && height && (
              <Typography variant='caption' align='center'>
                {width} x {height} pixel
              </Typography>
            )
          }
        </div>
      </div>
    </div>
  )
}

DropzoneInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  keepPreviewWhenUnmount: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,

  t: PropTypes.func
}

export default withTranslation('fields')(DropzoneInput)
