import React from 'react'
import i18n from 'i18next'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'

import 'moment/locale/th'

import PageLayout from 'components/layouts/PageLayout'
import Panel from 'components/containers/Panel'

const languages = [
  { text: 'English', value: 'en' },
  { text: 'ภาษาไทย', value: 'th' }
]

export function PreferencesPage ({ updateLocale }) {
  const { t } = useTranslation('preferences')

  function handleUpdateLocale (locale) {
    return updateLocale({ locale })
      .then(response => {
        i18n.changeLanguage(response.body.locale)

        moment.locale(response.body.locale)
      })
  }

  return (
    <PageLayout title={t('title')}>
      <Panel title={t('panel.languages')}>
        <div style={{ display: 'flex' }}>
          {
            languages.map(language => (
              <Button
                key={language.value}
                style={{ width: '120px', margin: 16 }}
                variant='contained'
                color='primary'
                disabled={i18n.language === language.value}
                onClick={() => handleUpdateLocale(language.value)}
              >
                {language.text}
              </Button>
            ))
          }
        </div>
      </Panel>
    </PageLayout>
  )
}

export default PreferencesPage
