import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { announcementSchema } from '@seekster/schemas'

import AnnouncementOverviewPane from './AnnouncementOverviewPane'

const mapStateToProps = (state, { match }) => ({
  announcement: getResource(state, match.params.id, announcementSchema)
})

export default connect(mapStateToProps)(AnnouncementOverviewPane)
