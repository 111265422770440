import React from 'react'
import { Grid } from '@material-ui/core'
import { useResource } from 'hooks/resources'
import { jobSchema } from '@seekster/schemas'

import Payins from './containers/Payins'
import Payouts from './containers/Payouts'

export function JobFinancePane({ match }) {
  const [job] = useResource(jobSchema, match.params.id)

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Payins job={job} />
      </Grid>

      <Grid item xs={6}>
        <Payouts job={job} />
      </Grid>
    </Grid>
  )
}

export default JobFinancePane
