import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, update } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { announcementSchema } from '@seekster/schemas'

import AnnouncementEditPage from './AnnouncementEditPage'

const mapStateToProps = (state, { match }) => ({
  announcement: getResource(state, match.params.id, announcementSchema)
})

const mapDispatchToProps = {
  fetchAnnouncement: (id, options) => fetch(id, announcementSchema, options),
  updateAnnouncement: (id, data, options) => update(id, data, announcementSchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementEditPage)
