export default theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    borderRadius: 'unset',
    alignItems: 'center',
    width: '100%',

    '@media screen and (max-width: 767px)': {
      position: 'fixed',
      width: '100%',
      bottom: 0
    }

  },
  input: {
    flex: 1,
    width: '100%',
    padding: 8
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  inputFile: {
    display: 'none'
  },
  labelInputFile: {
    margin: 0
  }
})
