import {
  CREATE_RESOURCE_SUCCEEDED,
  FETCH_RESOURCE_SUCCEEDED,
  UPDATE_RESOURCE_SUCCEEDED
} from 'constants/firebase/resources'

import { firebaseSupportPlatform } from 'utils/firebase'

const db = firebaseSupportPlatform.firestore()

export const createSucceeded = (response, schema, options) => ({
  type: CREATE_RESOURCE_SUCCEEDED,
  response,
  schema,
  options
})

export const updateSucceeded = (response, schema, options) => ({
  type: UPDATE_RESOURCE_SUCCEEDED,
  response,
  schema,
  options
})

export const fetchResourceSucceeded = (snapshot, schema, options) => ({
  type: FETCH_RESOURCE_SUCCEEDED,
  snapshot,
  schema,
  options
})

export const create = (path, data, schema, options = {}) => (dispatch) => {
  return db.collection(path)
    .add(data)
    .then(response => {
      db.collection(path).doc(response.id).update({ id: response.id })
      dispatch(createSucceeded(response, schema, options))
      return response
    })
}

export const update = (id, path, data, schema, options = {}) => (dispatch) => {
  return db.collection(path)
    .doc(id)
    .update(data)
    .then(response => {
      dispatch(updateSucceeded(response, schema, options))
      return response
    })
}
