import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Link } from 'react-router-dom'
import {
  CardActionArea,
  CardHeader,
  Typography,
  Avatar,
  IconButton,
  CircularProgress
} from '@material-ui/core'
import { PictureAsPdf } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import useData from 'hooks/useData'

import PageContent, { PageSection } from 'components/layouts/PageContent'
import Panel from 'components/containers/Panel'
import AttributeLabel from 'components/labels/AttributeLabel'
import InvoiceLineItemsTable from 'modules/invoices/InvoiceLineItemsTable'
import PaymentList from 'modules/payments/PaymentList'
import PaymentMethodMenu from 'modules/payments/PaymentMethodMenu'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'

export function InvoiceOverviewPane({ invoice, match, fetchPayments, t }) {
  const { loading } = useData(() => fetchPayments(match.params.id), [])

  function renderPaymentList() {
    if (loading) {
      return (
        <CircularProgress
          color='secondary'
          style={{ position: 'relative', left: '45%' }}
        />
      )
    } else if (invoice.get('payments', List()).isEmpty()) {
      return (
        <Typography color='textSecondary' style={{ position: 'relative', left: '45%' }}>
          {t('payments.no_payment')}
        </Typography>
      )
    } else {
      return <PaymentList invoice={invoice} />
    }
  }

  return (
    <PageContent>
      <PageSection position='center' borderRight>
        <Panel title={t('line_items')}>
          <InvoiceLineItemsTable invoice={invoice} />
        </Panel>

        <Panel
          title={t('payments.title')}
          actions={<PaymentMethodMenu invoice={invoice} />}
        >
          {renderPaymentList()}
        </Panel>
      </PageSection>

      <PageSection position='right'>
        <Panel
          title={t('details')}
          actions={
            <IconButton href={invoice.get('pdf_url')} target='_blank'>
              <PictureAsPdf />
            </IconButton>
          }
        >
          <div style={{ padding: '10px 16px' }}>
            <AttributeLabel title={`${t('id')} ${invoice.get('id')}`}>
              <Typography variant='h4'>{invoice.get('number')}</Typography>
            </AttributeLabel>

            <AttributeLabel title={t('due_at')}>
              <DateTimeFormatter value={invoice.get('due_at')} />
            </AttributeLabel>

            <AttributeLabel title={t('updated_at')}>
              <DateTimeFormatter value={invoice.get('updated_at')} />
            </AttributeLabel>

            <AttributeLabel title={t('created_at')}>
              <DateTimeFormatter value={invoice.get('created_at')} />
            </AttributeLabel>
          </div>
        </Panel>

        <Panel title={t('customer')} noPadding>
          <CardActionArea
            component={Link}
            to={`/customers/${invoice.getIn(['customer', 'id'])}`}
            style={{ textDecoration: 'none' }}
          >
            <CardHeader
              avatar={
                <Avatar
                  src={invoice.getIn(['customer', 'avatar_url'])}
                  alt={invoice.getIn(['customer', 'display_name'])}
                />
              }
              title={invoice.getIn(['customer', 'display_name'])}
              subheader={
                <>
                  <Typography inline variant='caption'>
                    {t('wallet.wallet_balance')}{' '}
                  </Typography>
                  {invoice.getIn([
                    'customer',
                    'wallet',
                    'available_balance',
                    'display_value'
                  ])}
                </>
              }
            />
          </CardActionArea>
        </Panel>
      </PageSection>
    </PageContent>
  )
}

InvoiceOverviewPane.propTypes = {
  invoice: ImmutablePropTypes.map,
  match: PropTypes.object,
  fetchPayments: PropTypes.func,

  t: PropTypes.func
}

InvoiceOverviewPane.defaultProps = {
  invoice: Map()
}

export default withTranslation(['common', 'invoice', 'wallets', 'customers'])(
  InvoiceOverviewPane
)
