import React from 'react'
import { connect, getIn } from 'formik'
import { Grid } from '@material-ui/core'

import { FastField } from 'components/fields/Field'
import SortableFormSection from 'components/fields/SortableFieldArray/SortableFormSection.new'
import CancellationChildrenForm from './CancellationChildrenForm'
import SortableFieldArray from 'components/fields/SortableFieldArray'
import { CANCELLATION_REASON } from 'constants/states/cancellationReason'
import { CANCELLATION_CATEGORIES } from 'constants/states/cancallationCategories'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'

function CancellationItemForm({ name, formik, ...props }) {
  const value = getIn(formik.values, name)
  const title = value.value ? value.value : 'Cancellation value'

  return (
    <SortableFormSection title={title} name={name} formik={formik} {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FastField
            label='Name'
            name={`${name}.owner`}
            component={SelectInput}
            options={arrayToOptions(CANCELLATION_REASON)}
          />
        </Grid>
        <Grid item xs={12}>
          <FastField
            label='Cancel by'
            name={`${name}.value`}
            component={SelectInput}
            options={arrayToOptions(CANCELLATION_CATEGORIES)}
          />
        </Grid>
        <Grid item xs={12}>
          <SortableFieldArray
            label='Children'
            name={`${name}.cancel_reason_values`}
            component={CancellationChildrenForm}
          />
        </Grid>
      </Grid>
    </SortableFormSection>
  )
}

export default connect(CancellationItemForm)
