import { Map } from 'immutable'
import { normalize } from 'normalizr'
import pluralize from 'pluralize'
import { upperFirst, camelCase } from 'lodash'

import { agentSchema, tenantSchema } from '@seekster/schemas'
import * as serviceWorker from '../serviceWorker'

import {
  SIGN_IN_SUCCEEDED,
  SIGN_OUT,
  ACCEPT_INVITATION_SUCCEEDED,
  FETCH_CURRENT_DEVICE_SUCCEEDED,
  FETCH_CURRENT_SUCCEEDED,
  UPDATE_CURRENT_DEVICE_SUCCEEDED,
  DESTROY_CURRENT_SUCCEEDED,
  CREATE_CURRENT_SUCCEEDED
} from 'constants/authentication'

const initialState = Map({
  token: localStorage.getItem('WF:token')
})

const authenticationReducer = (
  state = initialState,
  { type, response, schema, options, error }
) => {
  let normalizedAgent
  let normalizedTenant
  let normalizedCurrent
  let currentType

  switch (type) {
    case SIGN_IN_SUCCEEDED:
    case ACCEPT_INVITATION_SUCCEEDED:
    case FETCH_CURRENT_DEVICE_SUCCEEDED:
      normalizedAgent = normalize(response.body.user, agentSchema)
      normalizedTenant = normalize(response.body.tenant, tenantSchema)

      window.localStorage.setItem('WF:token', response.body.access_token)

      serviceWorker.register()

      return state
        .set('token', response.body.access_token)
        .set('registration_token', response.body.registration_token)
        .set('currentDevice', response.body.id)
        .set('currentAgent', normalizedAgent.result)
        .set('currentTenant', normalizedTenant.result)

    case FETCH_CURRENT_SUCCEEDED:
    case CREATE_CURRENT_SUCCEEDED:
      normalizedCurrent = normalize(response.body, schema)
      currentType = upperFirst(camelCase(pluralize.singular(schema._key)))

      return state.set(`current${currentType}`, normalizedCurrent.result)

    case UPDATE_CURRENT_DEVICE_SUCCEEDED:
      window.localStorage.setItem('FCM_token', response.body.registration_token)

      return state.set('registration_token', response.body.registration_token)

    case DESTROY_CURRENT_SUCCEEDED:
      currentType = upperFirst(camelCase(pluralize.singular(schema._key)))

      return state.delete(`current${currentType}`)

    case SIGN_OUT:
      window.localStorage.removeItem('WF:token')
      window.localStorage.removeItem('FCM_token')

      return state.clear()

    default:
      return state
  }
}

export default authenticationReducer
