import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { createChild } from 'actions/resources'
import { walletTransactionSchema } from '@seekster/schemas'

import WalletTransactionMenu from './WalletTransactionMenu'

const mapDispatchToProps = {
  fetchTransactions: (id, parentSchema, options) =>
    fetchChildren(parentSchema, id, walletTransactionSchema, {
      ...options,
      childrenType: 'wallet/transactions'
    }),

  createTransaction: (id, data, parentSchema, options) =>
    createChild(data, parentSchema, id, walletTransactionSchema, {
      ...options,
      childrenType: 'wallet/transactions'
    })
}

export default connect(null, mapDispatchToProps)(WalletTransactionMenu)
