import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { Grid, Paper } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import CircularLoader from 'components/CircularLoader'
import Search from 'components/Search'
import FilterModal from 'components/FilterModal'
import FilterTags from 'components/tags/FilterTags'
import AgentsFilterForm from 'forms/filters/AgentsFilterForm'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import { Table, Column } from 'components/tables/Table'
import Item from 'components/Item'
import BooleanChip from 'components/BooleanChip'
import PhoneNumberFormatter from 'components/formatters/PhoneNumberFormatter'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import Pagination from 'components/Pagination'
import Tag from 'components/Tag'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  }
}))

export function AgentIndexPageContent ({ agents, fetchAgents, totalCount, totalPages, query }) {
  const { loading } = useData(() => handleUpdateData(), [query])
  const classes = useStyles()

  function handleUpdateData () {
    const { page, ...rest } = query

    return fetchAgents({ page, query: rest })
  }

  return (
    <>
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Search />
          </Grid>

          <Grid item xs={2}>
            <FilterModal component={AgentsFilterForm} />
          </Grid>
        </Grid>
      </div>
      {
        loading ? (
          <Grid item xs={12}>
            <CircularLoader />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <FilterTags />

              <ResultsCountLabel value={totalCount} unit='agent' />

              <Paper>
                <Table collection={agents}>
                  <Column
                    name='display_name'
                    render={(displayName, agent) => (
                      <Item
                        name={displayName}
                        avatar={agent.get('avatar_url')}
                        to={`/agents/${agent.get('id')}`}
                        lazyLoad
                      />
                    )}
                  />
                  <Column name='email' />
                  <Column name='phone_number' component={PhoneNumberFormatter} />
                  <Column name='department.name' component={Tag} />
                  <Column name='active' component={BooleanChip} />
                  <Column name='created_at' component={DateTimeFormatter} />
                </Table>
              </Paper>
            </Grid>

            <Grid item xs={12} container justify='center'>
              <Pagination totalPages={totalPages} />
            </Grid>
          </>
        )
      }
    </>
  )
}

export function AgentIndexPage ({ t, ...props }) {
  function primaryActions () {
    return [{
      content: t('new'),
      to: '/agents/new'
    }]
  }

  return (
    <PageLayout title={t('title')} primaryActions={primaryActions()}>
      <AgentIndexPageContent {...props} />
    </PageLayout>
  )
}

AgentIndexPageContent.propTypes = {
  agents: ImmutablePropTypes.list,
  fetchAgents: PropTypes.func,
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
  query: PropTypes.object
}

AgentIndexPageContent.defaultProps = {
  agents: List()
}

AgentIndexPage.propTypes = {
  t: PropTypes.func
}

export default withTranslation('agents')(AgentIndexPage)
