import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, update } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { categorySchema } from '@seekster/schemas'

import CategoryEditPage from './CategoryEditPage'

const mapStateToProps = (state, { match }) => ({
  category: getResource(state, match.params.id, categorySchema)
})

const mapDispatchToProps = {
  fetchCategory: (id, options) => fetch(id, categorySchema, options),
  updateCategory: (id, data, options) => update(id, data, categorySchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryEditPage)
