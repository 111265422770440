import React from 'react'
import PropTypes from 'prop-types'
import MaterialPagination from '@material-ui/lab/Pagination'
import qs from 'qs'

import withQueryParser from 'utils/withQueryParser'

export class Pagination extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    query: PropTypes.object,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    onPageChange: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.handlePageChange = this.handlePageChange.bind(this)
  }

  handlePageChange (e, data) {
    if (this.props.onPageChange) {
      this.props.onPageChange(data)
    }
    else {
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: qs.stringify({
          ...this.props.query,
          page: data
        }, {
          addQueryPrefix: true
        })
      })
    }
  }

  render () {
    const page = parseInt(this.props.query.page || this.props.currentPage || 1, 10)

    return (
      <MaterialPagination
        count={this.props.totalPages}
        page={page}
        onChange={this.handlePageChange}
        style={{ margin: '8px 0' }}
        color='secondary'
      />
    )
  }
}

export default withQueryParser(Pagination)
