import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import useQuery from 'hooks/useQuery'

import {
  withMobileDialog,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  Grid,
  IconButton
} from '@material-ui/core'
import { useNodeCollection } from 'hooks/collections'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close'
import Search from 'components/Search'
import Pagination from 'components/Pagination'
import { useModal } from 'hooks/useModal'
import JobProvidersList from 'components/lists/JobProvidersList'
import ProvidersListTable from 'components/tables/ProvidersListTable'
import OrganizationsAssignTable from 'components/tables/OrganizationsAssignTable'
import { ASSIGN_PROVIDER } from 'constants/modal'
import { organizationSchema, userNodeSchema } from '@seekster/schemas'
import Swal from 'sweetalert2'

import styles from './JobProvidersPaneStyles'

export function JobProvidersPane({
  job,
  fetchJob,
  fetchJobProviders,
  assignOrgProvider,
  assignProvider,
  assignOrg,
  cancelOrg,
  match,
  classes,
  t
}) {
  const [query] = useQuery()
  const [organizations, { totalPages }] = useNodeCollection(
    organizationSchema,
    { url: '/organizations', page: query.page, query },
    [query]
  )

  const [users] = useNodeCollection(
    userNodeSchema,
    { url: '/providers', page: query.page, query, queryParam: { hasOrg: true } },
    [query]
  )

  const { openModal, closeModal } = useModal()
  const [open, setOpen] = useState()
  const [mode, setMode] = useState()

  const handleClickOpen = (mode) => {
    if (mode === 3) {
      handleOpenAssignModal()
    } else {
      setMode(mode)
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  function updateData() {
    fetchJob(match.params.id)

    return fetchJobProviders(match.params.id)
  }

  function handleOpenAssignModal() {
    openModal({
      name: ASSIGN_PROVIDER,
      data: {
        jobIds: [job.get('id')],
        jobStartTimes: [job.get('start_time')]
      },
      onConfirm: handleAssignProvider
    })
  }

  function handleAssignProvider(data) {
    return assignProvider(match.params.id, data)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'มอบหมายงาน เสร็จแล้ว!',
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          closeModal()
          updateData()
        })
      })
      .catch((error) => {
        alert(error.response.body.message)
        closeModal()
      })
  }

  function handleAssignOrg(orgId) {
    return assignOrg(match.params.id, {
      orgId
    })
      .then(() => {
        Swal.fire({
          html:
            '<span style="display: inline-flex;">' +
            '<img style="border-radius: 50%;margin-right: 20px;margin-top: 16px;" src="https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/Icon-awesome-check-circle.png" width="30" height="30">' +
            `<p style="color: #1B4171;font-family:Roboto, Helvetica, Arial, sans-serif;">${t(
              'assign_job_completed'
            )}</p>` +
            '</span>',
          showConfirmButton: false,
          timer: 1000
        }).then(() => {
          handleClose()
          updateData()
        })
      })
      .catch((error) => {
        alert(error.response.body.message)
      })
  }

  function handleCancelOrg(orgId) {
    return cancelOrg(match.params.id, {
      orgId
    })
      .then(() => {
        Swal.fire({
          html:
            '<span style="display: inline-flex;">' +
            '<img style="border-radius: 50%;margin-right: 20px;margin-top: 16px;" src="https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/Icon-awesome-check-circle.png" width="30" height="30">' +
            `<p style="color: #1B4171;font-family:Roboto, Helvetica, Arial, sans-serif;">${t(
              'cancel_org_completed'
            )}</p>` +
            '</span>',
          showConfirmButton: false,
          timer: 1000
        }).then(() => {
          handleClose()
          updateData()
        })
      })
      .catch((error) => {
        alert(error.response.body.message)
      })
  }

  function handleAssignProviderUnderOrg(providerId) {
    return assignOrgProvider(match.params.id, {
      providerId
    })
      .then(() => {
        Swal.fire({
          html:
            '<span style="display: inline-flex;">' +
            '<img style="border-radius: 50%;margin-right: 20px;margin-top: 16px;" src="https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/Icon-awesome-check-circle.png" width="30" height="30">' +
            `<p style="color: #1B4171;font-family:Roboto, Helvetica, Arial, sans-serif;">${t(
              'assign_job_completed'
            )}</p>` +
            '</span>',
          showConfirmButton: false,
          timer: 1000
        }).then(() => {
          handleClose()
          updateData()
        })
      })
      .catch((error) => {
        alert(error.response.body.message)
      })
  }

  return (
    <div style={{ padding: '14px' }}>
      <JobProvidersList
        job={job}
        handleClickOpen={handleClickOpen}
        handleCancelOrg={handleCancelOrg}
      />
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <div className={classes.titleBar}>
            <Typography variant='h6' className={classes.title}>
              {t('assign_job')}
            </Typography>
            <Typography variant='p'>{t('assign_job_description')}</Typography>
          </div>
        </AppBar>

        <div className={classes.pageContainer}>
          <div className={classes.searchContainer}>
            <Search withButton />
          </div>

          <div className={classes.metaContainer}>
            {mode === 1 && (
              <>
                <OrganizationsAssignTable
                  organizations={organizations}
                  handleAssignOrg={handleAssignOrg}
                />

                <Grid item xs={12} container justify='center'>
                  <Pagination totalPages={totalPages} />
                </Grid>
              </>
            )}
            {mode === 2 && (
              <>
                <ProvidersListTable
                  providers={users}
                  handleAssignProviderUnderOrg={handleAssignProviderUnderOrg}
                />
              </>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  )
}

JobProvidersPane.propTypes = {
  job: ImmutablePropTypes.map,
  fetchJob: PropTypes.func,
  fetchJobProviders: PropTypes.func,
  assignProvider: PropTypes.func,
  assignOrgProvider: PropTypes.func,
  assignOrg: PropTypes.func,
  cancelOrg: PropTypes.func,
  match: PropTypes.object,
  classes: PropTypes.object,

  t: PropTypes.func
}

JobProvidersPane.defaultProps = {
  job: Map()
}

export default compose(
  withStyles(styles),
  withMobileDialog(),
  withTranslation(['jobs', 'actions'])
)(JobProvidersPane)
