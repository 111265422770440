export default (
  isSupportPlatformEnabled,
  isSLAFuntionEnable = false,
  isRatingLinkEnable = false,
  isCancellationEnable = false
) => {
  const operationsPath = isSupportPlatformEnabled
    ? [
        { value: 'tickets', path: '/tickets' },
        { value: 'requests', path: '/inquiries' },
        { value: 'jobs', path: '/jobs' },
        ...(isSLAFuntionEnable
          ? [
              {
                value: 'sla',
                badge: true,
                children: [
                  { value: 'sla_pending_start', path: '/sla_pending_start' },
                  { value: 'sla_started', path: '/sla_started' }
                ]
              }
            ]
          : []),
        ...(isRatingLinkEnable ? [{ value: 'sms_ratings', path: '/sms_ratings' }] : []),
        { value: 'reviews', path: '/reviews' },
        { value: 'subscriptions', path: '/subscriptions' },
        { value: 'feedbacks', path: '/feedbacks' },
        { value: 'reports', path: '/reports' }
      ]
    : [
        { value: 'requests', path: '/inquiries' },
        { value: 'jobs', path: '/jobs' },
        ...(isSLAFuntionEnable
          ? [
              {
                value: 'sla',
                badge: true,
                children: [
                  { value: 'sla_pending_start', path: '/sla_pending_start' },
                  { value: 'sla_started', path: '/sla_started' }
                ]
              }
            ]
          : []),
        { value: 'reviews', path: '/reviews' },
        { value: 'subscriptions', path: '/subscriptions' },
        { value: 'feedbacks', path: '/feedbacks' },
        { value: 'reports', path: '/reports' }
      ]

  return [
    {
      value: 'Dashboard',
      path: '/'
    },
    {
      value: 'operations',
      children: operationsPath
    },
    {
      value: 'customers',
      children: [
        { value: 'customers', path: '/customers' },
        { value: 'companies', path: '/companies' }
      ]
    },
    {
      value: 'providers',
      children: [
        { value: 'organizations', path: '/organizations' },
        { value: 'providers', path: '/providers' },
        { value: 'badges', path: '/badges' },
        { value: 'provider_strengths', path: '/provider_strengths' },
        { value: 'provider_issues', path: '/provider_issues' }
      ]
    },
    {
      value: 'services',
      children: [
        { value: 'regions', path: '/regions' },
        { value: 'categories', path: '/categories' },
        { value: 'services', path: '/services' }
      ]
    },
    {
      value: 'marketing',
      children: [
        { value: 'promo_codes', path: '/promo_codes' },
        { value: 'announcements', path: '/announcements' }
      ]
    },
    {
      value: 'accounting',
      children: isCancellationEnable
        ? [
            { value: 'invoices', path: '/invoices' },
            { value: 'creditnotes', path: '/creditnotes' },
            { value: 'payments', path: '/payments' },
            { value: 'payrolls', path: '/payrolls' },
            { value: 'payouts', path: '/payouts' }
          ]
        : [
            { value: 'invoices', path: '/invoices' },
            { value: 'payments', path: '/payments' },
            { value: 'payrolls', path: '/payrolls' },
            { value: 'payouts', path: '/payouts' }
          ]
    }
  ]
}
