export default theme => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center'
  },

  menuAvatar: {
    marginRight: theme.spacing(1),

    '& > svg': {
      marginTop: 6
    }
  },

  menuContent: {
    width: '100%'
  }
})
