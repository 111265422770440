export default () => ({
  root: {
    width: '100%',
    marginBottome: 0
  },
  button: {
    float: 'right',
    margin: '14px 0 0 14px'
  }
})
