import agentsQuery from './queries/agentsQuery'
import announcementsQuery from './queries/announcementsQuery'
import companiesQuery from './queries/companiesQuery'
import categoriesQuery from './queries/categoriesQuery'
import badgesQuery from './queries/badgesQuery'
import customersQuery from './queries/customersQuery'
import clientsQuery from './queries/clientsQuery'
import invoicesQuery from './queries/invoicesQuery'
import jobsQuery from './queries/jobsQuery'
import promocodesQuery from './queries/promocodesQuery'
import promotionsQuery from './queries/promotionsQuery'
import providersQuery from './queries/providersQuery'
import requestsQuery from './queries/requestsQuery'
import reviewsQuery from './queries/reviewsQuery'
import regionsQuery from './queries/regionsQuery'
import subscriptionsQuery from './queries/subscriptionsQuery'
import servicesQuery from './queries/servicesQuery'
import tagsQuery from './queries/tagsQuery'
import areaTemplatesQuery from './queries/areaTemplatesQuery'
import packagesQuery from './queries/packagesQuery'
import branchsQuery from './queries/branchsQuery'
import paymentsQuery from './queries/paymentsQuery'
import payoutsQuery from './queries/payoutsQuery'
import smsRatingsQuery from './queries/smsRatingsQuery'

export const selectQueryToServer = (schema, options) => {
  const queryObject = options.query || {}

  switch (schema._key) {
    case 'agents':
      return agentsQuery(queryObject)
    case 'announcements':
      return announcementsQuery(queryObject)
    case 'companies':
      return companiesQuery(queryObject)
    case 'categories':
      return categoriesQuery(queryObject)
    case 'badges':
      return badgesQuery(queryObject)
    case 'customers':
      return customersQuery(queryObject)
    case 'clients':
      return clientsQuery(queryObject)
    case 'inquiries':
      return requestsQuery(queryObject)
    case 'invoices':
      return invoicesQuery(queryObject)
    case 'jobs':
      return jobsQuery(queryObject)
    case 'promo_codes':
      return promocodesQuery(queryObject)
    case 'promotions':
      return promotionsQuery(queryObject)
    case 'providers':
    case 'potential_providers':
    case 'approved_providers':
      return providersQuery(queryObject)
    case 'regions':
      return regionsQuery(queryObject)
    case 'reviews':
      return reviewsQuery(queryObject)
    case 'subscriptions':
      return subscriptionsQuery(queryObject)
    case 'services':
      return servicesQuery(queryObject)
    case 'packages':
      return packagesQuery(queryObject)
    case 'tags':
      return tagsQuery(queryObject)
    case 'area_templates':
      return areaTemplatesQuery(queryObject)
    case 'branches':
      return branchsQuery(queryObject)
    case 'payments':
      return paymentsQuery(queryObject)
    case 'payouts':
      return payoutsQuery(queryObject)
    case 'sms_ratings':
      return smsRatingsQuery(queryObject)
    default:
      return {}
  }
}

export default selectQueryToServer
