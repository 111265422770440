import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, ListItem, ListItemText, Paper } from '@material-ui/core'
import { MapRounded } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import { useSorting } from 'hooks'
import { useCollection } from 'hooks/collections'
import { useResourceAction } from 'hooks/resources'
import useQuery from 'hooks/useQuery'
import PageLayout from 'components/layouts/PageLayout'
import CircularLoader from 'components/CircularLoader'
import Search from 'components/Search'
import FilterModal from 'components/FilterModal'
import FilterTags from 'components/tags/FilterTags'
import RegionsFilterForm from 'forms/filters/RegionsFilterForm'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import SortableList from 'components/SortableList'
import { regionSchema } from '@seekster/schemas'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

export function RegionIndexPage () {
  const classes = useStyles()
  const [query] = useQuery()
  const { t } = useTranslation('regions')
  const { update: updateRegion } = useResourceAction(regionSchema)
  const {
    handleSortStart,
    handleSortEnd,
    sortingEventId
  } = useSorting(updateRegion)

  const [regions, { loading, totalCount, totalPages }] = useCollection(
    regionSchema,
    { page: query.page, query },
    [query, sortingEventId]
  )

  function primaryActions () {
    return [{
      avatar: <MapRounded fontSize='small' />,
      content: t('new'),
      to: '/regions/new'
    }]
  }

  return (
    <PageLayout title={t('title')} primaryActions={primaryActions()}>
      <div className={classes.searchContainer}>
        <Grid container spacing={3}>
          <Grid item xs={9} sm={10}>
            <Search />
          </Grid>

          <Grid item xs={3} sm={2}>
            <FilterModal component={RegionsFilterForm} />
          </Grid>
        </Grid>
      </div>
      {
        loading ? (
          <CircularLoader />
        ) : (
          <>
            <div className={classes.metaContainer}>

              <FilterTags />

              <ResultsCountLabel value={totalCount} unit='region' />

              <Paper>
                <SortableList
                  collection={regions}
                  title={region => (
                    <ListItem disableGutters component='div'>
                      <ListItemText primary={region.get('name')} />
                    </ListItem>
                  )}
                  onSortEnd={handleSortEnd}
                  onSortStart={handleSortStart}
                  useDragHandle
                />
              </Paper>
            </div>

            <Grid item xs={12} container justify='center'>
              <Pagination totalPages={totalPages} />
            </Grid>
          </>
        )
      }
    </PageLayout>
  )
}

export default RegionIndexPage
