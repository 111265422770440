import { red } from '@material-ui/core/colors'

export default ({ spacing, breakpoints }) => ({
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: '100%',
    marginTop: `-${spacing(1)}px`,
    padding: 0,
    backgroundColor: 'white',
    borderRadius: spacing(0.5),

    '& > button': {
      padding: spacing(0.5)
    },

    '&:focus': {
      outline: 'none'
    },

    [breakpoints.up('sm')]: {
      minWidth: 400
    }
  },

  footer: {
    textAlign: 'center',
    padding: `${spacing(0.5)}px ${spacing(2)}px`,
    marginBottom: `-${spacing(1)}px`
  },

  badge: {
    backgroundColor: red[800],
    color: 'white'
  },

  notification: {
    color: 'white'
  }
})
