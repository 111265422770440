import React, { useState, useEffect, useRef } from 'react'
import { Map } from 'immutable'
import { Grid, Typography, Button, TextField, InputAdornment, IconButton } from '@material-ui/core'
import { GetAppRounded, Delete, FileCopy } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { scbQrCodeIntegrationSchema } from '@seekster/schemas'

import { fetchCurrent, createCurrent, destroyCurrent, updateCurrent } from 'actions/authentication'
import { getCurrentResource } from 'selectors/authentication'

import Panel from 'components/containers/Panel'
import { Tabs, TabPane } from 'components/Tabs'
import AttributeLabel from 'components/labels/AttributeLabel'
import CircularLoader from 'components/CircularLoader'
import ScbQrCodeIntegrationForm from 'forms/resources/ScbQrCodeIntegrationForm'

const useStyles = makeStyles(({ palette, spacing }) => ({
  icon: {
    width: '100%'
  },

  uninstallButton: {
    fontWeight: 600,
    backgroundColor: palette.grey[50],
    color: palette.grey[600],

    '&:hover': {
      backgroundColor: palette.grey[200]
    }
  },

  tabs: {
    justifyContent: 'flex-start',
    backgroundColor: palette.grey[200]
  },

  tab: {
    fontSize: '1.45rem',
    fontWeight: 500,
    textTransform: 'capitalize'
  }
}))

const scbQrCodeLogoUrl = 'https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/qr_icon_payment_method.png'

export const ScbQrCodeIntegrationShowPage = () => {
  const { t } = useTranslation('integrations')
  const dispatch = useDispatch()
  const { icon, uninstallButton, tabs, tab } = useStyles()

  const [loading, setLoading] = useState(false)

  const integration = useSelector(state => getCurrentResource(state, scbQrCodeIntegrationSchema)) || Map()

  const copyRef = useRef(null)

  useEffect(() => {
    let isMounted = true

    setLoading(true)

    dispatch(fetchCurrent(scbQrCodeIntegrationSchema))
      .catch(console.log)
      .finally(() => isMounted && setLoading(false))

    return () => (isMounted = false)
  }, [dispatch])

  const handleCreateIntegration = () => {
    return dispatch(createCurrent({}, scbQrCodeIntegrationSchema))
  }

  const handleDeleteIntegration = () => {
    if (window.confirm(t('uninstall_confirmation_prompt'))) {
      return dispatch(destroyCurrent(scbQrCodeIntegrationSchema))
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    return dispatch(updateCurrent(values, scbQrCodeIntegrationSchema))
      .catch((error) => {
        const alertMessage = `Please re-check the information. ${error?.response?.body?.message}`
        alert(alertMessage)
      })
      .finally(() => setSubmitting(false))
  }

  const copyToClipboard = () => {
    copyRef.current.select()
    document.execCommand('copy')
    alert('Copied!')
  }

  return (
    <>
      {loading && <CircularLoader />}

      {!loading && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <Panel title={t('details')}>
              <img
                src={scbQrCodeLogoUrl}
                alt='SCB QR Code logo'
                className={icon}
              />

              <AttributeLabel title='scb_qr_code_integration'>
                <Typography variant='h4'>SCB QR Code</Typography>
              </AttributeLabel>

              {
                integration.isEmpty() ? (
                  <Button
                    variant='contained'
                    fullWidth
                    onClick={handleCreateIntegration}
                  >
                    <GetAppRounded fontSize='small' />
                    {t('install')}
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    className={uninstallButton}
                    fullWidth
                    onClick={handleDeleteIntegration}
                  >
                    <Delete fontSize='small' />
                    {t('uninstall')}
                  </Button>
                )
              }
            </Panel>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Tabs
              contained
              scrollButtons='off'
              classes={{ flexContainer: tabs }}
            >
              <TabPane label={t('description')} className={tab}>
                <h2>SCB QR Code</h2>
                <TextField
                  inputRef={copyRef}
                  label='Payment Confirmation Endpoint:'
                  value={`${process.env.REACT_APP_BASE_API}/scb_qr_code_integration/confirm`}
                  fullWidth
                  variant='filled'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={copyToClipboard}>
                          <FileCopy />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

              </TabPane>
              {
                !integration.isEmpty() && (
                  <TabPane label={t('configurations')} className={tab}>
                    <ScbQrCodeIntegrationForm
                      integration={integration}
                      handleSubmit={handleSubmit}
                    />
                  </TabPane>
                )
              }
            </Tabs>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ScbQrCodeIntegrationShowPage
