import React from 'react'
import PropTypes from 'prop-types'

export class HtmlFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired
  }

  render () {
    return <span dangerouslySetInnerHTML={{ __html: this.props.value }} />
  }
}

export default HtmlFormatter
