import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/buttons'
import { Container } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { customerSchema } from '@seekster/schemas'

import { createCollection } from 'actions/collections'

import CsvUpload from 'components/CsvUpload'
import PageLayout from 'components/layouts/PageLayout'
import { setFlashMessage } from 'actions/interface'
import { usePush } from 'hooks/utils'

export function GenerateCustomerPage() {
  const dispatch = useDispatch()
  const [jsonResult, setJsonResult] = useState(false)
  const push = usePush()
  const { t } = useTranslation('customers')

  const handleOnClick = () => {
    dispatch(
      createCollection(jsonResult, customerSchema, { type: 'customers_generator' })
    )
      .then(() => push('/customers'))
      .catch((error) => {
        const errorText = JSON.parse(error.response.text)
        dispatch(setFlashMessage('error', errorText.message))
      })
  }

  const validationAttributes = (customer, index) => {
    if (customer.first_name === '') {
      return {
        pass: false,
        message: `customer row ${index + 1} first name is empty`
      }
    }

    if (customer.email === '') {
      return {
        pass: false,
        message: `customer row ${index + 1} email is empty`
      }
    }

    if (customer.phone_number === '') {
      return {
        pass: false,
        message: `customer row ${index + 1} phone number is empty`
      }
    }

    if (customer.last_name === '') {
      return {
        pass: false,
        message: `customer row ${index + 1} last name is empty`
      }
    }

    return {
      pass: true,
      message: 'Pass'
    }
  }

  const setParsedJsonResult = (result) => {
    const error = []
    const data = []

    result.forEach((customer, index) => {
      const validation = validationAttributes(customer, index)
      if (!validation.pass) {
        error.push(validation.message)
      } else if (error.length === 0) {
        data.push({
          first_name: customer.first_name,
          last_name: customer.last_name,
          email: customer.email,
          password: customer.phone_number,
          phone_number: customer.phone_number,
          addresses_attributes: [
            {
              name: customer.address_name,
              latitude: parseFloat(customer.lat),
              longitude: parseFloat(customer.lng),
              house_number: customer.house_number,
              phone_number: customer.address_phone_number
            }
          ]
        })
      }
    })

    if (error.length > 0) {
      dispatch(setFlashMessage('error', error.toString()))
    } else {
      dispatch(setFlashMessage('success', 'CSV format validation pass'))
      setJsonResult({
        customers_json: JSON.stringify(data)
      })
    }
  }

  return (
    <PageLayout title={t('generate')} back='/customer'>
      <Container>
        <div>
          <CsvUpload onHandleDrop={(result) => setParsedJsonResult(result)} />
        </div>
        {jsonResult !== false ? (
          <div>
            <Button
              color='primary'
              variant='contained'
              size='small'
              onClick={handleOnClick}
            >
              {t('generate')}
            </Button>
          </div>
        ) : (
          <div />
        )}
      </Container>
    </PageLayout>
  )
}

export default GenerateCustomerPage
