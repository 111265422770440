import { connect } from 'react-redux'
import { List } from 'immutable'

import { potentialProviderSchema, inquirySchema } from '@seekster/schemas'

import { getResource } from 'selectors/resources'
import { fetchChildren } from 'actions/collections'

import AssignProvidersListPanel from './AssignProvidersListPanel'

const mapStateToProps = (state, { match }) => ({
  inquiry: getResource(state, match.params.id, inquirySchema),
  getAvailableProviders: () => {
    const resource = getResource(state, match.params.id, inquirySchema)

    if (resource) {
      return resource.get('potential_providers', List())
    }
    else {
      return List()
    }
  }
})

const mapDispatchToProps = {
  fetchAvailableProviders: (id, options) => fetchChildren(inquirySchema, id, potentialProviderSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignProvidersListPanel)
