import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { FileCopyRounded, Cancel, Delete } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import { SubscriptionMonthsPane, SubscriptionOverviewPane } from './panes'
import { SUBSCRIPTION_EDITABLE_STATES } from 'constants/states/subscriptionEditableStates'

export function SubscriptionShowPage ({
  subscription,
  fetchSubscription,
  push,
  cancel,
  destroy,
  setFlashMessage,
  location,
  match,
  t
}) {
  const { loading } = useData(() => fetchSubscription(match.params.id))

  function handleDestroy () {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      destroy(match.params.id)
        .then(response => {
          push(`/subscriptions`)
        })
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function handleCancel () {
    if (window.confirm('Are you sure you want to cancel this ?')) {
      cancel(match.params.id, 'cancel')
        .then(response => {
          push(`/subscriptions/${match.params.id}`)
        })
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function secondaryActions () {
    let actions = [{
      avatar: <FileCopyRounded />,
      content: t('duplicate'),
      to: `/subscriptions/${match.params.id}/duplicate`
    }, {
      avatar: <Delete />,
      content: t('delete'),
      onClick: handleDestroy
    }]

    if (SUBSCRIPTION_EDITABLE_STATES.includes(subscription.get('state'))) {
      actions = [
        ...actions,
        {
          avatar: <Cancel />,
          content: t('cancel'),
          onClick: handleCancel
        }
      ]
    }

    return actions
  }

  function subNavbar () {
    const path = `/subscriptions/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('common:navbar.overview')} to={path} />
        <NavigationMenuItem label={t('months')} to={`${path}/months`} />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs () {
    return [{
      text: t('title'),
      link: '/subscriptions'
    }, {
      text: subscription.get('number')
    }]
  }

  return (
    <PageLayout
      title={subscription.get('number')}
      actions={secondaryActions()}
      subNavbar={subNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/subscriptions/:id' component={SubscriptionOverviewPane} exact />
        <AuthenticatedRoute path='/subscriptions/:id/months' component={SubscriptionMonthsPane} />
      </Switch>
    </PageLayout>
  )
}

SubscriptionShowPage.ropTypes = {
  subscription: ImmutablePropTypes.map,
  fetchSubscription: PropTypes.func,
  push: PropTypes.func,
  cancel: PropTypes.func,
  destroy: PropTypes.func,
  setFlashMessage: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.object,
  t: PropTypes.func
}

SubscriptionShowPage.defaultProps = {
  subscription: Map()
}

export default withTranslation(['subscriptions', 'common'])(SubscriptionShowPage)
