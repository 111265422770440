import React from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/lib/Creatable'

export class TextCreatableSelect extends React.Component {
  static propTypes = {
    value: PropTypes.array,
    options: PropTypes.array,
    onChange: PropTypes.func
  }

  static defaultProps = {
    value: [],
    options: []
  }

  constructor (props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleCreate = this.handleCreate.bind(this)

    this.state = {
      value: [],
      options: []
    }
  }

  componentDidMount () {
    const value = this.props.value.map(element => (
      { label: element, value: element }
    ))
    const options = this.props.options.map(option => (
      { label: option, value: option })
    )

    this.setState({
      value,
      options: [...options, ...value]
    })
  }

  handleChange (data) {
    this.setState({ value: data })

    if (this.props.onChange) {
      this.props.onChange(data.map(option => option.value))
    }
  }

  handleCreate (newValue) {
    this.setState({
      value: [...this.state.value, { label: newValue, value: newValue }],
      options: [...this.state.options, { label: newValue, value: newValue }]
    })

    this.handleChange([...this.state.value, { label: newValue, value: newValue }])
  }

  render () {
    return (
      <CreatableSelect
        isClearable
        isMulti
        openMenuOnClick={this.state.options.length !== 0}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        onCreateOption={this.handleCreate}
        {...this.props}
        value={this.state.value}
        options={this.state.options}
        onChange={this.handleChange}
      />
    )
  }
}

export default TextCreatableSelect
