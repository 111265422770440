import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import MomentPropTypes from 'react-moment-proptypes'
import { DayPickerSingleDateController, CalendarDay } from 'react-dates'
import { xorWith } from 'lodash'

import './DatePicker.scss'

export function DatePicker({
  date,
  dates,
  onChange,
  isMulti,
  disabledPastDate,
  readOnly,
  highlightedDates,
  highlightLegendDescription,
  ...rest
}) {
  function handleDateChange(targetDate) {
    let newDate

    if (isMulti) {
      const isExistingDate = (existingDate, targetDate) =>
        targetDate.isSame(existingDate, 'day')

      newDate = xorWith(dates, [targetDate], isExistingDate)
    } else {
      newDate = targetDate
    }

    onChange && onChange(newDate)
  }

  function isSelectedDate(day) {
    if (isMulti) {
      return dates.some((date) => day && date.isSame(day, 'day'))
    } else {
      return date && date.isSame(day, 'day')
    }
  }

  function isHighlightedDate(day) {
    return highlightedDates.some((hightlightedDate) =>
      hightlightedDate.isSame(day, 'day')
    )
  }

  function setModifiers(modifiers, day) {
    const newModifiers = new Set([
      ...modifiers,
      isSelectedDate(day) ? 'selected' : null,
      isHighlightedDate(day) ? 'highlighted-calendar' : null
    ])

    newModifiers.delete(null)

    return newModifiers
  }

  return (
    <div className='date-picker'>
      <DayPickerSingleDateController
        hideKeyboardShortcutsPanel
        numberOfMonths={1}
        isOutsideRange={(day) =>
          disabledPastDate && day.isBefore(moment().startOf('day'))
        }
        onDateChange={!readOnly && handleDateChange}
        renderCalendarDay={(calendarProps) => {
          const { day, modifiers } = calendarProps

          return (
            <CalendarDay
              {...calendarProps}
              modifiers={modifiers && setModifiers(modifiers, day)}
            />
          )
        }}
        {...rest}
      />

      {highlightedDates && highlightLegendDescription && (
        <div>
          <span className='legend-dot' />
          <span className='legend-text'>{highlightLegendDescription}</span>
        </div>
      )}
    </div>
  )
}

DatePicker.propTypes = {
  date: MomentPropTypes.momentObj,
  dates: PropTypes.arrayOf(MomentPropTypes.momentObj),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  disabledPastDate: PropTypes.bool,
  readOnly: PropTypes.bool,
  highlightedDates: PropTypes.array,
  highlightLegendDescription: PropTypes.node
}

DatePicker.defaultProps = {
  dates: [],
  highlightedDates: []
}

export default DatePicker
