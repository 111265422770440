import React from 'react'
import PropTypes from 'prop-types'

import './AttributeLabel.scss'

export class AttributeLabel extends React.Component {
  static propTypes = {
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node,
    children: PropTypes.node,
    divided: PropTypes.bool
  }

  render() {
    const { title, subtitle, children, divided, ...rest } = this.props

    return (
      <div
        className='attribute-label'
        style={
          divided ? { paddingBottom: '1rem', borderBottom: '1px solid #f0f0f0' } : {}
        }
        {...rest}
      >
        <label className='label-title'>
          {title} {subtitle}
        </label>
        <div className='label-content'>{children}</div>
      </div>
    )
  }
}

export default AttributeLabel
