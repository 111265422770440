import {
  SET_APP_CONFIG_SUCCEEDED,
  LOAD_APP_CONFIGS_SUCCEEDED
} from 'constants/configurations'

export const setAppConfig = (config) => ({
  type: SET_APP_CONFIG_SUCCEEDED,
  payload: config
})

export const loadAppConfigs = () => ({
  type: LOAD_APP_CONFIGS_SUCCEEDED
})
