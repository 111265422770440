import React, { useEffect, Fragment, useState } from 'react'
import { Form } from 'formik'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, TextareaAutosize } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import ButtonLoader from 'components/buttons/ButtonLoader'
import arrayToOptions from 'utils/arrayToOptions'
import SelectInput from 'components/inputs/SelectInput'
import Panel from 'components/containers/Panel'
import Field from 'components/fields/Field'

const exampleImg = 'https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/Img.png'
const guildeIcon = 'https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/icon/question-circle.png'

const useStyles = makeStyles(() => ({
  container: {
    padding: '16px 24px'
  },
  spanContainer: {
    display: 'flex',
    paddingBottom: 24
  },
  noticeIcon: {
    marginRight: 8,
    width: 21,
    height: 21
  },
  secondaryPanel: {
    paddingTop: 40
  },
  image: {
    height: 450
  },
  button: {
    marginTop: 20,
    marginRight: 100,
    padding: '6px 44px'
  },
  panelShadow: {
    boxShadow: '0 2px 10px 0 rgba(182,182,182,0.4)'
  }
}))

export function PayrollSettingsForm ({ values, setFieldValue, isCreate, isSubmitting, errors }) {
  const classes = useStyles()
  const { t } = useTranslation(['attributes', 'payrolls'])
  const [amountPayroll, setAmountPayroll] = useState()

  useEffect(() => {
    if (values.payout_cycles_attributes.length !== undefined) {
      setAmountPayroll(values.payout_cycles_attributes.length)
    }
  }, [values.payout_cycles_attributes.length]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (values.payout_cycles_attributes.length !== 0) {
      const cycleAmount = values.payout_cycles_attributes.length

      if (cycleAmount === 1) {
        setFieldValue('payout_cycles_attributes[0].cycle_end_day', values.payout_cycles_attributes[0].cycle_start_day)
        setFieldValue('payout_cycles_attributes[0].payout_day', values.payout_cycles_attributes[0].cycle_start_day)
      }
      else if (cycleAmount > 1) {
        for (let i = 0; i < cycleAmount; i++) {
          i + 1 < cycleAmount && setFieldValue(`payout_cycles_attributes[${i + 1}].cycle_start_day`, values.payout_cycles_attributes[i].cycle_end_day)
          setFieldValue(`payout_cycles_attributes[${i}].payout_day`, values.payout_cycles_attributes[i].cycle_end_day)
        }

        setFieldValue(`payout_cycles_attributes[${cycleAmount - 1}].cycle_end_day`, values.payout_cycles_attributes[0].cycle_start_day)
      }
    }
  }, [values.payout_cycles_attributes]) // eslint-disable-line react-hooks/exhaustive-deps

  function handleOnchange (value) {
    const newArray = []
    let i

    setAmountPayroll(value)

    for (i = 0; i < value; i++) {
      newArray.push({
        cycle_start_day: '',
        cycle_end_time: '18:00:00',
        cycle_start_time: '18:00:01',
        payout_time: '23:59:59',
        cycle_end_day: ''
      })
    }

    setFieldValue('payout_cycles_attributes', newArray)
  }

  return (
    <Grid container justify='center' component={Form}>
      <Grid item xs={12} sm={12}>
        <div className={classes.panelShadow}>
          <Panel
            title={isCreate ? t('create_payroll') : t('edit_payroll')}
            headerStyle={{ height: 80, maxHeight: 80 }}
          >
            <div className={classes.container}>
              <span className={classes.spanContainer}>
                <img src={guildeIcon} alt='guilde' className={classes.noticeIcon} />
                <div>
                  {t('content')}
                </div>
              </span>

              <Grid container xs={12} spacing={3}>
                <Grid item xs={12} sm={6}>
                  <label>{t('amount_payroll')}</label>
                  <SelectInput
                    component={SelectInput}
                    options={arrayToOptions([1, 2, 3, 4])}
                    onChange={(value) => handleOnchange(value)}
                    isDisabled={!isCreate}
                    value={amountPayroll}
                  />
                </Grid>
              </Grid>

              {
                values.payout_cycles_attributes.map((cycle, index) => (
                  <Fragment key={`${index}_${values.payout_cycles_attributes.length}`}>
                    <h2>Payroll {t('round')} {index + 1}</h2>
                    <Grid container xs={12} spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <label>{t('cycle_start_day')}</label>
                        <Field name={`payout_cycles_attributes[${index}].cycle_start_day`} noLabel disabled={index !== 0 || !isCreate} placeholder={index === 0 && t('only_number')} hint={t('collect_time')} />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <label>{t('cycle_end_day')}</label>
                        <Field name={`payout_cycles_attributes[${index}].cycle_end_day`} noLabel disabled={(index + 1 === values.payout_cycles_attributes.length) || !isCreate} placeholder={(values.payout_cycles_attributes.length !== 1 && index + 1 !== values.payout_cycles_attributes.length) && t('only_number')} hint={t('collect_end_time')} />
                      </Grid>
                    </Grid>
                  </Fragment>
                ))
              }
            </div>
          </Panel>
        </div>

        {
          values.payout_cycles_attributes.length > 0 && (
            <div className={classes.secondaryPanel}>
              <div className={classes.panelShadow}>
                <Panel
                  title={t('remark')}
                  headerStyle={{ height: 80, maxHeight: 80 }}
                >
                  <div className={classes.container}>
                    <span className={classes.spanContainer}>
                      <img src={guildeIcon} alt='guilde' className={classes.noticeIcon} />
                      <div>
                        {t('remark_content')}
                      </div>
                    </span>

                    <Grid container xs={12} spacing={3}>
                      <Grid item xs={6} container direction='column'>
                        <Grid item xs>
                          <label>{t('fee_field')}</label>
                          <Field name='fee_field' noLabel component={TextareaAutosize} />
                        </Grid>

                        <Grid item xs>
                          <label>{t('remark_field')}</label>
                          <Field name='remark_field' noLabel component={TextareaAutosize} />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <p>{t('remark_example')}</p>

                        <img src={exampleImg} alt='example' className={classes.image} />
                      </Grid>
                    </Grid>
                  </div>
                </Panel>
              </div>
            </div>
          )
        }
      </Grid>

      <Grid item xs={12} container justify='flex-end'>
        <ButtonLoader
          type='submit'
          variant='contained'
          color={isSubmitting ? 'grey' : 'primary'}
          className={classes.button}
          isLoading={isSubmitting}
          disabled={Object.keys(errors).length !== 0}
        >
          {t('save')}
        </ButtonLoader>
      </Grid>
    </Grid>
  )
}

export default PayrollSettingsForm
