import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useResource, useResourceAction } from 'hooks/resources'
import { jobSchema } from '@seekster/schemas'

import PageLayout from 'components/layouts/PageLayout'
import Panel from 'components/containers/Panel'
import JobRescheduleForm from 'forms/actions/JobRescheduleForm'
import TimePickerToggleButton from 'components/buttons/TimePickerToggleButton'

export function JobReschedulePage({ match }) {
  const { t } = useTranslation(['jobs', 'attributes'])
  const [job, { loading }] = useResource(jobSchema, match.params.id)
  const { triggerWithData: triggerJob } = useResourceAction(jobSchema)
  const slotEnabled = job.getIn(['service', 'slot_enabled'])

  function handleReschedule(data) {
    return triggerJob(match.params.id, 'reschedule', data)
  }

  function handleRedistribute(data) {
    return triggerJob(match.params.id, 'redistribute', data)
  }

  function breadcrumbs() {
    return [
      { text: 'Jobs', link: '/jobs' },
      { text: job.get('number'), link: `/jobs/${match.params.id}` },
      { text: t('reschedule') }
    ]
  }

  return (
    <PageLayout title={job.get('number')} breadcrumbs={breadcrumbs()} isLoading={loading}>
      <Grid container justify='center'>
        <Grid item xs={12} sm={9}>
          <Panel
            title={t('start_time')}
            actions={slotEnabled ? null : <TimePickerToggleButton />}
          >
            <JobRescheduleForm
              job={job}
              onReschedule={handleReschedule}
              onRedistribute={handleRedistribute}
            />
          </Panel>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

JobReschedulePage.propTypes = {
  match: PropTypes.object
}

export default JobReschedulePage
