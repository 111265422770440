import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { compose } from 'redux'
import { withMobileDialog, Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import AccountMergingPanel from 'modules/accountMerges/AccountMergingPanel'
import UsersListPanel from 'modules/users/UsersListPanel'
import { customerSchema } from '@seekster/schemas'

export function CustomerAccountMergesPage ({
  customer,
  fetchCustomer,
  fetchCustomerDevices,
  match,
  fullScreen: isMobile,
  t
}) {
  const { loading } = useData(() => {
    return fetchCustomer(match.params.id)
      .then(() => fetchCustomerDevices(match.params.id))
  })

  return (
    <PageLayout
      title={t('merge')}
      isLoading={loading}
      back={`/customers/${match.params.id}`}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <AuthenticatedRoute
            path='/customers/:id/account_merges'
            component={UsersListPanel}
            componentProps={{
              schema: customerSchema
            }}
            exact={isMobile}
          />
        </Grid>

        <Grid item xs={12} sm={6} style={{ marginTop: isMobile && '-24px' }}>
          <AuthenticatedRoute
            path='/customers/:id/account_merges/:id'
            component={AccountMergingPanel}
            componentProps={{
              schema: customerSchema,
              targetUser: customer
            }}
          />
        </Grid>
      </Grid>
    </PageLayout>
  )
}

CustomerAccountMergesPage.propTypes = {
  customer: ImmutablePropTypes.map,
  fetchCustomer: PropTypes.func,
  fetchCustomerDevices: PropTypes.func,
  match: PropTypes.object,
  fullScreen: PropTypes.bool,

  t: PropTypes.func
}

CustomerAccountMergesPage.defaultProps = {
  customer: Map()
}

export default compose(
  withMobileDialog(),
  withTranslation('customers')
)(CustomerAccountMergesPage)
