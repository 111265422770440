import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'formik'
import { Grid, Button } from '@material-ui/core'
import { Field } from 'components/fields/Field'
import ButtonLoader from 'components/buttons/ButtonLoader'
import ResourceFirebaseCreateTableSelect from 'components/inputs/ResourceFirebaseCreateTableSelect'

import styles from './FirebaseTagFormStyles'

export function FirebaseTagForm ({
  classes,
  onCloseModal,
  isSubmitting,
  path,
  values,
  tenant,
  dirty
}) {
  return (
    <Form className={classes.root}>
      <Grid item xs={12} alignItems='center'>
        <Field
          name='tagsRef'
          label='Tags'
          component={ResourceFirebaseCreateTableSelect}
          className={classes.resourceSelectContainer}
          path={path}
          tenant={tenant}
          value={values.tagsRef}
        />
      </Grid>

      <Grid item xs={12} container justify='flex-end'>
        {
          dirty &&
            <ButtonLoader
              className={classes.button}
              variant='contained'
              color='primary'
              type='submit'
              isLoading={isSubmitting}
            >
            Save
            </ButtonLoader>
        }

        <Button
          type='button'
          variant='contained'
          className={classes.button}
          onClick={onCloseModal}
        >
          cancel
        </Button>
      </Grid>
    </Form>
  )
}

FirebaseTagForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  isSubmitting: PropTypes.bool,
  dirty: PropTypes.bool,
  path: PropTypes.string,
  tenant: PropTypes.string,
  onCloseModal: PropTypes.func
}

export default withStyles(styles)(FirebaseTagForm)
