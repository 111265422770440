import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { feedbackSchema } from '@seekster/schemas'

import FeedbackOverviewPane from './FeedbackOverviewPane'

const mapStateToProps = (state, { match }) => ({
  feedback: getResource(state, match.params.id, feedbackSchema)
})

export default connect(mapStateToProps)(FeedbackOverviewPane)
