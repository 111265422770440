/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import './cancelcustom.scss'
import { Field, ErrorMessage } from 'formik'

import './setupfee.scss'

const cancellationFormSection = (props) => {
  const {
    action,
    func,
    id,
    //  formikName,
    rawData,
    switchUpdate
  } = props
  const [open, setOpen] = useState(false)
  const [selectShow, setSelectShow] = useState('')
  const [valueCheck, setValueCheck] = useState('')

  const changeShow = (value, nameData) => {
    setSelectShow(value)
    setOpen(false)
    action.values.create_at && switchUpdate(action)
    action.setFieldValue(nameData, value)
  }

  const checkPlace = () => {
    if (selectShow === 'percent') {
      return 'Percentage  %'
    } else if (selectShow === 'baht') {
      return 'Fixed Price  ฿'
    }
    return 'Select choice'
  }

  const changeValue = (valueNumber, valueName) => {
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/
    const b = regex.test(valueNumber)
    if (b) {
      setValueCheck(valueNumber)
      action.setFieldValue(valueName, valueNumber)
    }
    action.values.create_at && switchUpdate(action)
    setOpen(false)
  }

  const renderIcon = () => {
    return (
      <>
        {selectShow === 'percent' || rawData.setFee === 'percent' ? '%' : ''}
        {selectShow === 'baht' || rawData.setFee === 'baht' ? '฿' : ''}
      </>
    )
  }

  const changeHrsAndmin = (values, name) => {
    action.setFieldValue(name, values)
    switchUpdate(action)
  }

  return (
    <div>
      <div className='d-flex align-item-center section-set-up-fees-custom'>
        <div className='section-time-fees-set-limit-custom'>
          <div className='input-hrs-custom'>
            <Field
              name={`cancellationConditionFees.${id}.hrs`}
              type='text'
              className='input-time hrs'
              autoComplete='off'
              onChange={(e) => changeHrsAndmin(e.target.value, `cancellationConditionFees.${id}.hrs`)}
            />
            <div className='text-position-custom'>hrs</div>
          </div>
        </div>
        <div className='section-time-fees-set-limit-custom'>
          <div className='input-min-custom'>
            <Field
              name={`cancellationConditionFees.${id}.min`}
              className='input-time min'
              autoComplete='off'
              onChange={(e) => changeHrsAndmin(e.target.value, `cancellationConditionFees.${id}.min`)}
            />
            <div className='text-position-custom'>min</div>
          </div>
        </div>
        <div className='section-time-fees-set-fee-custom'>
          <div className='section-select-choice'>
            <div
              className='input-select-custom pointer'
              onClick={() => {
                setOpen((pre) => !pre)
              }}
              onBlur={() => setOpen(false)}
            >
              <div>
                <Field
                  name={`cancellationConditionFees.${id}.value`}
                  className={`input-select-set-fee font-fee ${valueCheck.length > 0 && 'show-icon-percen-bath'
                    }`}
                  placeholder={checkPlace()}
                  onChange={(e) => {
                    changeValue(e.target.value, `cancellationConditionFees.${id}.value`)
                  }}
                  autoComplete='off'
                />
                <div className='icon'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16.648'
                    height='9.52'
                    viewBox='0 0 16.648 9.52'
                    className={`${open ? 'animation-close' : 'animation-open'}`}
                  >
                    <path
                      id='Icon_ionic-ios-arrow-down'
                      data-name='Icon ionic-ios-arrow-down'
                      d='M14.515,17.9l6.3-6.3a1.185,1.185,0,0,1,1.68,0,1.2,1.2,0,0,1,0,1.685l-7.133,7.138a1.188,1.188,0,0,1-1.641.035L6.534,13.286A1.19,1.19,0,0,1,8.215,11.6Z'
                      transform='translate(-6.188 -11.246)'
                      fill='gray'
                    />
                  </svg>
                </div>

                <div className='show-select-custom'>{renderIcon()}</div>
              </div>
            </div>
            {open && (
              <div className='show-menu pointer'>
                <div
                  className='d-flex choice-custom-fee'
                  onMouseDown={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    changeShow('percent', `cancellationConditionFees.${id}.setFee`)
                  }}
                >
                  <div>Percentage</div>
                  <div>%</div>
                </div>
                <div
                  className='d-flex choice-custom-fee'
                  onMouseDown={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    changeShow('baht', `cancellationConditionFees.${id}.setFee`)
                  }}
                >
                  <div>Fixed Price</div>
                  <div>฿</div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='section-time-fees-icon-delete'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='14.651'
            height='18.032'
            viewBox='0 0 14.651 18.032'
            onClick={() => {
              action.values.create_at && switchUpdate(action)
              func.remove(id)
            }}
            className='pointer'
          >
            <path
              id='Icon_metro-bin'
              data-name='Icon metro-bin'
              d='M5.626,7.563v11.27A1.13,1.13,0,0,0,6.753,19.96H16.9a1.13,1.13,0,0,0,1.127-1.127V7.563H5.626ZM9.007,17.706H7.88V9.817H9.007Zm2.254,0H10.134V9.817h1.127Zm2.254,0H12.388V9.817h1.127Zm2.254,0H14.642V9.817h1.127ZM18.3,4.182H14.642V2.773a.848.848,0,0,0-.845-.845H9.852a.848.848,0,0,0-.845.845V4.182H5.344a.848.848,0,0,0-.845.845V6.436H19.15V5.027a.848.848,0,0,0-.845-.845Zm-4.79,0H10.134V3.069h3.381V4.182Z'
              transform='translate(-4.499 -1.928)'
              fill='#e83528'
            />
          </svg>
        </div>
        <div className='text-validate pl-2'>
          <ErrorMessage
            name={`cancellationConditionFees.${id}.hrs`}
            component='div'
            className='field-error'
          />
        </div>
        <div className='text-validate pl-2'>
          <ErrorMessage
            name={`cancellationConditionFees.${id}.min`}
            component='div'
            className='field-error'
          />
        </div>
        <div className='text-validate pl-2'>
          <ErrorMessage
            name={`cancellationConditionFees.${id}.value`}
            component='div'
            className='field-error'
          />
        </div>
        <div className='text-validate pl-2'>
          <ErrorMessage
            name={`cancellationConditionFees.${id}.setFee`}
            component='div'
            className='field-error'
          />
        </div>
      </div>
    </div>
  )
}

export default cancellationFormSection
