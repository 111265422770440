import React from 'react'
import { connect, getIn } from 'formik'
import { Grid } from '@material-ui/core'

import InputField from 'components/fields/InputField'
import SortableFormSection from 'components/fields/SortableFieldArray/SortableFormSection.new'

import './SlotTimes.scss'

function SlotTimes({ name, formik, ...props }) {
  const value = getIn(formik.values, name)
  const title = value.name ? value.name : 'Slot name'
  return (
    <div className='slot-times-container'>
      <SortableFormSection title={title} name={name} formik={formik} {...props}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputField
              label='Slot name'
              name={`${name}.name`}
              variant='outlined'
              size='small'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              type='number'
              label='Quantity'
              name={`${name}.quantity`}
              variant='outlined'
              size='small'
              error={getIn(formik.errors, `${name}.quantity`)}
              helperText={getIn(formik.errors, `${name}.quantity`)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              label='Start Time'
              type='time'
              name={`${name}.start_time`}
              variant='outlined'
              size='small'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              label='End Time'
              type='time'
              name={`${name}.end_time`}
              variant='outlined'
              size='small'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
      </SortableFormSection>
    </div>
  )
}

export default connect(SlotTimes)
