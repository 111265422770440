const isIP = (hostname) => {
  return /^(?!.*\.$)((?!0\d)(1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(hostname)
}

const isLocal = hostname => hostname === 'localhost' || isIP(hostname)

const withTenant = (data = {}) => {
  if (window && window.location) {
    if (process.env.NODE_ENV === 'development' && isLocal(window.location.hostname)) {
      return { ...data, tenant: 'seekster' }
    }
    else {
      return { ...data, tenant: window.location.hostname.split('.').slice(0, -2).join('.') }
    }
  }
  else {
    return data
  }
}

export default withTenant
