import React from 'react'
import PropTypes from 'prop-types'
import { FilterAssociationTags } from '../../FilterAssociationTags'

export class FilterCategoryTags extends React.Component {
  static propTypes = {
    categoryName: PropTypes.string,
    fetchCategory: PropTypes.func,
    query: PropTypes.object,
    onQueryPush: PropTypes.func
  }

  static defaultProps = {
    categoryName: '',

    fetchService: () => {}
  }

  componentDidMount () {
    const { query, fetchCategory } = this.props

    query.categories_id && fetchCategory(query.categories_id)
  }

  renderTag () {
    if (this.props.query.categories_id) {
      return (
        <FilterAssociationTags
          content={`Category: ${this.props.categoryName}`}
          name='categories_id'
          query={this.props.query}
          onQueryPush={this.props.onQueryPush}
        />
      )
    }
    else {
      return null
    }
  }

  render () {
    return this.renderTag()
  }
}

export default FilterCategoryTags
