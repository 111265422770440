import React, { useState, useEffect, useRef } from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import {
  ListItem, ListItemAvatar, ListItemText,
  Avatar, withMobileDialog, Chip
} from '@material-ui/core'
import {
  AssignmentInd,
  Edit,
  CheckCircleOutline,
  NewReleases,
  PriorityHighRounded,
  Settings
} from '@material-ui/icons'
import {
  ticketSchema,
  messageSchema,
  agentSchema,
  firebaseUserSchema,
  notificationSchema,
  settingSchema,
  departmentSchema
} from '@seekster/schemas'
import { Button } from 'components/buttons'
import Modal from 'components/containers/Modal'
import Panel from 'components/containers/Panel'
import CircularLoader from 'components/CircularLoader'
import RelativeTimeFormatter from 'components/formatters/RelativeTimeFormatter'
import { LightboxImage } from 'components/images'
import { ResponsiveMenu, ResponsiveMenuItem } from 'components/menus/ResponsiveMenu'
import Tag from 'components/Tag'
import { FirebaseRefTag } from 'components/tags'
import { getCurrentResource } from 'selectors/authentication'
import TicketChatFrom from 'forms/actions/TicketChatFrom'
import AgentAssignmentForm from 'forms/actions/AgentAssignmentForm'
import { useReference } from 'hooks/utils'
import { useCollectionChildren } from 'hooks/firebase/collections'
import { useResourceAction as useFirebaseResourceAction, useResource as useFirebaseResource } from 'hooks/firebase/resources'
import { useResource, useResourceAction } from 'hooks/resources'
import FirebaseTagForm from 'forms/actions/FirebaseTagForm'
import PriorityForm from 'forms/actions/PriorityForm'
import SettingsTicketForm from 'forms/actions/SettingsTicketForm'
import InfiniteScroll from 'react-infinite-scroller'
import styles from './TicketChatPanelStyles'
import groupByStartDate from 'utils/groupByStartDate'
import Linkify from 'react-linkify'

const defaultAvatar = 'https://s3-ap-southeast-1.amazonaws.com/seekster-web/assets/defaults/default_avatar.png'

export const UserType = ({ isDepartment, id, userTypeSchema, exact }) => {
  const [user, { loading }] = useResource(userTypeSchema, id, [id, userTypeSchema])

  return (
    <>
      {
        loading ? null : isDepartment
          ? (exact ? (user.get('name').length > 5 ? `${(user.get('name').slice(0, 5))}...` : user.get('name')) : user.get('name'))
          : (exact ? (user.get('display_name').length > 5 ? `${(user.get('display_name').slice(0, 5))}...` : user.get('display_name')) : user.get('display_name'))
      }
    </>
  )
}

export function TicketChatPanel ({
  match,
  classes,
  exact,
  tenant
}) {
  const { t } = useTranslation('tickets')
  const messagesEndRef = useRef(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalEditTagOpen, setModalEditTagOpen] = useState(false)
  const [modalPriorityOpen, setModalPriorityOpen] = useState(false)
  const [modalSettingTicketOpen, setModalSettingTicketOpen] = useState(false)
  const CurrentAgent = useSelector(state => getCurrentResource(state, agentSchema)) || Map()
  const [ticket] = useFirebaseResource(`tenants/${tenant}/tickets`, match.params.id, ticketSchema)
  const [settings] = useFirebaseResource(`tenants/${tenant}/settings`, 'ticketConfiguration', settingSchema)
  // const [agent] = useResource(agentSchema, ticket.get('assignee'), [ticket.get('assignee')])
  const [user] = useReference(ticket.get('requestIdRef'), firebaseUserSchema)
  const [limit, setLimit] = useState(30)

  const [messages, { loading }] = useCollectionChildren(
    `tenants/${tenant}/tickets/${match.params.id}/messages`,
    messageSchema,
    ticketSchema,
    match.params.id,
    query => {
      return query.orderBy('created_at', 'desc').limit(limit)
    },
    [limit]
  )
  const isBlocked = !user.get('follow')
  const isOpen = ticket.get('status') === 'open' || ticket.get('status') === 'pending'
  const userTypeSchema = ticket.get('department') ? departmentSchema : agentSchema
  const { create: createMessage } = useFirebaseResourceAction(messageSchema)
  const { update: updateTicket } = useFirebaseResourceAction(ticketSchema)
  const { create: createNotification } = useResourceAction(notificationSchema)

  const scrollToBottom = () => {
    (messagesEndRef.current !== null && limit === 30) && messagesEndRef.current.scrollIntoView()
  }

  useEffect(scrollToBottom, [messages, messagesEndRef])

  useEffect(() => {
    if (!ticket.get('is_checked')) {
      updateTicket(match.params.id, `tenants/${tenant}/tickets`, { is_checked: true })
    }
  }, [ticket, updateTicket, match.params.id, tenant])

  function loadMorePost (page) {
    setLimit(limit => {
      return limit + 10
    })
  }

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target='_blank' rel='noopener noreferrer'>
      {text}
    </a>
  )

  function actions () {
    return (
      <>
        {
          ticket.get('assignee') ? (
            <Tag
              onClick={() => setModalOpen(true)}
              value={<UserType isDepartment={ticket.get('department')} userTypeSchema={userTypeSchema} id={ticket.get('assignee')} exact={exact} />}
              backgroundColor='#1b4170'
              color='white'
              className={classes.tagAction}
            />
          ) : (
            <Button
              color='primary'
              variant='contained'
              onClick={() => setModalOpen(true)}
            >
              <AssignmentInd className={classes.icon} fontSize='small' />
              {!exact && t('assign_agent')}
            </Button>
          )
        }
        <ResponsiveMenu>
          <ResponsiveMenuItem
            avatar={<Edit />}
            onClick={() => setModalEditTagOpen(true)}
            content={t('edit_tag')}
          />

          <ResponsiveMenuItem
            avatar={<PriorityHighRounded />}
            onClick={() => setModalPriorityOpen(true)}
            content={t('set_priority')}
          />

          <ResponsiveMenuItem
            avatar={<Settings />}
            onClick={() => setModalSettingTicketOpen(true)}
            content={t('setting_ticket')}
          />

          <ResponsiveMenuItem
            avatar={ticket.get('is_following') ? <CheckCircleOutline /> : <NewReleases />}
            onClick={() => updateTicket(match.params.id, `tenants/${tenant}/tickets`, { is_following: !ticket.get('is_following') })}
            content={ticket.get('is_following') ? t('read') : t('unread')}
          />

        </ResponsiveMenu>
      </>
    )
  }

  function renderContent () {
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMorePost}
        hasMore
        loader={messages.size === limit && <CircularLoader />}
        isReverse
        threshold={1}
        useWindow={false}
        initialLoad={false}
      >
        {
          groupByStartDate(messages, messageSchema, ['created_at']).map((group, index) => (
            <>
              <div className={classes.chipContanier} key={index}>
                <Chip
                  label={<RelativeTimeFormatter value={group.get('created_at')} />}
                />
              </div>

              {
                group.get('messages').reverse().map((message, index) => {
                  const isSelf = message.get('from') === 'workforce' || message.get('from') === 'bot'

                  return (
                    <ListItem
                      alignItems='flex-start'
                      className={isSelf && classes.messageSelf}
                      key={index}
                    >
                      {
                        !isSelf && (
                          <ListItemAvatar className={classes.avatarContainer}>
                            <Avatar
                              src={user.get('image_url') || defaultAvatar}
                              alt='avatar'
                              className={classes.avatar}
                            />
                          </ListItemAvatar>
                        )
                      }
                      <ListItemText
                        classes={isSelf ? {
                          root: classes.messageContentRootSelf,
                          primary: classes.messageContentSelf
                        } : {
                          root: classes.messageContentRoot,
                          primary: classes.messageContent
                        }}
                        primary={
                          message.get('type') !== 'text' ? <LightboxImage
                            source={{
                              src: message.get('value'),
                              thumbnail: message.get('value')
                            }}
                          /> : <Linkify componentDecorator={componentDecorator}>{message.get('value')}</Linkify>
                        }
                        secondary={
                          <div>
                            {message.get('agent_name') && `${message.get('agent_name')} -`} {moment(message.get('created_at')).format('HH:mm')}
                          </div>
                        }
                        secondaryTypographyProps={{ variant: 'caption' }}
                      />
                    </ListItem>
                  )
                })
              }
            </>
          ))
        }
      </InfiniteScroll>
    )
  }

  return (
    <div className={classes.ticketContainer}>
      <Panel
        title={
          <>
            {
              exact ? <p className={classes.titleContainer}>{`${user.get('name')} ${`(${ticket.get('status')})`} ${isBlocked ? '(Blocked)' : ''}`}</p>
                : `${user.get('name')} ${`(${ticket.get('status')})`} ${isBlocked ? '(Blocked)' : ''}`
            }
          </>
        }
        avatar={
          <>
            <div>
              <Avatar
                src={user.get('image_url') || defaultAvatar}
                alt='avatar'
                className={classes.avatar}
              />
            </div>
          </>
        }
        actions={actions()}
        subheader={
          <>
            {
              ticket.get('tagsRef') ? ticket.get('tagsRef').map((doc, index) => {
                return <FirebaseRefTag doc={doc} key={index} />
              })
                : <> - </>
            }
          </>
        }
        responsive
        noPadding
        square
        footer={
          <TicketChatFrom
            ticketId={match.params.id}
            onSubmit={createMessage}
            agent={CurrentAgent}
            macros={settings.get('macros')}
            disabled={isBlocked || !isOpen}
            tenant={tenant}
          />
        }
      >
        <div className={classes.panelList}>
          {loading ? <CircularLoader /> : renderContent()}
          <div ref={messagesEndRef} />
        </div>

        <Modal
          isOpen={modalOpen}
          onCloseModal={() => setModalOpen(false)}
        >
          <AgentAssignmentForm
            onCloseModal={() => setModalOpen(false)}
            ticketId={match.params.id}
            onSubmit={updateTicket}
            createNoti={createNotification}
            agentId={CurrentAgent.get('id')}
            ticket={ticket}
            tenant={tenant}
          />
        </Modal>

        <Modal
          isOpen={modalEditTagOpen}
          onCloseModal={() => setModalEditTagOpen(false)}
        >
          <FirebaseTagForm
            onCloseModal={() => setModalEditTagOpen(false)}
            path={`tenants/${tenant}/tags`}
            ticket={ticket}
            onSubmit={updateTicket}
            ticketId={match.params.id}
            tenant={tenant}
          />
        </Modal>

        <Modal
          isOpen={modalPriorityOpen}
          onCloseModal={() => setModalPriorityOpen(false)}
        >
          <PriorityForm
            onCloseModal={() => setModalPriorityOpen(false)}
            ticket={ticket}
            onSubmit={updateTicket}
            ticketId={match.params.id}
            tenant={tenant}
          />
        </Modal>

        <Modal
          isOpen={modalSettingTicketOpen}
          onCloseModal={() => setModalSettingTicketOpen(false)}
        >
          <SettingsTicketForm
            onCloseModal={() => setModalSettingTicketOpen(false)}
            ticket={ticket}
            onSubmit={updateTicket}
            ticketId={match.params.id}
            tenant={tenant}
          />
        </Modal>
      </Panel>
    </div>
  )
}

TicketChatPanel.defaultProps = {
  tenant: 'seekster'
}

TicketChatPanel.propTypes = {
  exact: PropTypes.bool,
  match: PropTypes.object,
  classes: PropTypes.object,
  tenant: PropTypes.string
}

export default compose(
  withStyles(styles),
  withMobileDialog()
)(TicketChatPanel)
