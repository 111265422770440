import request, { nodeRequest } from 'utils/request'
import convertJsonBodyToFormData from 'utils/convertJsonBodyToFormData'
import selectQueryToServer from 'utils/selectQueryToServer'

import {
  FETCH_COLLECTION_SUCCEEDED,
  FETCH_NODE_COLLECTION_SUCCEEDED,
  FETCH_CHILDREN_COLLECTION_SUCCEEDED,
  CREATE_COLLECTION_SUCCEEDED
} from 'constants/collections'

const fetchSucceeded = (response, schema, options) => ({
  type: FETCH_COLLECTION_SUCCEEDED,
  response,
  schema,
  options
})

const fetchNodeSucceeded = (response, schema, options, cacheKey) => ({
  type: FETCH_NODE_COLLECTION_SUCCEEDED,
  response,
  schema,
  options,
  cacheKey
})

const fetchNodeChildrenSucceeded = (
  id,
  response,
  schema,
  childrenSchema,
  options,
  cacheKey
) => ({
  type: FETCH_CHILDREN_COLLECTION_SUCCEEDED,
  id,
  response,
  schema,
  childrenSchema,
  options,
  cacheKey
})

const fetchChildrenSucceeded = (id, response, schema, childrenSchema, options) => ({
  type: FETCH_CHILDREN_COLLECTION_SUCCEEDED,
  id,
  response,
  schema,
  childrenSchema,
  options
})

const createCollectionSucceeded = (response, schema, options) => ({
  type: CREATE_COLLECTION_SUCCEEDED,
  response,
  schema,
  options
})

export const createCollection =
  (data, schema, options = {}) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const locale = options.locale
    const accessToken = getState().getIn(['authentication', 'token'])
    const query = options.query || {}

    // const formData = convertJsonBodyToFormData(data)

    const requestBuilder = request
      .post(`/${type}`)
      .locale(locale)
      .query(query)
      .authentication(accessToken)
      .send(data)

    // formData &&
    //   Object.keys(formData).forEach((key) => {
    //     formData[key] !== undefined &&
    //       formData[key] !== null &&
    //       requestBuilder.field(key, formData[key])
    //   })

    return requestBuilder.then((response) => {
      dispatch(createCollectionSucceeded(response, schema, options))
      return response
    })
  }

export const fetch =
  (schema, options = {}) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const url = options.url || `/${type}`
    const locale = options.locale
    const accessToken = getState().getIn(['authentication', 'token'])
    const query = {
      query: selectQueryToServer(schema, options),
      ...options.queryParam,
      ...(options.search && { search: options.search }),
      ...(options.order && { order: options.order }),
      ...(options.filter && {
        filter: selectQueryToServer(schema, { query: options.filter })
      })
    }
    const page = (options.page = options.page || 1)

    return request
      .get(url)
      .query({ ...query, page, locale })
      .authentication(accessToken)
      .then((response) => {
        dispatch(fetchSucceeded(response, schema, options))
        return response
      })
  }

export const fetchChildren =
  (schema, id, childrenSchema, options = {}) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const childrenType = options.childrenType || childrenSchema._key.split('/').pop()
    const url = options.url || `/${type}/${id}/${childrenType}`
    const locale = options.locale
    const accessToken = getState().getIn(['authentication', 'token'])
    const query = {
      query: selectQueryToServer(childrenSchema, options),
      ...options.queryParam
    }
    const page = (options.page = options.page || 1)

    return request
      .get(url)
      .query({ ...query, page, locale })
      .authentication(accessToken)
      .then((response) => {
        dispatch(fetchChildrenSucceeded(id, response, schema, childrenSchema, options))

        return response
      })
  }

export const fetchNode =
  (schema, options = {}, cacheKey) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const url = options.url || `/${type}`

    const query = {
      query: selectQueryToServer(schema, options),
      ...options.queryParam,
      ...(options.query.search && { search: options.query.search }),
      ...(options.query.sort && { sort: options.query.sort }),
      ...(options.filter && {
        filter: selectQueryToServer(schema, { query: options.filter })
      })
    }
    const page = (options.page = options.page || 1)

    return nodeRequest
      .get(url)
      .query({ ...query, page })
      .then((response) => {
        dispatch(fetchNodeSucceeded(response, schema, options, cacheKey))
        return response
      })
  }

export const fetchNodeChildren =
  (schema, id, childrenSchema, options = {}, cacheKey) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const childrenType = options.childrenType || childrenSchema._key.split('/').pop()
    const url = options.url || `/${type}/${id}/${childrenType}`

    const query = {
      query: selectQueryToServer(schema, options),
      ...options.queryParam,
      ...(options.query?.search && { search: options.query.search }),
      ...(options.query?.sort && { sort: options.query.sort }),
      ...(options.filter && {
        filter: selectQueryToServer(schema, { query: options.filter })
      })
    }
    const page = (options.page = options.page || 1)

    return nodeRequest
      .get(url)
      .query({ ...query, page })
      .then((response) => {
        dispatch(fetchChildrenSucceeded(id, response, schema, childrenSchema, options))
        return response
      })
  }
