import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/buttons'
import { Container } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { inquirySchema } from '@seekster/schemas'

import { createCollection } from 'actions/collections'

import CsvUpload from 'components/CsvUpload'
import PageLayout from 'components/layouts/PageLayout'
import { setFlashMessage } from 'actions/interface'
import { usePush } from 'hooks/utils'

export function GenerateInquiryPage() {
  const dispatch = useDispatch()
  const [jsonResult, setJsonResult] = useState(false)
  const push = usePush()
  const { t } = useTranslation('inquiries')

  const handleOnClick = () => {
    dispatch(createCollection(jsonResult, inquirySchema, { type: 'inquiries_generator' }))
      .then(() => {
        push('/inquiries')
      })
      .catch((error) => {
        const errorText = JSON.parse(error.response.text)
        dispatch(setFlashMessage('error', errorText.message))
      })
  }

  const validationAttributes = (inquiry, index) => {
    if (inquiry.service_id === '') {
      return {
        pass: false,
        message: `Inquiry row ${index + 1} service id is empty`
      }
    }

    if (inquiry.package_id === '') {
      return {
        pass: false,
        message: `Inquiry row ${index + 1} package id is empty`
      }
    }

    if (inquiry.customer_id === '') {
      return {
        pass: false,
        message: `Inquiry row ${index + 1} customer id is empty`
      }
    }

    if (inquiry.tenant_id === '') {
      return {
        pass: false,
        message: `Inquiry row ${index + 1} tenant id is empty`
      }
    }

    if (inquiry.start_time === '') {
      return {
        pass: false,
        message: `Inquiry row ${index + 1} start time is empty`
      }
    }

    return {
      pass: true,
      message: 'Pass'
    }
  }

  const setAnswersAttributes = (inquiry) => {
    const answersAttributes = []

    for (const key in inquiry) {
      if (key.match(/question_id/g)) {
        answersAttributes.push({
          question_id: key.split('_').pop(),
          value: inquiry[key]
        })
      }
    }

    return answersAttributes
  }

  const setOrdersAttributes = (inquiry) =>
    inquiry.package_ids.split(',').map((id) => {
      return {
        package_id: id,
        quantity: inquiry.quantity,
        tenant_id: inquiry.tenant_id
      }
    })

  const setJobsAttributes = (inquiry) => {
    return [
      {
        start_time: moment(
          `${inquiry.day}/${inquiry.month}/${inquiry.year}, ${inquiry.hour}:${inquiry.min}`,
          'DD/MM/YYYY, HH:mm'
        ).format('DD-MM-YYYY HH:mm'),
        tenant_id: inquiry.tenant_id
      }
    ]
  }

  const setExpectInvoice = (inquiry) => inquiry.expect_invoice === 'TRUE'

  const setParsedJsonResult = (result) => {
    const error = []
    const data = []
    const expectInvoice = {}

    result.forEach((inquiry, index) => {
      const validation = validationAttributes(inquiry, index)
      if (!validation.pass) {
        error.push(validation.message)
      } else if (error.length === 0) {
        data.push({
          service_id: inquiry.service_id,
          customer_id: inquiry.customer_id,
          tenant_id: inquiry.tenant_id,
          answers_attributes: setAnswersAttributes(inquiry),
          orders_attributes: setOrdersAttributes(inquiry),
          jobs_attributes: setJobsAttributes(inquiry),
          location_attributes: {
            contact_name: inquiry.contact_name,
            phone_number: inquiry.phone_number,
            house_number: inquiry.house_number,
            project_name: inquiry.project_name,
            landmark: inquiry.landmark,
            alley: inquiry.alley,
            street: inquiry.street,
            province: inquiry.province,
            district: inquiry.district,
            sub_district: inquiry.sub_district,
            zip_code: inquiry.zip_code
          }
        })

        expectInvoice[index] = setExpectInvoice(inquiry)
      }
    })

    if (error.length > 0) {
      dispatch(setFlashMessage('error', error.toString()))
    } else {
      dispatch(setFlashMessage('success', 'CSV format validation pass'))
      setJsonResult({
        inquiry_json: JSON.stringify(data),
        expect_invoice: expectInvoice
      })
    }
  }

  return (
    <PageLayout title={t('generate')} back='/inquiries'>
      <Container>
        <div>
          <CsvUpload onHandleDrop={(result) => setParsedJsonResult(result)} />
        </div>
        {jsonResult !== false ? (
          <div>
            <Button
              color='primary'
              variant='contained'
              size='small'
              onClick={handleOnClick}
            >
              {t('generate')}
            </Button>
          </div>
        ) : (
          <div />
        )}
      </Container>
    </PageLayout>
  )
}

export default GenerateInquiryPage
