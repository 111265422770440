import { withFormik } from 'formik'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'
import RegionsFilterForm from './RegionsFilterForm'

const formikConfig = {
  mapPropsToValues: ({ query }) => query,

  enableReinitialize: true,

  handleSubmit: (values, { props }) => {
    props.onQueryPush(values)

    props.onCloseModal()
  }
}

export default compose(
  withQueryParser,
  withFormik(formikConfig)
)(RegionsFilterForm)
