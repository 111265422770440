import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { connect } from 'formik'
import { Grid, TextareaAutosize } from '@material-ui/core'

import Input from 'components/inputs/Input'
import SelectInput from 'components/inputs/SelectInput'
import RadioInput from 'components/inputs/RadioInput'
import CheckboxInput from 'components/inputs/CheckboxInput'
import DropzoneInput from 'components/inputs/DropzoneInput'

export function BookingQuestionsFormSection({
  name,
  formik,
  fetchService,
  getQuestions
}) {
  const questions = getQuestions(formik.values.service_id) || List()

  useEffect(
    () => {
      fetchService(formik.values.service_id)

      return () => {
        formik.setFieldValue(name, undefined)
      }
    },
    [formik.values.service_id] // eslint-disable-line react-hooks/exhaustive-deps
  )

  function getAnswerIndex(question) {
    let answerIndex = 0

    const index =
      formik.values[name] &&
      formik.values[name].findIndex((answer) => answer.question_id === question.get('id'))

    if (index === -1) {
      answerIndex = formik.values[name].length
    } else if (index) {
      answerIndex = index
    }

    return answerIndex
  }

  function renderAnswer(question) {
    const answer =
      formik.values[name] &&
      formik.values[name].find((answer) => answer.question_id === question.get('id'))

    switch (question.get('display_type')) {
      case 'string':
        return (
          <Input
            value={answer ? answer.value : ''}
            onChange={(e) =>
              formik.setFieldValue(`${name}[${getAnswerIndex(question)}]`, {
                ...answer,
                question_id: question.get('id'),
                value: e.target.value
              })
            }
            placeholder={question.get('placeholder')}
          />
        )
      case 'textarea':
        return (
          <TextareaAutosize
            value={answer ? answer.value : ''}
            onChange={(e) =>
              formik.setFieldValue(`${name}[${getAnswerIndex(question)}]`, {
                ...answer,
                question_id: question.get('id'),
                value: e.target.value
              })
            }
            placeholder={question.get('placeholder')}
          />
        )
      case 'radio':
        return (
          <RadioInput
            value={answer ? answer.choice_id : ''}
            onChange={(value) =>
              formik.setFieldValue(`${name}[${getAnswerIndex(question)}]`, {
                ...answer,
                question_id: question.get('id'),
                choice_id: value
              })
            }
            options={question
              .get('choices')
              .map((choice) => ({ value: choice.get('id'), label: choice.get('value') }))}
          />
        )
      case 'checkboxes':
        return (
          <CheckboxInput
            value={answer ? answer.choice_ids : []}
            onChange={(value) =>
              formik.setFieldValue(`${name}[${getAnswerIndex(question)}]`, {
                ...answer,
                question_id: question.get('id'),
                choice_ids: value
              })
            }
            options={question
              .get('choices')
              .map((choice) => ({ value: choice.get('id'), label: choice.get('value') }))}
          />
        )
      case 'select':
        return (
          <SelectInput
            value={answer ? answer.choice_id : ''}
            isClearable
            onChange={(value) => {
              const tempData = formik.values.answers_attributes
              if (value === '') {
                const newData = tempData.filter(
                  (data) => data.question_id !== question.get('id')
                )

                formik.setFieldValue(name, newData)
              } else {
                formik.setFieldValue(`${name}[${getAnswerIndex(question)}]`, {
                  ...answer,
                  question_id: question.get('id'),
                  choice_id: value
                })
              }
            }}
            options={question
              .get('choices')
              .map((choice) => ({ value: choice.get('id'), label: choice.get('value') }))}
          />
        )
      case 'searchable_select':
        return (
          <SelectInput
            value={answer ? answer.choice_id : ''}
            isClearable
            onChange={(value) => {
              const tempData = formik.values.answers_attributes
              if (value === '') {
                const newData = tempData.filter(
                  (data) => data.question_id !== question.get('id')
                )

                formik.setFieldValue(name, newData)
              } else {
                formik.setFieldValue(`${name}[${getAnswerIndex(question)}]`, {
                  ...answer,
                  question_id: question.get('id'),
                  choice_id: value
                })
              }
            }}
            options={question
              .get('choices')
              .map((choice) => ({ value: choice.get('id'), label: choice.get('value') }))}
          />
        )
      case 'number':
        return (
          <Input
            value={answer ? answer.value : ''}
            onChange={(e) =>
              formik.setFieldValue(`${name}[${getAnswerIndex(question)}]`, {
                ...answer,
                question_id: question.get('id'),
                value: e.target.value
              })
            }
            type='number'
            placeholder={question.get('placeholder')}
          />
        )
      case 'image':
        return (
          <DropzoneInput
            value={answer ? answer.image : ''}
            onChange={(image) =>
              formik.setFieldValue(`${name}[${getAnswerIndex(question)}]`, {
                ...answer,
                question_id: question.get('id'),
                image
              })
            }
          />
        )
      case 'images':
        return (
          <DropzoneInput
            isMulti
            isClearable
            value={answer ? answer.attachments_attributes : []}
            onChange={(attachments) =>
              formik.setFieldValue(`${name}[${getAnswerIndex(question)}]`, {
                ...answer,
                question_id: question.get('id'),
                attachments_attributes: attachments
              })
            }
          />
        )
      default:
        return null
    }
  }

  return questions.map((question) => (
    <Grid container spacing={5} key={question.get('id')}>
      <Grid item xs={12}>
        <label>{question.get('name')}</label>

        {renderAnswer(question)}
      </Grid>
    </Grid>
  ))
}

BookingQuestionsFormSection.propTypes = {
  name: PropTypes.string,
  formik: PropTypes.object,
  fetchService: PropTypes.func,
  getQuestions: PropTypes.func
}

export default connect(BookingQuestionsFormSection)
