export function PriceFormatter ({ value }) {
  const formatter = new Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB'
  })

  return formatter.format(value)
}

export default PriceFormatter
