import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { providerSchema, eventSchema } from '@seekster/schemas'

import ProviderTimelinePane from './ProviderTimelinePane'

const mapStateToProps = (state, { match }) => ({
  provider: getResource(state, match.params.id, providerSchema)
})

const mapDispatchToProps = {
  fetchProviderEvent: (id, options) => fetchChildren(providerSchema, id, eventSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderTimelinePane)
