import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { destroy, fetch } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { issueSchema } from '@seekster/schemas'

import withFlashMessage from 'hoc/withFlashMessage'

import ProviderIssueShowPage from './ProviderIssueShowPage'

const mapStateToProps = (state, { match }) => ({
  issue: getResource(state, match.params.id, issueSchema)
})

const mapDispatchToProps = {
  deleteIssue: id => destroy(id, issueSchema),
  fetchIssue: id => fetch(id, issueSchema),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(withFlashMessage(ProviderIssueShowPage))
