import React from 'react'
import { Grid } from '@material-ui/core'
import ButtonLoader from 'components/buttons/ButtonLoader'

import { Form } from 'formik'
import { withTranslation } from 'react-i18next'
import SelectInput from 'components/inputs/SelectInput'
import { makeStyles } from '@material-ui/core/styles'

import { Field } from 'components/fields/Field'
import ResourceSelect from 'components/inputs/ResourceSelect'
import { serviceSchema, clientSchema, customerSchema } from '@seekster/schemas'

const useStyles = makeStyles(() => ({
  content: {
    paddingTop: 24
  },

  '@media screen and (max-width: 767px)': {
    paddingTop: 0
  },

  button: {
    marginRight: 18
  }
}))

export function WebhookForm({ isSubmitting, t, values }) {
  const classes = useStyles()

  return (
    <Form>
      <Grid container justify='center' className={classes.content}>
        <Grid item xs={12} sm={9}>
          <Grid item xs={12}>
            <Field name='name' label='Webhook Name' />
          </Grid>

          <Grid item xs={12}>
            <Field
              name='source_type'
              component={SelectInput}
              placeholder='Please Select...'
              options={[
                { value: 'Client', label: 'client' },
                { value: 'Service', label: 'service' },
                { value: 'Customer', label: 'customer' }
              ]}
            />
          </Grid>

          {values.source_type && (
            <>
              <Grid item xs={12}>
                {values.source_type === 'Client' && (
                  <Field
                    label='Client Name'
                    name='client_id'
                    component={ResourceSelect}
                    schema={clientSchema}
                  />
                )}
                {values.source_type === 'Service' && (
                  <Field
                    label='Service Name'
                    name='service_id'
                    component={ResourceSelect}
                    schema={serviceSchema}
                  />
                )}
                {values.source_type === 'Customer' && (
                  <Field
                    label='Customer Name'
                    name='customer_id'
                    component={ResourceSelect}
                    schema={customerSchema}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <Field placeholder='https://example.com' name='webhook_url' typr='url' />
              </Grid>

              <Grid container justify='flex-end'>
                <ButtonLoader
                  type='submit'
                  variant='contained'
                  color='primary'
                  isLoading={isSubmitting}
                  className={classes.button}
                >
                  {t('save')}
                </ButtonLoader>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Form>
  )
}

export default withTranslation(['actions'])(WebhookForm)
