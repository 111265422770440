import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import PageContent, { PageSection } from 'components/layouts/PageContent'
import Panel from 'components/containers/Panel'
import ReviewPreviewsList from 'modules/reviews/ReviewPreviewsList'
import FeedbackPreviewsList from 'modules/feedbacks/FeedbackPreviewsList'
// import AnalyticsCard from 'modules/analytics/AnalyticsCard'

import styles from './DashboardPageStyles'

// const {
//   REACT_APP_REQUEST_MONTHLY_ID: requestMonthlyId,
//   REACT_APP_JOB_MONTHLY_ID: jobMonthlyId,
//   REACT_APP_SUBSCRIPTION_MONTHLY_ID: subscriptionMonthlyId,
//   REACT_APP_GSV_MONTHLY_ID: gsvMonthlyId,
//   REACT_APP_JOB_PER_DAY_GRAPH_ID: jobPerDayGraphId
// } = process.env

export function DashboardPage({ classes }) {
  const { t } = useTranslation('dashboard')

  return (
    <PageLayout title={t('title')}>
      <PageContent>
        <PageSection position='center'>
          <Grid container spacing={1} alignContent='flex-start' style={{ marginLeft: 0 }}>
            {/* <Grid item xs={12}>
              <AnalyticsCard id={parseInt(jobPerDayGraphId, 10)} minHeight={400} showTitle />
            </Grid>

            {
              [
                requestMonthlyId, jobMonthlyId,
                subscriptionMonthlyId, gsvMonthlyId
              ].map(questionId => (
                <Grid item xs={12} sm={6} key={questionId}>
                  <AnalyticsCard id={parseInt(questionId, 10)} />
                </Grid>
              ))
            } */}
          </Grid>
        </PageSection>

        <PageSection position='right'>
          <Panel
            title={t('new_feedbacks')}
            actions={
              <Button component={Link} to='/feedbacks' className={classes.seeAll}>
                {t('see_all')}
              </Button>
            }
            noPadding
          >
            <FeedbackPreviewsList />
          </Panel>

          <Panel
            title={t('new_reviews')}
            actions={
              <Button component={Link} to='/reviews' className={classes.seeAll}>
                {t('see_all')}
              </Button>
            }
            noPadding
          >
            <ReviewPreviewsList />
          </Panel>
        </PageSection>
      </PageContent>
    </PageLayout>
  )
}

DashboardPage.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(DashboardPage)
