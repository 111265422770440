import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { withTranslation } from 'react-i18next'

import CustomersTable from 'components/tables/CustomersTable'
import Panel from 'components/containers/Panel'

export class CompanyCustomersPane extends React.Component {
  static propTypes = {
    company: ImmutablePropTypes.map,
    match: PropTypes.object,
    t: PropTypes.func,
    loadCompany: PropTypes.func,
    loadCompanyCustomers: PropTypes.func
  }

  static defaultProps = {
    company: Map()
  }

  componentDidMount () {
    if (this.props.company.isEmpty()) {
      this.props.loadCompany(this.props.match.params.id)
    }

    this.props.loadCompanyCustomers(this.props.match.params.id)
  }

  customers () {
    const { t, company } = this.props

    const customers = company.get('customers') || List()
    const contactPerson = company.get('contact_person') || Map()

    if (customers.isEmpty()) {
      return <p>{t('customers_empty')}</p>
    }
    else {
      return <CustomersTable customers={customers} contact_person={contactPerson} />
    }
  }

  render () {
    const { t } = this.props

    return (
      <Panel title={t('customers')}>
        {this.customers()}
      </Panel>
    )
  }
}

export default withTranslation('companies')(CompanyCustomersPane)
