import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { create } from 'actions/resources'
import { agentSchema } from '@seekster/schemas'

import AgentNewPage from './AgentNewPage'

const mapDispatchToProps = {
  createAgent: data => create(data, agentSchema),
  push
}

export default connect(null, mapDispatchToProps)(AgentNewPage)
