import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useNodeResource } from 'hooks/resources'

import PageLayout from 'components/layouts/PageLayout'
import {
  NavigationMenuBar,
  NavigationMenuItem
} from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import { jobReportSchema } from '@seekster/schemas'

import { ReportOverviewPane } from './panes'

export function ReportShowPage({ match, location }) {
  const { t } = useTranslation(['jobs', 'actions', 'common'])
  const [report, { loading }] = useNodeResource(jobReportSchema, match.params.id)

  function renderSubNavbar() {
    const path = `/report/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('navbar.overview')} to={path} replace />
      </NavigationMenuBar>
    )
  }

  return (
    <PageLayout
      title={`${report.get('number')}`}
      subNavbar={renderSubNavbar()}
      isLoading={loading}
      back='/reports'
    >
      <Switch>
        <AuthenticatedRoute path='/reports/:id' component={ReportOverviewPane} exact />
      </Switch>
    </PageLayout>
  )
}

ReportShowPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object
}

export default ReportShowPage
