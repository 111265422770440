import React from 'react'
import PropTypes from 'prop-types'
import { withMobileDialog, Grid } from '@material-ui/core'
import { Map } from 'immutable'

import { AssignProvidersListPanel, AssignProviderReportPanel } from 'modules/assignProviders'

import AuthenticatedRoute from 'components/AuthenticatedRoute'

export function InquiryAssignProviderPane ({
  fullScreen: isMobile
}) {
  return (
    <Grid container>
      <Grid item xs={12} sm={5}>
        <AuthenticatedRoute
          path='/inquiries/:id/assign_provider'
          component={AssignProvidersListPanel}
          exact={isMobile}
        />
      </Grid>

      <Grid item xs={12} sm={7} style={{ marginTop: isMobile && '-24px' }}>
        <AuthenticatedRoute
          path='/inquiries/:id/assign_provider/:provider_id'
          component={AssignProviderReportPanel}
        />
      </Grid>
    </Grid>
  )
}

InquiryAssignProviderPane.propTypes = {
  fullScreen: PropTypes.bool
}

InquiryAssignProviderPane.defaultProps = {
  inquiry: Map()
}

export default withMobileDialog()(InquiryAssignProviderPane)
