import React, { Component } from 'react'

import i18n from 'i18n'
import LocaleContext from 'contexts/LocaleContext'

export const withLocalizedFields = (WrappedComponent) => (
  class LocalizedComponent extends Component {
    constructor (props) {
      super(props)

      this.handleSetLocale = this.handleSetLocale.bind(this)

      this.state = {
        locale: ''
      }
    }

    handleSetLocale (locale) {
      this.setState({ locale })
    }

    componentDidMount () {
      !i18n.language
        ? i18n.init().then(() => this.setState({ locale: i18n.language }))
        : this.setState({ locale: i18n.language })
    }

    render () {
      return (
        <LocaleContext.Provider value={this.state.locale}>
          <WrappedComponent
            {...this.props}
            locale={this.state.locale}
            setLocale={this.handleSetLocale}
          />
        </LocaleContext.Provider>
      )
    }
  }
)

export default withLocalizedFields
