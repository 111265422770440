export default theme => ({
  tableContainer: {
    overflowX: 'auto'
  },

  lineItemChild: {
    '& > td': {
      color: theme.palette.grey[500]
    }
  },

  indented: {
    paddingLeft: theme.spacing(5)
  },

  total: {
    fontWeight: 600
  },

  withholdingTaxCheckbox: {
    paddingBottom: 0,
    marginBottom: 0
  },

  withholdingTaxRow: {
    height: '24px'
  },

  withholdingTaxCell: {
    paddingTop: 0,
    verticalAlign: 'top'
  },

  grandTotal: {
    fontWeight: 600
  },

  lastRow: {
    borderBottom: 0
  },

  newDiscountButton: {
    fontWeight: 600,
    color: theme.palette.primary.main
  },

  addIcon: {
    marginRight: theme.spacing(0.5)
  },

  editDiscountButton: {
    justifyContent: 'flex-end',
    marginRight: -8,
    fontSize: 'inherit',
    color: theme.palette.primary.dark
  }
})
