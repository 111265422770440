import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, FormControl } from '@material-ui/core'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'
import { CURRENCIES } from 'constants/currencies'

const useStyles = makeStyles(({ spacing }) => ({
  TextFieldContainer: {
    width: '80%',
    marginRight: 4
  },

  SelectInputContainer: {
    width: '18%'
  }
}))

export function MoneyInput ({ onCurrencyChange, currencyValue, ...rest }) {
  const classes = useStyles()

  return (
    <from noValidate autoComplete='off'>
      <FormControl className={classes.TextFieldContainer}>
        <TextField
          variant='outlined'
          color='primary'
          type='number'
          {...rest}
        />
      </FormControl>

      <FormControl className={classes.SelectInputContainer}>
        <SelectInput isClearable={false} options={arrayToOptions(CURRENCIES)} value={currencyValue} onChange={onCurrencyChange} />
      </FormControl>
    </from>
  )
}

MoneyInput.propTypes = {
  currencyValue: PropTypes.string,
  onCurrencyChange: PropTypes.func
}

export default MoneyInput
