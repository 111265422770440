import { withFormik } from 'formik'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'

import VoidTransactionForm from './VoidTransactionForm'

const formikConfig = {
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(props.id, 'void', values)
      .then(() => {
        props.onCloseModal()
        setSubmitting(false)
      })
      .catch(error => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(VoidTransactionForm)
