export default theme => ({
  transferSlip: {
    width: '100%',
    borderRadius: 4
  },

  button: {
    float: 'right',
    margin: '14px 0 0 14px'
  },

  voidButton: {
    marginTop: 14,
    fontWeight: 600,
    color: theme.palette.error.dark,

    '&:hover': {
      backgroundColor: '#ffe0e0'
    }
  },

  voidButtonLabel: {
    alignItems: 'flex-end'
  }
})
