import React from 'react'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'
import { Field } from 'components/fields/Field'
import { Form } from 'formik'
import { Grid } from '@material-ui/core'
import ButtonLoader from 'components/buttons/ButtonLoader'
import ResourceSelect from 'components/inputs/ResourceSelect'

import { reviewTypeSchema } from '@seekster/schemas'
export function ReviewForm({ isSubmitting, t }) {
  return (
    <Grid container justify='center' component={Form}>
      <Grid item xs={12} sm={6}>
        <Field name='rating' />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Field name='content' />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Field
          name='review_type_id'
          component={ResourceSelect}
          schema={reviewTypeSchema}
        />
      </Grid>

      <Grid container justify='flex-end'>
        <ButtonLoader
          type='submit'
          variant='contained'
          color='primary'
          isLoading={isSubmitting}
        >
          {t('save')}
        </ButtonLoader>
      </Grid>
    </Grid>
  )
}

ReviewForm.defaultProps = {
  review: Map()
}

export default withTranslation('reviews')(ReviewForm)
