import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { create } from 'actions/resources'
import { serviceSchema } from '@seekster/schemas'

import ServiceNewPage from './ServiceNewPage'

const mapDispatchToProps = {
  createService: (data, options) => create(data, serviceSchema, options),
  push
}

export default connect(null, mapDispatchToProps)(ServiceNewPage)
