import moment from 'moment'
import { get } from 'lodash'

export const categoriesQuery = ({
  search,
  active,
  service_id,
  created_at
}) => {
  return {
    translations_name_cont: search,

    active_eq: active,

    service_id_eq: service_id,

    created_at_gteq: get(created_at, 'from')
      ? moment(created_at.from).startOf('day').toISOString(true)
      : undefined,
    created_at_lteq: get(created_at, 'to')
      ? moment(created_at.to).endOf('day').toISOString(true)
      : undefined
  }
}

export default categoriesQuery
