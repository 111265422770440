import React from 'react'
import { connect, getIn } from 'formik'
import { Grid } from '@material-ui/core'

import InputField from 'components/fields/InputField'
import SortableFormSection from 'components/fields/SortableFieldArray/SortableFormSection.new'

function CancellationChildrenForm({ name, formik, ...props }) {
  const value = getIn(formik.values, name)
  const title = value.value ? value.value : 'Cancellation name'

  return (
    <SortableFormSection title={title} name={name} formik={formik} {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField
            label='Reason'
            name={`${name}.value`}
            variant='outlined'
            size='small'
          />
        </Grid>
      </Grid>
    </SortableFormSection>
  )
}

export default connect(CancellationChildrenForm)
