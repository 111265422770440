import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { providerSchema } from '@seekster/schemas'

import ProviderOverviewPane from './ProviderOverviewPane'

const mapStateToProps = (state, { match }) => ({
  provider: getResource(state, match.params.id, providerSchema)
})

export default connect(mapStateToProps)(ProviderOverviewPane)
