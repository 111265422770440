import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'

import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import Tag from 'components/Tag'

export function FeedbackOverviewPane({ feedback, t }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Panel title={t('details')}>
          <AttributesTable resource={feedback} basic='very'>
            <Attribute name='id' />
            <Attribute
              name='comment'
              render={(comment) => (
                <div style={{ whiteSpace: 'pre-wrap' }}>{comment}</div>
              )}
            />
            <Attribute
              name='tags'
              render={(tags) =>
                tags &&
                tags.map((tag) => <Tag key={tag.get('id')} value={tag.get('name')} />)
              }
            />
            <Attribute name='feedback_type.name' />
            <Attribute
              name='created_by'
              render={(creator) =>
                creator && (
                  <Link to={`/${creator.get('type')}/${creator.get('id')}`}>
                    {creator.get('display_name')}{' '}
                    <Tag value={feedback.get('created_by_type')} />
                  </Link>
                )
              }
            />
            <Attribute name='created_at' component={DateTimeFormatter} />
            <Attribute name='updated_at' component={DateTimeFormatter} />
          </AttributesTable>
        </Panel>
      </Grid>
    </Grid>
  )
}

FeedbackOverviewPane.propTypes = {
  feedback: ImmutablePropTypes.map,

  t: PropTypes.func
}

FeedbackOverviewPane.defaultProps = {
  feedback: Map()
}

export default withTranslation('feedbacks')(FeedbackOverviewPane)
