import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router-dom'
import { TableRow, TableCell } from '@material-ui/core'

export class Attribute extends React.Component {
  static propTypes = {
    label: PropTypes.node,
    value: PropTypes.node,
    association: ImmutablePropTypes.map,
    displayField: PropTypes.string,
    visible: PropTypes.bool,
    valueStyle: PropTypes.object
  }

  static defaultProps = {
    visible: true,
    displayField: 'name'
  }

  renderValue () {
    const { value, association, displayField } = this.props

    if (association) {
      return (
        <Link to={`/${association.get('type')}/${association.get('id')}`}>
          {association.get(displayField)}
        </Link>
      )
    }
    else if (value) {
      return value
    }
    else {
      return null
    }
  }

  render () {
    if (this.props.visible) {
      return (
        <TableRow>
          <TableCell>
            <b>{this.props.label}</b>
          </TableCell>

          <TableCell style={this.props.valueStyle}>
            {this.renderValue()}
          </TableCell>
        </TableRow>
      )
    }
    else {
      return null
    }
  }
}

export default Attribute
