import React from 'react'
import { Form } from 'formik'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'components/buttons'
import { Tabs, TabPane } from 'components/Tabs'
import SortableFieldArray from 'components/fields/SortableFieldArray'
import ResourceSelect from 'components/inputs/ResourceSelect'
import { TicketMacrosSettingsForm } from 'modules/tickets'
import { departmentSchema } from '@seekster/schemas'
import Field from 'components/fields/Field'

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    margin: '14px 0 0 14px',
    float: 'right'
  },

  container: {
    padding: spacing(2)
  },

  tabs: {
    justifyContent: 'flex-start'
  }
}))

export function TenantTicketSettingForm ({ values, dirty, isSubmitting }) {
  const classes = useStyles()

  return (
    <Grid container justify='center' component={Form}>
      <Grid item xs={12} sm={9}>
        <Tabs
          contained
          scrollButtons='off'
          classes={{ flexContainer: classes.tabs }}
        >
          <TabPane label='General Settings'>
            <Grid container className={classes.container}>
              <Grid item xs={12}>
                <Field name='auto_message' />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name='auto_message_duration'
                  hint='minutes'
                  type='number'
                />
              </Grid>

              <Grid item xs={12}>
                <Field label='Default Department' name='department_id' component={ResourceSelect} schema={departmentSchema} />
              </Grid>
            </Grid>
          </TabPane>

          <TabPane label='Macros Settings'>
            <SortableFieldArray
              name='macros'
              component={TicketMacrosSettingsForm}
              noLabel
              hasChildren
            />
          </TabPane>
        </Tabs>

        {
          dirty && (
            <Button
              color='primary'
              variant='contained'
              type='submit'
              loading={isSubmitting}
              className={classes.button}
            >
              save
            </Button>
          )
        }
      </Grid>
    </Grid>
  )
}

export default TenantTicketSettingForm
