import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InputBase, InputAdornment, Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Search as SearchIcon } from '@material-ui/icons'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'

import useQuery from 'hooks/useQuery'

const useStyles = makeStyles(({ spacing, shape, palette, transitions }) => ({
  root: {
    backgroundColor: 'white',
    padding: spacing(1, 2),
    border: '1px solid',
    borderColor: palette.grey[300],
    borderRadius: shape.borderRadius,
    transition: transitions.create(['border-color'])
  },
  button: {
    height: '46px'
  },
  focused: {
    borderColor: palette.primary.main
  }
}))

export function Search({ isDebounce, withButton }) {
  const classes = useStyles()
  const [query, { pushQuery }] = useQuery()
  const [searchValue, setSearch] = useState('')
  const { t } = useTranslation('general')

  function search(value) {
    pushQuery({ ...query, search: value !== '' ? value : undefined })
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      search(e.target.value)
    }
  }

  function handleKeyChange(e) {
    if (isDebounce) {
      debouncedSearch(e.target.value)
    }
    setSearch(e.target.value)
  }

  const debouncedSearch = debounce((value) => {
    search(value)
  }, 1000)

  return (
    <>
      {withButton ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={9} sm={10}>
              <InputBase
                defaultValue={query.search}
                placeholder='Search...'
                onKeyDown={handleKeyDown}
                onChange={handleKeyChange}
                classes={classes}
                color='primary'
                fullWidth
                endAdornment={
                  <InputAdornment position='end'>
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>

            <Grid item xs={3} sm={2}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => search(searchValue)}
                className={classes.button}
                fullWidth
              >
                {t('search')}
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <InputBase
          defaultValue={query.search}
          placeholder='Search...'
          onKeyDown={handleKeyDown}
          onChange={handleKeyChange}
          classes={classes}
          color='primary'
          fullWidth
          endAdornment={
            <InputAdornment position='end'>
              <SearchIcon />
            </InputAdornment>
          }
        />
      )}
    </>
  )
}

Search.propTypes = {
  isDebounce: PropTypes.bool,
  withButton: PropTypes.bool
}

Search.defaultProps = {
  isDebounce: false,
  withButton: false
}

export default Search
