export default theme => ({
  iconButton: {
    padding: 4
  },

  modalContent: {
    display: 'flex',
    justifyContent: 'center'
  },

  circularProgress: {
    alignSelf: 'center'
  },

  divider: {
    padding: 0
  }
})
