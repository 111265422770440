import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Grid, Button } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import Field from 'components/fields/Field'
import ResourceSelect from 'components/inputs/ResourceSelect'
import { providerSchema } from '@seekster/schemas'

export class BlacklistedProviderForm extends React.Component {
  static propTypes = {
    onCancelForm: PropTypes.func,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.handleFormCancel = this.handleFormCancel.bind(this)
  }

  handleFormCancel () {
    this.props.onCancelForm && this.props.onCancelForm()
  }

  render () {
    return (
      <Form>
        <Field name='provider_id' component={ResourceSelect} schema={providerSchema} />

        <Grid item xs={12} container justify='flex-end'>
          <Button
            variant='contained'
            type='button'
            onClick={this.handleFormCancel}
          >
            {this.props.t('cancel')}
          </Button>

          <Button
            variant='contained'
            color='primary'
            type='submit'
            style={{ marginLeft: 8 }}
          >
            {this.props.t('add')}
          </Button>
        </Grid>
      </Form>
    )
  }
}

export default withTranslation('actions')(BlacklistedProviderForm)
