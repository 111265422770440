import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'

import Field, { LocalizedField } from 'components/fields/Field'

import styles from './CompanyBillingAddressFormSectionStyles'

export function CompanyBillingAddressFormSection ({ name, classes, t }) {
  const fields = [
    { fieldName: 'name' },
    { fieldName: 'house_number' },
    { fieldName: 'project_name', label: t('billing_address.building_name') },
    {
      fieldName: 'floor',
      label: t('billing_address.unit_and_floor'),
      hint: t('billing_address.floor_hint')
    },
    { fieldName: 'alley' },
    { fieldName: 'street' },
    { fieldName: 'sub_district' },
    { fieldName: 'district' },
    { fieldName: 'province' }
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <label>{t('billing_address.title')}</label>
      </Grid>

      {
        fields.map(({ fieldName, ...attributes }) => (
          <Grid item xs={12} sm={6} key={fieldName} className={classes.fieldContainer}>
            <LocalizedField name={`${name}.${fieldName}`} {...attributes} />
          </Grid>
        ))
      }

      <Grid item xs={12} sm={6} className={classes.fieldContainer}>
        <Field name={`${name}.zip_code`} />
      </Grid>
    </Grid>
  )
}

CompanyBillingAddressFormSection.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.object,
  t: PropTypes.func
}

export default compose(
  withStyles(styles),
  withTranslation('companies')
)(CompanyBillingAddressFormSection)
