import React, { useRef } from 'react'
import { Button } from '@material-ui/core'

import { CSVReader } from 'react-papaparse'

export function CsvButtonUpload({ onHandleUpload }) {
  const buttonRef = useRef()

  function handleOpenDialog(e) {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e)
    }
  }

  function handleOnFileLoad(data) {
    const resultData = data.slice(1)

    const newArray = resultData.map((result, index) => {
      return result.data
    })

    return newArray
  }

  function handleOnError(err, file, inputElem, reason) {
    console.log(err)
  }

  return (
    <CSVReader
      ref={buttonRef}
      onFileLoad={(acceptedFiles) => onHandleUpload(handleOnFileLoad(acceptedFiles))}
      onError={handleOnError}
      noClick
      noDrag
    >
      {({ file }) => (
        <Button
          type='button'
          onClick={handleOpenDialog}
          variant='contained'
          color='secondary'
          style={{
            marginBottom: 16
          }}
        >
          Upload CSV
        </Button>
      )}
    </CSVReader>
  )
}

export default CsvButtonUpload
