import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/buttons'
import { Container } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { providerSchema } from '@seekster/schemas'

import { createCollection } from 'actions/collections'

import CsvUpload from 'components/CsvUpload'
import PageLayout from 'components/layouts/PageLayout'
import { setFlashMessage } from 'actions/interface'
import { usePush } from 'hooks/utils'

export function GenerateProviderPage() {
  const dispatch = useDispatch()
  const [jsonResult, setJsonResult] = useState(false)
  const push = usePush()
  const { t } = useTranslation('providers')

  const handleOnClick = () => {
    dispatch(
      createCollection(jsonResult, providerSchema, { type: 'providers_generator' })
    )
      .then(() => push('/providers'))
      .catch((error) => {
        const errorText = JSON.parse(error.response.text)
        dispatch(setFlashMessage('error', errorText.message))
      })
  }

  const validationAttributes = (provider, index) => {
    if (provider.first_name === '') {
      return {
        pass: false,
        message: `provider row ${index + 1} first name is empty`
      }
    }

    if (provider.email === '') {
      return {
        pass: false,
        message: `provider row ${index + 1} email is empty`
      }
    }

    if (provider.phone_number === '') {
      return {
        pass: false,
        message: `provider row ${index + 1} phone number is empty`
      }
    }

    if (provider.last_name === '') {
      return {
        pass: false,
        message: `provider row ${index + 1} last name is empty`
      }
    }

    if (provider.date_of_birth === '') {
      return {
        pass: false,
        message: `provider row ${index + 1} date of birth is empty`
      }
    }

    if (provider.gender === '') {
      return {
        pass: false,
        message: `provider row ${index + 1} gender is empty`
      }
    }

    return {
      pass: true,
      message: 'Pass'
    }
  }

  const setServiceIds = (ids) => {
    return ids.split(',').map(Number)
  }

  const setParsedJsonResult = (result) => {
    const error = []
    const data = []

    result.forEach((provider, index) => {
      const validation = validationAttributes(provider, index)
      if (!validation.pass) {
        error.push(validation.message)
      } else if (error.length === 0) {
        data.push({
          first_name: provider.first_name,
          last_name: provider.last_name,
          date_of_birth: provider.date_of_birth,
          gender: provider.gender,
          email: provider.email,
          password: provider.phone_number,
          phone_number: provider.phone_number,
          service_ids: setServiceIds(provider.service_ids)
        })
      }
    })

    if (error.length > 0) {
      dispatch(setFlashMessage('error', error.toString()))
    } else {
      dispatch(setFlashMessage('success', 'CSV format validation pass'))
      setJsonResult({
        providers_json: JSON.stringify(data)
      })
    }
  }

  return (
    <PageLayout title={t('generate')} back='/providers'>
      <Container>
        <div>
          <CsvUpload onHandleDrop={(result) => setParsedJsonResult(result)} />
        </div>
        {jsonResult !== false ? (
          <div>
            <Button
              color='primary'
              variant='contained'
              size='small'
              onClick={handleOnClick}
            >
              {t('generate')}
            </Button>
          </div>
        ) : (
          <div />
        )}
      </Container>
    </PageLayout>
  )
}

export default GenerateProviderPage
