import { connect } from 'react-redux'

import { fetch } from 'actions/resources'
import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { providerSchema, deviceSchema } from '@seekster/schemas'

import ProviderAccountMergesPage from './ProviderAccountMergesPage'

const mapStateToProps = (state, { match }) => ({
  provider: getResource(state, match.params.id, providerSchema)
})

const mapDispatchToProps = {
  fetchProvider: id => fetch(id, providerSchema),
  fetchProviderDevices: id => fetchChildren(providerSchema, id, deviceSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderAccountMergesPage)
