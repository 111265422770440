import { withFormik } from 'formik'
import { compose } from 'redux'
import { Map } from 'immutable'

import withFlashMessage from 'hoc/withFlashMessage'
import TenantTicketSettingForm from './TenantTicketSettingForm'

const formikConfig = {
  mapPropsToValues: ({ settings = Map() }) => {
    let macrosAttributes = [
      {
        value: []
      }
    ]

    return {
      macros: settings.get('macros') ? settings.get('macros').toJS() : macrosAttributes,
      auto_message: settings.get('auto_message'),
      auto_message_duration: settings.get('auto_message_duration') / 60000,
      department_id: settings.get('department_id')
    }
  },

  enableReinitialize: true,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit('ticketConfiguration', `tenants/${props.tenant}/settings`, { ...values, auto_message_duration: values.auto_message_duration * 60000 })

    setSubmitting(false)
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(TenantTicketSettingForm)
