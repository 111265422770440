import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { compose } from 'redux'
import { connect } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Paper, Divider, Typography, Button } from '@material-ui/core'
import { findIndex, find, reject, some } from 'lodash'
import { withTranslation } from 'react-i18next'

import CounterInput from 'components/inputs/CounterInput'
import HtmlFormatter from 'components/formatters/HtmlFormatter'

import styles from './BookingOrdersFormSectionStyles'

export function BookingOrdersFormSection({
  name,
  formik,
  fetchService,
  getLineItems,
  getItemType,
  classes,
  t
}) {
  const lineItems = getLineItems(formik.values.service_id) || List()

  const [focusedItem, setFocusedItem] = useState()

  useEffect(
    () => {
      fetchService(formik.values.service_id)

      return () => {
        formik.setFieldValue(name, undefined)
      }
    },
    [formik.values.service_id] // eslint-disable-line react-hooks/exhaustive-deps
  )

  function setValue(value, lineItem) {
    const predictate = (order) => order.package_id === lineItem.get('id')

    const existingOrder = find(formik.values[name], predictate) || {}

    const remainingOrders = reject(formik.values[name], ({ package_id: packageId }) => {
      if (value === 0 || value === null) {
        return (
          packageId === lineItem.get('id') ||
          lineItem.get('children', List()).some((child) => child.get('id') === packageId)
        )
      } else {
        return packageId === lineItem.get('id')
      }
    })

    let newOrder

    if (existingOrder.package_id && !value && value !== 0) {
      newOrder = remainingOrders
    } else if (value >= 0) {
      newOrder = [
        ...remainingOrders,
        {
          ...existingOrder,
          package_id: lineItem.get('id'),
          quantity: value
        }
      ]
    } else {
      newOrder = remainingOrders
    }

    formik.setFieldValue(name, newOrder)
  }

  function handleInputBlur(item, existingOrder) {
    setFocusedItem(null)

    if (!existingOrder.quantity || Number(existingOrder.quantity) === 0) {
      setValue(null, item)
    } else {
      setValue(Number(existingOrder.quantity), item)
    }
  }

  function renderOrderDetails(item) {
    return (
      <Grid item xs={6} sm={9}>
        <Typography variant='h6'>{item.get('name')}</Typography>
        <Typography variant='subtitle1' gutterBottom>
          {item.getIn(['price', 'display_value'])} / {item.get('unit')}
        </Typography>
        <Typography variant='caption' gutterBottom>
          <HtmlFormatter value={item.get('description')} />
        </Typography>
      </Grid>
    )
  }

  function renderOrderPicker(item) {
    const predictate = (order) => order.package_id === item.get('id')

    const existingOrder = find(formik.values[name], predictate) || {}

    if (!existingOrder.quantity && focusedItem !== item.get('id')) {
      return (
        <Button
          className={classes.addButton}
          variant='outlined'
          onClick={() => setValue(item.get('minimum_amount') || 1, item)}
        >
          {t('add')}
        </Button>
      )
    } else if (item.get('maximum_amount') > 1 || item.get('maximum_amount') === null) {
      return (
        <CounterInput
          value={existingOrder.quantity || ''}
          onChange={(value) => setValue(value, item)}
          onFocus={() => setFocusedItem(item.get('id'))}
          onBlur={() => handleInputBlur(item, existingOrder)}
          handleOnBlur={() => handleInputBlur(item, existingOrder)}
          min={item.get('minimum_amount')}
          max={item.get('maximum_amount')}
        />
      )
    } else {
      return (
        <Button
          className={classes.removeButton}
          variant='outlined'
          onClick={() => setValue(null, item)}
        >
          {t('remove')}
        </Button>
      )
    }
  }

  return lineItems.map((lineItem) => {
    const checkExisting = ({ package_id: packageId }) =>
      packageId !== lineItem.get('id') &&
      getItemType(packageId) === lineItem.get('item_type')

    if (lineItem.get('item_type') !== null && some(formik.values[name], checkExisting)) {
      return null
    } else {
      const index = findIndex(
        formik.values[name],
        (order) => order.package_id === lineItem.get('id')
      )

      return (
        <Paper key={lineItem.get('id')} className={classes.item}>
          <Grid container className={classes.details}>
            {renderOrderDetails(lineItem)}

            <Grid item xs={6} sm={3} className={classes.picker}>
              {renderOrderPicker(lineItem)}
            </Grid>
          </Grid>
          {index !== -1 && !lineItem.get('children').isEmpty() && (
            <>
              <Divider className={classes.divider} />

              {lineItem.get('children', List()).map((lineItemChild) => {
                return (
                  <Grid
                    container
                    key={lineItemChild.get('id')}
                    className={classes.details}
                  >
                    {renderOrderDetails(lineItemChild)}

                    <Grid item xs={6} sm={3} className={classes.picker}>
                      {renderOrderPicker(lineItemChild)}
                    </Grid>
                  </Grid>
                )
              })}
            </>
          )}
        </Paper>
      )
    }
  })
}

BookingOrdersFormSection.propTypes = {
  name: PropTypes.string,
  formik: PropTypes.object,
  fetchService: PropTypes.func,
  getLineItems: PropTypes.func,
  getItemType: PropTypes.func,
  classes: PropTypes.object,

  t: PropTypes.func
}

export default compose(
  connect,
  withStyles(styles),
  withTranslation('actions')
)(BookingOrdersFormSection)
