import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'

import InquiryForm from 'forms/resources/InquiryForm'

export function InquiryNewPage ({ createInquiry, push }) {
  const { t } = useTranslation('inquiries')

  return (
    <PageLayout
      title={t('new')}
      back='/inquiries'
    >
      <InquiryForm
        push={push}
        onSubmit={createInquiry}
      />
    </PageLayout>
  )
}

InquiryNewPage.propTypes = {
  createInquiry: PropTypes.func,
  push: PropTypes.func
}

export default InquiryNewPage
