import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Paper, Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import CircularLoader from 'components/CircularLoader'

import { withQueryParser } from 'utils/withQueryParser'

import PageLayout from 'components/layouts/PageLayout'
import Search from 'components/Search'
import FilterModal from 'components/FilterModal'
import FilterTags from 'components/tags/FilterTags'
import RegionsFilterForm from 'forms/filters/RegionsFilterForm'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import { Table, Column } from 'components/tables/Table'
import { DateTag, BooleanTag } from 'components/tags'
import { useCollection } from 'hooks/collections'
import { appSchema } from '@seekster/schemas'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

export function AppIndexContent ({ query, t }) {
  const { page, ...rest } = query
  const classes = useStyles()

  const [apps, { loading, totalCount, totalPages }] = useCollection(appSchema, { page, query: rest }, [query])

  return (
    <>
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={10}>
            <Search />
          </Grid>

          <Grid item xs={3} sm={2}>
            <FilterModal component={RegionsFilterForm} />
          </Grid>
        </Grid>
      </div>

      {
        loading && <CircularLoader />
      }

      {
        !loading && (
          <>
            <div className={classes.metaContainer}>
              <ResultsCountLabel value={totalCount} unit='app' />

              <FilterTags />
            </div>

            <Paper>
              <Table collection={apps}>
                <Column name='name' to={app => `/apps/${app.get('id')}`} />
                <Column name='official' component={BooleanTag} />
                <Column name='access_type' />
                <Column name='created_at' component={DateTag} />
              </Table>
            </Paper>

            <Grid item xs={12} container justify='center'>
              <Pagination totalPages={totalPages} />
            </Grid>
          </>
        )
      }
    </>
  )
}

export function AppIndexPage (props) {
  const { t } = props

  function actions () {
    return [{
      content: t('new'),
      to: '/apps/new'
    }]
  }

  return (
    <PageLayout title={t('title')} primaryActions={actions()}>
      <AppIndexContent {...props} />
    </PageLayout>
  )
}

AppIndexPage.propTypes = {
  query: PropTypes.object,
  t: PropTypes.func
}

export default compose(
  withQueryParser,
  withTranslation('apps')
)(AppIndexPage)
