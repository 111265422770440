/* eslint-disable space-before-function-paren */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Button } from '@material-ui/core'
import { Field, Formik, Form, ErrorMessage } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import { ChevronLeft } from '@material-ui/icons'
import moment from 'moment'
import axios from 'axios'
import * as yup from 'yup'
import i18n from 'i18n'

import Refund from '../refund/index'
import UploadFile from '../uploadfile/index'
import Signature from '../signature/index'

import callService from 'pages/settings/panes/HelpAndSupport/callService'
import CircularLoader from 'components/CircularLoader'
import Modal from 'components/containers/Modal'

import './detailcreditnote.scss'

import createStore from 'createStore'

export const store = createStore()

const index = () => {
  const { t } = useTranslation(['navbar', 'creditnote'])
  const history = useHistory()
  const params = useParams()

  const locale = i18n.language

  const accessToken = store.getState().getIn(['authentication', 'token'])
  const header = { authorization: `Bearer ${accessToken}` }
  const [isLoading, setIsloading] = useState(true)
  const [loadingPDF, setLoadingPDF] = useState(false)

  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [openModalSuccess, setOpenModalSuccess] = useState(false)
  const [openModalService, setOpenModalService] = useState(false)

  const displayTypeName = ['Mr.', 'Mrs.', 'Ms.']
  const [open, setOpen] = useState(false)

  const [rawData, setRawData] = useState({})

  const [invoiceTime, setInvoiceTime] = useState('')
  const [bankDataDetail, setBankDataDetail] = useState({})
  const [successLoading, setSuccessLoading] = useState(false)

  const getTimeinvoice = async (invoiceId) => {
    if (invoiceId) {
      const response = await callService(
        'GET',
        `${process.env.REACT_APP_BASE_API}/invoices/${invoiceId}`,
        null,
        header
      )
      setInvoiceTime(response.data.created_at)
    }
  }

  const getBankDetail = async (bankId) => {
    if (bankId) {
      const response = await callService(
        'GET',
        `${process.env.REACT_APP_BASE_API}/banks/${bankId}`,
        null,
        header
      )
      setBankDataDetail(response.data)
    }
  }

  const initialValues = {
    picked: rawData?.customer_nationality,
    taxId: '',
    branch: {
      pick: rawData?.customer_branch_type,
      number_branch: '',
      name_branch: rawData?.customer_branch_name
    },
    detail: {
      legal_type:
        rawData?.customer_legal_type === 'ordinary'
          ? 'Ordinary Person'
          : 'Juristic Person',
      full_name: rawData?.customer_name,
      select_gender: rawData?.customer_title,
      first_Name: rawData?.customer_first_name,
      last_Name: rawData?.customer_last_name
    },
    register: {
      address: rawData?.registered_address,
      district: rawData?.registered_district,
      sub_district: rawData?.registered_sub_district,
      city: rawData?.registered_city,
      country: rawData?.registered_country,
      post_code: rawData?.registered_postcode
    },
    contact_detail: {
      email: rawData?.contact_email,
      number: rawData?.contact_number
    },
    refund_detail: {
      invoiceID: rawData?.invoice_id,
      invoiceNo: rawData?.invoice_no,
      totalTax: '',
      issue_date: invoiceTime,
      issue_date_select: rawData?.issue_date,
      credit_note_info: rawData?.refund_reason_other ? 'other_reason' : 'booking_cancel',
      other_reason: rawData?.refund_reason_other,
      detail: rawData?.refund_jobs?.map((data) => {
        const priceNew = data.unit * data.unit_price
        return {
          ...data,
          unit: data?.unit.toLocaleString(),
          unit_price: data?.unit_price.toLocaleString(),
          price: `${priceNew.toLocaleString()}` || 0
        }
      })
    },
    upload_file: {
      create_at: `${moment(rawData?.transfer_slip_captured_at || new Date()).format(
        'DD/MM/YYYY HH:mm'
      )}`,
      fileSelect: []
    },
    signature: {
      file: rawData?.status !== 'pending_refund' ? rawData?.authorized_signature : '',
      first_name:
        rawData?.status !== 'pending_refund' ? rawData?.authorized_first_name : '',
      last_name: rawData?.status !== 'pending_refund' ? rawData?.authorized_last_name : ''
    },
    summary: {
      totalAmountTax: rawData?.tax_invoices_total_amount,
      newAmount: rawData?.new_amount,
      variance: rawData?.variance,
      vatAmount: rawData?.vat_total_amount,
      netAmount: rawData?.net,
      whtTax: rawData?.withholding_tax,
      totalAmount: rawData?.net_amount
    }
  }

  const ValidationSchema = yup.object().shape({
    upload_file: yup.object().shape({
      create_at: yup
        .string()
        .required('Capture at is required.')
        .matches(
          /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d (((([0-1][0-9])|(2[0-3])):?[0-5][0-9]$))$/,
          'Please make it like an example.'
        )
        .nullable(),
      fileSelect: yup.array().max(100, 'File exceeded').nullable()
    }),
    signature: yup.object().shape({
      file: yup.string().required('Signature is required.').nullable(),
      first_name: yup.string().required('First name is required.').nullable(),
      last_name: yup.string().required('Last name is required.').nullable()
    })
  })

  const renderTaxCss = (id) => {
    if (id === 0 || id === 4 || id === 9) {
      return 'mr-2'
    }
  }

  const changeBase = (dataSelect, action) => {
    action.setFieldValue('detail.select_gender', dataSelect)
    setOpen(false)
  }

  const callGetData = async () => {
    const response = await callService(
      'GET',
      `${process.env.REACT_APP_CANCELLATION_BASE_API}/api/credit-notes/${params.id}`,
      null,
      null
    )
    if (response.status === 200 || response.status === 201) {
      setRawData(response.data.data)
    }
    setIsloading(false)
  }

  const callServiceSubmit = async (values) => {
    const response = await callService(
      'PATCH',
      `${process.env.REACT_APP_CANCELLATION_BASE_API}/api/credit-notes/${rawData?.id}/void`,
      null,
      null
    )

    if (response.status === 200 || response.status === 201) {
      callGetData()
      setOpenModalConfirm(false)
      setOpenModalSuccess(true)
      setTimeout(() => {
        setOpenModalSuccess(false)
      }, 3000)
    }
  }

  const callServiceComplete = async () => {
    const response = await callService(
      'PATCH',
      `${process.env.REACT_APP_CANCELLATION_BASE_API}/api/credit-notes/${rawData?.id}/completed`,
      null,
      null
    )

    if (response.status === 200 || response.status === 201) {
      callGetData()
      setOpenModalConfirm(false)
      setOpenModalSuccess(true)
      setTimeout(() => {
        setOpenModalSuccess(false)
        setSuccessLoading(false)
      }, 3000)
    }
  }

  const callServiceSave = async (values) => {
    const formData = new FormData()
    values.upload_file.fileSelect.map((data, k) =>
      formData.append('credit_note_transfer_slips', data)
    )
    formData.append('authorized_signature', values.signature.file)
    formData.append('authorized_first_name', values.signature.first_name)
    formData.append('authorized_last_name', values.signature.last_name)

    setSuccessLoading(true)
    const response = await callService(
      'PUT',
      `${process.env.REACT_APP_CANCELLATION_BASE_API}/api/credit-notes/${rawData?.id}`,
      formData,
      null
    )
    if (response.status === 200 || response.status === 201) {
      callServiceComplete()
    }
  }

  const checkErrors = (action) => {
    const check = Object.keys(action.errors).length === 0
    if (!check) {
      setOpenModalService(false)
    } else {
      action.submitForm()
    }
  }

  const downloadImage = (data, filename = 'untitled.pdf') => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    document.body.appendChild(link)
    link.click()
    setLoadingPDF(false)
  }

  const handleDownloadPDF = async () => {
    setLoadingPDF(true)
    try {
      const header = {
        responseType: 'blob'
      }
      const response = await axios.get(
        `${process.env.REACT_APP_CANCELLATION_BASE_API}/api/credit-notes/${rawData?.id}/pdf`,
        header
      )
      downloadImage(response.data, `${Date.now()}.pdf`)
    } catch (error) {
      setLoadingPDF(false)
      alert('An error occurred while fetching the pdf. Please try again later.')
    }
  }

  useEffect(() => {
    callGetData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  useEffect(() => {
    getTimeinvoice(rawData?.invoice_id)
    getBankDetail(rawData?.cancellation_job?.recipient_bank_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData])

  return (
    <div>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
          <div className='section-top-btn-and-id'>
            <div>
              <div className='section-id-tax-and-status'>
                <div className='id-text-show'>{rawData?.credit_note_number}</div>
                <div
                  className={`status-text-show card-status-creditnite status-${rawData?.status}-creditnote`}
                >
                  {t(`creditnote_filter.${rawData?.status}`)}
                </div>
              </div>
              <div className='section-back-to-previous'>
                <ChevronLeft
                  className='icon-left pointer'
                  onClick={() => history.push('/creditnotes')}
                />
                <div className='pointer' onClick={() => history.push('/creditnotes')}>
                  Back to previous
                </div>
              </div>
            </div>
            {rawData?.status === 'completed' && (
              <div className='section-2-btn'>
                <div
                  className='btn-credit-note-1 btn-download-pdf pointer'
                  onMouseDown={() => !loadingPDF && handleDownloadPDF()}
                >
                  {loadingPDF ? (
                    <CircularLoader />
                  ) : (
                    <>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='17.603'
                        height='17.603'
                        viewBox='0 0 17.603 17.603'
                      >
                        <g id='icon' transform='translate(0.8 0.8)'>
                          <g id='Group_157469' data-name='Group 157469'>
                            <g id='Group_157466' data-name='Group 157466'>
                              <g
                                id='Icon_feather-download'
                                data-name='Icon feather-download'
                                transform='translate(0)'
                              >
                                <path
                                  id='Path_150601'
                                  data-name='Path 150601'
                                  d='M20.5,22.5v3.556a1.778,1.778,0,0,1-1.778,1.778H6.278A1.778,1.778,0,0,1,4.5,26.056V22.5'
                                  transform='translate(-4.5 -11.831)'
                                  fill='none'
                                  stroke='#fff'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='1.6'
                                />
                                <path
                                  id='Path_150602'
                                  data-name='Path 150602'
                                  d='M10.5,15l4.445,4.445L19.391,15'
                                  transform='translate(-6.941 -8.777)'
                                  fill='none'
                                  stroke='#fff'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='1.6'
                                />
                                <path
                                  id='Path_150603'
                                  data-name='Path 150603'
                                  d='M18,15.169V4.5'
                                  transform='translate(-9.997 -4.5)'
                                  fill='none'
                                  stroke='#fff'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='1.6'
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <p>Download PDF</p>
                    </>
                  )}
                </div>
                <div
                  className='btn-credit-note-1 btn-voide-creditnote pointer'
                  onClick={() => setOpenModalConfirm(true)}
                >
                  Void Credit Note
                </div>
              </div>
            )}
            {rawData?.status === 'pending_refund' && (
              <div className='section-2-btn'>
                <div
                  className='btn-credit-note-1 btn-voide-creditnote pointer'
                  onClick={() => setOpenModalConfirm(true)}
                >
                  Void Credit Note
                </div>
              </div>
            )}
          </div>
          <div className='section-money-refund-and-time'>
            <div>
              <div>
                <span className='text-money-1'>Refunded</span>
                <span className='text-money-1 pl-2'>
                  {rawData?.net_amount.toLocaleString()} ฿
                </span>
              </div>
            </div>
            <div>
              <div className='text-time-1'>
                {moment(rawData?.created_at).format('ddd, DD MMM YYYY - HH:mm')}
              </div>
            </div>
          </div>
          <div className='section-nationality'>
            <div>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={ValidationSchema}
                onSubmit={callServiceSave}
              >
                {(formik) => (
                  <Form>
                    <div className='bg-section-customer-detail'>
                      <div>
                        <div className='topic-credit-note'>{t('customer_detail')}</div>
                      </div>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <div className='section-box-first'>
                            <div className='text-nation'>{t('customer_nation')}</div>
                            <div className='section-radio-1'>
                              <Field type='radio' name='picked' value='thai' disabled />
                              <p>{t('thai')}</p>
                            </div>
                            <div className='section-radio-1'>
                              <Field type='radio' name='picked' value='others' disabled />
                              <p>{t('others')}</p>
                            </div>
                          </div>
                          <div className='text-topic-id-custom-create'>{t('taxId')}</div>
                          {formik.values.picked === 'thai' && (
                            <div className='section-taxid-custom mt-2'>
                              {rawData?.customer_tax_id ? (
                                <>
                                  {rawData?.customer_tax_id.split('').map((values, k) => {
                                    return (
                                      <div key={k} className={`${renderTaxCss(k)}`}>
                                        <input
                                          value={rawData?.customer_tax_id.split('')[k]}
                                          type='text'
                                          className='legal-tax-input'
                                          placeholder='X'
                                          disabled
                                        />
                                      </div>
                                    )
                                  })}
                                </>
                              ) : (
                                <>
                                  {new Array(13).fill('').map((v, l) => {
                                    return (
                                      <div key={l} className={`${renderTaxCss(l)}`}>
                                        <input
                                          type='text'
                                          className='legal-tax-input'
                                          placeholder='X'
                                          disabled
                                        />
                                      </div>
                                    )
                                  })}
                                </>
                              )}
                            </div>
                          )}
                          {formik.values.picked === 'others' && (
                            <div className='section-taxid-custom mt-2'>
                              <input
                                type='text'
                                value={rawData?.customer_tax_id}
                                maxLength='13'
                                className='legal-input'
                                placeholder={t('please_fill.taxID')}
                                disabled
                              />
                            </div>
                          )}
                          <div className='text-validate pl-2'>
                            <ErrorMessage
                              name='taxId'
                              component='div'
                              className='field-error'
                            />
                          </div>
                          <div className='section-box-first mt-1'>
                            <div className='text-nation'>{t('customer_branch')}</div>
                            <div className='section-radio-1'>
                              <Field type='radio' name='branch.pick' value='' disabled />
                              <p>{t('none')}</p>
                            </div>
                            <div className='section-radio-1'>
                              <Field
                                type='radio'
                                name='branch.pick'
                                value='headoffice'
                                disabled
                              />
                              <p>{t('head_office')}</p>
                            </div>
                            <div className='section-radio-1'>
                              <Field
                                type='radio'
                                name='branch.pick'
                                value='branch'
                                disabled
                              />
                              <p>{t('branch_office')}</p>
                            </div>
                          </div>
                          {formik.values.branch.pick && (
                            <div className='py-16'>
                              <div className='d-flex'>
                                {rawData?.customer_branch_id.split('').map((_, l) => {
                                  return (
                                    <div key={l}>
                                      <input
                                        value={rawData?.customer_branch_id.split('')[l]}
                                        type='text'
                                        className='legal-tax-input'
                                        disabled
                                      />
                                    </div>
                                  )
                                })}
                                <div className='section-name-branch'>
                                  <Field
                                    type='text'
                                    name='branch.name_branch'
                                    autoComplete='off'
                                    disabled
                                    className='legal-input'
                                    placeholder={t('please_fill.branch')}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className='mt-1'>
                            <div className='text-detail-legal mb-2'>
                              {t('business_detail')}
                            </div>
                            <Grid container spacing={3} className='mt-1'>
                              <Grid item xs={12} sm={5}>
                                <div>
                                  <span className='text-legal-type'>
                                    {t('business_type')}
                                  </span>
                                  <span className='dot-legal'>*</span>
                                </div>
                                <div className='mt-1'>
                                  <Field
                                    type='text'
                                    name='detail.legal_type'
                                    disabled
                                    className='legal-input'
                                    placeholder={t('please_fill.branch')}
                                  />
                                </div>
                              </Grid>
                              {formik.values.detail.legal_type !== 'Ordinary Person' && (
                                <Grid item xs={12} sm={6}>
                                  <div>
                                    <span className='text-legal-type'>
                                      {t('contact_name')}
                                    </span>
                                    <span className='dot-legal'>*</span>
                                  </div>
                                  <div className='mt-1'>
                                    <Field
                                      type='text'
                                      name='detail.full_name'
                                      className='legal-input'
                                      autoComplete='off'
                                      placeholder={`${t('please_fill.name')}`}
                                      disabled
                                    />
                                  </div>
                                </Grid>
                              )}
                            </Grid>
                            <div className='mt-2'>
                              {formik.values.detail.legal_type === 'Ordinary Person' && (
                                <Grid container spacing={3} className='mt-1'>
                                  <Grid item xs={12} sm={3} lg={2}>
                                    <div>
                                      <span className='text-legal-type'>
                                        {t('contact_name')}
                                      </span>
                                    </div>
                                    <div className='mt-1'>
                                      <div className='section-select-choice custom-bottom'>
                                        <div
                                          className='input-select-custom-creditnote-contact-name box-disabled-custom-id'
                                          tabIndex={0}
                                        >
                                          <div>
                                            <Field
                                              name='detail.select_gender'
                                              className='input-select-set-fee font-fee create-custom-2'
                                              placeholder='Title'
                                              value={formik.values.detail.select_gender}
                                              autoComplete='off'
                                              disabled
                                            />
                                            <div className='icon'>
                                              <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='16.648'
                                                height='9.52'
                                                viewBox='0 0 16.648 9.52'
                                                className={`${
                                                  open
                                                    ? 'animation-close'
                                                    : 'animation-open'
                                                }`}
                                              >
                                                <path
                                                  id='Icon_ionic-ios-arrow-down'
                                                  data-name='Icon ionic-ios-arrow-down'
                                                  d='M14.515,17.9l6.3-6.3a1.185,1.185,0,0,1,1.68,0,1.2,1.2,0,0,1,0,1.685l-7.133,7.138a1.188,1.188,0,0,1-1.641.035L6.534,13.286A1.19,1.19,0,0,1,8.215,11.6Z'
                                                  transform='translate(-6.188 -11.246)'
                                                  fill='gray'
                                                />
                                              </svg>
                                            </div>
                                          </div>
                                        </div>
                                        {open && (
                                          <div>
                                            <div className='show-menu pointer'>
                                              {displayTypeName.map((data, n) => {
                                                return (
                                                  <div
                                                    className='d-flex choice-custom-fee'
                                                    onMouseDown={(e) => {
                                                      e.preventDefault()
                                                      changeBase(data, formik)
                                                    }}
                                                    key={n}
                                                  >
                                                    <div>{data}</div>
                                                  </div>
                                                )
                                              })}
                                            </div>
                                            <div className='section-down-base' />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} sm={4} lg={3}>
                                    <div>
                                      <span className='dot-legal' />
                                    </div>
                                    <div className='mt-1'>
                                      <Field
                                        type='text'
                                        name='detail.first_Name'
                                        disabled
                                        className='legal-input'
                                        autoComplete='off'
                                        placeholder={`${t('please_fill.FirstName')}`}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} sm={4} lg={3}>
                                    <div>
                                      <span className='dot-legal' />
                                    </div>
                                    <div className='mt-1'>
                                      <Field
                                        type='text'
                                        name='detail.last_Name'
                                        disabled
                                        className='legal-input'
                                        autoComplete='off'
                                        placeholder={`${t('please_fill.LastName')}`}
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className='section-register-address'>
                      <div className='head-topic-text mt-2'>
                        {t('registered_address')}
                      </div>
                      <div>
                        <Grid item xs={12} sm={8}>
                          <div className='mt-2'>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12}>
                                <div className='mb-1 text-topic-register-address'>
                                  {t('register.address')}
                                </div>
                                <div>
                                  <Field
                                    type='text'
                                    name='register.address'
                                    className='legal-input-2'
                                    autoComplete='off'
                                    placeholder={`${t('please_fill.address')}`}
                                    disabled
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div className='mb-1 text-topic-register-address'>
                                  {t('register.district')}
                                </div>
                                <div>
                                  <Field
                                    type='text'
                                    name='register.district'
                                    className='legal-input'
                                    autoComplete='off'
                                    placeholder={`${t('please_fill.district')}`}
                                    disabled
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div className='mb-1 text-topic-register-address'>
                                  {t('register.sub_district')}
                                </div>
                                <div>
                                  <Field
                                    type='text'
                                    name='register.sub_district'
                                    className='legal-input'
                                    autoComplete='off'
                                    placeholder={`${t('please_fill.sub_district')}`}
                                    disabled
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div className='mb-1 text-topic-register-address'>
                                  {t('register.city')}
                                </div>
                                <div>
                                  <Field
                                    type='text'
                                    name='register.city'
                                    className='legal-input'
                                    autoComplete='off'
                                    placeholder={`${t('please_fill.city')}`}
                                    disabled
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div className='mb-1 text-topic-register-address'>
                                  {t('register.country')}
                                </div>
                                <div>
                                  <Field
                                    type='text'
                                    name='register.country'
                                    className='legal-input'
                                    autoComplete='off'
                                    placeholder={`${t('please_fill.country')}`}
                                    disabled
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div className='mb-1 text-topic-register-address'>
                                  {t('register.post_code')}
                                </div>
                                <div>
                                  <Field
                                    type='text'
                                    name='register.post_code'
                                    className='legal-input'
                                    autoComplete='off'
                                    placeholder={`${t('please_fill.post_code')}`}
                                    disabled
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </div>
                    </div>

                    <div className='section-register-address'>
                      <div className='head-topic-text mt-2'>
                        {t('contact_detail.topic')}
                      </div>
                      <div>
                        <Grid item xs={12} sm={8}>
                          <div className='mt-2'>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <div className='mb-1 text-topic-register-address'>
                                  {t('contact_detail.email')}
                                </div>
                                <div>
                                  <Field
                                    type='text'
                                    name='contact_detail.email'
                                    className='legal-input'
                                    autoComplete='off'
                                    placeholder={`${t('contact_fill.email')}`}
                                    disabled
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div className='mb-1 text-topic-register-address'>
                                  {t('contact_detail.number')}
                                </div>
                                <div>
                                  <Field
                                    type='text'
                                    name='contact_detail.number'
                                    className='legal-input'
                                    autoComplete='off'
                                    placeholder={`${t('contact_fill.number')}`}
                                    disabled
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </div>
                    </div>
                    <div className='section-register-address '>
                      <div className='head-topic-text mt-2'>{t('refund_detail')}</div>
                      <div className='mt-2'>
                        <Refund action={formik} name='refund_detail.detail' closeBtn />
                      </div>
                    </div>
                    <div className='section-register-address section-refund-main'>
                      <div className='head-topic-text mt-2'>{t('bank_detail')}</div>
                      <div className='mt-2'>
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={4} xl={3}>
                            <div className='section-id-tax-and-status'>
                              <div className='icon-bank-custom-creditnote'>
                                <img
                                  src={bankDataDetail.logo_url}
                                  alt='image_thumbnail'
                                  className='w-100 h-100'
                                />
                              </div>
                              <div className='number-account'>
                                {locale === 'en'
                                  ? bankDataDetail.name_en
                                  : bankDataDetail.name_th}
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={4} xl={3}>
                            <div className='section-id-tax-and-status'>
                              <div className='head-topic-text custom-color-bank-detail'>
                                {t('bank_account')}
                              </div>
                              <div className='number-account'>
                                {rawData?.cancellation_job
                                  ?.recipient_bank_account_number || '-'}
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={4} xl={4}>
                            <div className='section-id-tax-and-status'>
                              <div className='head-topic-text custom-color-bank-detail'>
                                {t('bank_name')}
                              </div>
                              <div className='number-account'>
                                {rawData?.cancellation_job?.recipient_bank_account_name ||
                                  '-'}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <div className='section-register-address section-refund-main'>
                      <div className='head-topic-text mt-2'>{t('upload_file')}</div>
                      <div className='mt-2'>
                        <UploadFile
                          action={formik}
                          slips={rawData?.credit_note_transfer_slips}
                          closeBtn={rawData?.status !== 'pending_refund'}
                        />
                      </div>
                    </div>
                    <div className='section-register-address section-refund-main'>
                      <div className='head-topic-text mt-2'>
                        {t('authen.topic')}
                        <span className='dot-legal'>*</span>
                      </div>
                      <div className='mt-2'>
                        <Signature
                          action={formik}
                          closeBtn={rawData?.status !== 'pending_refund'}
                        />
                      </div>
                    </div>
                    {rawData?.status === 'pending_refund' && (
                      <div className='section-btn-create-credit-note'>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() => setOpenModalService(true)}
                        >
                          {t('btn.createcreditnote')}
                        </Button>
                      </div>
                    )}

                    <Modal
                      isOpen={openModalConfirm}
                      onCloseModal={() => setOpenModalConfirm(false)}
                      className='section-custom-main-modal'
                    >
                      <div className='custom-padding-modal'>
                        <div className='text-center'>
                          <div className='modal-topic'>
                            {t('modal.headcreditnoteVoid')} {rawData?.credit_note_number}
                          </div>
                        </div>
                        <div className='d-flex align-item-center justify-content-center mt-2'>
                          <button
                            className='custom-btn-cancel-modal-2 d-flex align-item-center justify-content-center pointer'
                            onClick={() => setOpenModalConfirm((pre) => !pre)}
                          >
                            {t('btn.cancel')}
                          </button>
                          <button
                            className='custom-btn-cancel-modal-1 d-flex align-item-center justify-content-center pointer'
                            onClick={() => callServiceSubmit()}
                          >
                            {t('btn.confirm')}
                          </button>
                        </div>
                      </div>
                    </Modal>
                    {rawData?.status === 'pending_refund' && (
                      <Modal
                        isOpen={openModalService}
                        onCloseModal={() => setOpenModalService(false)}
                        className='section-custom-main-modal'
                      >
                        {successLoading ? (
                          <CircularLoader />
                        ) : (
                          <div className='custom-padding-modal'>
                            <div className='text-center'>
                              <div className='modal-topic'>
                                {t('modal.headcreditnote')}{' '}
                                {formik.values.refund_detail.invoiceID}
                              </div>
                              <div className='modal-content mt-3'>
                                {t('modal.titlecreditnote')}
                              </div>
                            </div>
                            <div className='d-flex align-item-center justify-content-center mt-2'>
                              <button
                                className='custom-btn-cancel-modal-2 d-flex align-item-center justify-content-center pointer'
                                onClick={() => setOpenModalService((pre) => !pre)}
                              >
                                {t('btn.cancel')}
                              </button>
                              <button
                                className='custom-btn-cancel-modal-1 d-flex align-item-center justify-content-center pointer'
                                onClick={() => checkErrors(formik)}
                              >
                                {t('btn.continue')}
                              </button>
                            </div>
                          </div>
                        )}
                      </Modal>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
            <Modal
              isOpen={openModalSuccess}
              onCloseModal={() => setOpenModalSuccess(false)}
              className='section-custom-main-modal-success'
            >
              <div className='d-flex align-item-center justify-content-between'>
                <div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='32.94'
                    height='32.94'
                    viewBox='0 0 32.94 32.94'
                  >
                    <path
                      d='M33.5,17.032A16.47,16.47,0,1,1,17.032.562,16.47,16.47,0,0,1,33.5,17.032ZM15.127,25.753l12.22-12.22a1.063,1.063,0,0,0,0-1.5l-1.5-1.5a1.063,1.063,0,0,0-1.5,0l-9.966,9.965L9.723,15.841a1.063,1.063,0,0,0-1.5,0l-1.5,1.5a1.063,1.063,0,0,0,0,1.5l6.907,6.907a1.063,1.063,0,0,0,1.5,0Z'
                      transform='translate(-0.562 -0.562)'
                      fill='#1b4171'
                    />
                  </svg>
                </div>
                <div className='topic-success-modal'>{t('creditnote.success')}</div>
              </div>
            </Modal>
          </div>
        </>
      )}
    </div>
  )
}

export default index
