import { connect } from 'react-redux'
import { compose } from 'redux'

import { fetch } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { withQueryParser } from 'utils/withQueryParser'
import { badgeSchema } from '@seekster/schemas'
import FilterBadgeTags from './FilterBadgeTags'

const mapStateToProps = (state, { query }) => {
  const badge = getResource(state, query.badge_id, badgeSchema)

  return ({
    badgeName: badge && badge.get('name')
  })
}

const mapDispatchToProps = {
  fetchBadge: (id, options) => fetch(id, badgeSchema, options)
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(FilterBadgeTags)
