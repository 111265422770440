import moment from 'moment'
import { get } from 'lodash'

export const promocodesQuery = ({
  search,
  amount_off_satangs,
  percent_off,
  quantity,
  limit_per_user,
  start_date,
  expiration_date
}) => {
  return {
    code_cont: search,

    percent_off_gteq: get(percent_off, 'min', undefined),
    percent_off_lteq: get(percent_off, 'max', undefined),

    quantity_gteq: get(quantity, 'min', undefined),
    quantity_lteq: get(quantity, 'max', undefined),

    limit_per_user_gteq: get(limit_per_user, 'min', undefined),
    limit_per_user_lteq: get(limit_per_user, 'max', undefined),

    amount_off_satangs_gteq: get(amount_off_satangs, 'min', undefined),
    amount_off_satangs_lteq: get(amount_off_satangs, 'max', undefined),
    amount_off_currency_eq: get(amount_off_satangs, 'currency'),

    start_date_gteq: get(start_date, 'from')
      ? moment(start_date.from).startOf('day').toISOString(true)
      : undefined,
    start_date_lteq: get(start_date, 'to')
      ? moment(start_date.to).endOf('day').toISOString(true)
      : undefined,

    expiration_date_gteq: get(expiration_date, 'from')
      ? moment(expiration_date.from).startOf('day').toISOString(true)
      : undefined,
    expiration_date_lteq: get(expiration_date, 'to')
      ? moment(expiration_date.to).endOf('day').toISOString(true)
      : undefined
  }
}

export default promocodesQuery
