import { connect } from 'react-redux'

import { fetch, fetchResourceChild, create, destroyChild, updateChild } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { integrationSchema, integrationInstanceSchema } from '@seekster/schemas'

import IntegrationShowPage from './IntegrationShowPage'

const mapStateToProps = (state, { match }) => ({
  integration: getResource(state, match.params.id, integrationSchema)
})

const mapDispatchToProps = {
  fetchIntegration: id => fetch(id, integrationSchema),
  fetchIntegrationInstance: id => fetchResourceChild(integrationSchema, id, integrationInstanceSchema),
  createIntegrationInstance: data => create(data, integrationInstanceSchema),
  destroyIntegrationInstance: id => destroyChild(integrationSchema, id, integrationInstanceSchema),
  updateIntegrationConfigs: (id, data) => updateChild(data, integrationSchema, id, integrationInstanceSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationShowPage)
