import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/buttons'
import {
  Box, Grid, Typography, Paper,
  List, ListSubheader,
  Table, TableHead, TableBody, TableRow
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { inquirySchema } from '@seekster/schemas'
import { get, set, reduce, isEmpty, isArray, trimEnd, map } from 'lodash'

import { createCollection } from 'actions/collections'

import CsvUpload from 'components/CsvUpload'
import PageLayout from 'components/layouts/PageLayout'
import TableCell from 'components/tables/TableCell'
import { usePush } from 'hooks/utils'
import ExpandableListItem from 'components/ExpandableListItem'
import { Alert } from '@material-ui/lab'

const ColumnErrorMessage = ({ columnName, errors }) => {
  return (
    <>
      <strong>{columnName}</strong>: {errors.join(', ')}
    </>
  )
}

export function UploadInquiriesPage() {
  const dispatch = useDispatch()
  const [errors, setErrors] = useState([])
  const [headers, setHeaders] = useState([])
  const [json, setJson] = useState([])
  const push = usePush()
  const { t } = useTranslation('inquiries')

  const handleOnClick = () => {
    dispatch(createCollection({ inquiries: json }, inquirySchema, { type: 'inquiries/bulk_upload' }))
      .then(() => {
        push('/inquiries')
      })
      .catch((error) => {
        setErrors(error.response.body)
      })
  }

  const handleDrop = (result) => {
    setErrors(result.map(row => ({})))
    setHeaders(Object.keys(result[0]))
    setJson(result.map(row => reduce(row, (object, value, key) => {
      if (isEmpty(value)) {
        return object
      }

      if (key.endsWith('choice_ids')) {
        value = value.split(',').map(item => item.trim())
      }

      return set(object, key, value)
    }, {})))
  }

  return (
    <PageLayout title={t('upload')} back='/inquiries'>
      <Grid container>
        <Grid item lg={9} xs={6}>
          <Box p={3}>
            <CsvUpload onHandleDrop={handleDrop} />

            {
              json && (
                <Box>
                  {
                    errors.find(error => !isEmpty(error)) && (
                      <Box my={3}>
                        <Alert severity='error'>
                          {
                            errors.map((errorRow, errorIndex) => !isEmpty(errorRow) && (
                              <div key={errorIndex}>
                                <label>Line {errorIndex + 1}</label>
                                <ul>
                                  {
                                    map(errorRow, (columnErrors, columnName) => (
                                      <li>
                                        <ColumnErrorMessage columnName={columnName} errors={columnErrors} />
                                      </li>
                                    ))
                                  }
                                </ul>
                              </div>
                            ))
                          }
                        </Alert>
                      </Box>
                    )
                  }

                  <Box mt={3} overflow='scroll'>
                    <Table collection={json}>
                      <TableHead>
                        <TableRow>
                          {
                            headers.map((columnName, index) => (
                              <TableCell key={index}>
                                {columnName}
                              </TableCell>
                            ))
                          }
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          json.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                              {
                                headers.map((columnName, columnIndex) => {
                                  const value = get(row, columnName)
                                  const error = get(errors[rowIndex], columnName) || get(errors[rowIndex], trimEnd(columnName, '_id'))

                                  return (
                                    <TableCell key={columnIndex} error={error}>
                                      {isArray(value) ? value.join(', ') : value}
                                    </TableCell>
                                  )
                                })
                              }
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </Box>

                  <Box my={3}>
                    <Button
                      color='primary'
                      variant='contained'
                      size='small'
                      onClick={handleOnClick}
                    >
                      {t('generate')}
                    </Button>
                  </Box>

                  {
                    process.env.NODE_ENV === 'development' && (
                      <Grid container spacing={3}>
                        <Grid item>
                          Data
                          <pre>{JSON.stringify(json, null, 4)}</pre>
                        </Grid>

                        <Grid item>
                          Errors
                          <pre>{JSON.stringify(errors, null, 4)}</pre>
                        </Grid>
                      </Grid>
                    )
                  }
                </Box>
              )
            }
          </Box>
        </Grid>

        <Grid item lg={3} xs={6}>
          <Paper square>
            <List
              subheader={
                <ListSubheader>Accepted Headers</ListSubheader>
              }
            >
              <ExpandableListItem primary='customer_id'>
                <Typography variant='caption'>เลข ID ของบัญชีลูกค้า (จำเป็นต้องกรอก)</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='customer_attributes.first_name'>
                <Typography variant='caption'>ชื่อลูกค้า</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='customer_attributes.last_name'>
                <Typography variant='caption'>นามสกุลลูกค้า</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='customer_attributes.email'>
                <Typography variant='caption'>อีเมลลูกค้า</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='customer_attributes.phone_number'>
                <Typography variant='caption'>เบอร์โทรของลูกค้าที่ใช้ลงทะเบียนเปิดบัญชี</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='company_id'>
                <Typography variant='caption'>เลข ID ของลูกค้าบริษัท</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='branch_id'>
                <Typography variant='caption'>รหัสสาขาของบริษัท (หากต้องการเปิดใบงานสำหรับลูกค้าบริษัท ต้องกรอก Branch ID Company ID และ Customer ID ด้วย)</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='service_id'>
                <Typography variant='caption'>เลข ID ของบริการ (จำเป็นต้องกรอก)</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='orders_attributes[0].package_id'>
                <Typography variant='caption'>เลข ID ของบริการย่อยหรือออเดอร์ (จำเป็นต้องกรอก อย่างน้อย 1 ชุด)</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='orders_attributes[0].quantity'>
                <Typography variant='caption'>จำนวนของออเดอร์ (จำเป็นต้องกรอก อย่างน้อย 1 ชุด)</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='jobs_attributes[0].start_time'>
                <Typography variant='caption'>วันเวลาที่เริ่มงาน (จำเป็นต้องกรอก) โดยกรอกได้ 2 รูปแบบ คือ 20/7/2022 12:00 หรือ 20 July 2020 12:00</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='jobs_attributes[0].end_time'>
                <Typography variant='caption'>วันเวลาที่สิ้นสุดงาน ใช้สำหรับงานที่เปิดการใช้สล๊อต</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='location_attributes.contact_name'>
                <Typography variant='caption'>ชื่อผู้ติดต่อ</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='location_attributes.phone_number'>
                <Typography variant='caption'>เบอร์โทรติดต่อ</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='location_attributes.house_number'>
                <Typography variant='caption'>บ้านเลขที่</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='location_attributes.project_name'>
                <Typography variant='caption'>ชื่อโครงการ เช่น หมู่บ้าน คอนโด</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='location_attributes.alley'>
                <Typography variant='caption'>ซอย</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='location_attributes.street'>
                <Typography variant='caption'>ถนน</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='location_attributes.sub_district'>
                <Typography variant='caption'>แขวง/ตำบล</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='location_attributes.district'>
                <Typography variant='caption'>เขต/อำเภอ</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='location_attributes.province'>
                <Typography variant='caption'>จังหวัด</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='location_attributes.zip_code'>
                <Typography variant='caption'>รหัสไปรษณีย์</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='location_attributes.landmark'>
                <Typography variant='caption'>จุดสังเกต</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='location_attributes.latitude'>
                <Typography variant='caption'>ละติจูด (หากระบุละติจูด และลองจิจูดครบถ้วน ระบบจะแสดงผลหมุดที่ตั้งที่ถูกต้องมาให้)</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='location_attributes.longitude'>
                <Typography variant='caption'>ลองจิจูด (หากระบุละติจูด และลองจิจูดครบถ้วน ระบบจะแสดงผลหมุดที่ตั้งที่ถูกต้องมาให้)</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='answers_attributes[0].question_id'>
                <Typography variant='caption'>เลข ID ของคำถามเปิดใบงาน</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='answers_attributes[0].choice_id'>
                <Typography variant='caption'>เลข ID ของคำตอบ กรณีที่เลือกตอบแบบตัวเลือก หรือ choice</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='answers_attributes[0].choice_ids'>
                <Typography variant='caption'>เลข ID ของคำตอบ กรณีที่เลือกตอบแบบหลายคำตอบ โดยกรุณากรอกเฉพาะเลข ID ตามด้วยเครื่องหมาย , เช่น 2001, 2002, 2003 เป็นต้น</Typography>
              </ExpandableListItem>

              <ExpandableListItem primary='answers_attributes[0].value'>
                <Typography variant='caption'>คำตอบของคำถามเปิดใบงาน สามารถระบุเป็นข้อความ หรือตัวเลข</Typography>
              </ExpandableListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default UploadInquiriesPage
