import request from 'utils/request'
import convertJsonBodyToFormData from 'utils/convertJsonBodyToFormData'

import {
  SET_FLASH_MESSAGE,
  CLEAR_FLASH_MESSAGE,
  UPDATE_LOCALE_SUCCEEDED,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  TOGGLE_SIDEBAR
} from 'constants/interface'

const updateLocaleSucceed = (response) => ({
  type: UPDATE_LOCALE_SUCCEEDED,
  response
})

export const setFlashMessage = (messageType, message) => ({
  type: SET_FLASH_MESSAGE,
  payload: { messageType, message }
})

export const clearFlashMessage = () => ({
  type: CLEAR_FLASH_MESSAGE
})

export const openSidebar = () => ({
  type: OPEN_SIDEBAR
})

export const closeSidebar = () => ({
  type: CLOSE_SIDEBAR
})

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR
})

export const updateLocale = (data) => (dispatch, getState) => {
  const accessToken = getState().getIn(['authentication', 'token'])

  const formData = convertJsonBodyToFormData(data)

  const requestBuilder = request
    .patch(`/device`)
    .authentication(accessToken)

  Object.keys(formData).forEach(key => {
    if (typeof formData[key] !== 'undefined' && formData[key] !== null) {
      requestBuilder.field(key, formData[key])
    }
  })

  return requestBuilder
    .then(response => {
      dispatch(updateLocaleSucceed(response))

      return response
    })
}
