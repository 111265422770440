import { connect } from 'react-redux'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'
import { fetchChildren } from 'actions/resources'
import {
  fetchChildren as fetchCollectionChildren,
  fetch as fetchCollection
} from 'actions/collections'
import { getResource } from 'selectors/resources'
import {
  customerSchema,
  walletSchema,
  walletTransactionSchema,
  jobSchema
} from '@seekster/schemas'

import CustomerWalletPane from './CustomerWalletPane'

const mapStateToProps = (state, { match }) => ({
  customer: getResource(state, match.params.id, customerSchema)
})

const mapDispatchToProps = {
  fetchWallet: (id, options) => fetchChildren(customerSchema, id, walletSchema, options),
  fetchWalletTransactions: (id, options) =>
    fetchCollectionChildren(customerSchema, id, walletTransactionSchema, {
      ...options,
      childrenType: 'wallet/transactions'
    }),

  fetchCustomerJobs: (id, options = {}) => {
    return fetchCollection(jobSchema, {
      queryParam: {
        query: {
          customer_id_eq: id
        },
        order: {
          start_time: 'desc'
        },
        ...options
      }
    })
  }
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(CustomerWalletPane)
