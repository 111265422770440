import { connect } from 'react-redux'
import { Map } from 'immutable'

import { getResource } from 'selectors/resources'
import { getCurrentIdAttribute } from 'selectors/authentication'
import { fetchChildren } from 'actions/collections'
import { conversationSchema, messageSchema, agentSchema } from '@seekster/schemas'

import ConversationMessagesPanel from './ConversationMessagesPanel'

const mapStateToProps = (state, { match }) => {
  const conversation = getResource(state, match.params.id, conversationSchema) || Map()

  return {
    messages: conversation.get('messages'),
    currentAgentId: getCurrentIdAttribute(state, agentSchema)
  }
}

const mapDispatchToProps = {
  fetchConversationMessages: id => fetchChildren(conversationSchema, id, messageSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationMessagesPanel)
