import React from 'react'
import { Form } from 'formik'
import { Button } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import FastField from 'components/fields/Field'

export function AcceptInvitationForm ({ t }) {
  return (
    <Form>
      <FastField
        name='password'
        type='password'
        noLabel
        placeholder={t('password')}
      />

      <Button type='submit' fullWidth variant='contained' color='secondary'>
        {t('set_password')}
      </Button>
    </Form>
  )
}

export default withTranslation('authentication')(AcceptInvitationForm)
