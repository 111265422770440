import React from 'react'
import { Table, Column } from 'components/tables/Table'
import { Paper, Avatar, Button } from '@material-ui/core'

import { DateTag } from 'components/tags'
import { useTranslation } from 'react-i18next'

export function OrganizationsAssignTable({ organizations, handleAssignOrg }) {
  const { t } = useTranslation('jobs')

  return (
    <Paper>
      <Table collection={organizations}>
        <Column
          name='name'
          render={(name, organizations) => (
            <>
              <Avatar
                style={{
                  marginRight: 4,
                  verticalAlign: 'middle',
                  display: 'inline-block',
                  height: 40,
                  width: 40
                }}
                src={
                  organizations.get('pictureUrl') !== ''
                    ? organizations.get('pictureUrl')
                    : 'https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/Org-icon.svg'
                }
              />
              {name}
            </>
          )}
        />
        <Column name='email' />
        <Column name='phoneNumber' />
        <Column name='members' render={(members) => <div>{`${members.size} คน`}</div>} />
        <Column name='createdAt' component={DateTag} isSort />
        <Column
          name='assign_job'
          render={(_, organizations) => (
            <Button
              onClick={() => handleAssignOrg(organizations.get('_id'))}
              variant='contained'
              color='primary'
            >
              {t('assign_job')}
            </Button>
          )}
        />
      </Table>
    </Paper>
  )
}

export default OrganizationsAssignTable
