import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Button, Switch, Typography, Select, InputBase, MenuItem } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import withLocalizedFields from 'hoc/withLocalizedFields'
import Panel from 'components/containers/Panel'
import Field from 'components/fields/Field'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'
import { CURRENCIES } from 'constants/currencies'

import CompanyBillingAddressFormSection from 'modules/companies/CompanyBillingAddressFormSection'

import styles from './TenantAccountingConfigFormStyles'

export function TenantAccountingConfigForm ({
  resetForm,
  classes,
  dirty,
  locale,
  setLocale,
  t
}) {
  function handleSetLocale (e) {
    setLocale(e.target.value)
  }

  function actions () {
    return [
      <Select
        key='1'
        value={locale}
        onChange={handleSetLocale}
        input={<InputBase />}
        className={classes.select}
      >
        <MenuItem value='en'><span role='img' aria-label='GB flag'>🇬🇧 </span>English</MenuItem>
        <MenuItem value='th'><span role='img' aria-label='Thai flag'>🇹🇭 </span>ภาษาไทย</MenuItem>
      </Select>
    ]
  }

  return (
    <Form>
      <Panel title={t('accounting_config.title')}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Field name='holding_duration' type='number' hint={t('second')} />
          </Grid>

          <Grid item xs={6}>
            <Field name='tax_rate' type='number' hint={t('decimal')} />
          </Grid>

          <Grid item xs={6}>
            <Field name='withholding_tax_rate' type='number' hint={t('decimal')} />
          </Grid>

          <Grid item xs={6}>
            <Field
              name='default_currency'
              component={SelectInput}
              options={arrayToOptions(CURRENCIES)}
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              name='active'
              noLabel
              render={({ field }) => (
                <>
                  <Switch checked={field.value} {...field} />
                  <Typography inline variant='subtitle1'>
                    {t(`activeness.${field.value}`)}
                  </Typography>
                </>
              )}
            />
          </Grid>
        </Grid>
      </Panel>

      <Panel title={t('billing_config.title')} actions={actions()}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Field name='tax_number' type='number' />
          </Grid>

          <Grid item xs={12}>
            <Field name='billing_email' type='email' />
          </Grid>

          <Grid item xs={12}>
            <CompanyBillingAddressFormSection name='billing_address_attributes' />
          </Grid>
        </Grid>
      </Panel>

      {
        dirty && (
          <Grid item xs={12}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.button}
            >
              {t('save')}
            </Button>
            <Button
              variant='contained'
              onClick={() => resetForm()}
              className={classes.button}
            >
              {t('cancel')}
            </Button>
          </Grid>
        )
      }
    </Form>
  )
}

TenantAccountingConfigForm.propTypes = {
  resetForm: PropTypes.func,
  classes: PropTypes.object,
  dirty: PropTypes.bool,
  locale: PropTypes.string,
  setLocale: PropTypes.func,

  t: PropTypes.func
}

export default compose(
  withLocalizedFields,
  withStyles(styles),
  withTranslation(['actions', 'settings'])
)(TenantAccountingConfigForm)
