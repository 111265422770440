import React from 'react'
import { Switch } from 'react-router-dom'
import { EditRounded, Delete } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import { useResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'

import PageLayout from 'components/layouts/PageLayout'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import { webhookHandlerSchema } from '@seekster/schemas'

import { WebhookOverviewPane } from './panes'

export function WebhookShowPage ({ match, location }) {
  const { t } = useTranslation(['regions', 'common'])
  const [webhook, { loading }] = useResource(webhookHandlerSchema, match.params.id)
  const { delete: deleteWebhook } = useResourceAction(webhookHandlerSchema)
  const push = usePush()

  function handleDestroy () {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      deleteWebhook(match.params.id)
        .then(() => push('/settings/webhook_settings'))
    }
  }

  function secondaryActions () {
    const actions = [{
      avatar: <EditRounded />,
      content: t('edit'),
      to: `/webhooks/${match.params.id}/edit`
    }, {
      avatar: <Delete />,
      content: t('delete'),
      onClick: handleDestroy
    }]

    return actions
  }

  function subNavbar () {
    const path = `/webhooks/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('navbar.overview')} to={path} />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs () {
    return [{
      text: t('title'),
      link: '/settings/webhook_settings'
    }, {
      text: webhook.get('name')
    }]
  }

  return (
    <PageLayout
      title={webhook.get('name')}
      actions={secondaryActions()}
      subNavbar={subNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
      back='/settings/webhook_settings'
    >
      <Switch>
        <AuthenticatedRoute path='/webhooks/:id' component={WebhookOverviewPane} exact />
      </Switch>
    </PageLayout>
  )
}

export default WebhookShowPage
