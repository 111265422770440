import React from 'react'
import { Dialog } from '@material-ui/core'
import { tenantSchema } from '@seekster/schemas'
import { useSelector } from 'react-redux'

import { getCurrentResource } from 'selectors/authentication'
import { useModal } from 'hooks/useModal'
import { ASSIGN_PROVIDER } from 'constants/modal'
import AssignProvider from './contents/AssignProvider'

import './AlertModal.scss'

function AlertModal() {
  const { open, name } = useModal()
  const tenant =
    useSelector((state) => getCurrentResource(state, tenantSchema)) || new Map()

  function renderContent() {
    switch (name) {
      case ASSIGN_PROVIDER:
        return <AssignProvider isHDL={!['seekster'].includes(tenant.get('slug'))} />
      default:
        return null
    }
  }

  return (
    <Dialog className='alert-modal' fullWidth open={open}>
      {renderContent()}
    </Dialog>
  )
}

export default AlertModal
