import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import MomentPropTypes from 'react-moment-proptypes'
import { Map } from 'immutable'
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { getAppConfigs } from 'selectors/configurations'

import DatePicker from 'components/inputs/DatePicker'
import TimePicker from 'components/inputs/TimePickerNew'
import TimeSelect from 'components/inputs/TimeSelect'

export function DateTimePicker ({
  value,
  onChange,
  highlightedSchedules,
  highlightLegendDescription,
  timePickerDuration,
  timeSelectAdditionalInfo
}) {
  const appConfigs = useSelector(state => getAppConfigs(state)) || Map()

  const date = moment(value)
  const hour = date.hour()
  const minute = date.minute()

  const highlightedDates = [...new Set(highlightedSchedules.map(({ startTime }) => (
    startTime.format('YYYY-MM-DD')
  )))].map(uniqueDate => moment(uniqueDate))

  const highlightedTimes = highlightedSchedules
    .filter(({ startTime }) => startTime.isSame(date, 'day'))
    .map(({ startTime, endTime }) => ({
      start: { hour: startTime.hour(), minute: startTime.minute() },
      end: { hour: endTime.hour(), minute: endTime.minute() }
    }))

  function handleDateChange (newDate) {
    const newValue = newDate.hour(hour).minute(minute).toISOString(true)

    handleChange(newValue)
  }

  function handleTimeChange ({ hour: newHour, minute: newMinute }) {
    const newValue = date.hour(newHour).minute(newMinute).toISOString(true)

    handleChange(newValue)
  }

  function handleChange (newValue) {
    onChange && onChange(newValue)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <DatePicker
          date={date}
          onChange={handleDateChange}
          focused={Boolean(date)}
          initialVisibleMonth={() => date}
          disabledPastDate
          highlightedDates={highlightedDates}
          highlightLegendDescription={highlightLegendDescription}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        {
          appConfigs.get('timePickerType') === 'custom' ? (
            <TimeSelect
              hour={hour}
              minute={minute}
              onChange={handleTimeChange}
              additionalInfo={timeSelectAdditionalInfo}
            />
          ) : (
            <TimePicker
              hour={hour}
              minute={minute}
              onChange={handleTimeChange}
              highlightedTimes={highlightedTimes}
              minuteDuration={timePickerDuration}
            />
          )
        }
      </Grid>
    </Grid>
  )
}

DateTimePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  highlightedSchedules: PropTypes.arrayOf(PropTypes.shape({
    startTime: MomentPropTypes.momentObj,
    endTime: MomentPropTypes.momentObj
  })),
  highlightLegendDescription: PropTypes.node,
  timePickerDuration: PropTypes.number,
  timeSelectAdditionalInfo: PropTypes.node
}

DateTimePicker.defaultProps = {
  highlightedSchedules: []
}

export default DateTimePicker
