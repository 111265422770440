import { connect } from 'react-redux'

import { fetch, update } from 'actions/resources'
import { fetchCurrent } from 'actions/authentication'
import { push } from 'react-router-redux'
import { getResource } from 'selectors/resources'
import { getCurrentResource, getCurrentIdAttribute } from 'selectors/authentication'
import { agentSchema } from '@seekster/schemas'

import AgentEditPage from './AgentEditPage'

const mapStateToProps = (state, { match }) => ({
  agent: match.params.id
    ? getResource(state, match.params.id, agentSchema)
    : getCurrentResource(state, agentSchema),
  currentAgent: getResource(state, getCurrentIdAttribute(state, agentSchema), agentSchema)
})

const mapDispatchToProps = {
  fetchAgent: id => fetch(id, agentSchema),
  fetchCurrentAgent: () => fetchCurrent(agentSchema),
  updateAgent: (id, data) => update(id, data, agentSchema),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentEditPage)
