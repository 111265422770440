import React from 'react'
import PropTypes from 'prop-types'
import RichTextEditor from 'react-rte'

import './RichTextInput.scss'

export class RichTextInput extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool
  }

  state = {
    richValue: RichTextEditor.createValueFromString(this.props.value, 'html'),
    htmlValue: this.props.value,
  };

  componentWillReceiveProps(newProps) {
    if (newProps.value != this.state.htmlValue) {
      this.setState({
        richValue: RichTextEditor.createValueFromString(newProps.value, 'html'),
        htmlValue: newProps.value,
      });
    }
  }

  onChange = (richValue) => {
    this.setState({ richValue, htmlValue: richValue.toString('html') }, () => {
      this.props.onChange(this.state.htmlValue);
    });
  };

  render() {
    const { name, value, onChange, ...rest } = this.props

    return (
      <RichTextEditor value={this.state.richValue} onChange={this.onChange} {...rest} />
    )
  }
}

export default RichTextInput
