import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { promoCodeSchema, inquirySchema } from '@seekster/schemas'

import PromoCodeRequestsPane from './PromoCodeRequestsPane'

const mapStateToProps = (state, { match }) => ({
  promoCode: getResource(state, match.params.id, promoCodeSchema)
})

const mapDispatchToProps = {
  fetchPromoCodeRequests: (id, options) => fetchChildren(promoCodeSchema, id, inquirySchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeRequestsPane)
