import React, { useEffect, useState } from 'react'
import { Grid, Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { smsRatingSchema } from '@seekster/schemas'

import useQuery from 'hooks/useQuery'
import { useCollection } from 'hooks/collections'
import PageLayout from 'components/layouts/PageLayout'
import Search from 'components/Search'
import FilterModal from 'components/FilterModal'
import FilterTags from 'components/tags/FilterTags'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import SMSRatingsTable from 'components/tables/SMSRatingsTable'
import Pagination from 'components/Pagination'
import SMSRatingsFilterForm from 'forms/filters/SMSRatingsFilterForm'
import Modal from 'components/containers/Modal'
import ButtonLoader from 'components/buttons/ButtonLoader'
import CheckCircle from '@material-ui/icons/CheckCircle'
import CancelCircle from '@material-ui/icons/Cancel'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

export function SMSRatingIndexPage({ sendIndividualSMS, sendMultipleSMS }) {
  const classes = useStyles()
  const [query] = useQuery()
  const [smsLoading, setSmsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [sendSmsStatus, setSendSmsStatus] = useState('')
  const [openStatusModal, setOpenStatusModal] = useState(false)
  const [sendSmsCount, setSendSmsCount] = useState(0)

  const { t } = useTranslation(['sms_ratings', 'inquiries'])
  const { search, sort, ...rest } = query
  const [jobs, { loading, totalCount, totalPages }] = useCollection(
    smsRatingSchema,
    { page: query.page, search: search, order: sort, filter: rest },
    [query, sendSmsCount]
  )

  useEffect(() => {
    setSendSmsCount(0)
  }, [query])

  const handleSendSMSCount = () => setSendSmsCount(sendSmsCount + 1)

  const handleSendIndividualSMS = (id) => {
    setSmsLoading(true)
    sendIndividualSMS(id)
      .then(() => {
        setSendSmsStatus('success')
        setOpenStatusModal(true)
        setSmsLoading(false)
        handleSendSMSCount()
      })
      .catch(error => {
        setSendSmsStatus(error.response.body.message)
        setOpenStatusModal(true)
        setSmsLoading(false)
      })
  }

  const handleCloseModal = () => setOpenModal(false)
  const handleOpenModal = () => setOpenModal(true)

  const handleSendMultipleSMS = () => {
    setSmsLoading(true)
    handleCloseModal()
    sendMultipleSMS({ search, filter: rest })
      .then(() => {
        setSendSmsStatus('success')
        setOpenStatusModal(true)
        setSmsLoading(false)
        handleSendSMSCount()
      })
      .catch(error => {
        const { message, exception } = error.response.body
        setSendSmsStatus(message || exception)
        setOpenStatusModal(true)
        setSmsLoading(false)
      })
  }

  return (
    <PageLayout title={t('title')}>
      {/* Modal Status */}
      <Modal
        isOpen={openStatusModal}
        onCloseModal={() => setOpenStatusModal(false)}
      >
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {
            sendSmsStatus === 'success' ? (
              <>
                <CheckCircle style={{ marginRight: 14, color: '#21ba45' }} />
                <div style={{ display: 'inline', color: '#1b4170', fontWeight: 'bold' }}>{t('completed')}</div>
              </>
            ) : (
              <>
                <CancelCircle style={{ marginRight: 14, color: '#f44336' }} />
                <div style={{ display: 'inline', color: '#f44336', fontWeight: 'bold' }}>{sendSmsStatus}</div>
              </>
            )
          }

        </Box>
      </Modal>
      {/* Modal Confirm send multiple sms */}
      <Modal
        isOpen={openModal}
        onCloseModal={handleCloseModal}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            fontSize: 16
          }}
        >
          <p style={{ color: '#003366' }}>
            {t('confirm_modal.title').replace(/\{:num\}/g, totalCount)}
          </p>
          <p style={{ color: 'rgb(122 122 126)' }}>
            {t('confirm_modal.description').replace(/\{:num\}/g, totalCount)}
          </p>
          <Grid container justify='center' style={{ gap: 20, marginTop: 10 }}>
            <Button
              onClick={handleCloseModal}
              variant='contained'
            >
              Cancel
            </Button>
            <ButtonLoader
              onClick={handleSendMultipleSMS}
              variant='contained'
              disabled={smsLoading}
              primary
              isLoading={smsLoading}
            >
              Confirm
            </ButtonLoader>
          </Grid>
        </Box>
      </Modal>
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={10}>
            <Search />
          </Grid>

          <Grid item xs={3} sm={2}>
            <FilterModal component={SMSRatingsFilterForm} />
          </Grid>
        </Grid>
      </div>

      <div className={classes.metaContainer}>
        <ResultsCountLabel value={totalCount} unit='job' style={{ margin: 0 }} />

        <FilterTags />

        <SMSRatingsTable
          jobs={jobs}
          onSendIndividual={handleSendIndividualSMS}
          onSendMultiple={handleOpenModal}
          smsLoading={smsLoading}
        />
      </div>

      <Grid item xs={12} container justify='center'>
        <Pagination totalPages={totalPages} />
      </Grid>
    </PageLayout>
  )
}

export default SMSRatingIndexPage
