import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'

import { withStyles } from '@material-ui/core/styles'

import Panel from 'components/containers/Panel'
import { AttributesTable, Attribute } from 'components/tables/AttributesTable'

import styles from './QuestionsAndAnswersStyles'

export function QuestionsAndAnswers({ answers, addtionalInformation, classes }) {
  function displayAnswer(obj) {
    const question = obj.get('question') || Map()
    const choice = obj.get('choice') || Map()
    const choices = obj.get('choices') || List()
    const attachments = obj.get('attachments') || List()

    switch (question.get('display_type')) {
      case 'radio':
        return choice.get('value') || obj.get('display_value')
      case 'string':
        return obj.get('value') || obj.get('display_value')
      case 'number':
        return obj.get('value') || obj.get('display_value')
      case 'textarea':
        return obj.get('value') || obj.get('display_value')
      case 'select':
        return choice.get('value') || obj.get('display_value')
      case 'searchable_select':
        return choice.get('value') || obj.get('display_value')
      case 'gallery':
        return (
          <img className={classes.image} src={obj.get('image_url')} alt='answerImage' />
        )
      case 'camera':
        return (
          <img className={classes.image} src={obj.get('image_url')} alt='answerImage' />
        )
      case 'gallery_multiple':
        return attachments.map((attachment, index) => {
          return (
            <img
              key={index}
              className={classes.image}
              src={attachment.get('file_url')}
              alt='answerImage'
            />
          )
        })
      case 'camera_multiple':
        return attachments.map((attachment, index) => {
          return (
            <img
              key={index}
              className={classes.image}
              src={attachment.get('file_url')}
              alt='answerImage'
            />
          )
        })
      case 'checkboxes':
        return choices.size > 0
          ? choices.map((item, index) => {
              return (
                <ul key={index}>
                  <li>{item.get('value')}</li>
                </ul>
              )
            })
          : obj.get('display_value')

      default:
        break
    }
  }

  return (
    <Panel className={classes.root}>
      <AttributesTable>
        {answers.map((answer, index) => {
          const question = answer.get('question') || Map()

          return (
            <Attribute
              key={index}
              label={question.get('name')}
              value={displayAnswer(answer)}
            />
          )
        })}

        {addtionalInformation && (
          <Attribute label='Additional Information' value={addtionalInformation} />
        )}
      </AttributesTable>
    </Panel>
  )
}

QuestionsAndAnswers.propTypes = {
  answers: ImmutablePropTypes.list,
  classes: PropTypes.object,
  addtionalInformation: PropTypes.string
}

QuestionsAndAnswers.defaultProps = {
  answers: List()
}

export default withStyles(styles)(QuestionsAndAnswers)
