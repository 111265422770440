import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Datetime from 'react-datetime'
import { withStyles } from '@material-ui/core/styles'

import Input from 'components/inputs/Input'

import styles from './DateTimePickerStyles'

export function DateTimePicker({ value, onChange, classes, ...rest }) {
  function handleChange(value) {
    if (onChange) {
      onChange(moment(value).toISOString(true))
    }
  }

  return (
    <Datetime
      value={value ? moment(value).format('DD MMM YYYY HH:mm') : ''}
      onChange={handleChange}
      dateFormat='DD MMM YYYY'
      timeFormat='HH:mm'
      renderInput={(props) => <Input {...props} />}
      className={classes.picker}
      {...rest}
    />
  )
}

DateTimePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  classes: PropTypes.object
}

export default withStyles(styles)(DateTimePicker)
