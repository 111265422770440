import React from 'react'
// import PropTypes from 'prop-types'
// import ImmutablePropTypes from 'react-immutable-proptypes'
import Moment from 'moment'

import { List } from 'immutable'
import { DayPickerSingleDateController } from 'react-dates'

import isSameDay from 'react-dates/lib/utils/isSameDay'
import isInclusivelyAfterDay from 'react-dates/lib/utils/isInclusivelyAfterDay'

import './DisabledDates.scss'

export class DisabledDates extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: null,
      focused: false,
      datesList: List()
    }
    this.onDateChange = this.onDateChange.bind(this)
  }

  componentDidMount() {
    this.setState({ datesList: this.props.values.disabled_dates })
  }

  onDateChange(date) {
    this.setState({ date })
    date = Moment(date).format('YYYY-MM-DD')

    var datesList = this.state.datesList || List()

    if (datesList.indexOf(date) !== -1) {
      datesList = datesList.splice(datesList.indexOf(date), 1)
    } else {
      datesList = datesList.push(date)
    }
    this.setState({ datesList })
    this.props.setFieldValue('disabled_dates', datesList.toArray())
  }

  render() {
    const { focused, date } = this.state

    return (
      <DayPickerSingleDateController
        date={date}
        focused={focused}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        onDateChange={this.onDateChange}
        onFocusChange={this.onFocusChange}
        isOutsideRange={(day) => !isInclusivelyAfterDay(day, Moment())}
        isDayHighlighted={(day1) =>
          this.state.datesList.some((day2) => isSameDay(Moment(day1), Moment(day2)))
        }
      />
    )
  }
}

export default DisabledDates
