import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import { EditRounded, Delete } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import { useResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import { regionSchema } from '@seekster/schemas'

import { RegionCategoriesPane, RegionOverviewPane } from './panes'

export function RegionShowPage ({ match, location, t }) {
  const [region, { loading }] = useResource(regionSchema, match.params.id)
  const { delete: deleteRegion } = useResourceAction(regionSchema)
  const push = usePush()

  function handleDestroy () {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      deleteRegion(match.params.id)
        .then(() => push(`/regions`))
    }
  }

  function secondaryActions () {
    let actions = [{
      avatar: <EditRounded />,
      content: t('edit'),
      to: `/regions/${match.params.id}/edit`
    }, {
      avatar: <Delete />,
      content: t('delete'),
      onClick: handleDestroy
    }]

    return actions
  }

  function subNavbar () {
    const path = `/regions/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('navbar.overview')} to={path} />
        <NavigationMenuItem label={t('navbar.categories')} to={`${path}/categories`} />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs () {
    return [{
      text: t('title'),
      link: '/regions'
    }, {
      text: region.get('name')
    }]
  }

  return (
    <PageLayout title={region.get('name')}
      actions={secondaryActions()}
      subNavbar={subNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/regions/:id' component={RegionOverviewPane} exact />
        <AuthenticatedRoute path='/regions/:id/categories' component={RegionCategoriesPane} />
      </Switch>
    </PageLayout>
  )
}

RegionShowPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,

  t: PropTypes.func
}

export default withTranslation(['regions', 'common'])(RegionShowPage)
