import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, destroy } from 'actions/resources'
import { fetchCurrent } from 'actions/authentication'
import sendInvitation from 'actions/invitations'
import { getResource } from 'selectors/resources'
import { getCurrentResource } from 'selectors/authentication'
import { agentSchema, invitationSchema } from '@seekster/schemas'

import withFlashMessage from 'hoc/withFlashMessage'

import AgentShowPage from './AgentShowPage'

const mapStateToProps = (state, { match }) => ({
  agent: match.params.id
    ? getResource(state, match.params.id, agentSchema)
    : getCurrentResource(state, agentSchema)
})

const mapDispatchToProps = {
  fetchAgent: id => fetch(id, agentSchema),
  fetchCurrentAgent: () => fetchCurrent(agentSchema),
  destroyAgent: id => destroy(id, agentSchema),
  sendInvitation: id => sendInvitation(agentSchema, id, invitationSchema),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(withFlashMessage(AgentShowPage))
