import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Grid } from '@material-ui/core'

import useData from 'hooks/useData'
import ConversationMessagesPanel from 'modules/conversations'

import CircularLoader from 'components/CircularLoader'

export function JobConversationPane ({ job, fetchJobConversation, match }) {
  const { loading } = useData(() => fetchJobConversation(match.params.id))

  const id = job.getIn(['conversations', 'id'])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={7}>
        {
          loading ? (
            <CircularLoader />
          ) : (
            <ConversationMessagesPanel
              withoutBackButton
              match={{ ...match, params: { id } }}
            />
          )
        }
      </Grid>
    </Grid>
  )
}

JobConversationPane.propTypes = {
  job: ImmutablePropTypes.map,
  fetchJobConversation: PropTypes.func,
  match: PropTypes.object
}

JobConversationPane.defaultProps = {
  job: Map()
}

export default JobConversationPane
