import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { badgeSchema } from '@seekster/schemas'

import BadgeOverviewPane from './BadgeOverviewPane'

const mapStateToProps = (state, { match }) => ({
  badge: getResource(state, match.params.id, badgeSchema)
})

export default connect(mapStateToProps)(BadgeOverviewPane)
