import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import { EditRounded, Delete } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import { useResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'

import PageLayout from 'components/layouts/PageLayout'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import { departmentSchema } from '@seekster/schemas'

import { DepartmentOverviewPane } from './panes'

export function DepartmentShowPage ({ match, location }) {
  const { t } = useTranslation(['regions', 'common'])
  const [department, { loading }] = useResource(departmentSchema, match.params.id)
  const { delete: deleteDepartment } = useResourceAction(departmentSchema)
  const push = usePush()

  function handleDestroy () {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      deleteDepartment(match.params.id)
        .then(() => push('/departments'))
    }
  }

  function secondaryActions () {
    const actions = [{
      avatar: <EditRounded />,
      content: t('edit'),
      to: `/departments/${match.params.id}/edit`
    }, {
      avatar: <Delete />,
      content: t('delete'),
      onClick: handleDestroy
    }]

    return actions
  }

  function subNavbar () {
    const path = `/departments/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('navbar.overview')} to={path} />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs () {
    return [{
      text: t('title'),
      link: '/departments'
    }, {
      text: department.get('name')
    }]
  }

  return (
    <PageLayout
      title={department.get('name')}
      actions={secondaryActions()}
      subNavbar={subNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/departments/:id' component={DepartmentOverviewPane} exact />
      </Switch>
    </PageLayout>
  )
}

DepartmentShowPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object
}

export default DepartmentShowPage
