import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { Field as FormikField, ErrorMessage, getIn } from 'formik'
import { isUndefined } from 'lodash'
import { useTranslation } from 'react-i18next'

import Input from 'components/inputs/Input'

import './Field.scss'

export function Field({
  name,
  label,
  noLabel,
  required,
  hint,
  placeholder,
  fluid,
  render,
  component: Component,
  initialValue,
  ...rest
}) {
  const { t } = useTranslation(['fields', 'attributes'])
  const labelName = name.split('.').pop()

  function renderInput({ field, form, meta }) {
    if (render) {
      return render({ field, form })
    } else if (Component) {
      return (
        <Component
          {...field}
          value={!isUndefined(field.value) ? field.value : initialValue}
          placeholder={placeholder || t(`placeholder.${name}`, { defaultValue: '' })}
          error={getIn(form.touched, name) && getIn(form.errors, name)}
          onChange={(eventOrValue, data) => {
            if (!isUndefined(eventOrValue) && eventOrValue.nativeEvent) {
              return !isUndefined(data)
                ? form.setFieldValue(name, data.value)
                : field.onChange(eventOrValue)
            } else {
              return form.setFieldValue(name, eventOrValue)
            }
          }}
          onBlur={(eventOrValue, data) => {
            if (!isUndefined(eventOrValue) && eventOrValue.nativeEvent) {
              return !isUndefined(data)
                ? form.setFieldTouched(name, true)
                : field.onBlur(eventOrValue)
            } else {
              return form.setFieldTouched(name, true)
            }
          }}
          required={required}
          {...rest}
        />
      )
    }
  }

  return (
    <FormikField name={name}>
      {(renderProps) => (
        <div className={fluid ? 'from-field-fluid' : 'form-field'}>
          {!noLabel && (
            <label className={required ? 'required' : ''}>
              {label || t([`label.${labelName}`, labelName])}
            </label>
          )}

          {renderInput(renderProps)}

          {hint && <p className='field-hint'>{hint || t([`hint.${name}`, name])}</p>}

          <ErrorMessage
            name={name}
            render={(message) =>
              message ? <Typography color='error'>{message}</Typography> : null
            }
          />
        </div>
      )}
    </FormikField>
  )
}

Field.propTypes = {
  initialValue: PropTypes.node,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fluid: PropTypes.bool,
  required: PropTypes.bool,
  noLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  hint: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  render: PropTypes.func
}

Field.defaultProps = {
  component: Input,
  initialValue: '',
  fluid: false,
  required: false
}

export default Field
