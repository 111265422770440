import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Link } from 'react-router-dom'
import {
  Grid,
  Avatar,
  Typography,
  List as ListMui,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import PageContent, { PageSection } from 'components/layouts/PageContent'
import Panel from 'components/containers/Panel'
import AttributeLabel from 'components/labels/AttributeLabel'
import Tag from 'components/Tag'
import BooleanChip from 'components/BooleanChip'
import HtmlFormatter from 'components/formatters/HtmlFormatter'
import DateFormatter from 'components/formatters/DateFormatter'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'

import styles from './ServiceOverviewPaneStyles'

export function ServiceOverviewPane({ service, classes }) {
  const { t } = useTranslation(['services'])

  return (
    <PageContent>
      <PageSection position='center' borderRight>
        <Panel title={t('service_details')}>
          <ListMui className={classes.listMuiContainer}>
            {service.get('details', List()).map((detail) => (
              <ListItem key={detail.get('id')} disableGutters>
                <ListItemAvatar>
                  <Avatar src={detail.get('icon_url')} alt={detail.get('content')} />
                </ListItemAvatar>

                <ListItemText primary={detail.get('content')} />
              </ListItem>
            ))}
          </ListMui>
        </Panel>

        <Panel title={t('service_scopes')}>
          <ListMui className={classes.listMuiContainer}>
            {service.get('scopes', List()).map((scope) => (
              <ListItem key={scope.get('id')} disableGutters alignItems='flex-start'>
                <ListItemAvatar>
                  <Avatar src={scope.get('icon_url')} alt={scope.get('title')} />
                </ListItemAvatar>

                <ListItemText
                  primary={scope.get('title')}
                  secondary={<HtmlFormatter value={scope.get('body')} />}
                  primaryTypographyProps={{ variant: 'h6', gutterBottom: true }}
                  secondaryTypographyProps={{ variant: 'body1', color: 'textPrimary' }}
                />
              </ListItem>
            ))}
          </ListMui>
        </Panel>

        <Panel title={t('service_pictures')}>
          <div className={classes.listMuiContainer}>
            <Typography variant='subtitle1' gutterBottom>
              {t('banner')} (en)
            </Typography>
            <img
              src={service.get('banner_en_url')}
              alt='banner'
              className={classes.banner}
            />

            <Typography variant='subtitle1' gutterBottom>
              {t('banner')} (th)
            </Typography>
            <img
              src={service.get('banner_th_url')}
              alt='banner'
              className={classes.banner}
            />

            <Typography variant='subtitle1' gutterBottom>
              {t('thumbnail')}
            </Typography>
            <img
              src={service.get('thumbnail_url')}
              alt='thumbnail'
              className={classes.thumbnail}
            />
          </div>
        </Panel>
      </PageSection>

      <PageSection position='right'>
        <Panel title={t('details')}>
          <ListItem disableGutters className={classes.nameSection}>
            <ListItemText
              primary={`${t('id')} ${service.get('id')}`}
              secondary={
                <>
                  <div>
                    {service.get('name')} <Tag value={service.get('code')} />
                  </div>
                  <Typography variant='caption'>
                    {t('slug')}: {service.get('slug')}
                  </Typography>
                </>
              }
              primaryTypographyProps={{ variant: 'caption' }}
              secondaryTypographyProps={{ variant: 'subtitle1', color: 'textPrimary' }}
            />

            <ListItemAvatar>
              <Avatar
                src={service.get('icon_url')}
                alt={service.get('name')}
                className={classes.icon}
              />
            </ListItemAvatar>
          </ListItem>

          <Grid container>
            <Grid item xs={6} className={classes.gridContainer}>
              <AttributeLabel title={t('region')}>
                {service.getIn(['region', 'name'])}
              </AttributeLabel>
            </Grid>

            <Grid item xs={6} className={classes.gridContainer}>
              <AttributeLabel title={t('category')}>
                <ul>
                  {service.get('categories', List()).map((category) => (
                    <li key={category.get('id')}>
                      <Link to={`/${category.get('type')}/${category.get('id')}`}>
                        {category.get('name')}
                      </Link>
                    </li>
                  ))}
                </ul>
              </AttributeLabel>
            </Grid>

            <Grid item xs={6} className={classes.gridContainer}>
              <AttributeLabel title={t('payment_methods')}>
                <ul>
                  {service.get('payment_methods', List()).map((method) => (
                    <li key={method.get('id')}>{method.get('name')}</li>
                  ))}
                </ul>
              </AttributeLabel>
            </Grid>

            <Grid item xs={6} className={classes.gridContainer}>
              <AttributeLabel title={t('disabled_dates')}>
                <ul>
                  {service.get('disabled_dates', List()).map((date) => (
                    <li key={date}>
                      <DateFormatter value={date} simple />
                    </li>
                  ))}
                </ul>
              </AttributeLabel>
            </Grid>

            <Grid item xs={6} className={classes.chip}>
              <BooleanChip name='requestable' value={service.get('requestable')} />
            </Grid>
            <Grid item xs={6} className={classes.chip}>
              <BooleanChip name='registerable' value={service.get('registerable')} />
            </Grid>
            <Grid item xs={6} className={classes.chip}>
              <BooleanChip name='public' value={service.get('public')} />
            </Grid>

            <Grid item xs={6} className={classes.chip}>
              <BooleanChip name='popularity' value={service.get('is_popular')} />
            </Grid>

            <Grid item xs={6} className={classes.gridContainer}>
              <AttributeLabel title={t('updated_at')}>
                <DateTimeFormatter value={service.get('updated_at')} />
              </AttributeLabel>
            </Grid>

            <Grid item xs={6} className={classes.gridContainer}>
              <AttributeLabel title={t('created_at')}>
                <DateTimeFormatter value={service.get('created_at')} />
              </AttributeLabel>
            </Grid>
          </Grid>
        </Panel>
      </PageSection>
    </PageContent>
  )
}

ServiceOverviewPane.propTypes = {
  service: ImmutablePropTypes.map,
  classes: PropTypes.object
}

ServiceOverviewPane.defaultProps = {
  service: Map()
}

export default compose(withStyles(styles))(ServiceOverviewPane)
