import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Grid, Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import { Table, Column } from 'components/tables/Table'
import Tag from 'components/Tag'

export function ServiceQuestionsPane ({ service, t }) {
  return (
    <Grid container spacing={1.5}>
      {
        ['request_questions', 'report_questions'].map(questionsType => (
          <Grid item xs={12} key={questionsType}>
            <Panel title={t(questionsType)}>
              {
                service.get(questionsType) && !service.get(questionsType).isEmpty() ? (
                  <Table collection={service.get(questionsType)} padding='dense'>
                    <Column name='id' />
                    <Column name='name' />
                    <Column name='position' />
                    <Column name='display_type' component={Tag} />
                    <Column
                      name='choices'
                      render={choices => choices && !choices.isEmpty() && (
                        <Table collection={choices} padding='dense'>
                          <Column name='id' style={{ width: 80 }} />
                          <Column name='value' />
                        </Table>
                      )}
                    />
                    <Column name='placeholder' />
                  </Table>
                ) : (
                  <Typography variant='overline' align='center'>{t('no_questions')}</Typography>
                )
              }
            </Panel>
          </Grid>
        ))
      }
    </Grid>
  )
}

ServiceQuestionsPane.propTypes = {
  service: ImmutablePropTypes.map,
  t: PropTypes.func
}

ServiceQuestionsPane.defaultProps = {
  service: Map()
}

export default withTranslation('services')(ServiceQuestionsPane)
