import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, update } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { badgeSchema } from '@seekster/schemas'

import BadgeEditPage from './BadgeEditPage'

const mapStateToProps = (state, { match }) => ({
  badge: getResource(state, match.params.id, badgeSchema)
})

const mapDispatchToProps = {
  fetchBadge: id => fetch(id, badgeSchema),
  updateBadge: (id, data) => update(id, data, badgeSchema),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(BadgeEditPage)
