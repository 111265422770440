import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import initGoogleMapApi from 'utils/initGoogleMapApi'
import './GoogleDynamicMap.scss'

export function GoogleDynamicMap ({ zoom, markers, center, marker, isContent, fullscreenControl }) {
  useEffect(() => {
    if (window.google && window.google.maps) {
      setupGoogleMap()
    }
    else {
      initGoogleMapApi().then(() => {
        setupGoogleMap()
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const mapRef = useRef()
  let popup, Popup

  function setupGoogleMap () {
    const map = new window.google.maps.Map(mapRef.current, {
      center,
      zoom,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: fullscreenControl
    })

    Popup = createPopupClass()

    if (markers) {
      markers.forEach((marker) => {
        if (marker !== null) {
          if (isContent) {
            popup = new Popup(new window.google.maps.LatLng(marker.lat, marker.lng), marker.index, marker)
            popup.setMap(map)
          }
          else {
            const createMarker = new window.google.maps.Marker({
              position: { lat: marker.lat, lng: marker.lng },
              map,
              icon: { url: `https://s3-ap-southeast-1.amazonaws.com/seekster-web/assets/images/marker_${marker.color}.png` }
            })

            createMarker.addListener('click', () => {
              alert(`lat:${createMarker.position.lat()} lng:${createMarker.position.lng()}`)
            })
          }
        }
      })
      if (!center) {
        const bounds = new window.google.maps.LatLngBounds()

        markers.forEach(marker => {
          marker !== null && bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng))
        })
        map.fitBounds(bounds)
      }
    }
    else {
      const createMarker = new window.google.maps.Marker({
        position: { lat: marker.lat, lng: marker.lng },
        map
      })

      createMarker.addListener('click', () => {
        alert(`lat:${createMarker.position.lat()} lng:${createMarker.position.lng()}`)
      })
    }
  }

  function renderPopup (marker, index) {
    return (
      <div className='popup-container' id={`contanier-${index}`}>
        <div className={`popup-bubble-anchor  ${marker.color}`}>
          <div id={marker.index} className={`popup-bubble ${marker.color}`}>
            <div>{marker.text}</div>
          </div>
        </div>
      </div>
    )
  }

  function createPopupClass () {
    function Popup (position, index) {
      this.position = position

      this.containerDiv = document.getElementById(`contanier-${index}`)
      window.google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv)
    }

    Popup.prototype = Object.create(window.google.maps.OverlayView.prototype)

    Popup.prototype.onAdd = function () {
      this.getPanes().floatPane.appendChild(this.containerDiv)
    }

    Popup.prototype.onRemove = function () {
      if (this.containerDiv.parentElement) {
        this.containerDiv.parentElement.removeChild(this.containerDiv)
      }
    }

    Popup.prototype.draw = function () {
      const divPosition = this.getProjection().fromLatLngToDivPixel(this.position)

      const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none'

      if (display === 'block') {
        this.containerDiv.style.left = divPosition.x + 'px'
        this.containerDiv.style.top = divPosition.y + 'px'
      }
      if (this.containerDiv.style.display !== display) {
        this.containerDiv.style.display = display
      }
    }

    return Popup
  }

  return (
    <div className='google-map-container'>
      <div className='google-map' ref={mapRef} id='map' />
      {
        isContent && markers.map((marker) => marker !== null && renderPopup(marker, marker.index))
      }
    </div>
  )
}

GoogleDynamicMap.propTypes = {
  zoom: PropTypes.number,
  isContent: PropTypes.bool,
  center: PropTypes.object,
  markers: PropTypes.object,
  marker: PropTypes.object,
  fullscreenControl: PropTypes.bool
}

GoogleDynamicMap.defaultProps = {
  zoom: 15,
  fullscreenControl: false
}

export default GoogleDynamicMap
