import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import { Field } from 'components/fields/Field'
import ButtonLoader from 'components/buttons/ButtonLoader'

const useStyles = makeStyles(() => ({
  formContainer: {
    padding: 16
  }
})
)

export function DepartmentForm ({ isSubmitting }) {
  const { t } = useTranslation('departments')
  const classes = useStyles()

  return (
    <Grid container justify='center' component={Form}>
      <Grid item xs={12} sm={9}>
        <Panel title={t('details')}>
          <div className={classes.formContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field name='name' />
              </Grid>

              <Grid item xs={12} container justify='flex-end'>
                <ButtonLoader
                  type='submit'
                  variant='contained'
                  isLoading={isSubmitting}
                >
                  {t('save')}
                </ButtonLoader>
              </Grid>
            </Grid>
          </div>
        </Panel>
      </Grid>
    </Grid>
  )
}

DepartmentForm.propTypes = {
  isSubmitting: PropTypes.bool
}

export default DepartmentForm
