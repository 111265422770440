import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'
import { withTranslation } from 'react-i18next'
import { isNil } from 'lodash'

import './PaymentStatusTag.scss'

export class PaymentStatusTag extends React.Component {
  static propTypes = {
    value: PropTypes.bool,
    t: PropTypes.func
  }

  static defaultProps = {
    value: false
  }

  render () {
    const { t } = this.props

    if (isNil(this.props.value)) {
      return null
    }
    else {
      return (
        <span className={classNames(['payment-status-tag', this.props.value.toString()])}>
          {t(`payment_status.${this.props.value}`)}
        </span>
      )
    }
  }
}

export default withTranslation('tags')(PaymentStatusTag)
