import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'
import { jobSchema } from '@seekster/schemas'

import PageLayout from 'components/layouts/PageLayout'
import Panel from 'components/containers/Panel'
import JobForm from 'forms/resources/JobForm'
import TimePickerToggleButton from 'components/buttons/TimePickerToggleButton'

export function JobEditPage({ match }) {
  const { t } = useTranslation(['jobs', 'attributes'])
  const [job, { loading }] = useResource(jobSchema, match.params.id)
  const { update: updateJob } = useResourceAction(jobSchema)
  const push = usePush()
  const slotEnabled = job.getIn(['service', 'slot_enabled'])

  function handleSubmit(data) {
    return updateJob(match.params.id, data).then(({ body }) => {
      push(`/jobs/${body.id}`)
    })
  }

  return (
    <PageLayout title={t('edit')} isLoading={loading} back={`/jobs/${match.params.id}`}>
      <Grid container justify='center'>
        <Grid item xs={12} sm={9}>
          <Panel
            title={t('start_time')}
            actions={slotEnabled ? null : <TimePickerToggleButton />}
          >
            <JobForm job={job} push={push} onSubmit={handleSubmit} />
          </Panel>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

JobEditPage.propTypes = {
  match: PropTypes.object
}

JobEditPage.defaultProps = {
  job: Map()
}

export default JobEditPage
