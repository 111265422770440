import React from 'react'
import { connect } from 'react-redux'

import { setFlashMessage } from 'actions/interface'

const withFlashMessage = WrappedComponent => {
  function ComponentWithFlashMessage (props) {
    return <WrappedComponent {...props} />
  }

  return connect(null, mapDispatchToProps)(ComponentWithFlashMessage)
}

const mapDispatchToProps = {
  setFlashMessage
}

export default withFlashMessage
