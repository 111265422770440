import { List } from 'immutable'
import { withFormik } from 'formik'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'

import PayrollSettingsForm from './PayrollSettingsForm'

const formikConfig = {
  mapPropsToValues: ({ payrollSetting = Map() }) => ({
    fee_field: payrollSetting.get('fee_field') || '',
    remark_field: payrollSetting.get('remark_field') || '',
    payout_cycles_attributes: payrollSetting.get('payout_cycles', List()).toJS() || ''
  }),

  enableReinitialize: true,

  validationSchema: ({ t }) => {
    return Yup.object().shape({
      payout_cycles_attributes: Yup.array().of(
        Yup.object().shape({
          cycle_end_day: Yup.number()
            .typeError(t('error.number'))
            .min(1, t('error.min')).max(28, t('error.max'))
            .required(t('error.required')),
          cycle_start_day: Yup.number()
            .typeError(t('error.number'))
            .min(1, t('error.min')).max(28, t('error.max'))
            .required(t('error.required'))
        })
      )
    })
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true)

    props.onSubmit(values)
      .then(() => {
        props.push('/payrolls')
        setSubmitting(false)
      })
  }
}

export default compose(
  withTranslation('payrolls'),
  withFormik(formikConfig))(PayrollSettingsForm)
