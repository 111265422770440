import React from 'react'
import { Switch } from 'react-router-dom'

import withFlashMessage from 'hoc/withFlashMessage'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import { PayrollOverviewPane, PayrollStatementPane } from './panes'

export function PayrollShowPage () {
  return (
    <Switch>
      <AuthenticatedRoute path='/payrolls/:id' component={PayrollOverviewPane} exact />
      <AuthenticatedRoute path='/payrolls/:id/statement/:statement_id' component={PayrollStatementPane} />
    </Switch>
  )
}

export default withFlashMessage(PayrollShowPage)
