import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Select, InputBase, MenuItem } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import AnnouncementForm from 'forms/resources/AnnouncementForm'
import withLocalizedFields from 'hoc/withLocalizedFields'

export class AnnouncementNewPage extends React.Component {
  static propTypes = {
    createAnnouncement: PropTypes.func,
    push: PropTypes.func,
    locale: PropTypes.string,
    setLocale: PropTypes.func,

    t: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (data) {
    return this.props.createAnnouncement(data)
  }

  actions () {
    return [
      <Select
        key='1'
        value={this.props.locale}
        onChange={(e) => this.props.setLocale(e.target.value)}
        input={<InputBase />}
      >
        <MenuItem value='en'><span role='img' aria-label='GB flag'>🇬🇧 </span>English</MenuItem>
        <MenuItem value='th'><span role='img' aria-label='Thai flag'>🇹🇭 </span>ภาษาไทย</MenuItem>
      </Select>
    ]
  }

  render () {
    return (
      <PageLayout
        title={this.props.t('new')}
        secondaryActions={this.actions()}
        back='/announcements'
      >
        <AnnouncementForm
          push={this.props.push}
          onSubmit={this.handleSubmit}
        />
      </PageLayout>
    )
  }
}

export default compose(
  withLocalizedFields,
  withTranslation('announcements')
)(AnnouncementNewPage)
