import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { EditRounded, Delete } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import { ProviderIssueOverviewPane } from './panes'

export function ProviderIssueShowPage ({
  issue,
  fetchIssue,
  deleteIssue,
  setFlashMessage,
  push,
  match,
  location,
  t
}) {
  const { loading } = useData(() => fetchIssue(match.params.id))

  function handleDeleteProviderIssue () {
    if (window.confirm(`${t('delete_confirmation_prompt')}`)) {
      deleteIssue(match.params.id)
        .then(push(`/provider_issues`))
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function secondaryActions () {
    return [{
      avatar: <EditRounded />,
      content: t('edit'),
      to: `/provider_issues/${match.params.id}/edit`
    }, {
      avatar: <Delete />,
      content: t('delete'),
      onClick: handleDeleteProviderIssue
    }]
  }

  function renderSubNavbar () {
    const path = `/provider_issues/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('panes.overview')} to={path} />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs () {
    return [
      { text: t('title'), link: '/provider_issues' },
      { text: issue.get('name') }
    ]
  }

  return (
    <PageLayout
      title={issue.get('name')}
      actions={secondaryActions()}
      subNavbar={renderSubNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/provider_issues/:id' component={ProviderIssueOverviewPane} exact />
      </Switch>
    </PageLayout>
  )
}

ProviderIssueShowPage.propTypes = {
  issue: ImmutablePropTypes.map,
  fetchIssue: PropTypes.func,
  deleteIssue: PropTypes.func,
  setFlashMessage: PropTypes.func,
  push: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,

  t: PropTypes.func
}

ProviderIssueShowPage.defaultProps = {
  issue: Map()
}

export default withTranslation('issues')(ProviderIssueShowPage)
