import request from 'utils/request'

import {
  TRIGGER_RESOURCE_EVENT_SUCCEEDED,
  TRIGGER_CHILD_RESOURCE_EVENT_SUCCEEDED
} from 'constants/resources'

const triggerSucceeded = (response, schema, options) => ({
  type: TRIGGER_RESOURCE_EVENT_SUCCEEDED,
  response,
  schema,
  options
})

const triggerChildSucceeded = (id, response, schema, childSchema, options) => ({
  type: TRIGGER_CHILD_RESOURCE_EVENT_SUCCEEDED,
  id,
  response,
  schema,
  childSchema,
  options
})

export const trigger = (id, schema, event, options = {}) => (dispatch, getState) => {
  const type = options.type || schema._key
  const locale = options.locale
  const accessToken = getState().getIn(['authentication', 'token'])

  return request
    .put(`/${type}/${id}/${event}`)
    .locale(locale)
    .authentication(accessToken)
    .then(response => {
      dispatch(triggerSucceeded(response, schema, options))
      return response
    })
}

export const triggerChild = (parentSchema, parentId, childSchema, childId, event, options = {}) => (dispatch, getState) => {
  const type = options.type || parentSchema._key
  const childType = options.childType || childSchema._key.split('/').pop()
  const locale = options.locale
  const accessToken = getState().getIn(['authentication', 'token'])

  return request
    .put(`/${type}/${parentId}/${childType}/${childId}/${event}`)
    .locale(locale)
    .authentication(accessToken)
    .then(response => {
      dispatch(triggerChildSucceeded(parentId, response, parentSchema, childSchema, options))
      return response
    })
}

export default trigger
