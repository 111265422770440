import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Map } from 'immutable'

import { getResource } from 'selectors/resources'
import { firebaseSupportPlatform } from 'utils/firebase'
import { create, update, fetchResourceSucceeded } from 'actions/firebase/resources'

const db = firebaseSupportPlatform.firestore()

export const useResource = (path, id, schema) => {
  const dispatch = useDispatch()
  const resource = useSelector(state => getResource(state, id, schema)) || Map()
  const [loading, setLoading] = useState(resource.isEmpty())

  useEffect(() => {
    return db.collection(path)
      .doc(id)
      .onSnapshot(snapshot => {
        dispatch(fetchResourceSucceeded(snapshot, schema))
        setLoading(false)
      })
  }, [path, dispatch, schema, id])

  return [resource, { loading }]
}

export const useResourceRef = (ref, schema) => {
  const dispatch = useDispatch()
  const resource = useSelector(state => getResource(state, ref.id, schema)) || Map()
  const [loading, setLoading] = useState(resource.isEmpty())

  useEffect(() => {
    const unsubscribe = ref && ref
      .onSnapshot(snapshot => {
        dispatch(fetchResourceSucceeded(snapshot, schema))
        setLoading(false)
      })

    return unsubscribe
  }, [ref, dispatch, schema])

  return [resource, { loading }]
}

export const useResourceAction = (schema) => {
  let [loading, setLoading] = useState(false)
  let [error, setError] = useState()

  const dispatch = useDispatch()

  const actionResource = (thunkAction) => {
    const action = (...params) => {
      setLoading(true)

      return dispatch(thunkAction(...params, schema, {}))
        .then(response => {
          setLoading(false)

          return response
        })
        .catch(error => {
          setLoading(false)
          setError(error)

          throw error
        })
    }

    return [action, loading, error]
  }

  const [createResource, creating, errorFromCreating] = actionResource(create)
  const [updateResource, updating, errorFromUpdating] = actionResource(update)

  return {
    create: createResource,
    update: updateResource,

    creating,
    updating,

    errorFromCreating,
    errorFromUpdating
  }
}
