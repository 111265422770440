import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { create } from 'actions/resources'
import { regionSchema } from '@seekster/schemas'

import RegionNewPage from './RegionNewPage'

const mapDispatchToProps = {
  createRegion: (data, options) => create(data, regionSchema, options),
  push
}

export default connect(null, mapDispatchToProps)(RegionNewPage)
