import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import MomentPropTypes from 'react-moment-proptypes'
import { DayPickerSingleDateController, CalendarDay } from 'react-dates'

import './ContinuousDatesPicker.scss'

export function ContinuousDatesPicker ({ startDate, weekdays, onChange, disabledPastDate, readOnly, ...rest }) {
  function handleDateChange (targetDate) {
    onChange && onChange(targetDate)
  }

  function isDateDisabled (day) {
    let result = false

    if (disabledPastDate) {
      result = day.isBefore(moment().startOf('day')) || !weekdays.includes(moment(day).weekday().toString())
    }
    else {
      result = !weekdays.includes(moment(day).weekday())
    }

    return result
  }

  function isDateSelected (day) {
    let result = false

    if (startDate && day) {
      result = day.isSameOrAfter(startDate, 'day') && weekdays.includes(moment(day).weekday().toString())
    }

    return result
  }

  return (
    <div className='continuous-dates-picker'>
      <DayPickerSingleDateController
        hideKeyboardShortcutsPanel
        numberOfMonths={1}
        isOutsideRange={isDateDisabled}
        onDateChange={!readOnly && handleDateChange}
        renderCalendarDay={calendarProps => {
          const { day, modifiers } = calendarProps

          return (
            <CalendarDay
              {...calendarProps}
              modifiers={isDateSelected(day) && modifiers ? new Set([...modifiers, 'selected']) : modifiers}
            />
          )
        }}
        {...rest}
      />
    </div>
  )
}

ContinuousDatesPicker.propTypes = {
  startDate: MomentPropTypes.momentObj,
  weekdays: PropTypes.array,
  onChange: PropTypes.func,
  disabledPastDate: PropTypes.bool,
  readOnly: PropTypes.bool
}

ContinuousDatesPicker.defaultProps = {
  weekdays: []
}

export default ContinuousDatesPicker
