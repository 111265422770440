import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import LazyLoad from 'react-lazyload'
import { List } from 'immutable'
import { Link } from 'react-router-dom'
import { Table, Column } from 'components/tables/Table'
import { Paper } from '@material-ui/core'

import { DateTag, StateTag } from 'components/tags'

export class ProvidersTable extends React.Component {
  static propTypes = {
    providers: ImmutablePropTypes.list
  }

  static defaultProps = {
    providers: List()
  }

  handleSort (field) {

  }

  render () {
    const { providers } = this.props

    return (
      <Paper>
        <Table collection={providers}>
          <Column
            name='name'
            render={(name, provider) => (
              <>
                <Link to={`/providers/${provider.get('id')}`}>
                  <LazyLoad height={25}>
                    <img src={provider.get('avatar_url')} alt='' style={{ marginRight: '10px', height: '25px' }} />
                  </LazyLoad>
                </Link>

                <Link to={`/providers/${provider.get('id')}`}>
                  {provider.get('display_name')}
                </Link>

                {
                  provider.get('alias') ? (
                    <Link to={`/providers/${provider.get('id')}`}>
                      ({provider.get('alias')})
                    </Link>
                  ) : null
                }
              </>
            )}
          />
          <Column name='email' />
          <Column name='phone_number' />
          <Column
            name='badges'
            render={badges => (
              <ul>
                {badges && badges.map((badge, index) => {
                  return (
                    <li key={index}>
                      {badge.get('name')}
                    </li>
                  )
                })}
              </ul>
            )}
          />
          <Column
            name='tags'
            render={tags => (
              <ul>
                {tags && tags.map((tag, index) => {
                  return (
                    <li key={index}>
                      {tag.get('name')}
                    </li>
                  )
                })}
              </ul>
            )}
          />
          <Column name='rating' />
          <Column name='state' component={StateTag} />
          <Column name='created_at' component={DateTag} />
        </Table>
      </Paper>
    )
  }
}

export default ProvidersTable
