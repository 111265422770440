import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import { Grid } from '@material-ui/core'

import EventTimeline from 'modules/events/EventTimeline'

export function CustomerTimelinePane ({
  customer,
  match,
  fetchCustomerEvent
}) {
  useEffect(() => {
    fetchCustomerEvent(match.params.id)
  }, [match.params.id, fetchCustomerEvent])

  const events = customer.get('events') || List()

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <EventTimeline events={events} />
      </Grid>
    </Grid>
  )
}

CustomerTimelinePane.propTypes = {
  customer: ImmutablePropTypes.map,
  match: PropTypes.object,

  fetchCustomerEvent: PropTypes.func
}

CustomerTimelinePane.defaultProps = {
  customer: Map()
}

export default CustomerTimelinePane
