import { connect } from 'react-redux'
import { compose } from 'redux'
import { push } from 'react-router-redux'

import { withQueryParser } from 'utils/withQueryParser'
import { fetchChildren } from 'actions/collections'
import { create } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { providerSchema, jobSchema, inquirySchema, potentialProviderSchema } from '@seekster/schemas'

import AssignProviderReportPanel from './AssignProviderReportPanel'

const mapStateToProps = (state, { match }) => ({
  provider: getResource(state, match.params.provider_id, providerSchema),
  potentialProvider: getResource(state, match.params.provider_id, potentialProviderSchema),
  inquiry: getResource(state, match.params.id, inquirySchema)
})

const mapDispatchToProps = {
  fetchProviderJobs: (id, options) => fetchChildren(providerSchema, id, jobSchema, options),
  assignProvider: (id, data, options) => create(data, inquirySchema, { type: `inquiries/${id}/assign` }),
  push
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(AssignProviderReportPanel)
