import { Map, List } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'

import RegionForm from './RegionForm'

const formikConfig = {
  mapPropsToValues: ({ region = Map() }) => ({
    name_en: region.get('name_en') || '',
    name_th: region.get('name_th') || '',
    slug: region.get('slug') || '',
    areas_attributes: region.get('areas', List()).map(area => ({
      id: area.get('id') || undefined,
      name_en: area.get('name_en') || '',
      name_th: area.get('name_th') || '',
      latitude: area.get('latitude') || '',
      longitude: area.get('longitude') || '',
      radius: area.get('radius') || '',
      geometry_json: JSON.stringify(area.get('geometry_json').toJS()) || ''
    })).toJS() || []
  }),

  validationSchema: Yup.object().shape({
    name_en: Yup.string(),
    name_th: Yup.string()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    const areas_attributes = values.areas_attributes
    // alert(JSON.stringify(values, null, 4))
    // setSubmitting(false)

    Object.values(areas_attributes).forEach((area, index) => {
      JSON.parse(area.geometry_json).features.forEach(feature => {
        if (feature.geometry.type === 'Polygon') {
          const temp = JSON.parse(areas_attributes[0].geometry_json)
          const geometry = { type: 'MultiPolygon', coordinates: [temp.features[0].geometry.coordinates] }
          const features = [{ ...feature, geometry }]

          areas_attributes[index]['geometry_json'] = JSON.stringify({ ...temp, features })
        }
      })
    })

    props.onSubmit(values)
      .then(response => {
        props.push(`/regions/${response.body.id}`)
      })
      .catch(error => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(RegionForm)
