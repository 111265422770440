import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Paper,
  Grid,
  Avatar,
  ListItemText
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import useQuery from 'hooks/useQuery'
import { useCollection } from 'hooks/collections'
import { trigger } from 'actions/resourceEvent'

import CircularLoader from 'components/CircularLoader'
import { ResponsiveMenuItem } from 'components/menus/ResponsiveMenu'
import Pagination from 'components/Pagination'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import PageLayout from 'components/layouts/PageLayout'
import { notificationRecipientSchema } from '@seekster/schemas'

const useStyles = makeStyles(({ spacing }) => ({
  metaContainer: {
    padding: spacing(2)
  },

  item: {
    minHeight: 70,
    padding: `${spacing(0.5)}px ${spacing(2)}px`,
    borderBottom: '1px solid #dddfe2'
  }
}))

export function NotificationIndexPage () {
  const classes = useStyles()
  const { t } = useTranslation('notification')
  const [query] = useQuery()
  const dispatch = useDispatch()

  function markNotificationRead (id) {
    dispatch(trigger(id, notificationRecipientSchema, 'read'))
  }

  const [notificationRecipients, {
    loading, totalCount, totalPages
  }] = useCollection(notificationRecipientSchema, { page: query.page, query }, [query])

  return (
    <PageLayout title={t('title')}>
      {
        loading ? (
          <CircularLoader />
        ) : (
          <>
            <div className={classes.metaContainer}>
              <ResultsCountLabel value={totalCount} unit='notification' style={{ margin: 0 }} />
            </div>

            <Paper square>
              {
                notificationRecipients.map(notificationRecipient =>
                  <ResponsiveMenuItem
                    key={notificationRecipient.get('id')}
                    component={Link}
                    to={notificationRecipient.getIn(['notification', 'url'])}
                    avatar={<Avatar src={notificationRecipient.getIn(['notification', 'actor', 'avatar_url'])} />}
                    content={
                      <ListItemText
                        primary={t(notificationRecipient.getIn(['notification', 'template', 'name']))}
                        primaryTypographyProps={{
                          style: {
                            fontWeight: notificationRecipient.get('read') ? 'normal' : 'bold'
                          }
                        }}
                        secondary={moment(notificationRecipient.getIn(['notification', 'created_at'])).fromNow()}
                      />
                    }
                    className={classes.item}
                    style={notificationRecipient.get('read') ? {} : { backgroundColor: '#edf2fa' }}
                    onClick={() => markNotificationRead(notificationRecipient.get('id'))}
                  />
                )
              }
            </Paper>
            <Grid item xs={12} container justify='center'>
              <Pagination totalPages={totalPages} />
            </Grid>
          </>
        )
      }
    </PageLayout>
  )
}

export default NotificationIndexPage
