import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import Panel from 'components/containers/Panel'
import { Table, Column } from 'components/tables/Table'
import { hourFormat } from 'utils/dateFormat'

export function ServiceSlotTimePane({ service, t }) {
  return (
    <Panel title={t('general_slot')}>
      <Table collection={service.get('slots')} padding='dense'>
        <Column name='name' columnName={t('slots.name')} />
        <Column
          columnName={t('slots.time')}
          render={(_, resource) =>
            `${hourFormat(resource.get('start_time'))} - ${hourFormat(
              resource.get('end_time')
            )}`
          }
        />
        <Column name='quantity' />
      </Table>
    </Panel>
  )
}

ServiceSlotTimePane.propTypes = {
  service: ImmutablePropTypes.map,
  t: PropTypes.func
}

ServiceSlotTimePane.defaultProps = {
  service: Map()
}

export default ServiceSlotTimePane
