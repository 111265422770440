import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { Grid, IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import CircularLoader from 'components/CircularLoader'
import { Table, Column } from 'components/tables/Table'
import VisibilityToggledContent from 'components/VisibilityToggledContent'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import Pagination from 'components/Pagination'

import styles from './UserLoginMethodsStyles'

export function UserLoginMethods ({
  schema, id, fetchOmniauthIdentities, destroyOmniauthIdentity,
  getOmniauthIdentities, classes, t
}) {
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [currentPages, setCurrentPage] = useState(1)

  useEffect(() => {
    handleUpdateData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function handleUpdateData () {
    setLoading(true)

    fetchOmniauthIdentities(schema, id)
      .then(({ headers }) => {
        setTotalPages(Math.ceil(headers['total'] / headers['per-page']))

        setLoading(false)
      })
  }

  function handlePageChange (page) {
    setLoading(true)

    setCurrentPage(page)

    fetchOmniauthIdentities(schema, id, { page })
      .then(() => setLoading(false))
  }

  function deleteOmniauthIdentity (id) {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      destroyOmniauthIdentity(id)
        .then(() => handleUpdateData())
    }
  }

  return (
    loading ? (
      <CircularLoader />
    ) : (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Table collection={getOmniauthIdentities(schema, id) || List()} padding='dense'>
            <Column name='provider' />
            <Column
              name='uid'
              render={value => <VisibilityToggledContent value={value} maxWidth={150} />}
            />
            <Column
              name='link'
              render={value => <a href={value} target='_blank' rel='noopener noreferrer'>{value}</a>}
              style={{ maxWidth: 150, overflowWrap: 'break-word' }}
            />
            <Column name='created_at' component={DateTimeFormatter} />
            <Column
              name='token'
              render={value => <VisibilityToggledContent value={value} maxWidth={200} />}
            />
            <Column
              name='id'
              columnName=' '
              render={id => (
                <IconButton onClick={() => deleteOmniauthIdentity(id)} className={classes.deleteButton}>
                  <Delete fontSize='small' />
                </IconButton>
              )}
            />
          </Table>
        </Grid>

        <Grid item xs={12} container justify='center'>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPages}
            onPageChange={handlePageChange}
          />
        </Grid>
      </Grid>

    )
  )
}

UserLoginMethods.propTypes = {
  schema: PropTypes.object,
  id: PropTypes.number,
  fetchOmniauthIdentities: PropTypes.func,
  destroyOmniauthIdentity: PropTypes.func,
  getOmniauthIdentities: PropTypes.func,
  classes: PropTypes.object,

  t: PropTypes.func
}

export default compose(
  withStyles(styles),
  withTranslation()
)(UserLoginMethods)
