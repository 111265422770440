import { SET_OPEN_MODAL, SET_CLOSE_MODAL } from '../constants/modal'

export function setOpenModal({ name, message, data, onConfirm }) {
  return {
    type: SET_OPEN_MODAL,
    payload: {
      name,
      message,
      data,
      onConfirm,
      open: true
    }
  }
}

export function setCloseModal() {
  return {
    type: SET_CLOSE_MODAL
  }
}
