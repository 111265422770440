import { Map } from 'immutable'
import { withFormik } from 'formik'

import * as Yup from 'yup'

import SLASettingForm from './SLASettingForm'

const formikConfig = {
  mapPropsToValues: ({ slaSetting = Map() }) => ({
    pending_start_time: slaSetting.get('pending_start_time'),
    pending_completion_time: slaSetting.get('pending_completion_time')
  }),
  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    pending_start_time: Yup.number()
      .min(0, '*Must be greater than or equal to 0')
      .integer('*Must be integer')
      .required('*Required'),
    pending_completion_time: Yup.number()
      .min(0, '*Must be greater than or equal to 0')
      .integer('*Must be integer')
      .required('*Required')
  }),
  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values).catch((error) => {
      setSubmitting(false)
      setErrors({ message: error.response.body.message })
    })
  }
}

export default withFormik(formikConfig)(SLASettingForm)
