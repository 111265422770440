import { withFormik } from 'formik'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'
import UserTagForm from './UserTagForm'

const formikConfig = {
  mapPropsToValues: () => ({
  }),
  enableReinitialize: true,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(props.userId, values)
      .then(() => {
        setSubmitting(false)
        props.onCancel()
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(UserTagForm)
