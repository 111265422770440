import { fromJS, List } from 'immutable'
import { toPath } from 'lodash'

export default (values, key, positionKey = 'position') => {
  const immutableValues = fromJS(values)
  const path = toPath(key)

  return immutableValues.updateIn(path, (collection = List()) => {
    return collection.map((item, index) => item.merge({ [positionKey]: index + 1 }))
  }).toJS()
}
