export default ({ spacing }) => ({
  card: {
    display: 'flex',
    borderRadius: 0
  },
  avatar: {
    width: 60,
    height: 60
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto',

    '&:last-child': {
      paddingBottom: 16
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center'
  }
})
