import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { create } from 'actions/resources'
import { clientSchema } from '@seekster/schemas'

import ClientNewPage from './ClientNewPage'

const mapDispatchToProps = {
  createClient: (data, options) => create(data, clientSchema, options),
  push
}

export default connect(null, mapDispatchToProps)(ClientNewPage)
