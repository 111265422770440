import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import Tag from 'components/Tag'

export function PromoCodeOverviewPane ({ promoCode, t }) {
  return (
    <Grid container>
      <Grid item xs={12} sm={6} style={{ paddingRight: 0 }}>
        <Panel title={t('details.promo_code')}>
          <AttributesTable resource={promoCode}>
            <Attribute name='id' />
            <Attribute name='code' />
            <Attribute name='amount_off.display_value' label={t('amount_off')} />
            <Attribute
              name='percent_off'
              render={percent => percent && `${percent} %`}
            />
            <Attribute name='usage_count' />
            <Attribute name='created_at' component={DateTimeFormatter} />
            <Attribute name='updated_at' component={DateTimeFormatter} />
          </AttributesTable>
        </Panel>
      </Grid>

      <Grid item xs={12} sm={6} style={{ paddingLeft: 0 }}>
        <Panel title={t('details.limitation')}>
          <AttributesTable resource={promoCode}>
            <Attribute
              name='services'
              render={(services = List()) => (
                <ul>
                  {
                    services.map(service => {
                      const region = service.get('regional_slug') && service.get('regional_slug').split('-').shift()

                      return (
                        <li key={service.get('id')}>
                          {service.get('name')} <Tag value={region} />
                        </li>
                      )
                    })
                  }
                </ul>
              )}
            />
            <Attribute
              name='packages'
              render={(packages = List()) => (
                <ul>
                  {
                    packages.map(pkg => {
                      return <li key={pkg.get('id')}>{pkg.get('name')}</li>
                    })
                  }
                </ul>
              )}
            />
            <Attribute name='start_date' component={DateTimeFormatter} />
            <Attribute name='expiration_date' component={DateTimeFormatter} />
            <Attribute name='minimum_price.display_value' label={t('minimum_price')} />
            <Attribute name='quantity' />
            <Attribute name='limit_per_user' />
            <Attribute name='first_time' />
          </AttributesTable>
        </Panel>
      </Grid>
    </Grid>
  )
}

PromoCodeOverviewPane.propTypes = {
  promoCode: ImmutablePropTypes.map,
  t: PropTypes.func
}

PromoCodeOverviewPane.defaultProps = {
  promoCode: Map()
}

export default withTranslation('promo_codes')(PromoCodeOverviewPane)
