import { withFormik } from 'formik'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'
import PayoutsFilterForm from './PayoutsFilterForm'

const formikConfig = {
  mapPropsToValues: ({ query }) => query,

  enableReinitialize: true,

  handleSubmit: (values, { props }) => {
    props.onQueryPush(values)
  }
}

export default compose(
  withQueryParser,
  withFormik(formikConfig)
)(PayoutsFilterForm)
