import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles'

export function PageContent ({ children }) {
  const classes = useStyles({
    sections: React.Children.map(children, child => child.props.position)
  })

  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}

PageContent.propTypes = {
  children: PropTypes.node
}

export { default as PageSection } from './PageSection'

export default PageContent
