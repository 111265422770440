import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { push as reduxPush } from 'connected-react-router'
import { getResource } from 'selectors/resources'
import { Map } from 'immutable'
import { fetchResourceSucceeded } from 'actions/firebase/resources'

export const usePush = () => {
  const dispatch = useDispatch()

  return (path) => dispatch(reduxPush(path))
}

export const usePrevious = value => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useReference = (ref, schema) => {
  const dispatch = useDispatch()
  const resource = useSelector(state => getResource(state, ref && ref.id, schema)) || Map()
  const [loading, setLoading] = useState(resource.isEmpty())

  useEffect(() => {
    const unsubscribe = ref && ref
      .onSnapshot(queryDocSnapshot => {
        dispatch(fetchResourceSucceeded(queryDocSnapshot, schema))
        setLoading(false)
      })

    return unsubscribe
  }, [dispatch, ref, schema])

  return [resource, { loading }]
}
