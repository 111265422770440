import { Map, List } from 'immutable'
import { withFormik } from 'formik'

import TenantProviderConfigForm from './TenantProviderConfigForm'

const formikConfig = {
  mapPropsToValues: ({ providerConfig = Map() }) => ({
    registration_completion_description_en: providerConfig.get('registration_completion_description_en', ''),
    registration_completion_description_th: providerConfig.get('registration_completion_description_th', ''),
    bank_ids: providerConfig.get('banks', List()).map(bank => bank.get('id')).toJS()
  }),

  enableReinitialize: true,

  // validationSchema: Yup.object().shape({
  //   holding_duration: Yup.number().min(0).required(),
  //   tax_rate: Yup.number().min(0).required(),
  //   withholding_tax_rate: Yup.number().min(0).required(),
  //   default_currency: Yup.string().required(),
  //   tax_number: Yup.string(),
  //   billing_email: Yup.string().email().required(),
  //   active: Yup.boolean().required()
  // }),

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values)
      .catch(error => {
        setSubmitting(false)

        setErrors({ message: error.response.body.message })
      })
  }
}

export default withFormik(formikConfig)(TenantProviderConfigForm)
