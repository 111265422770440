import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import { Button, Divider, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Field from 'components/fields/Field'

import styles from './WalletPaymentFormStyles'

export function WalletPaymentForm ({
  invoice,
  onCancel,
  classes
}) {
  const { t } = useTranslation(['payments', 'actions'])
  const insufficientBalance = invoice.getIn(['customer', 'available_balance', 'fractional']) < invoice.getIn(['outstanding_balance', 'fractional'])

  return (
    <>
      <Typography color='textSecondary'>
        {t('wallet.available_balance')}
      </Typography>

      <Typography
        variant='h5'
        className={insufficientBalance ? classes.insufficientBalance : classes.sufficientBalance}
      >
        {invoice.getIn(['customer', 'available_balance', 'display_value'])}
      </Typography>

      {
        insufficientBalance && (
          <Typography color='error'>
            {t('wallet.insufficient_balance')}
          </Typography>
        )
      }

      <Divider className={classes.divider} />

      <Form>
        <Field
          name='amount_satangs'
          hint={`${t('outstanding_balance')}: ${invoice.getIn(['outstanding_balance', 'display_value'])}`}
        />

        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
        >
          {t('save')}
        </Button>
        <Button
          variant='contained'
          onClick={onCancel}
          className={classes.button}
        >
          {t('cancel')}
        </Button>
      </Form>
    </>
  )
}

WalletPaymentForm.propTypes = {
  invoice: ImmutablePropTypes.map,
  onCancel: PropTypes.func,
  classes: PropTypes.object
}

WalletPaymentForm.defaultProps = {
  invoice: Map()
}

export default withStyles(styles)(WalletPaymentForm)
