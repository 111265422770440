import React from 'react'
import { Map, fromJS } from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Table, TableBody } from '@material-ui/core'

import ResourceContext from './ResourceContext'

export class AttributesTable extends React.Component {
  static propTypes = {
    resource: PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.object]),
    children: PropTypes.node
  }

  static defaultProps = {
    resource: Map()
  }

  render() {
    const { resource, children, ...rest } = this.props

    return (
      <ResourceContext.Provider value={fromJS(resource)}>
        <Table {...rest}>
          <TableBody>{children}</TableBody>
        </Table>
      </ResourceContext.Provider>
    )
  }
}

export default AttributesTable
