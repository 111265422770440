import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { Link } from 'react-router-dom'
import {
  List as MuiList, ListItem, ListItemAvatar, ListItemText,
  Avatar, IconButton, Typography, withMobileDialog
} from '@material-ui/core'
import { ArrowBackIosRounded } from '@material-ui/icons'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import Panel from 'components/containers/Panel'
import CircularLoader from 'components/CircularLoader'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'

import styles from './ConversationMessagesPanelStyles'

export function ConversationMessagesPanel ({
  messages,
  fetchConversationMessages,
  currentAgentId,
  match,
  withoutBackButton,
  classes,
  fullScreen: isMobile,
  t
}) {
  const { loading, error } = useData(() => (
    fetchConversationMessages(match.params.id)
  ), [match.params.id])

  const [resource, resourceId] = match.url.slice(1).split('/')

  function renderContent () {
    if (error) {
      return (
        <Typography variant='overline' align='center' className={classes.placeholderText}>
          {error.message}
        </Typography>
      )
    }
    else if (messages.isEmpty()) {
      return (
        <Typography variant='overline' align='center' className={classes.placeholderText}>
          {t('no_message')}
        </Typography>
      )
    }
    else {
      return (
        messages.reverse().map(message => {
          const isSelf = message.getIn(['sender', 'id']) === currentAgentId

          return (
            <ListItem
              key={message.get('id')}
              alignItems='flex-start'
              className={isSelf && classes.messageSelf}
            >
              {
                !isSelf && (
                  <ListItemAvatar className={classes.avatarContainer}>
                    <Avatar
                      component={Link}
                      to={`/${message.getIn(['sender', 'type'])}/${message.getIn(['sender', 'id'])}`}
                      src={message.getIn(['sender', 'avatar_url'])}
                      alt=''
                      className={classes.avatar}
                    />
                  </ListItemAvatar>
                )
              }

              <ListItemText
                classes={isSelf ? {
                  root: classes.messageContentRootSelf,
                  primary: classes.messageContentSelf
                } : {
                  root: classes.messageContentRoot,
                  primary: classes.messageContent
                }}
                primary={message.get('content')}
                secondary={
                  <>
                    {
                      !isSelf && (
                        <span>{message.getIn(['sender', 'display_name'])} - </span>
                      )
                    }
                    <DateTimeFormatter value={message.get('created_at')} simple />
                  </>
                }
                secondaryTypographyProps={{ variant: 'caption' }}
              />
            </ListItem>
          )
        })
      )
    }
  }

  return (
    <Panel
      title={t('title')}
      avatar={!withoutBackButton && isMobile && (
        <IconButton
          component={Link}
          to={`/${resource}/${resourceId}/conversations`}
          className={classes.backButton}
        >
          <ArrowBackIosRounded />
        </IconButton>
      )}
      responsive
      noPadding
    >
      <MuiList>
        {loading ? <CircularLoader /> : renderContent()}
      </MuiList>
    </Panel>
  )
}

ConversationMessagesPanel.propTypes = {
  messages: ImmutablePropTypes.list,
  fetchConversationMessages: PropTypes.func,
  currentAgentId: PropTypes.number,
  match: PropTypes.object,
  withoutBackButton: PropTypes.bool,
  classes: PropTypes.object,
  fullScreen: PropTypes.bool,

  t: PropTypes.func
}

ConversationMessagesPanel.defaultProps = {
  messages: List()
}

export default compose(
  withStyles(styles),
  withMobileDialog(),
  withTranslation('conversation')
)(ConversationMessagesPanel)
