/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef } from 'react'
import { Field, ErrorMessage } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Modal from 'components/containers/Modal'

import SignatureCanvas from 'react-signature-canvas'

import './signature.scss'

const index = (props) => {
  const { action, closeBtn } = props
  const { t } = useTranslation(['navbar', 'creditnote'])
  const inputRef = useRef(null)
  const canvasRef = useRef(null)
  const [openModal, setOpenModal] = useState(false)
  const [signature, setSignature] = useState('')
  const [showBtn, setShowBtn] = useState(false)
  const [file, setFile] = useState('')

  const SelectImage = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
    setShowBtn(false)
    setSignature('choose')
  }

  const upLoadimage = (event) => {
    setFile(event.currentTarget.files[0])
  }

  const renderImage = (file) => {
    if (!closeBtn && file) {
      return URL.createObjectURL(file)
    }
    return file
  }

  const createCanvas = () => {
    var image = canvasRef.current.toDataURL().replace('image/png', 'image/octet-stream')
    downloadImage(image, `${Date.now()}.png`)
  }

  const downloadImage = (data, filename = 'untitled.jpeg') => {
    var a = document.createElement('a')
    a.href = data
    a.download = filename
    document.body.appendChild(a)
    a.click()
    setFileFormik(data, filename)
    // canvasRef.current.clear()
  }

  const setFileFormik = (data, name) => {
    const url = `${data}`
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], `${name}`, { type: 'image/png' })
        setFile(file)
      })
  }

  const saveFileSignature = () => {
    action.setFieldValue('signature.file', file)
    setOpenModal(false)
  }

  const endCanvas = () => {
    var image = canvasRef.current.toDataURL().replace('image/png', 'image/octet-stream')
    setFileFormik(image, `${Date.now()}.png`)
    setShowBtn(true)
  }

  const closeModal = () => {
    setOpenModal(false)
    setSignature('')
    setFile('')
  }

  return (
    <div>
      <Grid item xs={12} sm={8}>
        <div className='mt-2'>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <div className='mb-1 text-topic-register-address'>
                {t('authen.first_name')}
              </div>
              <div>
                <Field
                  type='text'
                  name='signature.first_name'
                  autoComplete='off'
                  className='legal-input'
                  placeholder={`${t('please_fill.FirstName')}`}
                  disabled={closeBtn}
                />
              </div>
              <div className='text-validate mt-1'>
                <ErrorMessage
                  name='signature.first_name'
                  component='div'
                  className='field-error'
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className='mb-1 text-topic-register-address'>
                {t('authen.last_name')}
              </div>
              <div>
                <Field
                  type='text'
                  name='signature.last_name'
                  className='legal-input'
                  autoComplete='off'
                  placeholder={`${t('please_fill.LastName')}`}
                  disabled={closeBtn}
                />
              </div>
              <div className='text-validate mt-1'>
                <ErrorMessage
                  name='signature.last_name'
                  component='div'
                  className='field-error'
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className='mb-1 text-topic-register-address'>
                {t('authen.signature')}
                <span className='dot-legal'>*</span>
              </div>

              {action.values.signature.file ? (
                <div className='signature-success'>
                  <img
                    src={renderImage(action.values.signature.file)}
                    alt='file'
                    width='100%'
                    height='100%'
                  />
                </div>
              ) : (
                <div
                  className='box-signature-canvas-custom'
                  onClick={() => setOpenModal((prev) => !prev)}
                >
                  <div className='box-icon'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 18 18'
                    >
                      <path
                        id='Icon_awesome-pen'
                        data-name='Icon awesome-pen'
                        d='M10.221,3.278l4.5,4.5L4.949,17.552l-4.013.443A.844.844,0,0,1,0,17.063l.446-4.016,9.77-9.769Zm7.284-.67L15.392.495A1.688,1.688,0,0,0,13,.495L11.017,2.483l4.5,4.5L17.505,5a1.688,1.688,0,0,0,0-2.387Z'
                        transform='translate(0.001 -0.001)'
                        fill='gray'
                      />
                    </svg>
                  </div>
                  <div className='title-canvas'>{t('authen.signature_here')}</div>
                </div>
              )}
              <hr className='mt-3 hr-custom' />
              <div className='text-center custom-text-author'>
                {t('authen.author_name')}
              </div>
              <div className='text-validate mt-1'>
                <ErrorMessage
                  name='signature.file'
                  component='div'
                  className='field-error'
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Modal
        isOpen={openModal}
        onCloseModal={() => closeModal()}
        className='section-signature-modal'
      >
        <div className='section-head-modal-signature'>
          <h3 className=''>Create your signature</h3>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18.25'
            height='18.25'
            viewBox='0 0 18.25 18.25'
            className='pointer'
            onClick={() => closeModal()}
          >
            <path
              id='Icon_ionic-md-close-circle'
              data-name='Icon ionic-md-close-circle'
              d='M12.5,3.375A9.125,9.125,0,1,0,21.625,12.5,9.093,9.093,0,0,0,12.5,3.375Zm4.563,12.41-1.277,1.277L12.5,13.777,9.215,17.062,7.937,15.785,11.223,12.5,7.937,9.215,9.215,7.938,12.5,11.223l3.285-3.285,1.277,1.277L13.777,12.5Z'
              transform='translate(-3.375 -3.375)'
              fill='#ccc'
            />
          </svg>
        </div>
        <div className='d-flex align-item-center custom-padding-modal'>
          <div
            className='btn-draw-modal btn-default-modal-signature  d-flex align-item-center justify-content-center pointer'
            onClick={() => SelectImage()}
          >
            Choose
          </div>
          <div
            className='btn-default-modal-signature btn-draw-modal d-flex align-item-center justify-content-center pointer'
            onClick={() => setSignature('draw')}
          >
            Draw
          </div>
        </div>
        {signature === 'draw' && (
          <SignatureCanvas
            ref={canvasRef}
            canvasProps={{
              width: 525,
              height: 150,
              className: 'sigCanvas border-draw-choose'
            }}
            onEnd={() => endCanvas()}
          />
        )}
        {signature === 'choose' && (
          <>
            {file && (
              <div
                className='box-image-custom-signature pointer'
                onClick={() => SelectImage()}
              >
                <img src={renderImage(file)} alt='file' width='100%' height='100%' />
              </div>
            )}
          </>
        )}

        <div className='d-flex align-item-center justify-content-end'>
          <div
            className={`btn-default-modal-signature ${
              signature === 'draw' && showBtn ? 'btn-draw-modal' : 'btn-choose-modal'
            } d-flex align-item-center justify-content-center pointer`}
            onClick={(e) => {
              signature === 'draw' && showBtn ? createCanvas('s') : e.stopPropagation()
            }}
          >
            Create signature
          </div>
          <div
            className={`btn-default-modal-signature ${
              file ? 'btn-draw-modal' : 'btn-choose-modal'
            }  d-flex align-item-center justify-content-center pointer`}
            onClick={(e) => (file ? saveFileSignature() : e.stopPropagation())}
          >
            Apply signature
          </div>
        </div>
        <input
          ref={inputRef}
          type='file'
          className='d-none'
          accept='image/jpeg, image/png'
          onChange={(e) => upLoadimage(e)}
        />
      </Modal>
    </div>
  )
}

export default index
