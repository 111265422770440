import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { destroy } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { omniauthIdentitySchema } from '@seekster/schemas'

import UserLoginMethods from './UserLoginMethods'

const mapStateToProps = state => ({
  getOmniauthIdentities: (schema, id) => {
    if (getResource(state, id, schema)) {
      return getResource(state, id, schema).get('omniauth_identities')
    }
  }
})

const mapDispatchToProps = {
  fetchOmniauthIdentities: (schema, id, options) => fetchChildren(schema, id, omniauthIdentitySchema, options),
  destroyOmniauthIdentity: id => destroy(id, omniauthIdentitySchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLoginMethods)
