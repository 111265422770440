import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import PageContent, { PageSection } from 'components/layouts/PageContent'

import Location from './Location'
import Request from './Request'
import Details from './Details'

export function JobOverviewPane ({ job }) {
  return (
    <PageContent>
      <PageSection position='center' borderRight>
        <Details job={job} />

        <Request job={job} />
      </PageSection>

      <PageSection position='right'>
        <Location job={job} />
      </PageSection>
    </PageContent>
  )
}

JobOverviewPane.propTypes = {
  job: ImmutablePropTypes.map
}

JobOverviewPane.defaultProps = {
  job: Map()
}

export default JobOverviewPane
