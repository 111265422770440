import { connect } from 'react-redux'
import { compose } from 'redux'

import { fetch } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { withQueryParser } from 'utils/withQueryParser'
import { serviceSchema } from '@seekster/schemas'
import FilterServiceTags from './FilterServiceTags'

const mapStateToProps = (state, { query }) => {
  const service = getResource(state, query.service_id, serviceSchema)

  return ({
    serviceName: service && service.get('name')
  })
}

const mapDispatchToProps = {
  fetchService: (id, options) => fetch(id, serviceSchema, options)
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(FilterServiceTags)
