import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { feedbackSchema } from '@seekster/schemas'

import { useResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'

import PageLayout from 'components/layouts/PageLayout'
import { Panel } from 'components/containers'
import FeedbackForm from 'forms/resources/FeedbackForm'

export function FeedbackEditPage ({ match, t }) {
  const [feedback] = useResource(feedbackSchema, match.params.id)
  const { update: updateFeedback } = useResourceAction(feedbackSchema)
  const push = usePush()

  function handleSubmit (data) {
    return updateFeedback(match.params.id, data)
  }

  return (
    <PageLayout
      title={t('edit')}
      back={`/feedbacks/${match.params.id}`}
    >
      <Grid container justify='center'>
        <Grid item xs={12} sm={8}>
          <Panel>
            <FeedbackForm
              feedback={feedback}
              onSubmit={handleSubmit}
              push={push}
            />
          </Panel>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

FeedbackEditPage.propTypes = {
  match: PropTypes.object,
  t: PropTypes.func
}

export default withTranslation('feedbacks')(FeedbackEditPage)
