import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { EditRounded, Delete } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import { BadgeOverviewPane } from './panes'

export function BadgeShowPage ({
  badge,
  location,
  t,
  push,
  fetchBadge,
  destroy,
  setFlashMessage,
  match
}) {
  const { loading } = useData(() => fetchBadge(match.params.id))

  function handleDestroy () {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      destroy(match.params.id)
        .then(response => {
          push('/badges')
        })
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function secondaryActions () {
    const actions = [{
      avatar: <EditRounded />,
      content: t('edit'),
      to: `/badges/${match.params.id}/edit`
    }, {
      avatar: <Delete />,
      content: t('delete'),
      onClick: handleDestroy
    }]

    return actions
  }

  function subNavbar () {
    const path = `/badges/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('common:navbar.overview')} to={path} />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs () {
    return [{
      text: t('title'),
      link: '/badges'
    }, {
      text: badge.get('name')
    }]
  }

  return (
    <PageLayout
      title={badge.get('name')}
      actions={secondaryActions()}
      subNavbar={subNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/badges/:id' component={BadgeOverviewPane} exact />
      </Switch>
    </PageLayout>
  )
}

BadgeShowPage.propTypes = {
  badge: ImmutablePropTypes.map,
  location: PropTypes.object,
  t: PropTypes.func,
  push: PropTypes.func,
  fetchBadge: PropTypes.func,
  destroy: PropTypes.func,
  setFlashMessage: PropTypes.func,
  match: PropTypes.object
}

BadgeShowPage.defaultProps = {
  badge: Map(),
  loadBadge: () => {}
}

export default withTranslation(['badges', 'actions'])(BadgeShowPage)
