import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './PageSectionStyles'

export function PageSection ({ children, position, borderLeft, borderRight, ...rest }) {
  const classes = useStyles({ position, borderLeft, borderRight })

  return (
    <div className={classes.root} {...rest}>
      {children}
    </div>
  )
}

PageSection.propTypes = {
  children: PropTypes.node,
  borderLeft: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  borderRight: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  position: PropTypes.oneOf([
    'left', 'center', 'right'
  ]).isRequired
}

export default PageSection
