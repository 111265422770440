import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import Field from 'components/fields/Field'

import styles from './CustomDiscountFormStyles'

export class CustomDiscountForm extends React.Component {
  static propTypes = {
    discount: ImmutablePropTypes.map,
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
    classes: PropTypes.object,

    t: PropTypes.func
  }

  static defaultProps = {
    discount: Map()
  }

  render () {
    const { discount, classes, onCancel, onDelete, t } = this.props

    return (
      <Form>
        <Grid container spacing={3} direction='column'>
          <Grid item xs>
            <Field name='amount_satangs' />
          </Grid>

          <Grid item xs>
            <Field
              name='description'
              render={({ field }) => <textarea {...field} />}
            />
          </Grid>

          <Grid item xs>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.button}
            >
              {t('save')}
            </Button>
            <Button
              variant='contained'
              onClick={onCancel}
              className={classes.button}
            >
              {t('cancel')}
            </Button>
            {
              !discount.isEmpty() && (
                <Button
                  className={classes.deleteButton}
                  size='small'
                  onClick={onDelete}
                >
                  {t('delete')}
                </Button>
              )
            }
          </Grid>
        </Grid>
      </Form>
    )
  }
}

export default compose(
  withStyles(styles),
  withTranslation(['payments', 'actions'])
)(CustomDiscountForm)
