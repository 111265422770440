import { flatMap, reduce, isObject } from 'lodash'

export const convertJsonBodyToFormData = (data) => {
  const convertData = (data, keyString) => flatMap(data, (value, key) => {
    let newKey = key
    let newValue = value

    if (keyString) {
      if (data instanceof Array && (!isObject(data[0]) || data[0] instanceof File)) {
        newKey = `${keyString}[]`
        newValue = data
      }
      else {
        newKey = `${keyString}[${key}]`
        newValue = value
      }
    }

    const isValid = value instanceof File || !isObject(value)

    return isValid ? { [newKey]: newValue } : convertData(value, newKey)
  })

  return reduce(convertData(data), (result, item) => ({ ...result, ...item }))
}

export default convertJsonBodyToFormData
