import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { createChild } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { promoCodeSchema, commentSchema } from '@seekster/schemas'

import PromoCodeCommentsPane from './PromoCodeCommentsPane'

const mapStateToProps = (state, { match }) => ({
  promoCode: getResource(state, match.params.id, promoCodeSchema)
})

const mapDispatchToProps = {
  fetchPromoCodeComments: (id) => fetchChildren(promoCodeSchema, id, commentSchema),
  createPromoCodeComment: (data, id) => createChild(data, promoCodeSchema, id, commentSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeCommentsPane)
