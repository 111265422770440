import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, destroy } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { clientSchema } from '@seekster/schemas'

import withFlashMessage from 'hoc/withFlashMessage'

import ClientShowPage from './ClientShowPage'

const mapStateToProps = (state, { match }) => ({
  client: getResource(state, match.params.id, clientSchema)
})

const mapDispatchToProps = {
  loadClient: (id, options) => fetch(id, clientSchema, options),
  destroy: (id, options) => destroy(id, clientSchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(withFlashMessage(ClientShowPage))
