import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Panel from 'components/containers/Panel'
import CircularLoader from 'components/CircularLoader'
import { useCollection } from 'hooks/collections'
import useQuery from 'hooks/useQuery'
import WebhookCardList from 'modules/webhooks/WebhookCardList'
import { webhookHandlerSchema } from '@seekster/schemas'
import { usePush } from 'hooks/utils'

const useStyles = makeStyles({
  notFound: {
    textAlign: 'center'
  },
  notFoundButton: {
    marginBottom: 30
  },
  button: {
    margin: 5
  }
})

export function WebhookSettingsPane () {
  const classes = useStyles()
  const { t } = useTranslation(['settings'])
  const [query] = useQuery()
  const [webhooks, {
    loading, totalCount
  }] = useCollection(webhookHandlerSchema, { page: query.page, query }, [query])
  const push = usePush()

  function actions () {
    return (
      <Button
        color='primary'
        variant='contained'
        className={classes.button}
        onClick={() => push('/webhooks/new')}
      >
        {t('webhook.new')}
      </Button>
    )
  }

  return (
    <Grid container justify='center'>
      <Grid item xs={12} sm={12}>
        <Panel
          title={t('webhook.title')} actions={actions()}
        >
          {
            loading ? (
              <CircularLoader />
            ) : (
              <>
                {
                  totalCount === 0 ? (
                    <div className={classes.notFound}>
                      <h2>
                        Webhook not found
                      </h2>
                      <Button
                        type='button'
                        color='primary'
                        variant='contained'
                        onClick={() => push('/webhooks/new')}
                        className={classes.notFoundButton}
                      >
                        Create webhook
                      </Button>
                    </div>
                  ) : (
                    <WebhookCardList webhooks={webhooks} />
                  )
                }
              </>
            )
          }
        </Panel>
      </Grid>
    </Grid>
  )
}

export default WebhookSettingsPane
