import React from 'react'
import { Link } from 'react-router-dom'
import { Table, Column } from 'components/tables/Table'
import { Paper } from '@material-ui/core'
import { ListAltOutlined } from '@material-ui/icons'
import { DateTag, StateTag } from 'components/tags'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  icon: {
    marginRight: 4,
    color: '#1b4170'
  },

  text: {
    textDecoration: 'underline'
  }
}))

export function ReportsTable({ reports }) {
  const classes = useStyles()

  const getLatestApprover = (approvers) => {
    approvers = approvers.toJS()

    if (approvers.length < 1) return ''

    const lastIndex = approvers.length - 1

    return approvers[lastIndex]
  }

  const getLastApproverName = (approvers) => {
    const approver = getLatestApprover(approvers)
    return approver ? approver.approver.fullName : ''
  }

  const getLastApproverPosition = (approvers) => {
    const approver = getLatestApprover(approvers)
    return approver ? approver.type : ''
  }

  return (
    <Paper>
      <Table collection={reports}>
        <Column
          name='number'
          isSort
          render={(_id, reports) => (
            <Link
              to={`/jobs/${reports.getIn(['job', 'wfJobId'])}`}
              className={classes.text}
            >
              {reports.get('number')}
            </Link>
          )}
        />
        <Column
          name='organization'
          render={(_, reports) => <>{reports.getIn(['org', 'name'])}</>}
        />
        <Column
          name='reportDescription'
          render={(_id, reports) => (
            <>
              <ListAltOutlined className={classes.icon} />
              <Link to={`/reports/${reports.get('_id')}`} className={classes.text}>
                Job {reports.getIn(['job', 'number'])}
              </Link>
            </>
          )}
        />
        <Column name='startedAt' component={DateTag} isSort />
        <Column name='completedAt' component={DateTag} isSort />
        <Column
          name='approvedBy'
          render={(_, reports) => <>{getLastApproverName(reports.get('approvers'))}</>}
        />
        <Column
          name='role'
          render={(_, reports) => (
            <>{getLastApproverPosition(reports.get('approvers'))}</>
          )}
        />
        <Column name='status' component={StateTag} />
      </Table>
    </Paper>
  )
}

export default ReportsTable
