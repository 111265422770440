import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import { jobSchema } from '@seekster/schemas'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SkipNextIcon from '@material-ui/icons/SkipNext';
import ErrorIcon from '@material-ui/icons/Error';

import { useResource } from 'hooks/resources'
import _ from 'lodash'

import createStore from 'createStore'
import Modal from 'components/containers/Modal'
import { useState } from 'react'

import './JobStateButton.scss'
import { callService } from 'utils/callService'
export const store = createStore()

function JobStateButton() {
  let { id } = useParams()
  const [job] = useResource(jobSchema, id)
  const { t } = useTranslation('tags')
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const handleClickOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const cancelProvider = async () => {
    try {
      const job_provider_id = _.find(job.toJS().job_providers ?? [], function (o) { return o.state === "pending_start" || o.state === "completed"; })?.id

      const response = await callService.put(`${process.env.REACT_APP_BASE_API}/job_providers/${job_provider_id}/cancel`)
      return response
    } catch (error) {
      console.log('error', error)
      alert(error.response.data.message)
    }
  }

  const updateStatus = async () => {
    const cancelSuccess = await cancelProvider()

    if (cancelSuccess) {
      try {
        const response = await callService.post(
          `${process.env.REACT_APP_BASE_API}/jobs/${id}/assign_provider`,
          { "provider_id": _.find(job.toJS().job_providers ?? [], { state: "pending_start" })?.provider.id, })

        if (response.status === 200) {
          window.location.reload()
        }
      } catch (error) {

        setOpenAlert(prev => !prev)
        setAlertMessage(error.response.data.message ?? 'Something wrong!')
      }
    }
  }

  const updateCompletedStatus = async () => {
    try {
      const response = await callService.post(
        `${process.env.REACT_APP_BASE_API}/jobs/${id}/update_state`,
        { "provider_id": _.find(job.toJS().job_providers ?? [], { state: "pending_start" })?.provider.id, })

      if (response.status === 200) {
        window.location.reload()
      }
    } catch (error) {
      setOpenAlert(prev => !prev)
      setAlertMessage(error.response.data.message ?? 'Something wrong!')
    }
  }

  const handleCheckJobProviderStatus = () => {
    const hasJobProviderPendingStart = _.find(job.toJS().job_providers ?? [], { state: "pending_start" })
    const hasJobProviderCompleted = _.find(job.toJS().job_providers ?? [], { state: "completed" })

    if (hasJobProviderPendingStart) {
      updateStatus()
    } else if (hasJobProviderCompleted) {
      updateCompletedStatus()
    }
  }

  const handleCheckProvider = () => {
    if (
      job.toJS().job_providers.length === 0 ||
      job.get('job_providers') === null
    ) {
      setOpenAlert(prev => !prev)
      setAlertMessage(t('message.alert_no_provider'))
    } else {
      handleClickOpenConfirm(true)
    }
  }

  const color = {
    pending_provider: '#1B4171',
    pending_start: '#F1701B',
    pending_completion: '#18ABCC',
    completed: '#21AB15',
    cancelled: '#f44336'
  }

  return (
    <div>
      <Button
        className='job-state-btn'
        type='button'
        variant='contained'
        onClick={() => handleCheckProvider()}
        style={{ color: 'white', borderRadius: 50, backgroundColor: color[job.get('state')] }}
        disabled={job.get('state') !== 'pending_provider'}
        endIcon={job.get('state') === 'pending_provider' && <SkipNextIcon />}
      >
        {t(job.get('state'))}
      </Button>

      {/* ============ Modal Part ============================================================= */}
      <Dialog
        className='job-state-confirmation-modal'
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{}} color='primary'>
            {t('message.confirm_job_change_status')}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'stretch' }}>
          <Button style={{ flex: 1, borderWidth: 2 }} onClick={handleCloseConfirm} variant='outlined' color="primary">
            {t('no')}
          </Button>
          <Button style={{ flex: 1 }} onClick={() => handleCheckJobProviderStatus()} variant='contained' color="primary" autoFocus>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        className="job-state-alert"
        isOpen={openAlert}
        onCloseModal={() => setOpenAlert(false)}
        style={{ fontSize: 15, fontWeight: 'medium' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <ErrorIcon color='error' style={{ marginRight: 14, fontSize: 32 }} />
          </div>
          <div>
            {alertMessage}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default JobStateButton
