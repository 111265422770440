import React from 'react'

export function AddressFormatter ({ value }) {
  return (
    <p>
      {
        value.contact_name && (
          <span>{value.contact_name}, </span>
        )
      }
      {
        value.phone_number && (
          <span>{value.phone_number}, </span>
        )
      }
      {
        value.name && (
          <span>{value.name}, </span>
        )
      }
      {
        value.house_number && (
          <span>{value.house_number}, </span>
        )
      }
      {
        value.project_name && (
          <span>{value.project_name}, </span>
        )
      }
      {
        value.street && (
          <span>{value.street}, </span>
        )
      }
      {
        value.sub_district && (
          <span>{value.sub_district}, </span>
        )
      }
      {value.district}, {value.province}
    </p>
  )
}

export default AddressFormatter
