import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'
import { withTranslation } from 'react-i18next'

import './StateTag.scss'

export function StateTag({ value, t, round }) {
  return (
    <span className={classNames([round ? 'state-tag-round' : 'state-tag', value])}>
      {t(`${value}`)}
    </span>
  )
}

StateTag.propTypes = {
  value: PropTypes.string,
  round: PropTypes.bool,
  t: PropTypes.func
}

export default withTranslation('tags')(StateTag)
