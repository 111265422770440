import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { useReference } from 'hooks/utils'
import { firebaseUserSchema, agentSchema, departmentSchema } from '@seekster/schemas'
import { withStyles } from '@material-ui/core/styles'
import TimeFormatter from 'components/formatters/TimeFormatter'
import { upperFirst } from 'lodash'
import { useResource } from 'hooks/resources'

import {
  Avatar,
  Card,
  CardContent,
  CardActionArea,
  Grid,
  Typography
} from '@material-ui/core'

import Tag from 'components/Tag'
import styles from './TicketsListStyles'

export const UserType = ({ isDepartment, id, userTypeSchema }) => {
  const [user, { loading }] = useResource(userTypeSchema, id, [id, userTypeSchema])

  return (
    <>
      {
        loading ? null : isDepartment
          ? user.get('name')
          : user.get('first_name')
      }
    </>
  )
}

export function Ticket ({
  ticket,
  match,
  classes,
  location
}) {
  const defaultImage = 'https://s3-ap-southeast-1.amazonaws.com/seekster-web/assets/defaults/default_avatar.png'
  const [user] = useReference(ticket.get('requestIdRef'), firebaseUserSchema)
  const newMessage = ticket.get('newest_message').length > 20
    ? `${ticket.get('newest_message').slice(0, 20)}...`
    : ticket.get('newest_message')
  const selected = location.pathname === `${match.url}/${ticket.get('id')}`
  const tagColor = ({
    LINE: '#00c300',
    CUSTOMER: '#2d95f4',
    PROVIDER: '#2d95f4'
  })[user.get('source')]
  const priorityColor = ({
    high: '#FF7010',
    normal: '#81A7C9',
    low: '#B6B6B6'
  })[ticket.get('priority')]
  const userTypeSchema = ticket.get('department') ? departmentSchema : agentSchema

  const isTicketDisabled = !user.get('follow') || ticket.get('status') === 'closed'

  return (
    <Card square key={ticket.get('id')} className={selected && classes.selected}>
      <CardActionArea
        component={Link}
        to={`${match.url}/${ticket.get('id')}${location.search}`}
        className={isTicketDisabled ? classes.noDecorationWithDisabled : classes.noDecoration}
      >
        <CardContent className={classes.cardContainer}>
          <div className={classes.tagContainer}>
            <Tag value={upperFirst(ticket.get('priority'))} backgroundColor={priorityColor} color='white' />
            <Tag value={ticket.getIn(['channel', 'name'])} backgroundColor={tagColor} color='white' />

            <span className={classes.spanAgentContainer}>
              <UserType isDepartment={ticket.get('department')} userTypeSchema={userTypeSchema} id={ticket.get('assignee')} />
            </span>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={2}>
              <Avatar
                src={user.get('image_url') || defaultImage}
              />
            </Grid>

            <Grid item xs sm container>
              <Grid item xs container direction='column'>
                <Grid item xs>
                  <Typography gutterBottom variant='subtitle1' className={classes.title}>
                    {user.get('name')}
                  </Typography>

                  <Typography variant='body2' gutterBottom>
                    {newMessage}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <div className={classes.centerContainer}>
                  <TimeFormatter value={ticket.get('updated_at')} />
                </div>
                {
                  (!ticket.get('is_checked') || ticket.get('is_following')) && <div className={classes.centerContainer}>
                    <span className={classes.unReadCount} />
                  </div>
                }
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

Ticket.propTypes = {
  ticket: ImmutablePropTypes.map,
  match: PropTypes.object,
  classes: PropTypes.object,
  location: PropTypes.object
}

Ticket.defaultProps = {
  ticket: Map()
}

export default withStyles(styles)(Ticket)
