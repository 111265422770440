import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    '@media screen and (max-width: 767px)': {
      borderRadius: 0
    }
  },

  cardHeader: {
    backgroundColor: theme.palette.grey[200],
    padding: '10px 16px',
    maxHeight: 48,
    '& .MuiCardHeader-avatar': {
      display: 'flex'
    }
  },

  footer: {
    width: '100%',
    margin: '0'
  },

  cardFooter: {
    padding: 0
  },

  subheaderContainer: {
    backgroundColor: theme.palette.grey[200],
    padding: '0 0 8px 16px',

    '@media screen and (max-width: 767px)': {
      padding: '8px 0 8px 16px'
    }
  },

  subheader: {
    backgroundColor: theme.palette.grey[50],
    padding: '3px 16px'
  },

  cardAction: {
    position: 'relative',
    top: '-2px',
    left: 8
  },

  content: {
    padding: 0,
    '&:last-child': {
      padding: 0
    },
    '&::-webkit-scrollbar': {
      width: 5
    }
  }
}))
