import { connect } from 'react-redux'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'
import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { serviceSchema, providerSchema } from '@seekster/schemas'

import ServiceProvidersPane from './ServiceProvidersPane'

const mapStateToProps = (state, { match }) => ({
  service: getResource(state, match.params.id, serviceSchema)
})

const mapDispatchToProps = {
  fetchServiceProviders: (id, options) => fetchChildren(serviceSchema, id, providerSchema, options)
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(ServiceProvidersPane)
