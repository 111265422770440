import { connect } from 'react-redux'

import { fetch } from 'actions/resources'
import { areaTemplateSchema } from '@seekster/schemas'
import RegionFormController from './RegionFormController'

const mapDispatchToProps = {
  fetchAreaTemplate: (id, options) => fetch(id, areaTemplateSchema, options)
}

export default connect(null, mapDispatchToProps)(RegionFormController)
