import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { CardActionArea, CardHeader, Avatar } from '@material-ui/core'
import { Email, Phone } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { capitalize } from 'lodash'
import { useSelector } from 'react-redux'
import { inquirySchema } from '@seekster/schemas'

import PageContent, { PageSection } from 'components/layouts/PageContent'
import Panel from 'components/containers/Panel'
import JobsCalendar from 'modules/jobs/JobsCalendar'
import CustomerCard from 'components/cards/CustomerCard'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import PhoneNumberFormatter from 'components/formatters/PhoneNumberFormatter'
import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'
import QuestionsAndAnswers from 'components/lists/QuestionsAndAnswers'
import { getResource } from 'selectors/resources'
import RequestMapSection from 'modules/requests/RequestMapSection'
import Invoice from './components/Invoice'
import Subscription from './components/Subscription'
import useStyles from './styles'
import { firebaseWorkforcePartner } from 'utils/firebase'

const db = firebaseWorkforcePartner.firestore()

export function InquiryOverviewPane(props) {
  const classes = useStyles()
  const inquiry =
    useSelector((state) => getResource(state, props.match.params.id, inquirySchema)) ||
    Map()
  const [currentLocation, setCurrentLocation] = useState(null)
  const pathCurrentLocation =
    process.env.REACT_APP_ENV_NAME === 'staging'
      ? 'staging_current_locations'
      : 'current_locations'

  useEffect(() => {
    if (inquiry.get('state') === 'pending_completion') {
      const docRef = db
        .collection(`${pathCurrentLocation}`)
        .doc(`${inquiry.getIn(['upcoming_provider', 'id'])}`)
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            setCurrentLocation(doc.data())
            return doc.data()
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageContent>
      <PageSection position='center' borderRight>
        <Panel>
          <AttributesTable resource={inquiry}>
            <Attribute name='id' />
            <Attribute name='number' />
            <Attribute
              name='service'
              label='Service Name'
              render={(service = Map()) => {
                const region = service.get('regional_slug', '').split('-').shift()

                return (
                  <Link to={`/services/${service.get('id')}`}>
                    {service.get('name')} ({capitalize(region)})
                  </Link>
                )
              }}
            />
            <Attribute
              name='orders'
              render={(orders) => (
                <ul>
                  {orders &&
                    orders.map((order, index) => {
                      const pkg = order.get('package') || Map()

                      return (
                        <li key={index}>
                          <p>
                            {pkg.get('name')} x {order.get('quantity')} {pkg.get('unit')}
                          </p>
                        </li>
                      )
                    })}
                </ul>
              )}
            />
            <Attribute
              name='attachments'
              render={(attachments) =>
                attachments &&
                attachments.map((attachment, index) => (
                  <img
                    key={attachment.get('id')}
                    src={attachment.get('file_url')}
                    style={{ width: 'auto', height: '100px', maxWidth: '200px' }}
                    alt={`attachment ${index + 1}`}
                  />
                ))
              }
            />
            {inquiry.get('company') && <Attribute name='company.name' />}
            <Attribute name='location.contact_name' />
            <Attribute name='location.phone_number' component={PhoneNumberFormatter} />
            <Attribute name='location.full_address' />
            <Attribute name='ref' />
            <Attribute name='updated_at' component={DateTimeFormatter} />
            <Attribute name='created_at' component={DateTimeFormatter} />
            <Attribute name='remarks_for_provider' />
            <Attribute
              name='client.name'
              to={(inquiry) => `/clients/${inquiry.getIn(['client', 'id'])}`}
            />
          </AttributesTable>
        </Panel>

        <QuestionsAndAnswers
          answers={inquiry.get('answers')}
          addtionalInformation={inquiry.get('additional_information')}
        />
        <RequestMapSection
          currentLocation={currentLocation}
          route={inquiry.get('route') || Map()}
          location={inquiry.get('location') || Map()}
          isDelivery={inquiry.get('has_logistics')}
        />
      </PageSection>

      <PageSection position='right'>
        <CustomerCard title='Customer' customer={inquiry.get('customer')} />

        {inquiry.get('subscription_month') && (
          <Panel title='Subscription'>
            <Subscription inquiry={inquiry} {...props} />
          </Panel>
        )}

        <Panel title='Jobs Calendar' noPadding>
          <JobsCalendar jobs={inquiry.get('jobs')} />
        </Panel>

        {inquiry.get('company') && (
          <Panel title='Company'>
            <CardActionArea
              component={Link}
              to={`/companies/${inquiry.getIn(['company', 'id'])}`}
              style={{ textDecoration: 'none' }}
            >
              <CardHeader
                avatar={
                  <Avatar
                    src={inquiry.getIn(['company', 'avatar_url'])}
                    alt={inquiry.getIn(['company', 'display_name'])}
                  />
                }
                title={inquiry.getIn(['company', 'name'])}
                subheader={
                  <>
                    {inquiry.getIn(['company', 'billing_email']) && (
                      <div>
                        <Email className={classes.emailIcon} />
                        {inquiry.getIn(['company', 'billing_email'])}
                      </div>
                    )}

                    {inquiry.getIn(['company', 'phone_number']) && (
                      <div>
                        <Phone className={classes.phoneIcon} />
                        {inquiry.getIn(['company', 'phone_number'])}
                      </div>
                    )}
                  </>
                }
                style={{ padding: 0 }}
              />
            </CardActionArea>
          </Panel>
        )}

        <Panel title='Invoice'>
          <Invoice inquiry={inquiry} {...props} />
        </Panel>
      </PageSection>
    </PageContent>
  )
}

InquiryOverviewPane.propTypes = {
  classes: PropTypes.object,
  inquiry: ImmutablePropTypes.map,
  match: PropTypes.object
}

export default InquiryOverviewPane
