import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'formik'
import { useTranslation } from 'react-i18next'
import { AppBar, Badge, Button, Toolbar } from '@material-ui/core'

import LocaleContext from 'contexts/LocaleContext'
import useStyles from './NavigationBarPreviewStyles'
import './NavigationBarPreview.scss'

const recentlyAdded = { en: 'NEW', th: 'ใหม่' }

export function NavigationBarPreview ({ formik }) {
  const classes = useStyles()
  const locale = useContext(LocaleContext)
  const { t } = useTranslation('settings')

  const {
    navigation_links_attributes: navigationLinksAttributes
  } = formik.values.general_configuration_attributes

  return (
    <AppBar position='static' className={classes.appBar}>
      <Toolbar>
        <img
          src={formik.values.dark_logo_url}
          alt='Dark Logo'
          className={classes.logo}
        />

        <div className={classes.NavigationBar}>
          {
            navigationLinksAttributes && navigationLinksAttributes.map((navigationLinksAttribute, index) => (
              <Button
                disabled
                key={index}
                className={classes.text}
              >
                <Badge badgeContent={navigationLinksAttribute.recently_added ? recentlyAdded[`${locale}`] : 0} color='error' className={classes.badge}>
                  {navigationLinksAttribute[`text_${locale}`]}
                </Badge>
              </Button>
            ))
          }

          <Button disabled>{t('tenant.region')}</Button>
          <Button disabled>{t('tenant.sign_in')}</Button>
        </div>
      </Toolbar>
    </AppBar>
  )
}

NavigationBarPreview.propTypes = {
  formik: PropTypes.object
}

export default compose(
  connect
)(NavigationBarPreview)
