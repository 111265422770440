import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import styles from './FeedbackPreviewStyles'

export function FeedbackPreview ({ feedback, classes, t }) {
  return (
    <ListItem
      button
      component={Link}
      to={`/feedbacks/${feedback.get('id')}`}
    >
      <ListItemAvatar>
        <Avatar src={feedback.getIn(['created_by', 'avatar_url'])} alt='' />
      </ListItemAvatar>

      <ListItemText
        primary={
          <div className={classes.commentContainer}>
            “
            <span className={classes.comment}>{feedback.get('comment')}</span>
            ”
          </div>
        }
        secondary={`
          ${t('by')} ${feedback.getIn(['created_by', 'display_name'])}
          ${moment(feedback.get('created_at')).fromNow()}
        `}
        classes={{ secondary: classes.feedbackGiver }}
      />
    </ListItem>
  )
}

FeedbackPreview.propTypes = {
  feedback: ImmutablePropTypes.map,
  classes: PropTypes.object,

  t: PropTypes.func
}

FeedbackPreview.defaultProps = {
  feedback: Map()
}

export default compose(
  withStyles(styles),
  withTranslation('feedback')
)(FeedbackPreview)
