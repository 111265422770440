import { connect } from 'react-redux'
import { replace } from 'react-router-redux'

import {
  fetchCurrentTenantBySlug,
  signIn,
  updateCurrentDevice
} from 'actions/authentication'
import { getCurrentResource } from 'selectors/authentication'
import { tenantSchema } from '@seekster/schemas'

import SignInPage from './SignInPage'

const mapStateToProps = (state) => ({
  tenant: getCurrentResource(state, tenantSchema)
})

const mapDispatchToProps = {
  fetchCurrentTenantBySlug: () => fetchCurrentTenantBySlug(tenantSchema),
  signIn,
  updateCurrentDevice,
  replace
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage)
