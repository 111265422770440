import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, FormGroup, FormControlLabel, Switch } from '@material-ui/core'
import { connect, getIn } from 'formik'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { FastField, LocalizedField } from 'components/fields/Field'
import LocaleContext from 'contexts/LocaleContext'
import SortableFormSection from 'components/fields/SortableFieldArray/SortableFormSection'
import SortableFormItem from 'components/fields/SortableFieldArray/SortableFormItem'
import SortableFieldArray from 'components/fields/SortableFieldArray'
import CsvButtonUpload from 'components/CsvButtonUpload'

import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'

const displayType = [
  'string',
  'textarea',
  'radio',
  'checkboxes',
  'select',
  'number',
  'decimal',
  'gallery',
  'gallery_multiple',
  'camera',
  'camera_multiple',
  'searchable_select'
]

export class ServiceQuestionFormSection extends Component {
  static contextType = LocaleContext

  static propTypes = {
    name: PropTypes.string,
    formik: PropTypes.object,

    t: PropTypes.func
  }

  renderCustomerVisibleField() {
    const { name, t } = this.props
    if (name.includes('report_question')) {
      return (
        <Grid item xs={6}>
          <FastField
            name={`${name}.visible_to_customer`}
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  label={t(`visible_to_customer.${field.value}`)}
                  control={<Switch checked={field.value} {...field} />}
                />
              </FormGroup>
            )}
            noLabel
          />
        </Grid>
      )
    }
  }

  setParsedJsonResult(result, name) {
    const { setFieldValue, values } = this.props.formik

    const newResult = result.map((values) => {
      const newRow = {}

      newRow.value_th = values[0]
      newRow.value_en = values[1]
      return newRow
    })

    if (name.substr(0, 7) === 'request') {
      const oldData =
        values.request_questions_attributes[name.substr(29, 1)].choices_attributes !==
        undefined
          ? values.request_questions_attributes[name.substr(29, 1)].choices_attributes
          : []

      setFieldValue(name, [...oldData, ...newResult])
    } else {
      const oldData =
        values.report_questions_attributes[name.substr(28, 1)].choices_attributes !==
        undefined
          ? values.report_questions_attributes[name.substr(28, 1)].choices_attributes
          : []

      setFieldValue(name, [...oldData, ...newResult])
    }
  }

  renderAdditionalFields() {
    const { formik, name, t } = this.props
    const locale = this.context

    switch (getIn(formik.values, `${name}.display_type`)) {
      case 'radio':
      case 'checkboxes':
      case 'select':
      case 'searchable_select':
        return (
          <Grid item xs={12}>
            <label>{t('label.choices_attributes')}</label>

            <Typography gutterBottom variant='body1' color='textSecondary'>
              {t(locale)} - {locale}
            </Typography>

            <CsvButtonUpload
              onHandleUpload={(result) =>
                this.setParsedJsonResult(result, `${name}.choices_attributes`)
              }
            />
            <SortableFieldArray
              name={`${name}.choices_attributes`}
              render={(helperBag) => (
                <SortableFormItem key={helperBag.name} {...helperBag}>
                  <LocalizedField name={`${helperBag.name}.value`} noLabel noHint />
                </SortableFormItem>
              )}
              noLabel
            />
          </Grid>
        )
      case 'string':
      case 'textarea':
      case 'number':
      case 'decimal':
        return (
          <Grid item xs={12}>
            <LocalizedField name={`${name}.placeholder`} />
          </Grid>
        )
      case 'gallery':
      case 'gallery_multiple':
      case 'camera':
      case 'camera_multiple':
      default:
        return null
    }
  }

  render() {
    const { name, formik, t } = this.props
    const locale = this.context
    const values = getIn(formik.values, name)

    return (
      <SortableFormSection title={values[`name_${locale}`]} {...this.props}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <LocalizedField name={`${name}.name`} />
          </Grid>

          <Grid item xs={12}>
            <FastField
              name={`${name}.display_type`}
              component={SelectInput}
              options={arrayToOptions(displayType, {
                i18nNamespace: 'services',
                i18nKey: 'question_types'
              })}
            />
          </Grid>

          {this.renderAdditionalFields()}

          <Grid item xs={6}>
            <FastField
              name={`${name}.required`}
              noLabel
              render={({ field }) => (
                <FormGroup>
                  <FormControlLabel
                    label={t(`required.${field.value}`)}
                    control={<Switch checked={field.value} {...field} />}
                  />
                </FormGroup>
              )}
            />
          </Grid>

          {this.renderCustomerVisibleField()}
        </Grid>
      </SortableFormSection>
    )
  }
}

export default compose(
  connect,
  withTranslation(['fields', 'languages', 'common'])
)(ServiceQuestionFormSection)
