import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import SubscriptionForm from 'forms/resources/SubscriptionForm'

export class SubscriptionDuplicatePage extends React.Component {
  static propTypes = {
    subscription: ImmutablePropTypes.map,
    fetchSubscription: PropTypes.func,
    createSubscription: PropTypes.func,
    match: PropTypes.object,
    push: PropTypes.func,
    t: PropTypes.func
  }

  static defaultProps = {
    subscription: Map(),

    fetchSubscription: () => {}
  }

  componentDidMount () {
    this.props.fetchSubscription(this.props.match.params.id)
  }

  breadcrumbs () {
    return [{
      text: this.props.t('title'),
      link: '/subscriptions'
    }, {
      text: this.props.subscription.get('number'),
      link: `/subscriptions/${this.props.match.params.id}`
    }, {
      text: this.props.t('duplicate')
    }]
  }

  render () {
    return (
      <PageLayout
        title={this.props.t('duplicate')}
        breadcrumbs={this.breadcrumbs()}
        isLoading={this.props.subscription.isEmpty()}
      >
        {
          !this.props.subscription.isEmpty() &&
          <SubscriptionForm
            subscription={this.props.subscription}
            push={this.props.push}
            onSubmit={this.props.createSubscription}
            withoutAssociationIds
            withoutJobs
            showReferenceValues
          />
        }
      </PageLayout>
    )
  }
}

export default withTranslation('subscriptions')(SubscriptionDuplicatePage)
