import React, { useEffect, useState } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { connect, getIn } from 'formik'

import { Field } from 'components/fields/Field'
import SortableFormItem from 'components/fields/SortableFieldArray/SortableFormItem'
import SelectInput from 'components/inputs/SelectInput'

export function CategoryServiceFormItem ({ name, formik, services, ...rest }) {
  const values = getIn(formik.values, name)
  const [optionServices, setOptionServices] = useState(List())
  const categoryServices = formik.values.category_services_attributes || List()

  useEffect(() => {
    if (services) {
      updateOptionServices()
    }
  }, [values.service_id, categoryServices, services]) // eslint-disable-line react-hooks/exhaustive-deps

  function updateOptionServices () {
    const serviceIds = categoryServices.map(c => c.service_id)
    let options = services.map(service => ({ label: service.get('name'), value: service.get('id') }))

    options = options.filter(service => !serviceIds.includes(service.value) || values.service_id === service.value)
    setOptionServices(options)
  }

  return (
    <SortableFormItem key={values.service_id} {...rest}>
      <Field
        name={`${name}.service_id`}
        component={SelectInput}
        options={optionServices}
        noLabel
        noHint
      />
    </SortableFormItem>
  )
}

CategoryServiceFormItem.propTypes = {
  name: PropTypes.string,
  formik: PropTypes.object,

  services: ImmutablePropTypes.list
}

export default connect(CategoryServiceFormItem)
