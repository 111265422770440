import { Map, List } from 'immutable'
import { withFormik } from 'formik'
import OrganizationForm from './OrganizationForm'

const formikConfig = {
  mapPropsToValues: ({ organization = Map() }) => ({
    name: organization.get('name'),
    phoneNumber: organization.get('phoneNumber'),
    pictureUrl: organization.get('pictureUrl'),
    email: organization.get('email'),
    isRequireAdminApprove: organization.get('isRequireAdminApprove'),
    isRequireOrgApprove: organization.get('isRequireOrgApprove'),
    information: organization.get('information'),
    administrators: organization.get('administrators', List()).toJS() || [],
    providers: organization.get('providers', List()).toJS() || []
  }),

  enableReinitialize: true,

  handleSubmit: (values, { props, setSubmitting }) => {
    const newValue = values

    if (newValue.administrators.length !== 0) {
      const adminIds = values.administrators.map((value) => value.id || value.wfUserId)
      newValue.administrators = adminIds
    }

    if (newValue.providers.length !== 0) {
      const providerIds = values.providers.map((value) => value.id || value.wfUserId)
      newValue.providers = providerIds
    }

    delete newValue.pictureUrl

    props
      .onSubmit(newValue)
      .then(() => {
        setSubmitting(false)
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.body.message)
        } else if (error.message) {
          alert(error.message)
        }

        setSubmitting(false)
      })
  }
}

export default withFormik(formikConfig)(OrganizationForm)
