import React from 'react'
import { Grid } from '@material-ui/core'
import { useResource } from 'hooks/resources'

import Panel from 'components/containers/Panel'
import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import { webhookHandlerSchema } from '@seekster/schemas'

export function WebhookOverviewPane ({ match }) {
  const [webhook] = useResource(webhookHandlerSchema, match.params.id)

  return (
    <Grid container>
      <Grid item xs={12}>
        <Panel title={webhook.get('name')}>
          <AttributesTable resource={webhook} basic='very'>
            <Attribute name='id' />
            <Attribute name='name' />
            <Attribute label='Type' name='source.type' />
            <Attribute label={`${webhook.getIn(['source', 'type'])} name`} name='source.name' />
            <Attribute name='webhook_url' />
            <Attribute name='created_at' component={DateTimeFormatter} />
          </AttributesTable>
        </Panel>
      </Grid>
    </Grid>
  )
}

export default WebhookOverviewPane
