import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { create } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { inquirySchema, commentSchema } from '@seekster/schemas'

import InquiryComments from './InquiryComments'

const mapStateToProps = (state, { match }) => ({
  inquiry: getResource(state, match.params.id, inquirySchema)
})

const mapDispatchToProps = {
  loadComments: (id, options) => fetchChildren(inquirySchema, id, commentSchema, options),
  create: (data, id, options) => create(data, inquirySchema, { type: `inquiries/${id}/comments` })
}

export default connect(mapStateToProps, mapDispatchToProps)(InquiryComments)
