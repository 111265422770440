import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import withLocalizedFields from 'hoc/withLocalizedFields'
import PageLayout from 'components/layouts/PageLayout'
import CompanyForm from 'forms/resources/CompanyForm'
import SelectInput from 'components/inputs/SelectInput'

export function CompanyNewPage({ createCompany, push, locale, setLocale }) {
  const { t } = useTranslation('companies')

  function actions() {
    return (
      <SelectInput
        value={locale}
        onChange={setLocale}
        options={[
          { label: 'English', value: 'en' },
          { label: 'ภาษาไทย', value: 'th' }
        ]}
        isClearable={false}
        styles={{ container: (base) => ({ ...base, width: 140 }) }}
      />
    )
  }

  return (
    <PageLayout title={t('new')} secondaryActions={actions()} back='/companies'>
      <CompanyForm push={push} onSubmit={createCompany} />
    </PageLayout>
  )
}

CompanyNewPage.propTypes = {
  createCompany: PropTypes.func,
  push: PropTypes.func,
  locale: PropTypes.string,
  setLocale: PropTypes.func
}

export default withLocalizedFields(CompanyNewPage)
