import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Immutable from 'immutable'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Table, Column } from 'components/tables/Table'
import { Paper } from '@material-ui/core'
import { DateTag } from 'components/tags'

export function AddressesTable ({ addresses }) {
  return (
    <Paper>
      <Table collection={addresses}>
        <Column
          name='name'
          render={(name, address) => (
            <Link to={`/addresses/${address.get('id')}`}>{address.get('name')}</Link>
          )}
        />
        <Column
          name='owner'
          render={(owner, address) => (
            <Link to={`/${address.get(['customer', 'type'])}/${address.get(['customer', 'id'])}`}>{address.get(['customer', 'display_name'])}</Link>
          )}
        />
        <Column name='full_address' />
        <Column name='created_at' component={DateTag} />
      </Table>
    </Paper>
  )
}

AddressesTable.propTypes = {
  addresses: ImmutablePropTypes.list
}

AddressesTable.defaultProps = {
  addresses: Immutable.List()
}

export default withTranslation('addresses')(AddressesTable)
