import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Button, Avatar, Typography } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import { components } from 'react-select'
import { withTranslation } from 'react-i18next'

import Field from 'components/fields/Field'
import DateTimeInputField from 'components/fields/DateTimeInputField'
import { bankAccountSchema } from '@seekster/schemas'
import ResourceSelect from 'components/inputs/ResourceSelect'

import styles from './BankTransferPaymentReviewFormStyles'

export class BankTransferPaymentReviewForm extends React.Component {
  static propTypes = {
    payment: ImmutablePropTypes.map,
    onCancel: PropTypes.func,
    onDisgard: PropTypes.func,
    classes: PropTypes.object,

    t: PropTypes.func
  }

  static defaultProps = {
    payment: Map()
  }

  render () {
    const { payment, classes, onCancel, onDisgard, t } = this.props

    return (
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <img
              src={payment.getIn(['transfer_slip', 'image_url'])}
              alt='Transfer Slip'
              className={classes.transferSlip}
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <Field name='amount_satangs' />

            <Field
              name='bank_account_id'
              component={ResourceSelect}
              schema={bankAccountSchema}
              getOptionLabel={({ account_name: accountName }) => accountName}
              components={{
                Option: (props) => (
                  <components.Option {...props}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Avatar src={props.data.bank.logo_url} alt={props.data.bank.name} />
                      </Grid>
                      <Grid item>
                        <Typography variant='subtitle1'>{props.data.account_name}</Typography>
                        <Typography variant='caption'>{props.data.formatted_account_number}</Typography>
                      </Grid>
                    </Grid>
                  </components.Option>
                )
              }}
            />

            <DateTimeInputField name='captured_at' />
          </Grid>

          <Grid item xs={12}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.button}
            >
              {t('capture')}
            </Button>
            <Button
              variant='contained'
              onClick={onCancel}
              className={classes.button}
            >
              {t('cancel')}
            </Button>
            <Button
              onClick={onDisgard}
              className={classes.voidButton}
              classes={{ label: classes.voidButtonLabel }}
            >
              <Cancel />
              {t('void')}
            </Button>
          </Grid>
        </Grid>
      </Form>
    )
  }
}

export default compose(
  withStyles(styles),
  withTranslation(['payments', 'actions'])
)(BankTransferPaymentReviewForm)
