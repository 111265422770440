import { connect } from 'react-redux'

import { fetch } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { paymentSchema } from '@seekster/schemas'

import PaymentsShowPage from './PaymentsShowPage'

const mapStateToProps = (state, { match }) => ({
  payment: getResource(state, match.params.id, paymentSchema)
})

const mapDispatchToProps = {
  fetchPayment: (id, options) => fetch(id, paymentSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsShowPage)
