import { red } from '@material-ui/core/colors'

export default ({ spacing }) => ({
  iconButton: {
    padding: spacing(0.5)
  },

  reject: {
    color: red[800]
  }
})
