import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, update } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { appSchema } from '@seekster/schemas'

import AppEditPage from './AppEditPage'

const mapStateToProps = (state, { match }) => ({
  app: getResource(state, match.params.id, appSchema)
})

const mapDispatchToProps = {
  fetchApp: (id) => fetch(id, appSchema),
  updateApp: (id, data) => update(id, data, appSchema),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(AppEditPage)
