import React from 'react'
import { components } from 'react-select'
import Tag from 'components/Tag'

const RegionTag = ({ name }) => (
  <Tag
    value={name}
    style={{
      borderRadius: 4,
      backgroundColor: '#d7edff',
      marginLeft: 8
    }}
  />
)

export default {
  Option: ({ data, ...rest }) => {
    const { name, region, categories = [] } = data

    return (
      <components.Option {...rest}>
        {name || '-'}
        {region && <RegionTag name={region.name} />}
        {
          categories.map(category => (
            <Tag
              key={categories.id}
              value={category.name}
              style={{ marginLeft: 8 }}
            />
          ))
        }
      </components.Option>
    )
  },
  SingleValue: ({ children, data, ...rest }) => {
    const { name, region } = data

    return (
      <components.SingleValue {...rest}>
        {name || '-'}
        {region && <RegionTag name={region.name} />}
      </components.SingleValue>
    )
  }
}
