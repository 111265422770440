import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'

import { GENDERS } from 'constants/genders'

import './GenderPicker.scss'

export class GenderPicker extends React.Component {
  static propTypes = {
    value: PropTypes.string,

    t: PropTypes.func
  }

  state = {
    genders: []
  }

  componentDidMount() {
    this.setState({
      genders: GENDERS.map((gender) => ({
        label: this.props.t(`genders.${gender}`),
        value: gender
      }))
    })
  }

  render() {
    return this.state.genders.map((gender) => (
      <Button
        key={gender.value}
        style={{ width: '120px', margin: '0 16px' }}
        variant={this.props.value === gender.value ? 'contained' : 'outlined'}
        color='primary'
        onClick={() => this.props.onChange(gender.value)}
      >
        {gender.value}
      </Button>
    ))
  }
}

export default withTranslation('common')(GenderPicker)
