import {
  FETCH_COLLECTION_SUCCEEDED,
  FETCH_CHILDREN_COLLECTION_SUCCEEDED
} from 'constants/firebase/collections'

export const fetchCollectionSucceeded = (snapshot, schema, cacheKey) => ({
  type: FETCH_COLLECTION_SUCCEEDED,
  snapshot,
  schema,
  cacheKey
})

export const fetchChildrenSucceeded = (snapshot, schema, parentSchema, parentId) => ({
  type: FETCH_CHILDREN_COLLECTION_SUCCEEDED,
  snapshot,
  schema,
  parentSchema,
  parentId
})
