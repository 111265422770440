import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import {
  TicketSettingsPane
} from './panes'

export function TicketSettingsPage ({ location, t }) {
  function renderSubNavbar () {
    return (
      <NavigationMenuBar path={location.pathname.split('/', 3).join('/')}>
        <NavigationMenuItem label='Ticket Settings' to='/ticket/settings' />
      </NavigationMenuBar>
    )
  }

  function primaryActions () {
    return [{
      content: t('back_to_tickets'),
      to: '/tickets'
    }]
  }

  return (
    <PageLayout title={t('ticket_title')} primaryActions={primaryActions()} subNavbar={renderSubNavbar()}>
      <Switch>
        <AuthenticatedRoute path='/ticket/settings' component={TicketSettingsPane} exact />
      </Switch>
    </PageLayout>
  )
}

TicketSettingsPage.propTypes = {
  location: PropTypes.object,

  t: PropTypes.func
}

export default withTranslation('settings')(TicketSettingsPage)
