import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Button, Avatar, Typography, Switch } from '@material-ui/core'
import { debounce } from 'lodash'

import { withTranslation } from 'react-i18next'
import SelectInput from 'components/inputs/SelectInput'
import { components } from 'react-select'
import ResourceSelect from 'components/inputs/ResourceSelect'
import { bankAccountSchema } from '@seekster/schemas'

import DateTimeInputField from 'components/fields/DateTimeInputField'
import { Field, LocalizedField } from 'components/fields/Field'
import DateTimePicker from 'components/inputs/DateTimePicker'
import DropzoneInput from 'components/inputs/DropzoneInput'

import styles from './WalletTransactionFormStyles'

export class WalletTransactionForm extends React.Component {
  static propTypes = {
    resource: ImmutablePropTypes.map,
    onCancel: PropTypes.func,
    classes: PropTypes.object,
    values: PropTypes.object,
    formState: PropTypes.object,
    errors: PropTypes.object,
    setFormState: PropTypes.func,
    fetchJobs: PropTypes.func,
    fetchCodes: PropTypes.func,
    match: PropTypes.object,
    isProvider: PropTypes.bool,

    t: PropTypes.func
  }

  static defaultProps = {
    resource: Map()
  }

  constructor(props) {
    super(props)

    this.handleSetTransactionRelease = this.handleSetTransactionRelease.bind(this)
  }

  state = {
    search: '',
    jobOptions: [],
    codeOptions: [],
    loading: false
  }

  componentDidMount() {
    if (this.props.isProvider) {
      this.getJobs()
      this.getTransactionCode()
    }
  }

  componentDidUpdate(_, prevState) {
    if (
      this.state.search !== prevState.search &&
      (this.state.search.length > 2 || this.state.search.length === 0)
    ) {
      this.getJobs()
    }
  }

  getJobs = () => {
    const {
      match: {
        params: { id }
      },
      fetchJobs
    } = this.props
    this.setState({ loading: true })
    fetchJobs(id, { search: this.state.search })
      .then((res) => {
        const jobOptions = res.body.map((job) => ({ label: job.number, value: job.id }))
        this.setState({ jobOptions })
      })
      .catch((e) => {
        console.warn(e)
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  getTransactionCode = () => {
    const { fetchCodes, values } = this.props
    const type = values.type === 'Wallet::Payment' ? 'payment' : 'deposit'
    fetchCodes({
      queryParam: {
        query: { type_eq: type }
      }
    }).then((res) => {
      const codeOptions = res.body.map((code) => ({ label: code.code, value: code.id }))
      this.setState({ codeOptions })
    })
  }

  handleSetTransactionRelease() {
    this.props.setFormState({ processed: !this.props.formState.processed })
  }

  renderApprovalStatus() {
    if (this.props.formState.processed) {
      return this.props.t('transactions.processed')
    } else {
      return (
        <>
          {this.props.t('transactions.holding')}
          <Field name='released_at' component={DateTimePicker} />
        </>
      )
    }
  }

  handleInputChange = (value) => {
    this.handleSearch(value)
  }

  handleSearch = debounce((value) => this.setState({ search: value }), 500)

  render() {
    const { resource, onCancel, classes, formState, values, errors, t } = this.props
    const isValid = JSON.stringify(errors) === '{}'

    return (
      <Form>
        <Grid container spacing={3}>
          {values.type === 'Wallet::Deposit' && (
            <Grid item xs={12}>
              <Field
                name='transaction_type'
                isField
                options={[
                  { label: 'Topup', value: 'top_up' },
                  { label: 'Bank Transfer', value: 'bank_transfer' }
                ]}
                required
                component={SelectInput}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Field
              name='amount_satangs'
              type='number'
              step={0.01}
              hint={resource.getIn(['wallet', 'available_balance', 'currency'])}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              name='fee_satangs'
              type='number'
              step={0.01}
              hint={resource.getIn(['wallet', 'available_balance', 'currency'])}
            />
          </Grid>

          {this.props.isProvider && (
            <>
              <Grid item xs={12}>
                <Field
                  name='job_id'
                  isField
                  options={this.state.jobOptions}
                  onInputChange={this.handleInputChange}
                  component={SelectInput}
                  isLoading={this.state.loading}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name='transaction_code_id'
                  isField
                  options={this.state.codeOptions}
                  component={SelectInput}
                  // isLoading={this.state.loading}
                />
              </Grid>
            </>
          )}

          {values.type === 'Wallet::Deposit' && (
            <>
              {values.transaction_type === 'bank_transfer' && (
                <Grid item xs={12}>
                  <Field
                    name='bank_account_id'
                    component={ResourceSelect}
                    schema={bankAccountSchema}
                    getOptionLabel={({ account_name: accountName }) => accountName}
                    components={{
                      Option: (props) => (
                        <components.Option {...props}>
                          <Grid container spacing={1}>
                            <Grid item>
                              <Avatar
                                src={props.data.bank.logo_url}
                                alt={props.data.bank.name}
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant='subtitle1'>
                                {props.data.account_name}
                              </Typography>
                              <Typography variant='caption'>
                                {props.data.formatted_account_number}
                              </Typography>
                            </Grid>
                          </Grid>
                        </components.Option>
                      )
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Field
                  name='image'
                  component={DropzoneInput}
                  width={300}
                  height={200}
                  required={values.transaction_type === 'bank_transfer'}
                />
              </Grid>

              {values.image && (
                <Grid item xs={12}>
                  <DateTimeInputField
                    name='captured_at'
                    required
                    hint='Example: 18/01/2021 22:47'
                  />
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12}>
            <LocalizedField
              name='description'
              render={({ field }) => <textarea {...field} key={field.name} />}
            />
          </Grid>

          <Grid item xs={12}>
            <Switch
              checked={formState.processed}
              onChange={this.handleSetTransactionRelease}
            />
            {this.renderApprovalStatus()}
          </Grid>

          <Grid item xs={12}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={!isValid}
              className={classes.button}
            >
              {t('save')}
            </Button>
            <Button variant='contained' onClick={onCancel} className={classes.button}>
              {t('cancel')}
            </Button>
          </Grid>
        </Grid>
      </Form>
    )
  }
}

export default compose(
  withStyles(styles),
  withTranslation(['wallets', 'actions'])
)(WalletTransactionForm)
