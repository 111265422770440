import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import LazyLoad from 'react-lazyload'
import { Map, List } from 'immutable'
import { Link } from 'react-router-dom'
import { Table, Column } from 'components/tables/Table'
import { Paper, Avatar } from '@material-ui/core'

import { DateTag } from 'components/tags'

export function CustomersTable({ customers, contact_person }) {
  return (
    <Paper>
      <Table collection={customers}>
        <Column
          name='display_name'
          render={(dispalyName, customer) => (
            <>
              <Link to={`/customers/${customer.get('id')}`}>
                <LazyLoad height={25}>
                  <Avatar
                    style={{
                      marginRight: 4,
                      verticalAlign: 'middle',
                      display: 'inline-block'
                    }}
                    src={customer.get('avatar_url')}
                  />
                </LazyLoad>
                <span>
                  {customer.get('display_name')}
                  {/* {
                    contact_person.get('id') === customer.get('id') &&
                      <Popup
                        trigger={<Icon name="star" color="yellow" />}
                        content='Contact Person'
                      />
                  } */}
                </span>
              </Link>

              {customer.get('alias') ? (
                <Link to={`/customers/${customer.get('id')}`}>
                  ({customer.get('alias')})
                </Link>
              ) : null}
            </>
          )}
        />
        <Column
          name='customer_type.name'
          render={(param, customers) => (
            <span>{customers.getIn(['customer_type', 'name']) || '-'}</span>
          )}
        />
        <Column name='email' />
        <Column name='phone_number' />
        <Column
          name='tags'
          render={(tags) => (
            <ul>
              {tags.map((tag) => (
                <li key={tag.get('id')}>{tag.get('name')}</li>
              ))}
            </ul>
          )}
        />
        <Column name='created_at' component={DateTag} />
      </Table>
    </Paper>
  )
}

CustomersTable.propTypes = {
  customers: ImmutablePropTypes.list,
  contact_person: ImmutablePropTypes.map
}

CustomersTable.defaultProps = {
  customers: List(),
  contact_person: Map()
}

export default CustomersTable
