import { Map, List } from 'immutable'
import { normalize, denormalize } from 'normalizr'

import { UPDATE_ENTITY, UPDATE_ENTITIES } from 'constants/entities'

import {
  FETCH_COLLECTION_SUCCEEDED,
  FETCH_CHILDREN_COLLECTION_SUCCEEDED,
  CREATE_COLLECTION_SUCCEEDED,
  FETCH_NODE_COLLECTION_SUCCEEDED
} from 'constants/collections'

import { FETCH_RESOURCE_SUCCEEDED as FIREBASE_FETCH_RESOURCE_SUCCEEDED } from 'constants/firebase/resources'

import {
  FETCH_COLLECTION_SUCCEEDED as FIREBASE_FETCH_COLLECTION_SUCCEEDED,
  FETCH_CHILDREN_COLLECTION_SUCCEEDED as FIREBASE_FETCH_CHILDREN_COLLECTION_SUCCEEDED
} from 'constants/firebase/collections'

import {
  CREATE_RESOURCE_SUCCEEDED,
  FETCH_RESOURCE_SUCCEEDED,
  FETCH_CHILD_RESOURCE_SUCCEEDED,
  FETCH_CHILDREN_RESOURCE_SUCCEEDED,
  FETCH_CHILD_RESOURCE_WITH_CHILD_ID_SUCCEEDED,
  UPDATE_RESOURCE_SUCCEEDED,
  UPDATE_CHILD_RESOURCE_SUCCEEDED,
  DESTROY_RESOURCE_SUCCEEDED,
  DESTROY_CHILD_RESOURCE_SUCCEEDED,
  TRIGGER_RESOURCE_EVENT_SUCCEEDED
} from 'constants/resources'

import {
  SIGN_IN_SUCCEEDED,
  SIGN_OUT,
  FETCH_CURRENT_DEVICE_SUCCEEDED,
  FETCH_CURRENT_SUCCEEDED,
  UPDATE_CURRENT_SUCCEEDED,
  DESTROY_CURRENT_SUCCEEDED,
  CREATE_CURRENT_SUCCEEDED
} from 'constants/authentication'

import { SEND_INVITATION_SUCCEEDED } from 'constants/invitations'

import { userSchema, deviceSchema } from '@seekster/schemas'

export { normalize, denormalize }

function mergeStrategy(oldEntity, newEntity) {
  return Map.isMap(oldEntity) ? oldEntity.mergeWith(mergeStrategy, newEntity) : newEntity
}

const initialState = Map()

const entitiesReducer = (
  state = initialState,
  {
    type,
    schema,
    payload,
    snapshot,
    response,
    id,
    childrenSchema,
    parentSchema,
    parentId
  }
) => {
  let normalizedData

  switch (type) {
    case UPDATE_ENTITIES:
      return state.mergeWith(mergeStrategy, payload)

    case UPDATE_ENTITY:
      return state.updateIn([payload.schema.key, payload.id.toString()], (item) =>
        payload.func(item || Map())
      )

    case SIGN_IN_SUCCEEDED: {
      const normalizedUser = normalize(response.body.user, userSchema)

      return state.mergeWith(mergeStrategy, normalizedUser.entities)
    }

    case FETCH_COLLECTION_SUCCEEDED:
    case FETCH_NODE_COLLECTION_SUCCEEDED:
    case CREATE_COLLECTION_SUCCEEDED: {
      const normalizedCollection = normalize(response.body, [schema])

      return state.mergeWith(mergeStrategy, normalizedCollection.entities)
    }

    case FIREBASE_FETCH_COLLECTION_SUCCEEDED:
    case FIREBASE_FETCH_CHILDREN_COLLECTION_SUCCEEDED: {
      const collectionDocs = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))

      normalizedData = parentSchema
        ? normalize(
            {
              id: parentId,
              [schema._key]: collectionDocs
            },
            parentSchema
          )
        : normalize(collectionDocs, [schema])

      return state.mergeWith(mergeStrategy, normalizedData.entities)
    }

    case FETCH_CHILDREN_COLLECTION_SUCCEEDED: {
      const normalizedChildren = normalize(response.body, [childrenSchema])

      return state
        .mergeWith(mergeStrategy, normalizedChildren.entities)
        .updateIn([schema._key, id.toString()], (item) => {
          const newItem = item || Map({ id })

          return newItem.set(childrenSchema._key, List(normalizedChildren.result))
        })
    }

    case FIREBASE_FETCH_RESOURCE_SUCCEEDED: {
      const resourceDoc = { id: snapshot.id, ...snapshot.data() }
      const normalizedResourceDoc = normalize(resourceDoc, schema)

      return state.mergeWith(mergeStrategy, normalizedResourceDoc.entities)
    }

    case SEND_INVITATION_SUCCEEDED:
      var normalizedChild = normalize(response.body, childrenSchema)

      return state
        .mergeWith(mergeStrategy, normalizedChild.entities)
        .updateIn([schema._key, id.toString()], (item) => {
          const newItem = item || Map({ id })

          return newItem.update(childrenSchema._key, (list) =>
            list ? list.unshift(normalizedChild.result) : List([normalizedChild.result])
          )
        })

    case CREATE_RESOURCE_SUCCEEDED:
    case FETCH_RESOURCE_SUCCEEDED:
    case UPDATE_RESOURCE_SUCCEEDED:
    case DESTROY_RESOURCE_SUCCEEDED:
    case TRIGGER_RESOURCE_EVENT_SUCCEEDED:
    case FETCH_CURRENT_SUCCEEDED:
    case UPDATE_CURRENT_SUCCEEDED:
    case CREATE_CURRENT_SUCCEEDED: {
      const normalizedResource = normalize(response.body, schema)

      return state.mergeWith(mergeStrategy, normalizedResource.entities)
    }

    case FETCH_CHILDREN_RESOURCE_SUCCEEDED:
    case FETCH_CHILD_RESOURCE_SUCCEEDED:
    case FETCH_CHILD_RESOURCE_WITH_CHILD_ID_SUCCEEDED:
    case UPDATE_CHILD_RESOURCE_SUCCEEDED:
    case DESTROY_CHILD_RESOURCE_SUCCEEDED: {
      const normalizedResourceChildren = normalize(response.body || {}, childrenSchema)

      return state
        .mergeWith(mergeStrategy, normalizedResourceChildren.entities)
        .updateIn([schema._key, id && id.toString()], (item) => {
          const newItem = item || Map({ [schema._idAttribute]: id })
          const itemKey = childrenSchema._key.split('/').pop()

          return newItem.set(itemKey, normalizedResourceChildren.result)
        })
    }

    case FETCH_CURRENT_DEVICE_SUCCEEDED: {
      const normalizedDevice = normalize(response.body, deviceSchema)

      return state.mergeWith(mergeStrategy, normalizedDevice.entities)
    }

    case DESTROY_CURRENT_SUCCEEDED:
      return state.delete(schema._key)

    case SIGN_OUT:
      return state.clear()

    default:
      return state
  }
}

export default entitiesReducer
