import React from 'react'
import PropTypes from 'prop-types'
import { Select, InputBase, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'
import { regionSchema } from '@seekster/schemas'

import withLocalizedFields from 'hoc/withLocalizedFields'
import PageLayout from 'components/layouts/PageLayout'
import RegionForm from 'forms/resources/RegionForm'

export function RegionEditPage ({ match, locale, setLocale }) {
  const { t } = useTranslation(['regions'])
  const [region, { loading }] = useResource(regionSchema, match.params.id)
  const { update: updateRegion } = useResourceAction(regionSchema)
  const push = usePush()

  function handleSubmit (data) {
    return updateRegion(match.params.id, data)
  }

  function actions () {
    return [
      <Select
        key='1'
        value={locale}
        onChange={(e) => setLocale(e.target.value)}
        input={<InputBase />}
      >
        <MenuItem value='en'><span role='img' aria-label='GB flag'>🇬🇧 </span>English</MenuItem>
        <MenuItem value='th'><span role='img' aria-label='Thai flag'>🇹🇭 </span>ภาษาไทย</MenuItem>
      </Select>
    ]
  }

  return (
    <PageLayout
      title={t('edit')}
      isLoading={loading}
      secondaryActions={actions()}
      back={`/regions/${match.params.id}`}
    >
      <RegionForm
        region={region}
        push={push}
        onSubmit={handleSubmit}
      />
    </PageLayout>
  )
}

RegionEditPage.propTypes = {
  match: PropTypes.object,
  locale: PropTypes.string,
  setLocale: PropTypes.func
}

export default withLocalizedFields(RegionEditPage)
