import React, { useState, useEffect } from 'react'
import { fromJS, Map } from 'immutable'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { usePush } from 'hooks/utils'

import Panel from 'components/containers/Panel'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useResourceAction } from 'hooks/resources'
import PayrollsCycleTable from 'components/tables/PayrollsCycleTable'
import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'
import { getCurrentResource } from 'selectors/authentication'

import { manualPayrollIntegrationSchema, tenantSchema } from '@seekster/schemas'

const useStyles = makeStyles(() => ({
  panelShadow: {
    boxShadow: '0 2px 10px 0 rgba(182,182,182,0.4)'
  },
  button: {
    margin: 18,
    padding: '10px 16px'
  }
}))

export function PayrollsCyclePane () {
  const [payrollSetting, setPayrollSetting] = useState(Map())
  const classes = useStyles()
  const push = usePush()
  const { t } = useTranslation('payrolls')
  const { fetch: fetchPayrollSetting } = useResourceAction(manualPayrollIntegrationSchema)
  const tenant = useSelector(state => getCurrentResource(state, tenantSchema)) || Map()

  useEffect(() => {
    fetchPayrollSetting('').then(response => setPayrollSetting(fromJS(response.body)))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function actions () {
    return (
      <Button
        color='primary'
        variant='contained'
        className={classes.button}
        onClick={() => push('/payrolls/new')}
      >
        {t('edit_remark')}
      </Button>
    )
  }

  return (
    <>
      <div style={{ padding: 24 }}>
        <div className={classes.panelShadow}>
          <Panel
            title={t('your_payroll_cycle')}
            headerStyle={{ height: 80, maxHeight: 80 }}
          >
            <PayrollsCycleTable payrolls={payrollSetting.get('payout_cycles')} />
          </Panel>
        </div>
      </div>

      {
        tenant.get('slug') !== 'seekster' && (
          <div style={{ padding: 24 }}>
            <div className={classes.panelShadow}>
              <Panel
                title={t('remark')}
                headerStyle={{ height: 80, maxHeight: 80 }}
                actions={actions()}
              >
                <AttributesTable resource={payrollSetting}>
                  <Attribute name='fee_field' />
                  <Attribute name='remark_field' />
                </AttributesTable>
              </Panel>
            </div>
          </div>
        )
      }
    </>
  )
}

export default PayrollsCyclePane
