import { connect } from 'react-redux'

import { fetch } from 'actions/resources'
import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { companySchema, subscriptionSchema } from '@seekster/schemas'

import CompanySubscriptionsPane from './CompanySubscriptionsPane'

const mapStateToProps = (state, { match }) => ({
  company: getResource(state, match.params.id, companySchema)
})

const mapDispatchToProps = {
  loadCompany: (id, options) => fetch(id, companySchema, options),
  loaadCompanySubscriptions: (id, options) => fetchChildren(companySchema, id, subscriptionSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanySubscriptionsPane)
