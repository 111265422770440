import React from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Select, InputBase, MenuItem } from '@material-ui/core'

import withLocalizedFields from 'hoc/withLocalizedFields'
import PageLayout from 'components/layouts/PageLayout'
import RegionForm from 'forms/resources/RegionForm'

export function RegionNewPage ({
  createRegion,
  push,
  locale,
  setLocale,
  t
}) {
  function actions () {
    return [
      <Select
        key='1'
        value={locale}
        onChange={(e) => setLocale(e.target.value)}
        input={<InputBase />}
      >
        <MenuItem value='en'><span role='img' aria-label='GB flag'>🇬🇧 </span>English</MenuItem>
        <MenuItem value='th'><span role='img' aria-label='Thai flag'>🇹🇭 </span>ภาษาไทย</MenuItem>
      </Select>
    ]
  }

  return (
    <PageLayout
      title={t('new')}
      secondaryActions={actions()}
      back='/regions'
    >
      <RegionForm
        push={push}
        onSubmit={createRegion}
      />
    </PageLayout>
  )
}

export default compose(
  withLocalizedFields,
  withTranslation('regions')
)(RegionNewPage)
