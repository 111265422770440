import { Map } from 'immutable'
import { withFormik } from 'formik'
import pluralize from 'pluralize'
import { upperFirst, camelCase } from 'lodash'
import WebhookForm from './WebhookForm'

const formikConfig = {
  mapPropsToValues: ({ webhook = Map() }) => ({
    name: webhook.get('name'),
    source_type: upperFirst(
      camelCase(pluralize.singular(webhook.getIn(['source', 'type'], '')))
    ),
    webhook_url: webhook.get('webhook_url'),
    source_id: webhook.getIn(['source', 'id']),
    client_id:
      webhook.getIn(['source', 'type']) === 'clients' && webhook.getIn(['source', 'id']),
    service_id:
      webhook.getIn(['source', 'type']) === 'services' && webhook.getIn(['source', 'id']),
    customer_id:
      webhook.getIn(['source', 'type']) === 'customers' && webhook.getIn(['source', 'id'])
  }),

  enableReinitialize: true,

  handleSubmit: (values, { props, setSubmitting }) => {
    let newValue = values

    if (values.source_type === 'Client') {
      newValue = {
        ...newValue,
        source_id: values.client_id
      }
    } else if (values.source_type === 'Service') {
      newValue = {
        ...newValue,
        source_id: values.service_id
      }
    } else if (values.source_type === 'Customer') {
      newValue = {
        ...newValue,
        source_id: values.customer_id
      }
    }

    props
      .onSubmit(newValue)
      .then(({ body }) => {
        props.push(`/webhooks/${body.id}`)
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.body.message)
        } else if (error.message) {
          alert(error.message)
        }

        setSubmitting(false)
      })
  }
}

export default withFormik(formikConfig)(WebhookForm)
