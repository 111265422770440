import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import PageContent, { PageSection } from 'components/layouts/PageContent'

import Panel from 'components/containers/Panel'
import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'
import {
  MailFormatter,
  PhoneNumberFormatter,
  DateTimeFormatter
} from 'components/formatters'

export function CompanyOverviewPane({ company, t }) {
  return (
    <PageContent>
      <PageSection position='center' borderRight>
        <Panel title={t('details')}>
          <AttributesTable resource={company}>
            <Attribute name='id' />
            <Attribute name='name' />
            <Attribute
              name='company.phone_number'
              label={t('phone_number')}
              component={PhoneNumberFormatter}
            />
            <Attribute name='tax_number' />
            <Attribute
              name='contact_person'
              render={(contactPerson) =>
                contactPerson && (
                  <p>
                    <Link to={`/${contactPerson.get('type')}/${contactPerson.get('id')}`}>
                      {contactPerson.get('display_name')}
                    </Link>{' '}
                    &nbsp; (
                    <PhoneNumberFormatter value={contactPerson.get('phone_number')} />)
                  </p>
                )
              }
            />
            <Attribute
              name='company_type'
              render={(company) => <p>{company ? company.get('name') : '-'}</p>}
            />
            <Attribute name='responsible_by.display_name' />
            <Attribute
              name='support_by_id'
              render={() => <p>{company.getIn(['support_by', 'display_name'])}</p>}
            />
            <Attribute name='created_at' component={DateTimeFormatter} />
            <Attribute name='updated_at' component={DateTimeFormatter} />
          </AttributesTable>
        </Panel>
      </PageSection>

      <PageSection position='right'>
        <Panel title={t('billing_details')}>
          <AttributesTable resource={company}>
            <Attribute name='billing_email' component={MailFormatter} />
            <Attribute
              name='billing_address'
              label={t('billing_address.title')}
              render={(address, company) =>
                address && (
                  <>
                    <p>{address.get('name')}</p>

                    <p>
                      {address.get('house_number') && `${address.get('house_number')} `}
                      {address.get('project_name') && `${address.get('project_name')}, `}
                      {address.get('floor')}
                      <br />

                      {address.get('alley') && `${address.get('alley')}, `}
                      {address.get('street')}
                      <br />

                      {address.get('sub_district') && `${address.get('sub_district')}, `}
                      {address.get('district')}
                      <br />

                      {address.get('province') && `${address.get('province')}, `}
                      {address.get('zip_code')}
                    </p>

                    <p>
                      {t('tax_number')} {company.get('tax_number')}
                    </p>
                  </>
                )
              }
            />
          </AttributesTable>
        </Panel>
      </PageSection>
    </PageContent>
  )
}

CompanyOverviewPane.propTypes = {
  company: ImmutablePropTypes.map,
  t: PropTypes.func
}

CompanyOverviewPane.defaultProps = {
  company: Map()
}

export default withTranslation(['companies', 'common', 'attributes'])(CompanyOverviewPane)
