import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { TableCell } from '@material-ui/core'

function Column (props) {
  const { name, resource, component: Component, render, to, index, ...rest } = props

  function renderValue (resource) {
    const value = resource.getIn(name.split('.'))

    let returnValue = value

    if (Component) {
      returnValue = <Component value={value} />
    }
    else if (render) {
      returnValue = render(value, resource, index)
    }

    if (to) {
      return (
        <Link to={to(resource)}>{returnValue}</Link>
      )
    }
    else {
      return returnValue
    }
  }

  return (
    <TableCell {...rest}>
      {renderValue(resource)}
    </TableCell>
  )
}

Column.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  resource: PropTypes.oneOfType([
    ImmutablePropTypes.map,
    PropTypes.object
  ]),
  component: PropTypes.func,
  render: PropTypes.func,
  to: PropTypes.func
}

export default Column
