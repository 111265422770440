import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'

export class DateTag extends React.Component {
  static propTypes = {
    value: PropTypes.node
  }

  render() {
    if (this.props.value) {
      return (
        <span className='date-tag'>
          {Moment(this.props.value).format('ddd, DD MMM YYYY - HH:mm:ss')}
        </span>
      )
    } else {
      return null
    }
  }
}

export default DateTag
