import { connect } from 'react-redux'

import { create, fetch } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { companySchema } from '@seekster/schemas'

import CompanyNewAddressPage from './CompanyNewAddressPage'

const mapStateToProps = (state, { match }) => ({
  company: getResource(state, match.params.id, companySchema)
})

const mapDispatchToProps = {
  createCompanyAddress: (data, id, options) => create(data, companySchema, { type: `companies/${id}/addresses` }),
  loadCompany: (id, options) => fetch(id, companySchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyNewAddressPage)
