import { connect } from 'react-redux'

import { create, fetch } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { customerSchema } from '@seekster/schemas'

import CustomerNewAddressPage from './CustomerNewAddressPage'

const mapStateToProps = (state, { match }) => ({
  customer: getResource(state, match.params.id, customerSchema)
})

const mapDispatchToProps = {
  createCustomerAddress: (data, id, options) => create(data, customerSchema, { type: `customers/${id}/addresses` }),
  loadCustomer: (id, options) => fetch(id, customerSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerNewAddressPage)
