import React from 'react'
import { Form } from 'formik'
import { Map } from 'immutable'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Button, Avatar, Typography } from '@material-ui/core'
import { components } from 'react-select'
import { useTranslation } from 'react-i18next'
import DropzoneInput from 'components/inputs/DropzoneInput'

import ButtonLoader from 'components/buttons/ButtonLoader'
import Field from 'components/fields/Field'
import ResourceSelect from 'components/inputs/ResourceSelect'
import SwitchInput from 'components/inputs/SwitchInput'
import { bankSchema } from '@seekster/schemas'

import styles from './BankAccountFormStyles'

export function BankAccountForm({ onCancel, isSubmitting, classes, bankAccount }) {
  const { t } = useTranslation('actions')

  return (
    <Form>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            name='bank_id'
            component={ResourceSelect}
            schema={bankSchema}
            components={{
              Option: (props) => (
                <components.Option {...props}>
                  <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                      <Avatar src={props.data.logo_url} alt={props.data.name} />
                    </Grid>
                    <Grid item>
                      <Typography variant='subtitle1'>{props.data.name}</Typography>
                    </Grid>
                  </Grid>
                </components.Option>
              )
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Field name='account_number' />
        </Grid>

        <Grid item xs={12}>
          <Field name='account_name' />
        </Grid>

        <Grid item xs={12}>
          <Field name='description' />
        </Grid>

        <Field
          name='book_bank'
          label='Book Bank Image'
          component={DropzoneInput}
          width={800}
          height={250}
          initialValue={bankAccount.get('book_bank_url')}
        />
        <Grid item xs={12}>
          <Field name='disabled' component={SwitchInput} noLabel />
        </Grid>

        <Grid item xs={12}>
          <ButtonLoader
            type='submit'
            variant='contained'
            color='primary'
            style={{ float: 'right' }}
            className={classes.button}
            isLoading={isSubmitting}
          >
            {t('save')}
          </ButtonLoader>
          <Button variant='contained' onClick={onCancel} className={classes.button}>
            {t('cancel')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}

BankAccountForm.defaultProps = {
  bankAccount: Map()
}

export default withStyles(styles)(BankAccountForm)
