import { withFormik } from 'formik'
import { Map } from 'immutable'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'
import JobForm from './JobForm'

const formikConfig = {
  mapPropsToValues: ({ job = Map() }) => ({
    start_time: job.get('start_time'),
    end_time: job.get('end_time')
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit(values)
      .then(() => {
        setSubmitting(false)
      })
      .catch((error) => {
        setSubmitting(false)
        props.setFlashMessage('error', error.response.body?.message || 'error')
      })
  }
}

export default compose(withFlashMessage, withFormik(formikConfig))(JobForm)
