import React, { useState } from 'react'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { MoreVert, HighlightOffRounded } from '@material-ui/icons'

import { ResponsiveMenu, ResponsiveMenuItem } from 'components/menus/ResponsiveMenu'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  avatar: {
    width: 65,
    height: 65,
    marginTop: spacing(0.5),

    [breakpoints.up('sm')]: {
      width: 50,
      height: 50,
      marginTop: 0
    }
  },

  icon: {
    margin: '0 4px -5px 0'
  },

  state: {
    marginTop: spacing(2),
    display: 'blocked',

    [breakpoints.up('sm')]: {
      marginTop: 0
    }
  }
}))

const OrgAction = ({ orgId, disabled, handleCancelOrg }) => {
  const { t } = useTranslation(['actions', 'jobs'])
  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()

  function handleOpenMenu(e) {
    setMenuOpen(true)

    setAnchorEl(e.currentTarget)
  }

  function handleCloseMenu() {
    setMenuOpen(false)

    setAnchorEl(null)
  }

  return (
    <ResponsiveMenu
      open={menuOpen}
      onClose={handleCloseMenu}
      anchorEl={anchorEl}
      trigger={
        <IconButton
          onClick={handleOpenMenu}
          className={classes.iconButton}
          disabled={disabled}
        >
          <MoreVert fontSize='small' />
        </IconButton>
      }
    >
      <ResponsiveMenuItem
        avatar={<HighlightOffRounded />}
        content={t('cancel')}
        onClick={() => handleCancelOrg(orgId)}
      />
    </ResponsiveMenu>
  )
}

export default OrgAction
