import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { destroy, fetch } from 'actions/resources'
import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { appSchema, commentSchema } from '@seekster/schemas'

import withFlashMessage from 'hoc/withFlashMessage'

import AppShowPage from './AppShowPage'

const mapStateToProps = (state, { match }) => ({
  app: getResource(state, match.params.id, appSchema)
})

const mapDispatchToProps = {
  fetchComments: (id, options) => fetchChildren(appSchema, id, commentSchema, options),
  destroy: (id, options) => destroy(id, appSchema, options),
  fetchApp: (id, options) => fetch(id, appSchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(withFlashMessage(AppShowPage))
