import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import Moment from 'moment'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import CircularLoader from 'components/CircularLoader'
import {
  TextareaAutosize,
  Avatar,
  List as MaterialList,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core'
import { InsertDriveFile } from '@material-ui/icons'
import ButtonLoader from 'components/buttons/ButtonLoader'

import './Comments.scss'

export class Comments extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    comments: ImmutablePropTypes.list,
    loadComments: PropTypes.func,
    create: PropTypes.func,
    t: PropTypes.func
  }

  static defaultProps = {
    comments: List()
  }

  state = {
    content: ''
  }

  componentDidMount() {
    this.props.loadComments(this.props.id)
  }

  postComment() {
    this.props.create({ content: this.state.content }, this.props.id).then(() => {
      this.props.loadComments(this.props.id)
    })
    this.setState({ content: '' })
  }

  handleKeyPress(e) {
    if (e.keyCode === 13 && (e.ctrlKey || e.metaKey)) {
      this.postComment()
    }
  }

  comments() {
    const { t, comments } = this.props

    return (
      <div className='comments'>
        {!comments && <CircularLoader />}

        {comments && !comments.isEmpty() ? (
          <>
            <h3>Comments</h3>
            <hr />

            <MaterialList dense>
              {comments.map((comment, index) => {
                const agent = comment.get('agent')
                const provider = comment.get('provider')
                const resource = comment.get('resource') || Map()

                return (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      {agent && (
                        <Link to={`/agents/${agent.get('id')}`}>
                          <Avatar src={agent.get('avatar_url')} />
                        </Link>
                      )}
                      {provider && (
                        <Link to={`/providers/${provider.get('id')}`}>
                          <Avatar src={provider.get('avatar_url')} />
                        </Link>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          {agent && (
                            <Link to={`/agents/${agent.get('id')}`}>
                              <h4 style={{ display: 'inline' }}>
                                {agent.get('display_name')}
                              </h4>
                            </Link>
                          )}
                          {provider && (
                            <Link to={`/providers/${provider.get('id')}`}>
                              <h4 style={{ display: 'inline' }}>
                                {provider.get('display_name')}
                              </h4>
                            </Link>
                          )}
                          <p style={{ fontSize: 12, display: 'inherit' }}>
                            {' '}
                            {Moment(comment.get('created_at')).format(
                              'DD MMM YYYY - HH:mm'
                            )}
                          </p>
                        </>
                      }
                      secondary={`${comment.get('content')}`}
                    />

                    <Link to={`/${resource.get('type')}/${resource.get('id')}`}>
                      <InsertDriveFile fontSize='small' />
                      {t(`link_to.${resource.get('type')}`)}
                    </Link>
                  </ListItem>
                )
              })}
            </MaterialList>
          </>
        ) : (
          <p>{t('no_comments')}</p>
        )}
      </div>
    )
  }

  render() {
    const { t } = this.props

    return (
      <div style={{ padding: 16, maxWidth: 700 }}>
        <TextareaAutosize
          onChange={(e) => this.setState({ content: e.target.value })}
          value={this.state.content}
          rowsMin={2}
        />
        <ButtonLoader
          variant='contained'
          color='secondary'
          onClick={(e) => this.postComment()}
        >
          {t('add_comment')}
        </ButtonLoader>
        {this.comments()}
      </div>
    )
  }
}

export default withTranslation('comments')(Comments)
