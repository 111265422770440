import React from 'react'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'

import Field from 'components/fields/Field'

export function DateTimeInputSelectField({
  name,
  placeholder,
  hint,
  required,
  label,
  t,
  tReady,
  i18n,
  ...rest
}) {
  return (
    <Field
      name={name}
      render={({ field, form }) => (
        <TextField
          fullWidth
          color='primary'
          variant='outlined'
          type='datetime-local'
          defaultValue={moment(field.value).format('YYYY-MM-DDTHH:mm')}
          onChange={(e) => {
            if (
              moment(e.target.value, 'YYYY-MM-DDTHH:mm').isValid(true) &&
              e.target.value.toString().length === 16
            ) {
              form.setFieldValue(
                name,
                moment(e.target.value, 'YYYY-MM-DDTHH:mm').toISOString(true)
              )
            }
          }}
          inputProps={{ ...rest, field, form }}
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
      required={required}
      hint={hint || 'Example: 18/01/2019 22:47'}
    />
  )
}

export default withTranslation(['fields', 'attributes'])(DateTimeInputSelectField)
