import React from 'react'
import { useTranslation } from 'react-i18next'
import { useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'

import PageLayout from 'components/layouts/PageLayout'
import WebhookForm from 'forms/resources/WebhookForm'
import { webhookHandlerSchema } from '@seekster/schemas'

export function WebhookNewPage () {
  const { t } = useTranslation('settings')
  const { create: createDepartment } = useResourceAction(webhookHandlerSchema)
  const push = usePush()

  return (
    <PageLayout
      title={t('webhook.new')}
      back='/settings/webhook_settings'
    >
      <WebhookForm
        onSubmit={createDepartment}
        push={push}
      />
    </PageLayout>
  )
}

export default WebhookNewPage
