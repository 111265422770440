import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

import './GoogleMapInput.scss'

const googleMapsSrc = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places,drawing,geometry`
const markerIcon = 'https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/PinRedX3.png'

export class GoogleMapInput extends React.PureComponent {
  static propTypes = {
    value: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number
    }),
    onChange: PropTypes.func,

    zoom: PropTypes.number,
    withoutMarker: PropTypes.bool
  }

  static defaultProps = {
    zoom: 15,
    value: {}
  }

  state = {
    loading: false
  }

  constructor (props) {
    super(props)

    this.mapRef = React.createRef()
    this.inputRef = React.createRef()
    this.inputContainerRef = React.createRef()
  }

  componentDidMount () {
    if (window.google && window.google.maps) {
      this.setupGoogleMap()
    }
    else {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = googleMapsSrc
      script.onload = () => this.setupGoogleMap()
      document.head.appendChild(script)
    }
  }

  componentDidUpdate () {
    const { latitude, longitude } = this.props.value

    if (this.map && latitude && longitude) {
      this.map.setCenter({ lat: latitude, lng: longitude })
    }
  }

  handleChange (location, place) {
    const { value, onChange } = this.props

    onChange && onChange({ ...value, latitude: location.lat(), longitude: location.lng() }, place, place.name)
  }

  setupGoogleMap () {
    const { latitude = 13.7563, longitude = 100.5018 } = this.props.value

    this.setState({ loading: true })

    const geocoder = new window.google.maps.Geocoder()

    const map = this.map = new window.google.maps.Map(this.mapRef.current, {
      center: { lat: latitude, lng: longitude },
      zoom: this.props.zoom,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false
    })

    map.addListener('dragend', () => {
      const center = map.getCenter()

      geocoder.geocode({ location: center }, (results, status) => {
        status === 'OK' && this.handleChange(center, results[0])
      })
    })

    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(this.inputContainerRef.current)

    let autocomplete = new window.google.maps.places.Autocomplete(this.inputRef.current)

    autocomplete.bindTo('bounds', map)

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      const { location } = place.geometry

      if (!place.geometry) {
        window.alert('No details available for input: "" + place.name + ""')
        return
      }

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport)
      }
      else {
        map.setCenter(location)
      }

      this.handleChange(location, place)
    })

    this.setState({ loading: false })
  }

  preventSubmitOnEnter (e) {
    if (e.keyCode === 13) {
      e.preventDefault()
      return false
    }
  }

  render () {
    return (
      <div className='google-map-container'>
        <div className='google-map' ref={this.mapRef} />

        {!this.props.withoutMarker && <img src={markerIcon} alt='marker icon' className='google-map-marker' />}

        <div className={classNames(['google-map-places-container', { hidden: this.state.loading }])} ref={this.inputContainerRef}>
          <input
            className='google-map-places-input'
            placeholder='Search...'
            ref={this.inputRef}
            onKeyPress={this.preventSubmitOnEnter}
            onKeyDown={this.preventSubmitOnEnter}
          />
        </div>
      </div>
    )
  }
}

export default GoogleMapInput
