import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { firebaseSupportPlatform } from 'utils/firebase'

import {
  fetchCustomToken
} from 'actions/authentication'

const auth = firebaseSupportPlatform.auth()

export function useCustomToken (effectDependencies = []) {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    let isMounted = true
    setLoading(true)

    auth.onAuthStateChanged(async (user) => {
      if (user) {
        isMounted && setLoading(false)
      }
      else {
        dispatch(fetchCustomToken())
          .then(async (response) => {
            try {
              await auth.signInWithCustomToken(response.body.firebaseToken)
              isMounted && setLoading(false)
            }
            catch (error) {
              if (isMounted) {
                setError(error)
                setLoading(false)
              }
            }
          })
          .catch(error => {
            if (isMounted) {
              setError(error)
              setLoading(false)
            }
          })
      }
    })
    return () => (isMounted = false)
  }, [...effectDependencies]) // eslint-disable-line react-hooks/exhaustive-deps

  return [{ loading, error }]
}
