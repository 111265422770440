import React from 'react'
import PropTypes from 'prop-types'
import { isPossiblePhoneNumber } from 'libphonenumber-js'

export function PhoneNumberFormatter({ value, ...rest }) {
  if (value && isPossiblePhoneNumber(value, 'TH')) {
    return (
      <a href={`tel:${value}`} {...rest}>
        {value}
      </a>
    )
  } else {
    return value || '-'
  }
}

PhoneNumberFormatter.propTypes = {
  value: PropTypes.string
}

export default PhoneNumberFormatter
