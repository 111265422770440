import React from 'react'
import { useResource } from 'hooks/resources'

import ServicesTable from 'components/tables/ServicesTable'
import Panel from 'components/containers/Panel'

import { categorySchema } from '@seekster/schemas'

export function CategoryServicesPane ({ match }) {
  const [category, { loading }] = useResource(categorySchema, match.params.id)

  return (
    <Panel title='Services'>
      <ServicesTable category={category} loading={loading} services={category.get('services')} />
    </Panel>
  )
}

export default CategoryServicesPane
