import React, { useEffect, useState } from 'react'
import { Grid, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useCollection } from 'hooks/collections'
import { useResourceAction } from 'hooks/resources'
import Panel from 'components/containers/Panel'
import { getCurrentResource } from 'selectors/authentication'
import TenantSMSRatingConfigForm from 'forms/actions/TenantSMSRatingConfigForm'
import Modal from 'components/containers/Modal'
import CheckCircle from '@material-ui/icons/CheckCircle'

import { ratingLinkConfigurationSchema, tenantSchema } from '@seekster/schemas'

export function SMSRatingSettingsPane({ fetchSMSRatingConfig, t }) {
  const [openModal, setOpenModal] = useState(false)
  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()
  const [ratingLinkConfig] = useCollection(ratingLinkConfigurationSchema)
  const { create: createSMSRatingConfig, update: updateSMSRatingConfig } =
    useResourceAction(ratingLinkConfigurationSchema)

  useEffect(() => {
    fetchSMSRatingConfig()
  }, [fetchSMSRatingConfig])

  function handleUpdateSMSRating(data) {
    return updateSMSRatingConfig('', data).then(() => {
      setOpenModal(true)
      fetchSMSRatingConfig()
    })
  }

  function handleCreateSMSRating(data) {
    return createSMSRatingConfig(data).then(() => {
      setOpenModal(true)
      fetchSMSRatingConfig()
    })
  }

  return (
    <Grid container justify='center'>
      <Modal
        isOpen={openModal}
        onCloseModal={() => setOpenModal(false)}
      >
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircle style={{ marginRight: 14, color: '#21ba45' }} />
          <div style={{ display: 'inline', color: '#21ba45' }}>{t('sms_rating_config.setup_completed')}</div>
        </Box>
      </Modal>
      <Grid item xs={12} sm={9}>
        <Panel title={t('sms_rating_config.title')}>
          <TenantSMSRatingConfigForm
            smsRatingConfig={tenant.get('rating_link_configuration')}
            openModal={openModal}
            setOpenModal={setOpenModal}
            onSubmit={
              ratingLinkConfig.isEmpty() ? handleCreateSMSRating : handleUpdateSMSRating
            }
          />
        </Panel>
      </Grid>
    </Grid>
  )
}

SMSRatingSettingsPane.propTypes = {
  fetchSMSRatingConfig: PropTypes.func,

  t: PropTypes.func
}

export default withTranslation('settings')(SMSRatingSettingsPane)