import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import { Grid } from '@material-ui/core'

import EventTimeline from 'modules/events/EventTimeline'

export function ProviderTimelinePane ({
  provider,
  fetchProviderEvent,
  match
}) {
  useEffect(() => {
    fetchProviderEvent(match.params.id)
  }, [match.params.id, fetchProviderEvent])

  const events = provider.get('events') || List()

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <EventTimeline events={events} />
      </Grid>
    </Grid>
  )
}

ProviderTimelinePane.propTypes = {
  provider: ImmutablePropTypes.map,
  fetchProviderEvent: PropTypes.func,
  match: PropTypes.object
}

ProviderTimelinePane.defaultProps = {
  provider: Map()
}

export default ProviderTimelinePane
