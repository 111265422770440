import { withFormik } from 'formik'
import { pick, omit } from 'lodash'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'
import withFormState from 'hoc/withFormState'
import keyIn from 'utils/keyIn'

import InquiryForm from './InquiryForm'

const formikConfig = {
  mapPropsToValues: ({ inquiry, withoutAssociationIds, withoutJobs }) => {
    return !inquiry
      ? {}
      : {
          customer_id: inquiry.getIn(['customer', 'id']),
          branch_id: inquiry.getIn(['branch', 'id']),
          company_id: inquiry.getIn(['company', 'id']),
          route: inquiry.get('route') && inquiry.get('route').toJS(),
          route_id: inquiry.getIn(['route', 'id']),
          service_id: inquiry.getIn(['service', 'id']) || inquiry.get('service_id'),
          additional_information: inquiry.get('additional_information'),
          attachments_attributes: inquiry
            .get('attachments')
            .toSeq()
            .map((detail) =>
              detail
                .filter(
                  keyIn(
                    !withoutAssociationIds && 'id',
                    'type',
                    'attachment_type',
                    'created_at'
                  )
                )
                .merge({
                  remote_file_url: detail.get('file_url')
                })
            )
            .toJS(),

          remarks_for_provider: inquiry.get('remarks_for_provider'),

          jobs_attributes: !withoutJobs
            ? inquiry
                .get('jobs')
                .toSeq()
                .map((job) => job.filter(keyIn('id', 'start_time', 'end_time')))
                .toJS()
            : undefined,

          answers_attributes: inquiry
            .get('answers')
            .toSeq()
            .map((answer) =>
              answer.merge({
                question_id: answer.getIn(['question', 'id']),
                choice_id: answer.getIn(['choice', 'id']),
                choice_ids: answer.get('choices').map((choice) => choice.get('id')),
                image: answer.get('image_url'),
                attachments_attributes: answer.get('attachments')
              })
            )
            .map((answer) =>
              answer.filter(
                keyIn(
                  !withoutAssociationIds && 'id',
                  'value',
                  'image',
                  'attachments_attributes',
                  'question_id',
                  'choice_id',
                  'choice_ids'
                )
              )
            )
            .toJS(),

          orders_attributes: inquiry
            .get('orders')
            .toSeq()
            .map((order) =>
              order.merge({
                package_id: order.getIn(['package', 'id'])
              })
            )
            .map((order) =>
              order.filter(
                keyIn(!withoutAssociationIds && 'id', 'quantity', 'package_id')
              )
            )
            .toJS(),

          location_attributes:
            !inquiry.get('has_logistics') &&
            inquiry
              .get('location')
              .filter(
                keyIn(
                  'contact_name',
                  'country',
                  'district',
                  'floor',
                  'house_number',
                  'landmark',
                  'latitude',
                  'longitude',
                  'name',
                  'phone_number',
                  'extension_number',
                  'project_name',
                  'province',
                  'street',
                  'sub_district',
                  'zip_code'
                )
              )
              .toJS(),

          origin_attributes:
            inquiry.get('has_logistics') &&
            inquiry
              .getIn(['route', 'origin'])
              .filter(
                keyIn(
                  'contact_name',
                  'country',
                  'district',
                  'floor',
                  'house_number',
                  'landmark',
                  'latitude',
                  'longitude',
                  'name',
                  'phone_number',
                  'project_name',
                  'province',
                  'street',
                  'sub_district',
                  'zip_code'
                )
              )
              .toJS(),

          destination_attributes:
            inquiry.get('has_logistics') &&
            inquiry
              .getIn(['route', 'destination'])
              .filter(
                keyIn(
                  'contact_name',
                  'country',
                  'district',
                  'floor',
                  'house_number',
                  'landmark',
                  'latitude',
                  'longitude',
                  'name',
                  'phone_number',
                  'project_name',
                  'province',
                  'street',
                  'sub_district',
                  'zip_code'
                )
              )
              .toJS()
        }
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    let { answers_attributes = [], location_attributes = {}, ...restValues } = values
    const checkNum = new RegExp('^[0-9]*$')

    if (
      !values.route_id &&
      (!checkNum.test(location_attributes.phone_number) ||
        location_attributes.phone_number.length > 10)
    ) {
      return props.setFlashMessage('error', 'phone number invalid')
    }

    answers_attributes = answers_attributes.map((answer) => {
      if (typeof answer.image === 'string') {
        return omit(answer, 'image')
      } else if (
        answer.attachments_attributes &&
        answer.attachments_attributes.length !== 0
      ) {
        return {
          ...answer,
          attachments_attributes: answer.attachments_attributes.filter(
            ({ file, _destroy }) => file instanceof File || _destroy
          )
        }
      } else {
        return answer
      }
    })

    return props
      .onSubmit({ ...restValues, answers_attributes, location_attributes })
      .then((response) => {
        if (props.formState.rememberAddress) {
          const newAddress = pick(response.body.location, [
            'contact_name',
            'country',
            'district',
            'floor',
            'house_number',
            'landmark',
            'latitude',
            'longitude',
            'name',
            'phone_number',
            'project_name',
            'province',
            'street',
            'sub_district',
            'zip_code'
          ])

          if (response.body.company) {
            props.createCompanyAddress(newAddress, response.body.company.id)
          } else {
            props.createCustomerAddress(newAddress, response.body.customer.id)
          }
        }

        props.push(`/inquiries/${response.body.id}`)

        return response
      })
      .catch((error) => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(
  withFlashMessage,
  withFormState({ rememberAddress: false }),
  withFormik(formikConfig)
)(InquiryForm)
