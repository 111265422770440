import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import styles from './GoogleAuthButtonStyles'

export function GoogleAuthButton ({ onSuccess, classes }) {
  useEffect(() => {
    if (window && window.gapi) {
      renderGoogleAuthButton()
    }
    else {
      window.renderGoogleButton = renderGoogleAuthButton
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function renderGoogleAuthButton () {
    window.gapi.signin2.render('google-auth-button', {
      longtitle: true,
      theme: 'dark',
      height: 40,
      onsuccess: handleSuccess,
      onfailure: () => {
        console.warn('Render button failed')
      }
    })
  }

  function handleSuccess (googleUser) {
    const profile = googleUser.getBasicProfile()

    onSuccess({
      first_name: profile.getGivenName(),
      last_name: profile.getFamilyName(),
      remote_avatar_url: profile.getImageUrl(),
      email: profile.getEmail(),
      omniauth_identities_attributes: {
        uid: profile.getId(),
        provider: 'google',
        token: googleUser.getAuthResponse().access_token
      }
    })
      .then(() => window.gapi.auth2.getAuthInstance().signOut())
  }

  return <div id='google-auth-button' className={classes.button} />
}

GoogleAuthButton.propTypes = {
  onSuccess: PropTypes.func,
  classes: PropTypes.func
}

export default withStyles(styles)(GoogleAuthButton)
