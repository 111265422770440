import { useState } from 'react'

import useEventId from './useEventId'

export function useSorting (
  callback = () => Promise.reject(new Error('Sorting unsuccessful'))
) {
  const [draggingItem, setDraggingItem] = useState()
  const [sortingEventId, generateEventId] = useEventId()

  function handleSortStart (data) {
    const id = parseInt(data.node.id, 10)

    draggingItem !== id && setDraggingItem(id)
  }

  function handleSortEnd ({ oldIndex, newIndex }) {
    if (oldIndex !== newIndex) {
      callback(draggingItem, { position: newIndex + 1 })
        .then(() => generateEventId())
        .catch(error => console.error(error))
    }
  }

  return { handleSortStart, handleSortEnd, sortingEventId }
}

export default useSorting
