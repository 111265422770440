import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import { PaymentOverviewPane } from './panes'

export function PaymentsShowPage ({
  payment,
  location,
  t,
  fetchPayment,
  match
}) {
  const { loading } = useData(() => fetchPayment(match.params.id))
  function subNavbar () {
    const path = `/payments/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('common:navbar.overview')} to={path} />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs () {
    return [{
      text: t('title'),
      link: '/payments'
    }, {
      text: payment.get('id')
    }]
  }

  return (
    <PageLayout
      title={`${t('title')} ID:${payment.get('id')}`}
      subNavbar={subNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/payments/:id' component={PaymentOverviewPane} exact />
      </Switch>
    </PageLayout>
  )
}

PaymentsShowPage.propTypes = {
  payment: ImmutablePropTypes.map,
  location: PropTypes.object,
  t: PropTypes.func,
  fetchPayment: PropTypes.func,
  match: PropTypes.object
}

PaymentsShowPage.defaultProps = {
  payment: Map(),
  loadPayment: () => {}
}

export default withTranslation(['payments'])(PaymentsShowPage)
