import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { serviceSchema, inquirySchema } from '@seekster/schemas'

import ServiceRequestsPane from './ServiceRequestsPane'

const mapStateToProps = (state, { match }) => ({
  service: getResource(state, match.params.id, serviceSchema)
})

const mapDispatchToProps = {
  fetchServiceRequests: (id, options) => fetchChildren(serviceSchema, id, inquirySchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequestsPane)
