import Field from './Field'
import FastField from './FastField'
import LocalizedField from './LocalizedField'

export {
  Field,
  FastField,
  LocalizedField
}

export default FastField
