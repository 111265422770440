import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'formik'
import { Grid, TextareaAutosize } from '@material-ui/core'
import { Field } from 'components/fields/Field'
import ButtonLoader from 'components/buttons/ButtonLoader'

import styles from './VoidTransactionFormStyles'

export function VoidTransactionForm ({ classes, isSubmitting }) {
  return (
    <Form className={classes.root}>
      <Grid item xs={12} className={classes.fieldContainer}>
        <Field name='description' component={TextareaAutosize} />
      </Grid>

      <Grid item xs={12} container justify='flex-end'>
        <ButtonLoader
          className={classes.button}
          variant='contained'
          primary
          type='submit'
          isLoading={isSubmitting}
        >
          Confirm
        </ButtonLoader>
      </Grid>
    </Form>
  )
}

VoidTransactionForm.propTypes = {
  classes: PropTypes.object,
  isSubmitting: PropTypes.bool
}

export default withStyles(styles)(VoidTransactionForm)
