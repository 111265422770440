import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import ClientForm from 'forms/resources/ClientForm'

export class ClientNewPage extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    push: PropTypes.func,
    createClient: PropTypes.func
  }

  static defaultProps = {
    clients: Map()
  }

  breadcrumbs () {
    return [{
      text: this.props.t('title'),
      link: '/clients'
    }, {
      text: this.props.t('new')
    }]
  }

  render () {
    return (
      <PageLayout
        title={this.props.t('new')}
        breadcrumbs={this.breadcrumbs()}
      >
        <ClientForm
          push={this.props.push}
          onSubmit={this.props.createClient}
        />
      </PageLayout>
    )
  }
}

export default withTranslation('clients')(ClientNewPage)
