import { connect } from 'react-redux'

import { regionSchema, geoJsonSchema } from '@seekster/schemas'
import { getResource } from 'selectors/resources'
import { fetchChildren } from 'actions/collections'

import RegionOverviewPane from './RegionOverviewPane'

const mapStateToProps = (state, { match }) => ({
  region: getResource(state, match.params.id, regionSchema)
})

const mapDispatchToProps = {
  fetchGeoJson: (id, options) => fetchChildren(regionSchema, id, geoJsonSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(RegionOverviewPane)
