import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { useTranslation } from 'react-i18next'

import { Map } from 'immutable'
import { Button } from 'components/buttons'

import { Form } from 'formik'
import { Field } from 'components/fields/Field'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'
import objectToOptions from 'utils/objectToOptions'
import { withStyles } from '@material-ui/core/styles'
import ResourceSelect from 'components/inputs/ResourceSelect'
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

import { agentSchema, departmentSchema } from '@seekster/schemas'

import styles from './TicketFilterFormStyles'

export function TicketFilterForm ({
  channels,
  classes,
  handleSubmit,
  setFieldValue,
  values,
  onCloseModal
}) {
  const [userType, setUserType] = useState(values.department ? 'department' : 'agent')
  const { t } = useTranslation('filters')

  function handleFilter () {
    handleSubmit()

    onCloseModal()
  }

  function onHandleChange (value) {
    setFieldValue('id', [])

    if (value === 'department') {
      setFieldValue('department', true)
    }
    else {
      setFieldValue('department', [])
    }

    setUserType(value)
  }

  return (
    <Form className={classes.root}>
      <Field
        name='channel'
        component={SelectInput}
        options={[{ value: 'CUSTOMER', label: 'CUSTOMER' }, { value: 'PROVIDER', label: 'PROVIDER' }, ...objectToOptions(channels.toJS(), 'name')]}
      />

      <Field
        name='status'
        component={SelectInput}
        options={arrayToOptions(['open', 'closed', 'pending'])}
      />

      <Field
        name='priority'
        component={SelectInput}
        options={arrayToOptions(['high', 'normal', 'low'])}
      />

      <RadioGroup aria-label='position' name='position' value={userType} onChange={event => onHandleChange(event.target.value)} row>
        <FormControlLabel
          value='agent'
          control={
            <Radio color='primary' />
          }
          label='Agent'
        />

        <FormControlLabel
          value='department'
          control={
            <Radio color='primary' />
          }
          label='Department'
        />
      </RadioGroup>

      {
        userType === 'agent' && (
          <Field
            label='agent'
            name='id'
            moreOptions={[{ display_name: 'All', id: 'All' }]}
            component={ResourceSelect}
            schema={agentSchema}
          />
        )
      }

      {
        userType === 'department' && (
          <Field
            name='id'
            label='department'
            component={ResourceSelect}
            schema={departmentSchema}
          />
        )
      }

      <div className={classes.buttonContainer}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleFilter}
        >
          {t('apply')}
        </Button>

        <Button onClick={onCloseModal}>{t('cancel')}</Button>
      </div>
    </Form>
  )
}

TicketFilterForm.propTypes = {
  channels: ImmutablePropTypes.map,
  classes: PropTypes.object,
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  onCloseModal: PropTypes.func
}

TicketFilterForm.defaultProps = {
  channels: Map()
}

export default withStyles(styles)(TicketFilterForm)
