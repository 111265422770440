import { Map, List } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'

import AnnouncementForm from './AnnouncementForm'

const formikConfig = {
  mapPropsToValues: ({ announcement = Map() }) => ({
    static_banner: announcement.get('static_banner') || false,
    link: announcement.get('link') || '',
    title_en: announcement.get('title_en') || '',
    title_th: announcement.get('title_th') || '',
    body_en: announcement.get('body_en') || '',
    body_th: announcement.get('body_th') || '',
    banner_attributes: { id: announcement.getIn(['banner', 'id']) },
    action_link: announcement.get('action_link') || '',
    action_text_en: announcement.get('action_text_en') || '',
    action_text_th: announcement.get('action_text_th') || '',
    client_ids: announcement.get('clients', List()).map(client => client.get('id')).toJS(),
    region_ids: announcement.get('regions', List()).map(region => region.get('id')).toJS(),
    start_time: announcement.get('start_time') || '',
    end_time: announcement.get('end_time') || '',
    position: announcement.get('position') || 1
  }),

  validationSchema: Yup.object().shape({
    title_en: Yup.string().required(),
    title_th: Yup.string().required(),
    link: Yup.string(),
    body_en: Yup.string(),
    body_th: Yup.string(),
    position: Yup.number().required(),
    client_ids: Yup.array().required(),
    region_ids: Yup.array().required()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values)
      .then(response => {
        props.push(`/announcements/${response.body.id}`)
      })
      .catch(error => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(AnnouncementForm)
