export default ({ breakpoints, spacing }) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    flex: 1
  },
  titleBar: {
    padding: '0 120px 60px 120px'
  },
  assignButton: {
    padding: '13px 8px',
    textTransform: 'capitalize'
  },

  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  },

  pageContainer: {
    paddingRight: spacing(16),
    paddingLeft: spacing(16)
  },

  assignIcon: {
    marginRight: spacing(0.5)
  },

  content: {
    '& > div:not(:last-child)': {
      marginBottom: spacing(1)
    }
  },

  avatar: {
    width: 65,
    height: 65,
    marginTop: spacing(0.5),

    [breakpoints.up('sm')]: {
      width: 50,
      height: 50,
      marginTop: 0
    }
  },

  icon: {
    margin: '0 4px -5px 0'
  },

  state: {
    marginTop: spacing(2),

    [breakpoints.up('sm')]: {
      marginTop: 0
    }
  },

  noProvider: {
    textAlign: 'center',
    margin: '12px 0 7px'
  }
})
