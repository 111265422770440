import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useCollection } from 'hooks/collections'
import { useResourceAction } from 'hooks/resources'
import Panel from 'components/containers/Panel'
import { getCurrentResource } from 'selectors/authentication'
import TenantMarketingConfigForm from 'forms/actions/TenantMarketingConfigForm'

import { marketingConfigurationSchema, tenantSchema } from '@seekster/schemas'

export function MarketingSettingsPane({ fetchMarketingConfig, t }) {
  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()
  const [marketingrConfig] = useCollection(marketingConfigurationSchema)
  const { create: createMargetingConfig, update: updateMarketingConfig } =
    useResourceAction(marketingConfigurationSchema)

  useEffect(() => {
    fetchMarketingConfig()
  }, [fetchMarketingConfig])

  function handleUpdateAccount(data) {
    return updateMarketingConfig('', data).then(() => fetchMarketingConfig)
  }

  function handleCreateAccount(data) {
    return createMargetingConfig(data).then(() => fetchMarketingConfig)
  }

  return (
    <Grid container justify='center'>
      <Grid item xs={12} sm={9}>
        <Panel title={t('marketing_config.title')}>
          <TenantMarketingConfigForm
            marketingConfig={tenant.get('marketing_configuration')}
            onSubmit={
              marketingrConfig.isEmpty() ? handleCreateAccount : handleUpdateAccount
            }
          />
        </Panel>
      </Grid>
    </Grid>
  )
}

MarketingSettingsPane.propTypes = {
  fetchMarketingConfig: PropTypes.func,

  t: PropTypes.func
}

export default withTranslation('settings')(MarketingSettingsPane)
