export default theme => ({
  root: {
    width: '100%'
  },
  titleContanier: {
    marginBottom: 16
  },
  fieldContainer: {
    marginBottom: 8,
    minWidth: 250
  }
})
