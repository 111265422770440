export default () => ({
  phoneIcon: {
    fontSize: 14,
    margin: `0 8px -2px 0`
  },

  emailIcon: {
    fontSize: 16,
    margin: `0 6px -4px 0`
  }
})
