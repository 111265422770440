import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const googleMapsSrc = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places,drawing`

export function GoogleGeoMap ({ zoom, geo }) {
  useEffect(() => {
    if (window.google && window.google.maps) {
      setupGoogleMap()
    }
    else {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = googleMapsSrc
      script.onload = () => setupGoogleMap()
      document.head.appendChild(script)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const mapRef = useRef()

  function setupGoogleMap () {
    let initialLatLng = { lat: 13.7563309, lng: 100.50176510000006 }
    try {
      const coordinates = geo.toJS()['features'][0]['geometry']['coordinates'][0][0][0]
      initialLatLng = { lat: coordinates[1], lng: coordinates[0] }
    } catch(err) {
    }

    const map = new window.google.maps.Map(mapRef.current, {
      zoom,
      center: initialLatLng,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false
    })


    var infowindow = new window.google.maps.InfoWindow()

    if (geo) {
      map.data.addGeoJson(geo.toJS())
    }

    map.data.setStyle(feature => {
      return {
       fillColor: feature.getProperty('color'),
       strokeColor: feature.getProperty('color'),
       strokeOpacity: 1,
       strokeWeight: 2,
     }
    })

    map.data.addListener('mouseover', function(event) {
      map.data.revertStyle()
      map.data.overrideStyle(event.feature, {strokeWeight: 6});
      infowindow.setContent(`<p><b>Region</b>: ${event.feature.getProperty('region_name')}</br><b>Area</b>: ${event.feature.getProperty('area_name')}</p>`);
      infowindow.setPosition(event.latLng)
      infowindow.open(map)
    })

    map.data.addListener('mouseout', function(event) {
      map.data.revertStyle()
      infowindow.close()
    })
  }

  return (
    <div className='google-map-container'>
      <div className='google-map' ref={mapRef} id='map' />
    </div>
  )
}

GoogleGeoMap.propTypes = {
  zoom: PropTypes.number,
  isContent: PropTypes.bool,
  center: PropTypes.object,
  markers: PropTypes.object,
  marker: PropTypes.object
}

GoogleGeoMap.defaultProps = {
  zoom: 8
}

export default GoogleGeoMap
