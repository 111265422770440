import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'clsx'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { xor } from 'lodash'

import styles from './WeekdaysPickerStyles'

export function WeekdaysPicker ({ value, onChange, isMulti, classes, ...rest }) {
  function setDay (targetWeekday) {
    const newWeekday = isMulti ? xor(value, [targetWeekday]) : targetWeekday

    onChange && onChange(newWeekday)
  }

  return (
    <div className={classes.container}>
      {
        ['0', '1', '2', '3', '4', '5', '6'].map(weekday => {
          const isSelected = isMulti ? value.includes(weekday) : weekday === value

          return (
            <Button
              key={weekday}
              variant='outlined'
              className={classNames(classes.day, {
                [classes.selectedDay]: isSelected,
                [classes.disabledPicker]: isSelected && rest.disabled
              })}
              onClick={() => setDay(weekday)}
              {...rest}
            >
              {moment().weekday(weekday).format('ddd')}
            </Button>
          )
        })
      }
    </div>
  )
}

WeekdaysPicker.propTypes = {
  value: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  classes: PropTypes.object
}

export default withStyles(styles)(WeekdaysPicker)
