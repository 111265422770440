import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/buttons'
import { Grid } from '@material-ui/core'
import { Form } from 'formik'

import PromoCodeDetailsFormSection from './PromoCodeDetailsFormSection'
import PromoCodeLimitationsFormSection from './PromoCodeLimitationsFormSection'

class PromoCodeForm extends React.Component {
  static propTypes = {
    isSubmitting: PropTypes.bool
  }

  render () {
    return (
      <Form>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <PromoCodeDetailsFormSection {...this.props} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <PromoCodeLimitationsFormSection {...this.props} />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Button color='primary' variant='contained' type='submit' loading={this.props.isSubmitting}>Save</Button>
          </Grid>
        </Grid>
      </Form>
    )
  }
}

export default PromoCodeForm
