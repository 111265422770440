import { connect } from 'react-redux'

import { fetch } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { payoutSchema } from '@seekster/schemas'

import PayoutShowPage from './PayoutShowPage'

const mapStateToProps = (state, { match }) => ({
  payout: getResource(state, match.params.id, payoutSchema)
})

const mapDispatchToProps = {
  fetchPayout: (id, options) => fetch(id, payoutSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutShowPage)
