export default ({ breakpoints, spacing, palette }) => ({
  selectTime: {
    display: 'flex',
    justifyContent: 'space-between',

    [breakpoints.up('sm')]: {
      justifyContent: 'flex-start',

      '& > div:first-child': {
        marginRight: spacing(1)
      }
    }
  },

  selectModule: {
    width: '45%',

    [breakpoints.up('sm')]: {
      width: 110
    }
  },

  additionalInfo: {
    marginTop: spacing(2)
  },

  formCheckBox: {
    marginTop: spacing(2),
    marginBottom: 0
  }
})
