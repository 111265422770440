import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Grid, Button, Select, InputBase, MenuItem, Avatar, Typography, TextareaAutosize } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import ResourceSelect from 'components/inputs/ResourceSelect'
import { makeStyles } from '@material-ui/core/styles'

import withLocalizedFields from 'hoc/withLocalizedFields'
import Panel from 'components/containers/Panel'
import Field, { LocalizedField } from 'components/fields/Field'

import { bankSchema } from '@seekster/schemas'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    padding: spacing(2)
  }
}))

export function TenantProviderConfigForm ({
  resetForm,
  // classes,
  dirty,
  locale,
  setLocale
}) {
  const { t } = useTranslation(['actions', 'settings'])
  const classes = useStyles()

  function handleSetLocale (e) {
    setLocale(e.target.value)
  }

  function actions () {
    return [
      <Select
        key='locale-select'
        value={locale}
        onChange={handleSetLocale}
        input={<InputBase />}
      >
        <MenuItem value='en'><span role='img' aria-label='GB flag'>🇬🇧 </span>English</MenuItem>
        <MenuItem value='th'><span role='img' aria-label='Thai flag'>🇹🇭 </span>ภาษาไทย</MenuItem>
      </Select>
    ]
  }

  return (
    <Form>
      <Panel title={t('provider_config.title')} actions={actions()}>
        <Grid className={classes.container}>
          <Grid item xs={12}>
            <LocalizedField name='registration_completion_description' component={TextareaAutosize} />
          </Grid>
        </Grid>
      </Panel>

      <Panel title={t('bank_config.title')}>
        <Grid className={classes.container}>
          <Grid item xs={12}>
            <Field
              name='bank_ids'
              isMulti
              component={ResourceSelect}
              schema={bankSchema}
              components={{
                Option: (props) => (
                  <components.Option {...props}>
                    <Grid container spacing={8} alignItems='center'>
                      <Grid item>
                        <Avatar src={props.data.logo_url} alt={props.data.name} />
                      </Grid>
                      <Grid item>
                        <Typography variant='subtitle1'>{props.data.name}</Typography>
                      </Grid>
                    </Grid>
                  </components.Option>
                )
              }}
            />
          </Grid>
        </Grid>
      </Panel>

      {
        dirty && (
          <Grid item xs={12}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
            >
              {t('save')}
            </Button>
            <Button
              variant='contained'
              onClick={() => resetForm()}
            >
              {t('cancel')}
            </Button>
          </Grid>
        )
      }
    </Form>
  )
}

TenantProviderConfigForm.propTypes = {
  resetForm: PropTypes.func,
  dirty: PropTypes.bool,
  locale: PropTypes.string,
  setLocale: PropTypes.func
}

export default (withLocalizedFields)(TenantProviderConfigForm)
