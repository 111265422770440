import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Grid as GridMui, Avatar, Typography } from '@material-ui/core'
import { Form } from 'formik'
import { components } from 'react-select'
import { withTranslation } from 'react-i18next'
import ButtonLoader from 'components/buttons/ButtonLoader'

import { Field } from 'components/fields/Field'
import DateInputField from 'components/fields/DateInputField'
import DateTimeInputSelectField from 'components/fields/DateTimeInputSelectField'
import DropzoneInput from 'components/inputs/DropzoneInput'
import GenderPicker from 'components/inputs/GenderPicker'
import ResourceSelect from 'components/inputs/ResourceSelect'
import SwitchInput from 'components/inputs/SwitchInput'
import ResourceCreatableSelect from 'components/inputs/ResourceCreatableSelect'
import Tag from 'components/Tag'
import CapitalizeFormatter from 'components/formatters/CapitalizeFormatter'
import {
  badgeSchema,
  serviceSchema,
  tagSchema,
  providerSchema,
  areaSchema
} from '@seekster/schemas'

import './ProviderForm.scss'

export function ProviderForm({ t, isSubmitting, provider }) {
  return (
    <Form>
      <GridMui container className='provider-container'>
        <GridMui xs={12} sm={3} style={{ padding: 8 }}>
          <Field
            name='avatar'
            component={DropzoneInput}
            width={500}
            height={500}
            initialValue={provider.get('avatar_url')}
          />
        </GridMui>

        <GridMui container xs={9} spacing={3} className='provider-details'>
          <GridMui item xs={12} sm={6}>
            <Field name='first_name' />
          </GridMui>

          <GridMui item xs={12} sm={6}>
            <Field name='last_name' />
          </GridMui>

          <GridMui item xs={12} sm={6}>
            <Field name='alias' />
          </GridMui>

          <GridMui item xs={12} sm={6}>
            <Field name='gender' type='radio' component={GenderPicker} />
          </GridMui>

          <GridMui item xs={12} sm={6}>
            <Field name='email' type='email' />
          </GridMui>

          <GridMui item xs={12} sm={6}>
            <Field name='phone_number' maxLength={10} />
          </GridMui>

          <GridMui item xs={12} sm={6}>
            <DateInputField name='date_of_birth' />
          </GridMui>

          <GridMui item xs={12} sm={6}>
            <DateTimeInputSelectField name='date_of_start' />
          </GridMui>

          <GridMui item xs={12} sm={6}>
            <DateTimeInputSelectField name='lastest_covid_result_date' />
          </GridMui>

          <GridMui item xs={12} sm={6}>
            <Field
              name='tag_ids'
              component={ResourceCreatableSelect}
              schema={tagSchema}
              parentSchema={providerSchema}
              parentId={provider.get('id')}
            />
          </GridMui>

          <GridMui item xs={12} sm={6}>
            <Field
              name='badge_ids'
              component={ResourceSelect}
              schema={badgeSchema}
              isMulti
            />
          </GridMui>

          <GridMui item xs={12} sm={6}>
            <Field
              name='service_ids'
              component={ResourceSelect}
              schema={serviceSchema}
              isMulti
              styles={{
                valueContainer: (base) => ({ ...base, fontSize: 16 })
              }}
              components={{
                Option: (props) => (
                  <components.Option {...props}>
                    <GridMui container spacing={1} alignItems='center'>
                      <GridMui item>
                        <Avatar src={props.data.icon_url} alt={props.data.name} />
                      </GridMui>
                      <GridMui item>
                        <Typography variant='subtitle1' inline>
                          {props.data.name}{' '}
                        </Typography>
                        {
                          props.data.region &&
                          <Tag
                            value={props.data.region.name}
                            formatter={CapitalizeFormatter}
                          />
                        }
                      </GridMui>
                    </GridMui>
                  </components.Option>
                ),
                MultiValueLabel: (props) => (
                  <components.MultiValueLabel {...props}>
                    {props.data.name} (
                    {props.data.region ? props.data.region.name : 'Missing Region'})
                  </components.MultiValueLabel>
                )
              }}
            />
          </GridMui>

          <GridMui item xs={12} sm={6}>
            <Field
              name='area_ids'
              component={ResourceSelect}
              schema={areaSchema}
              isMulti
              styles={{
                valueContainer: (base) => ({ ...base, fontSize: 16 })
              }}
              components={{
                Option: (props) => (
                  <components.Option {...props}>
                    <GridMui container spacing={1} alignItems='center'>
                      <GridMui item>
                        <Typography variant='subtitle1' inline>
                          {`${props.data.name} (${props.data.region.name})`}{' '}
                        </Typography>
                      </GridMui>
                    </GridMui>
                  </components.Option>
                ),
                MultiValueLabel: (props) => (
                  <components.MultiValueLabel {...props}>
                    {props.data.name} (
                    {props.data.region ? props.data.region.name : 'Missing Region'})
                  </components.MultiValueLabel>
                )
              }}
            />
          </GridMui>

          <GridMui item xs={12} sm={6}>
            <Field name='disable_leads' component={SwitchInput} />
          </GridMui>

          <GridMui item xs={12} sm={6}>
            <Field name='disable_money' component={SwitchInput} />
          </GridMui>

          <GridMui container justify='flex-end'>
            <ButtonLoader
              type='submit'
              variant='contained'
              color='primary'
              isLoading={isSubmitting}
            >
              {t('save')}
            </ButtonLoader>
          </GridMui>
        </GridMui>
      </GridMui>
    </Form>
  )
}

ProviderForm.propTypes = {
  provider: ImmutablePropTypes.map,
  isSubmitting: PropTypes.bool,

  t: PropTypes.func
}

ProviderForm.defaultProps = {
  provider: Map()
}

export default withTranslation(['provider', 'attributes'])(ProviderForm)
