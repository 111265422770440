import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { subscriptionSchema } from '@seekster/schemas'

import SubscriptionOverviewPane from './SubscriptionOverviewPane'

const mapStateToProps = (state, { match }) => ({
  subscription: getResource(state, match.params.id, subscriptionSchema)
})

export default connect(mapStateToProps)(SubscriptionOverviewPane)
