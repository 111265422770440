import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'

import CircularLoader from 'components/CircularLoader'

import ReviewPreview from '../ReviewPreview'

export function ReviewPreviewsList ({ reviews, fetchReviews }) {
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    fetchReviews({ queryParam: { per_page: 5 } })
      .then(() => setLoading(false))
  }, [fetchReviews])

  return (
    isLoading ? (
      <CircularLoader containerHeight={335} />
    ) : (
      reviews.map(review => (
        <ReviewPreview review={review} key={review.get('id')} />
      ))
    )
  )
}

ReviewPreviewsList.propTypes = {
  reviews: ImmutablePropTypes.list,
  fetchReviews: PropTypes.func
}

ReviewPreviewsList.defaultProps = {
  reviews: List()
}

export default ReviewPreviewsList
