import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { compose } from 'redux'
import { Select, InputBase, MenuItem } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import AnnouncementForm from 'forms/resources/AnnouncementForm'
import withLocalizedFields from 'hoc/withLocalizedFields'

export function AnnouncementEditPage ({
  announcement,
  fetchAnnouncement,
  updateAnnouncement,
  push,
  match,
  locale,
  setLocale,
  t
}) {
  const { loading } = useData(() => fetchAnnouncement(match.params.id))

  function handleSubmit (data) {
    return updateAnnouncement(match.params.id, data)
  }

  function actions () {
    return [
      <Select
        key='1'
        value={locale}
        onChange={(e) => setLocale(e.target.value)}
        input={<InputBase />}
      >
        <MenuItem value='en'><span role='img' aria-label='GB flag'>🇬🇧 </span>English</MenuItem>
        <MenuItem value='th'><span role='img' aria-label='Thai flag'>🇹🇭 </span>ภาษาไทย</MenuItem>
      </Select>
    ]
  }

  return (
    <PageLayout
      title={t('edit')}
      secondaryActions={actions()}
      isLoading={loading}
      back={`/announcements/${match.params.id}`}
    >
      <AnnouncementForm
        announcement={announcement}
        push={push}
        onSubmit={handleSubmit}
      />
    </PageLayout>
  )
}

AnnouncementEditPage.propTypes = {
  announcement: ImmutablePropTypes.map,
  fetchAnnouncement: PropTypes.func,
  updateAnnouncement: PropTypes.func,
  push: PropTypes.func,
  match: PropTypes.object,
  locale: PropTypes.string,
  setLocale: PropTypes.func,

  t: PropTypes.func
}

AnnouncementEditPage.defaultProps = {
  announcement: Map()
}

export default compose(
  withLocalizedFields,
  withTranslation('announcements')
)(AnnouncementEditPage)
