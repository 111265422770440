import React from 'react'
import { IonGrid, IonRow, IonCol, IonText } from '@ionic/react'
import { Avatar } from '@material-ui/core'
import { DateFormatter } from 'components/formatters'
import './CommentCard.scss'

const CommentCard = ({ value }) => {
  const trimString = (string) => {
    return string?.length > 20 ? string.substring(0, 20) + '...' : string
  }

  return (
    <IonGrid className='comment-card-contanier'>
      <IonRow>
        <IonCol size='1'>
          <IonRow className='row-flex-end'>
            {value?.agent !== undefined && value?.agent === null ? (
              <Avatar
                className='service-icon'
                src={value?.provider?.avatar_url}
                alt='Service Icon'
              />
            ) : (
              <Avatar
                className='service-icon'
                src={value?.agent?.avatar_url}
                alt='Service Icon'
              />
            )}
          </IonRow>
        </IonCol>

        <IonCol size='10'>
          <IonRow className='row-flex-end'>
            {value?.agent !== undefined && value?.agent === null ? (
              <>
                <IonText className='name-title'>
                  {trimString(value?.provider?.display_name)}
                </IonText>
                <IonText className='position-title'>Provider</IonText>
              </>
            ) : (
              <>
                <IonText className='name-title'>
                  {trimString(value?.agent?.display_name)}
                </IonText>
                <IonText className='position-title'>Admin</IonText>
              </>
            )}
          </IonRow>

          <IonRow>
            <IonText className='time-comment'>
              <DateFormatter />
            </IonText>
          </IonRow>

          <IonRow>
            <IonText>{value?.content}</IonText>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default CommentCard
