import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import withLocalizedFields from 'hoc/withLocalizedFields'
import PageLayout from 'components/layouts/PageLayout'
import CompanyForm from 'forms/resources/CompanyForm'
import SelectInput from 'components/inputs/SelectInput'
import { useCollectionChildren } from 'hooks/collections'
import { companySchema, branchSchema } from '@seekster/schemas'

export function CompanyEditPage({
  company,
  loadCompany,
  loadCompanyCustomers,
  updateCompany,
  match,
  push,
  locale,
  setLocale,
  t
}) {
  useEffect(() => {
    loadCompany(match.params.id)
    loadCompanyCustomers(match.params.id)
  }, [loadCompany, loadCompanyCustomers, match.params.id])

  const [branches, { loading }] = useCollectionChildren(
    companySchema,
    match.params.id,
    branchSchema,
    {},
    [match.params.id]
  )

  function handleSubmit(data) {
    return updateCompany(match.params.id, data)
  }

  function actions() {
    return (
      <SelectInput
        value={locale}
        onChange={setLocale}
        options={[
          { label: 'English', value: 'en' },
          { label: 'ภาษาไทย', value: 'th' }
        ]}
        isClearable={false}
        styles={{ container: (base) => ({ ...base, width: 140 }) }}
      />
    )
  }

  return (
    <PageLayout
      title={t('edit')}
      back={`/companies/${match.params.id}`}
      secondaryActions={actions()}
      isLoading={company.isEmpty() || loading}
    >
      <CompanyForm
        company={company}
        branches={branches}
        push={push}
        onSubmit={handleSubmit}
      />
    </PageLayout>
  )
}

CompanyEditPage.propTypes = {
  company: ImmutablePropTypes.map,
  loadCompany: PropTypes.func,
  loadCompanyCustomers: PropTypes.func,
  updateCompany: PropTypes.func,
  locale: PropTypes.string,
  setLocale: PropTypes.func,
  match: PropTypes.object,
  push: PropTypes.func,
  t: PropTypes.func
}

CompanyEditPage.defaultProps = {
  company: Map()
}

export default compose(withLocalizedFields, withTranslation('companies'))(CompanyEditPage)
