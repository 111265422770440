import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect } from 'formik'

import Field from 'components/fields/Field'
import MoneyField from 'components/fields/MoneyField'
import Panel from 'components/containers/Panel'

class DetailsSection extends React.Component {
  static propTypes = {
    t: PropTypes.func
  }

  render () {
    const { t } = this.props

    return (
      <Panel title={t('details.promo_code')}>
        <div style={{ padding: '10px 16px' }}>
          <Field name='code' placeholder='CODE' />
          <MoneyField name='amount_off' />
          <Field name='percent_off' type='number' icon='percent' min={0} max={100} />
        </div>
      </Panel>
    )
  }
}

export default compose(
  connect,
  withTranslation('promo_codes')
)(DetailsSection)
