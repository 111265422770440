import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import { Field, LocalizedField } from 'components/fields/Field'
import DropzoneInput from 'components/inputs/DropzoneInput'
import ButtonLoader from 'components/buttons/ButtonLoader'

const useStyles = makeStyles(() => ({
  formContainer: {
    padding: 16
  }
})
)

export function BadgeForm ({ badge, isSubmitting }) {
  const { t } = useTranslation('badges')
  const classes = useStyles()

  return (
    <Grid container justify='center' component={Form}>
      <Grid item xs={12} sm={9}>
        <Panel title={t('details')}>
          <div className={classes.formContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Field
                  name='icon'
                  component={DropzoneInput}
                  width={250} height={250}
                  initialValue={badge.get('icon_url')}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <LocalizedField name='name' />
              </Grid>

              <Grid item xs={12} container justify='flex-end'>
                <ButtonLoader
                  type='submit'
                  variant='contained'
                  isLoading={isSubmitting}
                >
                  {t('save')}
                </ButtonLoader>
              </Grid>
            </Grid>
          </div>
        </Panel>
      </Grid>
    </Grid>
  )
}

BadgeForm.propTypes = {
  badge: ImmutablePropTypes.map,
  isSubmitting: PropTypes.bool
}

BadgeForm.defaultProps = {
  badge: Map()
}

export default BadgeForm
