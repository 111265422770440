import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, create } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { subscriptionSchema } from '@seekster/schemas'

import SubscriptionDuplicatePage from './SubscriptionDuplicatePage'

const mapStateToProps = (state, { match }) => ({
  subscription: getResource(state, match.params.id, subscriptionSchema)
})

const mapDispatchToProps = {
  fetchSubscription: (id, options) => fetch(id, subscriptionSchema, options),
  createSubscription: (data, options) => create(data, subscriptionSchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDuplicatePage)
