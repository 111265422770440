import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody } from '@material-ui/core'

export class AttributesTable extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    isEmpty: PropTypes.bool,
    emptyMessage: PropTypes.string
  }

  static defaultProps = {
    isEmpty: false,
    basic: true
  }

  render() {
    if (this.props.isEmpty) {
      return <p>{this.props.emptyMessage}</p>
    } else {
      return (
        <Table basic='very'>
          <TableBody>{this.props.children}</TableBody>
        </Table>
      )
    }
  }
}

export default AttributesTable
