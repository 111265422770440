export default ({ spacing, palette }) => ({
  number: {
    display: 'flex',
    width: 'max-content'
  },

  subscriptionIcon: {
    marginLeft: spacing(1),
    height: 20
  },

  phoneNumber: {
    display: 'flex',
    marginTop: spacing(1),
    width: 'max-content'
  },

  phoneIcon: {
    fontSize: spacing(2),
    marginRight: spacing(0.5)
  },

  service: {
    width: 'max-content',
    marginBottom: spacing(1)
  },

  regionTag: {
    fontWeight: 400,
    color: palette.grey[600],
    marginLeft: spacing(0.5)
  },

  orders: {
    marginLeft: `-${spacing(3)}px`
  },

  ordersAsSecondary: {
    color: palette.grey[600]
  },

  bookingDatesDivider: {
    textAlign: 'center',
    width: 110
  },

  address: {
    width: 120
  }
})
