import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Grid, Paper } from '@material-ui/core'
import { Form } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { usePush } from 'hooks/utils'
import { useCollectionChildren } from 'hooks/collections'
import { providerSchema, jobProviderSchema } from '@seekster/schemas'

import { Field } from 'components/fields/Field'
import DateTimePicker from 'components/inputs/DateTimePickerNew'
import ButtonLoader from 'components/buttons/ButtonLoader'
import AttributeLabel from 'components/labels/AttributeLabel'
import { DateTimeFormatter, DateFormatter } from 'components/formatters'

export function JobRescheduleForm ({
  job,
  onReschedule,
  onRedistribute,
  values,
  dirty,
  isSubmitting,
  setSubmitting,
  setFlashMessage
}) {
  const { t } = useTranslation('jobs')
  const push = usePush()

  const currentProvider = !job.get('job_providers', List()).isEmpty() ? (
    job.get('job_providers', List())
      .find(jobProvider => jobProvider.get('state') === 'pending_start', null, Map())
      .get('provider', Map())
  ) : (
    Map()
  )

  const [scheduledJobProviders] = useCollectionChildren(
    providerSchema,
    currentProvider.get('id'),
    jobProviderSchema,
    {
      queryParam: {
        query: {
          'state_in][': ['pending_start', 'pending_completion']
        }
      }
    },
    [job.size]
  )

  const jobStartTime = moment(job.get('start_time'))
  const jobEndTime = moment(job.get('end_time'))
  const jobDuration = jobEndTime.diff(jobStartTime, 'minutes')

  const flaggedSchedules = scheduledJobProviders
    .toSeq()
    .map(jobProvider => ({
      startTime: moment(jobProvider.getIn(['job', 'start_time'])),
      endTime: moment(jobProvider.getIn(['job', 'end_time']))
    }))
    .filter(({ startTime, endTime }) => (
      !startTime.isSame(jobStartTime) && !endTime.isSame(jobEndTime)
    ))
    .toJS()

  const currentStartTime = moment(values.start_time)
  const currentEndTime = moment(values.start_time).add(jobDuration, 'minutes')
  const schedulesOnCurrentDate = flaggedSchedules
    .filter(({ startTime }) => startTime.isSame(currentStartTime, 'day'), null, [])

  const isSelectedOnFlaggedSchudule = flaggedSchedules
    .reduce((result, { startTime, endTime }) => (
      (currentStartTime.isSameOrAfter(startTime) &&
      currentStartTime.isBefore(endTime)) || result
    ), false)

  const isTimeOverlapped = schedulesOnCurrentDate
    .reduce((result, { startTime, endTime }) => (
      (currentStartTime.isBefore(endTime) &&
      currentEndTime.isAfter(startTime)) || result
    ), false)

  function handleReschedule () {
    if (onReschedule && window.confirm(`${t('reschedule_confirmation_prompt')}`)) {
      afterSubmit(onReschedule(values))
    }
  }

  function handleRedistribute () {
    if (onRedistribute && window.confirm(`${t('redistribute_confirmation_prompt')}`)) {
      afterSubmit(onRedistribute(values))
    }
  }

  function afterSubmit (promise) {
    promise
      .then(({ body }) => {
        push(`/jobs/${body.id}`)
      })
      .catch(error => {
        setSubmitting(false)

        let errorMessage = 'Error Occured!'

        if (error.response.body && error.response.status === 400) {
          errorMessage = `
            ${error.response.body.message}
            Selected time is overlapped to other job in the same request!
          `
        }

        setFlashMessage('error', errorMessage)
      })
  }

  return (
    <Grid container spacing={2} justify='flex-end' component={Form}>
      <Grid item xs={12}>
        <Paper style={{ padding: '14px 14px 1px 14px' }}>
          <AttributeLabel title={t('current_job_details')}>
            <span>{t('start_on')}</span>
            <DateTimeFormatter value={job.get('start_time')} />

            <ul>
              {
                job.get('orders', List()).map(order => (
                  <li key={order.get('id')}>
                    {order.getIn(['package', 'name'])}
                  </li>
                ))
              }
            </ul>
          </AttributeLabel>

          <AttributeLabel title={t('current_provider')}>
            <Link to={`/provider/${currentProvider.get('id')}`}>
              {currentProvider.get('display_name')}
            </Link>
          </AttributeLabel>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Field
          name='start_time'
          noLabel
          component={DateTimePicker}
          highlightedSchedules={flaggedSchedules}
          highlightLegendDescription={t('current_provider_schedule')}
          timePickerDuration={jobDuration}
          timeSelectAdditionalInfo={schedulesOnCurrentDate.length > 0 && (
            <>
              <span>
                {t('current_provider_schedule')}
                {t('schedule_on')}
                <DateFormatter value={values.start_time} simple />
              </span>
              <ul>
                {
                  schedulesOnCurrentDate.map(({ startTime, endTime }, i) => (
                    <li key={i}>
                      {startTime.format('HH:mm')} - {endTime.format('HH:mm')}
                    </li>
                  ))
                }
              </ul>
            </>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <ButtonLoader
          type='button'
          variant='contained'
          color='primary'
          isLoading={isSubmitting}
          onClick={handleReschedule}
          disabled={!dirty || isSelectedOnFlaggedSchudule || isTimeOverlapped}
          fullWidth
          style={{ display: 'block' }}
        >
          {t('actions.reschedule')}
        </ButtonLoader>
      </Grid>

      <Grid item xs={12} sm={3}>
        <ButtonLoader
          type='button'
          variant='contained'
          color='primary'
          isLoading={isSubmitting}
          onClick={handleRedistribute}
          disabled={!dirty}
          fullWidth
          style={{ display: 'block' }}
        >
          {t('actions.redistribute')}
        </ButtonLoader>
      </Grid>
    </Grid>
  )
}

JobRescheduleForm.propTypes = {
  job: ImmutablePropTypes.map,
  onReschedule: PropTypes.func,
  onRedistribute: PropTypes.func,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  setSubmitting: PropTypes.func,
  setFlashMessage: PropTypes.func
}

JobRescheduleForm.defaultProps = {
  job: Map()
}

export default JobRescheduleForm
