import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { create } from 'actions/resources'
import { inquirySchema } from '@seekster/schemas'

import InquiryNewPage from './InquiryNewPage'

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = {
  createInquiry: (data, options) => create(data, inquirySchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(InquiryNewPage)
