import React from 'react'
import { Form } from 'formik'
import { compose } from 'redux'
import { Grid, Button, TextareaAutosize } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import Field from 'components/fields/Field'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    padding: spacing(2)
  }
}))

export function TagManagerSettingsForm ({
  resetForm,
  dirty,
  t
}) {
  const classes = useStyles()

  return (
    <Form>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Field
            name='head'
            component={TextareaAutosize}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            name='body_start'
            component={TextareaAutosize}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            name='body_end'
            component={TextareaAutosize}
          />
        </Grid>
      </Grid>

      {
        dirty && (
          <Grid item xs={12}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.button}
            >
              {t('save')}
            </Button>
            <Button
              variant='contained'
              onClick={() => resetForm()}
              className={classes.button}
            >
              {t('cancel')}
            </Button>
          </Grid>
        )
      }
    </Form>
  )
}

export default compose(
  withTranslation(['actions', 'settings'])
)(TagManagerSettingsForm)
