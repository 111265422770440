import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getFlashMessage, getSidebarOpen } from 'selectors/interface'
import { clearFlashMessage, openSidebar, closeSidebar, toggleSidebar } from 'actions/interface'

export const useFlashMessage = () => {
  const dispatch = useDispatch()
  const flashMessage = useSelector(getFlashMessage)

  useEffect(() => {
    dispatch(clearFlashMessage())
  }, [dispatch])

  return [flashMessage, () => dispatch(clearFlashMessage())]
}

export const useDocumentTitle = (title) => {
  useEffect(() => {
    if (window) {
      window.document.title = `${title} | Workforce`
    }
  }, [title])
}

export const useSidebar = () => {
  const dispatch = useDispatch()
  const sidebarOpen = useSelector(getSidebarOpen)

  const dispatchOpenSidebar = () => dispatch(openSidebar())
  const dispatchCloseSidebar = () => dispatch(closeSidebar())
  const dispatchToggleSidebar = () => dispatch(toggleSidebar())

  return {
    sidebarOpen,
    openSidebar: dispatchOpenSidebar,
    closeSidebar: dispatchCloseSidebar,
    toggleSidebar: dispatchToggleSidebar
  }
}
