import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import CircularLoader from 'components/CircularLoader'
import GoogleAuthButton from 'components/buttons/GoogleAuthButton'
import AcceptInvitationForm from 'forms/actions/AcceptInvitationForm'

import { permissionToReceiveNotification } from 'utils/firebase'

import './AcceptInvitationPage.scss'

const logoUrl = 'https://s3-ap-southeast-1.amazonaws.com/seekster-web/assets/images/workforce_logo_with_name_black.png'

export function AcceptInvitationPage ({
  invitation,
  fetchInvitation,
  acceptInvitationWithOauth,
  acceptInvitationWithEmail,
  updateCurrentDevice,
  tenant,
  fetchCurrentTenantBySlug,
  push,
  query,
  t
}) {
  const { error } = useData(() => fetchInvitation(query.token))
  const { loading } = useData(() => fetchCurrentTenantBySlug())

  function handleAcceptInvitationWithOauth (data) {
    return acceptInvitationWithOauth(data, query.token)
      .then(() => afterInvitationAccecpted())
  }
  function handleAcceptInvitationWithEmail (data) {
    return acceptInvitationWithEmail(data, query.token)
      .then(() => afterInvitationAccecpted())
  }

  function afterInvitationAccecpted () {
    push('/')

    return permissionToReceiveNotification()
      .then(fcmToken => {
        if (window.localStorage.getItem('FCM_token') !== fcmToken) {
          updateCurrentDevice({ registration_token: fcmToken })
        }
      })
      .catch(error => console.warn(error))
  }

  return (
    <div className='page'>
      <div className='container'>
        <div className='info-section'>
          <img src={logoUrl} alt='app-logo' className='app-logo' />

          <p className='description'>
            Workforce provides businesses with the digital capabilities of automating their workforce and task distribution processes.
          </p>
        </div>

        <div className='form-section'>
          {
            loading ? (
              <CircularLoader />
            ) : (
              <img
                src={tenant.get('dark_logo_url')}
                alt={tenant.get('name')}
                className='tenant-logo'
              />
            )
          }

          {
            error ? (
              <h2>{error.response.body ? error.response.body.message : 'Error Occured'}</h2>
            ) : (
              <Fragment>
                <h3 className='welcome-message'>
                  {t('hello')} {invitation.getIn(['user', 'display_name'])}
                </h3>

                <GoogleAuthButton onSuccess={handleAcceptInvitationWithOauth} />

                <div className='divider'>
                  <div />
                  <label>{t('or')}</label>
                  <div />
                </div>

                <AcceptInvitationForm onSubmit={handleAcceptInvitationWithEmail} />
              </Fragment>
            )
          }
        </div>
      </div>
    </div>
  )
}

AcceptInvitationPage.propTypes = {
  invitation: ImmutablePropTypes.map,
  tenant: ImmutablePropTypes.map,
  fetchInvitation: PropTypes.func,
  acceptInvitationWithOauth: PropTypes.func,
  acceptInvitationWithEmail: PropTypes.func,
  fetchCurrentTenantBySlug: PropTypes.func,
  updateCurrentDevice: PropTypes.func,
  push: PropTypes.func,
  query: PropTypes.object,

  t: PropTypes.func
}

AcceptInvitationPage.defaultProps = {
  invitation: Map(),
  tenant: Map()
}

export default withTranslation('authentication')(AcceptInvitationPage)
