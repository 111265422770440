import { connect } from 'react-redux'

import { fetch } from 'actions/collections'
import { getPage, getTotalPages, getCollection } from 'selectors/collections'
import { addressSchema } from '@seekster/schemas'

import AddressIndexPage from './AddressIndexPage'

const mapStateToProps = (state) => ({
  addresses: getCollection(state, addressSchema),
  page: getPage(state, addressSchema),
  totalPages: getTotalPages(state, addressSchema)
})

const mapDispatchToProps = {
  loadAddresses: options => fetch(addressSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressIndexPage)
