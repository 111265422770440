import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { Link } from 'react-router-dom'
import { Grid, Card, CardHeader, Avatar } from '@material-ui/core'
import { Phone } from '@material-ui/icons'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import PhoneNumberFormatter from 'components/formatters/PhoneNumberFormatter'
import Tag from 'components/Tag'
import MaterialRating from '@material-ui/lab/Rating'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import JobProviderActionsMenu from '../JobProviderActionsMenu'

import styles from './JobProvidersListStyles'

export function JobProvidersList({ jobProviders, jobId, classes, t }) {
  return (
    jobProviders.map(jobProvider => (
      <Card key={jobProvider.get('id')}>
        <CardHeader
          title={
            <Grid container>
              <Grid item xs={4} sm={1}>
                <Avatar
                  src={jobProvider.getIn(['provider', 'avatar_url'])}
                  alt={jobProvider.getIn(['provider', 'display_name'])}
                  className={classes.avatar}
                />
              </Grid>

              <Grid item xs={8} sm={11} container alignItems='center'>
                <Grid item xs={12} sm={3}>
                  <Link to={`/providers/${jobProvider.getIn(['provider', 'id'])}`}>
                    {jobProvider.getIn(['provider', 'display_name'])}
                  </Link>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Phone color='action' fontSize='small' className={classes.icon} />
                  <PhoneNumberFormatter value={jobProvider.getIn(['provider', 'phone_number'])} />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <MaterialRating
                    defaultValue={jobProvider.getIn(['provider', 'public_rating'])}
                    size='small'
                    readOnly
                    precision={0.5}
                    className='rating'
                    emptyIcon={<StarBorderIcon fontSize='inherit' />}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Tag value={jobProvider.get('state')} className='job-provider-states' />
                </Grid>
              </Grid>
            </Grid>
          }
          action={
            <JobProviderActionsMenu
              jobProviderId={jobProvider.get('id')}
              jobId={jobId}
              disabled={jobProvider.get('state') !== 'pending_start'}
            />
          }
          titleTypographyProps={{ variant: 'subtitle1' }}
        />
      </Card>
    ))
  )
}

JobProvidersList.propTypes = {
  jobProviders: ImmutablePropTypes.list,
  jobId: PropTypes.number,
  classes: PropTypes.object,

  t: PropTypes.func
}

JobProvidersList.defaultProps = {
  jobProviders: List()
}

export default compose(
  withStyles(styles),
  withTranslation('jobs')
)(JobProvidersList)
