import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Select, InputBase, MenuItem } from '@material-ui/core'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import withLocalizedFields from 'hoc/withLocalizedFields'

import PageLayout from 'components/layouts/PageLayout'
import ProviderStrengthForm from 'forms/resources/ProviderStrengthForm'

export function ProviderStrengthEditPage ({
  providerStrength,
  fetchProviderStrength,
  updateProviderStrength,
  push,
  match,
  locale,
  setLocale,
  t
}) {
  useEffect(() => {
    fetchProviderStrength(match.params.id)
  }, [fetchProviderStrength, match.params.id])

  function handleSubmit (data) {
    return updateProviderStrength(match.params.id, data)
  }

  function renderActions () {
    return [
      <Select
        value={locale}
        onChange={e => setLocale(e.target.value)}
        input={<InputBase />}
      >
        <MenuItem value='en'><span role='img' aria-label='GB flag'>🇬🇧 </span>English</MenuItem>
        <MenuItem value='th'><span role='img' aria-label='Thai flag'>🇹🇭 </span>ภาษาไทย</MenuItem>
      </Select>
    ]
  }

  return (
    <PageLayout
      title={t('edit')}
      secondaryActions={renderActions()}
      back={`/provider_strengths/${match.params.id}`}
    >
      <ProviderStrengthForm
        providerStrength={providerStrength}
        onSubmit={handleSubmit}
        push={push}
      />
    </PageLayout>
  )
}

ProviderStrengthEditPage.propTypes = {
  providerStrength: ImmutablePropTypes.map,
  fetchProviderStrength: PropTypes.func,
  updateProviderStrength: PropTypes.func,
  push: PropTypes.func,
  match: PropTypes.object,
  locale: PropTypes.string,
  setLocale: PropTypes.func,

  t: PropTypes.func
}

ProviderStrengthEditPage.defaultProps = {
  providerStrength: Map()
}

export default compose(
  withLocalizedFields,
  withTranslation('provider_strengths')
)(ProviderStrengthEditPage)
