/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { Calendar } from 'react-modern-calendar-datepicker'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import './ProviderCalendar.scss'

const ProviderCalendar = ({
  disableDate,
  handleSelectedDay,
  activeJobDate,
  availableDate
}) => {
  const { t } = useTranslation('provider_calendar')

  const [selectedDay, setSelectedDay] = useState(null)
  const [onceAvailableDay, setOnceAvailableDay] = useState([])
  const [onceActiveDay, setOnceActiveDay] = useState([])
  const [onceDisableDay, setOnceDisableDay] = useState([])
  const [pairAvailableActiveDay, setPairAvailableActiveDay] = useState([])
  const [pairAvailableDisableDay, setPairAvailableDisableDay] = useState([])
  const [pairActiveDisableDay, setPairActiveDisableDay] = useState([])
  const [groupAvailableActiveDisableDay, setGroupAvailableActiveDisableDay] = useState([])

  let concatCustomDate = groupAvailableActiveDisableDay
    .concat(pairAvailableActiveDay)
    .concat(pairAvailableDisableDay)
    .concat(pairActiveDisableDay)
    .concat(onceActiveDay)
    .concat(onceDisableDay)
    .concat(onceAvailableDay)

  useEffect(() => {
    const onceDisable = []
    const onceActive = []
    const onceAvailable = []
    const onceActiveAvailable = []
    const onceActiveDisable = []
    const onceDisableAvailable = []
    const onceMix = []

    if (availableDate && activeJobDate && disableDate) {
      availableDate.forEach((available) => {
        activeJobDate.forEach((active) => {
          disableDate.forEach((disable) => {
            if (
              moment(available.start_time).get('date') ===
                moment(active.start_time).get('date') &&
              moment(available.start_time).get('month') + 1 ===
                moment(active.start_time).get('month') + 1 &&
              moment(available.start_time).get('date') + 1 ===
                moment(disable.start_time).get('date') + 1 &&
              moment(available.start_time).get('month') + 1 ===
                moment(disable.start_time).get('month') + 1
            ) {
              const formatDay = {
                year: moment(active.start_time).get('year'),
                month: moment(active.start_time).get('month') + 1,
                day: moment(active.start_time).get('date'),
                className: 'group_available_active_disable_day'
              }
              onceMix.push(formatDay)
            }
          })
        })
      })

      availableDate.forEach((available) => {
        activeJobDate.forEach((active) => {
          if (
            moment(available.start_time).get('date') ===
              moment(active.start_time).get('date') &&
            moment(available.start_time).get('month') + 1 ===
              moment(active.start_time).get('month') + 1
          ) {
            const formatDay = {
              year: moment(active.start_time).get('year'),
              month: moment(active.start_time).get('month') + 1,
              day: moment(active.start_time).get('date'),
              className: 'pair_available_active_day'
            }
            onceActiveAvailable.push(formatDay)
          }
        })
      })

      availableDate.forEach((available) => {
        disableDate.forEach((disable) => {
          if (
            moment(available.start_time).get('date') ===
              moment(disable.start_time).get('date') &&
            moment(available.start_time).get('month') + 1 ===
              moment(disable.start_time).get('month') + 1
          ) {
            const formatDay = {
              year: moment(disable.start_time).get('year'),
              month: moment(disable.start_time).get('month') + 1,
              day: moment(disable.start_time).get('date'),
              className: 'pair_available_disable_day'
            }
            onceDisableAvailable.push(formatDay)
          }
        })
      })

      activeJobDate.forEach((active) => {
        disableDate.forEach((disable) => {
          if (
            moment(active.start_time).get('date') ===
              moment(disable.start_time).get('date') &&
            moment(active.start_time).get('month') + 1 ===
              moment(disable.start_time).get('month') + 1
          ) {
            onceActiveDisable.push({
              year: moment(disable.start_time).get('year'),
              month: moment(disable.start_time).get('month') + 1,
              day: moment(disable.start_time).get('date'),
              className: 'pair_active_disable_day'
            })
          }
        })
      })

      activeJobDate.forEach((active) => {
        onceActive.push({
          year: moment(active.start_time).get('year'),
          month: moment(active.start_time).get('month') + 1,
          day: moment(active.start_time).get('date'),
          className: 'active_day'
        })
      })

      availableDate.forEach((available) => {
        onceAvailable.push({
          year: moment(available.start_time).get('year'),
          month: moment(available.start_time).get('month') + 1,
          day: moment(available.start_time).get('date'),
          className: 'available_day'
        })
      })

      disableDate.forEach((disable) => {
        onceDisable.push({
          year: moment(disable.start_time).get('year'),
          month: moment(disable.start_time).get('month') + 1,
          day: moment(disable.start_time).get('date'),
          className: 'disable_day'
        })
      })

      setGroupAvailableActiveDisableDay(
        onceMix.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
      setOnceDisableDay(
        onceDisable.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
      setPairAvailableActiveDay(
        onceActiveAvailable.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
      setPairAvailableDisableDay(
        onceDisableAvailable.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
      setPairActiveDisableDay(
        onceActiveDisable.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
      setOnceActiveDay(
        onceActive.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
      setOnceAvailableDay(
        onceAvailable.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
    }
  }, [availableDate, activeJobDate, disableDate])

  const handleSelected = (value) => {
    setSelectedDay(value)
    handleSelectedDay(value)
  }

  return (
    <Calendar
      value={selectedDay}
      onChange={handleSelected}
      customDaysClassName={concatCustomDate}
      renderFooter={() => (
        <div className='container-footer-calendar'>
          {activeJobDate ? (
            <div className='container-text-footer'>
              <div className='active-icon' />
              <div className='text-footer'>{t('working_day')}</div>
            </div>
          ) : (
            ''
          )}
          {availableDate ? (
            <div className='container-text-footer'>
              <div className='available-icon' />
              <div className='text-footer'>{t('available_day')}</div>
            </div>
          ) : (
            ''
          )}
          {disableDate ? (
            <div className='container-text-footer'>
              <div className='disable-icon' />
              <div className='text-footer'>{t('day_off')}</div>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    />
  )
}

export default ProviderCalendar
