import { connect } from 'react-redux'

import { destroy } from 'actions/resources'
import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { providerSchema, attachmentSchema } from '@seekster/schemas'

import ProviderDocumentsPane from './ProviderDocumentsPane'

const mapStateToProps = (state, { match }) => ({
  provider: getResource(state, match.params.id, providerSchema)
})

const mapDispatchToProps = {
  loadProviderAttachments: (id, options) => fetchChildren(providerSchema, id, attachmentSchema, options),
  delete: (id, options) => destroy(id, attachmentSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDocumentsPane)
