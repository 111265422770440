import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, update } from 'actions/resources'
import { fetch as fetchCollection } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { getCollection } from 'selectors/collections'
import { providerSchema, badgeSchema, serviceSchema } from '@seekster/schemas'

import ProviderEditPage from './ProviderEditPage'

const mapStateToProps = (state, { match }) => ({
  provider: getResource(state, match.params.id, providerSchema),
  badges: getCollection(state, badgeSchema),
  services: getCollection(state, serviceSchema)
})

const mapDispatchToProps = {
  loadProvider: (id, options) => fetch(id, providerSchema, options),
  loadBadges: (options) => fetchCollection(badgeSchema, options),
  loadServices: (options) => fetchCollection(serviceSchema, options),
  updateProvider: (id, data, options) => update(id, data, providerSchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderEditPage)
