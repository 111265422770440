import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { clientSchema } from '@seekster/schemas'

import ClientOverviewPane from './ClientOverviewPane'

const mapStateToProps = (state, { match }) => ({
  client: getResource(state, match.params.id, clientSchema)
})

export default connect(mapStateToProps)(ClientOverviewPane)
