import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'

import Tooltip from '@material-ui/core/Tooltip'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import ButtonLoader from 'components/buttons/ButtonLoader'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import callService from './callService'

import { Formik, Field, Form, ErrorMessage } from 'formik'
import './HelpAndSupport.scss'

import * as yup from 'yup'

import createStore from '../../../../createStore'

export const store = createStore()

const useStyles = makeStyles(() => ({
  saveButton: {
    borderRadius: '8px',
    width: '168px',
    height: '64px',
    color: '#ffffff !important'
  }
}))

const HelpAndSupport = () => {
  const [checkStatus, setCheckStatus] = useState('Pr')
  const [changeValidate, setChangeValidate] = useState(false)
  const [provider, setProvider] = useState({
    application: '',
    contact_email: '',
    contact_line: null,
    created_at: '',
    id: 0,
    policy_term_translations: [],
    tenant_id: 0,
    updated_at: '',
    variant: ''
  })
  const [customer, setCustomer] = useState({
    application: '',
    contact_email: '',
    contact_line: null,
    created_at: '',
    id: 0,
    policy_term_translations: [],
    tenant_id: 0,
    updated_at: '',
    variant: ''
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation('action')
  const classes = useStyles()
  const accessToken = store.getState().getIn(['authentication', 'token'])
  const header = { authorization: `Bearer ${accessToken}` }

  const ValidationSchema = yup.object().shape({
    link: yup
      .string()
      .email('Please enter a valid email address.')
      .required('Link is required.')
      .nullable()
  })

  const ValidationSchemaLine = yup.object().shape({
    link: yup.string().required('Link is required.').nullable()
  })

  const regexCheck = (value, action, name) => {
    const regex = /[@]/
    const b = regex.test(value)
    if (b) {
      setChangeValidate(true)
    } else {
      setChangeValidate(false)
    }
    action.setFieldValue(name, value)
  }

  const initialValuesPr = {
    link: provider.contact_email || provider.contact_line
  }

  const initialValuesCs = {
    link: customer.contact_email || customer.contact_line
  }

  const statusShow = () => {
    if (checkStatus === 'Pr') {
      return initialValuesPr
    } else if (checkStatus === 'Cs') {
      return initialValuesCs
    }
  }

  // eslint-disable-next-line space-before-function-paren
  const callList = async () => {
    const response = await callService(
      'GET',
      `${process.env.REACT_APP_BASE_API}/policy_terms`,
      null,
      header
    )
    if (response.status === 200 || response.status === 201) {
      const providerData = response.data
        .filter((data) => data.application === 'Provider')
        .find((i) => i)
      const customerData = response.data
        .filter((data) => data.application === 'Customer')
        .find((i) => i)
      setProvider(providerData)
      setCustomer(customerData)
    }
  }

  useEffect(() => {
    if (accessToken) {
      callList()
    } else {
      window.location.reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  // eslint-disable-next-line space-before-function-paren
  const SubmitValue = async (value) => {
    setIsSubmitting(true)
    const regex = /[@]/
    const checkEmail = regex.test(value.link)
    const data = {
      contact_email: checkEmail ? value.link : '',
      contact_line: !checkEmail ? value.link : '',
      variant: checkStatus === 'Pr' ? provider.variant : customer.variant,
      application: checkStatus === 'Pr' ? provider.application : customer.application,
      content_en: '',
      content_th: ''
    }

    const response = await callService(
      'PUT',
      `${process.env.REACT_APP_BASE_API}/policy_terms/${
        checkStatus === 'Pr' ? provider.id : customer.id
      }`,
      data,
      header
    )
    if (response.status === 200 || response.status === 201) {
      setIsSubmitting(false)
      window.location.reload()
    }
  }

  return (
    <div>
      <div>
        <Grid container justify='center'>
          <Grid
            item
            xs={12}
            md={6}
            className={`pointer border-default ${checkStatus === 'Pr' && 'active'}`}
            onClick={() => setCheckStatus('Pr')}
          >
            <div>
              <h1 className='text-center'>Providers</h1>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className={`pointer border-default ${checkStatus === 'Cs' && 'active'}`}
            onClick={() => setCheckStatus('Cs')}
          >
            <div>
              <h1 className='text-center'>Customer</h1>
            </div>
          </Grid>
        </Grid>
        <Grid container justify='center'>
          <div className='container custom-box'>
            <Formik
              enableReinitialize
              initialValues={statusShow()}
              onSubmit={SubmitValue}
              validationSchema={changeValidate ? ValidationSchema : ValidationSchemaLine}
            >
              {(formik) => (
                <Form>
                  <div className='topic-custom'>
                    <p className='text-left'>Enter email for help and support contact</p>
                    <Tooltip title='' placement='right-start'>
                      <ErrorOutlineIcon />
                    </Tooltip>
                  </div>
                  <Field
                    name='link'
                    type='text'
                    onChange={(e) => regexCheck(e.target.value, formik, 'link')}
                    className='input-field'
                    placeholder='https://workforce.co'
                  />
                  <div className='validate-message'>
                    <ErrorMessage
                      component='div'
                      name='link'
                      className='errros-message-text'
                    />
                  </div>
                  <div className='text-right'>
                    <Grid container justify='flex-end'>
                      <ButtonLoader
                        type='submit'
                        variant='contained'
                        color='primary'
                        disabled={!formik.values.link || isSubmitting}
                        className={classes.saveButton}
                        isLoading={isSubmitting}
                      >
                        {t('save')}
                      </ButtonLoader>
                    </Grid>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </div>
    </div>
  )
}

export default HelpAndSupport
