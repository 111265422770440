import React from 'react'
import PropTypes from 'prop-types'
import { Select, InputBase, MenuItem } from '@material-ui/core'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import withLocalizedFields from 'hoc/withLocalizedFields'

import PageLayout from 'components/layouts/PageLayout'
import ProviderStrengthForm from 'forms/resources/ProviderStrengthForm'

export function ProviderStrengthNewPage ({ createProviderStrength, push, locale, setLocale, t }) {
  function renderActions () {
    return [
      <Select
        value={locale}
        onChange={e => setLocale(e.target.value)}
        input={<InputBase />}
      >
        <MenuItem value='en'><span role='img' aria-label='GB flag'>🇬🇧 </span>English</MenuItem>
        <MenuItem value='th'><span role='img' aria-label='Thai flag'>🇹🇭 </span>ภาษาไทย</MenuItem>
      </Select>
    ]
  }

  return (
    <PageLayout
      title={t('new')}
      secondaryActions={renderActions()}
      back='/provider_strengths'
    >
      <ProviderStrengthForm onSubmit={createProviderStrength} push={push} />
    </PageLayout>
  )
}

ProviderStrengthNewPage.propTypes = {
  createProviderStrength: PropTypes.func,
  push: PropTypes.func,
  locale: PropTypes.string,
  setLocale: PropTypes.func,

  t: PropTypes.func
}

export default compose(
  withLocalizedFields,
  withTranslation('provider_strengths')
)(ProviderStrengthNewPage)
