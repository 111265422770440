export default theme => ({
  button: {
    float: 'right',
    margin: '14px 0 0 14px'
  },

  deleteButton: {
    marginTop: 17,
    color: '#bc2727',
    fontWeight: 600
  }
})
