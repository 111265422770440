import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Form } from 'formik'
import { withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import { Field, FastField } from 'components/fields/Field'
import ButtonLoader from 'components/buttons/ButtonLoader'
import GoogleMapInput from 'components/inputs/GoogleMapInput'
import addressComponentExtractor from 'utils/addressComponentExtractor'

export function AddressForm({ values, setFieldValue, isSubmitting, t }) {
  function handleMapChange(
    { latitude, longitude },
    { address_components: addressComponents }
  ) {
    if (latitude && longitude) {
      setFieldValue('latitude', latitude)
      setFieldValue('longitude', longitude)
    }

    if (addressComponents) {
      const extractAddressComponent = addressComponentExtractor(addressComponents)

      setFieldValue('house_number', extractAddressComponent('street_number'))
      setFieldValue('project_name', extractAddressComponent('premise'))
      setFieldValue('street', extractAddressComponent('route'))
      setFieldValue(
        'sub_district',
        extractAddressComponent(['sublocality_level_2', 'locality'])
      )
      setFieldValue(
        'district',
        extractAddressComponent(['sublocality_level_1', 'administrative_area_level_2'])
      )
      setFieldValue('province', extractAddressComponent('administrative_area_level_1'))
      setFieldValue('country', extractAddressComponent('country'))
      setFieldValue('zip_code', extractAddressComponent('postal_code'))
    }
  }

  return (
    <Grid container justify='center' component={Form}>
      <Grid item xs={12} sm={9}>
        <Panel title={t('details')}>
          <Field
            name='google_map'
            noLabel
            value={values}
            component={GoogleMapInput}
            onChange={handleMapChange}
          />

          <Grid container>
            <Grid item xs={12} sm={6}>
              <FastField name='name' />
            </Grid>

            <Grid item xs={10} sm={5}>
              <FastField name='phone_number' />
            </Grid>
            <Grid item xs={2} sm={1}>
              <FastField name='extension_number' />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FastField name='house_number' />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FastField name='project_name' />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FastField name='street' />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FastField name='province' />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FastField name='sub_district' />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FastField name='district' />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FastField name='zip_code' />
            </Grid>
          </Grid>
        </Panel>

        <Grid container justify='flex-end'>
          <ButtonLoader
            type='submit'
            variant='contained'
            color='primary'
            isLoading={isSubmitting}
          >
            {t('save')}
          </ButtonLoader>
        </Grid>
      </Grid>
    </Grid>
  )
}

AddressForm.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  isSubmitting: PropTypes.bool,
  t: PropTypes.string
}

export default withTranslation(['common', 'attributes'])(AddressForm)
