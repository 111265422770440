import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { TextField, FormControl } from '@material-ui/core'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'
import { CURRENCIES } from 'constants/currencies'

import './MoneyRangeField.scss'

export function MoneyRangeField ({
  name,
  label,
  defaultCurrency,
  ...rest
}) {
  const { t } = useTranslation('fields')

  return (
    <Field
      name={name}
      render={({ field, form }) => (
        <div className='form-money-range-field'>
          <label>{label || t(`label.${name}`)}</label>

          <from noValidate autoComplete='off'>
            <FormControl className='money-range-input-field'>
              <TextField
                variant='outlined'
                placeholder={t(`placeholder.${name}`)}
                color='primary'
                type='number'
                {...field}
                value={
                  get(field, ['value', 'min'], '') !== ''
                    ? parseInt(get(field, ['value', 'min']), 10) / 100
                    : ''
                }
                onChange={
                  (e) => (
                    form.setFieldValue(
                      name,
                      {
                        ...form.values[name],
                        min: e.target.value ? parseInt((e.target.value * 100).toFixed(0)) : '',
                        currency: get(field, ['value', 'currency'], defaultCurrency)
                      }
                    )
                  )
                }
                {...rest}
              />
            </FormControl>

            <FormControl className='money-range-input-field'>
              <TextField
                variant='outlined'
                placeholder={t(`placeholder.${name}`)}
                color='primary'
                type='number'
                {...field}
                value={
                  get(field, ['value', 'max'], '') !== ''
                    ? parseInt(get(field, ['value', 'max']), 10) / 100
                    : ''
                }
                onChange={
                  (e) => (
                    form.setFieldValue(
                      name,
                      {
                        ...form.values[name],
                        max: e.target.value ? parseInt((e.target.value * 100).toFixed(0)) : '',
                        currency: get(field, ['value', 'currency'], defaultCurrency)
                      }
                    )
                  )
                }
                {...rest}
              />
            </FormControl>

            <FormControl className='money-range-input-field-select-input'>
              <SelectInput
                {...field}
                options={arrayToOptions(CURRENCIES)}
                value={get(field, ['value', 'currency']) || defaultCurrency}
                onChange={
                  value => (
                    form.setFieldValue(
                      name, { ...form.values[name], currency: value }
                    )
                  )
                }
                isClearable={false}
                {...rest}
              />
            </FormControl>
          </from>
        </div>
      )}
    />
  )
}

MoneyRangeField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultCurrency: PropTypes.string
}

export default MoneyRangeField
