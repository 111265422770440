import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/Help'
import { useTranslation } from 'react-i18next'
import { useCollectionChildren } from 'hooks/collections'

import Calendar from 'components/ProviderCalendar'
import { useResource } from 'hooks/resources'
import { providerSchema, jobSchema } from '@seekster/schemas'
import Panel from 'components/containers/Panel'

export function ProviderLeadsPane({ match }) {
  const { t } = useTranslation('provider_calendar')

  const [triggerUpdate, setTriggerUpdate] = useState(1)

  const [provider] = useResource(providerSchema, match.params.id, [triggerUpdate])
  const providerData = provider.toJS()
  const providerRestDate = providerData.provider_rest_dates
  const providerAvailableDate = providerData.provider_available_dates

  const [providerActiveJobs] = useCollectionChildren(
    providerSchema,
    match.params.id,
    jobSchema,
    {
      queryParam: {
        query: {
          'job_providers_state_in][': [
            'pending_start',
            'pending_completion',
            'heading_to_destination',
            'heading_to_origin'
          ],
          job_providers_provider_id_eq: match.params.id
        },

        order: {
          start_time: 'desc'
        }
      }
    }
  )

  const [selectedDay, setSelectedDay] = useState(null)
  const [isInitDay, setIsInitDay] = useState(false)
  const [informationRestDay, setInformationRestDay] = useState([])
  const [informationActiveDay, setInformationActiveDay] = useState([])
  const [informationAvailableDay, setInformationAvailableDay] = useState([])

  useEffect(() => {
    if (
      providerRestDate &&
      providerActiveJobs &&
      providerAvailableDate &&
      selectedDay !== null
    ) {
      const resultRest = []
      const resultActive = []
      const resultAvailable = []

      providerRestDate.forEach((restDate) => {
        if (
          moment(restDate.start_time).get('date') === selectedDay.day &&
          moment(restDate.start_time).get('month') + 1 === selectedDay.month &&
          moment(restDate.start_time).get('year') === selectedDay.year
        ) {
          resultRest.push(restDate)
        }
      })

      providerActiveJobs.forEach((active) => {
        if (
          moment(active.get('start_time')).get('date') === selectedDay.day &&
          moment(active.get('start_time')).get('month') + 1 === selectedDay.month &&
          moment(active.get('start_time')).get('year') === selectedDay.year
        ) {
          resultActive.push(active)
        }
      })

      providerAvailableDate.forEach((available) => {
        if (
          moment(available.start_time).get('date') === selectedDay.day &&
          moment(available.start_time).get('month') + 1 === selectedDay.month &&
          moment(available.start_time).get('year') === selectedDay.year
        ) {
          resultAvailable.push(available)
        }
      })

      setInformationRestDay(resultRest)
      setInformationActiveDay(resultActive)
      setInformationAvailableDay(resultAvailable)
    }
  }, [selectedDay]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedDay = (value) => {
    setIsInitDay(false)
    setSelectedDay(value)
    setTriggerUpdate(triggerUpdate + 1)
  }

  const useStyles = makeStyles({
    containerProviderCalendar: {
      padding: '10px',
      paddingBottom: '75px'
    },
    containerInfoText: {
      paddingBottom: '10px',
      display: 'flex',
      alignItems: 'center'
    },
    infoText: {
      padding: '16px'
    },
    containerCalendarProviderInfo: {
      backgroundColor: '#ffffff !important',
      borderRadius: '8px',
      padding: '20px 36px',
      boxShadow: '0 2px 10px 0 rgba(182,182,182,0.4)',
      fontSize: '16px',
      lineHeight: '22px',
      maxWidth: '700px',
      margin: '25px auto',
      width: '100%'
    },
    textRest: {
      color: '#F84B60',
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: '0',
      lineHeight: '22px',
      padding: '12px 0'
    },
    textActive: {
      color: '#2D95F4',
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: '0',
      lineHeight: '22px',
      padding: '12px 0'
    },
    textAvailable: {
      color: '#18CB88',
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: '0',
      lineHeight: '22px',
      padding: '12px 0'
    }
  })

  const classes = useStyles()

  return (
    <Grid container className={classes.containerProviderCalendar}>
      <Grid className={classes.containerInfoText}>
        <HelpIcon />
        <Grid className={classes.infoText}>{t('info')}</Grid>
      </Grid>
      <Grid className='container-calendar-provider'>
        <Panel title={t('calendar')}>
          <Grid container spacing={3}>
            <Calendar
              isInitDay={isInitDay}
              disableDate={providerRestDate}
              handleSelectedDay={(value) => handleSelectedDay(value)}
              activeJobDate={providerActiveJobs.toJS()}
              availableDate={providerAvailableDate}
            />
          </Grid>
        </Panel>
      </Grid>
      {informationRestDay.length !== 0 ||
      informationActiveDay.length !== 0 ||
      informationAvailableDay.length !== 0 ? (
        <Grid className={classes.containerCalendarProviderInfo}>
          <div>
            {informationRestDay.map((rest) => (
              <div className={classes.textRest}>
                หยุด {moment(rest.start_time).get('hour')}:
                {moment(rest.start_time).format('mm')} -{' '}
                {moment(rest.end_time).get('hour')}:{moment(rest.end_time).format('mm')}
              </div>
            ))}
            {informationActiveDay.map((active) => (
              <div className={classes.textActive}>
                มีงาน {moment(active.get('start_time')).get('hour')}:
                {moment(active.get('start_time')).format('mm')} -{' '}
                {moment(active.get('end_time')).get('hour')}:
                {moment(active.get('end_time')).format('mm')}
              </div>
            ))}
            {informationAvailableDay.map((available) => (
              <div className={classes.textAvailable}>
                พร้อมรับงาน {moment(available.start_time).get('hour')}:
                {moment(available.start_time).format('mm')} -{' '}
                {moment(available.end_time).get('hour')}:
                {moment(available.end_time).format('mm')}
              </div>
            ))}
          </div>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  )
}

export default ProviderLeadsPane
