import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import { EditRounded, Delete } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import { useResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'
import { clientSchema } from '@seekster/schemas'

import { ClientOverviewPane } from './panes'

export function ClientShowPage ({
  setFlashMessage,
  match,
  location,
  t
}) {
  const [client] = useResource(clientSchema, match.params.id)

  const { delete: deleteClient } = useResourceAction(clientSchema)
  const push = usePush()

  function handleDeleteClient (e) {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      deleteClient(match.params.id)
        .then(response => {
          push(`/clients`)
        })
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function secondaryActions () {
    return [
      {
        avatar: <EditRounded />,
        content: t('edit'),
        to: `/clients/${match.params.id}/edit`
      },
      {
        avatar: <Delete />,
        content: t('delete'),
        onClick: handleDeleteClient
      }
    ]
  }

  function subNavbar () {
    const path = `/clients/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('common:navbar.overview')} to={path} />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs () {
    return [{
      text: t('title'),
      link: '/clients'
    }, {
      text: client.get('name')
    }]
  }

  return (
    <PageLayout
      title={client.get('name')}
      actions={secondaryActions()}
      subNavbar={subNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={client.isEmpty()}
    >
      <Switch>
        <AuthenticatedRoute path='/clients/:id' component={ClientOverviewPane} exact />
      </Switch>
    </PageLayout>
  )
}

ClientShowPage.propTypes = {
  setFlashMessage: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  t: PropTypes.func
}

export default withTranslation('clients')(ClientShowPage)
