import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'

import { usePush } from 'hooks/utils'

import PageLayout from 'components/layouts/PageLayout'
import AddressForm from 'forms/resources/AddressForm'

export function CustomerNewAddressPage ({
  customer,
  match,
  loadCustomer,
  createCustomerAddress,
  t
}) {
  useEffect(() => {
    loadCustomer(match.params.id)
  }, [loadCustomer, match.params.id])

  const push = usePush()

  function handleSubmit (data) {
    return createCustomerAddress(data, customer.get('id'))
  }

  function breadcrumbs () {
    return [{
      text: t('title'),
      link: '/customers'
    }, {
      text: customer.get('display_name'),
      link: `/customers/${match.params.id}`
    }, {
      text: t('new_address')
    }]
  }

  return (
    <PageLayout
      title={t('new_address')}
      breadcrumbs={breadcrumbs()}
    >
      <AddressForm onSubmit={handleSubmit} push={push} />
    </PageLayout>
  )
}

CustomerNewAddressPage.propTypes = {
  customer: ImmutablePropTypes.map,
  match: PropTypes.object,
  loadCustomer: PropTypes.func,
  createCustomerAddress: PropTypes.func,
  t: PropTypes.func
}

CustomerNewAddressPage.defaultProps = {
  customer: Map()
}

export default withTranslation('customers')(CustomerNewAddressPage)
