import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, destroy } from 'actions/resources'
import { trigger } from 'actions/resourceEvent'
import { getResource } from 'selectors/resources'
import { companySchema } from '@seekster/schemas'

import withFlashMessage from 'hoc/withFlashMessage'

import CompanyShowPage from './CompanyShowPage'

const mapStateToProps = (state, { match }) => ({
  company: getResource(state, match.params.id, companySchema)
})

const mapDispatchToProps = {
  fetchCompany: (id, options) => fetch(id, companySchema, options),
  destroy: (id, options) => destroy(id, companySchema, options),
  updateCompanyState: (id, event) => trigger(id, companySchema, event),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(withFlashMessage(CompanyShowPage))
