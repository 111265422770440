import { connect } from 'react-redux'

import { fetch, destroy } from 'actions/resources'
import { fetchChildren } from 'actions/collections'
import { push } from 'react-router-redux'
import { getResource } from 'selectors/resources'
import { promoCodeSchema, commentSchema } from '@seekster/schemas'

import withFlashMessage from 'hoc/withFlashMessage'

import PromoCodeShowPage from './PromoCodeShowPage'

const mapStateToProps = (state, { match }) => ({
  promoCode: getResource(state, match.params.id, promoCodeSchema)
})

const mapDispatchToProps = {
  fetchComments: (id, options) => fetchChildren(promoCodeSchema, id, commentSchema, options),
  fetchPromoCode: (id, options) => fetch(id, promoCodeSchema, options),
  destroy: (id, options) => destroy(id, promoCodeSchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(withFlashMessage(PromoCodeShowPage))
