import { connect } from 'react-redux'
import { Map } from 'immutable'

import { getResource } from 'selectors/resources'
import { customerSchema } from '@seekster/schemas'

import CompanyForm from './CompanyForm'

const mapStateToProps = (state, { values }) => {
  let customers = []

  if (values.customer_ids && values.customer_ids.length !== 0) {
    customers = values.customer_ids.map((id) => {
      const customer = getResource(state, id, customerSchema) || Map()

      return {
        value: customer.get('id'),
        label: customer.get('display_name')
      }
    })
  }

  return { customers }
}

export default connect(mapStateToProps)(CompanyForm)
