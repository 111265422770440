import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import Rating from 'components/Rating'

import styles from './ReviewPreviewStyles'

export function ReviewPreview ({ review, classes, t }) {
  return (
    <ListItem
      button
      component={Link}
      to={`/reviews/${review.get('id')}`}
    >
      <ListItemAvatar>
        <Avatar src={review.getIn(['customer', 'avatar_url'])} alt='' />
      </ListItemAvatar>

      <ListItemText
        primary={
          <Fragment>
            {
              review.get('content') ? (
                <div className={classes.contentContainer}>
                  “
                  <span className={classes.content}>{review.get('content')}</span>
                  ”
                </div>
              ) : (
                <div>-</div>
              )
            }
            <Rating value={review.get('rating')} />
          </Fragment>
        }
        secondary={`
          ${t('by')} ${review.getIn(['customer', 'display_name'])}
          ${moment(review.get('created_at')).fromNow()}
        `}
        classes={{ secondary: classes.reviewer }}
      />
    </ListItem>
  )
}

ReviewPreview.propTypes = {
  review: ImmutablePropTypes.map,
  classes: PropTypes.object,

  t: PropTypes.func
}

ReviewPreview.defaultProps = {
  review: Map()
}

export default compose(
  withStyles(styles),
  withTranslation('review')
)(ReviewPreview)
