import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Table, Column } from 'components/tables/Table'
import { Grid, Paper, Avatar } from '@material-ui/core'
import { LibraryAddRounded } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import { useCollection } from 'hooks/collections'
import useQuery from 'hooks/useQuery'

import PageLayout from 'components/layouts/PageLayout'
import Search from 'components/Search'
import FilterModal from 'components/FilterModal'
import FilterTags from 'components/tags/FilterTags'
import ServicesFilterForm from 'forms/filters/ServicesFilterForm'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import Tag from 'components/Tag'
import BooleanChip from 'components/BooleanChip'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'

import { serviceSchema } from '@seekster/schemas'
import { useContext } from 'react'
import { AppLayoutContext } from 'components/layouts/AppLayout'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  },

  icon: {
    display: 'inline-block',
    width: 20,
    height: 20,
    margin: '0 5px -5px 0'
  }
}))

export function ServiceIndexPage() {
  const classes = useStyles()
  const [query] = useQuery()
  const { t } = useTranslation('services', 'common')
  const [services, { loading, totalCount, totalPages }] = useCollection(
    serviceSchema,
    { page: query.page, query },
    [query]
  )
  const generalConfig = useContext(AppLayoutContext).generalConfig


  function primaryActions() {
    return [
      {
        avatar: <LibraryAddRounded fontSize='small' />,
        content: t('new'),
        to: '/services/new'
      }
    ]
  }

  return (
    <PageLayout title={t('title')} primaryActions={primaryActions()} isLoading={loading}>
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={10}>
            <Search />
          </Grid>

          <Grid item xs={3} sm={2}>
            <FilterModal component={ServicesFilterForm} />
          </Grid>

          <Grid item xs={12}>
            <FilterTags />

            <ResultsCountLabel value={totalCount} unit='service' />
          </Grid>
        </Grid>
      </div>

      <div className={classes.metaContainer}>
        <Paper>
          <Table collection={services}>
            <Column
              name='name'
              render={(name, service) => (
                <Link to={`/services/${service.get('id')}`}>
                  <Avatar
                    src={service.get('icon_url')}
                    alt={name}
                    className={classes.icon}
                  />
                  {name}
                </Link>
              )}
            />
            <Column name='region.name' />
            <Column name='code' component={Tag} />
            <Column
              name='categories'
              render={(categories) => (
                <ul>
                  {categories.map((category, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/categories/${category.get('id')}`}>
                          {category.get('name')}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              )}
            />
            <Column name='requestable' component={BooleanChip} />
            <Column name='registerable' component={BooleanChip} />
            <Column name='public' component={BooleanChip} />
            {
              generalConfig?.is_cancellation_enabled &&
              <Column name='cancellation_enabled' component={BooleanChip} />
            }
            {/* <Column name='cancel_reschedule' component={BooleanChip} /> */}
            <Column name='created_at' component={DateTimeFormatter} />
          </Table>
        </Paper>
      </div>

      <Grid item xs={12} container justify='center'>
        <Pagination totalPages={totalPages} />
      </Grid>
    </PageLayout>
  )
}

export default ServiceIndexPage
