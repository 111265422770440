import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Button } from 'components/buttons'
import { withTranslation } from 'react-i18next'

import DateRangeField from 'components/fields/DateRangeField'
import FastField from 'components/fields/Field'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'
import { PLATFORMS } from 'constants/platforms'
import { ACCESS_TYPES } from 'constants/accessTypes'

export class ClientsFilterForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    onCloseModal: PropTypes.func,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit () {
    this.props.handleSubmit()

    this.props.onCloseModal()
  }

  render () {
    return (
      <Form>
        <DateRangeField name='created_at' />
        <FastField
          name='official'
          component={SelectInput}
          options={arrayToOptions(['true', 'false'], { i18nNamespace: 'common', i18nKey: 'boolean' })}
        />
        <FastField
          name='platform'
          component={SelectInput}
          options={arrayToOptions(PLATFORMS, { i18nNamespace: 'common', i18nKey: 'platforms' })}
        />
        <FastField
          name='access_type'
          component={SelectInput}
          options={arrayToOptions(ACCESS_TYPES, { i18nNamespace: 'common', i18nKey: 'access_types' })}
        />

        <div>
          <Button color='primary' onClick={this.handleSubmit}>{this.props.t('apply')}</Button>

          <Button onClick={this.props.onCloseModal}>{this.props.t('cancel')}</Button>
        </div>
      </Form>
    )
  }
}

export default withTranslation('filters')(ClientsFilterForm)
