import { withFormik } from 'formik'
import { compose } from 'redux'
import { Map } from 'immutable'

import withFlashMessage from 'hoc/withFlashMessage'
import FirebaseTagForm from './FirebaseTagForm'

const formikConfig = {
  mapPropsToValues: ({ ticket = Map() }) => ({
    tagsRef: ticket.get('tagsRef') || null
  }),
  enableReinitialize: true,

  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true)
    let data = values

    if (values.tagsRef === undefined) {
      data = {
        tagsRef: [],
        ...values
      }
    }

    props.onSubmit(props.ticketId, `tenants/${props.tenant}/tickets`, data)
      .then(() => {
        setSubmitting(false)
        props.onCloseModal()
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(FirebaseTagForm)
