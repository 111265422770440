import React from 'react'
import { Table, Column } from 'components/tables/Table'
import { Paper, Button, Avatar } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { PriceFormatter } from 'components/formatters'

export function PayrollsIndexTable ({ payroll_statements, payrollId }) {
  return (
    <Paper>
      <Table collection={payroll_statements}>
        <Column
          name='provider_id_and_name'
          render={(provider_id_and_name, payroll_statements) =>
            <Link to={`/payrolls/${payrollId}/statement/${payroll_statements.get('id')}`}>
              <p>{payroll_statements.getIn(['bank_account', 'owner', 'id'])} {payroll_statements.getIn(['bank_account', 'owner', 'display_name'])}</p>
            </Link>
          }
        />
        <Column
          name='amount'
          render={(amount, payroll_statements) => <p><PriceFormatter value={payroll_statements.getIn(['total_satangs']) / 100} /></p>}
        />
        <Column
          name='bank_account_info'
          render={(bank_account_info, payroll_statements) => (
            <>
              <Avatar
                src={payroll_statements.getIn(['bank_account', 'bank', 'logo_url'])}
                alt={payroll_statements.getIn(['bank_account', 'bank', 'name'])}
                style={{ marginRight: 12, verticalAlign: 'middle', display: 'inline-block', width: 28, height: 28 }}
              />
              <span>{payroll_statements.getIn(['bank_account', 'account_name'])} • {payroll_statements.getIn(['bank_account', 'formatted_account_number'])}</span>
            </>
          )}
        />
        <Column
          name='Action'
          render={(action) => (
            <Button
              color='primary'
              variant='outlined'
            >
              EXPORT CSV
            </Button>
          )}
        />
      </Table>
    </Paper>
  )
}

export default PayrollsIndexTable
