import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Paper, Typography, Grid, Button, IconButton } from '@material-ui/core'
import { Cancel, MoreHoriz } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import AttributeLabel from 'components/labels/AttributeLabel'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import Tag from 'components/Tag'
import { LightboxImage } from 'components/images'
import { ResponsiveMenu, ResponsiveMenuItem } from 'components/menus/ResponsiveMenu'

import BankTransferPaymentReviewModal from '../BankTransferPaymentReviewModal'

import styles from './PaymentListStyles'

export class PaymentList extends React.Component {
  static propTypes = {
    invoice: ImmutablePropTypes.map,
    voidPayment: PropTypes.func,
    fetchPayments: PropTypes.func,
    classes: PropTypes.object,

    t: PropTypes.func
  }

  static defaultProps = {
    invoice: Map()
  }

  constructor(props) {
    super(props)

    this.handleOpenMenu = this.handleOpenMenu.bind(this)
    this.handleCloseMenu = this.handleCloseMenu.bind(this)

    this.state = {
      anchorEl: null,
      menuOpen: false,
      modalOpen: false,
      isLoading: false
    }
  }

  handleOpenMenu(e) {
    this.setState({ menuOpen: true, anchorEl: e.currentTarget })
  }

  handleCloseMenu() {
    this.setState({ menuOpen: false, anchorEl: null })
  }

  voidPayment(payment) {
    const { voidPayment, invoice, t } = this.props

    if (window.confirm(t('void_confirmation_prompt'))) {
      return voidPayment(invoice.get('id'), payment.get('id')).then(() =>
        this.props.fetchPayments(invoice.get('id'))
      )
    }
  }

  renderSupportContentSection(payment) {
    if (payment.get('type') === 'payment/card_payments') {
      return (
        <>
          <AttributeLabel title={this.props.t('card_number')}>
            {payment.getIn(['card', 'display_value'])
              ? payment.getIn(['card', 'display_value'])
              : this.props.t('not_saved')}
          </AttributeLabel>

          {payment.get('image_url') && (
            <AttributeLabel title={this.props.t('transfer_slip')}>
              <LightboxImage
                source={{
                  src: payment.get('image_url'),
                  thumbnail: payment.get('image_url')
                }}
              />
            </AttributeLabel>
          )}
        </>
      )
    } else if (payment.get('type') === 'payment/credit_card_payments') {
      return (
        <>
          {payment.get('image_url') && (
            <AttributeLabel title={this.props.t('transfer_slip')}>
              <LightboxImage
                source={{
                  src: payment.get('image_url'),
                  thumbnail: payment.get('image_url')
                }}
              />
            </AttributeLabel>
          )}
        </>
      )
    } else if (payment.getIn(['transfer_slip', 'image_url'])) {
      return (
        <AttributeLabel title={this.props.t('transfer_slip')}>
          <LightboxImage
            source={{
              src: payment.getIn(['transfer_slip', 'image_url']),
              thumbnail: payment.getIn(['transfer_slip', 'image_url'])
            }}
          />
        </AttributeLabel>
      )
    } else {
      return <AttributeLabel title={this.props.t('additional_info')}>-</AttributeLabel>
    }
  }

  checkAvailablePrimaryAction(payment) {
    if (payment.get('type') === 'payment/card_payments') {
      return (
        <Button
          size='small'
          href={payment.getIn(['charge', 'omise_link'])}
          target='_blank'
          className={this.props.classes.buttonBasic}
        >
          {this.props.t('view_on_omise')}
        </Button>
      )
    } else if (
      payment.get('type') === 'payment/transfer_payments' &&
      payment.get('state') === 'pending'
    ) {
      return (
        <BankTransferPaymentReviewModal
          invoiceId={this.props.invoice.get('id')}
          payment={payment}
        />
      )
    } else {
      return null
    }
  }

  checkAvailableSecondaryAction(payment) {
    if (payment.get('state') === 'captured') {
      return (
        <ResponsiveMenu
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
          anchorEl={this.state.anchorEl}
          trigger={
            <IconButton onClick={this.handleOpenMenu}>
              <MoreHoriz fontSize='small' />
            </IconButton>
          }
        >
          <ResponsiveMenuItem
            avatar={<Cancel />}
            content={this.props.t('void')}
            onClick={() => this.voidPayment(payment)}
            className={this.props.classes.voidButton}
          />
        </ResponsiveMenu>
      )
    } else {
      return null
    }
  }

  render() {
    const { invoice, classes, t } = this.props

    return invoice.get('payments', List()).map((payment) => (
      <Paper className={classes.paper} key={payment.get('id')}>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Typography variant='caption'>
              {t('id')} {payment.get('id')}
            </Typography>

            <Grid container>
              <Grid item xs={6}>
                <Typography variant='h5'>
                  {payment.getIn(['amount', 'display_value'])}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Tag value={payment.get('state')} className='payment-states' />
              </Grid>
            </Grid>

            <Typography variant='subtitle2'>
              {payment.getIn(['payment_method', 'name'])}
            </Typography>
          </Grid>

          <Grid item sm={6} xs={12} container>
            <Grid item sm={6} xs={12}>
              {this.renderSupportContentSection(payment)}
            </Grid>

            <Grid item sm={6} xs={12}>
              <AttributeLabel title={t('captured_at')}>
                <DateTimeFormatter value={payment.get('captured_at')} />
              </AttributeLabel>

              <AttributeLabel title={t('created_at')}>
                <DateTimeFormatter value={payment.get('created_at')} />
              </AttributeLabel>

              <AttributeLabel title={t('updated_at')}>
                <DateTimeFormatter value={payment.get('updated_at')} />
              </AttributeLabel>

              <AttributeLabel title={t('captured_by')}>
                {payment.get('issued_by')
                  ? `${payment.getIn(['issued_by', 'display_name'])} (${payment.getIn([
                      'issued_by',
                      'type'
                    ])})`
                  : '-'}
              </AttributeLabel>

              {payment.get('state') === 'voided' && (
                <AttributeLabel title={t('voided_by')}>
                  {payment.get('voided_by')
                    ? `${payment.getIn(['voided_by', 'display_name'])} (${payment.getIn([
                        'voided_by',
                        'type'
                      ])})`
                    : '-'}
                </AttributeLabel>
              )}
            </Grid>
          </Grid>

          <Grid item sm={2} xs={12} container className={classes.actionSection}>
            <Grid item className={classes.primaryAction}>
              {this.checkAvailablePrimaryAction(payment)}
            </Grid>

            <Grid item className={classes.secondaryAction}>
              {this.checkAvailableSecondaryAction(payment)}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    ))
  }
}

export default compose(
  withStyles(styles),
  withTranslation(['payments', 'bank_accounts', 'attributes'])
)(PaymentList)
