import React from 'react'
import PropTypes from 'prop-types'

export class Case extends React.Component {
  static propTypes = {
    render: PropTypes.func,
    component: PropTypes.node
  }

  renderCase () {
    const { render, component: Component, ...rest } = this.props

    if (render) {
      return render()
    }
    else if (Component) {
      return <Component {...rest} />
    }
    else {
      return null
    }
  }

  render () {
    return this.renderCase()
  }
}

export default Case
