import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import { inquirySchema } from '@seekster/schemas'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SkipNextIcon from '@material-ui/icons/SkipNext';
import ErrorIcon from '@material-ui/icons/Error';
import { getResource } from 'selectors/resources'
import { useSelector } from 'react-redux'
import { Map } from 'immutable'

import createStore from 'createStore'
import Modal from 'components/containers/Modal'
import { useState } from 'react'
import './RequestStateButton.scss'
import { callService } from 'utils/callService'
export const store = createStore()

function RequestStateButton() {
  let { id } = useParams()
  const inquiry =
    useSelector((state) => getResource(state, id, inquirySchema)) ||
    Map()
  const { t } = useTranslation('tags')
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const color = {
    pending_provider: '#1B4171',
    pending_start: '#F1701B',
    pending_completion: '#18ABCC',
    completed: '#21AB15',
    cancelled: '#f44336'
  }

  const handleClickOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const updateStatus = async () => {
    try {
      const response = await callService.put(`${process.env.REACT_APP_BASE_API}/inquiries/${id}/complete`)
      if (response.status === 200) {
        window.location.reload()
      }
    } catch (error) {
      setAlertMessage(error.response.data.message)
      setOpenConfirm(prev => !prev)
      setOpenAlert(prev => !prev)
    }
  }

  const handleCheckProvider = () => {
    handleClickOpenConfirm(true)
  }

  const showTag = () => {

    if (inquiry.get('deleted_at')) {
      return (
        <Button
          key={'deleted'}
          className='auto-manual-tag'
          type='button'
          variant='contained'
          disabled
          style={{ color: 'white', borderRadius: 50, backgroundColor: '#f5474f' }}
        >
          {t('deleted')}
        </Button>)
    }

    if (inquiry.get('manual_distribution')) {
      return (
        <Button
          key={'manual'}
          className='auto-manual-tag'
          type='button'
          variant='contained'
          disabled
          style={{ color: 'white', borderRadius: 50, backgroundColor: '#f5474f' }}
        >
          {t('manual')}
        </Button>)
    } else if (inquiry.get('manual_distribution') === false) {
      return (
        <Button
          key={'auto'}
          className='auto-manual-tag'
          type='button'
          variant='contained'
          disabled
          style={{ color: 'white', borderRadius: 50, backgroundColor: '#00BA32' }}
        >
          {t('auto')}
        </Button>
      )
    }
  }

  return (
    <div>
      <Button
        className='job-state-btn'
        type='button'
        variant='contained'
        onClick={() => handleCheckProvider()}
        style={{ color: 'white', borderRadius: 50, marginRight: 8, backgroundColor: color[inquiry.get('state')] }}
        disabled={inquiry.get('state') !== 'pending_provider'}
        endIcon={inquiry.get('state') === 'pending_provider' && <SkipNextIcon />}
      >
        {t(inquiry.get('state'))}
      </Button>
      {showTag()}
      {/* ============ Modal Part ============================================================= */}
      <div>
        <Dialog
          className='request-state-confirmation-modal'
          open={openConfirm}
          onClose={handleCloseConfirm}
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{}} color='primary'>
              {t('message.confirm_request_change_status')}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'stretch' }}>
            <Button style={{ flex: 1, borderWidth: 2 }} onClick={handleCloseConfirm} variant='outlined' color="primary">
              {t('no')}
            </Button>
            <Button style={{ flex: 1 }} onClick={() => updateStatus()} variant='contained' color="primary" autoFocus>
              {t('yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Modal
        className="request-state-alert"
        isOpen={openAlert}
        onCloseModal={() => setOpenAlert(false)}
        style={{ fontSize: 15, fontWeight: 'medium' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <ErrorIcon color='error' style={{ marginRight: 14, fontSize: 32 }} />
          </div>
          <div>
            {alertMessage}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default RequestStateButton
