import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'

import { DateTag, StateTag } from 'components/tags'
import { AttributesTable, Attribute } from 'components/tables/AttributesTable'

export class Details extends React.Component {
  static propTypes = {
    job: ImmutablePropTypes.map
  }

  static defaultProps = {
    job: Map()
  }

  questionAnswers () {
    const { job } = this.props
    const answers = job.get('answers') || List()

    answers.sort().map((answer, index) => {
      const question = answer.get('question') || Map()

      return (
        <Attribute key={index}
          label={question.get('name')}
          value={this.answer(answer)} />
      )
    })
  }

  answer (obj) {
    const question = obj.get('question') || Map()
    const choice = obj.get('choice') || Map()
    const choices = obj.get('choices') || List()

    switch (question.get('display_type')) {
      case 'radio': return choice.get('value')
      case 'string' : return obj.get('value')
      case 'number': return obj.get('value')
      case 'textarea': return obj.get('value')
      case 'checkboxes': return choices.map((item, index) => {
        return (
          <ul key={index}>
            <li>{item.get('value')}</li>
          </ul>
        )
      })
      default: break
    }
  }

  render () {
    const { job } = this.props

    const service = job.get('service') || Map()
    const orders = job.get('orders') || List()

    return (
      <>
        <AttributesTable>
          <Attribute label='Job No.' value={job.get('number')} />
          <Attribute label='Service' association={service} path='/services' displayField='name' />
          <Attribute label='Orders' value={
            <ul>
              {
                orders.map((order, index) => {
                  const pkg = order.get('package') || Map()
                  const price = pkg.get('price') || Map()

                  return (
                    <li key={index}>{pkg.get('name')} [ {price.get('display_value')} x {order.get('quantity')} ]</li>
                  )
                })
              }
            </ul>
          } />
          <Attribute label='Start Time' value={<DateTag value={job.get('start_time')} />} />
          <Attribute label='End Time' value={<DateTag value={job.get('end_time')} />} />
          <Attribute label='State' value={<StateTag value={job.get('state')} />} />
          {this.questionAnswers()}
          <Attribute label='Note' value={job.get('additional_information')} />
          <Attribute label='Attachments' />
          <Attribute label='Updated At' value={<DateTag value={job.get('updated_at')} />} />
          <Attribute label='Created At' value={<DateTag value={job.get('created_at')} />} />
        </AttributesTable>
      </>
    )
  }
}

export default Details
