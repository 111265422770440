import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { Send, EditRounded, MergeType, Delete } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import Tag from 'components/Tag'
import { CapitalizeFormatter } from 'components/formatters'

import {
  CustomerAddressesPane,
  CustomerBlacklistedProvidersPane,
  CustomerCommentsPane,
  CustomerConversationsPane,
  CustomerFavoriteProvidersPane,
  CustomerFeedbacksPane,
  CustomerInquiriesPane,
  CustomerInvitationsPane,
  CustomerOverviewPane,
  CustomerReferralTreePane,
  CustomerReviewsPane,
  CustomerSubscriptionsPane,
  CustomerTechPane,
  CustomerTimelinePane,
  CustomerWalletPane
} from './panes'

export function CustomerShowPage({
  customer,
  location,
  match,
  fetchComments,
  fetchCustomer,
  sendInvitation,
  setFlashMessage,
  destroy,
  push,
  t
}) {
  const { loading } = useData(() => {
    return fetchCustomer(match.params.id)
      .then(() => fetchComments(match.params.id))
  })

  function handleDeleteCustomer() {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      destroy(match.params.id)
        .then(response => {
          push('/customers')
        })
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function handleSendInvitation() {
    if (window.confirm('Are you sure send invitation this Customer ?')) {
      sendInvitation(match.params.id)
        .then(response => {
          push(`/customers/${match.params.id}/invitations`)
        })
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function renderSubtitle() {
    if (customer.get('deleted_at')) {
      return (
        <Tag
          value={t('deleted')}
          backgroundColor='#f5474f'
          color='white'
          formatter={CapitalizeFormatter}
          key='deleted'
        />
      )
    } else if (customer.get('banned')) {
      return (
        <Tag
          value={t('banned')}
          backgroundColor='#f5474f'
          color='white'
          formatter={CapitalizeFormatter}
          key='banned'
        />
      )
    }
  }

  function secondaryActions() {
    return [{
      avatar: <EditRounded />,
      content: t('edit'),
      to: `/customers/${match.params.id}/edit`
    }, {
      avatar: <Send fontSize='small' />,
      content: t('send_invitation'),
      onClick: handleSendInvitation
    },
    {
      avatar: <MergeType style={{ transform: 'rotate(180deg)' }} />,
      content: t('merge'),
      to: `/customers/${match.params.id}/account_merges`
    }, {
      avatar: <Delete />,
      content: t('delete'),
      onClick: handleDeleteCustomer
    }]
  }

  function renderSubNavbar() {
    const path = `/customers/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname.split('/', 4).join('/')}>
        <NavigationMenuItem label={t('common:navbar.overview')} to={path} replace />
        <NavigationMenuItem label={t('inquiries')} to={`${path}/inquiries`} replace />
        <NavigationMenuItem label={t('addresses')} to={`${path}/addresses`} replace />
        <NavigationMenuItem label={t('invitations')} to={`${path}/invitations`} replace />
        <NavigationMenuItem label={t('subscriptions')} to={`${path}/subscriptions`} replace />
        <NavigationMenuItem label={t('wallet')} to={`${path}/wallet`} replace />
        <NavigationMenuItem label={t('conversations')} to={`${path}/conversations`} replace />
        <NavigationMenuItem label={t('reviews')} to={`${path}/reviews`} replace />
        <NavigationMenuItem label={t('feedbacks')} to={`${path}/feedbacks`} replace />
        <NavigationMenuItem label={t('tech')} to={`${path}/tech`} replace />
        <NavigationMenuItem
          label={t('navbar.comments')}
          to={`${path}/comments`}
          badge={customer.get('comments', 0).size}
          replace
        />
        <NavigationMenuItem label={t('timeline')} to={`${path}/timeline`} replace />
        <NavigationMenuItem label={t('favorites')} to={`${path}/favorites`} replace />
        <NavigationMenuItem label={t('blacklist')} to={`${path}/blacklist`} replace />
        <NavigationMenuItem label={t('referrals')} to={`${path}/referrals`} replace />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs() {
    return [{
      text: t('title'),
      link: '/customers'
    }, {
      text: customer.get('display_name')
    }]
  }

  return (
    <PageLayout
      title={customer.get('display_name')}
      subtitle={renderSubtitle()}
      actions={secondaryActions()}
      subNavbar={renderSubNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/customers/:id' component={CustomerOverviewPane} exact />
        <AuthenticatedRoute path='/customers/:id/inquiries' component={CustomerInquiriesPane} />
        <AuthenticatedRoute path='/customers/:id/addresses' component={CustomerAddressesPane} />
        <AuthenticatedRoute path='/customers/:id/invitations' component={CustomerInvitationsPane} />
        <AuthenticatedRoute path='/customers/:id/subscriptions' component={CustomerSubscriptionsPane} />
        <AuthenticatedRoute path='/customers/:id/wallet' component={CustomerWalletPane} />
        <AuthenticatedRoute path='/customers/:id/conversations' component={CustomerConversationsPane} />
        <AuthenticatedRoute path='/customers/:id/feedbacks' component={CustomerFeedbacksPane} />
        <AuthenticatedRoute path='/customers/:id/reviews' component={CustomerReviewsPane} />
        <AuthenticatedRoute path='/customers/:id/tech' component={CustomerTechPane} />
        <AuthenticatedRoute path='/customers/:id/comments' component={CustomerCommentsPane} />
        <AuthenticatedRoute path='/customers/:id/timeline' component={CustomerTimelinePane} />
        <AuthenticatedRoute path='/customers/:id/favorites' component={CustomerFavoriteProvidersPane} />
        <AuthenticatedRoute path='/customers/:id/blacklist' component={CustomerBlacklistedProvidersPane} />
        <AuthenticatedRoute path='/customers/:id/referrals' component={CustomerReferralTreePane} />
      </Switch>
    </PageLayout>
  )
}

CustomerShowPage.propTypes = {
  customer: ImmutablePropTypes.map,
  location: PropTypes.object,
  match: PropTypes.object,
  fetchComments: PropTypes.func,
  fetchCustomer: PropTypes.func,
  sendInvitation: PropTypes.func,
  setFlashMessage: PropTypes.func,
  destroy: PropTypes.func,
  push: PropTypes.func,
  t: PropTypes.func
}

CustomerShowPage.defaultProps = {
  customer: Map()
}

export default withTranslation(['customers', 'actions', 'common'])(CustomerShowPage)
