import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'

import { usePush } from 'hooks/utils'

import PageLayout from 'components/layouts/PageLayout'
import AddressForm from 'forms/resources/AddressForm'

export function CompanyNewAddressPage ({
  company,
  match,
  loadCompany,
  createCompanyAddress,
  t
}) {
  useEffect(() => {
    loadCompany(match.params.id)
  }, [loadCompany, match.params.id])

  const push = usePush()

  function handleSubmit (data) {
    return createCompanyAddress(data, company.get('id'))
  }

  function breadcrumbs () {
    return [{
      text: t('title'),
      link: '/companies'
    }, {
      text: company.get('name'),
      link: `/companies/${match.params.id}`
    }, {
      text: t('new_address')
    }]
  }

  return (
    <PageLayout
      title={t('new_address')}
      breadcrumbs={breadcrumbs()}
    >
      <AddressForm onSubmit={handleSubmit} push={push} />
    </PageLayout>
  )
}

CompanyNewAddressPage.propTypes = {
  company: ImmutablePropTypes.map,
  match: PropTypes.object,
  loadCompany: PropTypes.func,
  createCompanyAddress: PropTypes.func,
  t: PropTypes.func
}

CompanyNewAddressPage.defaultProps = {
  company: Map()
}

export default withTranslation('companies')(CompanyNewAddressPage)
