import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable'
import { tagSchema } from '@seekster/schemas'
import { firebaseSupportPlatform } from 'utils/firebase'
import { useResourceAction } from 'hooks/firebase/resources'
import styles from './ResourceFirebaseCreateTableSelectStyles'

const db = firebaseSupportPlatform.firestore()

export function ResourceFirebaseCreateTableSelect ({
  value: collectionRef,
  classes,
  path,
  onChange,
  tenant
}) {
  const [value, setValue] = useState([])
  const [valueRef] = useState(collectionRef)
  const tagsRef = db.collection(`tenants/${tenant}/tags`)
  const { create: createTag } = useResourceAction(tagSchema)

  useEffect(() => {
    if (valueRef !== null) {
      const initialValue = []

      valueRef.map(docRef => {
        return docRef.get().then(docSnapshot => {
          initialValue.push(docSnapshot.data())
          setValue(initialValue)
        })
      })
    }
  }, [valueRef])

  function handleChange (data) {
    setValue(data)

    onChange(data.map(option => db.doc(`tenants/${tenant}/tags/` + option.id)))
  }

  const loadOptions = () => {
    return tagsRef.get()
      .then(querySnapshot => querySnapshot.docs.map(queryDocSnapshot => {
        return queryDocSnapshot.data()
      }))
  }

  function handleCreate (data) {
    createTag(path, { name: data, type: path })
      .then((response) => {
        handleChange([...value, { name: data, type: path, id: response.id }])
      })
  }

  function handleValidateNewOption (inputValue, selectValue, selectOptions) {
    const isNotDuplicated = !selectOptions.map(option => option.name).includes(inputValue)
    const isNotEmpty = inputValue !== ''

    return isNotEmpty && isNotDuplicated
  }

  return (
    <AsyncCreatableSelect
      loadOptions={loadOptions}
      menuPortalTarget={document.body}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      cacheOptions
      defaultOptions
      isClearable
      className={classes.root}
      getOptionLabel={({ name }) => name}
      getOptionValue={({ id }) => id}
      onCreateOption={handleCreate}
      isValidNewOption={handleValidateNewOption}
      getNewOptionData={(inputValue, optionLabel) => ({ id: inputValue, name: optionLabel })}
      onChange={handleChange}
      value={value}
      isMulti
    />
  )
}

ResourceFirebaseCreateTableSelect.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func,
  path: PropTypes.string,
  tenant: PropTypes.string
}

export default withStyles(styles)(ResourceFirebaseCreateTableSelect)
