import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Button } from 'components/buttons'
import { withTranslation } from 'react-i18next'

import FastField from 'components/fields/Field'
import DateRangeField from 'components/fields/DateRangeField'
import NumericRangeField from 'components/fields/NumericRangeField'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'
import { PROVIDER_STATES } from 'constants/states/providerStates'

export class ProvidersFilterForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    onCloseModal: PropTypes.func,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit () {
    this.props.handleSubmit()

    this.props.onCloseModal()
  }

  render () {
    return (
      <Form>
        <DateRangeField name='created_at' />
        <DateRangeField name='last_active_at' />
        <FastField
          name='state'
          component={SelectInput}
          options={arrayToOptions(PROVIDER_STATES, { i18nNamespace: 'providers', i18nKey: 'states' })}
        />
        <NumericRangeField
          name='rating'
          min={0} max={5} step={0.01}
          placeholder='Rating from 0 to 5 ...'
        />

        <div>
          <Button color='primary' onClick={this.handleSubmit}>{this.props.t('apply')}</Button>

          <Button onClick={this.props.onCloseModal}>{this.props.t('cancel')}</Button>
        </div>
      </Form>
    )
  }
}

export default withTranslation('filters')(ProvidersFilterForm)
