import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import pluralize from 'pluralize'
import { capitalize } from 'lodash'
import { Map, List } from 'immutable'

import UserPropertyForm from 'forms/UserPropertyForm'

import './UserProperties.scss'

export class UserProperties extends React.Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    userSchema: PropTypes.object.isRequired,

    fetchUser: PropTypes.func,
    fetchUserProperties: PropTypes.func,
    fetchPropertyDefinitions: PropTypes.func,
    propertyDefinitions: ImmutablePropTypes.list,
    user: ImmutablePropTypes.map
  }

  static defaultProps = {
    propertyDefinitions: List(),
    user: Map()
  }

  constructor (props) {
    super(props)

    this.handleUpdateData = this.handleUpdateData.bind(this)
  }

  componentDidMount () {
    this.handleUpdateData()
  }

  handleUpdateData () {
    const { fetchUser, fetchUserProperties, fetchPropertyDefinitions, userId, userSchema } = this.props
    const category = capitalize(pluralize.singular(userSchema._key))

    fetchUser(userId, userSchema)
    fetchUserProperties(userId, userSchema)
    fetchPropertyDefinitions(category)
  }

  render () {
    const { userId, userSchema, propertyDefinitions, user } = this.props

    return (
      <div className='container'>
        {
          propertyDefinitions
            .map(propertyDefinition => {
              const propertiesValue = user.get('properties', List()).find(property => (
                property.getIn(['property_definition', 'id']) === propertyDefinition.get('id')
              )) || Map()
              const userProperty = {
                propertyDefinition: {
                  id: propertyDefinition.get('id'),
                  name: propertyDefinition.get('name')
                },
                property: {
                  id: propertiesValue.get('id'),
                  value: propertiesValue.get('value', '')
                }
              }

              return (
                <UserPropertyForm
                  key={propertyDefinition.get('id')}
                  userProperty={userProperty}
                  onUpdateData={this.handleUpdateData}
                  userSchema={userSchema}
                  userId={userId}
                  category={capitalize(pluralize.singular(userSchema._key))}
                />
              )
            })
        }
        {/* <label>New Property</label> */}
        <UserPropertyForm
          onUpdateData={this.handleUpdateData}
          userSchema={userSchema}
          userId={userId}
          category={capitalize(pluralize.singular(this.props.userSchema._key))}
        />
      </div>
    )
  }
}

export default UserProperties
