import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Grid } from '@material-ui/core'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import ButtonLoader from 'components/buttons/ButtonLoader'
import Panel from 'components/containers/Panel'
import { LocalizedField } from 'components/fields/Field'

import styles from './IssueFormStyles'

export function IssueForm ({ classes, t, isSubmitting }) {
  return (
    <Grid container justify='center' component={Form}>
      <Grid item xs={12} sm={9}>
        <Panel title={t('title')}>
          <div className={classes.formContainer}>
            <LocalizedField name='name' />

            <ButtonLoader
              type='submit'
              variant='contained'
              color='primary'
              className={classes.button}
              style={{ float: 'right' }}
              isLoading={isSubmitting}
            >
              {t('save')}
            </ButtonLoader>
          </div>
        </Panel>
      </Grid>
    </Grid>
  )
}

IssueForm.propTypes = {
  classes: PropTypes.object,
  isSubmitting: PropTypes.bool,

  t: PropTypes.func
}

export default compose(
  withStyles(styles),
  withTranslation('issues')
)(IssueForm)
