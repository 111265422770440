import { withFormik } from 'formik'
import { List } from 'immutable'
import * as Yup from 'yup'

import IntegrationConfigForm from './IntegrationConfigForm'

const formikConfig = {
  mapPropsToValues: ({ integrationConfigs = List() }) => ({
    integration_configurations_attributes: integrationConfigs.map(integrationConfig => ({
      id: integrationConfig.get('id'),
      value: integrationConfig.get('value') || ''
    })).toJS()
  }),

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    integration_configurations_attributes: Yup.array(Yup.object().shape({
      value: Yup.string()
    }))
  }),

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values)
      .catch(error => {
        setSubmitting(false)

        setErrors({
          message: error.response.body && error.response.body.message
        })
      })
  }
}

export default withFormik(formikConfig)(IntegrationConfigForm)
