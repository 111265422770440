import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { create } from 'actions/resources'
import { companySchema } from '@seekster/schemas'

import CompanyNewPage from './CompanyNewPage'

const mapDispatchToProps = {
  createCompany: (data, options) => create(data, companySchema, options),
  push
}

export default connect(null, mapDispatchToProps)(CompanyNewPage)
