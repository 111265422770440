import { connect } from 'react-redux'

import { getCollection } from 'selectors/collections'
import { fetch } from 'actions/collections'
import { feedbackSchema } from '@seekster/schemas'

import FeedbackPreviewsList from './FeedbackPreviewsList'

const mapStateToProps = state => ({
  feedbacks: getCollection(state, feedbackSchema)
})

const mapDispatchToProps = {
  fetchFeedbacks: options => fetch(feedbackSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackPreviewsList)
