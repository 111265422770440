import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import { AttributesTable, Attribute } from 'components/tables/AttributesTable'

export class ServiceTermsPane extends React.Component {
  static propTypes = {
    service: ImmutablePropTypes.map,
    t: PropTypes.func
  }

  static defaultProps = {
    service: Map()
  }

  render() {
    const { service, t } = this.props

    return (
      <Panel title={t('terms_and_conditions')}>
        <AttributesTable>
          <Attribute label={t('terms_en')} value={service.get('terms_en')} />
          <Attribute label={t('terms_th')} value={service.get('terms_th')} />
          <Attribute label={t('description_en')} value={service.get('description_en')} />
          <Attribute label={t('description_th')} value={service.get('description_th')} />
        </AttributesTable>
      </Panel>
    )
  }
}

export default withTranslation('services')(ServiceTermsPane)
