export default ({ palette }) => ({
  addButton: {
    border: `2px solid ${palette.primary.main}`,
    color: '#47525D',
    fontSize: 16,
    height: 44,
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.2)',
    '&:hover': {
      borderWidth: 2,
      borderColor: palette.primary.main,
      opacity: 0.8
    }
  },
  icon: {
    marginRight: 15
  }
})
