import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { connect, getIn } from 'formik'

import { FastField, LocalizedField } from 'components/fields/Field'
import MoneyField from 'components/fields/MoneyField'
import RichTextInput from 'components/inputs/RichTextInput'
import ResourceSelect from 'components/inputs/ResourceSelect'
import LocaleContext from 'contexts/LocaleContext'
import SortableFormSection from 'components/fields/SortableFieldArray/SortableFormSection'
import SortableFieldArray from 'components/fields/SortableFieldArray'
import { badgeSchema } from '@seekster/schemas'

export class ServiceScopeFormSection extends Component {
  static contextType = LocaleContext

  static propTypes = {
    name: PropTypes.string,
    hasChildren: PropTypes.bool,
    formik: PropTypes.object
  }

  static defaultProps = {
    hasChildren: false
  }

  render() {
    const { name, hasChildren, formik } = this.props
    const locale = this.context

    const values = getIn(formik.values, name)

    return (
      <SortableFormSection title={values[`name_${locale}`]} {...this.props}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LocalizedField name={`${name}.name`} required />
          </Grid>

          <Grid item xs={12} sm={6}>
            <LocalizedField name={`${name}.unit`} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField name={`${name}.code`} required />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField name={`${name}.item_type`} />
          </Grid>

          <Grid item xs={12}>
            <LocalizedField name={`${name}.description`} component={RichTextInput} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MoneyField name={`${name}.price`} required />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MoneyField name={`${name}.payout`} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MoneyField name={`${name}.minimum_price`} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MoneyField name={`${name}.minimum_payout`} />
          </Grid>

          <Grid item xs={12} sm={3}>
            <FastField type='number' name={`${name}.minimum_amount`} />
          </Grid>

          <Grid item xs={12} sm={3}>
            <FastField type='number' name={`${name}.maximum_amount`} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField
              type='number'
              name={`${name}.duration`}
              hint='To be Fixed (For now, add duration in minutes)'
              required
            />
          </Grid>

          <Grid item xs={12}>
            <FastField
              name={`${name}.badge_ids`}
              component={ResourceSelect}
              schema={badgeSchema}
              isMulti
            />
          </Grid>

          {hasChildren && (
            <Grid item xs={12}>
              <SortableFieldArray
                name={`${name}.children_attributes`}
                component={ConnectedServiceScopeFormSection}
              />
            </Grid>
          )}
        </Grid>
      </SortableFormSection>
    )
  }
}

const ConnectedServiceScopeFormSection = connect(ServiceScopeFormSection)

export default ConnectedServiceScopeFormSection
