import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Grid, Paper } from '@material-ui/core'
import { LocationOn, Phone } from '@material-ui/icons'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import LightboxImage from 'components/images/LightboxImage'

import Panel from 'components/containers/Panel'
import QuestionsAndAnswers from 'components/lists/QuestionsAndAnswers'
import Rating from 'components/Rating'
import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'

import {
  DateFormatter,
  PhoneNumberFormatter,
  DateTimeFormatter
} from 'components/formatters'

import styles from './JobProvidersReportListStyles'

export function JobProvidersReportList({ jobProviders, classes, t }) {
  return (
    <Grid container spacing={3}>
      {jobProviders.map((jobProvider) => (
        <Grid item xs={12} sm={6} key={jobProvider.get('id')}>
          <Panel>
            <Grid container>
              <Grid item xs={5} sm={5}>
                <Grid container justify='center' alignItems='center'>
                  <LightboxImage
                    source={{
                      src: jobProvider.getIn(['provider', 'avatar_url']),
                      thumbnail: jobProvider.getIn(['provider', 'avatar_url'])
                    }}
                    isAvatar
                  />
                </Grid>
              </Grid>

              <Grid item xs={7} sm={7}>
                <Grid className={classes.textContainer}>
                  <Link
                    className={classes.title}
                    to={`/providers/${jobProvider.getIn(['provider', 'id'])}`}
                  >
                    {jobProvider.getIn(['provider', 'display_name'])}
                  </Link>
                </Grid>

                <Grid className={classes.textContainer}>
                  <Rating value={jobProvider.getIn(['provider', 'rating'])} />
                </Grid>

                <Grid className={classes.textContainer}>
                  <Phone className={classes.small} />{' '}
                  <PhoneNumberFormatter
                    value={jobProvider.getIn(['provider', 'phone_number'])}
                    className={classes.small}
                  />
                </Grid>
              </Grid>
            </Grid>

            {jobProvider.get('start_latitude') ? (
              <div style={{ width: '100%', overflow: 'hidden', height: '400px' }}>
                <iframe
                  title='customer location'
                  src={`https://maps.google.com/maps/embed/v1/place?key=AIzaSyALQf_mHm2Kr5adNu_XQZDM_JBo-ZHoIHE&q=${jobProvider.get(
                    'start_latitude'
                  )},${jobProvider.get('start_longitude')}`}
                  width='100%'
                  height='400'
                  frameBorder='0'
                  loading='lazy'
                  // style={{ border: 0, marginTop: '-150px' }}
                  style={{ border: 0 }}
                />
              </div>
            ) : (
              <div className={classes.locationEmpty}>{t('no_location')}</div>
            )}

            <Grid container>
              <Grid item xs={6} sm={6} className={classes.markerContainer}>
                <LocationOn className={classes.marker} color='error' />
                <DateTimeFormatter value={jobProvider.getIn(['start_time'])} />
              </Grid>

              <Grid item xs={6} sm={6} className={classes.markerContainer}>
                <LocationOn className={classes.marker} color='secondary' />
                <DateTimeFormatter value={jobProvider.getIn(['end_time'])} />
              </Grid>

              <Grid container className={classes.withBorderTop}>
                <Grid item xs={4} sm={4} className={classes.textBorder}>
                  Date :
                </Grid>

                <Grid item xs={8} sm={8}>
                  <DateFormatter value={jobProvider.getIn(['depart_time', '0'])} />
                </Grid>
              </Grid>

              <Grid container className={classes.state}>
                <Grid item xs={4} sm={4} className={classes.textBorder}>
                  Time :
                </Grid>

                <Grid item xs={8} sm={8}>
                  {moment(jobProvider.getIn(['depart_time', '0'])).format('HH:mm')} -{' '}
                  {moment(jobProvider.get('end_time')).format('HH:mm')}
                </Grid>
              </Grid>
            </Grid>
          </Panel>
          {!jobProvider.get('answers').isEmpty() && (
            <QuestionsAndAnswers answers={jobProvider.get('answers')} />
          )}

          {jobProvider.get('signature') && (
            <Paper className={classes.signatureSection}>
              <AttributesTable resource={jobProvider.get('signature')} basic='very'>
                <Attribute
                  label={t('signature')}
                  name='file_url'
                  render={(url) => (
                    <img className={classes.signatureImage} src={url} alt='signature' />
                  )}
                />
                <Attribute name='created_at' component={DateTimeFormatter} />
              </AttributesTable>
            </Paper>
          )}
        </Grid>
      ))}
    </Grid>
  )
}

JobProvidersReportList.propTypes = {
  jobProviders: ImmutablePropTypes.list,
  classes: PropTypes.object,

  t: PropTypes.func
}

JobProvidersReportList.defaultProps = {
  jobProviders: List()
}

export default compose(
  withStyles(styles),
  withTranslation('jobs')
)(JobProvidersReportList)
