import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import { DateTag } from 'components/tags'
import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'

export function PayoutOverviewPane ({ payout, t }) {
  return (
    <Panel title={t('title')}>
      <AttributesTable resource={payout}>
        <Attribute name='id' />
        <Attribute
          name='amount'
          render={(amount, payout) => <p>{payout.getIn(['amount', 'full_display'])}</p>}
        />
        <Attribute
          name='description'
          render={(description, payout) => <p>{payout.get('description')}</p>}
        />
        <Attribute
          name='job.number'
          render={(job, payout) => (
            <Link to={`/jobs/${payout.getIn(['job', 'id'])}`}>{payout.getIn(['job', 'number'])}</Link>
          )}
        />
        <Attribute
          name='provider'
          render={(provider, payout) => (
            <Link to={`/providers/${payout.getIn(['provider', 'id'])}`}>{payout.getIn(['provider', 'display_name'])}</Link>
          )}
        />
        <Attribute name='created_at' component={DateTag} />
      </AttributesTable>
    </Panel>
  )
}

PayoutOverviewPane.propTypes = {
  payout: ImmutablePropTypes.map,
  t: PropTypes.func
}

PayoutOverviewPane.defaultProps = {
  payout: Map()
}

export default withTranslation('payouts')(PayoutOverviewPane)
