import React from 'react'
import PropTypes from 'prop-types'

import CircularLoader from 'components/CircularLoader'
import SnackBarContent from 'components/SnackBarContent'
import { PageHeader } from 'components/layouts'
import { useDocumentTitle, useFlashMessage } from 'hooks/interface'

import useStyles from './styles'
import './pageLayout.scss'

export function PageLayout({
  title,
  subtitle,
  breadcrumbs,
  actions,
  primaryActions,
  secondaryActions,
  subNavbar,
  children,
  isLoading,
  linkAction,
  avatar,
  back
}) {
  const [flashMessage, clearFlashMessage] = useFlashMessage()
  useDocumentTitle(title)

  const classes = useStyles({
    sections: React.Children.map(children, (child) => child.props.position)
  })

  return (
    <div className='page-layout'>
      <PageHeader
        title={title}
        subtitle={subtitle}
        avatar={avatar}
        primaryActions={primaryActions}
        secondaryActions={secondaryActions}
        actions={actions}
        navbar={subNavbar}
        linkAction={linkAction}
        back={back}
      />

      {flashMessage && (
        <SnackBarContent
          type={flashMessage.get('messageType')}
          message={flashMessage.get('message')}
          onClose={clearFlashMessage}
        />
      )}

      <div className={classes.content}>{isLoading ? <CircularLoader /> : children}</div>
    </div>
  )
}

PageLayout.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  secondaryActions: PropTypes.node,
  linkAction: PropTypes.object,
  back: PropTypes.string,
  avatar: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string
    })
  ),
  actions: PropTypes.arrayOf(PropTypes.object),
  primaryActions: PropTypes.arrayOf(PropTypes.object),
  subNavbar: PropTypes.node,
  children: PropTypes.node,
  isLoading: PropTypes.bool
}

export default PageLayout
