import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import {
  withMobileDialog, Grid
} from '@material-ui/core'
import { useSelector } from 'react-redux'

import { useCollection } from 'hooks/collections'
import { useResourceAction } from 'hooks/resources'
import TenantProviderConfigForm from 'forms/actions/TenantProviderConfigForm'
import { getCurrentResource } from 'selectors/authentication'

import { tenantSchema, providerConfigurationSchema } from '@seekster/schemas'

export function ProviderSettingsPane ({
  fullScreen,
  fetchProviderConfig
}) {
  const tenant = useSelector(state => getCurrentResource(state, tenantSchema)) || Map()
  const [providerConfig] = useCollection(providerConfigurationSchema)
  const { create: createProviderConfig, update: updateProviderConfig } = useResourceAction(providerConfigurationSchema)

  useEffect(() => {
    fetchProviderConfig()
  }, [fetchProviderConfig])

  function handleUpdateAccount (data) {
    return updateProviderConfig('', data).then(() => fetchProviderConfig)
  }

  function handleCreateAccount (data) {
    return createProviderConfig(data).then(() => fetchProviderConfig)
  }

  return (
    <Grid container spacing={24}>
      <Grid item xs={12} sm={12}>
        <TenantProviderConfigForm
          providerConfig={tenant.get('provider_configuration')}
          onSubmit={providerConfig.isEmpty() ? handleCreateAccount : handleUpdateAccount}
        />
      </Grid>
    </Grid>
  )
}

ProviderSettingsPane.propTypes = {
  fullScreen: PropTypes.bool,
  fetchProviderConfig: PropTypes.func
}

export default withMobileDialog()(ProviderSettingsPane)
