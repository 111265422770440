import React from 'react'
import PropTypes from 'prop-types'
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable'

export class ResourceCreatableSelect extends React.Component {
  static propTypes = {
    schema: PropTypes.object.isRequired,
    parentSchema: PropTypes.object.isRequired,
    parentId: PropTypes.number,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.array
    ]),
    onChange: PropTypes.func,
    fetchOptions: PropTypes.func,
    fetchChildren: PropTypes.func,
    createResource: PropTypes.func
  }

  static defaultProps = {
    schema: {}
  }

  constructor (props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleCreate = this.handleCreate.bind(this)

    this.state = {
      value: []
    }
  }

  componentDidMount () {
    const { value, fetchChildren, parentSchema, parentId, schema } = this.props

    if (value && parentSchema && parentId) {
      fetchChildren(parentSchema, parentId, schema)
        .then(({ body }) => (
          this.setState({ value: value.map(id => body.find(option => option.id === id)) })
        ))
    }
  }

  loadOptions = (query) => {
    return (
      this.props.fetchOptions(this.props.schema, query)
        .then(({ body }) => body)
    )
  }

  handleChange (data) {
    this.setState({ value: data })

    this.props.onChange(data.map(option => option.id))
  }

  handleCreate (value) {
    this.props.createResource({ name: value }, this.props.schema)
      .then(({ body }) => this.handleChange([...this.state.value, body]))
  }

  handleValidateNewOption (inputValue, selectValue, selectOptions) {
    const isNotDuplicated = !selectOptions.map(option => option.name).includes(inputValue)
    const isNotEmpty = inputValue !== ''

    return isNotEmpty && isNotDuplicated
  }

  render () {
    return (
      <AsyncCreatableSelect
        loadOptions={this.loadOptions}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        cacheOptions
        defaultOptions
        isClearable
        getOptionLabel={({ name }) => name}
        getOptionValue={({ id }) => id}
        onCreateOption={this.handleCreate}
        isValidNewOption={this.handleValidateNewOption}
        getNewOptionData={(inputValue, optionLabel) => ({ id: inputValue, name: optionLabel })}
        // {...this.props}
        onChange={this.handleChange}
        value={this.state.value}
        isMulti
      />
    )
  }
}

export default ResourceCreatableSelect
