import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Grid } from '@material-ui/core'

import CircularLoader from 'components/CircularLoader'

import InquiriesTable from 'components/tables/InquiriesTable'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'

export class CustomerInquiriesPane extends React.Component {
  static propTypes = {
    customer: ImmutablePropTypes.map,
    match: PropTypes.object,
    fetchCustomerInquiries: PropTypes.func,
    query: PropTypes.object
  }

  static defaultProps = {
    customer: Map(),

    fetchCustomerInquiries: () => {}
  }

  state = {
    loading: false,
    error: null,
    totalCount: 0,
    totalPages: 1
  }

  componentDidMount () {
    this.setState({ loading: true })

    this.props.fetchCustomerInquiries(this.props.match.params.id)
      .then(response => {
        this.setState({
          loading: false,
          totalCount: parseInt(response.headers['total'], 10),
          totalPages: Math.ceil(response.headers['total'] / response.headers['per-page'])
        })
      })
      .catch(error => {
        this.setState({ loading: false, error: error.message })
      })
  }

  componentDidUpdate (prevProps, prevState) {
    this.props.query.page !== prevProps.query.page && this.handleUpdateData()
  }

  handleUpdateData () {
    const { page } = this.props.query

    this.setState({ loading: true })

    this.props.fetchCustomerInquiries(this.props.match.params.id, { page })
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(error => {
        this.setState({ loading: false, error: error.message })
      })
  }

  render () {
    const { loading, totalCount, totalPages } = this.state
    const inquiries = this.props.customer.get('inquiries') || List()

    return (
      <div style={{ padding: '14px 0' }}>
        <ResultsCountLabel value={totalCount} unit='inquiry' />

        {
          loading && <CircularLoader />
        }

        {
          !loading && totalCount !== 0 && (
            <>
              <InquiriesTable inquiries={inquiries} />

              <Grid item xs={12} container justify='center'>
                <Pagination totalPages={totalPages} />
              </Grid>
            </>
          )
        }
      </div>
    )
  }
}

export default CustomerInquiriesPane
