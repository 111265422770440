import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import { SnackbarContent, Snackbar, IconButton } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

import styles from './SnackBarContentStyles'

const typesIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon
}

export function SnackBarContent ({ classes, message, type, onClose }) {
  const Icon = typesIcon[type]
  const [open, setOpen] = useState(true)

  function handleClose () {
    setOpen(false)
    onClose && onClose()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={open}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes[type]}
        aria-describedby='client-snackbar'
        message={
          <span id='client-snackbar' className={classes.message}>
            <Icon className={classes.icon} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={handleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  )
}

SnackBarContent.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error']).isRequired,
  classes: PropTypes.object,
  onClose: PropTypes.func
}

export default withStyles(styles)(SnackBarContent)
