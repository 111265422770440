import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'
import { useTranslation } from 'react-i18next'

import './Tag.scss'

export function Tag ({ value, formatter: Formatter, color, backgroundColor, className, i18nKey, ...rest }) {
  const { t } = useTranslation('tags')

  let parsedValue = value

  if (Formatter) {
    parsedValue = <Formatter value={t(`${value}`)} />
  }
  else if (i18nKey) {
    parsedValue = t(i18nKey + '.' + value)
  }

  return (
    <span
      className={classNames(['tag', className, { [value]: !color && !backgroundColor }])}
      style={{ color, backgroundColor }}
      {...rest}
    >
      {parsedValue || '-'}
    </span>
  )
}

Tag.propTypes = {
  value: PropTypes.string,
  formatter: PropTypes.func,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  i18nKey: PropTypes.string
}

export default Tag
