import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Tabs } from '@material-ui/core'

import styles from './NavigationMenuBarStyles'
import './NavigationMenuBar.scss'

export function NavigationMenuBar ({ children, path, classes, ...rest }) {
  const [currentTab, setCurrentTab] = useState(0)

  useEffect(() => {
    React.Children.map(children, ({ props: childProps }, i) => (
      childProps.to === path && setCurrentTab(i)
    ))
  }, [children, path])

  function handleChangeTab (e, tab) {
    setCurrentTab(tab)
  }

  return (
    <Tabs
      value={currentTab}
      onChange={handleChangeTab}
      indicatorColor='primary'
      textColor='primary'
      variant='scrollable'
      scrollButtons='on'
      classes={classes}
      {...rest}
    >
      {children}
    </Tabs>
  )
}

NavigationMenuBar.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
  classes: PropTypes.object
}

export default withStyles(styles)(NavigationMenuBar)
