import React from 'react'
import { Grid } from '@material-ui/core'
import ButtonLoader from 'components/buttons/ButtonLoader'

import { Form } from 'formik'
import { withTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import { Field } from 'components/fields/Field'

const useStyles = makeStyles(() => ({
  content: {
    padding: '20px 20px 10px 20px'
  },
  title: {
    fontSize: '16px',
    fontWeight: '600'
  }
}))

export function PopularServiceForm ({ isSubmitting, t, values }) {
  const classes = useStyles()

  const clientAvailable = (index) => (
    index <= 1 ? 'แสดงผลบน Desktop / Mobile' : 'แสดงผลบน Desktop '
  )

  return (
    <Form>
      <Grid container className={classes.content}>
        <Grid item xs={12} sm={9}>
          <Grid item xs={12}>
            <span className={classes.title}>{values.name}</span> {clientAvailable(values.index)}
          </Grid>
          <Grid item xs={12}>
            <Field name='position' />
          </Grid>
          <Grid container justify='flex-end'>
            <ButtonLoader
              type='submit'
              variant='contained'
              color='primary'
              isLoading={isSubmitting}
              className={classes.button}
            >
              {t('save')}
            </ButtonLoader>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  )
}

export default withTranslation(['actions'])(PopularServiceForm)
