import moment from 'moment'
import { get } from 'lodash'

export const badgesQuery = ({ search, created_at, updated_at }) => {
  return {
    translations_name_cont: search,

    created_at_gteq: get(created_at, 'from')
      ? moment(created_at.from).startOf('day').toISOString(true)
      : undefined,
    created_at_lteq: get(created_at, 'to')
      ? moment(created_at.to).endOf('day').toISOString(true)
      : undefined,

    updated_at_gteq: get(updated_at, 'from')
      ? moment(updated_at.from).startOf('day').toISOString(true)
      : undefined,
    updated_at_lteq: get(updated_at, 'to')
      ? moment(updated_at.to).endOf('day').toISOString(true)
      : undefined
  }
}

export default badgesQuery
