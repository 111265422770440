import React, { useContext, useState } from 'react'
import { InputBase, InputAdornment } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Search as SearchIcon } from '@material-ui/icons'

import { AssignProvidersListPanelContext } from '../AssignProvidersListPanel'

const useStyles = makeStyles(({ spacing, shape, palette, transitions }) => ({
  root: {
    backgroundColor: 'white',
    padding: spacing(1, 2),
    border: '1px solid',
    borderColor: palette.grey[300],
    borderRadius: shape.borderRadius,
    transition: transitions.create(['border-color'])
  },
  button: {
    height: '46px'
  },
  focused: {
    borderColor: palette.primary.main
  }
}))

export function ProviderSearch() {
  const classes = useStyles()
  const [typingTimeout, setTypingTimeout] = useState(0);
  const context = useContext(AssignProvidersListPanelContext)

  const handleChange = (event) => {
    const value = event.target.value

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        context.setSearch(value);
      }, 500)
    );
  };

  return (
    <>
      <InputBase
        defaultValue={context.search}
        placeholder='Search...'
        // onKeyDown={handleKeyDown}
        onChange={handleChange}
        classes={classes}
        color='primary'
        fullWidth
        endAdornment={
          <InputAdornment position='end'>
            <SearchIcon />
          </InputAdornment>
        }
      />
    </>
  )
}

export default ProviderSearch
