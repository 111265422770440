import React from 'react'
import PropTypes from 'prop-types'
import { startCase } from 'lodash'

export class StartCaseFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired
  }

  render () {
    return startCase(this.props.value)
  }
}

export default StartCaseFormatter
