export default theme => ({
  gallery: {
    display: 'flex',
    alignItems: 'stretch',
    overflow: 'auto hidden',

    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },

  avatar: {
    width: 140,
    height: 140,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3
  },

  thumbnailContainer: {
    display: 'inline-block',

    '&:not(:last-child)': {
      marginRight: theme.spacing.unit
    }
  },

  thumbnail: {
    maxHeight: 100,
    borderRadius: 4,
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2)',
    maxWidth: '100vh',

    '@media screen and (max-width: 767px)': {
      maxWidth: 450
    },

    '@media screen and (max-width: 1350px)': {
      maxWidth: 350
    },

    '@media screen and (max-width: 1220px)': {
      maxWidth: 200
    }
  }
})
