import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './BadgeIconStyles'

export class BadgeIcon extends PureComponent {
  static propTypes = {
    badge: ImmutablePropTypes.map,

    classes: PropTypes.object
  }

  static defaultProps = {
    badge: Map()
  }

  render () {
    const { badge, classes } = this.props

    return (
      <Tooltip title={badge.get('name')} placement='top'>
        <Link to={`/badges/${badge.get('id')}`}>
          <img
            className={classes.root}
            src={badge.get('icon_url')}
            alt={badge.get('name')}
          />
        </Link>
      </Tooltip>
    )
  }
}

export default withStyles(styles)(BadgeIcon)
