import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { jobSchema, eventSchema } from '@seekster/schemas'

import JobTimelinePage from './JobTimelinePage'

const mapStateToProps = (state, { match }) => ({
  job: getResource(state, match.params.id, jobSchema)
})

const mapDispatchToProps = {
  loadJobEvent: (id, options) => fetchChildren(jobSchema, id, eventSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(JobTimelinePage)
