import { connect } from 'react-redux'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'
import { getCollection, getTotalCount, getTotalPages } from 'selectors/collections'
import { fetch } from 'actions/collections'
import { agentSchema } from '@seekster/schemas'

import AgentIndexPage from './AgentIndexPage'

const mapStateToProps = (state) => ({
  agents: getCollection(state, agentSchema),
  totalCount: getTotalCount(state, agentSchema),
  totalPages: getTotalPages(state, agentSchema)
})

const mapDispatchToProps = {
  fetchAgents: options => fetch(agentSchema, options)
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(AgentIndexPage)
