import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox as MaterialCheckbox } from '@material-ui/core'

export function Checkbox({ field }) {
  return (
    <MaterialCheckbox
      {...field}
      checked={typeof field.value === 'string' ? field.value === 'true' : field.value}
    />
  )
}

Checkbox.propTypes = {
  field: PropTypes.object
}

export default Checkbox
