import moment from 'moment'
import { get } from 'lodash'

export const payoutsQuery = ({
  search,
  amount_satangs,
  created_at
}) => {
  return {
    id_eq: search,

    amount_satangs_gteq: get(amount_satangs, 'min')
      ? amount_satangs.min / 1.07
      : undefined,
    amount_satangs_lteq: get(amount_satangs, 'max')
      ? amount_satangs.max / 1.07
      : undefined,
    subtotal_currency_eq: get(amount_satangs, 'currency'),

    created_at_gteq: get(created_at, 'from')
      ? moment(created_at.from).startOf('day').toISOString(true)
      : undefined,
    created_at_lteq: get(created_at, 'to')
      ? moment(created_at.to).endOf('day').toISOString(true)
      : undefined
  }
}

export default payoutsQuery
