import React, { Component } from 'react'

export const withFormState = (initialState) => {
  return (WrappedComponent) => (
    class WithFormState extends Component {
      constructor (props) {
        super(props)

        this.handleSetFormState = this.handleSetFormState.bind(this)

        this.state = initialState
      }

      handleSetFormState (formState) {
        this.setState({ ...formState })
      }

      render () {
        return (
          <WrappedComponent
            {...this.props}
            formState={{ ...this.state }}
            setFormState={this.handleSetFormState}
          />
        )
      }
    }
  )
}

export default withFormState
