import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Grid } from '@material-ui/core'

import Panel from 'components/containers/Panel'
import { DateTag, BooleanTag } from 'components/tags'

import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'

export class CategoryOverviewPane extends React.Component {
  render () {
    const { category } = this.props

    return (
      <Grid container>
        <Grid item sm={6}>
          <Panel title='Category Details'>
            <AttributesTable resource={category}>
              <Attribute name='id' />
              <Attribute name='name_en' />
              <Attribute name='name_th' />
              <Attribute name='slug' />
              <Attribute name='region.name' />
              <Attribute name='active' component={BooleanTag} />
              <Attribute name='updated_at' component={DateTag} />
              <Attribute name='created_at' component={DateTag} />
            </AttributesTable>
          </Panel>
        </Grid>

        <Grid item sm={6}>
          <Panel title='Category Images'>
            <AttributesTable resource={category}>
              <Attribute
                name='icon_url'
                render={iconUrl => <img src={iconUrl} style={{ maxWidth: '100%' }} width={80} alt='tiny' />}
              />
              <Attribute
                name='banner_url'
                render={bannerUrl => <img src={bannerUrl} style={{ maxWidth: '100%' }} width={450} alt='large' />}
              />
              <Attribute
                name='thumbnail_url'
                render={thumbnailUrl => <img src={thumbnailUrl} style={{ maxWidth: '100%' }} width={150} alt='small' />}
              />
            </AttributesTable>
          </Panel>
        </Grid>
      </Grid>
    )
  }
}

CategoryOverviewPane.propTypes = {
  category: ImmutablePropTypes.map
}

CategoryOverviewPane.defaultProps = {
  category: Map()
}

export default CategoryOverviewPane
