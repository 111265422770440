import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  NativeSelect,
  InputLabel,
  FormControlLabel,
  FormHelperText,
  Checkbox
} from '@material-ui/core'
import { range } from 'lodash'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import styles from './TimeSelectStyles'

function SelectModule({
  type,
  value,
  onChange,
  options,
  paddedOptionLabel,
  disabled,
  classes,
  t
}) {
  function handleChange(e) {
    onChange && onChange(e.target.name, parseInt(e.target.value, 10))
  }

  return (
    <FormControl className={classes.selectModule}>
      <InputLabel>{t(type)}</InputLabel>

      <NativeSelect value={value} onChange={handleChange} inputProps={{ name: type }}>
        {options.map((option) => (
          <option value={option} key={option} disabled={disabled}>
            {paddedOptionLabel ? option.toString().padStart(2, '0') : option}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  )
}

const Select = compose(withStyles(styles), withTranslation('common'))(SelectModule)

export function TimeSelect({
  hour,
  minute,
  onChange,
  startHour,
  endHour,
  disabled,
  additionalInfo,
  classes,
  handleCheckbox,
  todayCheckbox,
  t
}) {
  function handleChange(type, value) {
    let currentHour = hour || startHour
    let currentMinute = minute

    switch (type) {
      case 'hour':
        currentHour = value
        break
      case 'minute':
        currentMinute = value
        break
      default:
        break
    }

    onChange && onChange({ hour: currentHour, minute: currentMinute })
  }

  return (
    <>
      <div className={classes.selectTime}>
        <Select
          type='hour'
          options={range(startHour, endHour)}
          value={hour}
          onChange={handleChange}
          disabled={disabled}
        />
        <Select
          type='minute'
          options={range(60)}
          value={minute}
          onChange={handleChange}
          disabled={disabled}
          paddedOptionLabel
        />
      </div>
      {todayCheckbox && (
        <FormControl error>
          <FormControlLabel
            className={classes.formCheckBox}
            label={t('form.date_time.checkbox_label')}
            control={
              <Checkbox
                className={classes.checkbox}
                onChange={(e) => handleCheckbox(e)}
                color='primary'
              />
            }
          />
          <FormHelperText>{t('form.date_time.checkbox_remark')}</FormHelperText>
        </FormControl>
      )}

      {additionalInfo && <div className={classes.additionalInfo}>{additionalInfo}</div>}
    </>
  )
}

SelectModule.propTypes = {
  type: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  options: PropTypes.array,
  paddedOptionLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  t: PropTypes.func
}

TimeSelect.propTypes = {
  hour: PropTypes.number,
  minute: PropTypes.number,
  startHour: PropTypes.number,
  endHour: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  additionalInfo: PropTypes.node,
  classes: PropTypes.object,
  handleCheckbox: PropTypes.func,
  todayCheckbox: PropTypes.bool
}

TimeSelect.defaultProps = {
  hour: 0,
  minute: 0,
  startHour: 0,
  endHour: 24,
  highlightedTimes: []
}

export default compose(withStyles(styles), withTranslation('inquiries'))(TimeSelect)
