import React from 'react'
import { useField } from 'formik'

import SwitchInput from 'components/inputs/SwitchInput.new'

function SwitchInputField({ name, ...props }) {
  const [field] = useField(name)

  return <SwitchInput {...field} {...props} />
}

export default SwitchInputField
