import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 110
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  label: {
    minHeight: 20
  }
}))

function NativeSelectInput({ options, value, inputLabel, placeholder, onChange, ...rest }) {
  const classes = useStyles()
  const [state, setState] = React.useState(value)

  const handleChange = (event) => {
    setState(event.target.value)
    if (onChange) onChange(event)
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <label className={classes.label}>{inputLabel}</label>
        <NativeSelect
          {...rest}
          value={state}
          onChange={handleChange}
        >
          <option value='' ariaLabel={placeholder} disabled>{placeholder}</option>
          {options.map((v, i) => (
            <option key={i} value={v.value}>{v.label}</option>
          ))}
        </NativeSelect>
      </FormControl>
    </div>
  )
}

NativeSelectInput.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array, ImmutablePropTypes.list]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  inputLabel: PropTypes.string,
  placeholder: PropTypes.string,
  noLabel: PropTypes.bool,
  onChange: PropTypes.func
}

export default NativeSelectInput
