import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Link } from 'react-router-dom'
import { Button, Grid, Paper } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'
import useEventId from 'hooks/useEventId'

import CircularLoader from 'components/CircularLoader'
import { Table, Column } from 'components/tables/Table'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import PhoneNumberFormatter from 'components/formatters/PhoneNumberFormatter'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'

export function CompanyAddressesPane ({
  company,
  match,
  query,
  fetchCompanyAddresses,
  destroyCompanyAddresses,
  push,
  t
}) {
  const [ eventId, generateEventId ] = useEventId()
  const { loading, totalCount, totalPages } = useData(() => {
    return fetchCompanyAddresses(match.params.id, { page: query.page })
  }, [eventId, query.page])

  function handleRemoveAddress (id) {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      destroyCompanyAddresses(id)
        .then(() => generateEventId())
    }
  }

  return (
    loading ? (
      <CircularLoader />
    ) : (
      <Fragment>
        <Grid
          container
          direction='row'
          justify='space-between'
          style={{ marginBottom: 8 }}
        >
          <ResultsCountLabel value={totalCount} unit='address' />

          <Link to={`/companies/${match.params.id}/addresses/new`}>
            <Button
              size='small'
              variant='outlined'
              color='secondary'
            >
              {t('add_new_address')}
            </Button>
          </Link>
        </Grid>

        {
          totalCount !== 0 && (
            <>
              <Grid>
                <Paper>
                  <Table collection={company.get('addresses')}>
                    <Column name='name' />
                    <Column name='contact_name' />
                    <Column name='phone_number' component={PhoneNumberFormatter} />
                    <Column name='full_address' style={{ width: 300, minWidth: 300 }} />
                    <Column name='created_at' component={DateTimeFormatter} />
                    <Column
                      columnName=' '
                      textAlign='right'
                      name='id'
                      style={{ width: 200, minWidth: 200 }}
                      render={value => (
                        <>
                          <Button
                            size='small'
                            variant='outlined'
                            component={Link}
                            to={`/addresses/${value}/edit`}
                          >
                            {t('edit')}
                          </Button>
                          <Button
                            size='small'
                            variant='outlined'
                            onClick={() => handleRemoveAddress(value)}
                            style={{ color: 'red', border: '1px solid red', marginLeft: 8 }}
                          >
                            {t('delete')}
                          </Button>
                        </>
                      )}
                    />
                  </Table>
                </Paper>
              </Grid>

              <Grid container justify='center'>
                <Pagination totalPages={totalPages} />
              </Grid>
            </>
          )
        }
      </Fragment>
    )
  )
}

CompanyAddressesPane.propTypes = {
  company: ImmutablePropTypes.map,
  match: PropTypes.object,
  query: PropTypes.object,
  fetchCompanyAddresses: PropTypes.func,
  destroyCompanyAddresses: PropTypes.func,
  push: PropTypes.func,
  t: PropTypes.func
}

CompanyAddressesPane.defaultProps = {
  company: Map()
}

export default withTranslation(['attributes', 'common', 'addresses'])(CompanyAddressesPane)
