import { connect } from 'react-redux'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'
import { fetchChildren } from 'actions/collections'
import { createChild, destroy } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { customerSchema, preferredProviderSchema } from '@seekster/schemas'

import CustomerFavoriteProvidersPane from './CustomerFavoriteProvidersPane'

const mapStateToProps = (state, { match }) => ({
  customer: getResource(state, match.params.id, customerSchema)
})

const mapDispatchToProps = {
  fetchCustomerFavoriteProviders: (id, options) => fetchChildren(customerSchema, id, preferredProviderSchema, options),
  createCustomerFavoriteProvider: (data, id) => createChild(data, customerSchema, id, preferredProviderSchema),
  destroyCustomerFavoriteProvider: (id, options) => destroy(id, preferredProviderSchema, options)
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(CustomerFavoriteProvidersPane)
