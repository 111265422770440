import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'

import { withQueryParser } from 'utils/withQueryParser'
import { useSorting } from 'hooks'
import { useCollectionChildren } from 'hooks/collections'
import { useResourceAction } from 'hooks/resources'

import CircularLoader from 'components/CircularLoader'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import SortableList from 'components/SortableList'
import BooleanChip from 'components/BooleanChip'
import Pagination from 'components/Pagination'
import { regionSchema, categorySchema } from '@seekster/schemas'

export function RegionCategoriesPane ({ match, query }) {
  const { update: updatecategory } = useResourceAction(categorySchema)

  const {
    handleSortStart,
    handleSortEnd,
    sortingEventId
  } = useSorting(updatecategory)

  const [categories, { loading, totalCount, totalPages }] = useCollectionChildren(
    regionSchema,
    match.params.id,
    categorySchema,
    { page: query.page },
    [query.page, sortingEventId]
  )

  return (
    <Grid container spacing={3} justify='center'>
      <Grid item xs={12}>
        <ResultsCountLabel value={totalCount} unit='category' />

        <Paper>
          {
            loading ? (
              <CircularLoader />
            ) : (
              <SortableList
                collection={categories}
                title={category => (
                  <ListItem disableGutters component='div'>
                    <ListItemAvatar>
                      <img
                        src={category.get('icon_url')}
                        width='30'
                        alt={category.get('name')}
                      />
                    </ListItemAvatar>

                    <ListItemText primary={category.get('name')} />
                  </ListItem>
                )}
                secondaryTitle={category => <BooleanChip value={category.get('active')} />}
                onSortEnd={handleSortEnd}
                onSortStart={handleSortStart}
                useDragHandle
              />
            )
          }
        </Paper>
      </Grid>

      <Grid item xs={12} container justify='center'>
        <Pagination totalPages={totalPages} />
      </Grid>
    </Grid>
  )
}

RegionCategoriesPane.propTypes = {
  match: PropTypes.object,
  query: PropTypes.object
}

export default withQueryParser(RegionCategoriesPane)
