import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { providerStrengthSchema } from '@seekster/schemas'

import ProviderStrengthOverviewPane from './ProviderStrengthOverviewPane'

const mapStateToProps = (state, { match }) => ({
  providerStrength: getResource(state, match.params.id, providerStrengthSchema)
})

export default connect(mapStateToProps)(ProviderStrengthOverviewPane)
