import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import moment from 'moment'
import { Map, List } from 'immutable'
import { withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import { Table, Column } from 'components/tables/Table'
import Tag from 'components/Tag'

export function SubscriptionMonthsPane ({ subscription, fetchSubscription, match, t }) {
  useEffect(() => {
    fetchSubscription(match.params.id)
  }, [fetchSubscription, match.params.id])

  return (
    <Panel title={t('subscription_months')}>
      <Table collection={subscription.get('subscription_months', List()).reverse()}>
        <Column
          name='date'
          columnName={t('month')}
          render={date => moment(date).format('MMMM YYYY')}
        />
        <Column
          name='inquiry'
          render={inquiry => inquiry && inquiry.get('number')}
          to={subscriptionMonth => `/inquiries/${subscriptionMonth.getIn(['inquiry', 'id'])}`}
        />
        <Column
          name='inquiry'
          columnName={t('inquiry_state')}
          render={inquiry => inquiry && <Tag value={inquiry.get('state')} className='inquiry-states' />}
        />
        <Column
          name='invoice'
          render={invoice => invoice && invoice.get('number')}
          to={subscriptionMonth => `/invoices/${subscriptionMonth.getIn(['invoice', 'id'])}`}
        />
        <Column
          name='invoice'
          columnName={t('invoice_state')}
          render={invoice => invoice && <Tag value={invoice.get('state')} className='invoice-states' />}
        />
      </Table>
    </Panel>
  )
}

SubscriptionMonthsPane.propTypes = {
  subscription: ImmutablePropTypes.map,
  fetchSubscription: PropTypes.func,
  match: PropTypes.object,

  t: PropTypes.func
}

SubscriptionMonthsPane.defaultProps = {
  subscription: Map()
}

export default withTranslation('subscriptions')(SubscriptionMonthsPane)
