import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DateTag } from 'components/tags'
import { isNil } from 'lodash'
import { Table, Column } from 'components/tables/Table'
import { Paper } from '@material-ui/core'

export function PromoCodesTable ({ promoCodes }) {
  const { t } = useTranslation('promo_codes')

  return (
    <Paper>
      <Table collection={promoCodes}>
        <Column
          name={t('code')}
          render={(code, promoCode) => (
            <Link to={`/promo_codes/${promoCode.get('id')}`}>{promoCode.get('code')}</Link>
          )}
        />
        <Column
          name={t('amount_off')}
          render={(amount_off, promoCode) => promoCode.getIn(['amount_off', 'full_display'])}
        />
        <Column
          name={t('percent_off')}
          render={(percent_off, promoCode) => isNil(promoCode.get('percent_off')) ? '' : promoCode.get('percent_off') + '%'}
        />
        <Column
          name={t('used_number')}
          render={(used_number, promoCode) => promoCode.get('usage_count')}
        />
        <Column
          name={t('limitation')}
          render={(limitation, promoCode) => promoCode.get('quantity')}
        />
        <Column
          name={t('limitation_per_user')}
          render={(limitation_per_user, promoCode) => promoCode.get('limit_per_user')}
        />
        <Column
          name={t('first_time')}
          render={(first_time, promoCode) => promoCode.get('first_time')}
        />
        <Column
          name={t('minimum_price')}
          render={(minimum_price, promoCode) => promoCode.getIn(['minimum_price', 'full_display'])}
        />
        <Column
          name={t('starting_date')}
          render={(starting_date, promoCode) => <DateTag value={promoCode.get('start_date')} />}
        />
        <Column
          name={t('expiration_date')}
          render={(expiration_date, promoCode) => <DateTag value={promoCode.get('expiration_date')} />}
        />
      </Table>
    </Paper>
  )
}

export default PromoCodesTable
