import React from 'react'
import { usePush } from 'hooks/utils'
import { useTranslation } from 'react-i18next'
import { useResourceAction } from 'hooks/resources'
import { customerSchema } from '@seekster/schemas'

import PageLayout from 'components/layouts/PageLayout'
import CustomerForm from 'forms/resources/CustomerForm'

export function CustomerNewPage() {
  const { t } = useTranslation('customers')
  const { create: createCustomer } = useResourceAction(customerSchema)
  const push = usePush()

  return (
    <PageLayout title={t('new')} back='/customers'>
      <CustomerForm push={push} onSubmit={createCustomer} />
    </PageLayout>
  )
}

export default CustomerNewPage
