import React from 'react'
import PropTypes from 'prop-types'
import { FilterAssociationTags } from '../../FilterAssociationTags'

export class FilterRegionTags extends React.Component {
  static propTypes = {
    regionName: PropTypes.string,
    fetchRegion: PropTypes.func,
    query: PropTypes.object,
    onQueryPush: PropTypes.func
  }

  static defaultProps = {
    regionName: '',

    fetchRegion: () => {}
  }

  componentDidMount () {
    const { query, fetchRegion } = this.props

    query.region_id && fetchRegion(query.region_id)
  }

  renderTag () {
    if (this.props.query.region_id) {
      return (
        <FilterAssociationTags
          content={`Region: ${this.props.regionName}`}
          name='region_id'
          query={this.props.query}
          onQueryPush={this.props.onQueryPush}
        />
      )
    }
    else {
      return null
    }
  }

  render () {
    return this.renderTag()
  }
}

export default FilterRegionTags
