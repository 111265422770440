import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { Link } from 'react-router-dom'
import { Table, Column } from 'components/tables/Table'
import { Paper } from '@material-ui/core'

import { DateTag, StateTag, PhoneTag } from 'components/tags'

export const SubscriptionsTable = ({ subscriptions }) => {
  return (
    <Paper>
      <Table collection={subscriptions}>
        <Column
          name='number'
          render={(number, subscription) => number && <Link to={`/subscriptions/${subscription.get('id')}`}>{number}</Link>}
        />

        <Column
          name='customer'
          render={(customer, inquiry) => (
            <>
              <p>
                <Link to={`/customers/${customer.get('id')}`}>{customer.get('display_name')}</Link>
              </p>
              <p>
                <PhoneTag value={inquiry.getIn(['location', 'phone_number'])} />
              </p>
            </>
          )}
        />

        <Column
          name='service'
          render={(service) => <Link to={`/services/${service.get('id')}`}>{service.get('name')}</Link>}
        />

        <Column
          name='orders'
          render={(orders) =>
            <ul>
              {
                orders.map((order) =>
                  <li key={order.get('id')}>{order.get('description')}</li>
                )
              }
            </ul>}
        />

        <Column
          name='Starting Date'
          render={(date, subscription) => <DateTag value={subscription.get('start_date')} />}
        />

        <Column name='location.short_address' />
        <Column name='state' component={StateTag} />
        <Column name='created_at' component={DateTag} />
      </Table>
    </Paper>
  )
}

SubscriptionsTable.propTypes = {
  subscriptions: ImmutablePropTypes.list
}

SubscriptionsTable.defaultProps = {
  subscriptions: List()
}

export default SubscriptionsTable
