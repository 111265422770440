import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { reviewSchema } from '@seekster/schemas'

import { useResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'

import PageLayout from 'components/layouts/PageLayout'
import { Panel } from 'components/containers'
import ReviewForm from 'forms/resources/ReviewForm'

export function ReviewEditPage ({ match }) {
  const [review] = useResource(reviewSchema, match.params.id)
  const { update: updateReview } = useResourceAction(reviewSchema)
  const { t } = useTranslation('review')
  const push = usePush()

  function handleSubmit (data) {
    return updateReview(match.params.id, data)
  }

  return (
    <PageLayout
      title={t('edit')}
      back={`/reviews/${match.params.id}`}
    >
      <Grid container justify='center'>
        <Grid item xs={12} sm={8}>
          <Panel>
            <ReviewForm
              review={review}
              onSubmit={handleSubmit}
              push={push}
            />
          </Panel>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default ReviewEditPage
