import { Map } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'

import BadgeForm from './BadgeForm'

const formikConfig = {
  mapPropsToValues: ({ badge = Map() }) => ({
    name: badge.get('name') || '',
    name_en: badge.get('name') || '',
    name_th: badge.get('name') || ''
  }),

  validationSchema: Yup.object().shape({
    name_en: Yup.string().required(),
    name_th: Yup.string()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values)
      .then(response => {
        props.push(`/badges/${response.body.id}`)
      })
      .catch(error => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(BadgeForm)
