import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Link } from 'react-router-dom'
import { Table, Column } from 'components/tables/Table'

import { DateTag } from 'components/tags'
import Panel from 'components/containers/Panel'

export function Payins({ job }) {
  function payins() {
    const payins = job.get('payins') || List()

    if (payins.isEmpty()) {
      return <p>No Payins.</p>
    } else {
      return (
        <Table collection={payins}>
          <Column
            name='source'
            render={(_, payin) => (
              <p>
                <Link to={`/providers/${payin.get('provider_id')}/wallet`}>
                  Wallet Transaction
                </Link>
              </p>
            )}
          />
          <Column
            name='amount'
            render={(amount) => <p>{amount.get('display_value')}</p>}
          />
          <Column name='description' />
          <Column name='created_at' component={DateTag} />
        </Table>
      )
    }
  }

  return <Panel title='Payins'>{payins()}</Panel>
}

Payins.propTypes = {
  job: ImmutablePropTypes.map
}

Payins.defaultProps = {
  job: Map()
}

export default Payins
