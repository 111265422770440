import React from 'react'
import { Grid } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { organizationSchema, jobReportSchema } from '@seekster/schemas'

import useQuery from 'hooks/useQuery'
import { useCollectionNodeChildren } from 'hooks/collections'
import Search from 'components/Search'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import CircularLoader from 'components/CircularLoader'
import ReportsTable from 'components/tables/ReportsTable'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

const OrganizationReportsPane = (props) => {
  const classes = useStyles()
  const [query] = useQuery()

  const { search } = query
  const [reports, { loading, totalCount, totalPages }] = useCollectionNodeChildren(
    organizationSchema,
    props.match.params.id,
    jobReportSchema,
    { query: { page: query.page, search: search } },
    [query]
  )

  return (
    <>
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Search withButton />
          </Grid>
        </Grid>
      </div>

      {loading ? (
        <CircularLoader />
      ) : (
        <>
          <div className={classes.metaContainer}>
            <ResultsCountLabel value={totalCount} unit='report' style={{ margin: 0 }} />
            <ReportsTable reports={reports} />
          </div>

          <Grid item xs={12} container justify='center'>
            <Pagination totalPages={totalPages} />
          </Grid>
        </>
      )}
    </>
  )
}

export default OrganizationReportsPane
