import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { jobSchema } from '@seekster/schemas'

import JobOverviewPane from './JobOverviewPane'

const mapStateToProps = (state, { match }) => ({
  job: getResource(state, match.params.id, jobSchema)
})

export default connect(mapStateToProps)(JobOverviewPane)
