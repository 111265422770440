import React from 'react'
import { Table, Column } from 'components/tables/Table'
import { Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export function PayrollsCycleTable ({ payrolls }) {
  const { t } = useTranslation('payrolls')

  return (
    <Paper>
      <Table collection={payrolls}>
        <Column
          name='payout_date'
          render={(payout_date, payroll, index) =>
            <p>
              Payroll Cycle #{index + 1}
            </p>
          }
        />
        <Column
          name='payroll_cycle_date'
          render={(description, payroll) => <>{t('date')} {payroll.get('cycle_start_day')} {payroll.get('cycle_start_time')} - {t('date')} {payroll.get('cycle_end_day')} {payroll.get('cycle_end_time')}</>}
        />
      </Table>
    </Paper>
  )
}

export default PayrollsCycleTable
