import { connect } from 'react-redux'
import { List } from 'immutable'

import { getResource } from 'selectors/resources'
import { fetchChildren } from 'actions/collections'
import { conversationSchema } from '@seekster/schemas'

import ConversationsListPanel from './ConversationsListPanel'

const mapStateToProps = (state, { match }) => ({
  getConversations: (schema) => {
    const resource = getResource(state, match.params.id, schema)

    if (resource) {
      return resource.get('conversations', List())
    }
    else {
      return List()
    }
  }
})

const mapDispatchToProps = {
  fetchResourceConversations: (schema, id) => fetchChildren(schema, id, conversationSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationsListPanel)
