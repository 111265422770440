import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, destroy } from 'actions/resources'
import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import sendInvitation from 'actions/invitations'
import { customerSchema, commentSchema, invitationSchema } from '@seekster/schemas'

import withFlashMessage from 'hoc/withFlashMessage'

import CustomerShowPage from './CustomerShowPage'

const mapStateToProps = (state, { match }) => ({
  customer: getResource(state, match.params.id, customerSchema)
})

const mapDispatchToProps = {
  fetchComments: (id, options) => fetchChildren(customerSchema, id, commentSchema, options),
  fetchCustomer: (id, options) => fetch(id, customerSchema, options),
  sendInvitation: (id, options) => sendInvitation(customerSchema, id, invitationSchema, options),
  destroy: (id, options) => destroy(id, customerSchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(withFlashMessage(CustomerShowPage))
