import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  error: {
    fontSize: '1.5rem',
    position: 'absolute',
    top: '20%',
    left: '20%',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      left: '40%'
    }
  }
})

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError () {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)
  }

  render () {
    if (this.state.hasError) {
      return (
        <div className={this.props.classes.error}>
          <span role='img' aria-label='dizzy face'>😵 </span>
          Something went wrong!
        </div>
      )
    }

    return this.props.children
  }
}

export default withStyles(styles)(ErrorBoundary)
