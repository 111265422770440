import { connect } from 'react-redux'

import { create, fetch as fetchResource } from 'actions/resources'
import { customerSchema } from '@seekster/schemas'
import { addressSchema } from '@seekster/schemas'

import SubscriptionFormController from './SubscriptionFormController'

const mapDispatchToProps = {
  createCustomerAddress: (data, id, options) => create(data, customerSchema, { type: `customers/${id}/addresses` }),
  fetchAddress: (id) => fetchResource(id, addressSchema)
}

export default connect(null, mapDispatchToProps)(SubscriptionFormController)
