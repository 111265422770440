import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { jobSchema, jobProviderSchema } from '@seekster/schemas'

import JobReportsPane from './JobReportsPane'

const mapStateToProps = (state, { match }) => ({
  job: getResource(state, match.params.id, jobSchema)
})

const mapDispatchToProps = {
  fetchJobProviders: id => fetchChildren(jobSchema, id, jobProviderSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(JobReportsPane)
