export default ({ breakpoints, spacing }) => ({
  assignButton: {
    padding: '13px 8px',
    textTransform: 'capitalize'
  },

  container: {
    padding: spacing(2)
  },

  assignIcon: {
    marginRight: spacing(0.5)
  },

  content: {
    '& > div:not(:last-child)': {
      marginBottom: spacing(1)
    }
  },

  avatar: {
    width: 65,
    height: 65,
    marginTop: spacing(0.5),

    [breakpoints.up('sm')]: {
      width: 50,
      height: 50,
      marginTop: 0
    }
  },

  icon: {
    margin: '0 4px -5px 0'
  },

  state: {
    marginTop: spacing(2),

    [breakpoints.up('sm')]: {
      marginTop: 0
    }
  },

  noProvider: {
    textAlign: 'center',
    margin: '12px 0 7px'
  }
})
