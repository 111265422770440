import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import ProviderForm from 'forms/resources/ProviderForm'

export class ProviderEditPage extends React.Component {
  static propTypes = {
    provider: ImmutablePropTypes.map,
    services: ImmutablePropTypes.list,
    badges: ImmutablePropTypes.list,
    match: PropTypes.object,
    t: PropTypes.func,
    push: PropTypes.func,
    loadProvider: PropTypes.func,
    loadBadges: PropTypes.func,
    loadServices: PropTypes.func,
    updateProvider: PropTypes.func
  }

  static defaultProps = {
    provider: Map(),
    services: List(),
    badges: List()
  }

  componentDidMount () {
    this.props.loadProvider(this.props.match.params.id)
  }

  handleSubmit (data, options) {
    return this.props.updateProvider(this.props.match.params.id, data, options)
  }

  render () {
    return (
      <PageLayout
        title={this.props.t('edit')}
        isLoading={this.props.provider.isEmpty()}
        back={`/providers/${this.props.match.params.id}`}
      >
        <ProviderForm provider={this.props.provider}
          services={this.props.services}
          badges={this.props.badges}
          loadServices={this.props.loadServices}
          loadBadges={this.props.loadBadges}
          push={this.props.push}
          onSubmit={this.handleSubmit.bind(this)}
        />
      </PageLayout>
    )
  }
}

export default withTranslation('providers')(ProviderEditPage)
