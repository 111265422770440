import React from 'react'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import useQuery from 'hooks/useQuery'
import { useCollection } from 'hooks/collections'
import { jobSchema } from '@seekster/schemas'

import { Grid } from '@material-ui/core'

import PageLayout from 'components/layouts/PageLayout'
import CircularLoader from 'components/CircularLoader'
import Search from 'components/Search'
import FilterModal from 'components/FilterModal'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import FilterTags from 'components/tags/FilterTags'

import SLAStartedTable from 'components/tables/SLAStartedTable'
import SLAStartedFilterForm from 'forms/filters/SLAStartedFilterForm'

const styles = ({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
})

const SLAStarted = ({ classes, t }) => {
  const [query] = useQuery()
  const { search, ...rest } = query
  const [jobs, { loading, totalCount, totalPages }] = useCollection(
    jobSchema,
    {
      page: query.page,
      search: search,
      filter: rest,
      queryParam: { pending_complete_sla: true }
    },
    [query]
  )
  return (
    <PageLayout title={t('title')}>
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={10}>
            <Search />
          </Grid>

          <Grid item xs={3} sm={2}>
            <FilterModal component={SLAStartedFilterForm} />
          </Grid>
        </Grid>
      </div>
      {loading ? (
        <CircularLoader />
      ) : (
        <>
          <div className={classes.metaContainer}>
            <ResultsCountLabel value={totalCount} unit='job' />
            <FilterTags />
            <SLAStartedTable t={t} startedJobs={jobs} loading={loading} />
          </div>
          <Grid item xs={12} container justify='center'>
            <Pagination totalPages={totalPages} />
          </Grid>
        </>
      )}
    </PageLayout>
  )
}

export default compose(
  withStyles(styles),
  withTranslation(['sla_started', 'common'])
)(SLAStarted)
