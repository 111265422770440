import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import PageContent, { PageSection } from 'components/layouts/PageContent'
import Panel from 'components/containers/Panel'
import AttributeLabel from 'components/labels/AttributeLabel'
import HtmlFormatter from 'components/formatters/HtmlFormatter'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import Tag from 'components/Tag'

export function AnnouncementOverviewPane ({ announcement, t }) {
  return (
    <PageContent>
      <PageSection position='center' borderRight>
        <Panel title={t('content')}>
          <div style={{ padding: '10px 16px' }}>
            <AttributeLabel title={`${t('id')} ${announcement.get('id')}`}>
              {announcement.get('title')}
            </AttributeLabel>

            <AttributeLabel
              title={t('banner.landscape.en')}
              subtitle={announcement.get('static_banner') && <Tag value={t('banner.static')} />}
            >
              <img
                src={announcement.getIn(['banner', 'landscape_en_url'])}
                alt={`${announcement.get('title')} ${t('banner.landscape.en')}`}
                width='100%'
              />
            </AttributeLabel>

            <AttributeLabel
              title={t('banner.landscape.th')}
              subtitle={announcement.get('static_banner') && <Tag value={t('banner.static')} />}
            >
              <img
                src={announcement.getIn(['banner', 'landscape_th_url'])}
                alt={`${announcement.get('title')} ${t('banner.landscape.th')}`}
                width='100%'
              />
            </AttributeLabel>

            <AttributeLabel
              title={t('banner.portrait.en')}
              subtitle={announcement.get('static_banner') && <Tag value={t('banner.static')} />}
            >
              <img
                src={announcement.getIn(['banner', 'portrait_en_url'])}
                alt={`${announcement.get('title')} ${t('banner.portrait.en')}`}
                width='100%'
              />
            </AttributeLabel>

            <AttributeLabel
              title={t('banner.portrait.th')}
              subtitle={announcement.get('static_banner') && <Tag value={t('banner.static')} />}
              divided
            >
              <img
                src={announcement.getIn(['banner', 'portrait_th_url'])}
                alt={`${announcement.get('title')} ${t('banner.portrait.th')}`}
                width='100%'
              />
            </AttributeLabel>

            <AttributeLabel title={t('link')} divided>
              {announcement.get('link') || '-'}
            </AttributeLabel>

            <AttributeLabel title={t('body')} divided>
              {
                announcement.get('body') ? (
                  <HtmlFormatter value={announcement.get('body')} />
                ) : (
                  '-'
                )
              }
            </AttributeLabel>

            <AttributeLabel title={t('action_text')}>
              {announcement.get('action_text')}
            </AttributeLabel>

            <AttributeLabel title={t('action_link')}>
              <a href={announcement.get('action_link')} target='_blank' rel='noopener noreferrer'>
                {announcement.get('action_link')}
              </a>
            </AttributeLabel>
          </div>
        </Panel>
      </PageSection>

      <PageSection position='right'>
        <Panel title={t('details')}>
          <div style={{ padding: '10px 16px' }}>
            <AttributeLabel title={t('clients')}>
              <ul>
                {
                  announcement.get('clients') && announcement.get('clients').map(client => (
                    <li key={client.get('id')}>
                      <Link to={`/${client.get('type')}/${client.get('id')}`}>
                        {client.get('name')}
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </AttributeLabel>

            <AttributeLabel title={t('regions')} divided>
              <ul>
                {
                  announcement.get('regions') && announcement.get('regions').map(region => (
                    <li key={region.get('id')}>
                      <Link to={`/${region.get('type')}/${region.get('id')}`}>
                        {region.get('name')}
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </AttributeLabel>

            <AttributeLabel title={t('start_time')}>
              <DateTimeFormatter value={announcement.get('start_time')} />
            </AttributeLabel>

            <AttributeLabel title={t('end_time')} divided>
              <DateTimeFormatter value={announcement.get('end_time')} />
            </AttributeLabel>

            <AttributeLabel title={t('created_at')}>
              <DateTimeFormatter value={announcement.get('created_at')} />
            </AttributeLabel>

            <AttributeLabel title={t('updated_at')}>
              <DateTimeFormatter value={announcement.get('updated_at')} />
            </AttributeLabel>
          </div>
        </Panel>
      </PageSection>

    </PageContent>
  )
}

AnnouncementOverviewPane.propTypes = {
  announcement: ImmutablePropTypes.map,

  t: PropTypes.func
}

AnnouncementOverviewPane.defaultProps = {
  announcement: Map()
}

export default withTranslation(['announcements', 'attributes'])(AnnouncementOverviewPane)
