import { connect } from 'react-redux'

import SMSRatingPage from './SMSRatingPage'
import { smsRatingSchema, sendIndividualSmsSchema, sendMultipleSmsSchema } from '@seekster/schemas'
import { sendIndividualSMS, sendMultipleSMS } from 'actions/smsRatings'

const mapDispatchToProps = {
  sendIndividualSMS: (id, options) => sendIndividualSMS(smsRatingSchema, id, sendIndividualSmsSchema, options),
  sendMultipleSMS: (options) => sendMultipleSMS(smsRatingSchema, sendMultipleSmsSchema, options)
 }

export default connect(null, mapDispatchToProps)(SMSRatingPage)
