import { withFormik } from 'formik'
import { Map } from 'immutable'
import * as Yup from 'yup'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'
import IssueForm from './IssueForm'

const formikConfig = {
  mapPropsToValues: ({ issue = Map() }) => ({
    name_en: issue.get('name_en', ''),
    name_th: issue.get('name_th', '')
  }),

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    name_en: Yup.string(),
    name_th: Yup.string()
  }),

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values)
      .then(({ body }) => {
        props.push(`/provider_issues/${body.id}`)
      })
      .catch(error => {
        setSubmitting(false)

        setErrors({
          name_en: error.response.body.message,
          name_th: error.response.body.message
        })

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(IssueForm)
