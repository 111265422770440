import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { Grid, Typography, IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import CircularLoader from 'components/CircularLoader'
import { Table, Column } from 'components/tables/Table'
import Tag from 'components/Tag'
import VisibilityToggledContent from 'components/VisibilityToggledContent'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import Pagination from 'components/Pagination'

import styles from './UserDevicesStyles'

export function UserDevices ({ schema, id, fetchDevices, destroyDevice, getDevices, classes, t }) {
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [currentPages, setCurrentPage] = useState(1)

  useEffect(() => {
    handleUpdateData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function handleUpdateData () {
    setLoading(true)

    fetchDevices(schema, id)
      .then(({ headers }) => {
        setTotalPages(Math.ceil(headers['total'] / headers['per-page']))

        setLoading(false)
      })
  }

  function handlePageChange (page) {
    setLoading(true)

    setCurrentPage(page)

    fetchDevices(schema, id, { page })
      .then(() => setLoading(false))
  }

  function deleteDevice (id) {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      destroyDevice(id)
        .then(() => handleUpdateData())
    }
  }

  return (
    loading ? (
      <CircularLoader />
    ) : (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Table collection={getDevices(schema, id) || List()} padding='dense'>
            <Column name='id' style={{ fontSize: 12 }} />
            <Column
              name='model'
              render={(value, resource) => (
                <Fragment>
                  <Typography>{resource.get('model')}</Typography>
                  <Typography variant='caption'>{resource.get('brand')}</Typography>
                  <Typography variant='caption'>{resource.get('os_version')}</Typography>
                </Fragment>
              )}
            />
            <Column
              name='client.name'
              render={(value, resource) => (
                <Fragment>
                  <Typography>{value}</Typography>
                  <Typography variant='caption'>{resource.get('client_version')}</Typography>
                </Fragment>
              )}
            />
            <Column name='last_used_at' component={DateTimeFormatter} style={{ fontSize: 12 }} />
            <Column name='locale' component={Tag} />
            <Column
              name='registration_token'
              render={value => <VisibilityToggledContent value={value} maxWidth={100} />}
            />
            <Column
              name='access_token'
              render={value => <VisibilityToggledContent value={value} maxWidth={100} />}
            />
            <Column
              name='uuid'
              render={value => <VisibilityToggledContent value={value} maxWidth={100} />}
            />
            <Column
              name='id'
              columnName=' '
              render={id => (
                <IconButton onClick={() => deleteDevice(id)} className={classes.deleteButton}>
                  <Delete fontSize='small' />
                </IconButton>
              )}
            />
          </Table>
        </Grid>

        <Grid item xs={12} container justify='center'>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPages}
            onPageChange={handlePageChange}
          />
        </Grid>
      </Grid>

    )
  )
}

UserDevices.propTypes = {
  schema: PropTypes.object,
  id: PropTypes.number,
  fetchDevices: PropTypes.func,
  destroyDevice: PropTypes.func,
  getDevices: PropTypes.func,
  classes: PropTypes.object,

  t: PropTypes.func
}

export default compose(
  withStyles(styles),
  withTranslation()
)(UserDevices)
