import React, { useState, useEffect } from 'react'
import { Map, fromJS } from 'immutable'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { withMobileDialog } from '@material-ui/core'
import { isEqual, intersectionWith } from 'lodash'
import FilterModal from 'components/FilterModal'
import Panel from 'components/containers/Panel'
import CircularLoader from 'components/CircularLoader'
import TicketsList from 'components/lists/TicketsList'
import { useCollection as firebaseUseCollection } from 'hooks/firebase/collections'
import TicketFilterForm from 'forms/filters/TicketFilterForm'
import Search from 'components/Search'
import { useCollection } from 'hooks/collections'
import useQuery from 'hooks/useQuery'
import { useSelector } from 'react-redux'
import { usePrevious } from 'hooks/utils'
import { getCurrentResource } from 'selectors/authentication'

import {
  ticketSchema,
  channelSchema,
  agentSchema,
  customerSchema
} from '@seekster/schemas'

import styles from './TicketsListPanelPanelStyles'

const priorityLists = ['high', 'normal', 'low']

export function TicketsListPanel ({
  match,
  classes,
  fullScreen: isMobile,
  location,
  tenant
}) {
  const [query] = useQuery()
  const { t } = useTranslation('tickets')
  const [channelSelected, setChannelSelected] = useState(null)
  const [requestId, setRequestId] = useState(false)
  const [isDepartment, setIdDepartment] = useState(false)
  const [ticketStatus, setTicketStatus] = useState(['open', 'pending'])
  const currentAgent = useSelector(state => getCurrentResource(state, agentSchema)) || Map()
  const myPreviousState = usePrevious(query)
  const [customers, { loading: loadCustomer }] = useCollection(customerSchema, { query }, [query.search])
  const [tickets, { loading }] = firebaseUseCollection(`tenants/${tenant}/tickets`, ticketSchema, filter => {
    if (channelSelected) {
    // eslint-disable-next-line
      return requestId ? 
        (requestId === 'All' ? filter.where('channel.name', '==', channelSelected).orderBy('updated_at', 'desc')
          : filter.where('channel.name', '==', channelSelected).where('department', '==', isDepartment).where('assignee', '==', parseInt(requestId)).orderBy('updated_at', 'desc'))
        : filter.where('assignee', 'in', [currentAgent.get('id', 1), false]).where('channel.name', '==', channelSelected).orderBy('updated_at', 'desc')
    }
    else {
    // eslint-disable-next-line
      return requestId ? (requestId === 'All' ? filter.orderBy('updated_at', 'desc')
        : filter.where('assignee', '==', parseInt(requestId)).where('department', '==', isDepartment).orderBy('updated_at', 'desc'))
        : filter.where('assignee', 'in', [currentAgent.get('id', 1), false]).orderBy('updated_at', 'desc')
    }
  }, [ticketStatus, channelSelected, requestId], {
    cacheKey: 'ticketList'
  })

  const [departmentTickets, { loading: loadDerpartmentTickets }] = firebaseUseCollection(`tenants/${tenant}/tickets`, ticketSchema, filter => {
    return filter.where('department', '==', true).where('assignee', '==', parseInt(currentAgent.getIn(['department', 'id'], 1), false)).orderBy('updated_at', 'desc')
  }, [], {
    cacheKey: 'ticketListDepartment'
  })

  useEffect(() => {
    if (!isEqual(myPreviousState, query)) {
      const { channel = '', status = '', id, department } = query

      if (status) {
        setTicketStatus([status])
      }
      else {
        setTicketStatus(['open', 'pending'])
      }

      setRequestId(id)
      department && setIdDepartment(true)
      setChannelSelected(channel)
    }
  }, [query, myPreviousState])

  const [channels] = firebaseUseCollection('line_channels', channelSchema)
  const newTickets = query.id ? tickets : departmentTickets.toSet().union(tickets.toSet()).toList()
  const ticketsWithCheckStatus = newTickets.filter(ticket => ticketStatus.includes(ticket.get('status')))
  const ticketsWithCheckPriority = priorityLists.includes(query.priority) ? ticketsWithCheckStatus.filter(ticket => ticket.get('priority').includes(query.priority)) : ticketsWithCheckStatus
  const allTickets = query.search ? fromJS(intersectionWith(ticketsWithCheckPriority.toJS(), customers.toJS(), (t, c) => c.id === parseInt(t.workforceId))) : ticketsWithCheckPriority
  const sortedTicket = allTickets.sortBy((item) => item.get('updated_at'))

  function actions () {
    return (
      <div className={classes.topbarContainer}>
        <FilterModal icon component={TicketFilterForm} channels={channels} />
      </div>
    )
  }

  return (
    <Panel
      responsive
      noPadding
      square
      title={<p className={classes.title}>{`${channelSelected || t('all_ticket')} (${allTickets.size})`}</p>}
      actions={actions()}
    >
      <div className={classes.searchContainer}> <Search isDebounce /> </div>

      {
        loading || loadCustomer || loadDerpartmentTickets
          ? <CircularLoader />
          : <TicketsList tickets={sortedTicket.reverse()} match={match} location={location} />
      }
    </Panel>
  )
}

TicketsListPanel.defaultProps = {
  tenant: 'seekster'
}

TicketsListPanel.propTypes = {
  fullScreen: PropTypes.bool,
  match: PropTypes.object,
  location: PropTypes.object,
  classes: PropTypes.object,
  tenant: PropTypes.string
}

export default compose(
  withStyles(styles),
  withMobileDialog()
)(TicketsListPanel)
