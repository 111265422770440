import { Map, List } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'
import keyIn from 'utils/keyIn'

import CompanyFormContainer from './CompanyFormContainer'

const packagesChildrenAttributesKeys = ['name', 'customer_ids']

const formikConfig = {
  mapPropsToValues: ({ company = Map(), withoutAssociationIds }) => {
    const branches = company.get('branches')
    let branchesAttributes

    if (branches) {
      branchesAttributes = company
        .get('branches')
        .toSeq()
        .map((branch) =>
          branch
            .filter(
              keyIn(!withoutAssociationIds && 'id', ...packagesChildrenAttributesKeys)
            )
            .merge({
              customer_ids: branch
                .get('customers', List())
                .map((customer) => customer.get('id'))
            })
        )
        .toJS()
    }

    const companyType = company.get('company_type') || Map()

    return {
      name: company.get('name'),
      tax_number: company.get('tax_number'),
      contact_person_id: company.getIn(['contact_person', 'id']),
      billing_email: company.get('billing_email'),
      branches_attributes: branchesAttributes,
      parent_company_type_id: companyType.get('parent_id'),
      company_type_id: companyType.get('id'),
      responsible_by_id: company.getIn(['responsible_by', 'id']),
      support_by_id: company.getIn(['support_by', 'id']),
      tag_ids: company
        .get('tags', List())
        .map((tag) => tag.get('id'))
        .toJS()
    }
  },

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    tax_number: Yup.string(),
    contact_person_id: Yup.number().nullable()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit(values)
      .then((response) => {
        props.push(`/companies/${response.body.id}`)
      })
      .catch((error) => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(withFlashMessage, withFormik(formikConfig))(CompanyFormContainer)
