import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Moment from 'moment'
import { List } from 'immutable'
import { DayPickerSingleDateController, CalendarDay } from 'react-dates'

import './JobsCalendar.scss'

export function JobsCalendar({ jobs }) {
  const earliestJob = jobs.minBy((job) => job.get('start_time')) || new Map()

  return (
    <div className='jobs-calendar'>
      <DayPickerSingleDateController
        hideKeyboardShortcutsPanel
        numberOfMonths={1}
        noBorder
        initialVisibleMonth={() => Moment(earliestJob.get('start_time'))}
        renderCalendarDay={(dayProps) => {
          let { day, modifiers } = dayProps

          const date = jobs.find(
            (job) => day && Moment(job.get('start_time')).isSame(day, 'day')
          )

          if (date) {
            modifiers = new Set([...(modifiers || new Set()), 'selected'])
          }

          return <CalendarDay {...dayProps} modifiers={modifiers} />
        }}
      />
    </div>
  )
}

JobsCalendar.propTypes = {
  jobs: ImmutablePropTypes.list
}

JobsCalendar.defaultProps = {
  jobs: List()
}

export default JobsCalendar
