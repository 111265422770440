export default theme => ({
  header: {
    textAlign: 'center'
  },

  referrerCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none !important',
    padding: theme.spacing(2)
  },

  refereeCard: {
    textDecoration: 'none !important'
  },

  emptyCard: {
    padding: theme.spacing(4),
    textAlign: 'center'
  },

  referrerAvatar: {
    width: 70,
    height: 70,
    marginBottom: theme.spacing(1)
  },

  refereeAvatar: {
    width: 65,
    height: 65
  }
})
