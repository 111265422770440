import { connect } from 'react-redux'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'
import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { customerSchema, inquirySchema } from '@seekster/schemas'

import CustomerInquiriesPane from './CustomerInquiriesPane'

const mapStateToProps = (state, { match }) => ({
  customer: getResource(state, match.params.id, customerSchema)
})

const mapDispatchToProps = {
  fetchCustomerInquiries: (id, options) => fetchChildren(customerSchema, id, inquirySchema, options)
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(CustomerInquiriesPane)
