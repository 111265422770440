export default ({ palette }) => ({
  container: {
    display: 'flex'
  },

  day: {
    color: palette.primary.main,
    fontWeight: 600,
    flexBasis: `${100 / 7}%`,
    minWidth: 0,

    '&:first-child': {
      borderRadius: '4px 0 0 4px'
    },

    '&:last-child': {
      borderRadius: '0 4px 4px 0',
      borderLeft: 'none'
    },

    '&:not(:first-child):not(:last-child)': {
      borderRadius: 0,
      borderLeft: 'none'
    }
  },

  selectedDay: {
    color: palette.grey[50],
    backgroundColor: palette.secondary.main,

    '&:hover': {
      color: palette.grey[50],
      backgroundColor: palette.secondary.light
    }
  },

  disabledPicker: {
    color: `${palette.grey[50]} !important`
  }
})
