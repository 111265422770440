import React from 'react'
import { useTranslation } from 'react-i18next'

import { useResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'
import { webhookHandlerSchema } from '@seekster/schemas'

import PageLayout from 'components/layouts/PageLayout'
import WebhookForm from 'forms/resources/WebhookForm'

export function WebhookEditPage ({ match }) {
  const { t } = useTranslation('settings')
  const [webhook, { loading }] = useResource(webhookHandlerSchema, match.params.id)
  const { update: updateWebhook } = useResourceAction(webhookHandlerSchema)
  const push = usePush()

  function handleSubmit (data) {
    return updateWebhook(match.params.id, data)
  }

  return (
    <PageLayout
      title={t('webhook.edit')}
      isLoading={loading}
      back={`/webhooks/${match.params.id}`}
    >
      <WebhookForm
        webhook={webhook}
        onSubmit={handleSubmit}
        push={push}
      />
    </PageLayout>
  )
}

export default WebhookEditPage
