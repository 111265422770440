/* eslint-disable camelcase */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import './uploadfile.scss'

import { Grid } from '@material-ui/core'

const index = (props) => {
  const { action, slips, closeBtn } = props
  const { t } = useTranslation(['navbar', 'creditnote'])
  const [fileList, setFileList] = useState([])
  const [viewMore, setViewMore] = useState(false)
  const imgRef = useRef(null)
  const warpperRef = useRef(null)

  const onFileDrop = (event) => {
    event.preventDefault()
    const newFile = event.target.files
    const checkType = Array.from(newFile).filter((data) => {
      if (data.type === 'image/png' || data.type === 'image/jpeg') {
        return data
      }
      return null
    })
    if (newFile.length > 0) {
      const setData = [...fileList, checkType]
      setFileList(setData)
    }
  }

  const newList = Array.from(fileList).slice().flat()
  const currentArray = Array.from(newList).filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          t.size === value.size &&
          t.name.split(' ').join('') === value.name.split(' ').join('')
      )
  )

  const reMoveFile = (data) => {
    const lists = Array.from(newList).filter((x) => {
      return x !== data
    })
    setFileList(lists)
    action.setFieldValue('upload_file.fileSelect', lists)
  }

  const onDragEnter = () => warpperRef.current.classList.add('drag-over')

  const onDragLeave = () => warpperRef.current.classList.remove('drag-over')

  const onDrop = () => warpperRef.current.classList.remove('drag-over')

  const changeValueTime = (event) => {
    action.setFieldValue('upload_file.create_at', event.target.value)
  }

  const removeValueTime = () => {
    action.setFieldValue('upload_file.create_at', '')
  }

  useEffect(() => {
    if (currentArray.length !== action.values.upload_file.fileSelect.length) {
      action.setFieldValue('upload_file.fileSelect', currentArray)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentArray])

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <div
            ref={warpperRef}
            className='drag-and-drop-file-border'
            onDragEnter={!closeBtn ? onDragEnter : null}
            onDragLeave={!closeBtn ? onDragLeave : null}
            onDrop={!closeBtn ? onDrop : null}
          >
            <div className='text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='83.555'
                height='55.703'
                viewBox='0 0 83.555 55.703'
              >
                <path
                  id='Icon_ionic-md-cloud-upload'
                  data-name='Icon ionic-md-cloud-upload'
                  d='M68.492,27.813A26.067,26.067,0,0,0,19.751,20.85a20.862,20.862,0,0,0,2.263,41.6H67.273A17.459,17.459,0,0,0,84.68,45.046,17.2,17.2,0,0,0,68.492,27.813ZM48.473,38.083v13.23H37.332V38.083H25.5L42.9,20.676,60.31,38.083Z'
                  transform='translate(-1.125 -6.75)'
                  fill='#2d95f4'
                />
              </svg>
              <div className='text-drop'>
                {t('drag1')}
                <br />
                {t('drag2')}
              </div>
              <div className='section-box-btn '>
                <div className='btn-browse-upload-file'>{t('drag3')}</div>
              </div>
            </div>
            {!closeBtn && (
              <input
                ref={imgRef}
                type='file'
                accept='image/jpeg, image/png'
                multiple
                onChange={(e) => onFileDrop(e)}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={7}>
          <div className='mb-1'>
            <div className='topic-upload-file'>{t('capture_at')}</div>
            <div className='section-input-upload-file position-relative'>
              <input
                type='text'
                className='input-upload-file-transaction'
                value={action.values.upload_file.create_at}
                onChange={(e) => changeValueTime(e)}
                disabled={closeBtn}
              />
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18.25'
                height='18.25'
                viewBox='0 0 18.25 18.25'
                onClick={() => !closeBtn && removeValueTime()}
              >
                <path
                  id='Icon_ionic-md-close-circle'
                  data-name='Icon ionic-md-close-circle'
                  d='M12.5,3.375A9.125,9.125,0,1,0,21.625,12.5,9.093,9.093,0,0,0,12.5,3.375Zm4.563,12.41-1.277,1.277L12.5,13.777,9.215,17.062,7.937,15.785,11.223,12.5,7.937,9.215,9.215,7.938,12.5,11.223l3.285-3.285,1.277,1.277L13.777,12.5Z'
                  transform='translate(-3.375 -3.375)'
                  fill='#ccc'
                />
              </svg>
            </div>
            <div>
              <span className='text-example-upload-file'>
                {t('example')}: 26/08/2022 22:47
              </span>
              {action.errors.upload_file?.create_at && (
                <span className='text-errors-upload-file'>
                  {action.errors.upload_file?.create_at}
                </span>
              )}
              {action.errors.upload_file?.fileSelect && (
                <span className='text-errors-upload-file'>
                  {action.errors.upload_file?.fileSelect}
                </span>
              )}
            </div>
          </div>
          <Grid container spacing={1}>
            {slips && closeBtn ? (
              <>
                {slips.slice(0, viewMore ? slips.length : 14).map((data, k) => {
                  return (
                    <Grid item xs={12} sm={4} key={k}>
                      <div className='section-box-file-upload'>
                        <div className='section-icon-file'>
                          <svg
                            id='icons_file_'
                            data-name='icons file '
                            xmlns='http://www.w3.org/2000/svg'
                            width='19.499'
                            height='25.998'
                            viewBox='0 0 19.499 25.998'
                          >
                            <path
                              id='Icon_awesome-file-image'
                              data-name='Icon awesome-file-image'
                              d='M19.5,6.192V6.5L13,0h.308a1.219,1.219,0,0,1,.862.357C19.37,5.559,19.5,5.869,19.5,6.192ZM13,0H1.219A1.219,1.219,0,0,0,0,1.219V24.779A1.219,1.219,0,0,0,1.219,26H18.28A1.219,1.219,0,0,0,19.5,24.779V6.5ZM3.277,11.374Z'
                              fill='#2d95f4'
                            />
                            <path
                              id='Icon_awesome-file-image-2'
                              data-name='Icon awesome-file-image'
                              d='M20.255,10Zm-13.784.812A2.437,2.437,0,1,1,4.034,13.25,2.437,2.437,0,0,1,6.471,10.812ZM17.033,23h-13l.025-2.462,2.006-2.006a.581.581,0,0,1,.837.025l2.006,2.006,5.256-5.256a.609.609,0,0,1,.862,0l2.006,2.006Z'
                              transform='translate(-1.568 -3.5)'
                              fill='#fff'
                            />
                          </svg>
                        </div>
                        <div className='box-text-file'>
                          <a
                            href={data.slip_url}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='link-custom-new-tab-upload-file'
                          >
                            <span>{data.id}</span>
                          </a>
                        </div>
                        <span className='icon-remove-upload-file'>X</span>
                      </div>
                    </Grid>
                  )
                })}
                {currentArray.length > 14 && !viewMore && (
                  <Grid item xs={12} sm={4}>
                    <span
                      className='pointer text-view-more-custom-upload-file'
                      onClick={() => setViewMore(true)}
                    >
                      View More
                    </span>
                  </Grid>
                )}
              </>
            ) : (
              <>
                {currentArray
                  .slice(0, viewMore ? currentArray.length : 14)
                  .map((data, k) => {
                    return (
                      <Grid item xs={12} sm={4} key={k}>
                        <div className='section-box-file-upload'>
                          <div className='section-icon-file'>
                            <svg
                              id='icons_file_'
                              data-name='icons file '
                              xmlns='http://www.w3.org/2000/svg'
                              width='19.499'
                              height='25.998'
                              viewBox='0 0 19.499 25.998'
                            >
                              <path
                                id='Icon_awesome-file-image'
                                data-name='Icon awesome-file-image'
                                d='M19.5,6.192V6.5L13,0h.308a1.219,1.219,0,0,1,.862.357C19.37,5.559,19.5,5.869,19.5,6.192ZM13,0H1.219A1.219,1.219,0,0,0,0,1.219V24.779A1.219,1.219,0,0,0,1.219,26H18.28A1.219,1.219,0,0,0,19.5,24.779V6.5ZM3.277,11.374Z'
                                fill='#2d95f4'
                              />
                              <path
                                id='Icon_awesome-file-image-2'
                                data-name='Icon awesome-file-image'
                                d='M20.255,10Zm-13.784.812A2.437,2.437,0,1,1,4.034,13.25,2.437,2.437,0,0,1,6.471,10.812ZM17.033,23h-13l.025-2.462,2.006-2.006a.581.581,0,0,1,.837.025l2.006,2.006,5.256-5.256a.609.609,0,0,1,.862,0l2.006,2.006Z'
                                transform='translate(-1.568 -3.5)'
                                fill='#fff'
                              />
                            </svg>
                          </div>
                          <div className='box-text-file'>
                            <span>{data.name}</span>
                          </div>
                          <span
                            className={`${
                              !closeBtn && 'pointer'
                            } icon-remove-upload-file`}
                            onClick={() => !closeBtn && reMoveFile(data)}
                          >
                            X
                          </span>
                        </div>
                      </Grid>
                    )
                  })}
                {currentArray.length > 14 && !viewMore && (
                  <Grid item xs={12} sm={4}>
                    <span
                      className='pointer text-view-more-custom-upload-file'
                      onClick={() => setViewMore(true)}
                    >
                      View More
                    </span>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default index
