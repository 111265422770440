import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Button } from 'components/buttons'
import { withTranslation } from 'react-i18next'

import FastField from 'components/fields/Field'
import DateRangeField from 'components/fields/DateRangeField'
import ResourceSelect from 'components/inputs/ResourceSelect'

import { customerTypeSchema } from '@seekster/schemas'

export class CustomersFilterForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    onCloseModal: PropTypes.func,
    t: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    this.props.handleSubmit()

    this.props.onCloseModal()
  }

  render() {
    return (
      <Form>
        <DateRangeField name='created_at' />
        <FastField
          name='customer_type_id'
          component={ResourceSelect}
          schema={customerTypeSchema}
        />

        <div>
          <Button color='primary' onClick={this.handleSubmit}>
            {this.props.t('apply')}
          </Button>

          <Button onClick={this.props.onCloseModal}>{this.props.t('cancel')}</Button>
        </div>
      </Form>
    )
  }
}

export default withTranslation('filters')(CustomersFilterForm)
