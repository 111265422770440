/* eslint-disable space-before-function-paren */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Close } from '@material-ui/icons'
import { Grid, Button } from '@material-ui/core'
import moment from 'moment'
import { Formik, Form } from 'formik'

import { invoiceSchema } from '@seekster/schemas'
import { useCollection } from 'hooks/collections'
import useQuery from 'hooks/useQuery'
import CircularLoader from 'components/CircularLoader'
import Search from 'components/Search'
import Pagination from 'components/Pagination'

import FilterTags from 'components/tags/FilterTags'

import FastField from 'components/fields/Field'
import DateRangeField from 'components/fields/DateRangeField'
import MoneyRangeField from 'components/fields/MoneyRangeField'

import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'
import { INVOICE_STATES } from 'constants/states/invoiceStates'

import createStore from '../../../../createStore'

import './invoicemodal.scss'

export const store = createStore()

const index = (props) => {
  const { setSelectInvoice, action, callListSuggest, setRefundOpenModal } = props
  const { t } = useTranslation(['navbar', 'creditnote', 'invoice'])
  const [openFilter, setOpenFilter] = useState(false)
  const [query, { pushQuery }] = useQuery()
  const [invoices, { loading, totalCount, totalPages }] = useCollection(
    invoiceSchema,
    { page: query.page, query },
    [query]
  )

  const addInvoce = (items) => {
    action.setFieldValue('refund_detail.invoiceID', items.id)
    action.setFieldValue('refund_detail.invoiceNo', items.number)
    action.setFieldValue('refund_detail.issue_date', items.created_at)
    action.setFieldValue('refund_detail.customer_id', items.customer.id)
    pushQuery({})
    callListSuggest(items.id)
    setSelectInvoice(false)
  }

  const initialValues = {
    created_at: '',
    subtotal_satangs: '',
    state: ''
  }

  const saveFilter = (values) => {
    const removeValues = []
    for (const key in values) {
      if (!values[key]) {
        removeValues.push(key)
      }
    }
    removeValues.forEach((key) => delete values[key])

    pushQuery({
      ...query,
      ...values
    })

    setOpenFilter(false)
  }

  useEffect(() => {
    setRefundOpenModal(openFilter)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openFilter])

  return (
    <div className={`section-modal-show-invoice ${openFilter && 'stop-scroll'}`}>
      <div className='section-topic-modal-invoice'>
        <Close onClick={() => setSelectInvoice(false)} className='pointer' />
        <div className='section-text-and-title'>
          <div className='topic-invoice'>{t('invoice.topic')}</div>
          <div className='title-invoice'>{t('invoice.title')}</div>
        </div>
      </div>
      <div className='section-table-filter-invoice'>
        <Grid container spacing={2} className='bg-gray-invoice'>
          <Grid item xs={9} sm={10}>
            <Search />
          </Grid>
          <Grid item xs={3} sm={2}>
            <Button
              className='btn-filter-invoice'
              onClick={() => setOpenFilter((prev) => !prev)}
            >
              FILTERS
            </Button>
          </Grid>
          {openFilter && (
            <Formik enableReinitialize initialValues={initialValues}>
              {(formik) => (
                <Form>
                  <div className='section-modal-invoice-refund'>
                    <div className='inside-modal-invoice-refund'>
                      <div>
                        <DateRangeField name='created_at' />
                      </div>
                      <div className='my-1'>
                        <MoneyRangeField name='subtotal_satangs' defaultCurrency='THB' />
                      </div>
                      <div>
                        <FastField
                          name='state'
                          component={SelectInput}
                          options={arrayToOptions(INVOICE_STATES, {
                            i18nNamespace: 'invoice',
                            i18nKey: 'states'
                          })}
                        />
                      </div>
                      <div className='my-1'>
                        <Button
                          onClick={() => saveFilter(formik.values)}
                          className='text-color-btn-blue apply'
                        >
                          {t('btn.apply')}
                        </Button>

                        <Button
                          onClick={() => setOpenFilter(false)}
                          className='text-color-btn-blue'
                        >
                          {t('btn.cancel')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}

          <Grid item xs={12}>
            {loading ? (
              <CircularLoader />
            ) : (
              <div>
                <div>
                  <div className='mb-1'>
                    <FilterTags />
                  </div>
                  <span>Viewing</span>
                  <span className='mx-1'>{totalCount.toLocaleString()}</span>
                  <span>invoice</span>
                </div>
                <div className='mt-1 bg-white'>
                  <table className='section-table-modal-invoice' cellSpacing='0'>
                    <thead>
                      <tr>
                        <th>{t('table_modal.number')}</th>
                        <th>{t('table_modal.customer')}</th>
                        <th>{t('table_modal.total')}</th>
                        <th>{t('table_modal.state')}</th>
                        <th>{t('table_modal.create_at')}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {invoices.toJS().map((data, k) => {
                        return (
                          <tr key={k} onClick={() => addInvoce(data)} className='pointer'>
                            <td>
                              <span className='text-color-click'>{data.number}</span>
                            </td>
                            <td>
                              <div>
                                <span className='pointer text-color-click'>
                                  {data.customer.full_name}
                                </span>
                              </div>
                              <div>
                                <span className='pointer text-color-click'>
                                  {data.customer.phone_number}
                                </span>
                              </div>
                            </td>
                            <td>
                              <p className='total'>{data.total.full_display}</p>
                            </td>
                            <td>
                              <span className={`box-state status-${data.state}`}>
                                {data.state}
                              </span>
                            </td>
                            <td>
                              <p className='time'>
                                {moment(data.created_at).format(
                                  'ddd, D, MMMM YYYY HH:mm'
                                )}
                              </p>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <div className='py-16'>
                    <Grid item xs={12} container justify='center'>
                      <Pagination totalPages={totalPages} />
                    </Grid>
                  </div>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default index
