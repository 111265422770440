import pluralize from 'pluralize'

import request, { nodeRequest } from 'utils/request'
import convertJsonBodyToFormData from 'utils/convertJsonBodyToFormData'
import {
  CREATE_RESOURCE_SUCCEEDED,
  CREATE_CHILD_RESOURCE_SUCCEEDED,
  FETCH_RESOURCE_SUCCEEDED,
  FETCH_CHILD_RESOURCE_SUCCEEDED,
  FETCH_CHILDREN_RESOURCE_SUCCEEDED,
  UPDATE_RESOURCE_SUCCEEDED,
  UPDATE_CHILD_RESOURCE_SUCCEEDED,
  DESTROY_RESOURCE_SUCCEEDED,
  DESTROY_CHILD_RESOURCE_SUCCEEDED,
  TRIGGER_RESOURCE_EVENT_SUCCEEDED,
  TRIGGER_CHILD_RESOURCE_EVENT_SUCCEEDED,
  FETCH_CHILD_RESOURCE_WITH_CHILD_ID_SUCCEEDED
} from 'constants/resources'

import { getCurrentIdAttribute } from 'selectors/authentication'
import { fetch as fetchCollection } from './collections'

const createSucceeded = (response, schema, options) => ({
  type: CREATE_RESOURCE_SUCCEEDED,
  response,
  schema,
  options
})

const createChildSucceeded = (id, response, schema, childSchema, options) => ({
  type: CREATE_CHILD_RESOURCE_SUCCEEDED,
  id,
  response,
  schema,
  childSchema,
  options
})

const fetchSucceeded = (response, schema, options) => ({
  type: FETCH_RESOURCE_SUCCEEDED,
  response,
  schema,
  options
})

const fetchChildrenSucceeded = (id, response, schema, childrenSchema, options) => ({
  type: FETCH_CHILDREN_RESOURCE_SUCCEEDED,
  id,
  response,
  schema,
  childrenSchema,
  options
})

const fetchResourceChildSucceeded = (response, schema, id, childrenSchema) => ({
  type: FETCH_CHILD_RESOURCE_SUCCEEDED,
  id,
  response,
  schema,
  childrenSchema
})

const fetchResourceChildWithChildIdSucceeded = (
  response,
  schema,
  id,
  childrenSchema,
  childId
) => ({
  type: FETCH_CHILD_RESOURCE_WITH_CHILD_ID_SUCCEEDED,
  id,
  response,
  schema,
  childrenSchema,
  childId
})

const updateSucceeded = (response, schema, options) => ({
  type: UPDATE_RESOURCE_SUCCEEDED,
  response,
  schema,
  options
})

const updateChildSucceeded = (response, schema, childrenSchema) => ({
  type: UPDATE_CHILD_RESOURCE_SUCCEEDED,
  response,
  schema,
  childrenSchema
})

const destroySucceeded = (response, schema, options) => ({
  type: DESTROY_RESOURCE_SUCCEEDED,
  response,
  schema,
  options
})

const destroyChildSucceeded = (response, schema, childrenSchema) => ({
  type: DESTROY_CHILD_RESOURCE_SUCCEEDED,
  response,
  schema,
  childrenSchema
})

const triggerSucceeded = (response, schema) => ({
  type: TRIGGER_RESOURCE_EVENT_SUCCEEDED,
  response,
  schema
})

const triggerChildSucceeded = (id, response, schema, childSchema) => ({
  type: TRIGGER_CHILD_RESOURCE_EVENT_SUCCEEDED,
  id,
  response,
  schema,
  childSchema
})

export const create =
  (data, schema, options = {}) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const locale = options.locale
    const accessToken = getState().getIn(['authentication', 'token'])
    const query = options.query || {}

    const formData = convertJsonBodyToFormData(data)
    const requestBuilder = request
      .post(`/${type}`)
      .locale(locale)
      .query(query)
      .authentication(accessToken)

    Object.keys(formData).forEach((key) => {
      formData[key] !== undefined &&
        formData[key] !== null &&
        requestBuilder.field(key, formData[key])
    })

    return requestBuilder.then((response) => {
      dispatch(createSucceeded(response, schema, options))
      return response
    })
  }

export const createChild =
  (data, schema, id, childSchema, options = {}) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const childType = options.childrenType || childSchema._key.split('/').pop()
    const locale = options.locale
    const accessToken = getState().getIn(['authentication', 'token'])

    const formData = convertJsonBodyToFormData(data)
    const requestBuilder = request
      .post(`/${type}/${id}/${childType}`)
      .locale(locale)
      .authentication(accessToken)

    Object.keys(formData).forEach((key) => {
      formData[key] && requestBuilder.field(key, formData[key])
    })

    return requestBuilder.then((response) => {
      dispatch(createChildSucceeded(id, response, schema, childSchema, options))
      return response
    })
  }

export const createNodeChild =
  (data, schema, id, childSchema, options = {}) =>
  (dispatch) => {
    const type = options.type || schema._key
    const childType = options.childrenType || childSchema._key.split('/').pop()

    const formData = convertJsonBodyToFormData(data)
    const requestBuilder = nodeRequest.post(`/${type}/${id}/${childType}`)

    Object.keys(formData).forEach((key) => {
      formData[key] && requestBuilder.field(key, formData[key])
    })

    return requestBuilder.then((response) => {
      dispatch(createChildSucceeded(id, response, schema, childSchema, options))
      return response
    })
  }

export const fetch =
  (id, schema, options = {}) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const locale = options.locale
    const accessToken = getState().getIn(['authentication', 'token'])

    return request
      .get(`/${type}/${id}`)
      .locale(locale)
      .authentication(accessToken)
      .then((response) => {
        dispatch(fetchSucceeded(response, schema, options))
        return response
      })
  }

export const fetchChildren =
  (schema, id, childrenSchema, options = {}) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const childrenType = options.childrenType || childrenSchema._key.split('/').pop()
    const url = options.url || `/${type}/${id}/${childrenType}`
    const locale = options.locale
    const query = options.query || {}
    const accessToken = getState().getIn(['authentication', 'token'])

    return request
      .get(url)
      .locale(locale)
      .query(query)
      .authentication(accessToken)
      .then((response) => {
        dispatch(fetchChildrenSucceeded(id, response, schema, childrenSchema, options))

        return response
      })
  }

export const fetchBlobFile =
  (schema, id, childrenSchema, options = {}) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const childrenType = options.childrenType || childrenSchema._key.split('/').pop()
    const url = options.url || `/${type}/${id}/${childrenType}`
    const locale = options.locale
    const accessToken = getState().getIn(['authentication', 'token'])

    return request
      .get(url)
      .locale(locale)
      .responseType('blob')
      .authentication(accessToken)
      .then((response) => response)
  }

export const fetchResourceChild =
  (parentSchema, id, childSchema) => (dispatch, getState) => {
    const childType = pluralize.singular(childSchema._key)
    const accessToken = getState().getIn(['authentication', 'token'])

    return request
      .get(`/${parentSchema._key}/${id}/${childType}`)
      .authentication(accessToken)
      .then((response) => {
        dispatch(fetchResourceChildSucceeded(response, parentSchema, id, childSchema))

        return response
      })
  }

export const fetchResourceChildWithId =
  (parentSchema, id, childSchema, childId) => (dispatch, getState) => {
    const accessToken = getState().getIn(['authentication', 'token'])

    return request
      .get(`/${parentSchema._key}/${id}/${childSchema._key}/${childId}`)
      .authentication(accessToken)
      .then((response) => {
        dispatch(
          fetchResourceChildWithChildIdSucceeded(
            response,
            parentSchema,
            id,
            childSchema,
            childId
          )
        )

        return response
      })
  }

export const fetchChildWithImplicitParent =
  (parentSchema, childSchema) => (dispatch, getState) => {
    const accessToken = getState().getIn(['authentication', 'token'])

    return request
      .get(`/${childSchema._key}`)
      .authentication(accessToken)
      .then((response) => {
        const idAttribute = getCurrentIdAttribute(getState(), parentSchema)

        dispatch(fetchChildrenSucceeded(idAttribute, response, parentSchema, childSchema))

        return response
      })
  }

export const update =
  (id, data, schema, options = {}) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const locale = options.locale
    const accessToken = getState().getIn(['authentication', 'token'])

    const formData = convertJsonBodyToFormData(data)

    const requestBuilder = request
      .patch(`/${type}/${id}`)
      .locale(locale)
      .authentication(accessToken)

    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] !== 'undefined' && formData[key] !== null) {
        requestBuilder.field(key, formData[key])
      }
    })

    return requestBuilder.then((response) => {
      dispatch(updateSucceeded(response, schema, options))
      return response
    })
  }

export const updateChild =
  (data, parentSchema, id, childSchema) => (dispatch, getState) => {
    const childType = pluralize.singular(childSchema._key)
    const accessToken = getState().getIn(['authentication', 'token'])

    const formData = convertJsonBodyToFormData(data)
    const requestBuilder = request
      .patch(`/${parentSchema._key}/${id}/${childType}`)
      .authentication(accessToken)

    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] !== 'undefined' && formData[key] !== null) {
        requestBuilder.field(key, formData[key])
      }
    })

    return requestBuilder.then((response) => {
      dispatch(updateChildSucceeded(response, parentSchema, childSchema))
      return response
    })
  }

export const destroy =
  (id, schema, options = {}) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const locale = options.locale
    const accessToken = getState().getIn(['authentication', 'token'])

    return request
      .delete(`/${type}/${id}`)
      .locale(locale)
      .authentication(accessToken)
      .then((response) => {
        dispatch(destroySucceeded(response, schema, options))
        return response
      })
  }

export const destroyChild = (parentSchema, id, childSchema) => (dispatch, getState) => {
  const childType = pluralize.singular(childSchema._key)
  const accessToken = getState().getIn(['authentication', 'token'])

  return request
    .delete(`/${parentSchema._key}/${id}/${childType}`)
    .authentication(accessToken)
    .then((response) => {
      dispatch(destroyChildSucceeded(response, parentSchema, childSchema))
      return response
    })
}

export const trigger = (id, event, schema) => (dispatch, getState) => {
  const accessToken = getState().getIn(['authentication', 'token'])

  return request
    .put(`/${schema._key}/${id}/${event}`)
    .authentication(accessToken)
    .then((response) => {
      dispatch(triggerSucceeded(response, schema))

      return response
    })
}

export const triggerWithData = (id, event, data, schema) => (dispatch, getState) => {
  const accessToken = getState().getIn(['authentication', 'token'])
  const formData = convertJsonBodyToFormData(data)
  const requestBuilder = request
    .put(`/${schema._key}/${id}/${event}`)
    .authentication(accessToken)

  Object.keys(formData).forEach((key) => {
    if (typeof formData[key] !== 'undefined' && formData[key] !== null) {
      requestBuilder.field(key, formData[key])
    }
  })

  return requestBuilder.then((response) => {
    dispatch(triggerSucceeded(response, schema))

    return response
  })
}

export const triggerChild =
  (parentSchema, parentId, childSchema, childId, event) => (dispatch, getState) => {
    const accessToken = getState().getIn(['authentication', 'token'])

    return request
      .put(
        `/${parentSchema._key}/${parentId}/${childSchema._key
          .split('/')
          .pop()}/${childId}/${event}`
      )
      .authentication(accessToken)
      .then((response) => {
        dispatch(triggerChildSucceeded(parentId, response, parentSchema, childSchema))

        return response
      })
  }

export const bulkDestroy =
  (data, schema, options = {}) =>
  (dispatch, getState) => {
    const accessToken = getState().getIn(['authentication', 'token'])
    const requestBuilder = request
      .delete(`/${schema._key}/bulk_destroy`)
      .authentication(accessToken)

    const formData = convertJsonBodyToFormData(data)

    Object.keys(formData).forEach((key) => {
      formData[key] !== undefined &&
        formData[key] !== null &&
        requestBuilder.field(key, formData[key])
    })

    return requestBuilder.then((response) => {
      dispatch(fetchCollection(schema))
      return response
    })
  }

export const bulkUpdateProvider = (data, schema) => (dispatch, getState) => {
  const accessToken = getState().getIn(['authentication', 'token'])
  const requestBuilder = request
    .put(`/${schema._key}/assign_provider`)
    .authentication(accessToken)
    .set('Content-Type', 'application/json')
    .send(JSON.stringify(data))

  return requestBuilder.then((response) => {
    dispatch(fetchCollection(schema))
    return response
  })
}

export const fetchNode =
  (id, schema, options = {}) =>
  (dispatch, getState) => {
    const type = options.type || schema._key

    return nodeRequest.get(`/${type}/${id}`).then((response) => {
      dispatch(fetchSucceeded(response, schema, options))
      return response
    })
  }

export const createNode =
  (data, schema, options = {}) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const locale = options.locale
    const query = options.query || {}

    const formData = convertJsonBodyToFormData(data)
    const requestBuilder = nodeRequest.post(`/${type}`).locale(locale).query(query)

    Object.keys(formData).forEach((key) => {
      formData[key] !== undefined &&
        formData[key] !== null &&
        requestBuilder.field(key, formData[key])
    })

    return requestBuilder.then((response) => {
      dispatch(createSucceeded(response, schema, options))
      return response
    })
  }

export const updateNode =
  (id, data, schema, options = {}) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const locale = options.locale

    const formData = convertJsonBodyToFormData(data)

    const requestBuilder = nodeRequest.put(`/${type}/${id}`).locale(locale)

    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] !== 'undefined' && formData[key] !== null) {
        requestBuilder.field(key, formData[key])
      }
    })

    return requestBuilder.then((response) => {
      dispatch(updateSucceeded(response, schema, options))
      return response
    })
  }
