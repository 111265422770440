import moment from 'moment'
import { get } from 'lodash'

export const agentsQuery = ({
  search,
  created_at
}) => {
  return {
    first_name_or_last_name_or_phone_number_or_email_cont: search,

    created_at_gteq: get(created_at, 'from')
      ? moment(created_at.from).startOf('day').toISOString(true)
      : undefined,
    created_at_lteq: get(created_at, 'to')
      ? moment(created_at.to).endOf('day').toISOString(true)
      : undefined
  }
}

export default agentsQuery
