import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Avatar,
  withMobileDialog
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Modal from 'components/containers/Modal'

import { Button } from 'components/buttons'
import UserCard from 'modules/users/UserCard'
import Panel from 'components/containers/Panel'
import MergeFirebaseUserForm from 'forms/resources/MergeFirebaseUserForm'
import { useResourceAction as useFirebaseResourceAction, useResource as useFirebaseResource } from 'hooks/firebase/resources'

import { useReference } from 'hooks/utils'
import {
  ticketSchema,
  customerSchema,
  firebaseUserSchema,
  providerSchema
} from '@seekster/schemas'
import Tag from 'components/Tag'
import styles from './UserInfoPanelStyles'

export function UserInfoPanel ({
  match,
  classes,
  fullScreen: isMobile,
  tenant
}) {
  const { t } = useTranslation('tickets')
  const [ticket] = useFirebaseResource(`tenants/${tenant}/tickets`, match.params.id, ticketSchema)
  const [user] = useReference(ticket.get('requestIdRef'), firebaseUserSchema)
  const [modalOpen, setModalOpen] = useState(false)
  const { update } = useFirebaseResourceAction(ticketSchema)

  const type = user.getIn(['workforce', 'type']) === 'customer' ? customerSchema : providerSchema

  return (
    <Panel
      responsive
      square
    >
      <Grid container className={classes.root}>
        <Grid
          container
          className={classes.profileContainer}
        >
          <Grid item xs={12} className={classes.displayFlex} justify='center' alignItems='center'>
            <Avatar
              src={user.get('image_url')}
              className={classes.avatar}
            />
          </Grid>

          <Grid item xs={12} className={classes.displayFlex} justify='center' alignItems='center'>
            <h3>{user.get('name')} <Tag value={user.get('source')} /></h3>
          </Grid>

          <Grid item xs={12} className={classes.contentContainer}>
            {
              user.get('workforce') ? <div>
                <UserCard userId={user.getIn(['workforce', 'id'])} schema={type} />
              </div>
                : <Modal
                  isOpen={modalOpen}
                  onCloseModal={() => setModalOpen(false)}
                  trigger={
                    <Button
                      color='primary'
                      variant='contained'
                      fullWidth
                      onClick={() => setModalOpen(true)}
                    >
                      {t('merge_account')}
                    </Button>
                  }
                >
                  <MergeFirebaseUserForm
                    onCloseModal={() => setModalOpen(false)}
                    ticketId={match.params.id}
                    userId={user.get('id')}
                    onSubmit={update}
                    tenant={tenant}
                  />
                </Modal>
            }
          </Grid>
        </Grid>
      </Grid>
    </Panel>
  )
}

UserInfoPanel.defaultProps = {
  tenant: 'seekster'
}

UserInfoPanel.propTypes = {
  fullScreen: PropTypes.bool,
  match: PropTypes.object,
  classes: PropTypes.object,
  tenant: PropTypes.string
}

export default compose(
  withStyles(styles),
  withMobileDialog()
)(UserInfoPanel)
