import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { destroy } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { deviceSchema } from '@seekster/schemas'

import UserDevices from './UserDevices'

const mapStateToProps = state => ({
  getDevices: (schema, id) => {
    if (getResource(state, id, schema)) {
      return getResource(state, id, schema).get('devices')
    }
  }
})

const mapDispatchToProps = {
  fetchDevices: (schema, id, options) => fetchChildren(schema, id, deviceSchema, options),
  destroyDevice: id => destroy(id, deviceSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDevices)
