import React, { useState } from 'react'
import {
  Grid,
  TextareaAutosize,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton
} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import ButtonLoader from 'components/buttons/ButtonLoader'
import useQuery from 'hooks/useQuery'
import { PersonAddRounded, Close } from '@material-ui/icons'

import { Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import DropzoneInput from 'components/inputs/DropzoneInput'
import ApprovalPicker from 'components/inputs/ApprovalPicker'
import Search from 'components/Search'
import ProviderOrgnaizationTable from 'components/tables/ProviderOrgnaizationTable'
import Pagination from 'components/Pagination'
import { approvedProviderSchema } from '@seekster/schemas'
import { DateFormatter } from 'components/formatters'

import { Field, FastField } from 'components/fields/Field'

import { useCollection } from 'hooks/collections'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '20px 20px 10px 20px'
  },
  title: {
    fontSize: '16px',
    fontWeight: '600'
  },
  searchContainer: {
    paddingBottom: 8
  },
  CardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  CardButton: {
    marginTop: 8
  },
  textContent: {
    margin: 0
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  },
  dialog: {
    maxHeight: 450
  },
  buttonAction: {
    width: 110
  },
  deleteIcon: {
    padding: 0
  },
  addContactBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '14px 0',
    width: '100%'
  }
}))

export function OrganizationForm({ isSubmitting, values, setFieldValue }) {
  const [query] = useQuery()
  const [providers, { totalPages }] = useCollection(
    approvedProviderSchema,
    {
      queryParam: {
        order: {
          first_name: 'desc'
        }
      },
      page: query.page,
      query
    },
    [query]
  )

  const { t } = useTranslation('organizations')
  const [open, setOpen] = useState()
  const [openProviderModal, setOpenProviderModal] = useState(false)
  const [isExpandProviders, setIsExpandProviders] = useState(false)
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  const handleProviderModalClose = () => {
    setOpenProviderModal(false)
  }

  const handleDeleteAdmin = (id) => {
    const newValue = values.administrators.filter((x) => {
      return x.id !== id && x.wfUserId !== id
    })

    setFieldValue('administrators', newValue)
  }

  const handleDeleteProvider = (id) => {
    const newValue = values.providers.filter((x) => {
      return x.id !== id && x.wfUserId !== id
    })

    setFieldValue('providers', newValue)
  }

  function handleAdminSelected(event, provider) {
    const selectedIndex = values.administrators.findIndex(
      (item) => item.id === provider.get('id') || item.wfUserId === provider.get('id')
    )
    let newSelected = []

    if (selectedIndex === -1) {
      if (values.administrators.length === 0) {
        setFieldValue('administrators', [provider.toJS()])
      } else {
        setFieldValue('administrators', [...values.administrators, provider.toJS()])
      }
    } else {
      newSelected = [...values.administrators]
      newSelected.splice(selectedIndex, 1)
      setFieldValue('administrators', [...newSelected])
    }
  }

  function handleProviderSelected(event, provider) {
    const selectedIndex = values.providers.findIndex(
      (item) => item.id === provider.get('id') || item.wfUserId === provider.get('id')
    )
    let newSelected = []

    if (selectedIndex === -1) {
      if (values.providers.length === 0) {
        setFieldValue('providers', [provider.toJS()])
      } else {
        setFieldValue('providers', [...values.providers, provider.toJS()])
      }
    } else {
      newSelected = [...values.providers]
      newSelected.splice(selectedIndex, 1)
      setFieldValue('providers', [...newSelected])
    }
  }

  const handleExpandProviders = () => setIsExpandProviders(true)

  return (
    <Form>
      <Grid container className='customer-container'>
        <Grid item xs={12} sm={6}>
          <b>{t('add_organization_details')}</b>
        </Grid>
      </Grid>

      <Grid container className='customer-container'>
        <Grid container className='customer-details'>
          <Grid container xs={9}>
            <Grid item xs={12} sm={12}>
              <Field name='name' />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field name='phoneNumber' maxLength={10} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field name='email' type='email' />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Field
                name='isRequireAdminApprove'
                type='radio'
                component={ApprovalPicker}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Field name='isRequireOrgApprove' type='radio' component={ApprovalPicker} />
            </Grid>

            <Grid item xs={12} sm={12}>
              <FastField name='information' component={TextareaAutosize} />
            </Grid>
          </Grid>
          <Grid xs={12} sm={3} style={{ padding: 8 }}>
            <Field
              name='picture'
              component={DropzoneInput}
              width={200}
              height={200}
              initialValue={values.pictureUrl}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container className='customer-container'>
        <Grid container xs={12} className='customer-details'>
          <div style={{ width: '100%' }}>
            <b>{t('contacts')}</b>
            {values.administrators.length > 0 ? (
              <>
                <Table className={classes.table}>
                  <TableBody>
                    {values.administrators.map((admin) => (
                      <TableRow key={admin.id || admin._id}>
                        <TableCell component='th' scope='row'>
                          <img
                            src='https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/default_avatar.png'
                            alt=''
                            style={{
                              borderRadius: '50%',
                              margin: '-10px 10px -7px -5px',
                              height: '25px'
                            }}
                          />
                          {admin.display_name || admin.fullName}
                        </TableCell>
                        <TableCell>{admin.phone_number || admin.phoneNumber}</TableCell>
                        <TableCell>
                          <DateFormatter value={admin.created_at || admin.createdAt} />
                        </TableCell>
                        <TableCell
                          onClick={() => handleDeleteAdmin(admin.id || admin.wfUserId)}
                        >
                          <IconButton className={classes.deleteIcon}>
                            <Close />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div className={classes.addContactBtn}>
                  <ButtonLoader
                    className={classes.CardButton}
                    onClick={() => setOpen(true)}
                    variant='contained'
                    color='primary'
                  >
                    {t('add_contacts')}
                  </ButtonLoader>
                </div>
              </>
            ) : (
              <Card>
                <CardContent className={classes.CardContent}>
                  <PersonAddRounded />
                  <label>คุณยังไม่มีรายชื่อผู้ติดต่อ</label>
                  <p className={classes.textContent}>
                    เพิ่มรายชื่อผู้ติดต่อในองค์กรของคุณได้ง่ายๆ โดยค้นหา ชื่อ
                  </p>{' '}
                  <p className={classes.textContent}>
                    หรือ หมายเลขโทรศัพท์ จากนั้นทำการเพิ่มลงในองค์กรของคุณ
                  </p>
                  <ButtonLoader
                    className={classes.CardButton}
                    onClick={() => setOpen(true)}
                    variant='contained'
                    color='primary'
                  >
                    {t('add_contacts')}
                  </ButtonLoader>
                </CardContent>
              </Card>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container className='customer-container'>
        <Grid container xs={12} className='customer-details'>
          <div style={{ width: '100%' }}>
            <b>{t('providers')}</b>

            {values.providers.length > 0 ? (
              <>
                <Table className={classes.table}>
                  <TableBody>
                    {values.providers.map((provider, key) =>
                      key >= 10 && !isExpandProviders ? (
                        <></>
                      ) : (
                        <TableRow key={provider.id || provider._id}>
                          <TableCell component='th' scope='row'>
                            <img
                              src='https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/default_avatar.png'
                              alt=''
                              style={{
                                borderRadius: '50%',
                                margin: '-10px 10px -7px -5px',
                                height: '25px'
                              }}
                            />
                            {provider.display_name || provider.fullName}
                          </TableCell>
                          <TableCell>
                            {provider.phone_number || provider.phoneNumber}
                          </TableCell>
                          <TableCell>
                            <DateFormatter
                              value={provider.created_at || provider.createdAt}
                            />
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              handleDeleteProvider(provider.id || provider.wfUserId)
                            }
                          >
                            <IconButton className={classes.deleteIcon}>
                              <Close />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                  {values.providers.length > 10 && !isExpandProviders && (
                    <u
                      onClick={handleExpandProviders}
                      style={{ width: '100%', cursor: 'pointer' }}
                    >
                      {t('see_more')}...
                    </u>
                  )}

                  <div className={classes.addContactBtn}>
                    <ButtonLoader
                      className={classes.CardButton}
                      onClick={() => setOpenProviderModal(true)}
                      variant='contained'
                      color='primary'
                    >
                      {t('add_contacts')}
                    </ButtonLoader>
                  </div>
                </div>
              </>
            ) : (
              <Card>
                <CardContent className={classes.CardContent}>
                  <PersonAddRounded />
                  <label>คุณยังไม่มีรายชื่อผู้ให้บริการ</label>
                  <p className={classes.textContent}>
                    เพิ่มรายชื่อผู้ติดต่อในองค์กรของคุณได้ง่ายๆ โดยค้นหา ชื่อ
                  </p>{' '}
                  <p className={classes.textContent}>
                    หรือ หมายเลขโทรศัพท์ จากนั้นทำการเพิ่มลงในองค์กรของคุณ
                  </p>
                  <ButtonLoader
                    className={classes.CardButton}
                    onClick={() => setOpenProviderModal(true)}
                    variant='contained'
                    color='primary'
                  >
                    {t('add_providers')}
                  </ButtonLoader>
                </CardContent>
              </Card>
            )}
          </div>
        </Grid>
      </Grid>

      <Dialog fullWidth maxWidth='lg' open={open} onClose={handleClose}>
        <DialogTitle onClose={handleClose}>
          {t('members')}
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
          <Search withButton />
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <div className={classes.pageContainer}>
            <div className={classes.metaContainer}>
              <ProviderOrgnaizationTable
                providers={providers}
                onSelect={handleAdminSelected}
                selectedValue={values.administrators}
                setFieldValue={setFieldValue}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} container justify='center'>
            <Pagination totalPages={totalPages} />
          </Grid>
          <ButtonLoader
            className={classes.buttonAction}
            onClick={handleClose}
            variant='contained'
            color='primary'
          >
            {t('add_member')}
          </ButtonLoader>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth='lg'
        open={openProviderModal}
        onClose={handleProviderModalClose}
      >
        <DialogTitle onClose={handleProviderModalClose}>
          {t('members')}
          <IconButton className={classes.closeButton} onClick={handleProviderModalClose}>
            <Close />
          </IconButton>
          <Search withButton />
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <div className={classes.pageContainer}>
            <div className={classes.metaContainer}>
              <ProviderOrgnaizationTable
                providers={providers}
                onSelect={handleProviderSelected}
                selectedValue={values.providers}
                setFieldValue={setFieldValue}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} container justify='center'>
            <Pagination totalPages={totalPages} />
          </Grid>
          <ButtonLoader
            className={classes.buttonAction}
            onClick={handleProviderModalClose}
            variant='contained'
            color='primary'
          >
            {t('add_member')}
          </ButtonLoader>
        </DialogActions>
      </Dialog>

      <Grid container className='customer-container'>
        <Grid xs={12} container justify='flex-end'>
          <ButtonLoader
            type='submit'
            variant='contained'
            color='primary'
            isLoading={isSubmitting}
          >
            {t('save')}
          </ButtonLoader>
        </Grid>
      </Grid>
    </Form>
  )
}

export default OrganizationForm
