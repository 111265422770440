import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, update } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { getCollection } from 'selectors/collections'
import { fetch as fetchCollections, fetchChildren } from 'actions/collections'
import { companySchema, customerSchema } from '@seekster/schemas'

import CompanyEditPage from './CompanyEditPage'

const mapStateToProps = (state, { match }) => ({
  company: getResource(state, match.params.id, companySchema),
  customers: getCollection(state, customerSchema)
})

const mapDispatchToProps = {
  loadCompany: (id, options) => fetch(id, companySchema, options),
  loadCustomers: options => fetchCollections(customerSchema, options),
  loadCompanyCustomers: (id, options) => fetchChildren(companySchema, id, customerSchema, options),
  updateCompany: (id, data, options) => update(id, data, companySchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEditPage)
