import { red, green } from '@material-ui/core/colors'

export default theme => ({
  item: {
    padding: `10px 0 10px 10px`,

    '&:not(:last-child)': {
      marginBottom: 10
    }
  },

  details: {
    marginBottom: 10
  },

  addButton: {
    width: '77%',
    marginRight: 20,
    fontWeight: 600,
    color: green[800],
    borderColor: green[800],

    '&:hover': {
      backgroundColor: green[50]
    }
  },

  removeButton: {
    width: '77%',
    marginRight: 20,
    fontWeight: 600,
    color: red[800],
    borderColor: red[800],

    '&:hover': {
      backgroundColor: red[50]
    }
  },

  picker: {
    textAlign: 'right'
  },

  divider: {
    marginBottom: 10
  }
})
