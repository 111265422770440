import React from 'react'
import { Link } from 'react-router-dom'
import { Table, Column } from 'components/tables/Table'
import { Paper, Avatar } from '@material-ui/core'
import BooleanChip from 'components/BooleanChip'

import { DateTag } from 'components/tags'

export function OrganizationsTable({ organizations }) {
  return (
    <Paper>
      <Table collection={organizations}>
        <Column
          name='number'
          isSort
          render={(_id, organizations) => (
            <Link to={`/organizations/${organizations.get('_id')}`}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  style={{
                    marginRight: 4,
                    verticalAlign: 'middle',
                    height: 30,
                    width: 30
                  }}
                  src={
                    organizations.get('pictureUrl') !== ''
                      ? organizations.get('pictureUrl')
                      : 'https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/Org-icon.svg'
                  }
                />
                {organizations.get('number')}
              </div>
            </Link>
          )}
        />
        <Column name='name' />
        <Column name='email' />
        <Column name='phoneNumber' />
        <Column name='isRequireAdminApprove' component={BooleanChip} />
        <Column name='isRequireOrgApprove' component={BooleanChip} />
        <Column name='createdAt' component={DateTag} isSort />
        <Column name='updatedAt' component={DateTag} isSort />
      </Table>
    </Paper>
  )
}

export default OrganizationsTable
