import { Map } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import BankAccountForm from './BankAccountForm'

const formikConfig = {
  mapPropsToValues: ({ bankAccount = Map() }) => ({
    account_name: bankAccount.get('account_name', ''),
    description: bankAccount.get('description', ''),
    account_number: bankAccount.get('formatted_account_number', ''),
    bank_id: bankAccount.getIn(['bank', 'id']) || '',
    disabled: bankAccount.get('disabled') || false,
    book_bank: bankAccount.get('book_bank_url', '')
  }),

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    account_name: Yup.string().required(),
    account_number: Yup.string().matches(/^\d{10}$/, 'Invalid Account Number').required(),
    bank_id: Yup.number().required()
  }),

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values)
      .catch(error => {
        setSubmitting(false)

        setErrors({ message: error.response.body.message })
      })
  }
}

export default withFormik(formikConfig)(BankAccountForm)
