export default ({ breakpoints, spacing }) => ({
  avatar: {
    width: 65,
    height: 65,
    marginTop: spacing(0.5),

    [breakpoints.up('sm')]: {
      width: 50,
      height: 50,
      marginTop: 0
    }
  },

  icon: {
    margin: '0 4px -5px 0'
  },

  state: {
    marginTop: spacing(2),
    display: 'blocked',

    [breakpoints.up('sm')]: {
      marginTop: 0
    }
  }
})
