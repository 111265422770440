import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'formik'
import { Grid, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { Field } from 'components/fields/Field'
import ButtonLoader from 'components/buttons/ButtonLoader'
import ResourceSelect from 'components/inputs/ResourceSelect'
import { agentSchema, departmentSchema } from '@seekster/schemas'
import { useCollectionChildren } from 'hooks/collections'

import styles from './AgentAssignmentFormStyles'

export function AgentAssignmentForm ({
  classes,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  values,
  dirty
}) {
  const [userType, setUserType] = useState(values.department ? 'department' : 'agent')
  const [departmentId, setDepartmentId] = useState(1)
  const [agentDepartment] = useCollectionChildren(
    departmentSchema,
    departmentId,
    agentSchema,
    {},
    [departmentId]
  )

  useEffect(() => {
    if (values.department && values.id) {
      setDepartmentId(values.id)
    }
  }, [values])

  function onHandleChange (value) {
    setFieldValue('id', [])

    if (value === 'department') {
      setFieldValue('department', true)
    }
    else {
      setFieldValue('department', [])
    }

    setUserType(value)
  }

  function handleSubmitForm () {
    let recipientsAttributes = []

    if (userType === 'department') {
      agentDepartment.forEach(agent => {
        const temp = recipientsAttributes
        recipientsAttributes = [...temp,
          {
            user_id: agent,
            user_type: 'Agent'
          }]
      })
      setFieldValue('recipientsAttributes', recipientsAttributes).then(() => {
        handleSubmit()
      })
    }
    else {
      handleSubmit()
    }
  }

  return (
    <Form className={classes.root}>
      <Grid item xs={12} className={classes.titleContanier}>
        <h2>Assign Agent</h2>
      </Grid>

      <RadioGroup aria-label='position' name='position' value={userType} onChange={event => onHandleChange(event.target.value)} row>
        <FormControlLabel
          value='agent'
          control={
            <Radio color='primary' />
          }
          label='Agent'
        />

        <FormControlLabel
          value='department'
          control={
            <Radio color='primary' />
          }
          label='Department'
        />
      </RadioGroup>

      <Grid item xs={12} className={classes.fieldContainer}>
        {userType === 'agent' && <Field noLabel name='id' component={ResourceSelect} schema={agentSchema} />}
        {userType === 'department' && <Field noLabel name='id' component={ResourceSelect} schema={departmentSchema} />}
      </Grid>

      {
        dirty &&
          <Grid item xs={12} container justify='flex-end'>
            <ButtonLoader
              color='primary'
              variant='contained'
              onClick={handleSubmitForm}
              isLoading={isSubmitting}
            >
              Save
            </ButtonLoader>
          </Grid>
      }
    </Form>
  )
}

AgentAssignmentForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  isSubmitting: PropTypes.bool,
  dirty: PropTypes.bool,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func
}

export default withStyles(styles)(AgentAssignmentForm)
