export default () => ({
  integrationCard: {
    '&:hover': {
      textDecoration: 'none'
    }
  },

  icon: {
    width: 50,
    height: 50
  }
})
