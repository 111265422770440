import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ spacing, breakpoints, transitions }) => ({
  root: {
    position: 'fixed',
    width: '100%',
    '@media screen and (min-width: 767px)': {
      width: ({ sidebarOpen }) => `calc(100% - ${sidebarOpen ? 250 : 0}px)`
    },

    transition: ({ sidebarOpen }) => transitions.create('width', {
      easing: sidebarOpen ? transitions.easing.easeOut : transitions.easing.sharp,
      duration: sidebarOpen ? transitions.duration.enteringScreen : transitions.duration.leavingScreen
    }),
    zIndex: 1,
    backgroundColor: '#eee',
    boxShadow: 'none',
    borderBottom: '1px solid #ddd',
    display: 'grid',
    gridTemplateRows: 'auto',

    [breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr 73px',
      gridTemplateAreas: ({ actions = [] }) => actions.length > 0 ? (
        `
          'header actions'
          'primary-actions primary-actions'
          'navbar navbar'
        `
      ) : (
          `
          'header actions'
          'primary-actions primary-actions'
          'navbar navbar'
        `
        )
    },

    [breakpoints.up('sm')]: {
      gridTemplateColumns: '55% 1fr 73px',
      gridTemplateAreas: ({ actions = [] }) => actions.length > 0 ? (
        `
          'header primary-actions actions'
          'navbar navbar navbar'
        `
      ) : (
          `
          'header primary-actions primary-actions'
          'navbar navbar navbar'
        `
        )
    }
  },

  spacer: {
    height: ({ componentHeight }) => componentHeight
  },

  header: {
    gridArea: 'header',
    padding: '16px 16px 8px 16px'
  },

  linkIconContainer: {
    gridArea: 'actions',
    padding: spacing(3, 0, 0, 0)
  },

  linkIcon: {
    fontSize: 30,
    cursor: 'pointer'
  },

  linkIconDisable: {
    fontSize: 30,
    opacity: 0.5
  },

  title: {
    margin: 0,
    fontSize: '2.5rem',
    fontWeight: 400
  },

  backText: {
    margin: spacing(0, 0, 1, 0)
  },

  actions: {
    gridArea: 'actions',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2, 2, 2, 2)
  },

  primaryActions: {
    gridArea: 'primary-actions',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: spacing(2),

    [breakpoints.down('xs')]: {
      padding: spacing(2),
      justifyContent: 'space-between'
    }
  },

  navbar: {
    gridArea: 'navbar',
    padding: spacing(0, 2)
  },

  avatarContainer: {
    display: 'inline-flex',
    paddingBottom: 16
  },

  avatar: {
    width: 80,
    // border: '1px solid #cecece',
    height: 80
  },

  titleAvatar: {
    margin: spacing(2, 0, 1, 0),
    fontSize: '2.5rem',
    fontWeight: 400,
    paddingLeft: 16
  }
}))
