import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { compose } from 'redux'
import {
  withMobileDialog,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Select,
  InputBase,
  MenuItem
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import withLocalizedFields from 'hoc/withLocalizedFields'
import { ResponsiveMenu, ResponsiveMenuItem } from 'components/menus/ResponsiveMenu'
import WalletTransactionForm from 'forms/actions/WalletTransactionForm'

export class WalletTransactionMenu extends React.Component {
  static propTypes = {
    resource: ImmutablePropTypes.map,
    parentSchema: PropTypes.object,
    fullScreen: PropTypes.bool,
    createTransaction: PropTypes.func,
    fetchTransactions: PropTypes.func,
    locale: PropTypes.string,
    setLocale: PropTypes.func,
    fetchJobs: PropTypes.func,
    fetchTransactionCodes: PropTypes.func,
    isProvider: PropTypes.bool,

    t: PropTypes.func
  }

  static defaultProps = {
    resource: Map(),
    fetchJobs: () => {}
  }

  constructor(props) {
    super(props)

    this.handleOpenMenu = this.handleOpenMenu.bind(this)
    this.handleCloseMenu = this.handleCloseMenu.bind(this)
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleSubmitTransaction = this.handleSubmitTransaction.bind(this)

    this.state = {
      anchorEl: null,
      menuOpen: false,
      modalOpen: false,
      type: ''
    }
  }

  handleOpenMenu(e) {
    this.setState({ menuOpen: true, anchorEl: e.currentTarget })
  }

  handleCloseMenu() {
    this.setState({ menuOpen: false, anchorEl: null })
  }

  selectTransactionType(type) {
    this.setState({ type })

    this.handleCloseMenu()

    this.handleOpenModal()
  }

  handleOpenModal() {
    this.setState({ modalOpen: true })
  }

  handleCloseModal() {
    this.setState({ modalOpen: false })
  }

  handleSubmitTransaction(data) {
    const { createTransaction, fetchTransactions, resource, parentSchema } = this.props

    this.handleCloseModal()

    return createTransaction(resource.get('id'), data, parentSchema).then(() =>
      fetchTransactions(resource.get('id'), parentSchema)
    )
  }

  renderLocaleSelect() {
    return [
      <Select
        style={{ float: 'right' }}
        value={this.props.locale}
        onChange={(e) => this.props.setLocale(e.target.value)}
        input={<InputBase />}
        key=''
      >
        <MenuItem value='en'>
          <span role='img' aria-label='GB flag'>
            🇬🇧{' '}
          </span>
          English
        </MenuItem>
        <MenuItem value='th'>
          <span role='img' aria-label='Thai flag'>
            🇹🇭{' '}
          </span>
          ภาษาไทย
        </MenuItem>
      </Select>
    ]
  }

  render() {
    return (
      <>
        <ResponsiveMenu
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
          anchorEl={this.state.anchorEl}
          trigger={
            <IconButton onClick={this.handleOpenMenu}>
              <MoreVert />
            </IconButton>
          }
        >
          <ResponsiveMenuItem
            content={this.props.t('transactions.deposit')}
            onClick={() => this.selectTransactionType('Wallet::Deposit')}
          />

          <ResponsiveMenuItem
            content={this.props.t('transactions.payment')}
            onClick={() => this.selectTransactionType('Wallet::Payment')}
          />
        </ResponsiveMenu>

        <Dialog
          fullWidth
          maxWidth='xs'
          fullScreen={this.props.fullScreen}
          open={this.state.modalOpen}
          onClose={this.handleCloseModal}
        >
          <DialogTitle>
            {this.props.t(`transactions.${this.state.type.slice(8).toLowerCase()}`)}
            {this.renderLocaleSelect()}
          </DialogTitle>
          <DialogContent>
            <WalletTransactionForm
              onSubmit={this.handleSubmitTransaction}
              onCancel={this.handleCloseModal}
              resource={this.props.resource}
              type={this.state.type}
              fetchJobs={this.props.fetchJobs}
              fetchCodes={this.props.fetchTransactionCodes}
              isProvider={this.props.isProvider}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

export default compose(
  withLocalizedFields,
  withMobileDialog(),
  withTranslation('wallets')
)(WalletTransactionMenu)
