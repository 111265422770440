import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Grid, ButtonBase, Popover, TextareaAutosize } from '@material-ui/core'
import { SketchPicker } from 'react-color'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import ButtonLoader from 'components/buttons/ButtonLoader'
import Panel from 'components/containers/Panel'
import { Field } from 'components/fields/Field'
import ResourceSelect from 'components/inputs/ResourceSelect'
import SelectInput from 'components/inputs/SelectInput'
import SwitchInput from 'components/inputs/SwitchInput'
import { PLATFORMS } from 'constants/platforms'
import { ACCESS_TYPES } from 'constants/accessTypes'
import { appSchema } from '@seekster/schemas'
import arrayToOptions from 'utils/arrayToOptions'

import styles from './ClientFormStyles'

export function ClientForm ({ classes, t, isSubmitting }) {
  const [anchorEl, setAnchorEl] = useState(null)

  function handleOpenColorPicker (e) {
    setAnchorEl(e.currentTarget)
  }

  function handleCloseColorPicker () {
    setAnchorEl(null)
  }

  return (
    <Grid container spacing={3} justify='center'>
      <Grid item xs={12} sm={9}>
        <Panel title={t('details')}>
          <Grid container spacing={3} component={Form} style={{ padding: '10px 16px' }}>
            <Grid item xs={12}>
              <Field name='name' />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name='app_id'
                component={ResourceSelect}
                schema={appSchema}
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <Field
                name='color'
                render={({ field, form }) => (
                  <>
                    <ButtonBase
                      onClick={handleOpenColorPicker}
                      className={classes.colorPicker}
                    >
                      <div
                        style={{ backgroundColor: field.value }}
                        className={classes.colorPreview}
                      />
                      <div>{field.value}</div>
                    </ButtonBase>

                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleCloseColorPicker}
                    >
                      <SketchPicker
                        {...field}
                        color={field.value}
                        onChange={({ hex }) => form.setFieldValue(field.name, hex)} />
                    </Popover>
                  </>
                )}
              />
            </Grid>

            <Grid item xs={6} sm={3} container alignItems='flex-end'>
              <Field name='official' component={SwitchInput} noLabel />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name='platform'
                component={SelectInput}
                options={arrayToOptions(PLATFORMS, { i18nNamespace: 'common', i18nKey: 'platforms' })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name='access_type'
                component={SelectInput}
                options={arrayToOptions(ACCESS_TYPES, { i18nNamespace: 'common', i18nKey: 'access_types' })}
              />
            </Grid>

            <Grid item xs={12}>
              <Field name='opener' />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field name='latest_version' />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field name='minimum_supported_version' />
            </Grid>

            <Grid item xs={12}>
              <Field name='fcm_server_key' component={TextareaAutosize} />
            </Grid>

            <Grid item xs={12}>
              <Field name='scheme' />
            </Grid>

            <Grid item xs={12}>
              <Field name='webhook_url' />
            </Grid>

            <Grid item xs={12} container justify='flex-end'>
              <ButtonLoader
                type='submit'
                variant='contained'
                color='primary'
                isLoading={isSubmitting}
              >
                {t('save')}
              </ButtonLoader>
            </Grid>
          </Grid>
        </Panel>
      </Grid>
    </Grid>
  )
}

ClientForm.propTypes = {
  classes: PropTypes.object,
  isSubmitting: PropTypes.bool,

  t: PropTypes.func
}

export default compose(
  withStyles(styles),
  withTranslation('clients')
)(ClientForm)
