import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { TableCell } from '@material-ui/core'

export class Column extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    index: PropTypes.number,
    resource: PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.object]),
    component: PropTypes.func,
    render: PropTypes.func,
    to: PropTypes.func
  }

  renderValue(resource) {
    const { name, to, render, component: Component, index } = this.props

    const value = name && resource.getIn(name.split('.'))

    let returnValue = value

    if (Component) {
      returnValue = <Component value={value} />
    } else if (render) {
      returnValue = render(value, resource, index)
    }

    if (to) {
      return <Link to={to(resource)}>{returnValue}</Link>
    } else {
      return returnValue
    }
  }

  render() {
    const { name, resource, component, render, to, ...rest } = this.props

    return <TableCell {...rest}>{this.renderValue(resource)}</TableCell>
  }
}

export default Column
