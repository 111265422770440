import { Map } from 'immutable'
import { compose } from 'redux'
import { withFormik } from 'formik'
import { withRouter } from 'react-router-dom'
import * as Yup from 'yup'

import withFlashMessage from 'hoc/withFlashMessage'
import withFormState from 'hoc/withFormState'

import WalletTransactionForm from './WalletTransactionForm'

const formikConfig = {
  mapPropsToValues: ({ match, type, resource = Map(), isProvider }) => ({
    type,
    amount_satangs: '',
    fee_satangs:
      type === 'Wallet::Withdrawal'
        ? resource.getIn(['wallet', 'withdrawal_fee', 'fractional']) / 100
        : 0,
    is_provider: isProvider,
    provider_id: match?.params?.id
  }),

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    type: Yup.string().required(),
    amount_satangs: Yup.number().required(),
    fee_satangs: Yup.number(),
    transaction_type: Yup.string().when('type', {
      is: (val) => val === 'Wallet::Deposit',
      then: Yup.string().required('This field is required')
    }),
    image: Yup.mixed().when('transaction_type', {
      is: (val) => val === 'bank_transfer',
      then: Yup.mixed().required('This field is required')
    }),
    captured_at: Yup.string().when('transaction_type', {
      is: (val) => val === 'bank_transfer',
      then: Yup.string().required('This field is required')
    })
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    values = {
      ...values,
      amount_satangs: parseInt((values.amount_satangs * 100).toFixed(0)),
      fee_satangs: parseInt((values.fee_satangs * 100).toFixed(0)),
      state: props.formState.processed ? 'processed' : 'holding',
      released_at: props.formState.processed ? undefined : values.released_at
    }

    props.onSubmit(values).catch((error) => {
      setSubmitting(false)

      props.setFlashMessage('error', error.response.body.message)
    })
  }
}

export default compose(
  withRouter,
  withFlashMessage,
  withFormState({ processed: true }),
  withFormik(formikConfig)
)(WalletTransactionForm)
