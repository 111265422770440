import React from 'react'
import PropTypes from 'prop-types'
import { withMobileDialog } from '@material-ui/core'

import { ConversationsListPanel, ConversationMessagesPanel } from 'modules/conversations'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import PageContent, { PageSection } from 'components/layouts/PageContent'
import { inquirySchema } from '@seekster/schemas'

export function InquiryConversationsPane ({ fullScreen: isMobile }) {
  return (
    <PageContent>
      <PageSection position='left'>
        <AuthenticatedRoute
          path='/inquiries/:id/conversations'
          component={ConversationsListPanel}
          componentProps={{ schema: inquirySchema }}
          exact={isMobile}
        />
      </PageSection>

      <PageSection position='center' style={{ borderLeft: '1px solid #ddd' }}>
        <AuthenticatedRoute
          path='/inquiries/:id/conversations/:id'
          component={ConversationMessagesPanel}
        />
      </PageSection>
    </PageContent>
  )
}

InquiryConversationsPane.propTypes = {
  fullScreen: PropTypes.bool
}

export default withMobileDialog()(InquiryConversationsPane)
