import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Grid, IconButton, withMobileDialog } from '@material-ui/core'
import LightboxImage from 'components/images/LightboxImage'
import { Phone, ArrowBackIosRounded } from '@material-ui/icons'
import { useResource } from 'hooks/resources'
import { providerSchema, jobSchema } from '@seekster/schemas'
import { useCollectionChildren } from 'hooks/collections'
import moment from 'moment'

import useData from 'hooks/useData'
import initGoogleMapApi from 'utils/initGoogleMapApi'

import ButtonLoader from 'components/buttons/ButtonLoader'
import PhoneNumberFormatter from 'components/formatters/PhoneNumberFormatter'
import Panel from 'components/containers/Panel'
import CircularLoader from 'components/CircularLoader'
import Rating from 'components/Rating'
import GoogleDynamicMap from 'components/maps/GoogleDynamicMap'
import DateFormatter from 'components/formatters/DateFormatter'
import Calendar from 'components/ProviderCalendar'

import styles from './AssignProviderReportPanelStyles'
import { callService } from 'utils/callService'

export function AssignProviderReportPanel({
  classes,
  inquiry,
  fetchProviderJobs,
  assignProvider,
  provider,
  // potentialProvider,
  match,
  withoutBackButton,
  fullScreen: isMobile,
  push,
  t
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [providerRestDate, setProviderRestDate] = useState([])
  const [providerAvailableDate, setProviderAvailableDate] = useState([])
  const [informationRestDay, setInformationRestDay] = useState([])
  const [informationActiveDay, setInformationActiveDay] = useState([])
  const [informationAvailableDay, setInformationAvailableDay] = useState([])
  const [selectedDay, setSelectedDay] = useState(null)
  const [potentialProvider, setPotentialProvider] = useState(null)

  const [providerResource] = useResource(providerSchema, match.params.provider_id, [
    match.params.provider_id
  ])

  useEffect(() => {
    fetchProvider()
  }, [match.params.provider_id])

  const fetchProvider = async () => {
    try {
      const response = await callService.get(`${process.env.REACT_APP_BASE_API}/providers/${match.params.provider_id}`);
      if (response.status === 200) {
        setPotentialProvider(response.data)
      }
    } catch (error) {
      console.error(error);
      return
    }
  }

  const [providerActiveDate] = useCollectionChildren(
    providerSchema,
    match.params.provider_id,
    jobSchema,
    {
      queryParam: {
        query: {
          'job_providers_state_in][': [
            'pending_start',
            'pending_completion',
            'heading_to_destination',
            'heading_to_origin'
          ],
          job_providers_provider_id_eq: match.params.provider_id
        },

        order: {
          start_time: 'desc'
        }
      }
    },
    [match.params.provider_id]
  )

  const providerData = providerResource.toJS()

  useEffect(() => {
    if (
      providerRestDate &&
      providerActiveDate &&
      providerAvailableDate &&
      selectedDay !== null
    ) {
      const resultRest = []
      const resultActive = []
      const resultAvailable = []

      providerRestDate.forEach((restDate) => {
        if (
          moment(restDate.start_time).get('date') === selectedDay.day &&
          moment(restDate.start_time).get('month') + 1 === selectedDay.month &&
          moment(restDate.start_time).get('year') === selectedDay.year
        ) {
          resultRest.push(restDate)
        }
      })

      providerActiveDate.forEach((active) => {
        if (
          moment(active.get('start_time')).get('date') === selectedDay.day &&
          moment(active.get('start_time')).get('month') + 1 === selectedDay.month &&
          moment(active.get('start_time')).get('year') === selectedDay.year
        ) {
          resultActive.push(active)
        }
      })

      providerAvailableDate.forEach((available) => {
        if (
          moment(available.start_time).get('date') === selectedDay.day &&
          moment(available.start_time).get('month') + 1 === selectedDay.month &&
          moment(available.start_time).get('year') === selectedDay.year
        ) {
          resultAvailable.push(available)
        }
      })

      setInformationRestDay(resultRest)
      setInformationActiveDay(resultActive)
      setInformationAvailableDay(resultAvailable)
    }
  }, [selectedDay, providerRestDate, providerActiveDate, providerAvailableDate])

  useEffect(() => {
    initGoogleMapApi()
  }, [])

  useEffect(() => {
    if (providerData.provider_rest_dates !== undefined) {
      setProviderRestDate(providerData.provider_rest_dates)
    }

    if (providerData.provider_available_dates !== undefined) {
      setProviderAvailableDate(providerData.provider_available_dates)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerResource])

  const { loading } = useData(
    () =>
      fetchProviderJobs(match.params.provider_id, { query: { state: 'pending_start' } }),
    [match.params.provider_id]
  )

  function handleAssignProvider() {
    setIsLoading(true)

    assignProvider(match.params.id, { provider_id: match.params.provider_id })
      .then(() => {
        push(`/inquiries/${match.params.id}/jobs`)
        setIsLoading(false)
      })
      .catch((error) => {
        alert(error.response.body.message)
        setIsLoading(false)
      })
  }

  function renderConflictJobsDate() {
    const inquiryAddress = {
      lat: inquiry.getIn(['location', 'latitude']),
      lng: inquiry.getIn(['location', 'longitude']),
      color: 'blue',
      text: `${t('selected_job')}: ${moment(
        inquiry.getIn(['first_job', 'start_time'])
      ).format('HH:mm')} - ${moment(inquiry.getIn(['first_job', 'end_time'])).format(
        'HH:mm'
      )}`
    }

    const inquiryJobs = inquiry.get('jobs') || Map()
    const providerJobs = provider.get('jobs') || Map()

    return (
      <Grid item xs={12} className={classes.confilctContainer}>
        {inquiryJobs.map((job, index) => {
          const sameDays = providerJobs.filter((providerJob) =>
            moment(providerJob.get('start_time')).isSame(job.get('start_time'), 'day')
          )
          if (!sameDays.isEmpty()) {
            return (
              <>
                <h3 className={classes.subTitle}>{t('date')} : </h3>
                <h4 className={classes.content}>
                  <DateFormatter
                    value={job.get('start_time')}
                    className={classes.dateContainer}
                  />
                </h4>

                {sameDays.map((sameDay) => {
                  if (
                    sameDay.getIn(['location', 'latitude']) === null ||
                    sameDay.getIn(['location', 'latitude']) === null
                  ) {
                    return (
                      <p>
                        -{' '}
                        {t('cannot_detected_position', {
                          time: `${moment(sameDay.get('start_time')).format(
                            'HH:mm'
                          )} - ${moment(sameDay.get('end_time')).format('HH:mm')}`
                        })}
                      </p>
                    )
                  } else {
                    return null
                  }
                })}

                <div className={classes.mapContainer}>
                  <GoogleDynamicMap
                    isContent
                    markers={[
                      { ...inquiryAddress, index: 1000 + index },
                      ...sameDays.toJS().map((sameDay) => {
                        if (
                          sameDay.location === null ||
                          sameDay.location.latitude === null ||
                          sameDay.location.longitude === null
                        ) {
                          return null
                        } else {
                          return {
                            lat: sameDay.location.latitude,
                            lng: sameDay.location.longitude,
                            color: 'red',
                            text: `${t('existing_job')}: ${moment(
                              sameDay.start_time
                            ).format('HH:mm')} - ${moment(sameDay.end_time).format(
                              'HH:mm'
                            )}`,
                            index
                          }
                        }
                      })
                    ]}
                  />
                </div>
              </>
            )
          } else {
            return (
              <p>
                {t('no_conflict', {
                  date: moment(job.get('start_time')).format('D MMM YYYY')
                })}
              </p>
            )
          }
        })}
      </Grid>
    )
  }

  const useStyles = makeStyles({
    containerWithPadding: {
      padding: '20px'
    },
    containerInfoText: {
      paddingBottom: '10px',
      display: 'flex',
      alignItems: 'center'
    },
    infoText: {
      padding: '16px'
    },
    containerCalendarProviderInfo: {
      backgroundColor: '#ffffff !important',
      borderRadius: '8px',
      padding: '20px 36px',
      boxShadow: '0 2px 10px 0 rgba(182,182,182,0.4)',
      fontSize: '16px',
      lineHeight: '22px',
      maxWidth: '700px',
      margin: '25px auto',
      width: '100%'
    },
    textRest: {
      color: '#F84B60',
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: '0',
      lineHeight: '22px',
      padding: '12px 0'
    },
    textActive: {
      color: '#2D95F4',
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: '0',
      lineHeight: '22px',
      padding: '12px 0'
    },
    textAvailable: {
      color: '#18CB88',
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: '0',
      lineHeight: '22px',
      padding: '12px 0'
    }
  })

  const styles = useStyles()

  const handleSelectedDay = (value) => {
    setSelectedDay(value)
  }

  return (
    <Grid container>
      <Grid item xs={12} className={styles.containerWithPadding}>
        <Panel title='Provider Calendar Job'>
          <Grid item xs={12} container justify='center' alignItems='center'>
            <Grid className='container-calendar-provider'>
              <Grid className='container-assign-provider-calendar'>
                <Calendar
                  handleSelectedDay={(value) => handleSelectedDay(value)}
                  disableDate={providerRestDate}
                  activeJobDate={providerActiveDate.toJS()}
                  availableDate={providerAvailableDate}
                />
              </Grid>
            </Grid>
          </Grid>
          {informationRestDay.length !== 0 ||
            informationActiveDay.length !== 0 ||
            informationAvailableDay.length !== 0 ? (
            <Grid className={styles.containerCalendarProviderInfo}>
              <div>
                {informationRestDay.map((rest) => (
                  <div className={styles.textRest} key={rest.id}>
                    หยุด {moment(rest.start_time).get('hour')}:
                    {moment(rest.start_time).format('mm')} -{' '}
                    {moment(rest.end_time).get('hour')}:
                    {moment(rest.end_time).format('mm')}
                  </div>
                ))}
                {informationActiveDay.map((active) => (
                  <div className={styles.textActive} key={active.id}>
                    มีงาน {moment(active.get('start_time')).get('hour')}:
                    {moment(active.get('start_time')).format('mm')} -{' '}
                    {moment(active.get('end_time')).get('hour')}:
                    {moment(active.get('end_time')).format('mm')}
                  </div>
                ))}
                {informationAvailableDay.map((available) => (
                  <div className={styles.textAvailable} key={available.id}>
                    พร้อมรับงาน {moment(available.start_time).get('hour')}:
                    {moment(available.start_time).format('mm')} -{' '}
                    {moment(available.end_time).get('hour')}:
                    {moment(available.end_time).format('mm')}
                  </div>
                ))}
              </div>
            </Grid>
          ) : (
            ''
          )}
        </Panel>
      </Grid>
      <Grid item xs={12} className={styles.containerWithPadding}>
        <Panel
          title={t('sub_title')}
          avatar={
            !withoutBackButton &&
            isMobile && (
              <IconButton
                component={Link}
                to={`/inquiries/${match.params.id}/assign_provider`}
                className={classes.backButton}
              >
                <ArrowBackIosRounded />
              </IconButton>
            )
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid item xs={12} container justify='center' alignItems='center'>
                <LightboxImage
                  source={{
                    src: potentialProvider?.avatar_url,
                    thumbnail: potentialProvider?.avatar_url
                  }}
                  isAvatar
                />
              </Grid>

              <Grid item xs={12}>
                <Grid className={classes.textContainer}>
                  <Link
                    className={classes.title}
                    to={`/providers/${potentialProvider?.id}`}
                  >
                    {potentialProvider?.display_name}{' '}
                    <Rating value={potentialProvider?.rating} />
                  </Link>
                </Grid>

                {/* <Grid className={classes.textContainer} >
            </Grid> */}

                <Grid className={classes.textContainer}>
                  <Phone className={classes.small} />{' '}
                  <PhoneNumberFormatter
                    value={potentialProvider?.phone_number}
                    className={classes.small}
                  />
                </Grid>
              </Grid>

              <div>
                <div className={classes.blueSquare}>
                  <p className={classes.text}>{t('selected_job')}</p>
                </div>
                <div className={classes.redSquare}>
                  <p className={classes.text}>{t('existing_job')}</p>
                </div>
              </div>

              {loading ? <CircularLoader /> : renderConflictJobsDate()}

              <Grid item xs={12} container justify='flex-end'>
                <ButtonLoader
                  className={classes.button}
                  variant='contained'
                  type='button'
                  color='primary'
                  onClick={handleAssignProvider}
                  isLoading={isLoading}
                >
                  {t('assign_provider')}
                </ButtonLoader>
              </Grid>
            </Grid>
          </Grid>
        </Panel>
      </Grid>
    </Grid>
  )
}

AssignProviderReportPanel.propTypes = {
  provider: ImmutablePropTypes.map,
  // potentialProvider: ImmutablePropTypes.map,
  inquiry: ImmutablePropTypes.map,
  classes: PropTypes.object,
  fetchProviderJobs: PropTypes.func,
  assignProvider: PropTypes.func,
  push: PropTypes.func,
  match: PropTypes.object,
  withoutBackButton: PropTypes.bool,
  fullScreen: PropTypes.bool,

  t: PropTypes.func
}

AssignProviderReportPanel.defaultProps = {
  provider: Map(),
  // potentialProvider: Map(),
  inquiry: Map()
}

export default compose(
  withStyles(styles),
  withMobileDialog(),
  withTranslation('assign_providers')
)(AssignProviderReportPanel)
