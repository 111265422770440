import { withFormik } from 'formik'
import { Map } from 'immutable'
import * as Yup from 'yup'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'

import AppForm from './AppForm'

const formikConfig = {
  mapPropsToValues: ({ app = Map() }) => ({
    name: app.get('name', ''),
    official: app.get('official') || false,
    access_type: app.get('access_type'),
    webhook_url: app.get('webhook_url', ''),
    fcm_server_key: app.get('fcm_server_key', ''),
    contact_name: app.get('contact_name', ''),
    contact_email: app.get('contact_email', ''),
    contact_phone_number: app.get('contact_phone_number', '')
  }),

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    official: Yup.boolean(),
    access_type: Yup.string(),
    webhook_url: Yup.string(),
    fcm_server_key: Yup.string(),
    contact_name: Yup.string(),
    contact_email: Yup.string().email(),
    contact_phone_number: Yup.string().matches(
      /(^0[23456789]\d{7,8}$)/,
      'Invalid phone number'
    )
  }),

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props
      .onSubmit(values)
      .then(({ body }) => {
        props.push(`/apps/${body.id}`)
      })
      .catch((error) => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(withFlashMessage, withFormik(formikConfig))(AppForm)
