import { connect } from 'react-redux'
import { getResource } from 'selectors/resources'

import { reviewSchema } from '@seekster/schemas'

import ReviewOverviewPane from './ReviewOverviewPane'

const mapStateToProps = (state, { match }) => ({
  review: getResource(state, match.params.id, reviewSchema)
})

export default connect(mapStateToProps)(ReviewOverviewPane)
