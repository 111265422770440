import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { createChild, fetch, fetchResourceChild } from 'actions/resources'
import {
  invoiceSchema,
  paymentSchema,
  transferPaymentSchema,
  walletPaymentSchema,
  cashPaymentSchema,
  creditCardPaymentSchema,
  integrationSchema,
  integrationInstanceSchema
} from '@seekster/schemas'

import PaymentMethodMenu from './PaymentMethodMenu'

const mapDispatchToProps = {
  fetchPayments: (id, options) =>
    fetchChildren(invoiceSchema, id, paymentSchema, options),
  fetchInvoice: (id, options) => fetch(id, invoiceSchema, options),
  createTransferPayment: (id, data, options) =>
    createChild(data, invoiceSchema, id, transferPaymentSchema, options),
  createWalletPayment: (id, data, options) =>
    createChild(data, invoiceSchema, id, walletPaymentSchema, options),
  createCashPayment: (id, data, options) =>
    createChild(data, invoiceSchema, id, cashPaymentSchema, options),
  createCardPayment: (id, data, options) =>
    createChild(data, invoiceSchema, id, creditCardPaymentSchema, options),
  fetchIntegrationInstance: (id) =>
    fetchResourceChild(integrationSchema, id, integrationInstanceSchema)
}

export default connect(null, mapDispatchToProps)(PaymentMethodMenu)
