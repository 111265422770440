import { connect } from 'react-redux'

import { signInWithOAuth, updateCurrentDevice } from 'actions/authentication'
import { push } from 'react-router-redux'

import GoogleLoginButton from './GoogleLoginButton'

const mapDispatchToProps = {
  onClick: signInWithOAuth,
  push,
  updateCurrentDevice
}

export default connect(null, mapDispatchToProps)(GoogleLoginButton)
