import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import SubscriptionForm from 'forms/resources/SubscriptionForm'

export class SubscriptionNewPage extends React.Component {
  static propTypes = {
    createSubscription: PropTypes.func,
    push: PropTypes.func,
    t: PropTypes.func
  }

  breadcrumbs () {
    return [{
      text: this.props.t('title'),
      link: '/subscriptions'
    }, {
      text: this.props.t('new')
    }]
  }

  render () {
    return (
      <PageLayout
        title={this.props.t('new')}
        breadcrumbs={this.breadcrumbs()}
      >
        <SubscriptionForm
          push={this.props.push}
          onSubmit={this.props.createSubscription}
        />
      </PageLayout>
    )
  }
}

export default withTranslation('subscriptions')(SubscriptionNewPage)
