import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { fetchResourceChild } from 'actions/resources'
import { jobSchema, conversationSchema } from '@seekster/schemas'

import JobConversationPane from './JobConversationPane'

const mapStateToProps = (state, { match }) => ({
  job: getResource(state, match.params.id, jobSchema)
})

const mapDispatchToProps = {
  fetchJobConversation: id => fetchResourceChild(jobSchema, id, conversationSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(JobConversationPane)
