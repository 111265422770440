import React from 'react'
import PropTypes from 'prop-types'
import { FilterAssociationTags } from '../../FilterAssociationTags'

export class FilterServiceTags extends React.Component {
  static propTypes = {
    serviceName: PropTypes.string,
    fetchService: PropTypes.func,
    query: PropTypes.object,
    onQueryPush: PropTypes.func
  }

  static defaultProps = {
    serviceName: '',

    fetchService: () => {}
  }

  componentDidMount () {
    const { query, fetchService } = this.props

    query.service_id && fetchService(query.service_id)
  }

  renderTag () {
    if (this.props.query.service_id) {
      return (
        <FilterAssociationTags
          content={`Service: ${this.props.serviceName}`}
          name='service_id'
          query={this.props.query}
          onQueryPush={this.props.onQueryPush}
        />
      )
    }
    else {
      return null
    }
  }

  render () {
    return this.renderTag()
  }
}

export default FilterServiceTags
