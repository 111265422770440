/* eslint-disable camelcase */
/* eslint-disable space-before-function-paren */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Field, Form, FieldArray, ErrorMessage } from 'formik'
import { Calendar } from 'react-multi-date-picker'
import moment from 'moment'

import { FastField } from 'components/fields/Field'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'
import ModalInvoice from './modal'

import { ChevronRight, ChevronLeft } from '@material-ui/icons'

import callService from 'pages/settings/panes/HelpAndSupport/callService'

import './refund.scss'

import createStore from 'createStore'

export const store = createStore()

const index = (props) => {
  const { action, name, setRefundOpenModal, closeBtn } = props
  const { t } = useTranslation(['navbar', 'creditnote'])
  const displayType = ['booking_cancel', 'other_reason']

  const accessToken = store.getState().getIn(['authentication', 'token'])
  const header = { authorization: `Bearer ${accessToken}` }

  const editRef = useRef(null)

  const [edit, setEdit] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const [selectInvoice, setSelectInvoice] = useState(false)
  const [valueEdit, setValuesEdit] = useState(`${action.values.summary.totalAmountTax}`)
  const [listSuggest, setListSuggest] = useState([])

  const renderNumber = (values) => {
    return values.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  const changeValueCalenda = (date) => {
    action.setFieldValue(
      'refund_detail.issue_date_select',
      moment(date.toDate()).format()
    )
    setShowCalendar(false)
  }

  const callListSuggest = async (id) => {
    const response = await callService(
      'GET',
      `${process.env.REACT_APP_BASE_API}/invoices/${id}`,
      null,
      header
    )
    if (response.status === 200 || response.status === 201) {
      setListSuggest(response.data)
      action.setFieldValue('refund_detail.totalTax', response.data.subtotal.decimal)
      action.setFieldValue('refund_detail.detail', [
        {
          job_id: 0,
          job_number: '',
          job_detail: '',
          unit: '',
          unit_price: '',
          price: '',
          vat: 7,
          withholding_tax: '',
          openTagSuggest: false
        }
      ])
    }
  }

  const callServiceCalDetail = async () => {
    const data = action.values.refund_detail.detail.map((items, k) => {
      return {
        job_id: items.job_id || k + 1,
        job_number: items.job_number,
        job_detail: items.job_detail,
        unit: parseInt(items.unit) || 0,
        unit_price: parseFloat(items.unit_price) || 0,
        vat: parseInt(items.vat) || 7,
        withholding_tax: parseInt(items.withholding_tax) || 0
      }
    })

    const dataSet = {
      tax_invoices_total_amount: action.values.refund_detail.totalTax || 0,
      refund_jobs: data
    }

    const response = await callService(
      'POST',
      `${process.env.REACT_APP_CANCELLATION_BASE_API}/api/credit-notes/calculate`,
      dataSet,
      header
    )
    if ((response.status === 200) | (response.status === 201)) {
      setValuesEdit(response.data.data.tax_invoices_total_amount)
      action.setFieldValue(
        'summary.totalAmountTax',
        response.data.data.tax_invoices_total_amount
      )
      action.setFieldValue('summary.newAmount', response.data.data.new_amount)
      action.setFieldValue('summary.variance', response.data.data.variance)
      action.setFieldValue('summary.vatAmount', response.data.data.vat_total_amount)
      action.setFieldValue('summary.netAmount', response.data.data.net)
      action.setFieldValue('summary.whtTax', response.data.data.withholding_tax)
      action.setFieldValue('summary.totalAmount', response.data.data.net_amount)
    }
  }

  const editValues = (values) => {
    const regex = /^[0-9]*$/
    const checkValues = regex.test(values)
    if (checkValues) {
      action.setFieldValue('summary.totalAmountTax', values)
      action.setFieldValue('refund_detail.totalTax', parseInt(values))
      setValuesEdit(values)
    }
  }

  const checkSaveEdit = () => {
    if (valueEdit) {
      setEdit((pre) => !pre)
      if (edit) {
        callServiceCalDetail()
      }
    }
  }

  const openTagEvent = (id) => {
    const updateSuggest = action.values.refund_detail.detail.map((item, index) => {
      if (index === id) {
        return { ...item, openTagSuggest: true }
      }
      return item
    })

    action.setFieldValue('refund_detail.detail', updateSuggest)
  }

  const closeTagEvent = () => {
    const updateSuggest = action.values.refund_detail.detail.map((item) => {
      if (item.openTagSuggest) {
        return { ...item, openTagSuggest: false }
      }
      return item
    })
    action.setFieldValue('refund_detail.detail', updateSuggest)
  }

  const clickSuggest = (event, name1, name2, value) => {
    event.stopPropagation()
    const jobID = value.job_id
    const jobNumber = value.job.number
    action.setFieldValue(name1, jobNumber)
    action.setFieldValue(name2, jobID)
  }

  const changeValueJob = (event, name) => {
    const { value } = event.target
    action.setFieldValue(name, value)
  }

  const calInvoice = (event) => {
    const checkValue = action.values.refund_detail.detail.filter(
      (items) =>
        items?.unit_price?.length <= 0 ||
        items?.price?.length <= 0 ||
        items?.unit?.length <= 0
    )
    if (checkValue.length > 0 || action.values.refund_detail.detail.length <= 0) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      callServiceCalDetail()
    }
  }

  const checkBtnBank = () => {
    const checkValue = action.values.refund_detail.detail.filter(
      (items) =>
        items.unit_price?.length <= 0 ||
        items.price?.length <= 0 ||
        items.unit?.length <= 0
    )
    if (checkValue.length > 0 || action.values.refund_detail.detail.length <= 0) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (edit) {
      editRef.current.focus()
    }
  }, [edit])

  return (
    <>
      {selectInvoice && (
        <ModalInvoice
          setSelectInvoice={setSelectInvoice}
          setRefundOpenModal={setRefundOpenModal}
          callListSuggest={callListSuggest}
          action={action}
        />
      )}
      <Grid item xs={12} sm={8}>
        <div className='mt-2'>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <div className='d-flex position-relative'>
                <div className='section-topic'>
                  <span className='text-topic-register-address'>{t('invoice_no')}</span>
                  <span className='dot-legal'>*</span>
                </div>
                <div className='w-100'>
                  <input
                    value={action.values.refund_detail.invoiceNo}
                    readOnly
                    type='text'
                    className='legal-input'
                    placeholder={t('search_invoice_no')}
                    disabled={closeBtn}
                  />
                </div>
                <div className='calendar-input-refund-detail-icon'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18.979'
                    height='19.566'
                    viewBox='0 0 18.979 19.566'
                    className={`${!closeBtn && 'pointer'}`}
                    onMouseDown={() => !closeBtn && setSelectInvoice(true)}
                  >
                    <g
                      id='Icon_feather-search'
                      data-name='Icon feather-search'
                      transform='translate(1 1)'
                    >
                      <path
                        id='Path_7'
                        data-name='Path 7'
                        d='M19.653,12.077A7.577,7.577,0,1,1,12.077,4.5,7.577,7.577,0,0,1,19.653,12.077Z'
                        transform='translate(-4.5 -4.5)'
                        fill='none'
                        stroke='#929598'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                      />
                      <path
                        id='Path_8'
                        data-name='Path 8'
                        d='M28.836,28.836l-3.861-3.861'
                        transform='translate(-12.272 -11.685)'
                        fill='none'
                        stroke='#929598'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div className='d-flex'>
                <div className='section-topic' />
                <div className='text-validate mt-1'>
                  <ErrorMessage
                    name='refund_detail.invoiceID'
                    component='div'
                    className='field-error'
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className='d-flex position-relative'>
                <div className='section-topic'>
                  <span className='text-topic-register-address'>
                    {t('invoice_issue_date')}
                  </span>
                  <span className='dot-legal'>*</span>
                </div>
                <div className='pl-2 w-100'>
                  <input
                    value={moment(
                      action.values.refund_detail.issue_date || new Date()
                    ).format('DD/MM/YYYY')}
                    readOnly
                    type='text'
                    className='legal-input bg-gray-input'
                    placeholder={moment(new Date()).format('DD/MM/YYYY')}
                  />
                </div>
                <div className='calendar-input-refund-detail-icon'>
                  <svg
                    id='carlendar'
                    xmlns='http://www.w3.org/2000/svg'
                    width='22.045'
                    height='22.045'
                    viewBox='0 0 22.045 22.045'
                  >
                    <path
                      id='calendar'
                      d='M23.154,5.833A3.149,3.149,0,0,0,20,2.683h-.787V1.918a.8.8,0,0,0-.749-.808.787.787,0,0,0-.825.786v.787H6.62V1.918A.8.8,0,0,0,5.87,1.11a.787.787,0,0,0-.825.786v.787H4.258A3.149,3.149,0,0,0,1.109,5.833v.59a.2.2,0,0,0,.2.2H22.957a.2.2,0,0,0,.2-.2ZM1.109,20a3.149,3.149,0,0,0,3.149,3.149H20A3.149,3.149,0,0,0,23.154,20V8.342a.148.148,0,0,0-.148-.148H1.256a.148.148,0,0,0-.148.148ZM18.036,9.769a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,18.036,9.769Zm0,3.937a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,18.036,13.706ZM14.1,9.769a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,14.1,9.769Zm0,3.937a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,14.1,13.706Zm0,3.937a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,14.1,17.643Zm-3.937-3.937a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,10.163,13.706Zm0,3.937a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,10.163,17.643ZM6.226,13.706a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,6.226,13.706Zm0,3.937a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,6.226,17.643Z'
                      transform='translate(-1.109 -1.109)'
                      fill='gray'
                    />
                  </svg>
                </div>
              </div>
              <div className='d-flex'>
                <div className='section-topic' />
                <div className='text-validate mt-1 pl-2'>
                  <ErrorMessage
                    name='refund_detail.issue_date'
                    component='div'
                    className='field-error'
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className='mt-2'>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <div className='d-flex'>
                <div className='section-topic'>
                  <span className='text-topic-register-address'>
                    {t('select_invoice_issue_date')}
                  </span>
                  <span className='dot-legal'>*</span>
                </div>
                <div className='w-100 position-relative'>
                  <input
                    value={
                      action.values.refund_detail.issue_date_select
                        ? moment(action.values.refund_detail.issue_date_select).format(
                            'DD/MM/YYYY'
                          )
                        : ''
                    }
                    type='text'
                    readOnly
                    className={`legal-input ${!closeBtn && 'pointer'}`}
                    placeholder={moment(new Date()).format('DD/MM/YYYY')}
                    onBlur={() => !closeBtn && setShowCalendar(false)}
                    onMouseDown={() => !closeBtn && setShowCalendar((pre) => !pre)}
                    disabled={closeBtn}
                  />
                  <div
                    className={`calendar-input-refund-detail-icon  d-none d-md-flex ${
                      !closeBtn && 'pointer'
                    }`}
                    tabIndex={0}
                    onBlur={() => !closeBtn && setShowCalendar(false)}
                    onMouseDown={() => !closeBtn && setShowCalendar((pre) => !pre)}
                  >
                    <svg
                      id='carlendar'
                      xmlns='http://www.w3.org/2000/svg'
                      width='22.045'
                      height='22.045'
                      viewBox='0 0 22.045 22.045'
                    >
                      <path
                        id='calendar'
                        d='M23.154,5.833A3.149,3.149,0,0,0,20,2.683h-.787V1.918a.8.8,0,0,0-.749-.808.787.787,0,0,0-.825.786v.787H6.62V1.918A.8.8,0,0,0,5.87,1.11a.787.787,0,0,0-.825.786v.787H4.258A3.149,3.149,0,0,0,1.109,5.833v.59a.2.2,0,0,0,.2.2H22.957a.2.2,0,0,0,.2-.2ZM1.109,20a3.149,3.149,0,0,0,3.149,3.149H20A3.149,3.149,0,0,0,23.154,20V8.342a.148.148,0,0,0-.148-.148H1.256a.148.148,0,0,0-.148.148ZM18.036,9.769a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,18.036,9.769Zm0,3.937a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,18.036,13.706ZM14.1,9.769a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,14.1,9.769Zm0,3.937a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,14.1,13.706Zm0,3.937a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,14.1,17.643Zm-3.937-3.937a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,10.163,13.706Zm0,3.937a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,10.163,17.643ZM6.226,13.706a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,6.226,13.706Zm0,3.937a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,6.226,17.643Z'
                        transform='translate(-1.109 -1.109)'
                        fill={`${
                          action.values.refund_detail.issue_date_select && !closeBtn
                            ? '#2d95f4'
                            : 'gray'
                        }`}
                      />
                    </svg>
                  </div>

                  {showCalendar && (
                    <div className='position-calenda-refund-detail'>
                      <div className='section-carenda-custom'>
                        <Calendar
                          value={moment(
                            action.values.refund_detail.issue_date_select || new Date()
                          ).format('YYYY/MM/DD')}
                          onChange={(date) => {
                            changeValueCalenda(date)
                          }}
                          onClose={() => setShowCalendar(false)}
                          showOtherDays
                          disableMonthPicker
                          disableYearPicker
                          mapDays={({ date, today, isSameDate }) => {
                            if (isSameDate(date, today)) {
                              return {
                                children: (
                                  <div className='custom-today'>
                                    <div
                                      style={{
                                        width: '7px',
                                        height: '7px',
                                        backgroundColor: 'red',
                                        borderRadius: '50%',
                                        position: 'absolute'
                                      }}
                                    />
                                    <div className='text-today'>{today.day}</div>
                                  </div>
                                )
                              }
                            }
                            return {
                              onMouseDown: (e) => {
                                e.stopPropagation()
                                e.preventDefault()
                              }
                            }
                          }}
                          renderButton={(direction, handleClick) => (
                            <div
                              onMouseDown={(e) => {
                                e.preventDefault()
                                handleClick()
                              }}
                              className='pointer arrow-next-carlendar-refund'
                            >
                              {direction === 'right' ? <ChevronRight /> : <ChevronLeft />}
                            </div>
                          )}
                        >
                          <div className='today-and-dot'>
                            <div className='dot' />
                            <div className='today-text'>Today</div>
                          </div>
                        </Calendar>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex'>
                <div className='section-topic' />
                <div className='text-validate mt-1'>
                  <ErrorMessage
                    name='refund_detail.issue_date_select'
                    component='div'
                    className='field-error'
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className='mt-2'>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className='d-flex'>
                <div className='section-topic-2'>
                  <span className='text-topic-register-address'>
                    {t('credit_note_info')}
                  </span>
                  <span className='dot-legal'>*</span>
                </div>
                <div className='w-100'>
                  {closeBtn ? (
                    <Field
                      type='text'
                      name='refund_detail.credit_note_info'
                      autoComplete='off'
                      className='legal-input'
                      value={
                        action.values.refund_detail.credit_note_info === 'other_reason'
                          ? 'Other reason'
                          : 'Booking Cancellation'
                      }
                      disabled={closeBtn}
                    />
                  ) : (
                    <FastField
                      name='refund_detail.credit_note_info'
                      component={SelectInput}
                      options={arrayToOptions(displayType, {
                        i18nNamespace: 'creditnote',
                        i18nKey: 'creditnote_choice'
                      })}
                      onChange={(value) => {
                        action.setFieldValue('refund_detail.credit_note_info', value)
                      }}
                      placeholder={`${t('reason_credit_note')}`}
                      noLabel
                      className='mb-1'
                    />
                  )}
                </div>
              </div>
            </Grid>
            {action.values.refund_detail.credit_note_info === 'other_reason' && (
              <Grid item xs={12} sm={12}>
                <div className='d-flex position-relative'>
                  <div className='section-topic'>
                    <span className='text-topic-register-address'>
                      {t('description_of')} <br />
                      {t('reason')}
                    </span>
                    <span className='dot-legal'>*</span>
                  </div>
                  <div className='w-100'>
                    <Field
                      type='text'
                      name='refund_detail.other_reason'
                      autoComplete='off'
                      className='legal-input'
                      placeholder={`${t('please_fill.reason')}`}
                      disabled={closeBtn}
                    />
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='section-topic' />
                  <div className='text-validate mt-1'>
                    <ErrorMessage
                      name='refund_detail.other_reason'
                      component='div'
                      className='field-error'
                    />
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
      <div className='mt-3 section-main-refund-credit-note'>
        <Form>
          <table className='w-100 section-table-refund-credit-note' cellSpacing='0'>
            <thead>
              <tr>
                <th>{t('table.no')}</th>
                <th className='text-left'>{t('table.job_number')}</th>
                <th className='custom-detail-credit-note-thead'>
                  <span className='text'>{t('table.detail')}</span>
                  <span className='dot-refund'>*</span>
                </th>
                <th>{t('table.unit')}</th>
                <th>{t('table.per_unit')}</th>
                <th>{t('table.price')}</th>
                <th>{t('table.vate')}</th>
                <th>{t('table.wht')}</th>
                <th />
              </tr>
            </thead>
            <FieldArray
              name={name}
              render={(arrayHelpers) => (
                <>
                  <tbody>
                    {action.values.refund_detail.detail.map((friend, index) => (
                      <tr key={index} className='text-center'>
                        <td>{index + 1}</td>
                        <td>
                          <div className='section-job-number'>
                            <Field
                              className='text-left refund-input-1'
                              name={`refund_detail.detail.${index}].job_number`}
                              placeholder='Input job number'
                              autoComplete='off'
                              onChange={(e) =>
                                changeValueJob(
                                  e,
                                  `refund_detail.detail.${index}].job_number`
                                )
                              }
                              onFocus={() => openTagEvent(index)}
                              onBlur={() => closeTagEvent(index)}
                              disabled={closeBtn}
                            />
                            {friend.openTagSuggest && (
                              <>
                                {listSuggest?.line_items && (
                                  <div className='suggest-section-under-job'>
                                    {listSuggest?.line_items.map((data, k) => {
                                      return (
                                        <div
                                          key={k}
                                          className='inside-suggest-job pointer'
                                          onMouseDown={(e) =>
                                            clickSuggest(
                                              e,
                                              `refund_detail.detail.${index}].job_number`,
                                              `refund_detail.detail.${index}].job_id`,
                                              data.source
                                            )
                                          }
                                        >
                                          {data.source.job.number}
                                        </div>
                                      )
                                    })}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </td>
                        <td className='data-details-custom'>
                          <Field
                            type='text'
                            className='text-left refund-input-1 w-100'
                            name={`refund_detail.detail.${index}].job_detail`}
                            placeholder='Input job detail'
                            autoComplete='off'
                            disabled={closeBtn}
                          />
                          <span className='text-validate mt-1'>
                            <ErrorMessage
                              name={`refund_detail.detail.${index}].job_detail`}
                              component='div'
                              className='field-error'
                            />
                          </span>
                        </td>
                        <td>
                          <Field
                            type='text'
                            autoComplete='off'
                            className='refund-input-1'
                            name={`refund_detail.detail.${index}].unit`}
                            placeholder='0'
                            disabled={closeBtn}
                          />
                        </td>
                        <td>
                          <Field
                            type='text'
                            autoComplete='off'
                            className='refund-input-1'
                            name={`refund_detail.detail.${index}].unit_price`}
                            placeholder='00.00'
                            disabled={closeBtn}
                          />
                        </td>
                        <td>
                          <Field
                            type='text'
                            autoComplete='off'
                            className='refund-input-1'
                            name={`refund_detail.detail.${index}].price`}
                            placeholder='00.00'
                            disabled={closeBtn}
                          />
                        </td>
                        <td>7%</td>

                        <td>
                          <Field
                            as='select'
                            name={`refund_detail.detail.${index}].withholding_tax`}
                            className='select-specific-input-refund'
                            disabled={closeBtn}
                          >
                            <option value=''>{t('table.not_specific')}</option>
                            <option value='3'>3 %</option>
                          </Field>
                        </td>
                        <td>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='14.651'
                            height='18.032'
                            viewBox='0 0 14.651 18.032'
                            className={`${!closeBtn && 'pointer'}`}
                            onClick={() => !closeBtn && arrayHelpers.remove(index)}
                          >
                            <path
                              id='Icon_metro-bin'
                              data-name='Icon metro-bin'
                              d='M5.626,7.563v11.27A1.13,1.13,0,0,0,6.753,19.96H16.9a1.13,1.13,0,0,0,1.127-1.127V7.563H5.626ZM9.007,17.706H7.88V9.817H9.007Zm2.254,0H10.134V9.817h1.127Zm2.254,0H12.388V9.817h1.127Zm2.254,0H14.642V9.817h1.127ZM18.3,4.182H14.642V2.773a.848.848,0,0,0-.845-.845H9.852a.848.848,0,0,0-.845.845V4.182H5.344a.848.848,0,0,0-.845.845V6.436H19.15V5.027a.848.848,0,0,0-.845-.845Zm-4.79,0H10.134V3.069h3.381V4.182Z'
                              transform='translate(-4.499 -1.928)'
                              fill='#e83528'
                            />
                          </svg>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <div className='button-refund-custom-add-more mt-3 d-flex align-item-center'>
                    <div
                      className={`icon-plus ${!closeBtn && 'pointer'}`}
                      onMouseDown={(e) => {
                        !closeBtn &&
                          arrayHelpers.push({
                            job_id: 0,
                            job_number: '',
                            detail: '',
                            unit: '',
                            per_unit: '',
                            price: '',
                            vat: 7,
                            wht: '',
                            openTagSuggest: false
                          })
                      }}
                    >
                      +
                    </div>
                    <div
                      className={`text-add-custom ${!closeBtn && 'pointer'}`}
                      onMouseDown={(e) => {
                        !closeBtn &&
                          arrayHelpers.push({
                            job_id: 0,
                            job_number: '',
                            detail: '',
                            unit: '',
                            per_unit: '',
                            price: '',
                            vat: 7,
                            wht: '',
                            openTagSuggest: false
                          })
                      }}
                    >
                      {t('btn_addmore')}
                    </div>
                  </div>
                </>
              )}
            />
          </table>
        </Form>
      </div>

      <hr className='mt-3' />
      <div className='section-btn-and-text-sum'>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div
              className={`${!closeBtn && 'pointer'} custom-btn ${
                checkBtnBank() || closeBtn
                  ? 'btn-close-cal-refund'
                  : 'btn-cal-refund-credit-note'
              }`}
              onMouseDown={(e) => !closeBtn && calInvoice(e)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='13.08'
                height='16.068'
                viewBox='0 0 13.08 16.068'
              >
                <path
                  id='Icon_awesome-calculator'
                  data-name='Icon awesome-calculator'
                  d='M11.679,0H1.4A1.494,1.494,0,0,0,0,1.506V14.561a1.494,1.494,0,0,0,1.4,1.506H11.679a1.494,1.494,0,0,0,1.4-1.506V1.506A1.494,1.494,0,0,0,11.679,0ZM3.737,13.658a.418.418,0,0,1-.374.4H2.242a.418.418,0,0,1-.374-.4V12.452a.418.418,0,0,1,.374-.4H3.363a.418.418,0,0,1,.374.4Zm0-4.017a.418.418,0,0,1-.374.4H2.242a.418.418,0,0,1-.374-.4V8.436a.418.418,0,0,1,.374-.4H3.363a.418.418,0,0,1,.374.4Zm3.737,4.017a.418.418,0,0,1-.374.4H5.979a.418.418,0,0,1-.374-.4V12.452a.418.418,0,0,1,.374-.4H7.1a.418.418,0,0,1,.374.4Zm0-4.017a.418.418,0,0,1-.374.4H5.979a.418.418,0,0,1-.374-.4V8.436a.418.418,0,0,1,.374-.4H7.1a.418.418,0,0,1,.374.4Zm3.737,4.017a.418.418,0,0,1-.374.4H9.717a.418.418,0,0,1-.374-.4V8.436a.418.418,0,0,1,.374-.4h1.121a.418.418,0,0,1,.374.4Zm0-8.034a.418.418,0,0,1-.374.4h-8.6a.418.418,0,0,1-.374-.4V2.41a.418.418,0,0,1,.374-.4h8.6a.418.418,0,0,1,.374.4Z'
                  fill={checkBtnBank() || closeBtn ? 'btn-close-call-refund' : '#fff'}
                />
              </svg>
              <span className='text-calculate-refund-credit-note'>
                {t('summary.calculate')}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className='text-summary-credit-note'>{t('summary.topic')}</div>
          </Grid>
        </Grid>
      </div>
      <div className='mt-3'>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={6}>
            <div className='section-custom-text-all'>
              <div className='text-right mb-1'>
                <div className='section-summary-credit-note-custom-1'>
                  {!closeBtn && (
                    <span
                      className='text-status-summary pointer'
                      onClick={() => checkSaveEdit()}
                    >
                      {edit ? `${t('btn.save')}` : `${t('btn.edit')}`}
                    </span>
                  )}

                  <span>{t('summary.total')}</span>
                </div>
                {edit ? (
                  <div className='section-summary-credit-note-custom-2'>
                    <div className='custom-main-input-and-text-bath-refund'>
                      <div className='section-custom-bath-text-input'>
                        <span className='bath-text'>฿</span>
                      </div>
                      <input
                        ref={editRef}
                        type='text'
                        className='text-right input-edit-refund'
                        onChange={(e) => editValues(e.target.value)}
                        value={valueEdit}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='section-summary-credit-note-custom-2'>
                    <span>{renderNumber(action.values.summary.totalAmountTax)}</span>
                    <span className='bath-text'>฿</span>
                  </div>
                )}
              </div>
              <div className='text-right mb-1'>
                <div className='section-summary-credit-note-custom-1'>
                  <span>{t('summary.new_amount')}</span>
                </div>
                <div className='section-summary-credit-note-custom-2'>
                  <span>{renderNumber(action.values.summary.newAmount)}</span>
                  <span className='bath-text'>฿</span>
                </div>
              </div>
              <div className='text-right mb-1'>
                <div className='section-summary-credit-note-custom-1'>
                  <span>{t('summary.variance')}</span>
                </div>
                <div className='section-summary-credit-note-custom-2'>
                  <span>{renderNumber(action.values.summary.variance)}</span>
                  <span className='bath-text'>฿</span>
                </div>
              </div>
              <div className='text-right mb-1'>
                <div className='section-summary-credit-note-custom-1'>
                  <span>{t('summary.vat')}</span>
                </div>
                <div className='section-summary-credit-note-custom-2'>
                  <span>{renderNumber(action.values.summary.vatAmount)}</span>
                  <span className='bath-text'>฿</span>
                </div>
              </div>
              <div className='text-right mb-1'>
                <div className='section-summary-credit-note-custom-1'>
                  <span>{t('summary.next_amount')}</span>
                </div>
                <div className='section-summary-credit-note-custom-2'>
                  <span>{renderNumber(action.values.summary.netAmount)}</span>
                  <span className='bath-text'>฿</span>
                </div>
              </div>
              <div className='text-right mb-1'>
                <div className='section-summary-credit-note-custom-1'>
                  <span>{t('summary.with_tax')}</span>
                </div>
                <div className='section-summary-credit-note-custom-2'>
                  <span>{renderNumber(action.values.summary.whtTax)}</span>
                  <span className='bath-text'>฿</span>
                </div>
              </div>
              <div className='text-right mb-1'>
                <div className='section-summary-credit-note-custom-1'>
                  <span>{t('summary.total_amount')}</span>
                </div>
                <div className='section-summary-credit-note-custom-2'>
                  <span>{renderNumber(action.values.summary.totalAmount)}</span>
                  <span className='bath-text'>฿</span>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default index
