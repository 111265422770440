import { Map } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { compose } from 'redux'

import keyIn from 'utils/keyIn'
import withFlashMessage from 'hoc/withFlashMessage'

import TenantForm from './TenantForm'

const formikConfig = {
  mapPropsToValues: ({ tenant = Map(), generalConfig = Map() }) => {
    const primaryColorAttributes =
      tenant.getIn(['general_configuration', 'primary_color']) || Map()
    const secondaryColorAttributes =
      tenant.getIn(['general_configuration', 'secondary_color']) || Map()
    const navigationLinkAttributes =
      generalConfig.get('navigation_links') &&
      generalConfig
        .get('navigation_links')
        .map((navigationLinkAttribute) =>
          navigationLinkAttribute.filter(
            keyIn('id', 'link_en', 'link_th', 'text_en', 'text_th', 'recently_added')
          )
        )

    const colorAttributeKeys = ['id', 'main', 'main_gradient', 'light', 'dark', 'text']

    const generalConfigAttributes = generalConfig
      .filter(
        keyIn(
          'id',
          'terms_of_use_url_en',
          'terms_of_use_url_th',
          'terms_of_use_url_en',
          'terms_of_use_th',
          'terms_of_use_en',
          'privacy_policy_url_en',
          'privacy_policy_url_th',
          'privacy_policy_en',
          'privacy_policy_th',
          'chat_enabled',
          'search_provider_enabled',
          'search_customer_enabled',
          'edit_address_detail_enabled',
          'edit_request_question_detail_enabled',
          'location_provider_enabled',
          'sla_funciton_enabled',
          'rating_link_enabled'
        )
      )
      .merge({
        primary_color_attributes: primaryColorAttributes.filter(
          keyIn(...colorAttributeKeys)
        ),
        secondary_color_attributes: secondaryColorAttributes.filter(
          keyIn(...colorAttributeKeys)
        ),
        navigation_links_attributes: navigationLinkAttributes
      })

    return {
      name: tenant.get('name') || '',
      dark_logo_url: tenant.get('dark_logo_url') || undefined,
      domains: tenant.get('domains') || [],
      general_configuration_attributes: generalConfigAttributes.toJS()
    }
  },

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    name: Yup.string().required()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit(values)
      .then(() => {
        setSubmitting(false)
        props.afterSubmit()
      })
      .catch((error) => {
        setSubmitting(false)

        props.setFlashMessage(
          'error',
          error.response.body ? error.response.body.message : 'Internal Server Error'
        )
      })
  }
}

export default compose(withFlashMessage, withFormik(formikConfig))(TenantForm)
