import { connect } from 'react-redux'

import { fetch } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { serviceSchema } from '@seekster/schemas'

import BookingQuestionsFormSection from './BookingQuestionsFormSection'

const mapStateToProps = state => ({
  getQuestions: id => {
    if (getResource(state, id, serviceSchema)) {
      return getResource(state, id, serviceSchema).get('request_questions')
    }
  }
})

const mapDispatchToProps = {
  fetchService: id => fetch(id, serviceSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingQuestionsFormSection)
