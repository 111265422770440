import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Grid, Typography } from '@material-ui/core'
import { compose } from 'redux'
import { withQueryParser } from 'utils/withQueryParser'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import Panel from 'components/containers/Panel'
import CircularLoader from 'components/CircularLoader'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import RequestsTable from 'modules/requests/RequestsTable'
import Pagination from 'components/Pagination'

export function ServiceRequestsPane ({ service, fetchServiceRequests, match, query, t }) {
  const { loading: initialLoading, totalCount, totalPages } = useData(() => (
    fetchServiceRequests(match.params.id)
  ))

  const { loading } = useData(() => (
    fetchServiceRequests(match.params.id, { page: query.page })
  ), [query.page])

  return (
    <Panel
      title={t('requests')}
      subtitle={!service.get('inquiries', List()).isEmpty() && (
        <ResultsCountLabel value={totalCount} unit='request' />
      )}
    >
      {
        initialLoading || loading ? (
          <CircularLoader />
        ) : (
          <Grid container spacing={3} justify='center'>
            {
              service.get('inquiries', List()).isEmpty() ? (
                <Typography variant='overline'>{t('no_request')}</Typography>
              ) : (
                <>
                  <Grid item xs={12}>
                    <RequestsTable data={service.get('inquiries')} exclude={{ service: true }} />
                  </Grid>

                  <Grid item xs={12} container justify='center'>
                    <Pagination totalPages={totalPages} />
                  </Grid>
                </>
              )
            }
          </Grid>
        )
      }
    </Panel>
  )
}

ServiceRequestsPane.propTypes = {
  service: ImmutablePropTypes.map,
  fetchServiceRequests: PropTypes.func,
  match: PropTypes.object,
  query: PropTypes.object,

  t: PropTypes.func
}

ServiceRequestsPane.defaultProps = {
  service: Map()
}

export default compose(
  withQueryParser,
  withTranslation('services')
)(ServiceRequestsPane)
