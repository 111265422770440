import moment from 'moment'
import { get } from 'lodash'

export const invoicesQuery = ({
  search,
  state,
  subtotal_satangs,
  created_at
}) => {
  return {
    number_or_customer_first_name_or_customer_last_name_or_customer_phone_number_cont: search,

    state_eq: state,

    subtotal_satangs_gteq: get(subtotal_satangs, 'min')
      ? subtotal_satangs.min / 1.07
      : undefined,
    subtotal_satangs_lteq: get(subtotal_satangs, 'max')
      ? subtotal_satangs.max / 1.07
      : undefined,
    subtotal_currency_eq: get(subtotal_satangs, 'currency'),

    created_at_gteq: get(created_at, 'from')
      ? moment(created_at.from).startOf('day').toISOString(true)
      : undefined,
    created_at_lteq: get(created_at, 'to')
      ? moment(created_at.to).endOf('day').toISOString(true)
      : undefined
  }
}

export default invoicesQuery
