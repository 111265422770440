import { Map } from 'immutable'

import {
  FETCH_ANALYTICS_SUCCEEDED
} from 'constants/analytics'

const initialState = Map()

const analyticsReducer = (
  state = initialState,
  { type, name, response, error, options }
) => {
  switch (type) {
    case FETCH_ANALYTICS_SUCCEEDED:
      return state.setIn([name, 'data'], response.body)

    default:
      return state
  }
}

export default analyticsReducer
