import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, update } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { serviceSchema } from '@seekster/schemas'

import ServiceEditPage from './ServiceEditPage'

const mapStateToProps = (state, { match }) => ({
  service: getResource(state, match.params.id, serviceSchema)
})

const mapDispatchToProps = {
  fetchService: id => fetch(id, serviceSchema),
  updateService: (id, data) => update(id, data, serviceSchema),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceEditPage)
