import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import { CircularProgress, Button } from '@material-ui/core'

import styles from './ButtonLoaderStyles'

export function ButtonLoader({ classes, isLoading, children, style, primary, ...rest }) {
  return (
    <div className={classes.root} style={style}>
      <div className={classes.wrapper}>
        <Button color={primary ? 'primary' : undefined} disabled={isLoading} {...rest}>
          {children}

          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </div>
    </div>
  )
}

ButtonLoader.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  primary: PropTypes.bool
}

export default withStyles(styles)(ButtonLoader)
