import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { connect } from 'formik'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { LocalizedField, Field } from 'components/fields/Field'
import SwitchInput from 'components/inputs/SwitchInput'

export function TenantGeneralConfigNavigationLinksFormSection ({ formik, name, index, t }) {
  const fields = ['text', 'link']

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <label style={{ marginBottom: '0', fontSize: '16px' }}>{`Menu ${index + 1}`}</label>
      </Grid>

      {
        fields.map(fieldName => (
          <Grid item key={fieldName} xs={4}>
            <LocalizedField
              name={`${name}[${index}].${fieldName}`}
              placeholder={t(`placeholder.navigation_${fieldName}`)}
            />
          </Grid>
        ))
      }

      <Grid item xs={4}>
        <Field
          name={`${name}[${index}].recently_added`}
          component={SwitchInput}
        />
      </Grid>
    </Grid>
  )
}

TenantGeneralConfigNavigationLinksFormSection.propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string,
  index: PropTypes.number,
  t: PropTypes.func
}

export default compose(
  connect,
  withTranslation(['attributes', 'fields'])
)(TenantGeneralConfigNavigationLinksFormSection)
