import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { jobSchema, jobProviderSchema, jobReportSchema } from '@seekster/schemas'
import { createNodeChild } from 'actions/resources'

import ReportOverviewPane from './ReportOverviewPane'

const mapDispatchToProps = {
  fetchJobProviders: (id) => fetchChildren(jobSchema, id, jobProviderSchema),
  approveReport: (id, data) =>
  createNodeChild(data, jobReportSchema, id, jobSchema, {
    childrenType: 'approve'
  }),
  rejectReport: (id, data) =>
  createNodeChild(data, jobReportSchema, id, jobSchema, {
    childrenType: 'reject'
  })
}

export default connect(null, mapDispatchToProps)(ReportOverviewPane)
