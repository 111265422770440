import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import AgentForm from 'forms/resources/AgentForm'

export function AgentEditPage ({
  agent,
  fetchAgent,
  fetchCurrentAgent,
  updateAgent,
  currentAgent = Map(),
  push,
  match,
  t
}) {
  const { loading } = useData(() => match.params.id ? fetchAgent(match.params.id) : fetchCurrentAgent())
  const isSelf = !match.params.id || parseInt(match.params.id, 10) === currentAgent.get('id')

  function handleSubmit (data) {
    return (
      updateAgent(agent.get('id'), data)
        .then(({ body }) => push(match.params.id ? `/agents/${body.id}` : `/profile`))
    )
  }

  function renderBreadcrumbs () {
    return (
      match.params.id ? [
        { text: t('title'), link: '/agents' },
        { text: agent.get('display_name'), link: `/agents/${match.params.id}` },
        { text: t('edit') }
      ] : [
        { text: t('profile_title'), link: '/profile' },
        { text: t('edit') }
      ]
    )
  }

  return (
    <PageLayout
      title={t('edit')}
      breadcrumbs={renderBreadcrumbs()}
      isLoading={loading}
    >
      <AgentForm
        agent={agent}
        onSubmit={handleSubmit}
        isSelf={isSelf}
        isAdmin={currentAgent.get('admin')}
      />
    </PageLayout>
  )
}

AgentEditPage.propTypes = {
  agent: ImmutablePropTypes.map,
  fetchAgent: PropTypes.func,
  fetchCurrentAgent: PropTypes.func,
  updateAgent: PropTypes.func,
  currentAgent: ImmutablePropTypes.map,
  push: PropTypes.func,
  match: PropTypes.object,

  t: PropTypes.func
}

AgentEditPage.defaultProps = {
  agent: Map()
}

export default withTranslation(['agents', 'profile'])(AgentEditPage)
