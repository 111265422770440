import { withFormik } from 'formik'
import * as Yup from 'yup'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'
import FavoriteProviderForm from './FavoriteProviderForm'

const formikConfig = {
  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    provider_id: Yup.number().required('Please select provider'),
    service_id: Yup.number().required('Please select service')
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values)
      .catch(error => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(FavoriteProviderForm)
