import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'

import Comments from 'modules/comments/Comments'

export class CompanyCommentsPane extends React.Component {
  static propTypes = {
    company: ImmutablePropTypes.map,
    match: PropTypes.object,
    fetchCompanyComments: PropTypes.func,
    createCompanyComment: PropTypes.func
  }

  static defaultProps = {
    company: Map()
  }

  render () {
    const comments = this.props.company.get('comments') || List()

    return (
      <Comments
        id={this.props.match.params.id}
        loadComments={this.props.fetchCompanyComments}
        create={this.props.createCompanyComment}
        comments={comments}
      />
    )
  }
}

export default CompanyCommentsPane
