import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Chip } from '@material-ui/core'
import { pickBy, isPlainObject, values, map, startCase } from 'lodash'

import { withQueryParser } from 'utils/withQueryParser'

export class FilterDateRangeTags extends React.Component {
  static propTypes = {
    query: PropTypes.object,
    onQueryPush: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.handleRemoveTag = this.handleRemoveTag.bind(this)
  }

  handleRemoveTag(e, { queryKey }) {
    const { [queryKey]: removedValue, ...rest } = this.props.query

    this.props.onQueryPush(rest)
  }

  handleRemoveDateListTag(index) {
    const { date: { dateList = [] }, ...rest } = this.props.query
    dateList.splice(index, 1)

    this.props.onQueryPush({ ...rest, date: { dateList } })
  }

  render() {
    const query = pickBy(
      this.props.query,
      (queryValue) => (
        isPlainObject(queryValue) &&
        moment(values(queryValue)[0] || values(queryValue)[1], 'YYYY-MM-DD', true).isValid()
      )
    )

    // For filter dateList and Time a to b
    if (typeof this.props.query.date?.dateList === 'object') {
      const value = this.props.query.date
      const dateList = (value.dateList || []).map(d => moment(d).format('DD-MMM-YY'))
      const size = dateList.length

      return dateList.map((d, i) => {
        let dateContent = ''

        if (size === 1) {
          const from = value.from || '00:00'
          const to = value.to || '23:59'
          dateContent = `${d} time ${from}-${to}`
        } else {
          dateContent = d
        }

        return (
          <Chip
            key={d}
            variant='outlined'
            color='primary'
            size='small'
            style={{ margin: '0 6px 6px 0' }}
            label={`${dateContent}`}
            onDelete={(e) => this.handleRemoveDateListTag(i)}
          />
        )
      })
    }

    return map(query, (value, key) => {
      const startDate = value.from
        ? `From ${moment(value.from).format('DD-MMM-YY')}` : ''
      const endDate = value.to
        ? `To ${moment(value.to).format('DD-MMM-YY')}` : ''
      const dateContent = value.from !== value.to
        ? `${startDate} ${endDate}` : moment(value.from).format('DD-MMM-YY')

      return (
        <Chip
          key={key}
          variant='outlined'
          color='primary'
          size='small'
          style={{ margin: '0 6px 6px 0' }}
          label={`${startCase(key)}: ${dateContent}`}
          onDelete={(e) => this.handleRemoveTag(e, { queryKey: key })}
        />
      )
    })
  }
}

export default withQueryParser(FilterDateRangeTags)
