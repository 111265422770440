import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'

import Comments from 'modules/comments/Comments'

export function FeedbackCommentsPane ({ feedback, match, loadComments, create }) {
  return (
    <Comments id={match.params.id}
      loadComments={loadComments}
      create={create}
      comments={feedback.get('comments')}
    />
  )
}

FeedbackCommentsPane.propTypes = {
  feedback: ImmutablePropTypes.map,
  match: PropTypes.object,
  loadComments: PropTypes.func,
  create: PropTypes.func
}

FeedbackCommentsPane.defaultProps = {
  comments: List(),
  feedback: Map()
}

export default FeedbackCommentsPane
