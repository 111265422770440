import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { useTranslation } from 'react-i18next'
import { customerSchema } from '@seekster/schemas'

import { useResource } from 'hooks/resources'
import { Grid, Avatar } from '@material-ui/core'
import { Phone, Mail } from '@material-ui/icons'

import PageContent, { PageSection } from 'components/layouts/PageContent'
import Panel from 'components/containers/Panel'
import AttributeLabel from 'components/labels/AttributeLabel'
import DateFormatter from 'components/formatters/DateFormatter'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import PhoneNumberFormatter from 'components/formatters/PhoneNumberFormatter'
import MailFormatter from 'components/formatters/MailFormatter'
import CapitalizeFormatter from 'components/formatters/CapitalizeFormatter'
import Tag from 'components/Tag'
import UserProperties from 'components/UserProperties'

import './CustomerOverviewPane.scss'

export function CustomerOverviewPane({ match }) {
  const { t } = useTranslation(['customers', 'attributes'])
  const [customer] = useResource(customerSchema, match.params.id)

  return (
    <PageContent>
      <PageSection position='left' borderRight>
        <Panel title={t('details.customer')}>
          <Grid container className='customer-details-content'>
            <Grid item xs={12} container justify='center'>
              <Avatar src={customer.get('avatar_url') || ''} className='avatar' />
            </Grid>

            <Grid item xs={12}>
              <AttributeLabel title={`${t('id')} ${customer.get('id')}`}>
                <h2 style={{ wordBreak: 'break-word' }}>
                  {customer.get('display_name')}
                  {customer.get('alias') && ` (${customer.get('alias')})`}
                </h2>
              </AttributeLabel>
            </Grid>

            <Grid item xs={12}>
              <Phone fontSize='small' />
              <PhoneNumberFormatter value={customer.get('phone_number')} />
            </Grid>

            <Grid item xs={12}>
              <Mail fontSize='small' />
              <MailFormatter value={customer.get('email') || '   -'} />
            </Grid>

            <Grid item xs={6}>
              <AttributeLabel title={t('date_of_birth')}>
                {customer.get('date_of_birth') ? (
                  <DateFormatter value={customer.get('date_of_birth')} simple />
                ) : (
                  '-'
                )}
              </AttributeLabel>
            </Grid>

            <Grid item xs={6}>
              <AttributeLabel title={t('gender')}>
                {customer.get('gender') ? (
                  <Tag value={customer.get('gender')} formatter={CapitalizeFormatter} />
                ) : (
                  '-'
                )}
              </AttributeLabel>
            </Grid>

            <Grid item xs={12}>
              <AttributeLabel title={t('responsible_by_id')}>
                {customer.getIn(['responsible_by', 'display_name']) || '-'}
              </AttributeLabel>
            </Grid>

            <Grid item xs={12}>
              <AttributeLabel title={t('support_by_id')} divided>
                {customer.getIn(['support_by', 'display_name']) || '-'}
              </AttributeLabel>
            </Grid>

            <Grid item xs={12}>
              <AttributeLabel title={t('updated_at')}>
                <DateTimeFormatter value={customer.get('updated_at')} />
              </AttributeLabel>
            </Grid>

            <Grid item xs={12}>
              <AttributeLabel title={t('created_at')}>
                <DateTimeFormatter value={customer.get('created_at')} />
              </AttributeLabel>
            </Grid>
          </Grid>
        </Panel>

        {customer.get('tags', List()).map((tag) => (
          <Tag key={tag.get('id')} value={tag.get('name')} />
        ))}
      </PageSection>

      <PageSection position='center'>
        <Panel title={t('details.properties')}>
          <UserProperties userId={match.params.id} userSchema={customerSchema} />
        </Panel>
      </PageSection>
    </PageContent>
  )
}

CustomerOverviewPane.propTypes = {
  match: PropTypes.object
}

export default CustomerOverviewPane
