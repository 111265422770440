import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import SubscriptionsTable from 'components/tables/SubscriptionsTable'
import Panel from 'components/containers/Panel'

export class CompanySubscriptions extends React.Component {
  static propTypes = {
    company: ImmutablePropTypes.map,
    overview: PropTypes.bool,
    match: PropTypes.object,
    t: PropTypes.func,
    loadCompany: PropTypes.func,
    loaadCompanySubscriptions: PropTypes.func
  }

  static defaultProps = {
    company: Map()
  }

  componentDidMount () {
    if (this.props.company.isEmpty()) {
      this.props.loadCompany(this.props.match.params.id)
    }
    this.props.loaadCompanySubscriptions(this.props.match.params.id)
  }

  subscriptions () {
    const { company, t } = this.props

    const subscriptions = company.get('subscriptions') || List()

    if (subscriptions.isEmpty()) {
      return <p>{t('subscriptions_empty')}</p>
    }
    else {
      return <SubscriptionsTable subscriptions={subscriptions} />
    }
  }

  actions () {
    if (this.props.overview) {
      return null
    }
    else {
      return [
        <Link className='button button-primary panel-actions'
          to={`/companies/${this.props.match.params.id}/subscriptions/new`}>New Subscription</Link>
      ]
    }
  }

  render () {
    const { t } = this.props

    return (
      <Panel title={t('subscriptions')} actions={this.actions()}>
        {this.subscriptions()}
      </Panel>
    )
  }
}

export default withTranslation('companies')(CompanySubscriptions)
