import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Field from 'components/fields/Field'

import styles from './CashPaymentFormStyles'

export function CashPaymentForm ({
  invoice,
  onCancel,
  classes
}) {
  const { t } = useTranslation(['payments', 'actions'])

  return (
    <Form>
      <Field
        name='amount_satangs'
        hint={`${t('outstanding_balance')}: ${invoice.getIn(['outstanding_balance', 'display_value'])}`}
      />

      <Button
        type='submit'
        variant='contained'
        color='primary'
        className={classes.button}
      >
        {t('save')}
      </Button>
      <Button
        variant='contained'
        onClick={onCancel}
        className={classes.button}
      >
        {t('cancel')}
      </Button>
    </Form>
  )
}

CashPaymentForm.propTypes = {
  invoice: ImmutablePropTypes.map,
  onCancel: PropTypes.func,
  classes: PropTypes.object
}

CashPaymentForm.defaultProps = {
  invoice: Map()
}

export default withStyles(styles)(CashPaymentForm)
