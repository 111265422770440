import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetchChildWithImplicitParent } from 'actions/resources'
import { fetch } from 'actions/collections'
import { fetchCurrent, updateCurrent } from 'actions/authentication'
import { getCurrentResource } from 'selectors/authentication'
import { tenantSchema, generalConfigurationSchema, serviceSchema } from '@seekster/schemas'

import GeneralSettingsPane from './GeneralSettingsPane'

const mapStateToProps = (state) => ({
  tenant: getCurrentResource(state, tenantSchema)
})

const mapDispatchToProps = {
  fetchGeneralConfig: () => fetchChildWithImplicitParent(tenantSchema, generalConfigurationSchema),
  fetchCurrentTenant: () => fetchCurrent(tenantSchema),
  updateCurrentTenant: data => updateCurrent(data, tenantSchema),
  fetchPopularService: () => fetch(serviceSchema, {
    queryParam: {
      popular: 4
    }
  }),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettingsPane)
