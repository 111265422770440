import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Grid, Button } from '@material-ui/core'

import { Field, FastField } from 'components/fields/Field'
import DateRangeField from 'components/fields/DateRangeField'
import ResourceSelect from 'components/inputs/ResourceSelect'
import SelectInput from 'components/inputs/SelectInput'
import { serviceSchema } from '@seekster/schemas'
import arrayToOptions from 'utils/arrayToOptions'
import { SUBSCRIPTION_STATES } from 'constants/states/subscriptionStates'

export class SubscriptionsFilterForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    onCloseModal: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit () {
    this.props.handleSubmit()

    this.props.onCloseModal()
  }

  render () {
    return (
      <Form>
        <DateRangeField name='start_date' />
        <DateRangeField name='created_at' />
        <FastField
          name='state'
          component={SelectInput}
          options={arrayToOptions(SUBSCRIPTION_STATES, { i18nNamespace: 'subscriptions', i18nKey: 'states' })}
        />
        <Field name='service_id' component={ResourceSelect} schema={serviceSchema} />

        <Grid item xs={12} container justify='flex-end'>
          <Button
            variant='contained'
            type='button'
            onClick={this.props.onCloseModal}
          >
            Cancel
          </Button>

          <Button
            variant='contained'
            color='primary'
            type='submit'
            onClick={this.handleSubmit}
            style={{ marginLeft: 8 }}
          >
            Apply
          </Button>
        </Grid>
      </Form>
    )
  }
}

export default SubscriptionsFilterForm
