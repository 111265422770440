import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Button } from 'components/buttons'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import { Field } from 'components/fields/Field'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'
import { SMS_RATING_REASONS } from 'constants/states/smsRatingStates'
import DateFilterInput from './DateFilterInput'
import SlotTimeFilterInput from './SlotTimeFilterInput'

const useStyles = makeStyles(() => ({
  form: {
    gap: '20px',
    display: 'grid'
  }
}))

function SMSRatingsFilterForm({ handleSubmit, onCloseModal, setFieldValue, values }) {
  const onSubmit = () => {
    handleSubmit()
    onCloseModal()
  }

  const { t } = useTranslation('sms_ratings')
  const classes = useStyles()

  return (
    <Form className={classes.form}>
      <Field
        name='reason'
        component={SelectInput}
        options={arrayToOptions(SMS_RATING_REASONS, {
          i18nNamespace: 'sms_ratings',
          i18nKey: 'reasons'
        })}
      />
      <Field
        name='date'
        component={DateFilterInput}
        setFieldValue={setFieldValue}
        formValues={values}
      />
      {(values.date.dateList || []).length < 2 && (
        <SlotTimeFilterInput
          t={t}
          setFieldValue={setFieldValue}
          formValues={values}
        />
      )}
      <div>
        <Button color='primary' onClick={onSubmit}>
          Apply
        </Button>

        <Button onClick={onCloseModal}>Cancel</Button>
      </div>
    </Form>
  )
}

SMSRatingsFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  onCloseModal: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.object
}

export default SMSRatingsFilterForm
