import { useSelector, useDispatch } from 'react-redux'

import { setOpenModal, setCloseModal } from 'actions/modal'

export function useModal() {
  const dispatch = useDispatch()
  const modal = useSelector((state) => state.get('modal'))

  function openModal({ name, message = '', data = {}, onConfirm = null }) {
    dispatch(setOpenModal({ name, message, data, onConfirm }))
  }

  function closeModal() {
    dispatch(setCloseModal())
  }

  return {
    open: modal.get('open'),
    name: modal.get('name'),
    message: modal.get('message'),
    data: modal.get('data'),
    onConfirm: modal.get('onConfirm'),
    openModal,
    closeModal
  }
}
