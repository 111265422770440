import React from 'react'
import { Radio, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import './ApprovalPicker.scss'

export function ApprovalPicker({ value, onChange }) {
  const { t } = useTranslation('organizations')

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <div className='span-container' onClick={() => onChange(true)}>
          <Radio checked={value} />
          {t('permissions_approvals')}
        </div>
      </Grid>

      <Grid item xs={12} sm={6}>
        <div
          className='span-container'
          style={{ marginLeft: '5%' }}
          onClick={() => onChange(false)}
        >
          <Radio checked={!value} />
          {t('no_permissions_approvals')}
        </div>
      </Grid>
    </Grid>
  )
}

export default ApprovalPicker
