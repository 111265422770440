import { connect } from 'react-redux'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'
import { fetchChildren } from 'actions/collections'
import { create, destroy } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { customerSchema, blacklistedProviderSchema } from '@seekster/schemas'

import CustomerBlacklistedProvidersPane from './CustomerBlacklistedProvidersPane'

const mapStateToProps = (state, { match }) => ({
  customer: getResource(state, match.params.id, customerSchema)
})

const mapDispatchToProps = {
  fetchCustomerBlacklistedProviders: (id, options) => fetchChildren(customerSchema, id, blacklistedProviderSchema, options),
  createCustomerBlacklistedProvider: (data, id, options) => create(data, customerSchema, { type: `customers/${id}/blacklisted_providers` }),
  destroyCustomerBlacklistedProvider: (id, options) => destroy(id, blacklistedProviderSchema, options)
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(CustomerBlacklistedProvidersPane)
