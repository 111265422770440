export const FETCH_RESOURCE_SUCCEEDED = 'FETCH_RESOURCE_SUCCEEDED'
export const FETCH_CHILD_RESOURCE_SUCCEEDED = 'FETCH_CHILD_RESOURCE_SUCCEEDED'
export const FETCH_CHILD_RESOURCE_WITH_CHILD_ID_SUCCEEDED = 'FETCH_CHILD_RESOURCE_WITH_CHILD_ID_SUCCEEDED'
export const FETCH_CHILDREN_RESOURCE_SUCCEEDED = 'FETCH_CHILDREN_RESOURCE_SUCCEEDED'
export const CREATE_RESOURCE_SUCCEEDED = 'CREATE_RESOURCE_SUCCEEDED'
export const CREATE_CHILD_RESOURCE_SUCCEEDED = 'CREATE_CHILD_RESOURCE_SUCCEEDED'
export const UPDATE_RESOURCE_SUCCEEDED = 'UPDATE_RESOURCE_SUCCEEDED'
export const UPDATE_CHILD_RESOURCE_SUCCEEDED = 'UPDATE_CHILD_RESOURCE_SUCCEEDED'
export const DESTROY_RESOURCE_SUCCEEDED = 'DESTROY_RESOURCE_SUCCEEDED'
export const DESTROY_CHILD_RESOURCE_SUCCEEDED = 'DESTROY_CHILD_RESOURCE_SUCCEEDED'
export const TRIGGER_RESOURCE_EVENT_SUCCEEDED = 'TRIGGER_RESOURCE_EVENT_SUCCEEDED'
export const TRIGGER_CHILD_RESOURCE_EVENT_SUCCEEDED = 'TRIGGER_CHILD_RESOURCE_EVENT_SUCCEEDED'
