import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import { compose } from 'redux'
import {
  withMobileDialog,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import { Switch, Case } from 'components/SwitchCase'
import { ResponsiveMenu, ResponsiveMenuItem } from 'components/menus/ResponsiveMenu'
import BankTransferPaymentForm from 'forms/actions/BankTransferPaymentForm'
import CardPaymentForm from 'forms/actions/CardPaymentForm'
import WalletPaymentForm from 'forms/actions/WalletPaymentForm'
import CashPaymentForm from 'forms/actions/CashPaymentForm'

export function PaymentMethodMenu({
  invoice,
  fullScreen,
  fetchPayments,
  fetchInvoice,
  createTransferPayment,
  createWalletPayment,
  createCashPayment,
  createCardPayment,
  fetchIntegrationInstance,
  t
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [modalName, setModalName] = useState(null)
  const [omiseToken, setOmiseToken] = useState(false)

  useEffect(() => {
    fetchIntegrationInstance(1)
      .then((res) => setOmiseToken(res.body.integration_configurations[0].value))
      .catch((err) => console.log(err))
  }, [fetchIntegrationInstance])

  function handleOpenMenu(e) {
    setMenuOpen(true)
    setAnchorEl(e.currentTarget)
  }

  function handleCloseMenu() {
    setMenuOpen(false)
    setAnchorEl(null)
  }

  function selectPaymentMethod(paymentMethod) {
    handleCloseMenu()

    handleOpenModal(paymentMethod)
  }

  function handleOpenModal(modalName) {
    setModalName(modalName)
  }

  function handleCloseModal() {
    setModalName(null)
  }

  function handleFetchPayment() {
    fetchPayments(invoice.get('id'))
    fetchInvoice(invoice.get('id'))
  }

  function handleSubmitBankTransferPayment(data) {
    handleCloseModal()

    return createTransferPayment(invoice.get('id'), data).then(() => handleFetchPayment())
  }

  function handleSubmitWalletPayment(data) {
    handleCloseModal()

    return createWalletPayment(invoice.get('id'), data).then(() => handleFetchPayment())
  }

  function handleSubmitCashPayment(data) {
    handleCloseModal()

    return createCashPayment(invoice.get('id'), data).then(() => handleFetchPayment())
  }

  function handleSubmitCardPayment(data) {
    handleCloseModal()

    return createCardPayment(invoice.get('id'), data).then(() => handleFetchPayment())
  }

  return (
    <>
      <ResponsiveMenu
        open={menuOpen}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        trigger={
          <IconButton onClick={handleOpenMenu}>
            <MoreVert />
          </IconButton>
        }
      >
        <ResponsiveMenuItem
          content={t('method.bank_transfer')}
          onClick={() => selectPaymentMethod('bank_transfer')}
        />
        <ResponsiveMenuItem
          content={t('method.seekster_wallet')}
          onClick={() => selectPaymentMethod('seekster_wallet')}
        />
        <ResponsiveMenuItem
          content={t('method.cash')}
          onClick={() => selectPaymentMethod('cash')}
        />

        {omiseToken && (
          <ResponsiveMenuItem
            content={t('method.card')}
            onClick={() => selectPaymentMethod('card')}
          />
        )}
      </ResponsiveMenu>

      <Dialog
        fullWidth
        maxWidth='sm'
        fullScreen={fullScreen}
        open={Boolean(modalName)}
        onClose={handleCloseModal}
      >
        <DialogTitle>{t(`method.${modalName}`)}</DialogTitle>
        <DialogContent>
          <Switch value={modalName}>
            <Case
              value='bank_transfer'
              render={() => (
                <BankTransferPaymentForm
                  invoice={invoice}
                  onSubmit={handleSubmitBankTransferPayment}
                  onCancel={handleCloseModal}
                />
              )}
            />

            <Case
              value='seekster_wallet'
              render={() => (
                <WalletPaymentForm
                  invoice={invoice}
                  onSubmit={handleSubmitWalletPayment}
                  onCancel={handleCloseModal}
                />
              )}
            />

            <Case
              value='cash'
              render={() => (
                <CashPaymentForm
                  invoice={invoice}
                  onSubmit={handleSubmitCashPayment}
                  onCancel={handleCloseModal}
                />
              )}
            />

            <Case
              value='card'
              render={() => (
                <CardPaymentForm
                  invoice={invoice}
                  omiseToken={omiseToken}
                  onSubmit={handleSubmitCardPayment}
                  onCancel={handleCloseModal}
                />
              )}
            />
          </Switch>
        </DialogContent>
      </Dialog>
    </>
  )
}

PaymentMethodMenu.propTypes = {
  invoice: ImmutablePropTypes.map,
  fullScreen: PropTypes.bool,
  fetchPayments: PropTypes.func,
  fetchInvoice: PropTypes.func,
  createTransferPayment: PropTypes.func,
  createWalletPayment: PropTypes.func,
  createCashPayment: PropTypes.func,
  createCardPayment: PropTypes.func,
  fetchIntegrationInstance: PropTypes.func,

  t: PropTypes.func
}

PaymentMethodMenu.defaultProps = {
  invoice: Map()
}

export default compose(withMobileDialog(), withTranslation('payments'))(PaymentMethodMenu)
