import React from 'react'
import Moment from 'moment'
import { DayPickerSingleDateController } from 'react-dates'
import isSameDay from 'react-dates/lib/utils/isSameDay'

import './DateFilterInput.scss'

class DateFilterInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: false,
      dateList: []
    }
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentDidMount() {
    const dateList = this.props.formValues.date.dateList || []
    this.setState({ dateList })
  }

  handleDateChange(date) {
    const { setFieldValue, formValues } = this.props

    date = Moment(date).format('YYYY-MM-DD')

    const dateList = [...this.state.dateList]

    if (dateList.indexOf(date) !== -1) {
      dateList.splice(dateList.indexOf(date), 1)
    } else {
      dateList.push(date)
    }

    this.setState({ dateList })
    setFieldValue('date', { ...formValues.date, dateList })
  }

  render() {
    const { focused, dateList } = this.state

    return (
      <DayPickerSingleDateController
        focused={focused}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        onDateChange={this.handleDateChange}
        isDayHighlighted={day1 => dateList.some(day2 => isSameDay(Moment(day1), Moment(day2)))}
      />
    )
  }
}

export default DateFilterInput
