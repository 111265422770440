import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import { PayoutOverviewPane } from './panes'

export function PayoutShowPage ({
  payout,
  location,
  t,
  fetchPayout,
  match
}) {
  const { loading } = useData(() => fetchPayout(match.params.id))
  function subNavbar () {
    const path = `/payouts/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('common:navbar.overview')} to={path} />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs () {
    return [{
      text: t('title'),
      link: '/payouts'
    }, {
      text: payout.get('id')
    }]
  }

  return (
    <PageLayout
      title={`${t('title')} ID:${payout.get('id')}`}
      subNavbar={subNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/payouts/:id' component={PayoutOverviewPane} exact />
      </Switch>
    </PageLayout>
  )
}

PayoutShowPage.propTypes = {
  payout: ImmutablePropTypes.map,
  location: PropTypes.object,
  t: PropTypes.func,
  fetchPayout: PropTypes.func,
  match: PropTypes.object
}

PayoutShowPage.defaultProps = {
  payout: Map(),
  loadPayout: () => {}
}

export default withTranslation(['payouts'])(PayoutShowPage)
