import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { customerSchema, invitationSchema } from '@seekster/schemas'

import CustomerInvitationsPane from './CustomerInvitationsPane'

const mapStateToProps = (state, { match }) => ({
  customer: getResource(state, match.params.id, customerSchema)
})

const mapDispatchToProps = {
  fetchInvitations: id => fetchChildren(customerSchema, id, invitationSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInvitationsPane)
