import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { xor } from 'lodash'
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'

export function CheckboxInput ({ value: currentValues, onChange, options, ...rest }) {
  function setValue (value) {
    onChange && onChange(xor(currentValues, [value]))
  }

  return (
    <FormGroup>
      {
        options.map(({ value, label }) => (
          <FormControlLabel
            key={value}
            label={label}
            control={
              <Checkbox
                checked={currentValues.includes(value)}
                onChange={() => setValue(value)}
                value={value}
                {...rest}
              />
            }
          />
        ))
      }
    </FormGroup>
  )
}

CheckboxInput.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([
    PropTypes.array,
    ImmutablePropTypes.list
  ])
}

CheckboxInput.defaultProps = {
  value: []
}

export default CheckboxInput
