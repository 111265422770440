import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { fetch } from 'actions/resources'
import { providerSchema } from '@seekster/schemas'
import FavoriteProviderFormController from './FavoriteProviderFormController'

const mapStateToProps = (state) => ({
  getProvider: (id) => getResource(state, id, providerSchema)
})

const mapDispatchToProps = {
  fetchProvider: (id, options) => fetch(id, providerSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteProviderFormController)
