import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'

import FastField from 'components/fields/Field'
import ButtonLoader from 'components/buttons/ButtonLoader'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'

const useStyles = makeStyles(({ spacing }) => ({
  formActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(3)
  },

  cancelButton: {
    fontWeight: 600
  },

  saveButton: {
    marginLeft: spacing(1)
  }
}))

const ScbQrCodeIntegrationForm = ({ integration, handleSubmit }) => {
  const { t } = useTranslation('integrations')
  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        api_key: integration.get('api_key'),
        api_secret: integration.get('api_secret'),
        biller_id: integration.get('biller_id'),
        ref3_prefix: integration.get('ref3_prefix'),
        supporting_reference: integration.get('supporting_reference')
      }}
      validationSchema={Yup.object().shape({
        api_key: Yup.string().required(),
        api_secret: Yup.string().required(),
        biller_id: Yup.string().required(),
        ref3_prefix: Yup.string().required(),
        supporting_reference: Yup.string().required()
      })}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ dirty, isSubmitting }) => (
        <Form>
          <FastField label='API Key' name='api_key' required />
          <FastField label='API Secret' name='api_secret' required />
          <FastField label='Biller ID' name='biller_id' required />
          <FastField label='Reference 3 Prefix' name='ref3_prefix' required />
          <FastField
            label='Supporting Reference'
            name='supporting_reference'
            component={SelectInput}
            options={arrayToOptions(['ONE', 'TWO'])}
            required
          />

          {
            dirty && (
              <div className={classes.formActions}>
                <Button
                  type='reset'
                  className={classes.cancelButton}
                >
                  {t('cancel')}
                </Button>

                <ButtonLoader
                  type='submit'
                  color='primary'
                  className={classes.saveButton}
                  isLoading={isSubmitting}
                >
                  {t('save')}
                </ButtonLoader>
              </div>
            )
          }
        </Form>
      )}
    </Formik>
  )
}

ScbQrCodeIntegrationForm.propTypes = {
  integration: ImmutablePropTypes.map,
  handleSubmit: PropTypes.func
}

export default ScbQrCodeIntegrationForm
