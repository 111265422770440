import React from 'react'
import { Link } from 'react-router-dom'
import { Table, Column } from 'components/tables/Table'
import { Paper } from '@material-ui/core'

import { DateTag, StateTag, PhoneTag } from 'components/tags'

export function InvoicesTable ({ invoices }) {
  return (
    <Paper>
      <Table collection={invoices}>
        <Column
          name='number'
          render={(number, invoice) => (
            <Link to={`/invoices/${invoice.get('id')}`}>{invoice.get('number')}</Link>
          )}
        />
        <Column
          name='customer'
          render={(customer, invoice) => (
            <>
              <p>
                <Link to={`/${customer.get('type')}/${customer.get('id')}`}>{customer.get('display_name')}</Link>
              </p>
              <p>
                <PhoneTag value={customer.get('phone_number')} />
              </p>
            </>
          )}
        />
        <Column
          name='Total'
          render={(total, invoice) => invoice.getIn(['grand_total', 'display_value'])}
        />
        <Column name='state' component={StateTag} />
        <Column name='created_at' component={DateTag} />
      </Table>
    </Paper>
  )
}

export default InvoicesTable
