import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Grid } from '@material-ui/core'

import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'
import { DateTag, BooleanTag } from 'components/tags'
import Panel from 'components/containers/Panel'

export class AppOverviewPane extends React.Component {
  static propTypes = {
    app: ImmutablePropTypes.map
  }

  static defaultProps = {
    app: Map()
  }

  render () {
    const { app } = this.props

    return (
      <Grid container>
        <Grid item xs={12} sm={6} style={{ paddingRight: 0 }}>
          <Panel title='App Details'>
            <AttributesTable resource={app} basic='very'>
              <Attribute name='id' />
              <Attribute name='name' />
              <Attribute name='official' component={BooleanTag} />
              <Attribute name='webhook_url' />
              <Attribute name='fcm_server_key' />
              <Attribute name='updated_at' component={DateTag} />
              <Attribute name='created_at' component={DateTag} />
            </AttributesTable>
          </Panel>
        </Grid>

        <Grid item xs={12} sm={6} style={{ paddingRight: 0 }}>
          <Panel title='Contact'>
            <AttributesTable resource={app} basic='very'>
              <Attribute name='contact_name' />
              <Attribute name='contact_email' />
              <Attribute name='contact_phone_number' />
            </AttributesTable>
          </Panel>
        </Grid>
      </Grid>
    )
  }
}

export default AppOverviewPane
