import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { useSelector } from 'react-redux'
import { Form } from 'formik'
import { Grid } from '@material-ui/core'
import NativeSelect from '@material-ui/core/NativeSelect'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Map } from 'immutable'

import CompanyBillingAddressFormSection from 'modules/companies/CompanyBillingAddressFormSection'
import CompanyBranchFormSection from 'modules/companies/CompanyBranchFormSection'

import { Tabs, TabPane } from 'components/Tabs'
import { Field, FastField } from 'components/fields/Field'
import SortableFieldArray from 'components/fields/SortableFieldArray'
import ButtonLoader from 'components/buttons/ButtonLoader'
import ResourceSelect from 'components/inputs/ResourceSelect'
import {
  customerSchema,
  companyTypeSchema,
  companySchema,
  tagSchema,
  agentSchema
} from '@seekster/schemas'
import { getResource } from 'selectors/resources'
import InputBase from '@material-ui/core/InputBase'
import ResourceCreatableSelect from 'components/inputs/ResourceCreatableSelect/'

const useStyles = makeStyles(({ spacing }) => ({
  panelContanier: {
    padding: spacing(2)
  },

  button: {
    float: 'right',
    marginBottom: spacing(2),
    marginTop: spacing(2)
  },

  tabs: {
    justifyContent: 'flex-start'
  },

  customSelectField: {
    marginTop: '26px',
    width: '100%'
  }
}))

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase)

export function CompanyForm({ values, isSubmitting, setFieldValue, company }) {
  const { t } = useTranslation(['companies', 'common'])
  const classes = useStyles()
  let selectedCompanyType = useSelector(
    (state) =>
      getResource(state, values.parent_company_type_id, companyTypeSchema) || Map()
  )

  selectedCompanyType = selectedCompanyType.toJS()

  return (
    <Grid container justify='center' component={Form}>
      <Grid item xs={12} sm={9}>
        <Tabs contained scrollButtons='off' classes={{ flexContainer: classes.tabs }}>
          <TabPane label={t('details')}>
            <div className={classes.panelContanier}>
              <FastField name='name' />

              <FastField name='phone_number' />

              <FastField name='tax_number' />

              <Field
                name='contact_person_id'
                component={ResourceSelect}
                schema={customerSchema}
              />

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Field
                    name='parent_company_type_id'
                    component={ResourceSelect}
                    schema={companyTypeSchema}
                    value={values.parent_company_type_id}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div className='form-field'>
                    <NativeSelect
                      className={classes.customSelectField}
                      value={values.company_type_id}
                      onChange={(e) => setFieldValue('company_type_id', e.target.value)}
                      input={<BootstrapInput />}
                      disabled={!values.parent_company_type_id}
                    >
                      <option value=''>-- choose --</option>
                      {selectedCompanyType?.children?.map((subCompanyType, key) => (
                        <option key={key} value={subCompanyType.id}>
                          {subCompanyType.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  name='tag_ids'
                  component={ResourceCreatableSelect}
                  schema={tagSchema}
                  parentSchema={companySchema}
                  parentId={company.get('id')}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FastField
                  name='responsible_by_id'
                  component={ResourceSelect}
                  schema={agentSchema}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FastField
                  name='support_by_id'
                  component={ResourceSelect}
                  schema={agentSchema}
                />
              </Grid>
            </div>
          </TabPane>

          <TabPane label={t('billing_details')}>
            <div className={classes.panelContanier}>
              <FastField name='billing_email' />

              <CompanyBillingAddressFormSection name='billing_address_attributes' />
            </div>
          </TabPane>

          <TabPane label='branch'>
            <div className={classes.panelContanier}>
              <SortableFieldArray
                name='branches_attributes'
                component={CompanyBranchFormSection}
              />
            </div>
          </TabPane>
        </Tabs>

        <Grid container justify='flex-end'>
          <ButtonLoader
            type='submit'
            variant='contained'
            color='primary'
            className={classes.button}
            isLoading={isSubmitting}
          >
            {t('save')}
          </ButtonLoader>
        </Grid>
      </Grid>
    </Grid>
  )
}

CompanyForm.propTypes = {
  values: PropTypes.object,
  company: ImmutablePropTypes.map,
  setFieldValue: PropTypes.func,
  isSubmitting: PropTypes.bool
}

CompanyForm.defaultProps = {
  company: Map()
}

export default CompanyForm
