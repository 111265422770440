import { Map } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'

import ReviewForm from './ReviewForm'

const formikConfig = {
  mapPropsToValues: ({ review = Map() }) => ({
    rating: review.get('rating') || '',
    content: review.get('content') || '',
    review_type_id: review.get('review_type_id') || ''
  }),

  validationSchema: Yup.object().shape({
    rating: Yup.number().required()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit(values)
      .then((response) => {
        props.push(`/reviews/${response.body.id}`)
      })
      .catch((error) => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(withFlashMessage, withFormik(formikConfig))(ReviewForm)
