export default ({ breakpoints, spacing }) => ({
  avatar: {
    width: 140,
    height: 140,
    marginTop: spacing(1),
    marginBottom: spacing(3)
  },
  textContainer: {
    marginTop: spacing(3),
    marginBottom: spacing(2),
    fontSize: '22px'
  },
  title: {
    fontWeight: 'bolder'
  },
  small: {
    fontSize: '18px'
  },
  markerContainer: {
    marginTop: spacing(1)
  },
  marker: {
    marginRight: '4px'
  },
  state: {
    marginTop: spacing(2),
    paddingLeft: spacing(1)
  },
  withBorderTop: {
    marginTop: spacing(2),
    paddingTop: spacing(2),
    paddingLeft: spacing(1),
    borderTop: 'solid #dadada 1px'
  },
  textBorder: {
    fontWeight: 'bolder'
  },
  signatureSection: {
    padding: spacing(2)
  },
  signatureImage: {
    width: '100%',

    [breakpoints.up('sm')]: {
      width: '50%'
    }
  }
})
