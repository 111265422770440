import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Grid, CircularProgress, Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import WalletTransactionList from 'modules/wallets/WalletTransactionList'
import WalletTransactionMenu from 'modules/wallets/WalletTransactionMenu'
import Panel from 'components/containers/Panel'
import AttributeLabel from 'components/labels/AttributeLabel'
import Pagination from 'components/Pagination'
import { customerSchema } from '@seekster/schemas'

export class CustomerWalletPane extends React.Component {
  static propTypes = {
    customer: ImmutablePropTypes.map,
    query: PropTypes.object,
    match: PropTypes.object,
    fetchWallet: PropTypes.func,
    fetchWalletTransactions: PropTypes.func,
    fetchCustomerJobs: PropTypes.func,

    t: PropTypes.func
  }

  static defaultProps = {
    customer: Map()
  }

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      totalCount: 0,
      totalPages: 1
    }
  }

  componentDidMount() {
    this.handleUpdateData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { query, customer } = this.props

    if (prevProps.query.page !== query.page) {
      this.handleUpdateDataOnPageChange()
    } else if (
      prevProps.customer.get('wallet_transactions', List()).size !==
      customer.get('wallet_transactions', List()).size
    ) {
      this.handleUpdateData()
    }
  }

  handleUpdateData() {
    const { page } = this.props.query

    this.setState({ loading: true })

    this.props.fetchWallet(this.props.match.params.id)

    this.props
      .fetchWalletTransactions(this.props.match.params.id, { page })
      .then((response) => {
        this.setState({
          loading: false,
          totalCount: parseInt(response.headers.total, 10),
          totalPages: Math.ceil(response.headers.total / response.headers['per-page'])
        })
      })
      .catch((error) => {
        this.setState({ loading: false, error: error.message })
      })
  }

  handleUpdateDataOnPageChange() {
    const { page } = this.props.query

    this.setState({ loading: true })

    this.props
      .fetchWalletTransactions(this.props.match.params.id, { page })
      .then(() => {
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false, error: error.message })
      })
  }

  renderWalletTransactionMenu() {
    return (
      <WalletTransactionMenu
        resource={this.props.customer}
        parentSchema={customerSchema}
        fetchJobs={this.props.fetchCustomerJobs}
        isCustomer
      />
    )
  }

  renderWalletTransactionList() {
    const transactions = this.props.customer.get('wallet_transactions', List())

    if (this.state.loading) {
      return <CircularProgress color='secondary' />
    } else if (transactions.isEmpty()) {
      return (
        <Typography color='textSecondary'>
          {this.props.t('transactions.no_transaction')}
        </Typography>
      )
    } else {
      return (
        <>
          <WalletTransactionList transactions={transactions} />

          <Pagination totalPages={this.state.totalPages} />
        </>
      )
    }
  }

  render() {
    const { customer, t } = this.props

    return (
      <Grid container direction='row-reverse'>
        <Grid item sm={4} container direction='column'>
          <Grid item>
            <Panel title={t('wallet.title')}>
              <div style={{ padding: '10px 16px 0' }}>
                <AttributeLabel title={t('wallet.available')}>
                  <Typography variant='h4'>
                    {customer.getIn(['wallet', 'available_balance', 'display_value'], '')}
                  </Typography>
                </AttributeLabel>

                <AttributeLabel title={t('wallet.withdrawal_fee')}>
                  {customer.getIn(['wallet', 'withdrawal_fee', 'display_value'], '')}
                </AttributeLabel>
              </div>
            </Panel>
          </Grid>
        </Grid>

        <Grid item sm={8} xs={12}>
          <Panel
            title={t('transactions.title')}
            subtitle={`${this.state.totalCount} total`}
            actions={this.renderWalletTransactionMenu()}
            cardContentProps={{ style: { textAlign: 'center' } }}
          >
            {this.renderWalletTransactionList()}
          </Panel>
        </Grid>
      </Grid>
    )
  }
}

export default withTranslation(['wallets', 'customers'])(CustomerWalletPane)
