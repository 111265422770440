import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useSorting } from 'hooks'
import { useCollection } from 'hooks/collections'
import { useResourceAction } from 'hooks/resources'
import useQuery from 'hooks/useQuery'

import PageLayout from 'components/layouts/PageLayout'
import CircularLoader from 'components/CircularLoader'
import Search from 'components/Search'
import FilterModal from 'components/FilterModal'
import FilterTags from 'components/tags/FilterTags'
import AnnouncementsFilterForm from 'forms/filters/AnnouncementsFilterForm'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import { RelativeTimeIntevalFormatter } from 'components/formatters'
import SortableList from 'components/SortableList'
import BooleanChip from 'components/BooleanChip'

import { announcementSchema } from '@seekster/schemas'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

export function AnnouncementIndexPage () {
  const classes = useStyles()
  const [query] = useQuery()
  const { t } = useTranslation('announcements')
  const { update: updateAnnouncement } = useResourceAction(announcementSchema)
  const {
    handleSortStart,
    handleSortEnd,
    sortingEventId
  } = useSorting(updateAnnouncement)

  const [announcements, { loading, totalCount, totalPages }] = useCollection(
    announcementSchema,
    { page: query.page, query },
    [query, sortingEventId]
  )

  function primaryActions () {
    return [{ content: t('new'), to: '/announcements/new' }]
  }

  return (
    <PageLayout title={t('title')} primaryActions={primaryActions()}>
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Search />
          </Grid>

          <Grid item xs={2}>
            <FilterModal component={AnnouncementsFilterForm} />
          </Grid>
        </Grid>
      </div>

      {
        loading ? (
          <CircularLoader />
        ) : (
          <>
            <div className={classes.metaContainer}>
              <FilterTags />

              <ResultsCountLabel value={totalCount} unit='announcement' />

              <Paper>
                <SortableList
                  collection={announcements}
                  title={announcement => announcement.get('title') || `[ ${t('no_title')} ]`}
                  subtitle={announcement => (
                    <RelativeTimeIntevalFormatter
                      startTime={{
                        value: announcement.get('start_time'),
                        eventName: 'live'
                      }}
                      endTime={{
                        value: announcement.get('end_time'),
                        eventName: 'expire'
                      }}
                    />
                  )}
                  secondaryTitle={announcement => <BooleanChip value={announcement.get('active')} />}
                  onSortEnd={handleSortEnd}
                  onSortStart={handleSortStart}
                  useDragHandle
                />
              </Paper>
            </div>

            <Grid item xs={12} container justify='center'>
              <Pagination totalPages={totalPages} />
            </Grid>
          </>
        )
      }
    </PageLayout>
  )
}

export default AnnouncementIndexPage
