import { withFormik } from 'formik'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'
import TagManagerSettingsForm from './TagManagerSettingsForm'

const formikConfig = {
  mapPropsToValues: ({ tagManagerSettings = Map() }) => ({
    head: tagManagerSettings.get('head'),
    body_start: tagManagerSettings.get('body_start'),
    body_end: tagManagerSettings.get('body_end'),
    id: tagManagerSettings.get('id')
  }),

  enableReinitialize: true,

  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true)
    props.onSubmit(values)
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(TagManagerSettingsForm)
