import React, { useEffect, useState } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import { List } from 'immutable'

import { Form } from 'formik'
import { withTranslation } from 'react-i18next'
import SelectInput from 'components/inputs/SelectInput'
import { makeStyles } from '@material-ui/core/styles'
import { useCollection } from 'hooks/collections'

import { Field } from 'components/fields/Field'
import Button from 'components/buttons/Button'
import { cancelReasonTypeSchema } from '@seekster/schemas'

const useStyles = makeStyles(({ palette }) => ({
  '@media screen and (max-width: 767px)': {
    paddingTop: 0
  },

  buttonContainer: {
    marginBottom: 18
  },

  confirmButton: {
    marginLeft: 18,
    width: 161,
    fontSize: 16,
    height: 44
  },

  cancelButton: {
    border: `2px solid ${palette.primary.main}`,
    color: '#47525D',
    fontSize: 16,
    height: 44,
    width: 161,
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.2)',
    '&:hover': {
      borderWidth: 2,
      borderColor: palette.primary.main,
      opacity: 0.8
    }
  }
}))

export function RequestCancelForm({
  onCloseModal,
  isSubmitting,
  values,
  setFieldValue,
  owner
}) {
  const classes = useStyles()
  const [reasons, { loading }] = useCollection(cancelReasonTypeSchema, {
    queryParam: {
      owner
    }
  })
  const [reasonType, setReasonType] = useState(List())
  const [isOptional, setIsOptional] = useState(false)

  useEffect(() => {
    if (values.cancel_reason_type_id) {
      const cancelValue = reasons.find(
        (reason) => reason.get('id') === values.cancel_reason_type_id
      )

      if (cancelValue.get('value') === 'Other') {
        setIsOptional(true)
        setFieldValue('cancel_reason_value_id', null)
      } else {
        setIsOptional(false)
      }

      setReasonType(cancelValue.get('cancel_reason_values'))
    }
  }, [values.cancel_reason_type_id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form>
      <Grid container>
        {reasons.isEmpty() ? (
          <>
            <h3>ยกเลิกงาน</h3>

            <Grid item xs={12} sm={12}>
              <Grid container justify='flex-end' className='action'>
                <Button
                  onClick={onCloseModal}
                  variant='outlined'
                  color='primary'
                  disabled={isSubmitting}
                >
                  ไว้ก่อน
                </Button>

                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={isSubmitting}
                  className={classes.confirmButton}
                >
                  {isSubmitting ? <CircularProgress size={20} /> : <b>ยืนยัน</b>}
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <h3>โปรดระบุเหตุผลในการขอยกเลิกงาน</h3>

            <Grid item xs={12} sm={12}>
              <Grid item xs={12}>
                <Field
                  name='cancel_reason_type_id'
                  component={SelectInput}
                  isLoading={loading}
                  placeholder='Please Select...'
                  options={reasons.map((reason) => ({
                    value: reason.get('id'),
                    label: `${reason.get('owner')} Cancellation [By ${reason.get(
                      'value'
                    )}]`
                  }))}
                  schema={cancelReasonTypeSchema}
                  isClearable
                />
              </Grid>

              {isOptional ? (
                <Grid item xs={12}>
                  <Field name='other_value' placeholder='Please Input...' />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Field
                    name='cancel_reason_value_id'
                    component={SelectInput}
                    placeholder='Please Select...'
                    options={reasonType.map((reason) => ({
                      value: reason.get('id'),
                      label: reason.get('value')
                    }))}
                  />
                </Grid>
              )}

              <Grid container justify='flex-end' className={classes.buttonContainer}>
                <Button
                  onClick={onCloseModal}
                  variant='outlined'
                  color='secondary'
                  disabled={isSubmitting}
                  className={classes.cancelButton}
                >
                  ไว้ก่อน
                </Button>

                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={isSubmitting}
                  className={classes.confirmButton}
                >
                  {isSubmitting ? <CircularProgress size={20} /> : <b>ยืนยัน</b>}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Form>
  )
}

export default withTranslation(['actions'])(RequestCancelForm)
