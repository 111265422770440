import React from 'react'
import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core'
import RequestCancelForm from 'forms/actions/RequestCancelForm'

import theme from 'utils/theme'

export function RequestCancelModal({
  isOpen,
  onClose,
  icon,
  component: Component,
  ...props
}) {
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  function handleClose() {
    onClose && onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogContent>
        <RequestCancelForm onCloseModal={handleClose} {...props} />
      </DialogContent>
    </Dialog>
  )
}

export default RequestCancelModal
