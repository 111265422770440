import { Map } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import TenantSMSRatingConfigForm from './TenantSMSRatingConfigForm'

const formikConfig = {
  mapPropsToValues: ({ smsRatingConfig = Map() }) => ({
    link_expire_day: smsRatingConfig.get('link_expire_day'),
    link_expire_hour: smsRatingConfig.get('link_expire_hour')
  }),

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    link_expire_day: Yup.number().integer().required(),
    link_expire_hour: Yup.number().integer().required()
  }),

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values)
      .catch(error => {
        setSubmitting(false)

        setErrors({ message: error.response.body.message })
      })
  }
}

export default withFormik(formikConfig)(TenantSMSRatingConfigForm)
