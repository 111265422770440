import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useNodeResource } from 'hooks/resources'

import PageLayout from 'components/layouts/PageLayout'
import {
  NavigationMenuBar,
  NavigationMenuItem
} from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import { organizationSchema } from '@seekster/schemas'

import {
  OrganizationOverviewPane,
  OrganizationJobsPane,
  OrganizationReportsPane
} from './panes'

export function OrganizationShowPage({ match, location }) {
  const { t } = useTranslation(['jobs', 'actions', 'common', 'organizations'])
  const [orgainzation, { loading }] = useNodeResource(organizationSchema, match.params.id)
  const seeksterOrg =
    'https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/Org-icon.svg'

  function renderSubNavbar() {
    const path = `/organizations/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('navbar.overview')} to={path} replace />
        <NavigationMenuItem label={t('job')} to={`${path}/jobs`} replace />
        <NavigationMenuItem label={t('report')} to={`${path}/reports`} replace />
      </NavigationMenuBar>
    )
  }

  return (
    <PageLayout
      title={orgainzation.get('name')}
      subNavbar={renderSubNavbar()}
      isLoading={loading}
      back='/organizations/'
      avatar={
        orgainzation.get('pictureUrl') !== ''
          ? orgainzation.get('pictureUrl')
          : seeksterOrg
      }
    >
      <Switch>
        <AuthenticatedRoute
          path='/organizations/:id'
          component={OrganizationOverviewPane}
          exact
        />
        <AuthenticatedRoute
          path='/organizations/:id/jobs'
          component={OrganizationJobsPane}
          exact
        />
        <AuthenticatedRoute
          path='/organizations/:id/reports'
          component={OrganizationReportsPane}
          exact
        />
      </Switch>
    </PageLayout>
  )
}

OrganizationShowPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object
}

export default OrganizationShowPage
