import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { withMobileDialog, Typography } from '@material-ui/core'

import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import Panel from 'components/containers/Panel'
import CircularLoader from 'components/CircularLoader'
import JobProvidersReportList from 'modules/jobProviders/JobProvidersReportList'

import styles from './JobReportsPaneStyles'

export function JobReportsPane ({
  job,
  fetchJobProviders,
  match,
  classes,
  t
}) {
  const { loading } = useData(() => fetchJobProviders(match.params.id))

  const jobProviders = job.get('job_providers', List()).filter(jobProvider => (
    jobProvider.get('state') === 'completed'
  ))

  function renderContent () {
    if (loading) {
      return <CircularLoader />
    }
    else if (!jobProviders.isEmpty()) {
      return (
        <JobProvidersReportList
          jobProviders={jobProviders}
        />
      )
    }
    else {
      return (
        <Typography variant='overline' className={classes.noProvider}>
          {t('no_provider')}
        </Typography>
      )
    }
  }

  return (
    <Panel
      title={t('providers')}
      cardContentProps={{ className: classes.content }}
    >
      <div className={classes.container}>
        {renderContent()}
      </div>
    </Panel>
  )
}

JobReportsPane.propTypes = {
  job: ImmutablePropTypes.map,
  fetchJobProviders: PropTypes.func,
  match: PropTypes.object,
  classes: PropTypes.object,

  t: PropTypes.func
}

JobReportsPane.defaultProps = {
  job: Map()
}

export default compose(
  withStyles(styles),
  withMobileDialog(),
  withTranslation(['jobs', 'actions'])
)(JobReportsPane)
