import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core'

export function RadioInput ({ value: valueProp, onChange, options, ...rest }) {
  function handleChange (e, value) {
    const currentValue = isNaN(valueProp) ? value : parseInt(value, 10)

    onChange && onChange(currentValue)
  }

  return (
    <FormControl>
      <RadioGroup value={valueProp} onChange={handleChange}>
        {
          options.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              value={value}
              label={label}
              control={<Radio {...rest} />}
            />
          ))
        }
      </RadioGroup>
    </FormControl>
  )
}

RadioInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([
    PropTypes.array,
    ImmutablePropTypes.list
  ])
}

RadioInput.defaultProps = {
  value: ''
}

export default RadioInput
