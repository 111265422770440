import React from 'react'
import PropTypes from 'prop-types'

export function MailFormatter ({ value, ...rest }) {
  return (
    <a href={`mailto:${value}`} {...rest}>
      {value || '-'}
    </a>
  )
}

MailFormatter.propTypes = {
  value: PropTypes.string
}

export default MailFormatter
