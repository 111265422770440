import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Select, InputBase, MenuItem } from '@material-ui/core'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import withLocalizedFields from 'hoc/withLocalizedFields'

import PageLayout from 'components/layouts/PageLayout'
import IssueForm from 'forms/resources/IssueForm'

export function ProviderIssueEditPage ({
  issue,
  fetchIssue,
  updateIssue,
  push,
  match,
  locale,
  setLocale,
  t
}) {
  useEffect(() => {
    fetchIssue(match.params.id)
  }, [fetchIssue, match.params.id])

  function handleSubmit (data) {
    return updateIssue(match.params.id, data)
  }

  function renderActions () {
    return [
      <Select
        value={locale}
        onChange={e => setLocale(e.target.value)}
        input={<InputBase />}
      >
        <MenuItem value='en'><span role='img' aria-label='GB flag'>🇬🇧 </span>English</MenuItem>
        <MenuItem value='th'><span role='img' aria-label='Thai flag'>🇹🇭 </span>ภาษาไทย</MenuItem>
      </Select>
    ]
  }

  return (
    <PageLayout
      title={t('edit')}
      secondaryActions={renderActions()}
      back={`/provider_issues/${match.params.id}`}
    >
      <IssueForm
        issue={issue}
        onSubmit={handleSubmit}
        push={push}
      />
    </PageLayout>
  )
}

ProviderIssueEditPage.propTypes = {
  issue: ImmutablePropTypes.map,
  fetchIssue: PropTypes.func,
  updateIssue: PropTypes.func,
  push: PropTypes.func,
  match: PropTypes.object,
  locale: PropTypes.string,
  setLocale: PropTypes.func,

  t: PropTypes.func
}

ProviderIssueEditPage.defaultProps = {
  issue: Map()
}

export default compose(
  withLocalizedFields,
  withTranslation('issues')
)(ProviderIssueEditPage)
