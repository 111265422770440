import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { providerSchema, leadSchema } from '@seekster/schemas'

import { useCollectionChildren } from 'hooks/collections'

import Panel from 'components/containers/Panel'
import { Table, Column } from 'components/tables/Table'
import { DateTag } from 'components/tags'
import Pagination from 'components/Pagination'
import CircularLoader from 'components/CircularLoader'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
export function ProviderLeadsPane({ match, query }) {
  const [leads, { loading, totalCount, totalPages }] = useCollectionChildren(
    providerSchema,
    match.params.id,
    leadSchema,
    { page: query.page },
    [query.page]
  )

  return (
    <div style={{ padding: '14px 0' }}>
      <Grid container spacing={1}>
        {loading ? (
          <Grid item xs={12}>
            <CircularLoader />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <ResultsCountLabel value={totalCount} unit='lead' />
            </Grid>
            <Grid item xs={12}>
              <Panel title='List of Leads'>
                <div className='responsive'>
                  {!leads.isEmpty() && (
                    <Table collection={leads}>
                      <Column
                        name='inquiry.number'
                        render={(number, lead) => (
                          <Link to={`/inquiries/${lead.getIn(['inquiry', 'id'])}`}>
                            {number}
                          </Link>
                        )}
                      />
                      <Column
                        name='inquiry.service.name'
                        render={(name, service) => (
                          <Link to={`/services/${service.get('id')}`}>{name}</Link>
                        )}
                      />
                      <Column name='start_time' component={DateTag} />
                      <Column name='inquiry.location.masked_address' />
                      <Column name='payout.display_value' />
                    </Table>
                  )}
                </div>
              </Panel>
            </Grid>
            {
              !leads.isEmpty() && (
                <Grid container justify='center'>
                  <Pagination totalPages={totalPages} />
                </Grid>
              )
            }
          </>
        )}
      </Grid>
    </div>
  )
}

ProviderLeadsPane.propTypes = {
  match: PropTypes.object,
  query: PropTypes.object
}

ProviderLeadsPane.defaultProps = {
  provider: Map()
}

export default ProviderLeadsPane
