import { red } from '@material-ui/core/colors'

export default ({ palette, spacing }) => ({
  root: {
    marginBottom: spacing(2)
  },

  header: {
    padding: 0
  },

  title: {
    padding: '0 !important',
    display: 'flex',
    alignItems: 'center'
  },

  dragHandler: {
    cursor: 'grab',
    margin: spacing(2)
  },

  clickableArea: {
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: palette.grey[50]
    }
  },

  titleContent: {
    justifyContent: 'space-between',
    textAlign: 'left',
    textTransform: 'none'
  },

  titleText: {
    wordBreak: 'break-word'
  },

  caret: {
    margin: spacing(2)
  },

  expanded: {
    transform: 'rotate(180deg)'
  },

  cardActions: {
    borderTop: '1px solid',
    borderColor: palette.divider,
    justifyContent: 'flex-end'
  },

  leftIcon: {
    marginRight: spacing(0.5)
  },

  removeButton: {
    color: red[800],

    '&:hover': {
      backgroundColor: `rgba(198, 40, 40, 0.08)`
    }
  },

  activeRemoveButton: {
    color: palette.common.white,
    backgroundColor: red[800],

    '&:hover': {
      backgroundColor: `rgba(198, 40, 40, 0.8)`
    }
  }
})
