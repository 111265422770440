import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { jobSchema, tenantSchema } from '@seekster/schemas'

import { getCurrentResource } from 'selectors/authentication'
import useQuery from 'hooks/useQuery'
import { useCollection } from 'hooks/collections'
import PageLayout from 'components/layouts/PageLayout'
import Search from 'components/Search'
import FilterModal from 'components/FilterModal'
import FilterTags from 'components/tags/FilterTags'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import JobsTable from 'components/tables/JobsTable'
import Pagination from 'components/Pagination'
import JobsFilterForm from 'forms/filters/JobsFilterForm'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

export function JobIndexPage() {
  const classes = useStyles()
  const [query] = useQuery()
  const { t } = useTranslation(['jobs', 'inquiries'])
  const { search, ...rest } = query
  const tenant =
    useSelector((state) => getCurrentResource(state, tenantSchema)) || new Map()
  const [jobs, { loading, totalCount, totalPages }] = useCollection(
    jobSchema,
    { page: query.page, search: search, filter: rest },
    [query]
  )

  return (
    <PageLayout title={t('title')}>
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={10}>
            <Search />
          </Grid>

          <Grid item xs={3} sm={2}>
            <FilterModal component={JobsFilterForm} />
          </Grid>
        </Grid>
      </div>

      <div className={classes.metaContainer}>
        <ResultsCountLabel value={totalCount} unit='job' style={{ margin: 0 }} />

        <FilterTags />

        <JobsTable
          jobs={jobs}
          selectable={!['seekster'].includes(tenant.get('slug'))}
          loading={loading}
        />
      </div>

      <Grid item xs={12} container justify='center'>
        <Pagination totalPages={totalPages} />
      </Grid>
    </PageLayout>
  )
}

export default JobIndexPage
