import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'formik'
import { Grid, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { Field } from 'components/fields/Field'
import ButtonLoader from 'components/buttons/ButtonLoader'
import ResourceSelect from 'components/inputs/ResourceSelect'
import { providerSchema, customerSchema } from '@seekster/schemas'
import { useResourceAction } from 'hooks/resources'

import styles from './MergeFirebaseUserFormStyles'

export function MergeFirebaseUserForm ({
  classes,
  isSubmitting,
  handleSubmit,
  dirty,
  setFieldValue,
  values
}) {
  const [userType, setUserType] = useState('customer')
  const { fetch: fetchProvider } = useResourceAction(providerSchema)
  const { fetch: fetchCustomer } = useResourceAction(customerSchema)

  useEffect(() => {
    setFieldValue('type', userType)
  }, [setFieldValue, userType])

  useEffect(() => {
    if (values.id !== undefined) {
      if (values.type === 'customer') {
        fetchCustomer(values.id).then(({ body }) => {
          setFieldValue('image_url', body.avatar_url)
        })
      }
      else if (values.type === 'provider') {
        fetchProvider(values.id).then(({ body }) => {
          setFieldValue('image_url', body.avatar_url)
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, setFieldValue])

  return (
    <Form className={classes.root}>
      <Grid item xs={12} className={classes.titleContanier}>
        <h2>Connect with workforce</h2>
      </Grid>

      <RadioGroup aria-label='position' name='position' value={userType} onChange={event => setUserType(event.target.value)} row>
        <FormControlLabel
          value='customer'
          control={
            <Radio color='primary' />
          }
          label='Customer'
        />

        <FormControlLabel
          value='provider'
          control={
            <Radio color='primary' />
          }
          label='Provider'
        />
      </RadioGroup>

      <Grid item xs={12} className={classes.fieldContainer}>
        { userType === 'customer' && <Field noLabel name='id' component={ResourceSelect} schema={customerSchema} /> }
        { userType === 'provider' && <Field noLabel name='id' component={ResourceSelect} schema={providerSchema} /> }
      </Grid>

      {
        dirty && (
          <Grid item xs={12} container justify='flex-end'>
            <ButtonLoader
              color='primary'
              variant='contained'
              onClick={handleSubmit}
              isLoading={isSubmitting}
            >
              Save
            </ButtonLoader>
          </Grid>
        )
      }
    </Form>
  )
}

MergeFirebaseUserForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  isSubmitting: PropTypes.bool,
  dirty: PropTypes.bool,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func
}

export default withStyles(styles)(MergeFirebaseUserForm)
