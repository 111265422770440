export default (theme) => ({
  root: {
    textDecoration: 'none !important',
    minWidth: 0
  },

  selected: {
    fontWeight: 600
  }
})
