import React, { useState, useMemo } from 'react'
import { Grid } from '@material-ui/core'

// import InputField from 'components/fields/InputField'
import { Field } from 'components/fields/Field'
import NativeSelectInput from 'components/inputs/NativeSelectInput'

import './SlotTimeFilterInput.scss'

const initArrayTime = (n) => {
  return [...Array(n).keys()].map(a => (
    {
      label: `${a}`.padStart(2, '0'),
      value: `${a}`.padStart(2, '0')
    }
  ))
}

function SlotTimeFilterInput({ t, setFieldValue, formValues }) {
  const { from = '', to = '' } = formValues.date
  const from1 = from.substring(0, 2)
  const from2 = from.substring(3, 5)
  const to1 = to.substring(0, 2)
  const to2 = to.substring(3, 5)

  const [timeData, setTimeData] = useState({
    timeFromHrs: from1,
    timeFromMin: from2,
    timeToHrs: to1,
    timeToMin: to2
  })
  const handleSetField = (key, value) => {
    setFieldValue('date', { ...formValues.date, [key]: value })
  }
  // const handleFieldChange = (e) => {
  //   handleSetField(e.target.name, e.target.value)
  // }
  const handleTimeChange = (e) => {
    setTimeData({ ...timeData, [e.target.name]: e.target.value })
  }

  useMemo(() => {
    if (timeData.timeFromHrs &&
      (timeData.timeFromHrs !== from1 || timeData.timeFromMin !== from2)) {
      handleSetField('from', `${timeData.timeFromHrs}:${timeData.timeFromMin || '00'}`)
    }
    if (timeData.timeToHrs &&
      (timeData.timeToHrs !== to1 || timeData.timeToMin !== to2)) {
      handleSetField('to', `${timeData.timeToHrs}:${timeData.timeToMin || '00'}`)
    }
  }, [timeData])

  return (
    <div className='slot-times-container'>
        <Grid className='slot-times-topic' container>{t('choose_finish_time_job')}</Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <Field
              name='timeFromHrs'
              component={NativeSelectInput}
              options={initArrayTime(24)}
              inputLabel={t('start_finish_time')}
              placeholder={t('hour')}
              noLabel
              value={from1}
              onChange={handleTimeChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field
              name='timeFromMin'
              component={NativeSelectInput}
              options={initArrayTime(60)}
              placeholder={t('minute')}
              noLabel
              value={from2}
              onChange={handleTimeChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field
              name='timeToHrs'
              component={NativeSelectInput}
              inputLabel={t('end_finish_time')}
              options={initArrayTime(24)}
              placeholder={t('hour')}
              noLabel
              value={to1}
              onChange={handleTimeChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field
              name='timeToMin'
              component={NativeSelectInput}
              options={initArrayTime(60)}
              placeholder={t('minute')}
              noLabel
              value={to2}
              onChange={handleTimeChange}
            />
          </Grid>
          {/* Slot time */}
          {/* <Grid item xs={12} sm={6}>
            <InputField
              label={t('start_finish_time')}
              type='time'
              name='from'
              variant='outlined'
              size='small'
              onChange={handleFieldChange}
              value={formValues.date.from}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              label={t('end_finish_time')}
              type='time'
              name='to'
              variant='outlined'
              size='small'
              onChange={handleFieldChange}
              value={formValues.date.to}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid> */}
        </Grid>
    </div>
  )
}

export default SlotTimeFilterInput
