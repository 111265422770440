import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Button, Grid, InputBase, MenuItem, Select } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import NavigationBarPreview from 'modules/tenant/NavigationBarPreview'
import TenantGeneralConfigFormSection from 'modules/tenant/TenantGeneralConfigFormSection'
import TenantGeneralConfigEnableFunctionForm from 'modules/tenant/TenantGeneralConfigEnableFunctionForm'
import TenantGeneralConfigNavigationLinksFormSection from 'modules/tenant/TenantGeneralConfigNavigationLinksFormSection'

import Panel from 'components/containers/Panel'
import ButtonLoader from 'components/buttons/ButtonLoader'
import { Field, FastField } from 'components/fields/Field'
import DropzoneInput from 'components/inputs/DropzoneInput'
import TextCreatableSelect from 'components/inputs/TextCreatableSelect'
import ColorPicker from 'components/inputs/ColorPicker'

import styles from './TenantFormStyles'

function ColorAttributesFormSection({ name }) {
  const fields = ['main', 'main_gradient', 'light', 'dark', 'text']

  return (
    <>
      {fields.map((fieldName) => (
        <Grid key={fieldName} item xs={6} sm>
          <FastField name={`${name}.${fieldName}`} component={ColorPicker} />
        </Grid>
      ))}
    </>
  )
}

export function TenantForm({
  classes,
  t,
  isSubmitting,
  dirty,
  resetForm,
  tenant,
  locale,
  setLocale
}) {
  function handleSetLocale(e) {
    setLocale(e.target.value)
  }

  function actions() {
    return [
      <Select
        key='locale-select'
        value={locale}
        onChange={handleSetLocale}
        input={<InputBase />}
      >
        <MenuItem value='en'>
          <span role='img' aria-label='GB flag'>
            🇬🇧{' '}
          </span>
          English
        </MenuItem>
        <MenuItem value='th'>
          <span role='img' aria-label='Thai flag'>
            🇹🇭{' '}
          </span>
          ภาษาไทย
        </MenuItem>
      </Select>
    ]
  }

  return (
    <Form>
      <Panel title={t('tenant.details')} actions={actions()}>
        <Grid container className={classes.content} spacing={3}>
          <Grid item xs={12}>
            <Field name='name' initialValue={tenant.get('name')} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              name='light_logo'
              component={DropzoneInput}
              width={800}
              height={200}
              initialValue={tenant.get('light_logo_url')}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              name='dark_logo'
              component={DropzoneInput}
              width={800}
              height={200}
              initialValue={tenant.get('dark_logo_url')}
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <Field
              name='default_icon'
              component={DropzoneInput}
              width={200}
              height={200}
              initialValue={tenant.get('default_icon_url')}
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <Field
              name='active_icon'
              component={DropzoneInput}
              width={200}
              height={200}
              initialValue={tenant.get('active_icon_url')}
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <Field
              name='web_favicon'
              component={DropzoneInput}
              width={64}
              height={64}
              initialValue={tenant.get('web_favicon_url')}
            />
          </Grid>

          <Grid item xs={12}>
            <Field name='domains' component={TextCreatableSelect} />
          </Grid>

          <TenantGeneralConfigFormSection name='general_configuration_attributes' />
        </Grid>
      </Panel>

      <Panel title={t('function_enabled.header')}>
        <Grid container className={classes.content}>
          <TenantGeneralConfigEnableFunctionForm name='general_configuration_attributes' />
        </Grid>
      </Panel>

      <Panel title={t('primary_colors')}>
        <Grid container className={classes.content}>
          <ColorAttributesFormSection name='general_configuration_attributes.primary_color_attributes' />
        </Grid>
      </Panel>

      <Panel title={t('secondary_colors')}>
        <Grid container className={classes.content}>
          <ColorAttributesFormSection name='general_configuration_attributes.secondary_color_attributes' />
        </Grid>
      </Panel>

      <Panel title={t('tenant.navigation_bar_details')} actions={actions()}>
        <Grid container className={classes.content}>
          <Grid item xs={12}>
            <NavigationBarPreview />
          </Grid>

          <Grid item xs={12}>
            <TenantGeneralConfigNavigationLinksFormSection
              name='general_configuration_attributes.navigation_links_attributes'
              index={0}
            />

            <TenantGeneralConfigNavigationLinksFormSection
              name='general_configuration_attributes.navigation_links_attributes'
              index={1}
            />

            <TenantGeneralConfigNavigationLinksFormSection
              name='general_configuration_attributes.navigation_links_attributes'
              index={2}
            />
          </Grid>
        </Grid>
      </Panel>

      {dirty && (
        <Grid item xs={12}>
          <ButtonLoader
            type='submit'
            variant='contained'
            color='primary'
            style={{ float: 'right' }}
            isLoading={isSubmitting}
            className={classes.button}
          >
            {t('save')}
          </ButtonLoader>

          <Button
            type='button'
            variant='contained'
            onClick={() => resetForm()}
            className={classes.button}
          >
            {t('cancel')}
          </Button>
        </Grid>
      )}
    </Form>
  )
}

TenantForm.propTypes = {
  tenant: ImmutablePropTypes.map,
  locale: PropTypes.string,
  setLocale: PropTypes.func,
  classes: PropTypes.object,
  isSubmitting: PropTypes.bool,
  dirty: PropTypes.bool,
  resetForm: PropTypes.func,
  t: PropTypes.func
}

TenantForm.defaultProps = {
  tenant: Map()
}

export default compose(
  withStyles(styles),
  withTranslation(['settings', 'actions', 'attributes'])
)(TenantForm)
