import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'
import { Map } from 'immutable'
import { Link } from 'react-router-dom'

import CircularLoader from 'components/CircularLoader'

import SubscriptionsTable from 'components/tables/SubscriptionsTable'
import Panel from 'components/containers/Panel'

export class CustomerSubscriptionsPane extends React.Component {
  static propTypes = {
    customer: ImmutablePropTypes.map,
    overview: PropTypes.bool,
    match: PropTypes.object,
    loadSubscriptions: PropTypes.func,
    t: PropTypes.func
  }

  static defaultProps = {
    customer: Map()
  }

  componentDidMount () {
    this.props.loadSubscriptions(this.props.match.params.id)
  }

  actions () {
    if (this.props.overview) {
      return null
    }
    else {
      return [
        <Link
          key='1'
          className='button button-primary panel-actions'
          to={`/customers/${this.props.match.params.id}/subscriptions/new`}>New Subscription</Link>
      ]
    }
  }

  render () {
    const { customer, t } = this.props

    const subscriptions = customer.get('subscriptions')

    return (
      <Panel title={t('subscriptions')} actions={this.actions()}>
        {
          !subscriptions && <CircularLoader />
        }

        {
          subscriptions && !subscriptions.isEmpty()
            ? <SubscriptionsTable subscriptions={subscriptions} />
            : <p>{t('subscriptions_empty')}</p>
        }
      </Panel>
    )
  }
}

export default withTranslation('customers')(CustomerSubscriptionsPane)
