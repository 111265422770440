import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import AsyncSelect from 'react-select/lib/Async'

export class ResourceSelect extends React.Component {
  static propTypes = {
    schema: PropTypes.object.isRequired,
    parentSchema: PropTypes.object,
    parentId: PropTypes.number,
    moreOptions: PropTypes.object,
    lastOptions: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    isMulti: PropTypes.bool,
    onChange: PropTypes.func,
    fetchOptions: PropTypes.func,
    fetchResource: PropTypes.func,
    fetchInitialValueWithIds: PropTypes.func,
    styles: PropTypes.object
  }

  static defaultProps = {
    styles: {},
    schema: {},
    parentSchema: {},
    parentId: null
  }

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)

    this.state = {
      value: null
    }
  }

  componentDidMount() {
    const {
      isMulti,
      value,
      fetchInitialValueWithIds,
      fetchResource,
      schema,
      parentSchema,
      moreOptions,
      parentId
    } = this.props

    if (isMulti && value) {
      this.setState({ value: [] })

      fetchInitialValueWithIds(schema, parentSchema, parentId, value).then(({ body }) => {
        if (value && value.length > 0) {
          this.setState({ value: body })
        }
      })
    } else if (value) {
      moreOptions
        ? moreOptions[0].id !== value &&
          fetchResource(value, schema).then((response) =>
            this.setState({ value: response.body })
          )
        : fetchResource(value, schema).then((response) =>
            this.setState({ value: response.body })
          )
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isMulti,
      value,
      fetchInitialValueWithIds,
      schema,
      parentSchema,
      parentId
    } = this.props

    if (typeof value !== 'object') {
      if (prevProps.value !== value) {
        if (isMulti && value) {
          fetchInitialValueWithIds(schema, parentSchema, parentId, value).then(
            ({ body }) => {
              if (value && value.length > 0) {
                this.setState({ value: body })
              }
            }
          )
        }
      }
    }
  }

  loadOptions = (query) => {
    const { moreOptions, lastOptions } = this.props

    if (moreOptions && lastOptions) {
      return this.props
        .fetchOptions(
          this.props.schema,
          query,
          this.props.parentSchema,
          this.props.parentId
        )
        .then(({ body }) => {
          const data = [...moreOptions, ...body, ...lastOptions]
          return data
        })
    } else {
      return moreOptions
        ? this.props
            .fetchOptions(
              this.props.schema,
              query,
              this.props.parentSchema,
              this.props.parentId
            )
            .then(({ body }) => {
              const data = [...moreOptions, ...body]
              return data
            })
        : lastOptions
        ? this.props
            .fetchOptions(
              this.props.schema,
              query,
              this.props.parentSchema,
              this.props.parentId
            )
            .then(({ body }) => {
              const data = [...body, ...lastOptions]
              return data
            })
        : this.props
            .fetchOptions(
              this.props.schema,
              query,
              this.props.parentSchema,
              this.props.parentId
            )
            .then(({ body }) => body)
    }
  }

  handleChange(data) {
    const { onChange, isMulti } = this.props

    this.setState({ value: data })

    if (data !== null && typeof data.id === 'string') {
      onChange(data)
    } else {
      onChange(isMulti ? data.map((option) => option.id) : get(data, 'id', undefined))
    }
  }

  render() {
    const { isMulti, styles, ...rest } = this.props

    return (
      <AsyncSelect
        loadOptions={this.loadOptions}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          ...styles
        }}
        cacheOptions
        defaultOptions
        isClearable
        isMulti={isMulti}
        getOptionLabel={({ name, display_name: displayName }) => name || displayName}
        getOptionValue={({ id }) => id}
        {...rest}
        onChange={this.handleChange}
        value={this.state.value}
      />
    )
  }
}

export default ResourceSelect
