export default theme => ({
  root: {
    textDecoration: 'none !important',
    minWidth: 0,
    fontSize: 12.5
  },

  selected: {
    fontWeight: 600
  },

  badge: {
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
})
