export default ({ spacing }) => ({
  phoneIcon: {
    fontSize: 12,
    margin: `0 ${spacing(0.5)}px -2px 0`
  },

  emailIcon: {
    fontSize: 14,
    margin: `0 ${spacing(0.5)}px -2px ${spacing(1)}px`
  }
})
