/* eslint-disable space-before-function-paren */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import { Grid, Button } from '@material-ui/core'
import {
  Field,
  Formik,
  Form,
  //  FieldArray,
  ErrorMessage
} from 'formik'
import * as yup from 'yup'

import { useSelector } from 'react-redux'

import Refund from '../refund/index'
import UploadFile from '../uploadfile/index'
import Signature from '../signature/index'

import Modal from 'components/containers/Modal'
import { FastField } from 'components/fields/Field'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'
import CircularLoader from 'components/CircularLoader'
import { useHistory } from 'react-router-dom'

import moment from 'moment'

import './creditnotescreate.scss'

import callService from 'pages/settings/panes/HelpAndSupport/callService'

const create = () => {
  let currentPin = 0
  let currentPinBranch = 0
  const { t } = useTranslation(['navbar', 'creditnote'])
  const history = useHistory()
  const redux = useSelector((state) => state)
  const [pin, setPin] = useState(new Array(13).fill(''))
  const [pinOther, setPinOther] = useState('')
  const [activePin, setActivePin] = useState(0)
  const [pinFocus, setFocusPin] = useState(false)

  const [pinBranch, setPinBranch] = useState(new Array(4).fill(''))
  const [activePinBranch, setActivePinBranch] = useState(0)
  const [pinBranchFocus, setPinBranchFocus] = useState(false)

  const [refundOpenModal, setRefundOpenModal] = useState(false)

  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [openModalSuccess, setOpenModalSuccess] = useState(false)
  const [successLoading, setSuccessLoading] = useState(false)

  const tanantId = redux.toJS().cancellation.tenantId
  const displayType = ['ordinary', 'jurastic']
  const displayTypeName = ['Mr.', 'Mrs.', 'Ms.']
  const [open, setOpen] = useState(false)
  const inputRef = useRef(null)
  const inputRefBranch = useRef(null)

  const initialValues = {
    picked: 'thai',
    taxId: '',
    branch: {
      pick: '',
      number_branch: '',
      name_branch: ''
    },
    detail: {
      legal_type: '',
      full_name: '',
      select_gender: '',
      first_Name: '',
      last_Name: ''
    },
    register: {
      address: '',
      district: '',
      sub_district: '',
      city: '',
      country: '',
      post_code: ''
    },
    contact_detail: {
      email: '',
      number: ''
    },
    refund_detail: {
      customer_id: 0,
      invoiceID: '',
      invoiceNo: '',
      totalTax: 0,
      issue_date: '',
      issue_date_select: '',
      credit_note_info: '',
      other_reason: '',
      detail: [
        {
          job_id: 0,
          job_number: '',
          job_detail: '',
          unit: '',
          unit_price: '',
          price: '',
          vat: 7,
          withholding_tax: '',
          openTagSuggest: false
        }
      ]
    },
    upload_file: {
      create_at: `${moment(new Date()).format('DD/MM/YYYY HH:mm')}`,
      fileSelect: []
    },
    signature: {
      file: '',
      first_name: '',
      last_name: ''
    },
    summary: {
      totalAmountTax: 0,
      newAmount: 0,
      variance: 0,
      vatAmount: 0,
      netAmount: 0,
      whtTax: 0,
      totalAmount: 0
    }
  }

  const ValidationSchema = yup.object().shape({
    detail: yup.object().shape({
      legal_type: yup.string().required('Business is required.').nullable(),
      full_name: yup.string().when('legal_type', {
        is: 'jurastic',
        then: yup.string().required('Contact name is required.').nullable()
      }),
      select_gender: yup.string().when('legal_type', {
        is: 'ordinary',
        then: yup.string().required('Title is required.').nullable()
      }),
      first_Name: yup.string().when('legal_type', {
        is: 'ordinary',
        then: yup.string().required('First name is required.').nullable()
      }),
      last_Name: yup.string().when('legal_type', {
        is: 'ordinary',
        then: yup.string().required('Last name is required.').nullable()
      })
    }),

    refund_detail: yup.object().shape({
      detail: yup
        .array(
          yup.object({
            job_detail: yup.string().required('Please enter a detail.').nullable(),
            unit_price: yup
              .string()
              .matches(/^[0-9]*(\.[0-9]{0,2})?$/, '2 Digit')
              .nullable()
          })
        )
        .min(1, 'Detail is required.')
        .nullable(),
      invoiceID: yup.string().required('Invoice no is required.').nullable(),
      issue_date: yup.string().required('Invoice issue date is required.').nullable(),
      issue_date_select: yup
        .string()
        .required('Select issue date no is required.')
        .nullable(),
      credit_note_info: yup.string().required('Credit Note info is required.').nullable(),
      other_reason: yup.string().when('credit_note_info', {
        is: 'other_reason',
        then: yup.string().required('Description of reason is required.').nullable()
      })
    }),
    upload_file: yup.object().shape({
      create_at: yup
        .string()
        .required('Capture at is required.')
        .matches(
          /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d (((([0-1][0-9])|(2[0-3])):?[0-5][0-9]$))$/,
          'Please make it like an example.'
        )
        .nullable(),
      fileSelect: yup.array().max(100, 'File exceeded').nullable()
    }),
    signature: yup.object().shape({
      file: yup.string().required('Signature is required.').nullable(),
      first_name: yup.string().required('First name is required.').nullable(),
      last_name: yup.string().required('Last name is required.').nullable()
    })
  })

  const callServiceSubmit = async (values) => {
    setSuccessLoading(true)
    const formData = new FormData()
    formData.append('recipient_type', 'Customer')
    formData.append('recipient_id', values.refund_detail.customer_id)
    formData.append('customer_nationality', values.picked)
    formData.append('customer_tax_id', values.taxId)
    formData.append('customer_branch_type', values.branch.pick)
    formData.append('customer_branch_id', values.branch.number_branch)
    formData.append('customer_branch_name', values.branch.name_branch)
    formData.append('customer_legal_type', values.detail.legal_type)
    formData.append('customer_title', values.detail.select_gender)
    formData.append('customer_name', values.detail.full_name)
    formData.append('customer_first_name', values.detail.first_Name)
    formData.append('customer_last_name', values.detail.last_Name)
    formData.append('registered_address', values.register.address)
    formData.append('registered_district', values.register.district)
    formData.append('registered_sub_district', values.register.sub_district)
    formData.append('registered_city', values.register.city)
    formData.append('registered_country', values.register.country)
    formData.append('registered_postcode', values.register.post_code)
    formData.append('contact_email', values.contact_detail.email)
    formData.append('contact_number', values.contact_detail.number)
    formData.append('authorized_signature', values.signature.file)
    formData.append('authorized_first_name', values.signature.first_name)
    formData.append('authorized_last_name', values.signature.last_name)
    // formData.append('refund_reason', values.refund_detail.credit_note_info)
    formData.append('refund_reason_other', values.refund_detail.other_reason)
    values.refund_detail.detail.map((data, k) =>
      formData.append(`refund_jobs[${k}][job_id]`, data.job_id)
    )
    values.refund_detail.detail.map((data, k) =>
      formData.append(`refund_jobs[${k}][job_number]`, data.job_number)
    )
    values.refund_detail.detail.map((data, k) =>
      formData.append(`refund_jobs[${k}][job_detail]`, data.job_detail)
    )
    values.refund_detail.detail.map((data, k) =>
      formData.append(`refund_jobs[${k}][unit]`, parseInt(data.unit))
    )
    values.refund_detail.detail.map((data, k) =>
      formData.append(`refund_jobs[${k}][unit_price]`, parseInt(data.unit_price))
    )
    values.refund_detail.detail.map((data, k) =>
      formData.append(`refund_jobs[${k}][vat]`, data.vat)
    )
    values.refund_detail.detail.map((data, k) =>
      data.withholding_tax
        ? formData.append(
            `refund_jobs[${k}][withholding_tax]`,
            parseInt(data.withholding_tax)
          )
        : formData.append(`refund_jobs[${k}][withholding_tax]`, 0)
    )
    formData.append('tenant_id', tanantId)
    formData.append('invoice_id', values.refund_detail.invoiceID)
    formData.append('invoice_no', values.refund_detail.invoiceNo)
    formData.append('tax_invoices_total_amount', values.refund_detail.totalTax)
    formData.append(
      'issue_date',
      moment(values.refund_detail.issue_date_select).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    )
    values.upload_file.fileSelect.map((data, k) =>
      formData.append('credit_note_transfer_slips', data)
    )
    formData.append(
      'transfer_slip_captured_at',
      moment(values.upload_file.create_at, 'dd/mm/yyyy HH:mm').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      )
    )
    const response = await callService(
      'POST',
      `${process.env.REACT_APP_CANCELLATION_BASE_API}/api/credit-notes/admin`,
      formData,
      null
    )
    if (response.status === 200 || response.status === 201) {
      setOpenModalConfirm(false)
      setOpenModalSuccess(true)
      setTimeout(() => {
        setOpenModalSuccess(false)
        setSuccessLoading(false)
        history.push(`/creditnotes/${response.data.data.id}`)
      }, 3000)
    }
  }

  const changeValue = (event, id, action) => {
    const value = event.target.value
    const regex = /^[0-9]*$/
    const checkValue = regex.test(value)
    const newPin = [...pin]
    newPin[id] = value.substring(value.length - 1)
    currentPin = id
    if (checkValue) {
      if (!value) {
        setActivePin(currentPin - 1)
      } else {
        setActivePin(currentPin + 1)
      }
      setPin(newPin)
    }
    setPinOther(newPin.join().replace(/,/g, ''))
    action.setFieldValue('taxId', newPin.join(''))
  }

  const checkType = (event, id) => {
    const key = event.key
    currentPin = id
    if (key === 'Backspace') {
      setActivePin(currentPin - 1)
    }
  }

  const renderTaxCss = (id) => {
    if (id === 0 || id === 4 || id === 9) {
      return 'mr-2'
    }
  }

  const changeValueBranch = (event, id, action) => {
    const value = event.target.value
    const regex = /^[0-9]*$/
    const checkValue = regex.test(value)
    const newPinBranch = [...pinBranch]
    newPinBranch[id] = value.substring(value.length - 1)
    currentPinBranch = id
    if (checkValue) {
      if (!value) {
        setActivePinBranch(currentPinBranch - 1)
      } else {
        setActivePinBranch(currentPinBranch + 1)
      }
      setPinBranch(newPinBranch)
    }
    action.setFieldValue('branch.number_branch', newPinBranch.join(''))
  }

  const checkTypeBranch = (event, id) => {
    const key = event.key
    currentPinBranch = id
    if (key === 'Backspace') {
      setActivePin(currentPinBranch - 1)
    }
  }

  const switchTaxId = (value, action) => {
    const regex = /^[0-9]*$/
    const checkValue = regex.test(value)
    if (checkValue) {
      const newPinOther = value.split('')
      const result = newPinOther.concat(new Array(13 - newPinOther.length).fill(''))
      setPin(result)
      setPinOther(value)
      action.setFieldValue('taxId', value)
    }
  }

  const changeBase = (dataSelect, action) => {
    action.setFieldValue('detail.select_gender', dataSelect)
    setOpen(false)
  }

  const checkErrors = (action) => {
    const check = Object.keys(action.errors).length === 0
    if (!check) {
      setOpenModalConfirm(false)
    }
    action.submitForm()
  }

  useEffect(() => {
    if (pinBranchFocus) {
      // eslint-disable-next-line no-unused-expressions
      inputRefBranch.current?.focus()
    }
  }, [pinBranchFocus, activePinBranch])

  useEffect(() => {
    if (pinFocus) {
      // eslint-disable-next-line no-unused-expressions
      inputRef.current?.focus()
    }
  }, [pinFocus, activePin])

  useEffect(() => {
    if (refundOpenModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [refundOpenModal])

  return (
    <PageLayout title={t('createcreditnote')} back='/creditnotes'>
      <div>
        <div className='section-nationality'>
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={ValidationSchema}
              onSubmit={callServiceSubmit}
            >
              {(formik) => (
                <Form>
                  <div className='bg-section-customer-detail'>
                    <div>
                      <div className='topic-credit-note'>{t('customer_detail')}</div>
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div className='section-box-first'>
                          <div className='text-nation'>{t('customer_nation')}</div>
                          <div className='section-radio-1'>
                            <Field type='radio' name='picked' value='thai' />
                            <p>{t('thai')}</p>
                          </div>
                          <div className='section-radio-1'>
                            <Field type='radio' name='picked' value='others' />
                            <p>{t('others')}</p>
                          </div>
                        </div>
                        <div className='text-topic-id-custom-create'>{t('taxId')}</div>
                        {formik.values.picked === 'thai' && (
                          <div className='section-taxid-custom mt-2'>
                            {pin.map((_, k) => {
                              return (
                                <div key={k} className={`${renderTaxCss(k)}`}>
                                  <input
                                    ref={k === activePin ? inputRef : null}
                                    value={pin[k]}
                                    type='text'
                                    className='legal-tax-input'
                                    placeholder='X'
                                    onChange={(e) => changeValue(e, k, formik)}
                                    onKeyDown={(e) => checkType(e, k)}
                                    onFocus={() => setFocusPin(true)}
                                  />
                                </div>
                              )
                            })}
                          </div>
                        )}
                        {formik.values.picked === 'others' && (
                          <div className='section-taxid-custom mt-2'>
                            <input
                              onChange={(e) => switchTaxId(e.target.value, formik)}
                              type='text'
                              value={pinOther}
                              maxLength='13'
                              className='legal-input tax-id-input'
                              placeholder={t('please_fill.taxID')}
                            />
                          </div>
                        )}
                        <div className='section-box-first mt-1'>
                          <div className='text-nation'>{t('customer_branch')}</div>
                          <div className='section-radio-1'>
                            <Field type='radio' name='branch.pick' value='' />
                            <p>{t('none')}</p>
                          </div>
                          <div className='section-radio-1'>
                            <Field type='radio' name='branch.pick' value='headoffice' />
                            <p>{t('head_office')}</p>
                          </div>
                          <div className='section-radio-1'>
                            <Field type='radio' name='branch.pick' value='branch' />
                            <p>{t('branch_office')}</p>
                          </div>
                        </div>
                        {formik.values.branch.pick && (
                          <div className='py-16'>
                            <div className='d-flex'>
                              {pinBranch.map((_, l) => {
                                return (
                                  <div key={l}>
                                    <input
                                      ref={l === activePinBranch ? inputRefBranch : null}
                                      value={pinBranch[l]}
                                      type='text'
                                      className='legal-tax-input'
                                      placeholder='X'
                                      onChange={(e) => changeValueBranch(e, l, formik)}
                                      onKeyDown={(e) => checkTypeBranch(e, l)}
                                      onFocus={() => setPinBranchFocus(true)}
                                    />
                                  </div>
                                )
                              })}
                              <div className='section-name-branch'>
                                <Field
                                  type='text'
                                  name='branch.name_branch'
                                  autoComplete='off'
                                  className='legal-input'
                                  placeholder={t('please_fill.branch')}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='mt-1'>
                          <div className='text-detail-legal mb-2'>
                            {t('business_detail')}
                          </div>
                          <Grid container spacing={3} className='mt-1'>
                            <Grid item xs={12} sm={5}>
                              <div>
                                <span className='text-legal-type'>
                                  {t('business_type')}
                                </span>
                                <span className='dot-legal'>*</span>
                              </div>
                              <div className='mt-1'>
                                <FastField
                                  name='detail.legal_type'
                                  component={SelectInput}
                                  options={arrayToOptions(displayType, {
                                    i18nNamespace: 'creditnote',
                                    i18nKey: 'creditnote_choice'
                                  })}
                                  onChange={(value) => {
                                    formik.setFieldValue('detail.legal_type', value)
                                  }}
                                  placeholder={`${t('please')}`}
                                  noLabel
                                />
                              </div>
                            </Grid>
                            {formik.values.detail.legal_type !== 'ordinary' && (
                              <Grid item xs={12} sm={6}>
                                <div>
                                  <span className='text-legal-type'>
                                    {t('contact_name')}
                                  </span>
                                  <span className='dot-legal'>*</span>
                                </div>
                                <div className='mt-1'>
                                  <Field
                                    type='text'
                                    name='detail.full_name'
                                    className='legal-input contact-name-bussiness'
                                    autoComplete='off'
                                    placeholder={`${t('please_fill.name')}`}
                                  />
                                </div>
                                <div className='text-validate mt-1'>
                                  <ErrorMessage
                                    name='detail.full_name'
                                    component='div'
                                    className='field-error'
                                  />
                                </div>
                              </Grid>
                            )}
                          </Grid>
                          <div className='mt-2'>
                            {formik.values.detail.legal_type === 'ordinary' && (
                              <Grid container spacing={3} className='mt-1'>
                                <Grid item xs={12} sm={3} lg={2}>
                                  <div>
                                    <span className='text-legal-type'>
                                      {t('contact_name')}
                                    </span>
                                  </div>
                                  <div className='mt-1'>
                                    <div className='section-select-choice custom-bottom'>
                                      <div
                                        className='input-select-custom-contact-name pointer custom-select-create'
                                        onMouseDown={() => {
                                          setOpen(true)
                                        }}
                                        tabIndex={0}
                                        onBlur={() => setOpen(false)}
                                      >
                                        <div>
                                          <Field
                                            name='detail.select_gender'
                                            className='input-select-set-fee font-fee create-custom-2'
                                            placeholder='Title'
                                            value={formik.values.detail.select_gender}
                                            autoComplete='off'
                                            disabled
                                          />
                                          <div className='icon'>
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='16.648'
                                              height='9.52'
                                              viewBox='0 0 16.648 9.52'
                                              className={`${
                                                open
                                                  ? 'animation-close'
                                                  : 'animation-open'
                                              }`}
                                            >
                                              <path
                                                id='Icon_ionic-ios-arrow-down'
                                                data-name='Icon ionic-ios-arrow-down'
                                                d='M14.515,17.9l6.3-6.3a1.185,1.185,0,0,1,1.68,0,1.2,1.2,0,0,1,0,1.685l-7.133,7.138a1.188,1.188,0,0,1-1.641.035L6.534,13.286A1.19,1.19,0,0,1,8.215,11.6Z'
                                                transform='translate(-6.188 -11.246)'
                                                fill='gray'
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                      {open && (
                                        <div>
                                          <div className='show-menu pointer'>
                                            {displayTypeName.map((data, n) => {
                                              return (
                                                <div
                                                  className='d-flex choice-custom-fee'
                                                  onMouseDown={(e) => {
                                                    e.preventDefault()
                                                    changeBase(data, formik)
                                                  }}
                                                  key={n}
                                                >
                                                  <div>{data}</div>
                                                </div>
                                              )
                                            })}
                                          </div>
                                          <div className='section-down-base' />
                                        </div>
                                      )}
                                      <div className='text-validate mt-1'>
                                        <ErrorMessage
                                          name='detail.select_gender'
                                          component='div'
                                          className='field-error'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={4} lg={3}>
                                  <div>
                                    <span className='dot-legal' />
                                  </div>
                                  <div className='mt-1'>
                                    <Field
                                      type='text'
                                      name='detail.first_Name'
                                      className='legal-input'
                                      autoComplete='off'
                                      placeholder={`${t('please_fill.FirstName')}`}
                                    />
                                  </div>
                                  <div className='text-validate mt-1'>
                                    <ErrorMessage
                                      name='detail.first_Name'
                                      component='div'
                                      className='field-error'
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={4} lg={3}>
                                  <div>
                                    <span className='dot-legal' />
                                  </div>
                                  <div className='mt-1'>
                                    <Field
                                      type='text'
                                      name='detail.last_Name'
                                      className='legal-input'
                                      autoComplete='off'
                                      placeholder={`${t('please_fill.LastName')}`}
                                    />
                                  </div>
                                  <div className='text-validate mt-1'>
                                    <ErrorMessage
                                      name='detail.last_Name'
                                      component='div'
                                      className='field-error'
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className='section-register-address'>
                    <div className='head-topic-text mt-2'>{t('registered_address')}</div>
                    <div>
                      <Grid item xs={12} sm={8}>
                        <div className='mt-2'>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                              <div className='mb-1 text-topic-register-address'>
                                {t('register.address')}
                              </div>
                              <div>
                                <Field
                                  type='text'
                                  name='register.address'
                                  className='legal-input-2'
                                  autoComplete='off'
                                  placeholder={`${t('please_fill.address')}`}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div className='mb-1 text-topic-register-address'>
                                {t('register.district')}
                              </div>
                              <div>
                                <Field
                                  type='text'
                                  name='register.district'
                                  className='legal-input'
                                  autoComplete='off'
                                  placeholder={`${t('please_fill.district')}`}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div className='mb-1 text-topic-register-address'>
                                {t('register.sub_district')}
                              </div>
                              <div>
                                <Field
                                  type='text'
                                  name='register.sub_district'
                                  className='legal-input'
                                  autoComplete='off'
                                  placeholder={`${t('please_fill.sub_district')}`}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div className='mb-1 text-topic-register-address'>
                                {t('register.city')}
                              </div>
                              <div>
                                <Field
                                  type='text'
                                  name='register.city'
                                  className='legal-input'
                                  autoComplete='off'
                                  placeholder={`${t('please_fill.city')}`}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div className='mb-1 text-topic-register-address'>
                                {t('register.country')}
                              </div>
                              <div>
                                <Field
                                  type='text'
                                  name='register.country'
                                  className='legal-input'
                                  autoComplete='off'
                                  placeholder={`${t('please_fill.country')}`}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div className='mb-1 text-topic-register-address'>
                                {t('register.post_code')}
                              </div>
                              <div>
                                <Field
                                  type='text'
                                  name='register.post_code'
                                  className='legal-input'
                                  autoComplete='off'
                                  placeholder={`${t('please_fill.post_code')}`}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </div>
                  </div>

                  <div className='section-register-address'>
                    <div className='head-topic-text mt-2'>
                      {t('contact_detail.topic')}
                    </div>
                    <div>
                      <Grid item xs={12} sm={8}>
                        <div className='mt-2'>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <div className='mb-1 text-topic-register-address'>
                                {t('contact_detail.email')}
                              </div>
                              <div>
                                <Field
                                  type='text'
                                  name='contact_detail.email'
                                  className='legal-input'
                                  autoComplete='off'
                                  placeholder={`${t('contact_fill.email')}`}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div className='mb-1 text-topic-register-address'>
                                {t('contact_detail.number')}
                              </div>
                              <div>
                                <Field
                                  type='text'
                                  name='contact_detail.number'
                                  className='legal-input'
                                  autoComplete='off'
                                  placeholder={`${t('contact_fill.number')}`}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </div>
                  </div>
                  <div className='section-register-address '>
                    <div className='head-topic-text mt-2'>{t('refund_detail')}</div>
                    <div className='mt-2'>
                      <Refund
                        action={formik}
                        name='refund_detail.detail'
                        setRefundOpenModal={setRefundOpenModal}
                      />
                    </div>
                  </div>
                  <div className='section-register-address section-refund-main'>
                    <div className='head-topic-text mt-2'>{t('upload_file')}</div>
                    <div className='mt-2'>
                      <UploadFile action={formik} />
                    </div>
                  </div>
                  <div className='section-register-address section-refund-main'>
                    <div className='head-topic-text mt-2'>
                      {t('authen.topic')}
                      <span className='dot-legal'>*</span>
                    </div>
                    <div className='mt-2'>
                      <Signature action={formik} />
                    </div>
                  </div>
                  <div className='section-btn-create-credit-note'>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => setOpenModalConfirm(true)}
                      // disabled={!formik.values.refund_detail.invoiceID}
                    >
                      {t('btn.createcreditnote')}
                    </Button>
                  </div>
                  <Modal
                    isOpen={openModalConfirm}
                    onCloseModal={() => setOpenModalConfirm(false)}
                    className='section-custom-main-modal'
                  >
                    {successLoading ? (
                      <CircularLoader />
                    ) : (
                      <div className='custom-padding-modal'>
                        <div className='text-center'>
                          <div className='modal-topic'>
                            {t('modal.headcreditnote')}{' '}
                            {formik.values.refund_detail.invoiceID}
                          </div>
                          <div className='modal-content mt-3'>
                            {t('modal.titlecreditnote')}
                          </div>
                        </div>
                        <div className='d-flex align-item-center justify-content-center mt-2'>
                          <button
                            className='custom-btn-cancel-modal-2 d-flex align-item-center justify-content-center pointer'
                            onClick={() => setOpenModalConfirm((pre) => !pre)}
                          >
                            {t('btn.cancel')}
                          </button>
                          <button
                            className='custom-btn-cancel-modal-1 d-flex align-item-center justify-content-center pointer'
                            type='submit'
                            onClick={() => checkErrors(formik)}
                          >
                            {t('btn.continue')}
                          </button>
                        </div>
                      </div>
                    )}
                  </Modal>
                </Form>
              )}
            </Formik>
          </div>
          <Modal
            isOpen={openModalSuccess}
            onCloseModal={() => setOpenModalSuccess(false)}
            className='section-custom-main-modal-success'
          >
            <div className='d-flex align-item-center justify-content-between'>
              <div>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='32.94'
                  height='32.94'
                  viewBox='0 0 32.94 32.94'
                >
                  <path
                    d='M33.5,17.032A16.47,16.47,0,1,1,17.032.562,16.47,16.47,0,0,1,33.5,17.032ZM15.127,25.753l12.22-12.22a1.063,1.063,0,0,0,0-1.5l-1.5-1.5a1.063,1.063,0,0,0-1.5,0l-9.966,9.965L9.723,15.841a1.063,1.063,0,0,0-1.5,0l-1.5,1.5a1.063,1.063,0,0,0,0,1.5l6.907,6.907a1.063,1.063,0,0,0,1.5,0Z'
                    transform='translate(-0.562 -0.562)'
                    fill='#1b4171'
                  />
                </svg>
              </div>
              <div className='topic-success-modal'>{t('creditnote.success')}</div>
            </div>
          </Modal>
        </div>
      </div>
    </PageLayout>
  )
}

export default create
