import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { capitalize } from 'lodash'
import { Map } from 'immutable'

import { jobSchema } from '@seekster/schemas'

import { getResource } from 'selectors/resources'

import PageContent, { PageSection } from 'components/layouts/PageContent'
import Panel from 'components/containers/Panel'
import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'
import CustomerCard from 'components/cards/CustomerCard'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import PhoneNumberFormatter from 'components/formatters/PhoneNumberFormatter'
import JobsCalendar from 'modules/jobs/JobsCalendar'
// import useStyles from './styles'

const SLAPendingJobOverviewPane = (props) => {
  // const classes = useStyles()
  const job =
    useSelector((state) => getResource(state, props.match.params.id, jobSchema)) || Map()

  return (
    <PageContent>
      <PageSection position='center' borderRight>
        <Panel>
          <AttributesTable resource={job}>
            <Attribute name='overdue_sla' />
            <Attribute name='sla_time' />
            <Attribute name='booking_time' />
            <Attribute name='id' />
            <Attribute name='number' />
            <Attribute
              name='service'
              label='Service Name'
              render={(service = Map()) => {
                const region = service.get('regional_slug', '').split('-').shift()

                return (
                  <Link to={`/services/${service.get('id')}`}>
                    {service.get('name')} ({capitalize(region)})
                  </Link>
                )
              }}
            />
            <Attribute
              name='orders'
              render={(orders) => (
                <ul>
                  {orders &&
                    orders.map((order, index) => {
                      const pkg = order.get('package') || Map()

                      return (
                        <li key={index}>
                          <p>
                            {pkg.get('name')} x {order.get('quantity')} {pkg.get('unit')}
                          </p>
                        </li>
                      )
                    })}
                </ul>
              )}
            />
            <Attribute
              name='attachments'
              render={(attachments) =>
                attachments &&
                attachments.map((attachment, index) => (
                  <img
                    key={attachment.get('id')}
                    src={attachment.get('file_url')}
                    style={{ width: 'auto', height: '100px', maxWidth: '200px' }}
                    alt={`attachment ${index + 1}`}
                  />
                ))
              }
            />
            <Attribute name='location.contact_name' />
            <Attribute name='location.phone_number' component={PhoneNumberFormatter} />
            <Attribute name='location.full_address' />
            <Attribute name='ref' />
            <Attribute name='updated_at' component={DateTimeFormatter} />
            <Attribute name='created_at' component={DateTimeFormatter} />
            <Attribute name='remarks_for_provider' />
            <Attribute
              name='client.name'
              to={(inquiry) => `/clients/${inquiry.getIn(['client', 'id'])}`}
            />
          </AttributesTable>
        </Panel>
      </PageSection>
      <PageSection position='right'>
        <CustomerCard title='Customer' customer={job.get('customer')} />
        <Panel title='Jobs Calendar' noPadding>
          <JobsCalendar jobs={Map().set('jobs', job)} />
        </Panel>
      </PageSection>
    </PageContent>
  )
}

export default SLAPendingJobOverviewPane
