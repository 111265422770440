import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetchChildren } from 'actions/collections'
import { fetch, destroy } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { trigger } from 'actions/resourceEvent'
import { providerSchema, commentSchema } from '@seekster/schemas'

import withFlashMessage from 'hoc/withFlashMessage'

import ProviderShowPage from './ProviderShowPage'

const mapStateToProps = (state, { match }) => ({
  provider: getResource(state, match.params.id, providerSchema)
})

const mapDispatchToProps = {
  fetchProvider: id => fetch(id, providerSchema),
  updateProviderState: (id, event) => trigger(id, providerSchema, event),
  deleteProvider: id => destroy(id, providerSchema),
  fetchProviderComments: id => fetchChildren(providerSchema, id, commentSchema),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(withFlashMessage(ProviderShowPage))
