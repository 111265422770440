import { connect } from 'react-redux'

import { fetchChildren as fetchChildrenCollections } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { invoiceSchema, paymentSchema } from '@seekster/schemas'

import InvoiceOverviewPane from './InvoiceOverviewPane'

const mapStateToProps = (state, { match }) => ({
  invoice: getResource(state, match.params.id, invoiceSchema)
})

const mapDispatchToProps = {
  fetchPayments: (id, options) => fetchChildrenCollections(invoiceSchema, id, paymentSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceOverviewPane)
