import React from 'react'
import PropTypes from 'prop-types'
import { FilterAssociationTags } from '../../FilterAssociationTags'
import { useResource } from 'hooks/resources'
import { customerSchema } from '@seekster/schemas'
import { withQueryParser } from 'utils/withQueryParser'

export function FilterCustomerTags ({ query, onQueryPush }) {
  const [customer] = useResource(customerSchema, query.customer_id && query.customer_id)

  return query.customer_id ? <FilterAssociationTags
    content={`customer: ${customer.get('display_name')}`}
    name='customer_id'
    query={query}
    onQueryPush={onQueryPush}
  />
    : null
}

FilterCustomerTags.propTypes = {
  query: PropTypes.object,
  onQueryPush: PropTypes.func
}

export default (withQueryParser)(FilterCustomerTags)
