import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import AppBar from 'components/layouts/AppBar'
import SideNavDrawer from 'components/layouts/SideNavDrawer'
import { useSidebar } from 'hooks/interface'

import useStyles from './styles'
import ErrorBoundary from './ErrorBoundary'
import { callService } from 'utils/callService'

export const AppLayoutContext = React.createContext();

export function AppLayout({ children, ...rest }) {
  const { sidebarOpen } = useSidebar()
  const classes = useStyles({ sidebarOpen })
  const [generalConfig, setGeneralConfig] = useState()

  useEffect(() => {
    fetchGeneralConfiguration()
  }, [])

  const fetchGeneralConfiguration = async () => {
    const response = await callService.get(`${process.env.REACT_APP_BASE_API}/tenant/general_configuration`)
    if (response.status === 200) {
      setGeneralConfig({ ...response.data })
    }
  }

  return (
    <>
      <AppLayoutContext.Provider value={{ generalConfig }}>
        <div className={classes.toolbarSpacer} />
        <AppBar />
        <SideNavDrawer />

        <main className={classes.main}>
          <ErrorBoundary>
            {React.cloneElement(children, { ...rest })}
          </ErrorBoundary>
        </main>
      </AppLayoutContext.Provider>
    </>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node
}

export default AppLayout
