import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import ButtonLoader from 'components/buttons/ButtonLoader'
import RegionAreasForm from 'modules/regions/RegionAreasForm'
import SortableFieldArray from 'components/fields/SortableFieldArray'

import { LocalizedField, Field } from 'components/fields/Field'
import { Tabs, TabPane } from 'components/Tabs'

import styles from './RegionFormStyles'

export function RegionForm ({ classes, region, isSubmitting, t, fetchAreaTemplate }) {
  return (
    <Grid container justify='center' component={Form}>
      <Grid item xs={12} sm={9}>
        <Tabs
          contained
          scrollButtons='off'
          classes={{ flexContainer: classes.tabs }}
        >
          <TabPane label='Region Details'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <LocalizedField name='name' />
              </Grid>

              <Grid item xs={12}>
                <Field name='slug' />
              </Grid>
            </Grid>
          </TabPane>

          <TabPane label='Areas'>
            <SortableFieldArray
              name='areas_attributes'
              component={RegionAreasForm}
              fetchAreaTemplate={fetchAreaTemplate}
              noLabel
            />
          </TabPane>
        </Tabs>

        <ButtonLoader
          type='submit'
          variant='contained'
          color='primary'
          isLoading={isSubmitting}
          className={classes.saveButton}
          style={{ marginTop: '1rem' }}
        >
          {t('save')}
        </ButtonLoader>
      </Grid>
    </Grid>
  )
}

RegionForm.propTypes = {
  region: ImmutablePropTypes.map,
  classes: PropTypes.object,
  isSubmitting: PropTypes.bool,
  fetchAreaTemplate: PropTypes.func,

  t: PropTypes.func
}

RegionForm.defaultProps = {
  region: Map()
}

export default compose(withStyles(styles), withTranslation())(RegionForm)
