import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { createChild } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { invoiceSchema, commentSchema } from '@seekster/schemas'

import InvoiceCommentsPane from './InvoiceCommentsPane'

const mapStateToProps = (state, { match }) => ({
  invoice: getResource(state, match.params.id, invoiceSchema)
})

const mapDispatchToProps = {
  fetchInvoiceComments: (id) => fetchChildren(invoiceSchema, id, commentSchema),
  createInvoiceComment: (data, id) => createChild(data, invoiceSchema, id, commentSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCommentsPane)
