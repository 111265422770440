import moment from 'moment'
import { get } from 'lodash'

export const clientsQuery = ({
  search,
  official,
  platform,
  access_type,
  created_at
}) => {
  return {
    name_or_minimum_supported_version_or_latest_version_cont: search,

    official_eq: official,

    platform_eq: platform,

    access_type_eq: access_type,

    created_at_gteq: get(created_at, 'from')
      ? moment(created_at.from).startOf('day').toISOString(true)
      : undefined,
    created_at_lteq: get(created_at, 'to')
      ? moment(created_at.to).endOf('day').toISOString(true)
      : undefined
  }
}

export default clientsQuery
