import { connect } from 'react-redux'

import { fetch } from 'actions/resources'
import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { companySchema, customerSchema } from '@seekster/schemas'

import CompanyCustomersPane from './CompanyCustomersPane'

const mapStateToProps = (state, { match }) => ({
  company: getResource(state, match.params.id, companySchema)
})

const mapDispatchToProps = {
  loadCompany: (id, options) => fetch(id, companySchema, options),
  loadCompanyCustomers: (id, options) => fetchChildren(companySchema, id, customerSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCustomersPane)
