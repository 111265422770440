import moment from 'moment'
import { get } from 'lodash'

export const jobsQuery = ({
  search,
  state,
  inquiry_state,
  service_id,
  jobs_start_date,
  created_at,
  provider_id,
  customer_id
}) => {
  return {
    number_or_customer_first_name_or_customer_last_name_or_customer_phone_number_or_location_contact_name_or_location_phone_number_or_location_name_or_location_house_number_or_location_street_or_location_project_name_or_location_sub_district_or_location_district_or_location_province_or_providers_first_name_or_providers_last_name_cont: search,

    state_eq: state,

    inquiry_state_eq: inquiry_state,

    service_id_eq: service_id,

    job_providers_provider_id_eq: provider_id,

    customer_id_eq: customer_id,

    start_time_gteq: get(jobs_start_date, 'from')
      ? moment(jobs_start_date.from).startOf('day').toISOString(true)
      : undefined,
    start_time_lteq: get(jobs_start_date, 'to')
      ? moment(jobs_start_date.to).endOf('day').toISOString(true)
      : undefined,

    created_at_gteq: get(created_at, 'from')
      ? moment(created_at.from).startOf('day').toISOString(true)
      : undefined,
    created_at_lteq: get(created_at, 'to')
      ? moment(created_at.to).endOf('day').toISOString(true)
      : undefined
  }
}

export default jobsQuery
