import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { useTranslation } from 'react-i18next'

import Button from 'components/buttons/Button'
import GoogleLoginButton from 'components/buttons/OmniauthButtons/GoogleLoginButton'
import SignInForm from 'forms/resources/SignInForm'

import './SignInPage.scss'

const logoImage =
  'https://s3-ap-southeast-1.amazonaws.com/seekster-web/assets/images/workforce_logo_with_name_black.png'

export function SignInPage({
  tenant,
  fetchCurrentTenantBySlug,
  signIn,
  updateCurrentDevice,
  replace
}) {
  const { t } = useTranslation('authentication')

  useEffect(() => {
    fetchCurrentTenantBySlug()
  }, [fetchCurrentTenantBySlug])

  return (
    <div className='sign-in-background'>
      <Grid
        stretched
        container
        textAlign='center'
        alignItems='center'
        direction='row'
        justify='center'
        style={{ height: '100%', margin: 0 }}
      >
        <Grid direction='column' textAlign='center' alignItems='center'>
          <div className='sign-in-container'>
            <div className='details-container'>
              <img className='logo-image' src={logoImage} alt='logo' />

              <p>
                Workforce provides businesses with the digital capabilities of automating
                their workforce and task distribution processes.
              </p>

              <Button
                variant='outlined'
                primary
                as='a'
                href='http://workforce-saas.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                Learn More
              </Button>
            </div>

            <div className='form-container'>
              {tenant.get('dark_logo_url') && (
                <img
                  src={tenant.get('dark_logo_url')}
                  className='tenant-image'
                  alt='tenant-logo'
                />
              )}

              <h3>{t('sign_in')}</h3>

              <GoogleLoginButton />

              <div className='separator'>
                <h4>OR</h4>
              </div>

              <SignInForm
                onSubmit={signIn}
                updateCurrentDevice={updateCurrentDevice}
                replace={replace}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

SignInPage.propTypes = {
  tenant: ImmutablePropTypes.map,
  fetchCurrentTenantBySlug: PropTypes.func,
  signIn: PropTypes.func,
  updateCurrentDevice: PropTypes.func,
  replace: PropTypes.func
}

SignInPage.defaultProps = {
  tenant: Map()
}

export default SignInPage
