import { connect } from 'react-redux'

import { fetch, update } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { addressSchema } from '@seekster/schemas'

import AddressEditPage from './AddressEditPage'

const mapStateToProps = (state, { match }) => ({
  address: getResource(state, match.params.id, addressSchema)
})

const mapDispatchToProps = {
  fetchAddress: id => fetch(id, addressSchema),
  updateAddress: (id, data) => update(id, data, addressSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressEditPage)
