import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'formik'
import { Button, Grid, Paper, Typography, InputAdornment } from '@material-ui/core'
import { Field } from 'components/fields/Field'
import Panel from 'components/containers/Panel'

import styles from './SLASettingFormStyles'
import { withStyles } from '@material-ui/styles'

const SLASettingForm = ({ classes }) => {
  const { t } = useTranslation(['common', 'settings'])
  return (
    <Form>
      <div className={classes.panelShadow}>
        <Panel title={t('sla.title')} headerStyle={{ height: 80, maxHeight: 80 }} />
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.subtitle} variant='h6'>
                {t('sla.subtitle.sla_pending_start')}
              </Typography>
              <Field
                name='pending_start_time'
                type='number'
                min={0}
                endAdornment={
                  <InputAdornment position='end'>{t('minute')}</InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subtitle} variant='h6'>
                {t('sla.subtitle.sla_started')}
              </Typography>
              <Field
                name='pending_completion_time'
                type='number'
                endAdornment={
                  <InputAdornment position='end'>{t('minute')}</InputAdornment>
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
      <Grid container justify='flex-end'>
        <Grid item>
          <Button
            className={classes.button}
            type='submit'
            variant='contained'
            color='primary'
          >
            {t('sla.save')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}

export default withStyles(styles)(SLASettingForm)
