import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { Grid, CircularProgress } from '@material-ui/core'
import { approvedProviderSchema } from '@seekster/schemas'
import moment from 'moment'
import { debounce } from 'lodash'

import { useCollection } from 'hooks/collections'
import { useModal } from 'hooks/useModal'
import SelectInput from 'components/inputs/SelectInput'
import SwitchInput from 'components/inputs/SwitchInput.new'
import Button from 'components/buttons/Button'
import Field from 'components/fields/Field'

export function ProviderAssignmentWithFilterForm({
  jobIds,
  jobStartTimes,
  showToggle = false,
  isSubmitting
}) {
  const { t } = useTranslation('modal')
  const { closeModal } = useModal()
  const [options, setOptions] = useState({
    query: {
      search: ''
    },
    queryParam: {
      'job_ids[]': jobIds,
      not_overlap: true
    }
  })
  const [data, { loading }] = useCollection(approvedProviderSchema, options, [options])
  const jobStartTimeDateFormats = jobStartTimes.map((jobStart) =>
    moment(jobStart).format('MMM Do YY')
  )

  function validateRestDate(providerRestDates) {
    if (providerRestDates.length) {
      const filterDate = providerRestDates.filter((date) =>
        jobStartTimeDateFormats.includes(
          moment(date.get('start_time')).format('MMM Do YY')
        )
      )
      const result = filterDate.length ? '(มีวันหยุดตรงกับงานนี้)' : ''

      return result
    } else {
      return ''
    }
  }

  function handleToggle() {
    setOptions((prev) => ({
      ...prev,
      queryParam: {
        ...prev.queryParam,
        not_overlap: !prev.queryParam.not_overlap
      }
    }))
  }

  const handleSearchProvider = debounce((value, { action }) => {
    if (action === 'input-change') {
      setOptions((prev) => ({
        ...prev,
        query: {
          search: value
        }
      }))
    }
  }, 500)

  return (
    <Grid container spacing={3} component={Form}>
      {showToggle && (
        <Grid item xs={12}>
          <SwitchInput
            value={options.queryParam.not_overlap}
            switchLabel={t(
              `assign_provider.only_not_overlap.${options.queryParam.not_overlap}`
            )}
            onChange={handleToggle}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Field
          name='provider_id'
          isField
          isLoading={loading}
          options={data.map((provider) => ({
            value: provider.get('id'),
            label: `${provider.get('display_name')} ${validateRestDate(
              provider.get('provider_rest_dates')
            )}`
          }))}
          component={SelectInput}
          schema={approvedProviderSchema}
          onInputChange={handleSearchProvider}
          isClearable
        />
      </Grid>

      <Grid item xs={12} className='action'>
        <Button className='cancel-btn' onClick={closeModal} disabled={isSubmitting}>
          {t('assign_provider.cancel')}
        </Button>
        <Button color='primary' type='submit' disabled={isSubmitting}>
          {isSubmitting ? (
            <CircularProgress size={20} />
          ) : (
            <b>{t('assign_provider.confirm')}</b>
          )}
        </Button>
      </Grid>
    </Grid>
  )
}

ProviderAssignmentWithFilterForm.propTypes = {
  jobIds: PropTypes.array,
  jobStartTimes: PropTypes.array,
  showToggle: PropTypes.bool,
  isSubmitting: PropTypes.bool
}

ProviderAssignmentWithFilterForm.defaultProps = {
  jobIds: [],
  jobStartTimes: [],
  showToggle: false,
  isSubmitting: false
}

export default ProviderAssignmentWithFilterForm
