import React from 'react'
import { useTranslation } from 'react-i18next'

import { useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'
import { departmentSchema } from '@seekster/schemas'
import DepartmentForm from 'forms/resources/DepartmentForm'

import PageLayout from 'components/layouts/PageLayout'

export function DepartmentNewPage () {
  const { t } = useTranslation('departments')
  const { create: createDepartment } = useResourceAction(departmentSchema)
  const push = usePush()

  return (
    <PageLayout title={t('new')} back='/departments'>
      <DepartmentForm
        onSubmit={createDepartment}
        push={push}
      />
    </PageLayout>
  )
}

export default DepartmentNewPage
