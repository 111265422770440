import { connect } from 'react-redux'

import { fetchChildWithImplicitParent } from 'actions/resources'
import { fetch as fetchCollections } from 'actions/collections'
import { tenantSchema, accountingConfigurationSchema, bankAccountSchema } from '@seekster/schemas'

import AccountingSettingsPane from './AccountingSettingsPane'

const mapDispatchToProps = {
  fetchAccountingConfig: () => fetchChildWithImplicitParent(tenantSchema, accountingConfigurationSchema),
  fetchBankAccounts: (options) => fetchCollections(bankAccountSchema, options)
}

export default connect(null, mapDispatchToProps)(AccountingSettingsPane)
