export default ({ button }) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: 'green',
    position: 'absolute'
  }
})
