import React from 'react'
import PropTypes from 'prop-types'
import Cleave from 'cleave.js/react'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'

import Field from 'components/fields/Field'

export class DateTimeInputField extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    hint: PropTypes.string,

    label: PropTypes.string,
    t: PropTypes.func,
    tReady: PropTypes.bool,
    i18n: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.handleDateInit = this.handleDateInit.bind(this)
    this.renderCleave = this.renderCleave.bind(this)

    this.state = {
      cleave: null
    }
  }

  handleDateInit(cleave) {
    this.setState({ cleave })
  }

  renderCleave(props) {
    const { name, field, form, placeholder, label, tReady, i18n, ...rest } = props

    return (
      <Cleave
        {...field}
        placeholder={placeholder || props.t('placeholder.date_time_input')}
        options={{
          blocks: [2, 2, 4, 2, 2],
          delimiters: ['/', '/', ' ', ':'],
          numericOnly: true
        }}
        onInit={this.handleDateInit}
        value={field.value ? moment(field.value).format('DD-MM-YYYY HH:mm') : ''}
        {...rest}
      />
    )
  }

  render() {
    const { name, hint } = this.props

    return (
      <Field
        name={name}
        render={({ field, form }) => (
          <TextField
            fullWidth
            color='primary'
            variant='outlined'
            onChange={(e) => {
              if (
                moment(e.target.value, 'DD/MM/YYYY HH:mm').isValid(true) &&
                e.target.rawValue.toString().length === 12
              ) {
                form.setFieldValue(
                  name,
                  moment(e.target.value, 'DD/MM/YYYY HH:mm').toISOString(true)
                )
              }
            }}
            inputProps={{ ...this.props, field, form }}
            InputProps={{
              inputComponent: this.renderCleave
            }}
          />
        )}
        hint={hint || 'Example: 18/01/2019 22:47'}
      />
    )
  }
}

export default withTranslation(['fields', 'attributes'])(DateTimeInputField)
