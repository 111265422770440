import { withFormik } from 'formik'
import { Map } from 'immutable'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'
import AgentAssignmentForm from './AgentAssignmentForm'

const formikConfig = {
  mapPropsToValues: ({ ticket = Map() }) => ({
    id: ticket.get('assignee') || null,
    department: ticket.get('department') || false
  }),

  enableReinitialize: true,

  handleSubmit: (values, { props, setSubmitting }) => {
    if (values.department && values.id) {
      props.onSubmit(props.ticketId, `tenants/${props.tenant}/tickets`, { assignor: props.agentId, department: true, assignee: values.id, recipients_attributes: values.recipientsAttributes })
        .then(() => {
          const notiData = {
            type: 'GroupNotification',
            template_slug: 'assign_agent_to_ticket',
            url: `tickets/${props.ticketId}`,
            actor_id: props.agentId,
            actor_type: 'Agent',
            recipients_attributes: values.recipientsAttributes
          }
          props.createNoti(notiData)
        })
    }
    else if (values.id) {
      props.onSubmit(props.ticketId, `tenants/${props.tenant}/tickets`, { assignor: props.agentId, department: false, assignee: values.id })
        .then(() => {
          const notiData = {
            type: 'DirectNotification',
            template_slug: 'assign_agent_to_ticket',
            url: `tickets/${props.ticketId}`,
            actor_id: props.agentId,
            actor_type: 'Agent',
            recipient_attributes: {
              user_id: values.id,
              user_type: 'Agent'
            }
          }
          props.createNoti(notiData)
        })
    }
    else {
      props.onSubmit(props.ticketId, `tenants/${props.tenant}/tickets`, { assignor: null, department: false, assignee: false })
    }
    props.onCloseModal()
    setSubmitting(false)
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(AgentAssignmentForm)
