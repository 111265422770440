import request from 'utils/request'

import { SEND_INVITATION_SUCCEEDED } from 'constants/invitations'

const sendInvitationSucceeded = (id, response, schema, childrenSchema, options) => ({
  type: SEND_INVITATION_SUCCEEDED,
  id,
  response,
  schema,
  childrenSchema,
  options
})

export const sendInvitation = (schema, id, childrenSchema, options = {}) => (dispatch, getState) => {
  const type = options.type || schema._key
  const childrenType = options.childrenType || childrenSchema._key
  const locale = options.locale
  const accessToken = getState().getIn(['authentication', 'token'])

  return request
    .post(`/${type}/${id}/${childrenType}`)
    .locale(locale)
    .authentication(accessToken)
    .then(response => {
      dispatch(sendInvitationSucceeded(id, response, schema, childrenSchema, options))

      return response
    })
}

export default sendInvitation
