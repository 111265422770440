import { connect } from 'react-redux'

import { create } from 'actions/resources'
import { push } from 'react-router-redux'
import { appSchema } from '@seekster/schemas'

import AppNewPage from './AppNewPage'

const mapDispatchToProps = {
  createApp: data => create(data, appSchema),
  push
}

export default connect(null, mapDispatchToProps)(AppNewPage)
