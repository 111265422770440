import React from 'react'
import PropTypes from 'prop-types'
import { Field, getIn } from 'formik'
import { isUndefined } from 'lodash'
import { withTranslation } from 'react-i18next'

import MoneyInput from 'components/inputs/MoneyInput'

export class MoneyField extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    noLabel: PropTypes.bool,
    name: PropTypes.string,
    hint: PropTypes.string,

    t: PropTypes.func
  }

  render () {
    const { label, noLabel, t, name, hint } = this.props
    const amountName = `${name}_satangs`
    const currencyName = `${name}_currency`
    const labelName = name.split('.').pop()

    return (
      <Field
        name={name}
        render={({
          field,
          form: { values, touched, errors, handleChange, setFieldValue },
          ...props
        }) => {
          const amountValue = getIn(values, amountName)
          const currencyValue = getIn(values, currencyName)

          return (
            <div className='form-field'>
              {!noLabel && <label>{label || t([`label.${labelName}`, labelName])}</label>}

              <MoneyInput
                value={!isUndefined(amountValue) ? parseFloat(amountValue, 10) / 100 : ''}
                onChange={(e) => (
                  setFieldValue(amountName, !isUndefined(e.target.value) ? (parseFloat(e.target.value, 10) * 100).toFixed(0) : '')
                )}
                currencyValue={!isUndefined(currencyValue) ? currencyValue : ''}
                onCurrencyChange={value => setFieldValue(currencyName, value)}
              />

              {hint && <p className='field-hint'>{hint || t([`hint.${name}`, name])}</p>}
            </div>
          )
        }}
      />
    )
  }
}

export default withTranslation('attributes')(MoneyField)
