import { Map } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'
import ClientForm from './ClientForm'

const formikConfig = {
  mapPropsToValues: ({ client = Map() }) => ({
    name: client.get('name') || '',
    app_id: client.getIn(['app', 'id']) || '',
    platform: client.get('platform') || 'web',
    access_type: client.get('access_type') || 'customer',
    opener: client.get('opener') || '',
    official: client.get('official') || false,
    color: client.get('color') || '#000000',
    latest_version: client.get('latest_version') || '',
    minimum_supported_version: client.get('minimum_supported_version') || '',
    fcm_server_key: client.get('fcm_server_key') || '',
    scheme: client.get('scheme') || ''
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    app_id: Yup.number(),
    platform: Yup.string(),
    access_type: Yup.string(),
    official: Yup.bool(),
    color: Yup.string(),
    latest_version: Yup.string(),
    minimum_supported_version: Yup.string(),
    fcm_server_key: Yup.string(),
    scheme: Yup.string()
  }),

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values)
      .then(response => {
        props.push(`/clients/${response.body.id}`)
      })
      .catch(error => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(ClientForm)
