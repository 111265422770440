import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Select, InputBase, MenuItem } from '@material-ui/core'

import withLocalizedFields from 'hoc/withLocalizedFields'
import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import ServiceForm from 'forms/resources/ServiceForm'

export function ServiceEditPage({
  service,
  fetchService,
  updateService,
  push,
  match,
  locale,
  setLocale,
  t
}) {
  const { loading } = useData(() => fetchService(match.params.id))

  function handleSubmit(data) {
    return updateService(match.params.id, data)
  }

  function renderActions() {
    return [
      <Select
        key='1'
        value={locale}
        onChange={(e) => setLocale(e.target.value)}
        input={<InputBase />}
      >
        <MenuItem value='en'>
          <span role='img' aria-label='GB flag'>
            🇬🇧{' '}
          </span>
          English
        </MenuItem>
        <MenuItem value='th'>
          <span role='img' aria-label='Thai flag'>
            🇹🇭{' '}
          </span>
          ภาษาไทย
        </MenuItem>
      </Select>
    ]
  }

  return (
    <PageLayout
      title={t('edit')}
      secondaryActions={renderActions()}
      isLoading={loading}
      back={`/services/${match.params.id}`}
    >
      <ServiceForm service={service} push={push} onSubmit={handleSubmit} />
    </PageLayout>
  )
}

ServiceEditPage.propTypes = {
  service: ImmutablePropTypes.map,
  fetchService: PropTypes.func,
  updateService: PropTypes.func,
  push: PropTypes.func,
  match: PropTypes.object,
  locale: PropTypes.string,
  setLocale: PropTypes.func,

  t: PropTypes.func
}

ServiceEditPage.defaultProps = {
  service: Map()
}

export default compose(withLocalizedFields, withTranslation('services'))(ServiceEditPage)
