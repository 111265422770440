import { connect } from 'react-redux'
import { compose } from 'redux'
import { push } from 'react-router-redux'

import { withQueryParser } from 'utils/withQueryParser'
import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { getCollection } from 'selectors/collections'
import { inquirySchema, jobSchema, providerSchema } from '@seekster/schemas'

import InquiryJobsPane from './InquiryJobsPane'

const mapStateToProps = (state, { match }) => ({
  inquiry: getResource(state, match.params.id, inquirySchema),
  providers: getCollection(state, providerSchema)
})

const mapDispatchToProps = {
  fetchInquiryJobs: (id, options) => fetchChildren(inquirySchema, id, jobSchema, {
    ...options,
    queryParam: { order: { start_time: 'asc' } }
  }),
  push
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(InquiryJobsPane)
