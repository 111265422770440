import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Field from './Field'
import LocaleContext from 'contexts/LocaleContext'

export function LocalizedField({ name, noHint, ...rest }) {
  const { t } = useTranslation(['languages', 'fields', 'attributes'])
  const locale = useContext(LocaleContext)
  const labelName = name.split('.').pop()

  return (
    <Field
      key={`${name}_${locale}`}
      name={`${name}_${locale}`}
      label={t([`label.${labelName}`, labelName])}
      hint={!noHint && `${t(locale)} - ${locale}`}
      showTooltip
      {...rest}
    />
  )
}

LocalizedField.propTypes = {
  name: PropTypes.string.isRequired,
  noHint: PropTypes.bool
}

export default LocalizedField
