import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import Attribute from './Attribute'

export class LocalizedAttribute extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    lng: PropTypes.string,

    t: PropTypes.func,
    tReady: PropTypes.bool
  }

  render () {
    const { name, lng, t, tReady, ...rest } = this.props

    return (
      <Attribute
        name={`${name}_${lng}`}
        label={t(name)}
        {...rest}
      />
    )
  }
}

export default withTranslation('attributes')(LocalizedAttribute)
