import { withFormik } from 'formik'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'
import MergeFirebaseUserForm from './MergeFirebaseUserForm'

const formikConfig = {
  mapPropsToValues: () => ({
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(props.userId, `tenants/${props.tenant}/users`, { workforce: { ...values } })
      .then(() => {
        props.onSubmit(props.ticketId, `tenants/${props.tenant}/tickets`, { workforceId: values.id })
        props.onCloseModal()
        setSubmitting(false)
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(MergeFirebaseUserForm)
