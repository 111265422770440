import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ zIndex, breakpoints, transitions }) => ({
  appBar: {
    zIndex: zIndex.drawer + 1,
    width: '100%'
  },

  grow: {
    flexGrow: 1
  },

  logo: {
    display: 'block',
    height: '30px',
    '@media screen and (max-width: 767px)': {
      display: 'none'
    }
  },

  avatar: {
    height: '30px',
    width: '30px'
  }
}))
