import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import AddressesTable from 'components/tables/AddressesTable'

export class AddressIndexPage extends React.Component {
  static propTypes = {
    addresses: ImmutablePropTypes.list,
    loadAddresses: PropTypes.func,
    updatePage: PropTypes.func,
    t: PropTypes.func
  }

  static defaultProps = {
    addresses: List(),
    loadAddresses: () => {},
    updatePage: () => {}
  }

  componentDidMount () {
    this.props.loadAddresses()
  }

  handlePageChange (page) {
    this.props.updatePage(page)
    this.props.loadAddresses()
  }

  actions () {
    return [{
      content: this.props.t('new'),
      to: '/addresses/new'
    }]
  }

  render () {
    return (
      <PageLayout title={this.props.t('title')} primaryActions={this.actions()}>
        <AddressesTable addresses={this.props.addresses} />
      </PageLayout>
    )
  }
}

export default withTranslation('addresses')(AddressIndexPage)
