import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Grid, Typography, Button } from '@material-ui/core'
import { GetAppRounded } from '@material-ui/icons'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import Panel from 'components/containers/Panel'
import { Tabs, TabPane } from 'components/Tabs'
import AttributeLabel from 'components/labels/AttributeLabel'
import CircularLoader from 'components/CircularLoader'
import HtmlFormatter from 'components/formatters/HtmlFormatter'
import IntegrationConfigForm from 'forms/resources/IntegrationConfigForm'

import styles from './IntegrationShowPageStyles'

export function IntegrationShowPage ({
  integration,
  fetchIntegration,
  fetchIntegrationInstance,
  createIntegrationInstance,
  destroyIntegrationInstance,
  updateIntegrationConfigs,
  match,
  classes,
  t
}) {
  const { loading } = useData(() => {
    return handleUpdateData()
  })

  const [currentTab, setCurrentTab] = useState()

  function handleUpdateData () {
    fetchIntegration(match.params.id)

    return fetchIntegrationInstance(match.params.id)
  }

  function handleCreateIntegrationInstance () {
    const data = {
      integration_id: integration.get('id'),
      integration_configurations_attributes: integration.get('configuration_definitions').map(definition => ({
        definition_id: definition.get('id')
      })).toJS()
    }

    return createIntegrationInstance(data)
      .then(() => handleUpdateData())
      .then(() => setCurrentTab(1))
  }

  function handleDeleteIntegrationInstance () {
    if (window.confirm(t('uninstall_confirmation_prompt'))) {
      return destroyIntegrationInstance(match.params.id)
        .then(() => handleUpdateData())
    }
  }

  function handleUpdateIntegrationConfigs (data) {
    return updateIntegrationConfigs(match.params.id, data)
  }

  function renderActionButton () {
    if (integration.get('integration_instances')) {
      return (
        <Button
          variant='contained'
          className={classes.uninstallButton}
          fullWidth
          onClick={handleDeleteIntegrationInstance}
        >
          {t('uninstall')}
        </Button>
      )
    }
    else {
      return (
        <Button
          variant='contained'
          className={classes.installButton}
          fullWidth
          onClick={handleCreateIntegrationInstance}
        >
          <GetAppRounded fontSize='small' />
          {t('install')}
        </Button>
      )
    }
  }

  return (
    loading ? (
      <CircularLoader />
    ) : (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Panel title={t('details')}>
            <img
              src={integration.get('icon_url')}
              alt={`${integration.get('name')} icon`}
              className={classes.icon}
            />

            <AttributeLabel title={`ID ${integration.get('id')}`}>
              <Typography variant='h4'>
                {integration.get('name')}
              </Typography>
            </AttributeLabel>

            {renderActionButton()}
          </Panel>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Tabs
            contained
            scrollButtons='off'
            currentTab={integration.get('integration_instances') ? 1 : currentTab}
            classes={{ flexContainer: classes.tabs }}
          >
            <TabPane label={t('description')} className={classes.tab}>
              <HtmlFormatter value={integration.get('description')} />
            </TabPane>
            {
              integration.get('integration_instances') && (
                <TabPane label={t('configurations')} className={classes.tab}>
                  <IntegrationConfigForm
                    integrationConfigs={integration.getIn(['integration_instances', 'integration_configurations'])}
                    onSubmit={handleUpdateIntegrationConfigs}
                  />
                </TabPane>
              )
            }
          </Tabs>
        </Grid>
      </Grid>
    )
  )
}

IntegrationShowPage.propTypes = {
  integration: ImmutablePropTypes.map,
  fetchIntegration: PropTypes.func,
  fetchIntegrationInstance: PropTypes.func,
  createIntegrationInstance: PropTypes.func,
  destroyIntegrationInstance: PropTypes.func,
  updateIntegrationConfigs: PropTypes.func,
  match: PropTypes.object,
  classes: PropTypes.object,

  t: PropTypes.func
}

IntegrationShowPage.defaultProps = {
  integration: Map()
}

export default compose(
  withStyles(styles),
  withTranslation('integrations')
)(IntegrationShowPage)
