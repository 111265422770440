import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import DateTimePicker from 'react-datetime'
import Moment from 'moment'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'
import { Switch } from '@material-ui/core'
import { components } from 'react-select'
import { connect } from 'formik'
import { compose } from 'redux'

import Panel from 'components/containers/Panel'
import Field from 'components/fields/Field'
import MoneyField from 'components/fields/MoneyField'
import ResourceSelect from 'components/inputs/ResourceSelect'
import SelectInput from 'components/inputs/SelectInput'
import Tag from 'components/Tag'
import { CapitalizeFormatter } from 'components/formatters'
import { serviceSchema, packageSchema } from '@seekster/schemas'

import './PromoCodeLimitationsFormSection.scss'

export function PromoCodeLimitationsFormSection ({
  t, formik, values, promoCode, setFieldValue, setValues
}) {
  useEffect(() => {
    !values.service_ids && setValues({ service_ids: undefined, package_ids: undefined })
    !values.package_ids && setFieldValue('package_ids', undefined)
  }, [values.service_ids, values.package_ids]) // eslint-disable-line react-hooks/exhaustive-deps

  const [limitServices, setLimitServices] = useState(values.service_ids)
  const [limitPackages, setLimitPackages] = useState(values.package_ids)

  const choicesFirstTime = [
    { label: 'User', value: 'user' },
    { label: 'Service', value: 'service' }
  ]

  return (
    <Panel title={t('details.limitation')}>
      <div style={{ padding: '10px 16px' }}>
        <div className='form-group'>
          <label>{t('starting_date')}</label>

          <DateTimePicker
            value={values.start_date ? Moment(values.start_date) : ''}
            onChange={dateTime => setFieldValue('start_date', dateTime.toISOString(true))}
            inputProps={{ readOnly: true }}
            dateFormat='ddd, DD MMM YYYY'
            timeFormat='HH:mm'
            className='u-full-width'
          />
        </div>

        <div className='form-group'>
          <label>{t('expiration_date')}</label>

          <DateTimePicker
            value={values.expiration_date ? Moment(values.expiration_date) : ''}
            onChange={dateTime => setFieldValue('expiration_date', dateTime.toISOString(true))}
            inputProps={{ readOnly: true }}
            dateFormat='ddd, DD MMM YYYY'
            timeFormat='HH:mm'
            className='u-full-width'
          />
        </div>

        <div className='form-group'>
          <label>Limit Services</label>

          <Switch
            checked={limitServices}
            onChange={() => setLimitServices(!limitServices)}
          />

          {
            limitServices && (
              <Field
                name='service_ids'
                component={ResourceSelect}
                schema={serviceSchema}
                isMulti
                styles={{ valueContainer: base => ({ ...base, fontSize: 16 }) }}
                components={{
                  Option: (props) => (
                    <components.Option {...props}>
                      <span>{props.data.name} </span>

                      <Tag
                        value={props.data.region.name}
                        formatter={CapitalizeFormatter}
                      />
                    </components.Option>
                  ),
                  MultiValueLabel: (props) => (
                    <components.MultiValueLabel {...props}>
                      <span>{props.data.name} </span>

                      {
                        props.data.region ? (
                          <Tag
                            value={props.data.region.name}
                            formatter={CapitalizeFormatter}
                          />
                        ) : (
                          'Missing Region'
                        )
                      }
                    </components.MultiValueLabel>
                  )
                }}
              />
            )
          }
        </div>

        {
          limitServices && (
            <div className='form-group'>
              <label>Limit Packages</label>

              <Switch
                checked={limitPackages}
                onChange={() => setLimitPackages(!limitPackages)}
              />
              {
                limitPackages && (
                  <Field name='package_ids' component={ResourceSelect} schema={packageSchema} isMulti />
                )
              }
            </div>
          )
        }

        <MoneyField name='minimum_price' />
        <Field name='quantity' type='number' min={0} />
        <Field name='limit_per_user' type='number' min={0} />
        <Field name='first_time' component={SelectInput} options={choicesFirstTime} />
      </div>
    </Panel>
  )
}

PromoCodeLimitationsFormSection.propTypes = {
  promoCode: ImmutablePropTypes.map,
  formik: PropTypes.object,
  values: PropTypes.object,
  t: PropTypes.func,
  setValues: PropTypes.func,
  setFieldValue: PropTypes.func
}

PromoCodeLimitationsFormSection.defaultProps = {
  promoCode: Map()
}

export default compose(
  connect,
  withTranslation('promo_codes')
)(PromoCodeLimitationsFormSection)
