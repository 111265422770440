export default theme => ({
  root: {
    padding: '0 16px'
  },
  avatar: {
    height: '130px',
    width: '130px'
  },
  resourceSelectContainer: {
    marginRight: 6
  },
  buttonTag: {
    float: 'right',
    margin: '14px 0 0 14px'
  },
  displayFlex: {
    display: 'flex',
    marginBottom: 16
  },
  contentContainer: {
    margin: '8px 0'
  },
  profileContainer: {
    paddingTop: 16,
    paddingBottom: 8,
    borderBottom: 'solid #dadada 1px'
  }
})
