export default ({ spacing, palette }) => ({
  customerSection: {
    marginBottom: spacing(2)
  },

  tabs: {
    justifyContent: 'flex-start',
    backgroundColor: palette.grey[200]
  },

  tab: {
    fontSize: '1.25rem',
    fontWeight: 500
  },

  customerAvatar: {
    width: 80,
    height: 80
  },

  customerName: {
    marginLeft: 5
  },

  chip: {
    display: 'flex',
    justifyContent: 'flex-start',
    border: 'none'
  }
})
