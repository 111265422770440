import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './CircularLoaderStyles'

export function CircularLoader ({ containerHeight, classes, ...rest }) {
  return (
    <div
      className={classes.container}
      style={containerHeight && {
        height: containerHeight,
        alignItems: 'center'
      }}
    >
      <CircularProgress color='secondary' {...rest} />
    </div>
  )
}

CircularLoader.propTypes = {
  containerHeight: PropTypes.number,
  classes: PropTypes.object
}

export default withStyles(styles)(CircularLoader)
