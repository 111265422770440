import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import ProviderForm from 'forms/resources/ProviderForm'

export class ProviderNewPage extends React.Component {
  static propTypes = {
    services: ImmutablePropTypes.list,
    badges: ImmutablePropTypes.list,
    t: PropTypes.func,
    push: PropTypes.func,
    createProvider: PropTypes.func,
    loadBadges: PropTypes.func,
    loadServices: PropTypes.func

  }

  static propTypes = {
    services: List(),
    badges: List()
  }

  render () {
    return (
      <PageLayout
        title={this.props.t('new')}
        back='/providers'
      >
        <ProviderForm services={this.props.services}
          badges={this.props.badges}
          loadServices={this.props.loadServices}
          loadBadges={this.props.loadBadges}
          push={this.props.push}
          onSubmit={this.props.createProvider}
        />
      </PageLayout>
    )
  }
}

export default withTranslation('providers')(ProviderNewPage)
