import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { create } from 'actions/resources'
import { promoCodeSchema } from '@seekster/schemas'

import PromoCodeNewPage from './PromoCodeNewPage'

const mapDispatchToProps = {
  createPromoCode: (data, options) => create(data, promoCodeSchema, options),
  push
}

export default connect(null, mapDispatchToProps)(PromoCodeNewPage)
