import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
// import { EditRounded, Cancel, Delete, Schedule } from '@material-ui/icons'
import { EditRounded, Cancel } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import withFlashMessage from 'hoc/withFlashMessage'
import { useResource, useResourceAction } from 'hooks/resources'
import { useCollectionChildren } from 'hooks/collections'
// import { usePush } from 'hooks/utils'
import { jobSchema, commentSchema, cancelReasonSchema } from '@seekster/schemas'

import PageLayout from 'components/layouts/PageLayout'
import {
  NavigationMenuBar,
  NavigationMenuItem
} from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import Tag from 'components/Tag'
import CapitalizeFormatter from 'components/formatters/CapitalizeFormatter'
import { JOB_EDITABLE_STATES } from 'constants/states/jobEditableStates'
import RequestCancelModal from 'modules/requests/RequestCancelModal'

import {
  JobCommentsPane,
  JobConversationPane,
  JobFinancePane,
  JobOverviewPane,
  JobProvidersPane,
  JobReviewsPane,
  JobReportsPane,
  JobTimelinePane
} from './panes'
import { AppLayoutContext } from 'components/layouts/AppLayout'
import { useEffect } from 'react'

export function JobShowPage({ setFlashMessage, match, location }) {
  const [job, { loading }] = useResource(jobSchema, match.params.id)
  const [modalOpen, setModalOpen] = useState(false)
  const {
    trigger: triggerJob,
    fetchBlobFile,
    fetchingBlobFile
  } = useResourceAction(jobSchema)

  const { create: createCancelReason } = useResourceAction(cancelReasonSchema)
  const generalConfig = useContext(AppLayoutContext).generalConfig

  const { t } = useTranslation(['jobs', 'actions', 'common'])

  useCollectionChildren(jobSchema, match.params.id, commentSchema)

  function handleCancelJob(values) {
    let basePromise = Promise.resolve()

    if (values.cancel_reason_type_id) {
      basePromise = basePromise.then(() => {
        return createCancelReason(values)
      })
    }

    basePromise = basePromise.then(() => {
      return triggerJob(match.params.id, 'cancel')
    })

    return basePromise.catch((error) => {
      setFlashMessage('error', error.response.body.message)
    })
  }

  function handleDownloadReport() {
    fetchBlobFile(jobSchema, match.params.id, null, { childrenType: 'download' }).then(
      ({ body }) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(body)
        link.download = `${job.get('number')}.pdf`
        link.click()
      }
    )
  }

  function primaryActions() {
    if (!job.getIn(['inquiry', 'has_logistics'])) {
      return [
        {
          content: 'Download PDF',
          onClick: handleDownloadReport,
          loading: fetchingBlobFile
        }
      ]
    }

    return []
  }

  function secondaryActions() {
    let actions = []

    const editAction = {
      avatar: <EditRounded />,
      content: t('edit'),
      to: `/jobs/${match.params.id}/edit`
    }

    const cancelJobAction = {
      avatar: <Cancel />,
      content: t('cancel_job'),
      to: `/jobs/${match.params.id}/cancel`
    }

    const cancelAction = {
      avatar: <Cancel />,
      content: t('cancel'),
      onClick: () => setModalOpen(true)
    }

    if (generalConfig) {
      if (job.get('state') === 'pending_start') {
        actions = [
          editAction,
          generalConfig.is_cancellation_enabled ? cancelJobAction : cancelAction
        ]
      } else if (JOB_EDITABLE_STATES.includes(job.get('state'))) {
        actions = [
          editAction,
          generalConfig.is_cancellation_enabled ? cancelJobAction : cancelAction
        ]
      } else {
        actions = [editAction]
      }
    }

    return actions
  }

  function renderSubNavbar() {
    const path = `/jobs/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('navbar.overview')} to={path} replace />
        <NavigationMenuItem label={t('providers')} to={`${path}/providers`} replace />
        <NavigationMenuItem
          label={t('navbar.provider_reports')}
          to={`${path}/provider_reports`}
          replace
        />
        <NavigationMenuItem label={t('finance')} to={`${path}/finance`} replace />
        <NavigationMenuItem label={t('navbar.reviews')} to={`${path}/reviews`} replace />
        <NavigationMenuItem
          label={t('navbar.comments')}
          to={`${path}/comments`}
          badge={job.get('comments', 0).size}
          replace
        />
        <NavigationMenuItem
          label={t('navbar.conversation')}
          to={`${path}/conversation`}
          replace
        />
        <NavigationMenuItem
          label={t('navbar.timeline')}
          to={`${path}/timeline`}
          replace
        />
      </NavigationMenuBar>
    )
  }

  function renderSubtitle() {
    return (
      <Tag
        value={job.get('state')}
        className='job-states'
        formatter={CapitalizeFormatter}
      />
    )
  }

  return (
    <PageLayout
      title={job.get('number')}
      subtitle={renderSubtitle()}
      subNavbar={renderSubNavbar()}
      actions={secondaryActions()}
      isLoading={loading}
      primaryActions={primaryActions()}
    >
      <Switch>
        <AuthenticatedRoute path='/jobs/:id' component={JobOverviewPane} exact />
        <AuthenticatedRoute path='/jobs/:id/providers' component={JobProvidersPane} />
        <AuthenticatedRoute
          path='/jobs/:id/provider_reports'
          component={JobReportsPane}
        />
        <AuthenticatedRoute path='/jobs/:id/finance' component={JobFinancePane} />
        <AuthenticatedRoute path='/jobs/:id/reviews' component={JobReviewsPane} />
        <AuthenticatedRoute path='/jobs/:id/comments' component={JobCommentsPane} />
        <AuthenticatedRoute
          path='/jobs/:id/conversation'
          component={JobConversationPane}
        />
        <AuthenticatedRoute path='/jobs/:id/timeline' component={JobTimelinePane} />
      </Switch>

      <RequestCancelModal
        isOpen={modalOpen}
        onClose={setModalOpen}
        owner='Job'
        owner_id={match.params.id}
        onSubmit={handleCancelJob}
      />
    </PageLayout>
  )
}

JobShowPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  setFlashMessage: PropTypes.func
}

export default withFlashMessage(JobShowPage)
