import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { usePush } from 'hooks/utils'
import useData from 'hooks/useData'
import withLocalizedFields from 'hoc/withLocalizedFields'

import PageLayout from 'components/layouts/PageLayout'
import AddressForm from 'forms/resources/AddressForm'

export function AddressEditPage ({
  address,
  fetchAddress,
  updateAddress,
  match,
  t
}) {
  const { loading } = useData(() => fetchAddress(match.params.id))
  const push = usePush()

  function handleSubmit (data) {
    return updateAddress(match.params.id, data)
  }

  function breadcrumbs () {
    return [{
      text: t('title')
    }, {
      text: address.get('name') || address.get('id')
    }, {
      text: t('edit')
    }]
  }

  return (
    <PageLayout
      title={t('edit')}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <AddressForm
        address={address}
        onSubmit={handleSubmit}
        push={push}
      />
    </PageLayout>
  )
}

AddressEditPage.propTypes = {
  address: ImmutablePropTypes.map,
  fetchAddress: PropTypes.func,
  updateAddress: PropTypes.func,
  match: PropTypes.object,
  t: PropTypes.func
}

AddressEditPage.defaultProps = {
  address: Map()
}

export default compose(
  withLocalizedFields,
  withTranslation('addresses')
)(AddressEditPage)
