import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Link } from 'react-router-dom'
import { Table, Column } from 'components/tables/Table'
import { Table as MaterialTable, TableCell, TableRow, TableBody } from '@material-ui/core'
import { DateTag, StateTag } from 'components/tags'
import AttributeLabel from 'components/labels/AttributeLabel'

const useStyles = makeStyles(({ spacing }) => ({
  invoiceContainer: {
    padding: '8px 16px 0'
  }
}))

export function Invoice ({ inquiry }) {
  const classes = useStyles()

  let invoice = inquiry.get('invoice') || Map()

  if (inquiry.get('subscription_month')) {
    invoice = inquiry.getIn(['subscription_month', 'invoice']) || Map()
  }

  const subtotal = invoice.get('subtotal') || Map()
  const tax = invoice.get('tax') || Map()
  const total = invoice.get('total') || Map()
  const lineItems = invoice.get('line_items') || List()
  const discounts = invoice.get('discounts') || List()

  return (
    <>
      <div className={classes.invoiceContainer}>
        <AttributeLabel title='Number'>
          <Link to={`/invoices/${invoice.get('id')}`}>
            {invoice.get('number')}
          </Link>
        </AttributeLabel>

        <AttributeLabel title='Due at'>
          <DateTag value={invoice.get('due_at')} />
        </AttributeLabel>

        <AttributeLabel title='State'>
          <StateTag value={invoice.get('state')} />
        </AttributeLabel>
      </div>

      <Table collection={lineItems}>
        <Column name='description' />
        <Column name='quantity' />
        <Column
          name='amount'
          render={(amount) => amount.get('full_display')}
        />
      </Table>

      <MaterialTable>
        <TableBody>
          {
            discounts.map((discount, index) => {
              const discountAmount = discount.get('amount') || Map()

              return (
                <TableRow key={index} className='discounts'>
                  <TableCell scope='row'>
                    {discount.get('description')}
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell align='right'>-{discountAmount.get('full_display')}</TableCell>
                </TableRow>
              )
            })
          }

          <TableRow>
            <TableCell scope='row'>&nbsp;</TableCell>
            <TableCell>&nbsp;</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>

          <TableRow>
            <TableCell scope='row' />
            <TableCell align='right'><b>Sub Total</b></TableCell>
            <TableCell align='right'>{subtotal.get('full_display')}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell scope='row' />
            <TableCell align='right'><b>VAT 7.0%</b></TableCell>
            <TableCell align='right'>{tax.get('full_display')}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell scope='row' />
            <TableCell align='right'><b>Total</b></TableCell>
            <TableCell align='right'>{total.get('full_display')}</TableCell>
          </TableRow>
        </TableBody>
      </MaterialTable>
    </>
  )
}

Invoice.propTypes = {
  inquiry: ImmutablePropTypes.map
}

Invoice.defaultProps = {
  inquiry: Map()
}

export default Invoice
