const addressComponentExtractor = (components = {}) => {
  return (typeOrTypes) => {
    const types = Array.isArray(typeOrTypes) ? typeOrTypes : [typeOrTypes]

    let component = types
      .map(
        type => components.find(component => component.types.includes(type))
      )
      .find(type => !!type)

    return (component && component.long_name) || ''
  }
}

export default addressComponentExtractor
