import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'formik'
import { Grid, Button } from '@material-ui/core'
import { Field } from 'components/fields/Field'
import ButtonLoader from 'components/buttons/ButtonLoader'
import ResourceCreatableSelect from 'components/inputs/ResourceCreatableSelect'
import { tagSchema } from '@seekster/schemas'
import { useResource } from 'hooks/resources'

import styles from './UserTagFormStyles'

export function UserTagForm ({
  classes,
  onCancel,
  isSubmitting,
  setFieldValue,
  schema,
  userId
}) {
  const [user] = useResource(schema, userId, [userId])

  useEffect(() => {
    setFieldValue('tag_ids', user.get('tags', List()).map(tag => tag.get('id')).toJS())
  }, [user, setFieldValue])

  return (
    <Form className={classes.root}>
      <Grid item xs={12} alignItems='center'>
        <Field
          name='tag_ids'
          component={ResourceCreatableSelect}
          noLabel
          schema={tagSchema}
          parentSchema={schema}
          parentId={userId}
          initialValue={user.get('tags', List()).map(tag => tag.get('id')).toJS()}
        />
      </Grid>

      <Grid item xs={12} container justify='flex-end'>
        <ButtonLoader
          className={classes.button}
          variant='contained'
          color='primary'
          type='submit'
          isLoading={isSubmitting}
        >
          Save
        </ButtonLoader>

        <Button
          type='button'
          variant='contained'
          className={classes.button}
          onClick={onCancel}
        >
          cancel
        </Button>
      </Grid>
    </Form>
  )
}

UserTagForm.propTypes = {
  isSubmitting: PropTypes.bool,
  classes: PropTypes.object,
  schema: PropTypes.object,
  userId: PropTypes.string,
  onCancel: PropTypes.func,
  setFieldValue: PropTypes.func
}

export default withStyles(styles)(UserTagForm)
