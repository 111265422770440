import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { categorySchema } from '@seekster/schemas'

import CategoryOverviewPane from './CategoryOverviewPane'

const mapStateToProps = (state, { match }) => ({
  category: getResource(state, match.params.id, categorySchema)
})

export default connect(mapStateToProps)(CategoryOverviewPane)
