import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import styles from './VisibilityToggledContentStyles'

export function VisibilityToggledContent ({ value, maxWidth, classes, t }) {
  const [visibile, setVisibility] = useState(false)

  function handleToggleVisibility () {
    setVisibility(!visibile)
  }

  return (
    <>
      <Button
        onClick={handleToggleVisibility}
        className={visibile ? classes.hideButton : classes.showButton}
        size='small'
        disabled={Boolean(!value)}
      >
        {visibile ? t('hide') : t('show')}
      </Button>

      {
        visibile && (
          <div
            className={classes.content}
            style={maxWidth && { maxWidth, overflowWrap: 'break-word' }}
          >
            {value}
          </div>
        )
      }
    </>
  )
}

VisibilityToggledContent.propTypes = {
  value: PropTypes.node,
  maxWidth: PropTypes.number,
  classes: PropTypes.object,

  t: PropTypes.func
}

export default compose(
  withStyles(styles),
  withTranslation('actions')
)(VisibilityToggledContent)
