import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { AddToQueueRounded } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'

import PageLayout from 'components/layouts/PageLayout'
import CircularLoader from 'components/CircularLoader'
import Search from 'components/Search'
import FilterModal from 'components/FilterModal'
import FilterTags from 'components/tags/FilterTags'
import ClientsFilterForm from 'forms/filters/ClientsFilterForm'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import ClientsTable from 'components/tables/ClientsTable'
import Pagination from 'components/Pagination'
import { useCollection } from 'hooks/collections'
import { clientSchema } from '@seekster/schemas'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  }
}))

export function ClientIndexContent ({ query, t }) {
  const { page, ...rest } = query
  const classes = useStyles()
  const [clients, { loading, totalCount, totalPages }] = useCollection(clientSchema, { page, query: rest }, [query])

  return (
    <>
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={10}>
            <Search />
          </Grid>

          <Grid item xs={3} sm={2}>
            <FilterModal component={ClientsFilterForm} />
          </Grid>
        </Grid>
      </div>

      {
        loading ? (
          <Grid item xs={12}>
            <CircularLoader />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <FilterTags />

              <ResultsCountLabel value={totalCount} unit='client' />

              <ClientsTable clients={clients} />
            </Grid>

            <Grid item xs={12} container justify='center'>
              <Pagination totalPages={totalPages} />
            </Grid>
          </>
        )
      }
    </>
  )
}

export function ClientIndexPage (props) {
  const { t } = props

  function primaryActions () {
    return [{
      avatar: <AddToQueueRounded fontSize='small' />,
      content: t('new'),
      to: `/clients/new`
    }]
  }

  return (
    <PageLayout title={t('title')} primaryActions={primaryActions()}>
      <ClientIndexContent {...props} />
    </PageLayout>
  )
}

ClientIndexPage.propTypes = {
  query: PropTypes.object,
  t: PropTypes.func
}

export default compose(
  withQueryParser,
  withTranslation('clients')
)(ClientIndexPage)
