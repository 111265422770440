import React from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'formik'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'

import SwitchInputField from 'components/fields/SwitchInputField'
import styles from './TenantGeneralConfigEnableFunctionFormStyle'

function EnabledSearchSection({ name, searchCustomerEnabled, searchProviderEnabled }) {
  const { t } = useTranslation('settings')

  return (
    <>
      <Grid item xs={12}>
        <label>{t('function_enabled.search_enabled.header')}</label>
      </Grid>

      <Grid item xs={12} sm={4}>
        <SwitchInputField
          name={`${name}.search_customer_enabled`}
          switchLabel={t(
            `function_enabled.search_enabled.customer.${searchCustomerEnabled}`
          )}
        />
      </Grid>

      <Grid item xs={12} sm={8}>
        <SwitchInputField
          name={`${name}.search_provider_enabled`}
          switchLabel={t(
            `function_enabled.search_enabled.provider.${searchProviderEnabled}`
          )}
        />
      </Grid>
    </>
  )
}

function EnabledChatSection({ name, chatEnabled, classes }) {
  const { t } = useTranslation('settings')

  return (
    <>
      <Grid className={classes.gapTop} item xs={12}>
        <label>{t('function_enabled.chat_enabled.header')}</label>
      </Grid>
      <Grid item xs={12}>
        <SwitchInputField
          name={`${name}.chat_enabled`}
          switchLabel={t(`function_enabled.chat_enabled.${chatEnabled}`)}
        />
      </Grid>
    </>
  )
}

function EnabledEditFunctionSection({
  name,
  addressDetailEnabled,
  requestQuestionDetailEnabled,
  classes
}) {
  const { t } = useTranslation('settings')

  return (
    <>
      <Grid className={classes.gapTop} item xs={12}>
        <label>{t('function_enabled.edit_function_enabled.header')}</label>
      </Grid>

      <Grid item xs={12} sm={4} spacing={1}>
        <SwitchInputField
          name={`${name}.edit_request_question_detail_enabled`}
          switchLabel={t(
            `function_enabled.edit_function_enabled.request_question_detail_enabled.${requestQuestionDetailEnabled}`
          )}
        />
      </Grid>

      <Grid item xs={12} sm={8} spacing={1}>
        <SwitchInputField
          name={`${name}.edit_address_detail_enabled`}
          switchLabel={t(
            `function_enabled.edit_function_enabled.address_detail_enabled.${addressDetailEnabled}`
          )}
        />
      </Grid>
    </>
  )
}

function EnabledLocationFunctionSection({ name, classes, locationProviderEnabled }) {
  const { t } = useTranslation('settings')

  return (
    <>
      <Grid className={classes.gapTop} item xs={12}>
        <label>{t('function_enabled.location_provider_enabled.header')}</label>
      </Grid>

      <Grid item xs={12} sm={4} spacing={1}>
        <SwitchInputField
          name={`${name}.location_provider_enabled`}
          switchLabel={t(
            `function_enabled.location_provider_enabled.${locationProviderEnabled}`
          )}
        />
      </Grid>
    </>
  )
}

function EnabledSLAFunctionSection({ name, classes, slaFunctionitonEnabled }) {
  const { t } = useTranslation('settings')

  return (
    <>
      <Grid className={classes.gapTop} item xs={12}>
        <label>{t('function_enabled.sla_funciton_enabled.header')}</label>
      </Grid>

      <Grid item xs={12} sm={4} spacing={1}>
        <SwitchInputField
          name={`${name}.sla_funciton_enabled`}
          switchLabel={t(
            `function_enabled.sla_funciton_enabled.${slaFunctionitonEnabled}`
          )}
        />
      </Grid>
    </>
  )
}

function EnabledRatingLinkSection({ name, classes, ratingLinkEnabled }) {
  const { t } = useTranslation('settings')

  return (
    <>
      <Grid className={classes.gapTop} item xs={12}>
        <label>{t('function_enabled.rating_link_enabled.header')}</label>
      </Grid>

      <Grid item xs={12} sm={4} spacing={1}>
        <SwitchInputField
          name={`${name}.rating_link_enabled`}
          switchLabel={t(
            `function_enabled.rating_link_enabled.${ratingLinkEnabled}`
          )}
        />
      </Grid>
    </>
  )
}

function TenantGeneralConfigEnableFunctionForm({ formik, name, classes }) {
  const {
    chat_enabled: chatEnabled,
    edit_address_detail_enabled: addressDetailEnabled,
    edit_request_question_detail_enabled: requestQuestionDetailEnabled,
    search_customer_enabled: searchCustomerEnabled,
    search_provider_enabled: searchProviderEnabled,
    location_provider_enabled: locationProviderEnabled,
    sla_funciton_enabled: slaFunctionitonEnabled,
    rating_link_enabled: ratingLinkEnabled
  } = formik.values.general_configuration_attributes

  return (
    <>
      <EnabledSearchSection
        name={name}
        classes={classes}
        searchCustomerEnabled={searchCustomerEnabled}
        searchProviderEnabled={searchProviderEnabled}
      />

      <EnabledChatSection name={name} chatEnabled={chatEnabled} classes={classes} />

      <EnabledEditFunctionSection
        name={name}
        addressDetailEnabled={addressDetailEnabled}
        requestQuestionDetailEnabled={requestQuestionDetailEnabled}
        classes={classes}
      />

      <EnabledLocationFunctionSection
        name={name}
        locationProviderEnabled={locationProviderEnabled}
        classes={classes}
      />

      <EnabledSLAFunctionSection
        name={name}
        slaFunctionitonEnabled={slaFunctionitonEnabled}
        classes={classes}
      />

      <EnabledRatingLinkSection
        name={name}
        ratingLinkEnabled={ratingLinkEnabled}
        classes={classes}
      />
    </>
  )
}

export default compose(connect, withStyles(styles))(TenantGeneralConfigEnableFunctionForm)
