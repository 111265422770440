import {
  UPDATE_ENTITY,
  UPDATE_ENTITIES
} from 'constants/entities'

export const updateEntity = (schema, id, func) => ({
  type: UPDATE_ENTITY,
  payload: { schema, id, func }
})

export const updateEntities = (entities) => ({
  type: UPDATE_ENTITIES,
  payload: entities
})
