import { Map } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'
import InvoiceForm from './InvoiceForm'

const formikConfig = {
  mapPropsToValues: ({ invoice = Map() }) => ({
    customer_id: invoice.getIn(['customer', 'id']) || '',
    company_id: invoice.getIn(['customer', 'company_id']) || '',
    due_at: invoice.get('due_at') || ''
  }),

  validationSchema: Yup.object().shape({
    customer_id: Yup.string().required(),
    company_id: Yup.string(),
    due_at: Yup.string()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    // alert(JSON.stringify(values, null, 4))
    // setSubmitting(false)
    props.onSubmit(values)
      .then(response => {
        props.push(`/invoices/${response.body.id}`)
      })
      .catch(error => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(InvoiceForm)
