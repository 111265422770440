export default theme => ({
  root: {
    width: '100%'
  },
  titleContanier: {
    marginBottom: 8
  },
  fieldContainer: {
    marginBottom: 8
  }
})
