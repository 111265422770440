import React, { useState, useEffect } from 'react'
import { PageLayout } from 'components/layouts'
import { useHistory, useParams } from 'react-router-dom'
import './JobCancellationPage.scss'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { callService } from 'utils/callService'
import { jobSchema } from '@seekster/schemas'
import { useResource } from 'hooks/resources'
import moment from 'moment'
import _ from 'lodash'

import {
  Button,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import dayjs from 'dayjs'
import { Modal } from 'components/containers'

const formInitialValue = {
  cancelReasonType: null,
  selectedReason: [],
  otherReason: ''
}

function JobCancellationPage() {
  const { t, i18n } = useTranslation(['jobs'])
  const { id } = useParams()
  const [job] = useResource(jobSchema, id)
  const redux = useSelector((state) => state)
  const tenant_id = redux.toJS().cancellation.tenantId
  const locale = i18n.language
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [cancellation, setCancellation] = useState()
  const [cancelReasonTypeOptions, setCancelReasonTypeOptions] = useState([])
  const [jobData, setJobData] = useState()
  const [formValue, setFormValue] = useState({
    cancel_reason_values: null,
    cancelReasonType: null,
    selectedReason: [],
    otherReason: ''
  })
  const [openModalSuccess, setOpenModalSuccess] = useState(false)
  const [isShowInvlidMessage, setIsShowInvalidMessage] = useState()

  const { is_reason_other_enabled, is_reason_choice_enabled, cancellationReasons } =
    cancellation || {}
  const hasOtherReasonDisabled = !is_reason_other_enabled
  const hasChoiceReasonDisabled =
    !is_reason_choice_enabled || cancellationReasons.length === 0

  const hasFormValid = formValue.selectedReason.length !== 0 || formValue.otherReason !== ""

  useEffect(() => {
    if (tenant_id && !_.isEmpty(job.toJS())) {
      fetchCancellation(job.toJS().inquiry.service_id)
      fetchCancelReasonType()
      setJobData(job.toJS())
      setLoading(false)
    }
  }, [tenant_id, job])

  useEffect(() => {
    hasFormValid ? setIsShowInvalidMessage(false) : setIsShowInvalidMessage(true)
  }, [formValue], false)

  const handleChangeCancelReasonType = (value) => {
    setFormValue({ ...formValue, cancelReasonType: value })
    clearStateWhenSelectReasonType()
  }

  const jobAmount = () => {
    const {
      cancellation: {
        cancellationConditionFees: conditionFees = null,
        is_base_condition_fee_enabled,
        base_condition_fee_value,
        base_condition_fee_type,
        is_condition_fee_enabled
      },
      jobData: {
        payout: { decimal: jobPrice },
        start_time: jobDate
      }
    } = { cancellation, jobData }

    const nowDate = dayjs(new Date())
    const cancellationMinutes = dayjs(jobDate).diff(nowDate, 'millisecond')
    const sortedConditions = conditionFees.sort((a, b) => a.before_minutes - b.before_minutes)

    let fee = 0
    if (is_condition_fee_enabled) {
      for (const condition of sortedConditions) {
        if (cancellationMinutes <= condition.before_minutes) {
          fee = condition.type === 'baht'
            ? condition.value
            : (condition.value * jobPrice) / 100
          break
        }
      }
    }

    if (!fee && is_base_condition_fee_enabled) {
      fee = base_condition_fee_type === 'baht'
        ? base_condition_fee_value
        : (base_condition_fee_value * jobPrice) / 100
    }

    return jobPrice - fee
  }


  const fetchCancelReasonType = async () => {
    try {
      const response = await callService.get(
        `${process.env.REACT_APP_BASE_API}/cancel_reason_types?owner=Job&page=1`
      )
      if (response.status === 200) {
        let data = response.data
        if (data) {
          setCancelReasonTypeOptions(data)
        }
      }
    } catch (error) {
      alert(`An error occurred while fetch the cancel reason: ${error.message}`)
    }
  }

  const fetchCancellation = async (service_id) => {
    try {
      const response = await callService.get(
        `${process.env.REACT_APP_CANCELLATION_BASE_API}/api/cancellations?tenant_id=${tenant_id}&service_id=${service_id}`
      )
      if (response.status === 200) {
        let data = response.data.data.cancellations

        if (data) {
          setCancellation(data)
        }
      }
    } catch (error) {
      alert(`An error occurred while fetch the cancellation: ${error.message}`)
    }
  }

  const handleDataForSubmit = () => {
    const reasonType = formValue.cancelReasonType.value || '';
    let dataForSubmit = {};

    switch (reasonType) {
      case "Customer":
        dataForSubmit = {
          cancel_reason_type_id: formValue.cancelReasonType.id,
          cancellation_job_reason_other: formValue.otherReason,
          job_id: parseInt(id),
          tenant_id: tenant_id,
          job_amount: jobAmount(),
          job_start_date: moment(jobData.start_time).format("YYYY-MM-DD"),
          cancellation_id: cancellation.id,
          recipient_type: "Customer",
          recipient_id: `${jobData.customer.id}` ?? null,
          cancellationReasonsAttributes: formValue.selectedReason.map(item => {
            return {
              cancellation_reasons_id: item
            };
          })
        };
        break;
      case "Provider":
        dataForSubmit = {
          owner_id: id,
          owner_type: formValue.cancelReasonType.owner,
          cancel_reason_type_id: formValue.cancelReasonType.id,
          cancel_reason_value_id: formValue.cancel_reason_values.id,
        };
        break;
      case "Other":
        dataForSubmit = {
          owner_id: id,
          owner_type: formValue.cancelReasonType.owner,
          cancel_reason_type_id: formValue.cancelReasonType.id,
          other_value: formValue.otherReason
        };
        break;
    }

    return dataForSubmit;
  };

  const submitCancellationJob = async dataForSubmit => {
    try {
      const response = await callService.post(
        `${process.env.REACT_APP_CANCELLATION_BASE_API}/api/cancellation-jobs`,
        dataForSubmit
      );
      if (response.status === 200 || response.status === 201) {
        return response
      }
    } catch (error) {
      alert(`An error occurred while create the cancellation job: ${error.message}`);
      return error;
    }
  };

  const cancelJob = async () => {
    try {
      const response = await callService.put(
        `${process.env.REACT_APP_BASE_API}/jobs/${id}/cancel`
      );
      return response;
    } catch (error) {
      alert(`An error occurred while cancelling job: ${error}`);
    }
  };

  const submitProviderOrOtherCancellationJob = async (dataForSubmit) => {
    try {
      const cancelJobResponse = await cancelJob();
      const response = await callService.post(
        `${process.env.REACT_APP_BASE_API}/cancel_reasons`,
        dataForSubmit
      );
      return response;
    } catch (error) {
      alert(`An error occurred while submitting cancel reason: ${error}`);
    }
  };

  const handleSubmitFunction = async () => {
    const reasonType = formValue.cancelReasonType.value || ''
    const dataForSubmit = handleDataForSubmit();

    try {
      let response;
      if (reasonType === 'Customer') {
        response = await submitCancellationJob(dataForSubmit);
      } else if (reasonType === 'Provider' || reasonType === 'Other') {
        response = await submitProviderOrOtherCancellationJob(dataForSubmit);
      }

      if (response.status === 200 || response.status === 201) {
        setOpenModalSuccess(true)
        setTimeout(function () {
          setOpenModalSuccess(false)
          history.push(`/jobs/${id}`)
        }, 3000);
      }
    } catch (error) {
      alert(`An error occurred while submitting the cancellation job: ${error.message}`);
    }
  };

  const clearStateWhenSelectReasonType = () => {
    setFormValue(prev => {
      return {
        ...formInitialValue,
        cancelReasonType: prev.cancelReasonType
      }
    })
    setIsShowInvalidMessage()
  }

  const handleReasonCheckbox = (value) => {
    let clonedArray = [...formValue.selectedReason]

    if (_.includes(clonedArray, value)) {
      _.pull(clonedArray, value);
      setFormValue({
        ...formValue,
        selectedReason: clonedArray
      })
    } else {
      clonedArray.push(value);
      setFormValue({
        ...formValue,
        selectedReason: clonedArray
      })
    }
  }

  return (
    <PageLayout title={`Cancellation`} back={`/jobs/${id}`} isLoading={loading}>
      {jobData && (
        <>
          <div className='cancelJob-section-card cancelJob-detail-container'>
            <Grid container>
              <Grid item xs={3}>
                <span className='cancelJob-number'>{jobData.number}</span>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <span className='cancelJob-detail-title'>{t('date')}</span>
                  &nbsp;
                  <span className='cancelJob-detail-value'>
                    {moment(jobData.start_time).format('DD-MM-YYYY')}
                  </span>
                </div>
              </Grid>
              <Grid item xs={2}>
                <span className='cancelJob-detail-title'>{t('start_time')}</span>
                &nbsp;
                <span className='cancelJob-detail-value'>
                  {moment(jobData.start_time).format('HH:mm')} - &nbsp;
                  {moment(jobData.end_time).format('HH:mm')}
                </span>
              </Grid>
            </Grid>
          </div>
          <div className='cancelJob-section-card'>
            <span className='cancelJob-label'>{t('cancel_job_by')}</span>
            <Grid container>
              <Grid item xs={5}>
                <Autocomplete
                  disableClearable
                  options={cancelReasonTypeOptions}
                  getOptionLabel={(option) => option.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      fullWidth
                      placeholder={t('please_select')}
                    />
                  )}
                  onChange={(event, value) => handleChangeCancelReasonType(value)}
                />
              </Grid>
            </Grid>
          </div>

          {
            formValue.cancelReasonType &&
            <>
              {
                //Customer
                formValue.cancelReasonType.value === 'Customer' &&
                <>
                  {hasOtherReasonDisabled && hasChoiceReasonDisabled && (
                    <div className='cancelJob-section-card'>
                      <div className='cancelJob-label'>{t('reason_for_cancellation')}</div>
                      <div className='cancelJob-sub-label'>{t('empty_reason_cancellation')}</div>
                    </div>
                  )}

                  {cancellation &&
                    cancellation.is_reason_choice_enabled &&
                    cancellation.cancellationReasons.length !== 0 && (
                      <div className='cancelJob-section-card'>
                        <div className='cancelJob-label'>
                          {cancellation[`title_reason_choice_${locale}`]}
                        </div>
                        {cancellation.cancellationReasons.map((item, index) => {
                          return (
                            <div className='cancelJob-checkbox-item' key={`checkbox-${index}`}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={_.includes(formValue.selectedReason, item.id)}
                                    onChange={(e) => handleReasonCheckbox(e.target.value)}
                                    value={item.id}
                                    color='primary'
                                  />
                                }
                                label={item[`reason_${locale}`]}
                              />
                            </div>
                          )
                        })}
                        {
                          isShowInvlidMessage === true && <div className='cancelJob-error-message'>{t('invalid_message')}</div>
                        }
                      </div>
                    )}

                  {cancellation && cancellation.is_reason_other_enabled && (
                    <div className='cancelJob-section-card'>
                      <div className='cancelJob-label'>{cancellation[`title_reason_other_${locale}`]}</div>
                      <Grid container>
                        <Grid item xs={5}>
                          <TextField
                            multiline
                            rows={4}
                            variant='outlined'
                            fullWidth
                            onChange={(e) => setFormValue({ ...formValue, otherReason: e.target.value })}
                          />
                        </Grid>
                      </Grid>
                      {
                        isShowInvlidMessage === true && <div className='cancelJob-error-message'>{t('invalid_message')}</div>
                      }
                    </div>
                  )}
                </>
              }

              {
                //Provider
                formValue.cancelReasonType.value === 'Provider' && cancelReasonTypeOptions &&
                <div className='cancelJob-section-card'>
                  <Grid container>
                    <Grid item xs={5}>
                      <Autocomplete
                        disableClearable
                        options={cancelReasonTypeOptions[2].cancel_reason_values}
                        getOptionLabel={(option) => option.value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            fullWidth
                            placeholder={t('please_select')}
                          />
                        )}
                        onChange={(event, value) => setFormValue({ ...formValue, cancel_reason_values: value })}
                      />
                    </Grid>
                  </Grid>
                </div>
              }

              {
                //Other
                formValue.cancelReasonType.value === 'Other' &&
                <div className='cancelJob-section-card'>
                  <div className='cancelJob-label'>Other reason</div>
                  <Grid container>
                    <Grid item xs={5}>
                      <TextField
                        multiline
                        rows={4}
                        variant='outlined'
                        fullWidth
                        onChange={(e) => setFormValue({ ...formValue, otherReason: e.target.value })}
                      />
                    </Grid>
                  </Grid>
                </div>
              }
            </>
          }

          <div className='cancelJob-section-card cancelJob-confirm-container'>
            <Button
              color='primary'
              variant='contained'
              onClick={() => handleSubmitFunction()}
              disabled={formValue.cancelReasonType === null || (formValue.cancelReasonType.value === 'Customer' && isShowInvlidMessage === true)}
            >
              {t('confirm')}
            </Button>
          </div>

          <Modal
            isOpen={openModalSuccess}
            onCloseModal={() => setOpenModalSuccess(false)}
            className='section-custom-main-modal-success'
          >
            <div className='d-flex align-item-center justify-content-between'>
              <div>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='32.94'
                  height='32.94'
                  viewBox='0 0 32.94 32.94'
                >
                  <path
                    d='M33.5,17.032A16.47,16.47,0,1,1,17.032.562,16.47,16.47,0,0,1,33.5,17.032ZM15.127,25.753l12.22-12.22a1.063,1.063,0,0,0,0-1.5l-1.5-1.5a1.063,1.063,0,0,0-1.5,0l-9.966,9.965L9.723,15.841a1.063,1.063,0,0,0-1.5,0l-1.5,1.5a1.063,1.063,0,0,0,0,1.5l6.907,6.907a1.063,1.063,0,0,0,1.5,0Z'
                    transform='translate(-0.562 -0.562)'
                    fill='#1b4171'
                  />
                </svg>
              </div>
              <div className='topic-success-modal'>
                {t('cancellation_job_success_message')}
              </div>
            </div>
          </Modal>
        </>
      )}
    </PageLayout>
  )
}

export default JobCancellationPage
