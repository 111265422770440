import React from 'react'
import { List, fromJS } from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import {
  Table as MaterialTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import useQuery from 'hooks/useQuery'

import styles from './TableStyles'

export function Table({ collection, classes, t, children, ...rest }) {
  const filteredChildren = children.filter((child) => child instanceof Object)
  const [query, { pushQuery }] = useQuery()

  function sortHander(name) {
    if (query.sort === `${name}:desc`) {
      pushQuery({ ...query, sort: `${name}:asc` })
    } else {
      pushQuery({ ...query, sort: `${name}:desc` })
    }
  }

  return (
    <div className={classes.tableContainer}>
      <MaterialTable className={classes.root} {...rest}>
        <TableHead>
          <TableRow>
            {React.Children.map(filteredChildren, (child, index) => {
              const { name, value, columnName, component, ...customProps } = child.props

              return (
                <TableCell
                  className={classes.attributeHeader}
                  {...customProps}
                  key={index}
                >
                  {child.props.isSort ? (
                    <TableSortLabel
                      active={child.props.isSort ? query?.sort?.includes(name) : false}
                      direction={query.sort === `${name}:desc` ? 'desc' : 'asc'}
                      onClick={() => sortHander(child.props.name)}
                    >
                      {columnName || t(child.props.name)}
                    </TableSortLabel>
                  ) : (
                    <> {columnName || t(child.props.name)}</>
                  )}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {fromJS(collection).map((resource, index) => {
            return (
              <TableRow key={resource.get('id')}>
                {React.Children.map(filteredChildren, (child) => {
                  return React.cloneElement(child, {
                    resource,
                    className: classes.attributeValue,
                    index
                  })
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </MaterialTable>
    </div>
  )
}

Table.propTypes = {
  collection: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.array]),
  children: PropTypes.node,

  classes: PropTypes.object,
  t: PropTypes.func
}

Table.defaultProps = {
  collection: List()
}

export default compose(withStyles(styles), withTranslation('attributes'))(Table)
