import React from 'react'
import PropTypes from 'prop-types'

import { tagSchema } from '@seekster/schemas'
import Tag from 'components/Tag'
import { useResourceRef } from 'hooks/firebase/resources'

export function FirebaseRefTag ({
  doc
}) {
  const [tag] = useResourceRef(doc, tagSchema)

  return <Tag value={tag.get('name')} backgroundColor='#1b4170' color='white' />
}

FirebaseRefTag.propTypes = {
  doc: PropTypes.object
}

export default FirebaseRefTag
