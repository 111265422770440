import React, { Fragment, useState, useEffect } from 'react'
import { useSelector, connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Map, fromJS } from 'immutable'
import { Link, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemText, Collapse, Badge } from '@material-ui/core'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import { getResource } from 'selectors/resources'
import { fetchChildWithImplicitParent } from 'actions/resources'

import {
  integrationSchema,
  tenantSchema,
  generalConfigurationSchema
} from '@seekster/schemas'

import { useSidebar } from 'hooks/interface'

import items from './items'
import styles from './DrawerMenusStyles'

export function DrawerMenus({
  closeDrawerOnSelect,
  location,
  classes,
  t,
  fetchGeneralConfig
}) {
  const [generalConfig, setGeneralConfig] = useState(Map())

  useEffect(() => {
    fetchGeneralConfig().then((res) => setGeneralConfig(fromJS(res.body)))
  }, [fetchGeneralConfig])

  const isSupportPlatformEnabled = useSelector(
    (state) => getResource(state, 'support-platform', integrationSchema) || Map()
  )

  const { closeSidebar } = useSidebar()

  const defaultItem =
    items(
      !isSupportPlatformEnabled.isEmpty(),
      generalConfig.get('sla_funciton_enabled'),
      generalConfig.get('rating_link_enabled'),
      generalConfig.toJS().is_cancellation_enabled
    ).find(
      ({ children }) =>
        children &&
        children.some((child) => {
          return (
            location.pathname.startsWith(child.path) ||
            new RegExp('^/' + child.value).test(location.pathname)
          )
        })
    ) || {}

  const [expandedItems, setExpandedItems] = useState([defaultItem.value])
  useEffect(() => {
    setExpandedItems([defaultItem.value])
  }, [defaultItem.value])

  function handleClickItem(itemValue) {
    if (expandedItems.includes(itemValue)) {
      const currentExpandedItems = expandedItems.filter((item) => item !== itemValue)

      setExpandedItems(currentExpandedItems)
    } else {
      setExpandedItems([...expandedItems, itemValue])
    }
  }

  function handleClickMenu() {
    closeDrawerOnSelect && closeSidebar()
  }

  return (
    <List component='nav'>
      {items(
        !isSupportPlatformEnabled.isEmpty(),
        generalConfig.get('sla_funciton_enabled'),
        generalConfig.get('rating_link_enabled'),
        generalConfig.toJS().is_cancellation_enabled
      ).map(({ value, path, children }) => {
        const isExpanded = expandedItems.includes(value)

        return (
          <Fragment key={value}>
            {!children ? (
              <>
                <ListItem className={classes.item} button component={Link} to={path}>
                  <ListItemText
                    className={classes.itemText}
                    primary={t(value)}
                    disableTypography
                  />
                </ListItem>
              </>
            ) : (
              <>
                <ListItem
                  className={classes.item}
                  button
                  onClick={() => handleClickItem(value)}
                >
                  <ListItemText
                    className={classes.itemText}
                    primary={t(value)}
                    disableTypography
                  />
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={isExpanded} timeout='auto' unmountOnExit>
                  <List>
                    {children.map(
                      ({ value, path, disabled, badge, children: nestedChildren }) => (
                        <>
                          <ListItem
                            selected={location.pathname.startsWith(path)}
                            key={value}
                            button
                            className={classes.nested}
                            component={Link}
                            to={path}
                            disabled={disabled}
                            onClick={handleClickMenu}
                          >
                            <Badge
                              invisible
                              classes={{ badge: classes.customBadge }}
                              color='error'
                              variant='dot'
                            >
                              <ListItemText primary={t(value)} disableTypography />
                            </Badge>
                          </ListItem>
                          {nestedChildren && (
                            <List className={classes.nestedList}>
                              {nestedChildren.map(({ value, path }) => (
                                <ListItem
                                  selected={location.pathname.startsWith(path)}
                                  className={classes.nestedChildren}
                                  key={value}
                                  button
                                  component={Link}
                                  to={path}
                                >
                                  <Badge
                                    invisible
                                    classes={{ badge: classes.customBadge }}
                                    color='error'
                                    variant='dot'
                                  >
                                    <ListItemText primary={t(value)} disableTypography />
                                  </Badge>
                                </ListItem>
                              ))}
                            </List>
                          )}
                        </>
                      )
                    )}
                  </List>
                </Collapse>
              </>
            )}
          </Fragment>
        )
      })}
    </List>
  )
}

DrawerMenus.propTypes = {
  closeDrawerOnSelect: PropTypes.bool,
  location: PropTypes.object,
  classes: PropTypes.object,
  t: PropTypes.func,
  fetchGeneralConfig: PropTypes.func
}

DrawerMenus.defaultProps = {
  closeDrawerOnSelect: false
}

const mapDispatch = {
  fetchGeneralConfig: () =>
    fetchChildWithImplicitParent(tenantSchema, generalConfigurationSchema)
}

export default compose(
  withRouter,
  withStyles(styles),
  withTranslation('navbar'),
  connect(null, mapDispatch)
)(DrawerMenus)
