import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { create } from 'actions/resources'
import { announcementSchema } from '@seekster/schemas'

import AnnouncementNewPage from './AnnouncementNewPage'

const mapDispatchToProps = {
  createAnnouncement: (data, options) => create(data, announcementSchema, options),
  push
}

export default connect(null, mapDispatchToProps)(AnnouncementNewPage)
