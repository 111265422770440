import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { useTranslation } from 'react-i18next'

import { useResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'
import { customerSchema } from '@seekster/schemas'

import PageLayout from 'components/layouts/PageLayout'
import CustomerForm from 'forms/resources/CustomerForm'

export function CustomerEditPage ({ match }) {
  const { t } = useTranslation('customers')
  const [customer, { loading }] = useResource(customerSchema, match.params.id)
  const { update: updateCustomer } = useResourceAction(customerSchema)
  const push = usePush()

  function handleSubmit (data) {
    return updateCustomer(match.params.id, data)
  }

  return (
    <PageLayout
      title={t('edit')}
      isLoading={loading}
      back={`/customers/${match.params.id}`}
    >
      <CustomerForm
        customer={customer}
        push={push}
        onSubmit={handleSubmit}
      />
    </PageLayout>
  )
}

CustomerEditPage.propTypes = {
  match: PropTypes.object
}

CustomerEditPage.defaultProps = {
  customer: Map()
}

export default CustomerEditPage
