import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import UserLoginMethods from 'components/UserLoginMethods'
import UserDevices from 'components/UserDevices'

import { providerSchema } from '@seekster/schemas'

export function ProviderTechPane ({ match, t }) {
  return (
    <Fragment>
      <Panel title={t('login_methods')}>
        <UserLoginMethods schema={providerSchema} id={match.params.id} />
      </Panel>

      <Panel title={t('devices')}>
        <UserDevices schema={providerSchema} id={match.params.id} />
      </Panel>
    </Fragment>
  )
}

ProviderTechPane.propTypes = {
  match: PropTypes.object,

  t: PropTypes.func
}

export default withTranslation()(ProviderTechPane)
