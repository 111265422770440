import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

function ConfirmDialog({ title, isOpen, children, onSave, onClose, classes }) {
  const { t } = useTranslation('actions')

  return (
    <Dialog fullWidth maxWidth='xs' fullScreen={false} open={isOpen} onClose={onClose}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button className={[classes.button, classes.disable]} onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button className={classes.button} onClick={onSave} color='primary'>
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const StyledConfirmDialog = withStyles({
  title: {
    color: '#48525D'
  },
  button: {
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  disable: {
    color: '#8E8E93'
  }
})(ConfirmDialog)

export default StyledConfirmDialog
