import React from 'react'
import PropTypes from 'prop-types'
import { FilterAssociationTags } from '../../FilterAssociationTags'

export class FilterClientTags extends React.Component {
  static propTypes = {
    clientName: PropTypes.string,
    fetchClient: PropTypes.func,
    query: PropTypes.object,
    onQueryPush: PropTypes.func
  }

  static defaultProps = {
    clientName: '',

    fetchClient: () => {}
  }

  componentDidMount () {
    const { query, fetchClient } = this.props

    query.client_id && fetchClient(query.client_id)
  }

  renderTag () {
    if (this.props.query.client_id) {
      return (
        <FilterAssociationTags
          content={`Client: ${this.props.clientName}`}
          name='client_id'
          query={this.props.query}
          onQueryPush={this.props.onQueryPush}
        />
      )
    }
    else {
      return null
    }
  }

  render () {
    return this.renderTag()
  }
}

export default FilterClientTags
