import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { getCurrentResource } from 'selectors/authentication'
import { agentSchema } from '@seekster/schemas'

import AgentTechPane from './AgentTechPane'

const mapStateToProps = (state, { match }) => ({
  agent: match.params.id
    ? getResource(state, match.params.id, agentSchema)
    : getCurrentResource(state, agentSchema)
})

export default connect(mapStateToProps)(AgentTechPane)
