import React from 'react'
import PropTypes from 'prop-types'
import { useResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'

import PageLayout from 'components/layouts/PageLayout'
import InvoiceForm from 'forms/resources/InvoiceForm'

import { invoiceSchema } from '@seekster/schemas'

export function InvoiceEditPage ({ match }) {
  const [invoice, { loading }] = useResource(invoiceSchema, match.params.id)
  const { update: updateInvoice } = useResourceAction(invoiceSchema)
  const push = usePush()

  function handleSubmit (data) {
    return updateInvoice(match.params.id, data)
  }

  return (
    <PageLayout
      title={`Edit Invoice - ${invoice.get('number')}`}
      back={`/invoices/${match.params.id}`}
      isLoading={loading}
    >
      <InvoiceForm
        invoice={invoice}
        push={push}
        onSubmit={handleSubmit}
      />
    </PageLayout>
  )
}

InvoiceEditPage.propTypes = {
  match: PropTypes.object
}

export default InvoiceEditPage
