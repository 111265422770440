import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { PersonAddRounded, EditRounded } from '@material-ui/icons'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useCollection } from 'hooks/collections'
import useQuery from 'hooks/useQuery'

import PageLayout from 'components/layouts/PageLayout'
import CircularLoader from 'components/CircularLoader'
import Search from 'components/Search'
import FilterModal from 'components/FilterModal'
import FilterTags from 'components/tags/FilterTags'
import CustomersFilterForm from 'forms/filters/CustomersFilterForm'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import CustomersTable from 'components/tables/CustomersTable'
import Pagination from 'components/Pagination'

import { customerSchema } from '@seekster/schemas'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

export function CustomerIndexPage() {
  const classes = useStyles()
  const [query] = useQuery()
  const { t } = useTranslation('customers')
  const [customers, { loading, totalCount, totalPages }] = useCollection(
    customerSchema,
    { page: query.page, query },
    [query]
  )

  function primaryActions() {
    return [
      {
        avatar: <PersonAddRounded fontSize='small' />,
        content: t('new'),
        to: '/customers/new'
      }
    ]
  }

  function secondaryActions() {
    return [
      {
        avatar: <EditRounded />,
        content: t('generate'),
        to: '/customers/generate'
      }
    ]
  }

  return (
    <PageLayout
      title={t('title')}
      primaryActions={primaryActions()}
      actions={secondaryActions()}
    >
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={10}>
            <Search />
          </Grid>

          <Grid item xs={3} sm={2}>
            <FilterModal component={CustomersFilterForm} />
          </Grid>
        </Grid>
      </div>
      {loading ? (
        <CircularLoader />
      ) : (
        <>
          <div className={classes.metaContainer}>
            <FilterTags />

            <ResultsCountLabel value={totalCount} unit='customer' />

            <CustomersTable customers={customers} />
          </div>

          <Grid item xs={12} container justify='center'>
            <Pagination totalPages={totalPages} />
          </Grid>
        </>
      )}
    </PageLayout>
  )
}

export default CustomerIndexPage
