import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'
import { inquirySchema } from '@seekster/schemas'

import PageLayout from 'components/layouts/PageLayout'

import InquiryForm from 'forms/resources/InquiryForm'

export function InquiryEditPage ({ match }) {
  const { t } = useTranslation(['inquiries'])
  const [inquiry, { loading }] = useResource(inquirySchema, match.params.id)
  const { update: updateInquiry } = useResourceAction(inquirySchema)
  const push = usePush()

  function handleSubmit (data) {
    return updateInquiry(match.params.id, data)
  }

  return (
    <PageLayout
      title={t('edit')}
      isLoading={loading}
      back={`/inquiries/${match.params.id}`}
    >
      <InquiryForm
        inquiry={inquiry}
        push={push}
        onSubmit={handleSubmit}
        disabledOrdersSection
        disabledJobsSection
        disabledUserSection
      />
    </PageLayout>
  )
}

InquiryEditPage.propTypes = {
  match: PropTypes.object
}

export default InquiryEditPage
