import React from 'react'
import Numeral from 'numeral'
import { useTranslation } from 'react-i18next'

import {
  Grid,
  Paper,
  Table as MaterialTable,
  TableCell,
  TableRow,
  TableBody,
  TableHead
} from '@material-ui/core'

export function RequestPricingSection({ lineItems, ordersAttributes, routePrice }) {
  let selectedItems
  let totalAmount = 0
  const { t } = useTranslation(['attributes'])

  ordersAttributes.map((order) => {
    selectedItems = lineItems.filter((item) => item.get('id') === order.package_id)
    selectedItems.forEach((item) => {
      totalAmount += item.getIn(['price', 'decimal'], 0) * order.quantity
    })

    return totalAmount
  })

  return (
    <Grid container spacing={3} style={{ flexDirection: 'row-reverse' }}>
      <Grid item xs={12} sm={6} style={{ margin: '8px 0' }}>
        <Paper>
          <MaterialTable>
            <TableHead>
              <TableRow>
                <TableCell>{t('description')}</TableCell>
                <TableCell align='right'>{t('quantity')}</TableCell>
                <TableCell align='right'>{t('price')}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {ordersAttributes.map((order) => {
                selectedItems = lineItems.filter(
                  (item) => item.get('id') === order.package_id
                )
                return (
                  <>
                    {selectedItems.map((selectedItem) => {
                      return (
                        <TableRow key={selectedItem.get('id')}>
                          <TableCell scope='row'>
                            <b>{selectedItem.get('name')}</b>
                          </TableCell>
                          <TableCell align='right'>
                            <b>{order.quantity}</b>
                          </TableCell>
                          <TableCell align='right'>
                            <b>
                              {selectedItem.getIn(['price', 'decimal'], 0) *
                                order.quantity}
                            </b>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </>
                )
              })}

              <TableRow>
                <TableCell scope='row'>
                  <b>{t('logistics_price')}</b>
                </TableCell>
                <TableCell align='right'>&nbsp;</TableCell>
                <TableCell align='right'>
                  <b>{routePrice.decimal}</b>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell scope='row'>&nbsp;</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>

              <TableRow>
                <TableCell scope='row' />
                <TableCell align='right'>
                  <b style={{ color: '#4183c4' }}>{t('total')}</b>
                </TableCell>
                <TableCell align='right'>
                  <h2 style={{ color: '#4183c4' }}>
                    {Numeral(totalAmount + routePrice.decimal).format('0,0[.]00')}
                  </h2>
                </TableCell>
              </TableRow>
            </TableBody>
          </MaterialTable>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default RequestPricingSection
