import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'

import AgentForm from 'forms/resources/AgentForm'

export function AgentNewPage ({ createAgent, push, t }) {
  function handleSubmit (data) {
    return (
      createAgent(data)
        .then(({ body }) => push(`/agents/${body.id}`))
    )
  }

  function renderBreadcrumbs () {
    return [
      { text: t('title'), link: '/agents' },
      { text: t('new') }
    ]
  }

  return (
    <PageLayout title={t('new')} breadcrumbs={renderBreadcrumbs()}>
      <AgentForm onSubmit={handleSubmit} />
    </PageLayout>
  )
}

AgentNewPage.propTypes = {
  createAgent: PropTypes.func,
  push: PropTypes.func,

  t: PropTypes.func
}

export default withTranslation('agents')(AgentNewPage)
