import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, update } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { issueSchema } from '@seekster/schemas'

import ProviderIssueEditPage from './ProviderIssueEditPage'

const mapStateToProps = (state, { match }) => ({
  issue: getResource(state, match.params.id, issueSchema)
})

const mapDispatchToProps = {
  fetchIssue: id => fetch(id, issueSchema),
  updateIssue: (id, data) => update(id, data, issueSchema),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderIssueEditPage)
