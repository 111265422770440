import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { compose } from 'redux'

import { connect } from 'formik'

import { Field, FastField } from 'components/fields/Field'
import GoogleMapInput from 'components/inputs/GoogleMapInput'
import addressComponentExtractor from 'utils/addressComponentExtractor'
import { withTranslation } from 'react-i18next'

export class AddressFormSection extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    t: PropTypes.func,
    formik: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.handleMapChange = this.handleMapChange.bind(this)
  }

  handleMapChange(
    { latitude, longitude },
    { address_components: addressComponents },
    placeName
  ) {
    const { name } = this.props
    const { values, setFieldValue } = this.props.formik

    let newValues = {
      ...values[name],
      latitude,
      longitude
    }

    if (addressComponents) {
      const extractAddressComponent = addressComponentExtractor(addressComponents)

      newValues = {
        ...newValues,
        house_number: extractAddressComponent('street_number'),
        project_name: placeName || extractAddressComponent('premise'),
        street: extractAddressComponent('route'),
        sub_district: extractAddressComponent(['sublocality_level_2', 'locality']),
        district: extractAddressComponent([
          'sublocality_level_1',
          'administrative_area_level_2'
        ]),
        province: extractAddressComponent('administrative_area_level_1'),
        country: extractAddressComponent('country'),
        zip_code: extractAddressComponent('postal_code')
      }
    }

    setFieldValue(name, newValues)
  }

  render() {
    const { name, t } = this.props

    return (
      <div style={{ padding: '10px 16px' }}>
        <Grid container>
          <Grid item xs={12}>
            <Field
              name={name}
              noLabel
              component={GoogleMapInput}
              onChange={this.handleMapChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField label={t('adress_name')} name={`${name}.name`} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField label={t('contact_name')} name={`${name}.contact_name`} />
          </Grid>

          <Grid item xs={10} sm={4}>
            <FastField label={t('phone_number')} name={`${name}.phone_number`} required />
          </Grid>
          <Grid item xs={2} sm={2}>
            <FastField label={t('extension_number')} name={`${name}.extension_number`} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField label={t('house_number')} name={`${name}.house_number`} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField label={t('project_name')} name={`${name}.project_name`} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField label={t('landmark')} name={`${name}.landmark`} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField label={t('street')} name={`${name}.street`} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField label={t('province')} name={`${name}.province`} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField label={t('district')} name={`${name}.district`} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField label={t('sub_district')} name={`${name}.sub_district`} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField label={t('zip_code')} name={`${name}.zip_code`} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField label={t('country')} name={`${name}.country`} />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default compose(connect, withTranslation('attributes'))(AddressFormSection)
