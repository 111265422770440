import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Tab, Badge } from '@material-ui/core'

import styles from './NavigationMenuItemStyles'

export function NavigationMenuItem(props) {
  const {
    label,
    value,
    to,
    badge: badgeContent,
    classes: { root, selected, badge },
    ...rest
  } = props

  return (
    <Tab
      label={
        badgeContent ? (
          <Badge badgeContent={badgeContent} color='secondary' classes={{ badge }}>
            {label}
          </Badge>
        ) : (
          label
        )
      }
      value={value}
      to={to}
      component={Link}
      classes={{ root, selected }}
      {...rest}
    />
  )
}

NavigationMenuItem.propTypes = {
  label: PropTypes.node,
  badge: PropTypes.number,
  value: PropTypes.number,
  to: PropTypes.string,
  classes: PropTypes.object
}

export default withStyles(styles)(NavigationMenuItem)
