import { Map, List } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import moment from 'moment'

import keyIn from 'utils/keyIn'
import addPositionToCollection from 'utils/addPositionToCollection'
import withFlashMessage from 'hoc/withFlashMessage'

import ServiceForm from './ServiceForm'

const questionsAttributesKeys = [
  'name_en',
  'name_th',
  'placeholder_en',
  'placeholder_th',
  'display_type',
  'position',
  'has_other_option',
  'visible_to_customer',
  'required'
]

const choicesAttributesKeys = [
  'value_en',
  'value_th',
  'description_en',
  'description_th',
  'position'
]

const packagesChildrenAttributesKeys = [
  'name',
  'name_en',
  'name_th',
  'unit_en',
  'unit_th',
  'description_en',
  'description_th',
  'code',
  'item_type',
  'unit_type',
  'duration',
  'position',
  'minimum_amount',
  'maximum_amount',
  'price_satangs',
  'price_currency',
  'payout_satangs',
  'payout_currency',
  'minimum_price_satangs',
  'minimum_price_currency',
  'minimum_payout_satangs',
  'minimum_payout_currency',
  'badge_ids'
]

const packagesAttributesKeys = [
  ...packagesChildrenAttributesKeys,
  'children_attributes',
  'subscription_line_items_attributes'
]

const subscriptionLineItemsAttributesKeys = [
  'quantity_of_weekdays',
  'price_satangs',
  'price_currency'
]

const scheduleAttributesKeys = ['amount', 'type']

const logisticsPricingsAttributesKeys = [
  'starting_distance',
  'ending_distance',
  'payout_satangs',
  'price_per_km_currency',
  'price_per_km_satangs',
  'payout_per_km_satangs',
  'payout_per_km_currency'
]

const formikConfig = {
  mapPropsToValues: ({
    service = Map(),
    withoutAssociationIds,
    withoutPackageCode,
    withoutSlotId
  }) => {
    const packages = service.get('packages')
    const details = service.get('details')
    const scopes = service.get('scopes')
    const slots = service.get('slots') || List()

    let packagesAttributes = [
      {
        price_currency: 'THB',
        payout_currency: 'THB',
        minimum_price_currency: 'THB',
        minimum_payout_currency: 'THB',
        children_attributes: []
      }
    ]

    let slotsAttributes = []

    let detailsAttributes, scopesAttributes

    const requestQuestionsAttributes = service
      .get('request_questions', List())
      .map((requestQuestion) =>
        requestQuestion
          .filter(keyIn(!withoutAssociationIds && 'id', ...questionsAttributesKeys))
          .merge({
            choices_attributes: requestQuestion
              .get('choices', List())
              .map((choice) =>
                choice
                  .filter(keyIn(!withoutAssociationIds && 'id', ...choicesAttributesKeys))
                  .toJS()
              )
          })
      )
      .toJS()

    const reportQuestionsAttributes = service
      .get('report_questions', List())
      .map((reportQuestion) =>
        reportQuestion
          .filter(keyIn(!withoutAssociationIds && 'id', ...questionsAttributesKeys))
          .merge({
            choices_attributes: reportQuestion
              .get('choices', List())
              .map((choice) =>
                choice
                  .filter(keyIn(!withoutAssociationIds && 'id', ...choicesAttributesKeys))
                  .toJS()
              )
          })
      )
      .toJS()

    const logisticsPricingsAttributes = service
      .get('logistics_pricings', List())
      .toSeq()
      .map((children) =>
        children.filter(
          keyIn(!withoutAssociationIds && 'id', ...logisticsPricingsAttributesKeys)
        )
      )
      .toJS()

    if (packages) {
      packagesAttributes = service
        .get('packages')
        .toSeq()
        .map((pkg) =>
          pkg.merge({
            children_attributes: pkg
              .get('children', List())
              .toSeq()
              .map((children) =>
                children
                  .filter(
                    keyIn(
                      !withoutAssociationIds && 'id',
                      ...packagesChildrenAttributesKeys
                    )
                  )
                  .merge({
                    badge_ids: children
                      .get('badges', List())
                      .map((badge) => badge.get('id'))
                  })
              )
              .toJS(),

            subscription_line_items_attributes: pkg
              .get('subscription_line_items', List())
              .map((item) =>
                item
                  .filter(
                    keyIn(
                      !withoutAssociationIds && 'id',
                      ...subscriptionLineItemsAttributesKeys
                    )
                  )
                  .merge({
                    job_schedule_attributes: item
                      .get('job_schedule')
                      .filter(
                        keyIn(!withoutAssociationIds && 'id', ...scheduleAttributesKeys)
                      ),
                    renewal_schedule_attributes: item
                      .get('renewal_schedule')
                      .filter(
                        keyIn(!withoutAssociationIds && 'id', ...scheduleAttributesKeys)
                      )
                  })
              )
              .toJS(),

            badge_ids: pkg.get('badges', List()).map((badge) => badge.get('id'))
          })
        )
        .map((answer) =>
          answer.filter(keyIn(!withoutAssociationIds && 'id', ...packagesAttributesKeys))
        )
        .toJS()
    }

    if (details) {
      detailsAttributes = service
        .get('details')
        .toSeq()
        .map((detail) =>
          detail
            .filter(
              keyIn(
                !withoutAssociationIds && 'id',
                'content_en',
                'content_th',
                'position'
              )
            )
            .merge({
              remote_icon_url: detail.get('icon_url')
            })
        )
        .toJS()
    }

    if (scopes) {
      scopesAttributes = service
        .get('scopes')
        .toSeq()
        .map((scope) =>
          scope
            .filter(
              keyIn(
                !withoutAssociationIds && 'id',
                'title_en',
                'title_th',
                'body_en',
                'body_th',
                'position'
              )
            )
            .merge({
              remote_icon_url: scope.get('icon_url')
            })
        )
        .toJS()
    }

    if (slots.size) {
      slotsAttributes = slots.toJS().map((slot) => ({
        ...(!withoutSlotId && { id: slot.id }),
        name: slot.name,
        position: slot.position,
        quantity: slot.quantity,
        start_time: moment(slot.start_time).format('HH:mm'),
        end_time: moment(slot.end_time).format('HH:mm')
      }))
    }

    return {
      name_en: service.get('name_en') || '',
      name_th: service.get('name_th') || '',
      description_en: service.get('description_en') || '',
      description_th: service.get('description_th') || '',
      terms_en: service.get('terms_en') || '',
      terms_th: service.get('terms_th') || '',
      category_ids: service
        .get('categories', List())
        .map((category) => category.get('id'))
        .toJS(),
      code: !withoutPackageCode ? service.get('code') || '' : '',
      requestable: service.get('requestable'),
      registerable: service.get('registerable'),
      public: service.get('public'),
      is_popular: service.get('is_popular'),
      slug: service.get('slug') || '',
      _destroy: service.get('_destroy') || false,
      disabled_dates: service.get('disabled_dates') || List(),
      lead_time: service.get('lead_time') || '',
      payment_method_ids: service
        .get('payment_methods', List())
        .map((paymentMethod) => paymentMethod.get('id'))
        .toJS(),
      region_id: service.getIn(['region', 'id']),
      packages_attributes: packagesAttributes,
      logistics_pricings_attributes: logisticsPricingsAttributes,
      request_questions_attributes: requestQuestionsAttributes,
      report_questions_attributes: reportQuestionsAttributes,
      details_attributes: detailsAttributes,
      scopes_attributes: scopesAttributes,
      title_en: service.get('title_en', ''),
      title_th: service.get('title_th', ''),
      keywords: service.get('keywords', List()).toJS(),
      remote_icon_url: service.get('icon_url'),
      remote_banner_en_url: service.get('banner_en_url'),
      remote_banner_th_url: service.get('banner_th_url'),
      remote_thumbnail_url: service.get('thumbnail_url'),
      slots_attributes: slotsAttributes,
      slot_enabled: service.get('slot_enabled')
    }
  },

  validationSchema: Yup.object().shape({
    slots_attributes: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.number().min(0, 'must be greater than or equal to 0')
      })
    )
  }),

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    let valuesWithPositions = addPositionToCollection(values, 'packages_attributes')
    valuesWithPositions = addPositionToCollection(
      valuesWithPositions,
      'request_questions_attributes'
    )
    valuesWithPositions = addPositionToCollection(
      valuesWithPositions,
      'report_questions_attributes'
    )
    valuesWithPositions = addPositionToCollection(
      valuesWithPositions,
      'details_attributes'
    )
    valuesWithPositions = addPositionToCollection(
      valuesWithPositions,
      'scopes_attributes'
    )

    values.packages_attributes.forEach((lineItemAttributes, index) => {
      valuesWithPositions = addPositionToCollection(
        valuesWithPositions,
        `packages_attributes[${index}].children_attributes`
      )
    })

    values.request_questions_attributes.forEach((lineItemAttributes, index) => {
      valuesWithPositions = addPositionToCollection(
        valuesWithPositions,
        `request_questions_attributes[${index}].choices_attributes`
      )
    })

    values.report_questions_attributes.forEach((lineItemAttributes, index) => {
      valuesWithPositions = addPositionToCollection(
        valuesWithPositions,
        `report_questions_attributes[${index}].choices_attributes`
      )
    })

    valuesWithPositions.details_attributes.forEach((detailAttributes, index) => {
      if (detailAttributes.icon) {
        delete valuesWithPositions.details_attributes[index].remote_icon_url
      }
    })

    valuesWithPositions.scopes_attributes.forEach((scopeAttributes, index) => {
      if (scopeAttributes.icon) {
        delete valuesWithPositions.scopes_attributes[index].remote_icon_url
      }
    })

    if (valuesWithPositions.icon) {
      delete valuesWithPositions.remote_icon_url
    }

    if (valuesWithPositions.banner_en) {
      delete valuesWithPositions.remote_banner_en_url
    }

    if (valuesWithPositions.banner_th) {
      delete valuesWithPositions.remote_banner_th_url
    }

    if (valuesWithPositions.thumbnail) {
      delete valuesWithPositions.remote_thumbnail_url
    }

    props
      .onSubmit(valuesWithPositions)
      .then((response) => {
        props.push(`/services/${response.body.id}`)
      })
      .catch((error) => {
        setSubmitting(false)

        props.setFlashMessage(
          'error',
          error.response.body ? error.response.body.message : 'Internal Server Error'
        )
      })
  }
}

export default compose(
  withFlashMessage,
  withRouter,
  withFormik(formikConfig)
)(ServiceForm)
