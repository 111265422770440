import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { Form } from 'formik'
import { Button } from '@material-ui/core'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import ButtonLoader from 'components/buttons/ButtonLoader'
import FastField from 'components/fields/Field'

import styles from './IntegrationConfigFormStyles'

export function IntegrationConfigForm ({ integrationConfigs, isSubmitting, values, dirty, resetForm, classes, t }) {
  function handleResetForm () {
    resetForm()
  }

  return (
    <Form>
      {
        integrationConfigs.map(integrationConfig => {
          const index = values.integration_configurations_attributes
            .findIndex(config => config.id === integrationConfig.get('id'))

          return (
            <FastField
              key={integrationConfig.get('id')}
              label={integrationConfig.getIn(['definition', 'name'])}
              name={`integration_configurations_attributes.${index}.value`}
            />
          )
        })
      }
      {
        dirty && (
          <div className={classes.formActions}>
            <Button
              type='button'
              className={classes.cancelButton}
              onClick={handleResetForm}
            >
              {t('cancel')}
            </Button>

            <ButtonLoader
              type='submit'
              color='primary'
              className={classes.saveButton}
              isLoading={isSubmitting}
            >
              {t('save')}
            </ButtonLoader>
          </div>
        )
      }
    </Form>
  )
}

IntegrationConfigForm.propTypes = {
  integrationConfigs: ImmutablePropTypes.list,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  resetForm: PropTypes.func,
  classes: PropTypes.object,

  t: PropTypes.func
}

IntegrationConfigForm.defaultProps = {
  integrationConfigs: List()
}

export default compose(
  withStyles(styles),
  withTranslation()
)(IntegrationConfigForm)
