import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'

import Comments from 'modules/comments/Comments'

export class PromoCodeCommentsPane extends React.Component {
  static propTypes = {
    promoCode: ImmutablePropTypes.map,
    match: PropTypes.object,
    fetchPromoCodeComments: PropTypes.func,
    createPromoCodeComment: PropTypes.func
  }

  static defaultProps = {
    promoCode: Map()
  }

  render () {
    const comments = this.props.promoCode.get('comments') || List()

    return (
      <Comments
        id={this.props.match.params.id}
        loadComments={this.props.fetchPromoCodeComments}
        create={this.props.createPromoCodeComment}
        comments={comments}
      />
    )
  }
}

export default PromoCodeCommentsPane
