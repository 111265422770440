import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import DatePicker from 'components/inputs/DatePicker'
import SlotTimeField from 'components/fields/SlotTimeField'
import { hourFormat } from 'utils/dateFormat'

export function DateSlotTimePicker({ serviceId, value, setFieldValue, job }) {
  const { t } = useTranslation('attributes')
  const date = moment(value.start_time)
  const [currentDate, setCurrentDate] = useState(date)

  function handleDateChange(newDate) {
    setCurrentDate(newDate)
    setFieldValue('start_time', null)
    setFieldValue('end_time', null)
  }

  function handleSlotChange(startTime, endTime) {
    const [startHour, startMin] = startTime.split(':')
    const [endHour, endMin] = endTime.split(':')

    const newStartTime = currentDate.hour(startHour).minute(startMin).toISOString(true)
    const newEndTime = currentDate.hour(endHour).minute(endMin).toISOString(true)
    setFieldValue('start_time', newStartTime)
    setFieldValue('end_time', newEndTime)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <DatePicker
          date={currentDate}
          onChange={handleDateChange}
          focused={Boolean(date)}
          initialVisibleMonth={() => date}
          disabledPastDate
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <div style={{ marginBottom: 10 }}>
          {`${t('start_time')}: ${hourFormat(job.get('start_time'))} ${t(
            'end_time'
          )}: ${hourFormat(job.get('end_time'))}`}
        </div>
        <SlotTimeField
          value={value}
          currentDate={currentDate.format()}
          handleSlotChange={handleSlotChange}
          serviceId={serviceId}
        />
      </Grid>
    </Grid>
  )
}

DateSlotTimePicker.propTypes = {
  serviceId: PropTypes.number,
  value: PropTypes.object,
  setFieldValue: PropTypes.func,
  job: PropTypes.object
}

export default DateSlotTimePicker
