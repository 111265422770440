import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { payoutSchema } from '@seekster/schemas'

import PayoutOverviewPane from './PayoutOverviewPane'

const mapStateToProps = (state, { match }) => ({
  payout: getResource(state, match.params.id, payoutSchema)
})

export default connect(mapStateToProps)(PayoutOverviewPane)
