import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Grid } from '@material-ui/core'
import ButtonLoader from 'components/buttons/ButtonLoader'

import { Form } from 'formik'
import { withTranslation } from 'react-i18next'

import { Field, FastField } from 'components/fields/Field'
import ResourceSelect from 'components/inputs/ResourceSelect'
import DateInputField from 'components/fields/DateInputField'
import DropzoneInput from 'components/inputs/DropzoneInput'
import GenderPicker from 'components/inputs/GenderPicker'
import ResourceCreatableSelect from 'components/inputs/ResourceCreatableSelect'
import {
  tagSchema,
  customerSchema,
  customerTypeSchema,
  agentSchema
} from '@seekster/schemas'

import './CustomerForm.scss'

export function CustomerForm({ customer, isSubmitting, t }) {
  return (
    <Form>
      <Grid container className='customer-container'>
        <Grid xs={12} sm={3} style={{ padding: 8 }}>
          <Field
            name='avatar'
            component={DropzoneInput}
            width={500}
            height={500}
            initialValue={customer.get('avatar_url')}
          />
        </Grid>

        <Grid container xs={9} spacing={3} className='customer-details'>
          <Grid item xs={12} sm={6}>
            <Field name='first_name' />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field name='last_name' />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field name='alias' />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field name='gender' type='radio' component={GenderPicker} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field name='email' type='email' />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field name='phone_number' maxLength={10} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DateInputField name='date_of_birth' />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              name='tag_ids'
              component={ResourceCreatableSelect}
              schema={tagSchema}
              parentSchema={customerSchema}
              parentId={customer.get('id')}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField
              name='customer_type_id'
              component={ResourceSelect}
              schema={customerTypeSchema}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField
              name='responsible_by_id'
              component={ResourceSelect}
              schema={agentSchema}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FastField
              name='support_by_id'
              component={ResourceSelect}
              schema={agentSchema}
            />
          </Grid>

          <Grid container justify='flex-end'>
            <ButtonLoader
              type='submit'
              variant='contained'
              color='primary'
              isLoading={isSubmitting}
            >
              {t('save')}
            </ButtonLoader>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  )
}

CustomerForm.propTypes = {
  customer: ImmutablePropTypes.map,
  isSubmitting: PropTypes.bool,

  t: PropTypes.func
}

CustomerForm.defaultProps = {
  customer: Map()
}

export default withTranslation(['customers', 'actions'])(CustomerForm)
