import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Grid, Chip, FormControlLabel, Checkbox, TextareaAutosize } from '@material-ui/core'
import { Phone, MailOutline } from '@material-ui/icons'
import { Form } from 'formik'
import { components } from 'react-select'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import ButtonLoader from 'components/buttons/ButtonLoader'
import Panel from 'components/containers/Panel'
import { Tabs, TabPane } from 'components/Tabs'
import { Field, FastField } from 'components/fields/Field'
import ResourceSelect from 'components/inputs/ResourceSelect'
import CapitalizeFormatter from 'components/formatters/CapitalizeFormatter'
import PhoneNumberFormatter from 'components/formatters/PhoneNumberFormatter'
import Item from 'components/Item'
import Tag from 'components/Tag'
import { serviceSchema, customerSchema, addressSchema } from '@seekster/schemas'

import AddressFormSection from 'modules/addresses/AddressFormSection'
import SubscriptionJobsFormSection from 'modules/subscriptions/SubscriptionJobsFormSection'
import {
  BookingCustomerFormSection,
  BookingQuestionsFormSection,
  BookingOrdersFormSection
} from 'modules/bookings'

import styles from './SubscriptionFormStyles'

export function SubscriptionForm ({
  subscription,
  fetchAddress,
  values,
  setFieldValue,
  isSubmitting,
  setValues,
  formState,
  setFormState,
  disabledJobsSection,
  showReferenceValues,
  disabledOrdersSection,
  classes,
  t
}) {
  function handleAddressChange (addressId) {
    if (addressId) {
      fetchAddress(addressId).then(({ body }) => {
        setFieldValue('location_attributes', {
          ...values.location_attributes,
          latitude: body.latitude,
          longitude: body.longitude,
          name: body.name,
          contact_name: body.contact_name,
          phone_number: body.phone_number,
          house_number: body.house_number,
          project_name: body.project_name,
          street: body.street,
          sub_district: body.sub_district,
          district: body.district,
          province: body.province,
          country: body.country,
          zip_code: body.zip_code
        })
      })
    }
  }

  function handleRememberAddress () {
    setFormState({ rememberAddress: !formState.rememberAddress })
  }

  function handleCustomerTabChange () {
    setValues({ ...values, customer_id: undefined, customer_attributes: undefined })
  }

  return (
    <Grid container spacing={3} justify='center' component={Form}>
      <Grid item xs={12} sm={9}>
        <Panel title={t('form.date_time.title')}>
          <SubscriptionJobsFormSection
            weekdaysName='wdays'
            startDateName='start_date'
            readOnly={disabledJobsSection}
            referenceJobs={showReferenceValues && {
              startDate: subscription.get('start_date'),
              weekdays: subscription.get('weekdays').keySeq().toJS()
            }}
          />
        </Panel>

        <div className={classes.customerSection}>
          <Tabs
            onTabChange={handleCustomerTabChange}
            contained
            scrollButtons='off'
            classes={{ flexContainer: classes.tabs }}
          >
            <TabPane label={t('form.customer.existing')} className={classes.tab}>
              <Field
                name='customer_id'
                noLabel
                component={ResourceSelect}
                schema={customerSchema}
                styles={{
                  valueContainer: base => ({ ...base, height: 60, fontSize: 16 })
                }}
                components={{
                  Option: ({ data, ...rest }) => (
                    <components.Option {...rest}>
                      <Item
                        name={data.display_name}
                        avatar={data.avatar_url}
                        avatarProps={{ className: classes.customerAvatar }}
                      >
                        <Chip
                          icon={<MailOutline color='disabled' fontSize='small' />}
                          label={data.email}
                          variant='outlined'
                          className={classes.chip}
                        />

                        <Chip
                          icon={<Phone color='disabled' fontSize='small' />}
                          label={<PhoneNumberFormatter value={data.phone_number} />}
                          variant='outlined'
                          className={classes.chip}
                        />
                      </Item>
                    </components.Option>
                  ),
                  SingleValue: ({ children, data, ...rest }) => (
                    <components.SingleValue {...rest}>
                      <Item name={data.display_name} avatar={data.avatar_url} />
                    </components.SingleValue>
                  )
                }}
              />
            </TabPane>

            <TabPane label={t('form.customer.new')} className={classes.tab}>
              <BookingCustomerFormSection attributeScope='customer_attributes' />
            </TabPane>
          </Tabs>
        </div>

        <Panel title={t('form.location.title')}>
          <label>{t('form.location.customer_address')}</label>

          <ResourceSelect
            key={values.customer_id}
            schema={addressSchema}
            parentSchema={customerSchema}
            parentId={values.customer_id}
            getOptionLabel={({ short_address: shortAddress }) => shortAddress}
            onChange={handleAddressChange}
          />

          <AddressFormSection name='location_attributes' />

          <FormControlLabel
            label={t('form.location.remember_address')}
            control={
              <Checkbox
                checked={formState.rememberAddress}
                onChange={handleRememberAddress}
                color='primary'
              />
            }
          />
        </Panel>

        <Panel title={t('form.service.title')}>
          <Field
            name='service_id'
            component={ResourceSelect}
            schema={serviceSchema}
            noLabel
            isDisabled={disabledOrdersSection}
            styles={{
              valueContainer: base => ({ ...base, height: 60, fontSize: 16 })
            }}
            components={{
              Option: ({ data, ...rest }) => (
                <components.Option {...rest}>
                  <Item
                    name={
                      <>
                        {data.name} <Tag value={data.region.name} formatter={CapitalizeFormatter} />
                      </>
                    }
                    avatar={data.icon_url}
                  />
                </components.Option>
              ),
              SingleValue: ({ data, children, ...rest }) => (
                <components.SingleValue {...rest}>
                  <Item
                    name={
                      <>
                        {data.name} <Tag value={data.region.name} formatter={CapitalizeFormatter} />
                      </>
                    }
                    avatar={data.icon_url}
                  />
                </components.SingleValue>
              )
            }}
          />

          {
            values.service_id && (
              <Grid container spacing={5} >
                <Grid item xs={12} style={disabledOrdersSection && { pointerEvents: 'none', opacity: 0.7 }}>
                  <BookingOrdersFormSection name='orders_attributes' />
                </Grid>

                <Grid item xs={12}>
                  <BookingQuestionsFormSection name='answers_attributes' />
                </Grid>
              </Grid>
            )
          }
        </Panel>

        <Panel title={t('form.additional_information.title')}>
          <FastField
            name='additional_information'
            component={TextareaAutosize}
            noLabel
          />
        </Panel>

        <ButtonLoader
          type='submit'
          variant='contained'
          color='primary'
          style={{ float: 'right' }}
          isLoading={isSubmitting}
        >
          {t('save')}
        </ButtonLoader>
      </Grid>
    </Grid>
  )
}

SubscriptionForm.propTypes = {
  subscription: ImmutablePropTypes.map,
  fetchAddress: PropTypes.func,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  setValues: PropTypes.func,
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  disabledJobsSection: PropTypes.bool,
  showReferenceValues: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  disabledOrdersSection: PropTypes.bool,
  classes: PropTypes.object,

  t: PropTypes.func
}

SubscriptionForm.defaultProps = {
  subscription: Map()
}

export default compose(
  withStyles(styles),
  withTranslation('subscriptions')
)(SubscriptionForm)
