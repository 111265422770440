import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Switch, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './SwitchInputStyles'

export function SwitchInput({
  value,
  onChange,
  name,
  switchLabel,
  disabled,
  classes,
  ...rest
}) {
  return (
    <FormControlLabel
      className={classes.input}
      label={<Typography variant='subtitle1'>{switchLabel}</Typography>}
      control={
        <Switch
          {...rest}
          inputProps={{ name }}
          checked={!!value}
          onChange={onChange}
          disabled={disabled}
          classes={
            disabled &&
            !!value && {
              disabled: classes.disabled,
              bar: classes.bar
            }
          }
        />
      }
    />
  )
}

SwitchInput.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  switchLabel: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.object
}

export default withStyles(styles)(SwitchInput)
