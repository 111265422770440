import { Map } from 'immutable'
import * as Yup from 'yup'

import { withFormik } from 'formik'
import { compose } from 'redux'
import pluralize from 'pluralize'

import withFlashMessage from 'hoc/withFlashMessage'

import AddressForm from './AddressForm'

const formikConfig = {
  mapPropsToValues: ({ address = Map() }) => ({
    name: address.get('name') || '',
    phone_number: address.get('phone_number') || '',
    extension_number: address.get('extension_number') || '',
    house_number: address.get('house_number') || '',
    project_name: address.get('project_name') || '',
    street: address.get('street') || '',
    province: address.get('province') || '',
    sub_district: address.get('sub_district') || '',
    district: address.get('district') || '',
    zip_code: address.get('zip_code') || '',
    latitude: address.get('latitude') || 13.7563,
    longitude: address.get('longitude') || 100.5018
  }),

  validationSchema: Yup.object().shape({
    phone_number: Yup.string().matches(/^0[23456789]\d{7,8}$/, 'Invalid phone number')
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit(values)
      .then(({ body }) => {
        props.push(
          `/${pluralize.plural(body.owner_type.toLowerCase())}/${body.owner_id}/addresses`
        )
      })
      .catch((error) => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(withFlashMessage, withFormik(formikConfig))(AddressForm)
