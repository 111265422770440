import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ transitions, mixins }) => ({
  main: {
    flexGrow: 1,
    transition: ({ sidebarOpen }) => transitions.create('margin', {
      easing: sidebarOpen ? transitions.easing.easeOut : transitions.easing.sharp,
      duration: sidebarOpen ? transitions.duration.enteringScreen : transitions.duration.leavingScreen
    }),
    '@media screen and (min-width: 767px)': {
      marginLeft: ({ sidebarOpen }) => sidebarOpen ? 250 : 0
    }
  },

  toolbarSpacer: mixins.toolbar
}))
