import React from 'react'
import { Table, Column } from 'components/tables/Table'
import { Paper } from '@material-ui/core'

import { DateTag } from 'components/tags'

export function PayoutsTable ({ payouts }) {
  return (
    <Paper>
      <Table collection={payouts}>
        <Column
          name='id'
          to={payout => `/payouts/${payout.get('id')}`}
        />
        <Column
          name='amount'
          render={(amount, payout) => <p>฿{payout.getIn(['amount_satangs']) / 100}</p>}
        />
        <Column
          name='description'
          render={(description, payout) => <p>{payout.get('description')}</p>}
        />
        <Column name='created_at' component={DateTag} />
      </Table>
    </Paper>
  )
}

export default PayoutsTable
