import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field as OrginalField } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/buttons'
import { Field, FastField } from 'components/fields/Field'
import Checkbox from 'components/inputs/Checkbox'
import DateRangeField from 'components/fields/DateRangeField'
import ResourceSelect from 'components/inputs/ResourceSelect'
import SelectInput from 'components/inputs/SelectInput'
import {
  serviceSchema,
  regionSchema,
  clientSchema,
  customerSchema,
  providerSchema
} from '@seekster/schemas'
import arrayToOptions from 'utils/arrayToOptions'
import { REQUEST_STATES } from 'constants/states/requestStates'

import components from './serviceSelectModifiers'
export function RequestsFilterForm({ handleSubmit, onCloseModal }) {
  const { t } = useTranslation('fields')

  function onHandleSubmit() {
    handleSubmit()
    onCloseModal()
  }

  return (
    <Form>
      <label style={{ marginLeft: '-10px' }}>
        <OrginalField name='created_by_id' component={Checkbox} />
        {t('label.show_my_inquiries')}
      </label>
      <DateRangeField name='jobs_start_date' />
      <DateRangeField name='created_at' />
      <FastField
        name='state'
        component={SelectInput}
        options={arrayToOptions(REQUEST_STATES, {
          i18nNamespace: 'inquiries',
          i18nKey: 'states'
        })}
      />
      <Field
        name='service_id'
        component={ResourceSelect}
        schema={serviceSchema}
        components={components}
      />
      <Field name='region_id' component={ResourceSelect} schema={regionSchema} />
      <Field name='client_id' component={ResourceSelect} schema={clientSchema} />
      <Field name='customer_id' component={ResourceSelect} schema={customerSchema} />
      <Field name='provider_id' component={ResourceSelect} schema={providerSchema} />
      <div>
        <Button color='primary' onClick={onHandleSubmit}>
          Apply
        </Button>

        <Button onClick={onCloseModal}>Cancel</Button>
      </div>
    </Form>
  )
}

RequestsFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  onCloseModal: PropTypes.func
}

export default RequestsFilterForm
