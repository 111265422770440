import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { update } from 'actions/resources'
import { triggerChild } from 'actions/resourceEvent'
import { invoiceSchema, paymentSchema } from '@seekster/schemas'

import BankTransferPaymentReviewModal from './BankTransferPaymentReviewModal'

const mapDispatchToProps = {
  fetchPayments: (id, options) => fetchChildren(invoiceSchema, id, paymentSchema, options),
  updatePayment: (id, data, options) => update(id, data, paymentSchema, options),
  capturePayment: (invoiceId, paymentId, options) => triggerChild(invoiceSchema, invoiceId, paymentSchema, paymentId, 'capture', options),
  voidPayment: (invoiceId, paymentId, options) => triggerChild(invoiceSchema, invoiceId, paymentSchema, paymentId, 'void', options)
}

export default connect(null, mapDispatchToProps)(BankTransferPaymentReviewModal)
