import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Grid, Avatar, Typography } from '@material-ui/core'
import { MailOutline, Phone, DomainRounded } from '@material-ui/icons'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import AttributeLabel from 'components/labels/AttributeLabel'
import PhoneNumberFormatter from 'components/formatters/PhoneNumberFormatter'
import MailFormatter from 'components/formatters/MailFormatter'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import BooleanChip from 'components/BooleanChip'
import Tag from 'components/Tag'

import styles from './AgentOverviewPaneStyles'

export function AgentOverviewPane ({ agent, classes, t }) {
  return (
    <Grid container>
      <Grid item xs={12} sm={5}>
        <Panel title={t('details')}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Avatar
                src={agent.get('avatar_url')}
                alt={agent.get('display_name')}
                className={classes.avatar}
              />
            </Grid>

            <Grid item xs={12}>
              <AttributeLabel title={`${t('id')} ${agent.get('id')}`}>
                <Typography variant='h5' gutterBottom className={classes.displayName}>
                  {agent.get('display_name')}
                </Typography>
              </AttributeLabel>
            </Grid>

            <Grid item xs={12}>
              <MailOutline color='action' fontSize='small' className={classes.icon} />
              <MailFormatter value={agent.get('email')} />
            </Grid>

            <Grid item xs={12}>
              <Phone color='action' fontSize='small' className={classes.icon} />
              <PhoneNumberFormatter value={agent.get('phone_number')} />
            </Grid>

            <Grid item xs={12}>
              <DomainRounded color='action' fontSize='small' className={classes.icon} />
              <Tag value={agent.getIn(['department', 'name'])} />
            </Grid>

            <Grid item xs={12}>
              <BooleanChip name='active' value={agent.get('active')} />
            </Grid>

            <Grid item xs={12}>
              <AttributeLabel title={t('created_at')}>
                <DateTimeFormatter value={agent.get('created_at')} />
              </AttributeLabel>
            </Grid>
          </Grid>
        </Panel>
      </Grid>
    </Grid>
  )
}

AgentOverviewPane.propTypes = {
  agent: ImmutablePropTypes.map,
  classes: PropTypes.object,

  t: PropTypes.func
}

AgentOverviewPane.defaultProps = {
  agent: Map()
}

export default compose(
  withStyles(styles),
  withTranslation()
)(AgentOverviewPane)
