import { Map } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import TenantAccountingConfigForm from './TenantAccountingConfigForm'
import keyIn from 'utils/keyIn'

const formikConfig = {
  mapPropsToValues: ({ accountingConfig = Map() }) => ({
    holding_duration: accountingConfig.get('holding_duration', ''),
    tax_rate: accountingConfig.get('tax_rate', ''),
    withholding_tax_rate: accountingConfig.get('withholding_tax_rate', ''),
    default_currency: accountingConfig.get('default_currency', ''),
    tax_number: accountingConfig.get('tax_number', ''),
    billing_email: accountingConfig.get('billing_email', ''),
    billing_address_attributes: accountingConfig.get('billing_address') && (
      accountingConfig.get('billing_address').filter(keyIn(
        'id',
        'name_en', 'name_th',
        'house_number_en', 'house_number_th',
        'project_name_en', 'project_name_th',
        'floor_en', 'floor_th',
        'alley_en', 'alley_th',
        'street_en', 'street_th',
        'sub_district_en', 'sub_district_th',
        'district_en', 'district_th',
        'province_en', 'province_th',
        'zip_code'
      )).toJS()
    ),
    active: accountingConfig.get('active') || false
  }),

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    holding_duration: Yup.number().min(0).required(),
    tax_rate: Yup.number().min(0).required(),
    withholding_tax_rate: Yup.number().min(0).required(),
    default_currency: Yup.string().required(),
    tax_number: Yup.string(),
    billing_email: Yup.string().email().required(),
    active: Yup.boolean().required()
  }),

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values)
      .catch(error => {
        setSubmitting(false)

        setErrors({ message: error.response.body.message })
      })
  }
}

export default withFormik(formikConfig)(TenantAccountingConfigForm)
