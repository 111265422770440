import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { useNodeResource } from 'hooks/resources'
import { Link } from 'react-router-dom'
import { Menu, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { PersonAddRounded, Phone } from '@material-ui/icons'
import { organizationSchema } from '@seekster/schemas'
import Button from '@material-ui/core/Button'
import NestedMenuItem from 'material-ui-nested-menu-item'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import JobProviderList from 'modules/jobProviders/JobProvidersList'
import OrgAction from './OrgAction'

import './JobProvidersList.scss'

export function JobProvidersList({
  resourceLink,
  job,
  handleClickOpen,
  handleCancelOrg
}) {
  const [orgainzation] = useNodeResource(organizationSchema, job.get('org_id'))
  const [menuPosition, setMenuPosition] = useState(null)
  const { t } = useTranslation('job_provider')

  const handleRightClick = (event) => {
    if (menuPosition) {
      return
    }
    event.preventDefault()
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    })
  }

  const handleItemClick = (mode) => {
    setMenuPosition(null)
    handleClickOpen(mode)
  }

  const handleItemLeave = () => {
    setMenuPosition(null)
  }

  return (
    <div>
      {job.get('org_id') !== null ? (
        <>
          {t('organization')}
          <div className='org-card-container'>
            <div className='main-content org-container'>
              <div className='org-avatar'>
                <img
                  className='org-avatar'
                  src={orgainzation.get('pictureUrl')}
                  alt='avatar'
                />
              </div>
              <div className='name'>
                <div>{orgainzation.get('name')}</div>
              </div>
              <div className='phone-number'>
                <Phone color='action' fontSize='small' className='icon' />
                <div>{orgainzation.get('phoneNumber')}</div>
              </div>
            </div>
            {job.get('state') === 'pending_provider' && (
              <OrgAction
                handleCancelOrg={handleCancelOrg}
                orgId={job.get('org_id')}
                disabled={orgainzation.get('state') !== 'pending_assign'}
              />
            )}
          </div>
        </>
      ) : (
        <>
          {job.get('state') === 'pending_provider' && (
            <div className='job-card-container'>
              <div className='main-content'>
                <Link to={resourceLink}>
                  <PersonAddRounded />
                </Link>
                <span>
                  <div className='title'>
                    <div>{t('waiting_provider_accept_job')}</div>
                  </div>
                </span>
              </div>
              <div className='actions-component'>
                <div>
                  <Button
                    aria-controls='simple-menu'
                    aria-haspopup='true'
                    variant='contained'
                    color='primary'
                    onClick={handleRightClick}
                    endIcon={
                      menuPosition === null ? <ExpandMoreIcon /> : <ExpandLessIcon />
                    }
                  >
                    {t('assign_job')}
                  </Button>
                  <Menu
                    id='simple-menu'
                    open={!!menuPosition}
                    onClose={() => setMenuPosition(null)}
                    anchorReference='anchorPosition'
                    anchorPosition={menuPosition}
                    MenuListProps={{ onMouseLeave: handleItemLeave }}
                  >
                    <MenuItem onClick={() => handleItemClick(3)}>
                      {t('provider')}
                    </MenuItem>
                    <NestedMenuItem
                      label={t('organization')}
                      parentMenuOpen={!!menuPosition}
                    >
                      <MenuItem onClick={() => handleItemClick(1)}>
                        {t('assign_org')}
                      </MenuItem>
                      <MenuItem onClick={() => handleItemClick(2)}>
                        {t('assign_provider_under_org')}
                      </MenuItem>
                    </NestedMenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {!job.get('job_providers', List()).isEmpty() && (
        <>
          {t('provider')}
          <JobProviderList
            jobProviders={job.get('job_providers')}
            jobId={job.get('id')}
          />
        </>
      )}
    </div>
  )
}

JobProvidersList.propTypes = {
  job: ImmutablePropTypes.map,
  resourceLink: PropTypes.string,
  handleClickOpen: PropTypes.func,
  handleCancelOrg: PropTypes.func
}

JobProvidersList.defaultProps = {
  subtitle: () => null,
  primarySupportContent: () => null,
  secondarySupportContent: () => null,
  actionButtons: () => null,
  job: Map()
}

export default JobProvidersList
