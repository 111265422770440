export default ({ spacing }) => ({
  textArea: {
    fontSize: 16
  },

  button: {
    float: 'right',
    marginBottom: spacing(2)
  },

  formContainer: {
    padding: 16
  }
})
