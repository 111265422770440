import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { fetch, createChild, createNodeChild } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { jobSchema, jobProviderSchema } from '@seekster/schemas'

import JobProvidersPane from './JobProvidersPane'

const mapStateToProps = (state, { match }) => ({
  job: getResource(state, match.params.id, jobSchema)
})

const mapDispatchToProps = {
  fetchJob: (id) => fetch(id, jobSchema),
  fetchJobProviders: (id) => fetchChildren(jobSchema, id, jobProviderSchema),
  assignProvider: (id, data) =>
    createChild(data, jobSchema, id, jobProviderSchema, {
      childrenType: 'assign_provider'
    }),
  assignOrgProvider: (id, data) =>
    createNodeChild(data, jobSchema, id, jobProviderSchema, {
      childrenType: 'assign_provider'
    }),
  assignOrg: (id, data) =>
    createNodeChild(data, jobSchema, id, jobProviderSchema, {
      childrenType: 'assign_org'
    }),
  cancelOrg: (id, data) =>
    createNodeChild(data, jobSchema, id, jobProviderSchema, {
      childrenType: 'cancel'
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(JobProvidersPane)
