import React from 'react'
import { usePush } from 'hooks/utils'
import { useTranslation } from 'react-i18next'
import { useResourceAction } from 'hooks/resources'
import { organizationSchema } from '@seekster/schemas'

import PageLayout from 'components/layouts/PageLayout'
import OrganizationForm from 'forms/resources/OrganizationForm'
import Swal from 'sweetalert2'

export function OrganizationNewPage() {
  const { t } = useTranslation('organizations')
  const { nodeCreate: createOrgnaization } = useResourceAction(organizationSchema)
  const push = usePush()

  function handleSubmit(data) {
    return createOrgnaization(data).then(({ body }) => {
      Swal.fire({
        html:
          '<span style="display: inline-flex;">' +
          '<img style="border-radius: 50%;margin-right: 20px;margin-top: 16px;" src="https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/Icon-awesome-check-circle.png" width="30" height="30">' +
          '<p style="color: #1B4171;font-family:Roboto, Helvetica, Arial, sans-serif;">สร้างองค์กร เสร็จเรียบร้อยแล้ว!</p>' +
          '</span>',
        showConfirmButton: false,
        timer: 1000
      }).then(() => {
        push(`/organizations/${body._id}`)
      })
    })
  }

  return (
    <PageLayout title={t('new')} back='/organizations'>
      <OrganizationForm onSubmit={handleSubmit} push={push} />
    </PageLayout>
  )
}

export default OrganizationNewPage
