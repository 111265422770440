export default ({ palette }) => ({
  seeAll: {
    textTransform: 'capitalize',
    color: `${palette.grey[600]} !important`,
    padding: '13px 8px',

    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
})
