import React from 'react'
import PropTypes from 'prop-types'

export class MailTag extends React.Component {
  static propTypes = {
    value: PropTypes.string
  }

  render () {
    if (this.props.value) {
      return (
        <a href={`mailto:${this.props.value}`}>
          {this.props.value}
        </a>
      )
    }
    else {
      return null
    }
  }
}

export default MailTag
