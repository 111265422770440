import React from 'react'
import PropTypes from 'prop-types'
import { connect, getIn } from 'formik'

import { Grid } from '@material-ui/core'
import { Field } from 'components/fields/Field'
import FormSection from 'components/fields/FieldArray/FormSection'
import SortableFieldArray from 'components/fields/SortableFieldArray'

export function TicketMacrosSettingsForm (props) {
  const { name, formik, hasChildren } = props
  const values = getIn(formik.values, name)

  return (
    <FormSection title={values.group} {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field noLabel name={`${name}.group`} required />
        </Grid>

        {
          hasChildren && (
            <Grid item xs={12}>
              <SortableFieldArray
                name={`${name}.value`}
                component={ConnectedTicketMacrosSettingsForm}
              />
            </Grid>
          )
        }
      </Grid>
    </FormSection>
  )
}

TicketMacrosSettingsForm.propTypes = {
  name: PropTypes.string,
  hasChildren: PropTypes.bool,
  formik: PropTypes.object
}

const ConnectedTicketMacrosSettingsForm = connect(TicketMacrosSettingsForm)

export default ConnectedTicketMacrosSettingsForm
