import { Map } from 'immutable'

import { SET_DATA_CANCELLATION } from 'constants/cancellation'

const initialState = Map({
  list: {
    cancel_detail_th: '',
    cancel_detail_en: '',
    cancel_reasons: [],
    cancel_fees: [],
    base_fees: '',
    create_at: ''
  }
})

const cancellationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DATA_CANCELLATION:
      return Map(payload)
    default:
      return state
  }
}

export default cancellationReducer
