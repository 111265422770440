import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Card, CardActionArea, CardHeader, Avatar, Typography } from '@material-ui/core'
import { KeyboardArrowDownRounded } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import Pagination from 'components/Pagination'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'

import styles from './CustomerReferralTreePaneStyles'

export class CustomerReferralTreePane extends React.Component {
  static propTypes = {
    customer: ImmutablePropTypes.map,
    match: PropTypes.object,
    fetchCustomer: PropTypes.func,
    fetchCustomerReferee: PropTypes.func,
    classes: PropTypes.object,
    query: PropTypes.object,
    t: PropTypes.func
  }

  static defaultProps = {
    customer: Map()
  }

  constructor (props) {
    super(props)

    this.state = {
      isLoading: false,
      error: null,
      totalCount: 0,
      totalPages: 1
    }
  }

  componentDidMount () {
    this.handleUpdateData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.query.page !== prevProps.query.page) {
      this.handleUpdateDataOnPageChange()
    }

    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.handleUpdateData()
    }
  }

  handleUpdateData () {
    this.setState({ isLoading: true })

    this.props.fetchCustomer(this.props.match.params.id)
      .then(() => this.props.fetchCustomerReferee(this.props.match.params.id))
      .then(response => {
        this.setState({
          isLoading: false,
          totalCount: parseInt(response.headers['total'], 10),
          totalPages: Math.ceil(response.headers['total'] / response.headers['per-page'])
        })
      })
      .catch(error => {
        this.setState({ isLoading: false, error: error.message })
      })
  }

  handleUpdateDataOnPageChange () {
    const { page } = this.props.query

    this.setState({ isLoading: true })

    this.props.fetchCustomerReferee(this.props.match.params.id, { page })
      .then(() => {
        this.setState({ isLoading: false })
      })
      .catch(error => {
        this.setState({ isLoading: false, error: error.message })
      })
  }

  render () {
    const { customer, classes, t } = this.props

    return (
      <Grid container spacing={1} justify='center'>
        <Typography variant='overline'>{t('referrer')}</Typography>

        <Grid item xs={12} container justify='center'>
          {
            customer.get('referred_by', Map()) ? (
              <Grid item xs={12} sm={3}>
                <Card>
                  <CardActionArea
                    component={Link}
                    to={`/customers/${customer.getIn(['referred_by', 'id'])}/referrals`}
                    className={classes.referrerCard}
                  >
                    <Avatar
                      className={classes.referrerAvatar}
                      src={customer.getIn(['referred_by', 'avatar_url'])}
                      alt={customer.getIn(['referred_by', 'display_name'])}
                    />
                    <Typography variant='h6'>{customer.getIn(['referred_by', 'display_name'])}</Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            ) : (
              <Grid item xs={12} sm={3}>
                <Card className={classes.emptyCard}>
                  <Typography variant='caption'>{t('no_referrer')}</Typography>
                </Card>
              </Grid>
            )
          }

          <Grid item xs={12} className={classes.header}>
            <KeyboardArrowDownRounded fontSize='large' />
            <Typography gutterBottom variant='overline'>{t('self')}</Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Card className={classes.referrerCard}>
              <Avatar
                src={customer.get('avatar_url')}
                alt={customer.get('display_name')}
              />
              <Typography variant='h6' color='textSecondary'>{customer.get('display_name')}</Typography>
            </Card>
          </Grid>

          <Grid item xs={12} className={classes.header}>
            <KeyboardArrowDownRounded fontSize='large' />
            <Typography variant='overline'>
              {t('referees')}
              {this.state.totalCount !== 0 && ` (${this.state.totalCount})`}
            </Typography>
          </Grid>
        </Grid>

        {
          !customer.get('referrals', List()).isEmpty() ? (
            customer.get('referrals').map(referral => (
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardActionArea
                    className={classes.refereeCard}
                    component={Link}
                    to={`/customers/${referral.getIn(['referent', 'id'])}/referrals`}
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          className={classes.refereeAvatar}
                          src={referral.getIn(['referent', 'avatar_url'])}
                          alt={referral.getIn(['referent', 'display_name'])}
                        />
                      }
                      title={
                        <Typography variant='h6'>
                          {referral.getIn(['referent', 'display_name'])}
                        </Typography>
                      }
                      subheader={
                        <Fragment>
                          <Typography variant='caption'>{t('created_at')}</Typography>
                          <DateTimeFormatter value={referral.getIn(['referent', 'created_at'])} />
                        </Fragment>
                      }
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} sm={4}>
              <Card className={classes.emptyCard}>
                <Typography variant='caption'>{t('no_referee')}</Typography>
              </Card>
            </Grid>
          )
        }

        <Pagination totalPages={this.state.totalPages} />
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles),
  withTranslation('customers')
)(CustomerReferralTreePane)
