import { useEffect, useState } from 'react'

export function useData (
  fetcher = () => Promise.resolve({}),
  params = []
) {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [totalCount, setTotalCount] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    setLoading(true)

    fetcher()
      .then(response => {
        setLoading(false)

        setData(response.body)

        setTotalCount(parseInt(response.headers['total'], 10))
        setTotalPages(Math.ceil(response.headers['total'] / response.headers['per-page']))
      })
      .catch(error => {
        setLoading(false)

        setError(error)
      })
  }, params) // eslint-disable-line react-hooks/exhaustive-deps

  return { data, loading, totalCount, totalPages, error }
}

export default useData
