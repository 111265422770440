import { withFormik } from 'formik'
import * as Yup from 'yup'

import AcceptInvitationForm from './AcceptInvitationForm'

const formikConfig = {
  validationSchema: Yup.object().shape({
    password: Yup.string().required()
  }),

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values)
      .catch(error => {
        setSubmitting(false)

        setErrors({ message: error.response.body.message })
      })
  }
}

export default withFormik(formikConfig)(AcceptInvitationForm)
