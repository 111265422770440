import React from 'react'
import { Table, Column } from 'components/tables/Table'
import { Paper } from '@material-ui/core'
import Tag from 'components/Tag'

import DateTimeFormatter from 'components/formatters/DateTimeFormatter'

export function PayrollsStatementTable ({ bankAccount, walletTransactions }) {
  return (
    <Paper>
      <Table collection={walletTransactions}>
        <Column name='id' />
        <Column name='type' />
        <Column name='state' render={value => <Tag round value={value} className='wallet-transaction-states' />} />
        <Column name='amount' textAlign='right' render={amount => amount.get('full_display')} />
        <Column name='fee' textAlign='right' render={fee => fee.get('full_display')} />
        <Column name='description' />
        <Column
          name='bank_account'
          render={bankAccount => (
            bankAccount && `${bankAccount.get('account_name')} [${bankAccount.get('formatted_account_number')}]`
          )}
        />
        <Column
          name='issued_by'
          render={(issuedBy) => issuedBy && !issuedBy.isEmpty() ? (
            `${issuedBy.get('first_name')} ${issuedBy.get('last_name')}`
          ) : (
            'System'
          )}
        />
        <Column name='released_at' component={DateTimeFormatter} />
        <Column name='created_at' component={DateTimeFormatter} />
      </Table>
    </Paper>
  )
}

export default PayrollsStatementTable
