import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'

import SortableFieldArray from 'components/fields/SortableFieldArray'
import CancellationItemForm from './CancellationItemForm'

import './CancellationForm.scss'

const CancellationForm = ({ cancelReasonTypes, onSubmit }) => {
  const { t } = useTranslation('integrations')

  return (
    <Formik
      initialValues={{
        cancel_reason_types_attributes: cancelReasonTypes.toJS()
      }}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className='cancellation-form'>
          <SortableFieldArray
            label='เหตุผลในการขอยกเลิก'
            name='cancel_reason_types_attributes'
            component={CancellationItemForm}
            newItemText='เหตุผลในการขอยกเลิก'
            hasChildren
          />
          <Button
            type='submit'
            variant='contained'
            color='primary'
            size='large'
            disabled={isSubmitting}
            style={{ width: 157, marginTop: 50, float: 'right' }}
          >
            {t('save')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

CancellationForm.propTypes = {
  cancelReasonTypes: ImmutablePropTypes.map,
  onSubmit: PropTypes.func
}

export default CancellationForm
