import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import { Field, FastField } from 'components/fields/Field'
import ResourceSelect from 'components/inputs/ResourceSelect'
import { customerTypeSchema } from '@seekster/schemas'

export function BookingCustomerFormSection({ attributeScope }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Field name={`${attributeScope}.first_name`} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Field name={`${attributeScope}.last_name`} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Field name={`${attributeScope}.email`} type='email' />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Field name={`${attributeScope}.phone_number`} type='tel' maxLength={10} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          name={`${attributeScope}.customer_type_id`}
          component={ResourceSelect}
          schema={customerTypeSchema}
        />
      </Grid>
    </Grid>
  )
}

BookingCustomerFormSection.propTypes = {
  attributeScope: PropTypes.string
}

export default BookingCustomerFormSection
