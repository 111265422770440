import { Map } from 'immutable'
import { withFormik } from 'formik'
import { compose } from 'redux'

import * as Yup from 'yup'
import withFlashMessage from 'hoc/withFlashMessage'

import AgentForm from './AgentForm'

const formikConfig = {
  mapPropsToValues: ({ agent = Map() }) => ({
    first_name: agent.get('first_name', ''),
    last_name: agent.get('last_name', ''),
    department_id: agent.getIn(['department', 'id']),
    email: agent.get('email', ''),
    phone_number: agent.get('phone_number', ''),
    active: agent.get('active', ''),
    admin: agent.get('admin', '')
  }),

  validationSchema: Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string(),
    email: Yup.string().email(),
    phone_number: Yup.string()
      .matches(/(^0[23456789]\d{7,8}$)/, 'Invalid phone number')
      .required()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    // alert(JSON.stringify(values, null, 4))
    // setSubmitting(false)
    props.onSubmit(values).catch((error) => {
      setSubmitting(false)

      props.setFlashMessage('error', error.response.body.message)
    })
  }
}

export default compose(withFlashMessage, withFormik(formikConfig))(AgentForm)
