import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Button } from 'components/buttons'
import { withTranslation } from 'react-i18next'

import FastField from 'components/fields/Field'
import DateRangeField from 'components/fields/DateRangeField'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'

export class CategoriesFilterForm extends React.Component {
  static propTypes = {
    onCloseModal: PropTypes.func,
    t: PropTypes.func
  }

  render () {
    return (
      <Form>
        <DateRangeField name='created_at' />
        <FastField
          name='active'
          component={SelectInput}
          options={arrayToOptions(['true', 'false'], { i18nNamespace: 'common', i18nKey: 'boolean' })}
        />

        <div>
          <Button color='primary' type='submit'>{this.props.t('apply')}</Button>

          <Button onClick={this.props.onCloseModal}>{this.props.t('cancel')}</Button>
        </div>
      </Form>
    )
  }
}

export default withTranslation('filters')(CategoriesFilterForm)
