import { connect } from 'react-redux'

import { fetchChildWithImplicitParent, update } from 'actions/resources'
import { tenantSchema, ratingLinkConfigurationSchema } from '@seekster/schemas'

import SMSRatingSettingsPane from './SMSRatingSettingsPane'

const mapDispatchToProps = {
  fetchSMSRatingConfig: () => fetchChildWithImplicitParent(tenantSchema, ratingLinkConfigurationSchema),
  updateSMSRatingConfig: data => update('', data, ratingLinkConfigurationSchema)
}

export default connect(null, mapDispatchToProps)(SMSRatingSettingsPane)
