import { withFormik } from 'formik'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'
import RequestCancelForm from './RequestCancelForm'

const formikConfig = {
  mapPropsToValues: ({ owner, owner_id }) => ({
    owner_id,
    owner_type: owner
  }),
  enableReinitialize: true,

  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true)

    props.onSubmit(values).then(() => {
      props.onCloseModal()
    })
  }
}

export default compose(withFlashMessage, withFormik(formikConfig))(RequestCancelForm)
