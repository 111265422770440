import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { EditRounded, Delete } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import { AnnouncementOverviewPane } from './panes'

export function AnnouncementShowPage ({
  announcement,
  match,
  location,
  fetchAnnouncement,
  setFlashMessage,
  deleteAnnouncement,
  push
}) {
  const { t } = useTranslation(['announcements', 'common'])
  const { loading } = useData(() => fetchAnnouncement(match.params.id))

  function handleDeleteResource () {
    if (window.confirm(`${t('delete_confirmation_prompt')}`)) {
      deleteAnnouncement(match.params.id)
        .then(push('/announcements'))
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function actions () {
    return [{
      avatar: <EditRounded />,
      content: t('edit'),
      to: `/announcements/${match.params.id}/edit`
    },
    {
      avatar: <Delete />,
      content: t('delete'),
      onClick: handleDeleteResource
    }]
  }

  function subNavbar () {
    const path = `/announcements/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('panes.overview')} to={path} />
      </NavigationMenuBar>
    )
  }

  return (
    <PageLayout
      title={announcement.get('title')}
      actions={actions()}
      subNavbar={subNavbar()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/announcements/:id' component={AnnouncementOverviewPane} exact />
      </Switch>
    </PageLayout>
  )

}

AnnouncementShowPage.propTypes = {
  announcement: ImmutablePropTypes.map,
  match: PropTypes.object,
  location: PropTypes.object,

  fetchAnnouncement: PropTypes.func,
  setFlashMessage: PropTypes.func,
  deleteAnnouncement: PropTypes.func,
  push: PropTypes.func
}

AnnouncementShowPage.defaultProps = {
  announcement: Map()
}

export default AnnouncementShowPage
