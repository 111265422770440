import { SET_DATA_CANCELLATION } from '../constants/cancellation'

export function setDatalist({ data, status, tenantId }) {
  return {
    type: SET_DATA_CANCELLATION,
    payload: {
      list: data,
      status_cancellation: status,
      tenantId: tenantId
    }
  }
}
