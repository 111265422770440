export default ({ spacing }) => ({
  button: {
    margin: '14px 0 0 14px'
  },

  container: {
    padding: spacing(2)
  },

  divider: {
    margin: '15px 0'
  },

  setup: {
    display: 'inline'
  },

  infoIcon: {
    marginLeft: 14,
    fontSize: 18,
    color: '#2d95f4'
  }
})
