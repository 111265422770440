import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { List, Map } from 'immutable'

import { fetchCollectionSucceeded, fetchChildrenSucceeded } from 'actions/firebase/collections'
import { getCollection } from 'selectors/collections'
import { firebaseSupportPlatform } from 'utils/firebase'
import { getResource } from 'selectors/resources'

const db = firebaseSupportPlatform.firestore()

export const useCollection = (path, schema, buildQuery = query => query, queryDeps = [], options = {}) => {
  const dispatch = useDispatch()

  const collection = useSelector(state => getCollection(state, schema, options.cacheKey)) || List()
  const memoizedBuildQuery = useCallback(buildQuery, queryDeps)
  const [loading, setLoading] = useState(collection.isEmpty())

  useEffect(() => {
    return memoizedBuildQuery(db.collection(path))
      .onSnapshot(snapshot => {
        dispatch(fetchCollectionSucceeded(snapshot, schema, options.cacheKey))
        setLoading(false)
      })
  }, [path, dispatch, schema, memoizedBuildQuery, options.cacheKey])

  return [collection, { loading }]
}

export const useCollectionChildren = (path, schema, parentSchema, parentId, buildQuery = query => query, queryDeps = []) => {
  const dispatch = useDispatch()

  const parentResource = useSelector(state => getResource(state, parentId, parentSchema)) || Map()
  const collectionChildren = parentResource.get(schema._key) || List()
  const memoizedBuildQuery = useCallback(buildQuery, queryDeps)
  const [loading, setLoading] = useState(collectionChildren.isEmpty())

  useEffect(() => {
    return memoizedBuildQuery(db.collection(path))
      .onSnapshot(snapshot => {
        dispatch(fetchChildrenSucceeded(snapshot, schema, parentSchema, parentId))
        setLoading(false)
      })
  }, [path, dispatch, schema, memoizedBuildQuery, parentSchema, parentId])

  return [collectionChildren, { loading }]
}

export const useCollectionOption = (path, buildQuery = query => query, queryDeps = []) => {
  const memoizedBuildQuery = useCallback(buildQuery, queryDeps)
  const [data, setData] = useState({})

  useEffect(() => {
    const unsubscribe = memoizedBuildQuery(db.collection(path))
      .onSnapshot(snapshot => {
        const docData = snapshot.docs.map(doc => doc.data())

        if (docData) {
          setData(docData)
        }
      })

    return unsubscribe
  }, [path, memoizedBuildQuery])

  return [data]
}
