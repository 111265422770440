export default () => ({
  cardContainer: {
    padding: '8px 0 16px 16px'
  },
  tagContainer: {
    paddingBottom: 8
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  noDecoration: {
    '&:hover': {
      textDecoration: 'none'
    }
  },

  spanAgentContainer: {
    fontSize: 11,
    color: '#81A7C9',
    position: 'absolute',
    right: 6
  },

  noDecorationWithDisabled: {
    opacity: 0.4,

    '&:hover': {
      textDecoration: 'none'
    }
  },
  unReadCount: {
    padding: 6,
    marginTop: 12,
    borderRadius: '50%',
    backgroundColor: 'red',
    border: '2px solid #ffffffc9'
  },
  title: {
    fontWeight: 600
  },
  selected: {
    backgroundColor: '#00000024'
  },
  bookmarkIcon: {
    color: '#ffc107'
  },
  blockIcon: {
    color: 'red'
  }
})
