import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withMobileDialog, Dialog, DialogContent, DialogTitle, Button } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import BankTransferPaymentReviewForm from 'forms/actions/BankTransferPaymentReviewForm'

import styles from './BankTransferPaymentReviewModalStyles'

export class BankTransferPaymentReviewModal extends React.Component {
  static propTypes = {
    payment: ImmutablePropTypes.map,
    invoiceId: PropTypes.number,
    fullScreen: PropTypes.bool,
    classes: PropTypes.object,
    updatePayment: PropTypes.func,
    capturePayment: PropTypes.func,
    voidPayment: PropTypes.func,
    fetchPayments: PropTypes.func,

    t: PropTypes.func
  }

  static defaultProps = {
    payment: Map()
  }

  constructor (props) {
    super(props)

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleCapturePayment = this.handleCapturePayment.bind(this)
    this.handleVoidPayment = this.handleVoidPayment.bind(this)

    this.state = {
      modalOpen: false
    }
  }

  handleOpenModal () {
    this.setState({ modalOpen: true })
  }

  handleCloseModal () {
    this.setState({ modalOpen: false })
  }

  handleCapturePayment (data) {
    const { updatePayment, capturePayment, fetchPayments, invoiceId, payment } = this.props

    return (
      updatePayment(payment.get('id'), data)
        .then(() => capturePayment(invoiceId, payment.get('id')))
        .then(() => fetchPayments(invoiceId))
        .then(this.handleCloseModal())
    )
  }

  handleVoidPayment () {
    const { voidPayment, invoiceId, payment, t } = this.props

    if (window.confirm(t('void_confirmation_prompt'))) {
      return (
        voidPayment(invoiceId, payment.get('id'))
          .then(() => this.props.fetchPayments(invoiceId))
          .then(this.handleCloseModal())
      )
    }
  }

  render () {
    return (
      <Fragment>
        <Button
          onClick={this.handleOpenModal}
          className={this.props.classes.buttonBasic}
        >
          {this.props.t('review')}
        </Button>

        <Dialog
          fullWidth
          maxWidth='sm'
          fullScreen={this.props.fullScreen}
          open={this.state.modalOpen}
          onClose={this.handleCloseModal}
        >
          <DialogTitle>{this.props.t('bank_transfer_review')}</DialogTitle>
          <DialogContent>
            <BankTransferPaymentReviewForm
              payment={this.props.payment}
              onSubmit={this.handleCapturePayment}
              onDisgard={this.handleVoidPayment}
              onCancel={this.handleCloseModal}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withMobileDialog(),
  withTranslation('payments')
)(BankTransferPaymentReviewModal)
