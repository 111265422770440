import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { create } from 'actions/resources'
import { fetch } from 'actions/collections'
import { getCollection } from 'selectors/collections'
import { providerSchema, badgeSchema, serviceSchema } from '@seekster/schemas'

import ProviderNewPage from './ProviderNewPage'

const mapStateToProps = (state) => ({
  badges: getCollection(state, badgeSchema),
  services: getCollection(state, serviceSchema)
})

const mapDispatchToProps = {
  createProvider: (data, options) => create(data, providerSchema, options),
  loadBadges: (options) => fetch(badgeSchema, options),
  loadServices: (options) => fetch(serviceSchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderNewPage)
