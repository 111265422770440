import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Button } from 'components/buttons'
import { withTranslation } from 'react-i18next'

import DateRangeField from 'components/fields/DateRangeField'

export class CompaniesFilterForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    onCloseModal: PropTypes.func,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit () {
    this.props.handleSubmit()

    this.props.onCloseModal()
  }

  render () {
    return (
      <Form>
        <DateRangeField name='created_at' />

        <div>
          <Button color='primary' onClick={this.handleSubmit}>{this.props.t('apply')}</Button>

          <Button onClick={this.props.onCloseModal}>{this.props.t('cancel')}</Button>
        </div>
      </Form>
    )
  }
}

export default withTranslation('filters')(CompaniesFilterForm)
