import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import Panel from 'components/containers/Panel'
import { DateTag } from 'components/tags'

import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'

export class ProviderDocumentsPane extends React.Component {
  static propTypes = {
    provider: ImmutablePropTypes.map,
    match: PropTypes.object,
    loadProviderAttachments: PropTypes.func,
    delete: PropTypes.func
  }

  static defaultProps = {
    provider: Map(),
    loadProviderAttachments: () => {}
  }

  componentDidMount () {
    this.props.loadProviderAttachments(this.props.match.params.id, { query: { attachment_type: 'id_card' } })
    this.props.loadProviderAttachments(this.props.match.params.id, { query: { attachment_type: 'contract' } })
  }

  constructor (props) {
    super(props)
    this.state = {
      showModal: false
    }

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  handleOpenModal () {
    this.setState({ showModal: true })
  }

  handleCloseModal () {
    this.setState({ showModal: false })
  }

  delete (id, providerId) {
    this.props.delete(id, providerId, this.handleCloseModal)
  }

  render () {
    const { provider } = this.props

    return (
      <Panel title='Registration & Document'>
        <AttributesTable resource={provider}>
          <Attribute name='national_id' />
          <Attribute name='approved_at' component={DateTag} />
        </AttributesTable>
      </Panel>
    )
  }
}

export default ProviderDocumentsPane
