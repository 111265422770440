import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
  TextareaAutosize
} from '@material-ui/core'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import ButtonLoader from 'components/buttons/ButtonLoader'
import Panel from 'components/containers/Panel'
import FastField from 'components/fields/Field'
import SelectInput from 'components/inputs/SelectInput'
import { ACCESS_TYPES } from 'constants/accessTypes'
import arrayToOptions from 'utils/arrayToOptions'

import styles from './AppFormStyles'

export function AppForm ({ classes, isSubmitting, t }) {
  return (
    <Grid container justify='center' component={Form}>
      <Grid item xs={12} sm={9}>
        <Panel title={t('details')}>
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12} sm={6}>
              <FastField name='name' />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FastField
                name='official'
                noLabel
                render={({ field }) => (
                  <FormGroup>
                    <FormControlLabel
                      label={t(`officiality.${field.value}`)}
                      control={<Switch checked={field.value} {...field} />}
                      className={classes.switch}
                    />
                  </FormGroup>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FastField
                name='access_type'
                component={SelectInput}
                options={arrayToOptions(ACCESS_TYPES, { i18nNamespace: 'common', i18nKey: 'access_types' })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FastField
                name='client'
                label='Client'
                component={SelectInput}
                isDisabled
                placeholder='NOT AVAILABLE'
              />
            </Grid>

            <Grid item xs={12}>
              <FastField name='webhook_url' typr='url' />
            </Grid>

            <Grid item xs={12}>
              <FastField name='fcm_server_key' component={TextareaAutosize} />
            </Grid>

            <Grid item xs={12}>
              <FastField name='contact_name' />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FastField name='contact_email' type='email' />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FastField
                name='contact_phone_number'
                type='tel'
                maxLength={10}
                pattern='[0-9]*'
              />
            </Grid>

            <Grid item xs={12} container justify='flex-end'>
              <ButtonLoader
                type='submit'
                variant='contained'
                color='primary'
                style={{ float: 'right' }}
                isLoading={isSubmitting}
              >
                {t('save')}
              </ButtonLoader>
            </Grid>
          </Grid>
        </Panel>
      </Grid>
    </Grid>
  )
}

AppForm.propTypes = {
  classes: PropTypes.object,
  isSubmitting: PropTypes.bool,

  t: PropTypes.func
}

export default compose(
  withStyles(styles),
  withTranslation('apps')
)(AppForm)
