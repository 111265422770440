import React from 'react'
import { List, fromJS } from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Table as MaterialTable, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import styles from './TableStyles'

export class Table extends React.Component {
  static propTypes = {
    collection: PropTypes.oneOfType([
      ImmutablePropTypes.list,
      PropTypes.array
    ]),
    children: PropTypes.node,

    classes: PropTypes.object,
    t: PropTypes.func
  }

  static defaultProps = {
    collection: List()
  }

  render () {
    const { collection, classes, t, ...rest } = this.props
    const filteredChildren = this.props.children.filter(child => child instanceof Object)

    return (
      <div className={classes.tableContainer}>
        <MaterialTable className={classes.root} {...rest}>
          <TableHead>
            <TableRow>
              {
                React.Children.map(filteredChildren, (child, index) => {
                  const { name, value, columnName, component, ...customProps } = child.props

                  return (
                    <TableCell className={classes.attributeHeader} {...customProps} key={index}>
                      {columnName || t(child.props.name)}
                    </TableCell>
                  )
                })
              }
            </TableRow>
          </TableHead>

          <TableBody>
            {
              fromJS(collection).map((resource, index) => {
                return (
                  <TableRow key={resource.get('id')}>
                    {
                      React.Children.map(filteredChildren, child => {
                        return React.cloneElement(child, { resource, className: classes.attributeValue, index })
                      })
                    }
                  </TableRow>
                )
              })
            }
          </TableBody>
        </MaterialTable>
      </div>
    )
  }
}

export default compose(
  withStyles(styles),
  withTranslation('attributes')
)(Table)
