import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, update } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { providerStrengthSchema } from '@seekster/schemas'

import ProviderStrengthEditPage from './ProviderStrengthEditPage'

const mapStateToProps = (state, { match }) => ({
  providerStrength: getResource(state, match.params.id, providerStrengthSchema)
})

const mapDispatchToProps = {
  fetchProviderStrength: id => fetch(id, providerStrengthSchema),
  updateProviderStrength: (id, data) => update(id, data, providerStrengthSchema),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderStrengthEditPage)
