import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { agentSchema, invitationSchema } from '@seekster/schemas'

import AgentInvitationsPane from './AgentInvitationsPane'

const mapStateToProps = (state, { match }) => ({
  agent: getResource(state, match.params.id, agentSchema)
})

const mapDispatchToProps = {
  fetchInvitations: id => fetchChildren(agentSchema, id, invitationSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentInvitationsPane)
