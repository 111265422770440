import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, update } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { clientSchema } from '@seekster/schemas'

import ClientEditPage from './ClientEditPage'

const mapStateToProps = (state, { match }) => ({
  client: getResource(state, match.params.id, clientSchema)
})

const mapDispatchToProps = {
  loadClient: (id, options) => fetch(id, clientSchema, options),
  updateClient: (id, data, options) => update(id, data, clientSchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientEditPage)
