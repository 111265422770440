import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { organizationSchema, jobNodeSchema } from '@seekster/schemas'

import useQuery from 'hooks/useQuery'
import { useCollectionNodeChildren } from 'hooks/collections'
import Search from 'components/Search'
import FilterTags from 'components/tags/FilterTags'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import JobsOrgTable from 'components/tables/JobsOrgTable'
import Pagination from 'components/Pagination'
import CircularLoader from 'components/CircularLoader'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

const OrganizationJobsPane = (props) => {
  const classes = useStyles()
  const [query] = useQuery()

  const { search } = query
  const [jobs, { loading, totalCount, totalPages }] = useCollectionNodeChildren(
    organizationSchema,
    props.match.params.id,
    jobNodeSchema,
    { query: { page: query.page, search: search } },
    [query]
  )

  return (
    <>
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Search withButton />
          </Grid>
        </Grid>
      </div>

      <div className={classes.metaContainer}>
        <ResultsCountLabel value={totalCount} unit='job' style={{ margin: 0 }} />
        <FilterTags />
        {loading ? <CircularLoader /> : <JobsOrgTable jobs={jobs} loading={loading} />}
      </div>

      <Grid item xs={12} container justify='center'>
        <Pagination totalPages={totalPages} />
      </Grid>
    </>
  )
}

export default OrganizationJobsPane
