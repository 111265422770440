import { Map } from 'immutable'

import {
  SET_FLASH_MESSAGE,
  CLEAR_FLASH_MESSAGE,
  UPDATE_LOCALE_SUCCEEDED,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  TOGGLE_SIDEBAR
} from 'constants/interface'

const initialState = Map({
  sidebarOpen: window.localStorage.getItem('sidebarOpen') === 'true'
})

const interfaceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FLASH_MESSAGE:
      return state.set('flashMessage', Map(payload))

    case CLEAR_FLASH_MESSAGE:
      return state.set('flashMessage', undefined)

    case OPEN_SIDEBAR:
      window.localStorage.setItem('sidebarOpen', true)

      return state.set('sidebarOpen', true)

    case CLOSE_SIDEBAR:
      window.localStorage.setItem('sidebarOpen', false)

      return state.set('sidebarOpen', false)

    case TOGGLE_SIDEBAR:
      const newState = !state.get('sidebarOpen')

      window.localStorage.setItem('sidebarOpen', newState)

      return state.set('sidebarOpen', newState)

    case UPDATE_LOCALE_SUCCEEDED:
      return state

    default:
      return state
  }
}

export default interfaceReducer
