import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { CheckCircle, Cancel } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    display: 'inline-flex',
    alignItems: 'center'
  },

  checkIcon: {
    color: 'green',
    marginRight: 3
  },

  xIcon: {
    color: theme.palette.error.dark,
    marginRight: 3
  },

  trueText: {
    color: 'green'
  },

  falseText: {
    color: theme.palette.error.dark
  }
}))

export function BooleanChip({ name, value, ...rest }) {
  const classes = useStyles()
  const { t } = useTranslation(['attributes', 'common'])

  function renderIcon(value) {
    if (value) {
      return <CheckCircle fontSize='small' className={classes.checkIcon} />
    } else {
      return <Cancel fontSize='small' className={classes.xIcon} />
    }
  }

  return (
    <div className={classes.chipContainer} {...rest}>
      {renderIcon(value)}
      {name && (
        <Typography variant='caption' className={classes[`${value}Text`]}>
          {t(`${name}.${value}`)}
        </Typography>
      )}
    </div>
  )
}

BooleanChip.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool
}

export default BooleanChip
