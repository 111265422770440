import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import PageLayout from 'components/layouts/PageLayout'
import InvoiceForm from 'forms/resources/InvoiceForm'

export class InvoiceNewPage extends React.Component {
  static propTypes = {
    customers: ImmutablePropTypes.list,
    push: PropTypes.func,
    create: PropTypes.func,
    loadCustomers: PropTypes.func
  }

  static defaultProps = {
    clients: Map()
  }

  render() {
    return (
      <PageLayout title='New Invoice' back='/invoices'>
        <InvoiceForm
          customers={this.props.customers}
          push={this.props.push}
          onSubmit={this.props.create}
          loadCustomers={this.props.loadCustomers}
        />
      </PageLayout>
    )
  }
}

export default InvoiceNewPage
