import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Form } from 'formik'
import { Field } from 'components/fields/Field'
import { Button } from 'components/buttons'
import arrayToOptions from 'utils/arrayToOptions'
import { useTranslation } from 'react-i18next'
import RadioInput from 'components/inputs/RadioInput'

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '500px'
  },

  buttonContainer: {
    float: 'right'
  }
}))

export function SettingsTicketForm ({
  onCloseModal,
  handleSubmit,
  isSubmitting,
  values
}) {
  const classes = useStyles()
  const { t } = useTranslation('filters')

  function handleSubmitForm () {
    handleSubmit()

    onCloseModal()
  }

  return (
    <Form className={classes.root}>
      <h2>Set Status Ticket</h2>

      <Field
        name='status'
        noLabel
        component={RadioInput}
        options={arrayToOptions(['open', 'pending', 'closed'])}
      />

      {
        values.status === 'closed' && <Field name='summary' />
      }

      <div className={classes.buttonContainer}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmitForm}
          loading={isSubmitting}
        >
          {t('confirm')}
        </Button>

        <Button onClick={onCloseModal}>{t('cancel')}</Button>
      </div>
    </Form>
  )
}

SettingsTicketForm.propTypes = {
  isSubmitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onCloseModal: PropTypes.func,
  values: PropTypes.object
}

export default SettingsTicketForm
