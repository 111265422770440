import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Grid } from '@material-ui/core'
import { Form } from 'formik'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import ButtonLoader from 'components/buttons/ButtonLoader'
import Panel from 'components/containers/Panel'
import { Field, FastField } from 'components/fields/Field'
import DropzoneInput from 'components/inputs/DropzoneInput'
import SwitchInput from 'components/inputs/SwitchInput'
import ResourceSelect from 'components/inputs/ResourceSelect'
import { departmentSchema } from '@seekster/schemas'

export function AgentForm ({ isSelf, isAdmin, t, isSubmitting, agent }) {
  return (
    <Grid container justify='center' component={Form}>
      <Grid item xs={12} sm={9}>
        <Panel title={t('details')}>
          <Grid container spacing={3} style={{ padding: '10px 16px' }}>
            <Grid item xs={12} sm={4}>
              <Field
                name='avatar'
                component={DropzoneInput}
                width={500}
                height={500}
                initialValue={agent.get('avatar_url')}
              />
            </Grid>

            <Grid item xs={12} sm={8} container spacing={3} alignContent='flex-start'>
              <Grid item xs={12} sm={6}>
                <FastField name='first_name' />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FastField name='last_name' />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FastField name='email' type='email' />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FastField name='phone_number' type='tel' maxLength={10} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name='department_id'
                  component={ResourceSelect}
                  schema={departmentSchema}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FastField
                  name='active'
                  component={SwitchInput}
                  noLabel
                  disabled={!isAdmin || isSelf}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FastField
                  name='admin'
                  component={SwitchInput}
                  noLabel
                  disabled={!isAdmin}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} container justify='flex-end'>
              <ButtonLoader
                type='submit'
                variant='contained'
                color='primary'
                isLoading={isSubmitting}
              >
                {t('save')}
              </ButtonLoader>
            </Grid>

          </Grid>
        </Panel>
      </Grid>
    </Grid>
  )
}

AgentForm.defaultProps = {
  agent: Map()
}

AgentForm.propTypes = {
  agent: ImmutablePropTypes.map,
  isSubmitting: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isSelf: PropTypes.bool,

  t: PropTypes.string
}

export default compose(
  withTranslation()
)(AgentForm)
