import i18next from 'i18next'

export const arrayToOption = (array, { i18nNamespace, i18nKey } = {}) => {
  return array.map((value) => ({
    value,
    label:
      i18nNamespace && i18nKey ? i18next.t(`${i18nNamespace}:${i18nKey}.${value}`) : value
  }))
}

export default arrayToOption
