import { connect } from 'react-redux'

import { fetch } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { subscriptionSchema } from '@seekster/schemas'

import SubscriptionMonthsPane from './SubscriptionMonthsPane'

const mapStateToProps = (state, { match }) => ({
  subscription: getResource(state, match.params.id, subscriptionSchema)
})

const mapDispatchToProps = {
  fetchSubscription: (id, options) => fetch(id, subscriptionSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionMonthsPane)
