import { Map, List } from 'immutable'
import { normalize } from 'normalizr'

import { SIGN_OUT } from 'constants/authentication'
import {
  FETCH_COLLECTION_SUCCEEDED,
  CREATE_COLLECTION_SUCCEEDED,
  FETCH_NODE_COLLECTION_SUCCEEDED
} from 'constants/collections'

import {
  FETCH_COLLECTION_SUCCEEDED as FIREBASE_FETCH_COLLECTION_SUCCEEDED,
  FETCH_CHILDREN_COLLECTION_SUCCEEDED as FIREBASE_FETCH_CHILDREN_COLLECTION_SUCCEEDED
} from 'constants/firebase/collections'

const initialState = Map()

const collectionsReducer = (
  state = initialState,
  { type, schema, snapshot, response, cacheKey }
) => {
  let key

  switch (type) {
    case FETCH_COLLECTION_SUCCEEDED:
    case FETCH_NODE_COLLECTION_SUCCEEDED:
    case CREATE_COLLECTION_SUCCEEDED: {
      const normalizedData = normalize(response.body, [schema])

      return state
        .setIn([schema._key, 'data'], List(normalizedData.result))
        .setIn([schema._key, 'total'], parseInt(response.headers.total, 10))
        .setIn([schema._key, 'perPage'], parseInt(response.headers['per-page'], 10))
    }

    case FIREBASE_FETCH_COLLECTION_SUCCEEDED:
    case FIREBASE_FETCH_CHILDREN_COLLECTION_SUCCEEDED: {
      key = cacheKey || schema._key

      const collectionDocs = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      const normalizedFirebaseCollection = normalize(collectionDocs, [schema])

      return state.setIn([key, 'data'], List(normalizedFirebaseCollection.result))
    }
    case SIGN_OUT:
      return state.clear()

    default:
      return state
  }
}

export default collectionsReducer
