export default ({ palette }) => ({
  icon: {
    width: '100%'
  },

  uninstallButton: {
    fontWeight: 600,
    backgroundColor: palette.grey[50],
    color: palette.grey[600],

    '&:hover': {
      backgroundColor: palette.grey[200]
    }
  },

  tabs: {
    justifyContent: 'flex-start',
    backgroundColor: palette.grey[200]
  },

  tab: {
    fontSize: '1.45rem',
    fontWeight: 500,
    textTransform: 'capitalize'
  }
})
