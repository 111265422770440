import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { withTranslation } from 'react-i18next'

import { AttributesTable, Attribute } from 'components/tables/AttributesTable'

export class QuestionsAndAnswers extends React.Component {
  static propTypes = {
    subscription: ImmutablePropTypes.map,
    t: PropTypes.func
  }

  static defaultProps = {
    subscription: Map()
  }

  answer (obj) {
    const question = obj.get('question') || Map()
    const choice = obj.get('choice') || Map()
    const choices = obj.get('choices') || List()

    switch (question.get('display_type')) {
      case 'radio': return choice.get('value')
      case 'string' : return obj.get('value')
      case 'number': return obj.get('value')
      case 'textarea': return obj.get('value')
      case 'checkboxes': return choices.map((item, index) => {
        return (
          <ul key={index}>
            <li>{item.get('value')}</li>
          </ul>
        )
      })
      default: break
    }
  }

  render () {
    const answers = this.props.subscription.get('answers') || List()

    return (
      <AttributesTable>
        {
          answers.sort().map((answer, index) => {
            const question = answer.get('question') || Map()

            return (
              <Attribute key={index}
                label={question.get('name')}
                value={this.answer(answer)} />
            )
          })
        }
        <Attribute
          label={this.props.t('additional_information')}
          value={this.props.subscription.get('additional_information')}
        />
      </AttributesTable>
    )
  }
}

export default withTranslation('subscriptions')(QuestionsAndAnswers)
