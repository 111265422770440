import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { Select, InputBase, MenuItem } from '@material-ui/core'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { useResource } from 'hooks/resources'

import withLocalizedFields from 'hoc/withLocalizedFields'
import { badgeSchema } from '@seekster/schemas'

import PageLayout from 'components/layouts/PageLayout'
import BadgeForm from 'forms/resources/BadgeForm'

export function BadgeEditPage ({ fetchBadge, updateBadge, push, match, locale, setLocale, t }) {
  const [badge, { loading }] = useResource(badgeSchema, match.params.id)

  function handleSubmit (data) {
    return updateBadge(match.params.id, data)
  }

  function renderActions () {
    return [
      <Select
        value={locale}
        onChange={e => setLocale(e.target.value)}
        input={<InputBase />}
      >
        <MenuItem value='en'><span role='img' aria-label='GB flag'>🇬🇧 </span>English</MenuItem>
        <MenuItem value='th'><span role='img' aria-label='Thai flag'>🇹🇭 </span>ภาษาไทย</MenuItem>
      </Select>
    ]
  }

  return (
    <PageLayout
      title={t('edit')}
      secondaryActions={renderActions()}
      back={`/badges/${match.params.id}`}
      isLoading={loading}
    >
      <BadgeForm badge={badge} onSubmit={handleSubmit} push={push} />
    </PageLayout>
  )
}

BadgeEditPage.propTypes = {
  fetchBadge: PropTypes.func,
  updateBadge: PropTypes.func,
  push: PropTypes.func,
  match: PropTypes.object,
  locale: PropTypes.string,
  setLocale: PropTypes.func,

  t: PropTypes.func
}

BadgeEditPage.defaultProps = {
  badge: Map()
}

export default compose(
  withLocalizedFields,
  withTranslation('badges')
)(BadgeEditPage)
