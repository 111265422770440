import { connect } from 'react-redux'

import { create } from 'actions/resources'
import { push } from 'react-router-redux'
import { subscriptionSchema } from '@seekster/schemas'

import SubscriptionNewPage from './SubscriptionNewPage'

const mapDispatchToProps = {
  createSubscription: (data, options) => create(data, subscriptionSchema, options),
  push
}

export default connect(null, mapDispatchToProps)(SubscriptionNewPage)
