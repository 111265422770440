import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useCollection } from 'hooks/collections'
import useQuery from 'hooks/useQuery'

import PageLayout from 'components/layouts/PageLayout'
import CircularLoader from 'components/CircularLoader'
import Search from 'components/Search'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import { Table, Column } from 'components/tables/Table'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'

import { issueSchema } from '@seekster/schemas'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

export function ProviderIssueIndexPage () {
  const classes = useStyles()
  const [query] = useQuery()
  const { t } = useTranslation('issues')
  const [issues, {
    loading, totalCount, totalPages
  }] = useCollection(issueSchema, { page: query.page, query }, [query])

  function primaryActions () {
    return [{ content: t('new'), to: '/provider_issues/new' }]
  }

  return (
    <PageLayout title={t('title')} primaryActions={primaryActions()}>
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Search />
          </Grid>
        </Grid>
      </div>
      {
        loading ? (
          <CircularLoader />
        ) : (
          <div className={classes.metaContainer}>
            <ResultsCountLabel value={totalCount} unit='issue' />

            <Paper>
              <Table collection={issues}>
                <Column name='id' />
                <Column name='name' to={issue => `/provider_issues/${issue.get('id')}`} />
                <Column name='updated_at' component={DateTimeFormatter} />
                <Column name='created_at' component={DateTimeFormatter} />
              </Table>
            </Paper>
          </div>
        )
      }
      <Grid item xs={12} container justify='center'>
        <Pagination totalPages={totalPages} />
      </Grid>
    </PageLayout>
  )
}

export default ProviderIssueIndexPage
