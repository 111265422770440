import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Grid } from '@material-ui/core'

import CircularLoader from 'components/CircularLoader'

import JobsTable from 'components/tables/JobsTable'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'

export class ProviderJobsPane extends React.Component {
  static propTypes = {
    provider: ImmutablePropTypes.map,
    match: PropTypes.object,
    fetchProviderJobs: PropTypes.func,
    query: PropTypes.object
  }

  static defaultProps = {
    provider: Map(),

    fetchProviderJobs: () => {}
  }

  state = {
    loading: false,
    error: null,
    totalCount: 0,
    totalPages: 1
  }

  componentDidMount() {
    this.setState({ loading: true })

    this.props
      .fetchProviderJobs(this.props.match.params.id)
      .then((response) => {
        this.setState({
          loading: false,
          totalCount: parseInt(response.headers['total'], 10),
          totalPages: Math.ceil(response.headers['total'] / response.headers['per-page'])
        })
      })
      .catch((error) => {
        this.setState({ loading: false, error: error.message })
      })
  }

  componentDidUpdate(prevProps, prevState) {
    this.props.query.page !== prevProps.query.page && this.handleUpdateData()
  }

  handleUpdateData() {
    const { page } = this.props.query

    this.setState({ loading: true })

    this.props
      .fetchProviderJobs(this.props.match.params.id, { page })
      .then(() => {
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false, error: error.message })
      })
  }

  render() {
    const { loading, totalCount, totalPages } = this.state
    const jobs = this.props.provider.get('jobs') || List()

    return (
      <div style={{ padding: '14px 0' }}>
        <Grid>
          <ResultsCountLabel value={totalCount} unit='job' />

          {loading && <CircularLoader />}

          {!loading && totalCount !== 0 && (
            <>
              <JobsTable jobs={jobs} />

              <Grid item xs={12} container justify='center'>
                <Pagination totalPages={totalPages} />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    )
  }
}

export default ProviderJobsPane
