import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import {
  CardActionArea, CardHeader, Avatar
} from '@material-ui/core'
import { Email, Phone } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import Panel from 'components/containers/Panel'

import styles from './CustomerCardStyles'

export function CustomerCard ({
  classes,
  customer,
  title
}) {
  return (
    <Panel title={title}>
      <CardActionArea
        component={Link}
        to={`/customers/${customer.get('id')}`}
        style={{ textDecoration: 'none', padding: '14px' }}
      >
        <CardHeader
          avatar={
            <Avatar
              src={customer.get('avatar_url')}
              alt={customer.get('display_name')}
            />
          }
          title={customer.get('display_name')}
          subheader={
            <>
              {
                customer.get('email') && (
                  <div>
                    <Email className={classes.emailIcon} />
                    {customer.get('email')}
                  </div>
                )
              }

              {
                customer.get('phone_number') && (
                  <div>
                    <Phone className={classes.phoneIcon} />
                    {customer.get('phone_number')}
                  </div>
                )
              }
            </>
          }
          style={{ padding: 0 }}
        />
      </CardActionArea>
    </Panel>
  )
}

CustomerCard.propTypes = {
  classes: PropTypes.object,
  customer: ImmutablePropTypes.map,
  title: PropTypes.string
}

CustomerCard.defaultProps = {
  customer: Map()
}

export default withStyles(styles)(CustomerCard)
