import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useNodeCollection } from 'hooks/collections'
import useQuery from 'hooks/useQuery'

import PageLayout from 'components/layouts/PageLayout'
import CircularLoader from 'components/CircularLoader'
import Search from 'components/Search'
import ReportsTable from 'components/tables/ReportsTable'
import Pagination from 'components/Pagination'

import { jobReportSchema } from '@seekster/schemas'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  },
  button: {
    height: '48px'
  }
}))

export function ReportIndexPage() {
  const classes = useStyles()
  const [query] = useQuery()
  const { t } = useTranslation('reports')

  const [reports, { loading, totalPages }] = useNodeCollection(
    jobReportSchema,
    {
      url: '/job_reports',
      page: query.page,
      query,
      queryParam: { isFilterByOrg: false }
    },
    [query]
  )

  return (
    <PageLayout title={t('title')}>
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={8}>
            <Search withButton />
          </Grid>
        </Grid>
      </div>

      {loading ? (
        <CircularLoader />
      ) : (
        <>
          <div className={classes.metaContainer}>
            <ReportsTable reports={reports} />
          </div>

          <Grid item xs={12} container justify='center'>
            <Pagination totalPages={totalPages} />
          </Grid>
        </>
      )}
    </PageLayout>
  )
}

export default ReportIndexPage
