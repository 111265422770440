import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import './DateTimeInput.scss'

export function DateTimeInput ({
  value,
  onChange
}) {
  function handleChange (e) {
    onChange(Moment(e.target.value).toISOString(), e)
  }

  return (
    <input
      type='datetime-local'
      className='u-full-width datetime-input'
      value={value ? Moment(value).format('YYYY-MM-DDTHH:mm') : false}
      onChange={onChange ? (e) => handleChange(e) : null}
    />
  )
}

DateTimeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default DateTimeInput
