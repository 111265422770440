import { red } from '@material-ui/core/colors'

export default ({ spacing }) => ({
  deleteButton: {
    width: 'auto !important',
    color: red[800]
  },

  loader: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: spacing(3)
  }
})
