import React, { useCallback, useState } from 'react'
import { Paper, Avatar } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@material-ui/icons'

import { useSidebar } from 'hooks/interface'
import { Button } from 'components/buttons'
import { ResponsiveMenu, ResponsiveMenuItem } from 'components/menus/ResponsiveMenu'

import useStyles from './styles'
import JobStateButton from './JobStateButton'
import RequestStateButton from './RequestStateButton'

export function PageHeader({
  title,
  subtitle,
  primaryActions,
  secondaryActions,
  actions,
  navbar,
  linkAction,
  avatar,
  back
}) {
  const { t } = useTranslation('common')
  const [componentHeight, setComponentHeight] = useState(0)
  const componentRef = useCallback((node) => {
    // eslint-disable-line react-hooks/exhaustive-deps
    if (node !== null) {
      setComponentHeight(node.getBoundingClientRect().height)
    }
  })

  const { sidebarOpen } = useSidebar()
  const location = useLocation()
  const isJobPage = matchPath(location.pathname, {
    path: '/jobs/:id',
    exact: true
  })

  const isRequestPage = matchPath(location.pathname, {
    path: '/inquiries/:id'
  })

  const classes = useStyles({ actions, componentHeight, sidebarOpen })

  return (
    <>
      <Paper square elevation={2} className={classes.root} ref={componentRef}>
        <div className={classes.header}>
          {avatar ? (
            <span className={classes.avatarContainer}>
              <Avatar src={avatar} alt={avatar} className={classes.avatar} />
              <h1 className={classes.titleAvatar}>{title}</h1>
            </span>
          ) : (
            <h1 className={classes.title}>{title}</h1>
          )}
          {isJobPage && <JobStateButton />}
          {isRequestPage && <RequestStateButton />}

          {/* {subtitle} */}

          {back && (
            <Link to={back}>
              <div className={classes.backText}>{t('page_back')}</div>
            </Link>
          )}
        </div>

        {primaryActions && (
          <div className={classes.primaryActions}>
            {primaryActions.map(
              ({ avatar, content, onClick, to, className, ...rest }) => (
                <Button
                  key={content}
                  type='button'
                  variant='contained'
                  color='primary'
                  className={clsx([
                    'primary-action-button',
                    { multi: primaryActions.length > 1 },
                    className
                  ])}
                  onClick={onClick}
                  component={to && Link}
                  to={to}
                  {...rest}
                >
                  {avatar && React.cloneElement(avatar, { fontSize: 'small' })}
                  {content}
                </Button>
              )
            )}
          </div>
        )}

        {actions && (
          <div className={classes.actions}>
            <ResponsiveMenu>
              {actions.map(({ content, onClick, to, ...action }) => (
                <ResponsiveMenuItem
                  {...action}
                  content={content}
                  key={content}
                  onClick={onClick}
                  component={to && Link}
                  to={to}
                />
              ))}
            </ResponsiveMenu>
          </div>
        )}

        {secondaryActions && (
          <div className={classes.primaryActions}>{secondaryActions}</div>
        )}

        {navbar && <div className={classes.navbar}>{navbar}</div>}

        {linkAction && (
          <div className={classes.linkIconContainer}>
            {linkAction.prev ? (
              <Link to={linkAction.prev}>
                <ArrowBackIosRounded className={classes.linkIcon} />
              </Link>
            ) : (
              <ArrowBackIosRounded className={classes.linkIconDisable} />
            )}

            {linkAction.next ? (
              <Link to={linkAction.next}>
                <ArrowForwardIosRounded className={classes.linkIcon} />
              </Link>
            ) : (
              <ArrowForwardIosRounded className={classes.linkIconDisable} />
            )}
          </div>
        )}
      </Paper>

      <div className={classes.spacer} />
    </>
  )
}

export default PageHeader
