import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { permissionToReceiveNotification } from 'utils/firebase'

import './GoogleLoginButton.scss'

export function GoogleLoginButton ({
  push,
  onClick,
  updateCurrentDevice
}) {
  useEffect(() => {
    if (!window) return console.warn('window could not be found')

    if (window.gapi) {
      renderGoogleLoginButton()
    }
    else {
      window.renderGoogleLoginButton = renderGoogleLoginButton
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function renderGoogleLoginButton () {
    window.gapi.signin2.render('google-login-button', {
      longtitle: true,
      theme: 'dark',
      height: 50,
      onsuccess: handleSuccess,
      onfailure: () => {
        console.warn('failure')
      }
    })
  }

  function handleSuccess (googleUser) {
    var profile = googleUser.getBasicProfile()

    onClick({
      first_name: profile.getGivenName(),
      last_name: profile.getFamilyName(),
      remote_avatar_url: profile.getImageUrl(),
      email: profile.getEmail(),
      omniauth_identities_attributes: {
        uid: profile.getId(),
        provider: 'google',
        token: googleUser.getAuthResponse().access_token
      }
    })
      .then(() => {
        window.gapi.auth2.getAuthInstance().signOut()

        push('/')

        return permissionToReceiveNotification()
      })
      .then(fcmToken => {
        if (window.localStorage.getItem('FCM_token') !== fcmToken) {
          updateCurrentDevice({ registration_token: fcmToken })
        }
      })
      .catch(error => console.warn(error))
  }

  return (
    <div className='form-group'>
      <div id='google-login-button' />
    </div>
  )
}

GoogleLoginButton.propTypes = {
  push: PropTypes.func,
  onClick: PropTypes.func,
  updateCurrentDevice: PropTypes.func
}

export default GoogleLoginButton
