import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import Panel from 'components/containers/Panel'
import { DateTag, ColorTag, BooleanTag } from 'components/tags'
import { AttributesTable, Attribute } from 'components/tables/AttributesTable'

export function ClientOverviewPane ({ client }) {
  return (
    <Panel title='Client Overview'>
      <AttributesTable>
        <Attribute label='ID' value={client.get('id')} />
        <Attribute label='Name' value={client.get('name')} />
        <Attribute label='Access Key' value={client.get('access_key')} />
        <Attribute label='Secret Key' value={client.get('secret_key')} valueStyle={{ wordBreak: 'break-all' }} />
        <Attribute label='Official' value={<BooleanTag value={client.get('official')} />} />
        <Attribute label='Access Type' value={client.get('access_type')} />
        <Attribute label='Platform' value={client.get('platform')} />
        <Attribute label='Opener' value={client.get('opener')} />
        <Attribute
          label='Color'
          value={
            <span><ColorTag value={client.get('color')} />{client.get('color')}</span>
          }
        />
        <Attribute label='Latest Version' value={client.get('latest_version')} />
        <Attribute label='Minimum Supported Version' value={client.get('minimum_supported_version')} />
        <Attribute label='FCM Server Key' value={client.get('fcm_server_key')} valueStyle={{ wordBreak: 'break-all' }} />
        <Attribute label='Webhook URL' value={client.get('webhook_url')} />
        <Attribute label='Scheme' value={client.get('scheme')} />
        <Attribute label='QR Code' value={<img height={300} src={client.get('qr_code_url')} alt='QR Code' />} />
        <Attribute label='Updated At' value={<DateTag value={client.get('updated_at')} />} />
        <Attribute label='Created At' value={<DateTag value={client.get('created_at')} />} />
      </AttributesTable>
    </Panel>
  )
}

ClientOverviewPane.propTypes = {
  client: ImmutablePropTypes.map
}

ClientOverviewPane.defaultProps = {
  client: Map()
}

export default ClientOverviewPane
