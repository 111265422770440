import axios from 'axios'

export default async function callService(method, url, data, headers) {
  try {
    const response = await axios({
      method,
      headers,
      url,
      data
    })
    return response
  } catch (error) {
    console.log(error)
  }
  // const response = await axios({
  //   method,
  //   headers,
  //   url,
  //   data
  // })
  //   .then((res) => res)
  //   .catch((error) => {
  //     console.log(error)
  //   })
  // return response
}
