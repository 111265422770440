import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { Map, fromJS } from 'immutable'
import { useResourceAction } from 'hooks/resources'
import Panel from 'components/containers/Panel'
import TagManagerSettingsForm from 'forms/actions/TagManagerSettingsForm'

import { tagManagerIntegrationConfigurationSchema } from '@seekster/schemas'

export function TagManagerSettingsPane({ t }) {
  const [tagManager, setTagManager] = useState(Map())
  const {
    create: createTagManager,
    update: updateTagManager,
    fetch: fetchTagManager
  } = useResourceAction(tagManagerIntegrationConfigurationSchema)

  useEffect(() => {
    fetchTagManager('').then((response) => setTagManager(fromJS(response.body)))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function handleUpdateAccount(data) {
    return updateTagManager('', data)
  }

  function handleCreateAccount(data) {
    return createTagManager(data)
  }

  return (
    <Grid container justify='center'>
      <Grid item xs={12} sm={9}>
        <Panel title={t('tag_manager.title')}>
          <TagManagerSettingsForm
            tagManagerSettings={tagManager}
            onSubmit={tagManager.isEmpty() ? handleCreateAccount : handleUpdateAccount}
          />
        </Panel>
      </Grid>
    </Grid>
  )
}

export default withTranslation('settings')(TagManagerSettingsPane)
