import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { serviceSchema } from '@seekster/schemas'

import ServiceTermsPane from './ServiceTermsPane'

const mapStateToProps = (state, { match }) => ({
  service: getResource(state, match.params.id, serviceSchema)
})

export default connect(mapStateToProps)(ServiceTermsPane)
