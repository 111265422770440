export default (theme) => ({
  logo: {
    marginBottom: theme.spacing(1)
  },

  item: {
    height: 50
  },

  itemText: {
    fontWeight: 600
  },

  nested: {
    paddingLeft: theme.spacing(4)
  },

  nestedList: {
    paddingTop: 0
  },

  nestedChildren: {
    paddingLeft: theme.spacing(8)
  },

  customBadge: {
    top: '4px !important',
    right: '-8px !important'
  }
})
