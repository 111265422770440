import React, { useState } from 'react'
const { ListItem, ListItemText, Collapse } = require('@material-ui/core')

const ExpandableListItem = ({ primary, children, ...props }) => {
  const [visible, setVisible] = useState(false)

  const handleClick = () => {
    setVisible(visible => !visible)
  }

  return (
    <>
      <ListItem button onClick={handleClick} {...props}>
        <ListItemText primary={primary} />
      </ListItem>

      <Collapse in={visible}>
        <ListItem>
          {children}
        </ListItem>
      </Collapse>
    </>
  )
}

export default ExpandableListItem
