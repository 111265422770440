import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { Delete, EditRounded, MailOutlineRounded } from '@material-ui/icons'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import {
  AgentInvitationsPane,
  AgentOverviewPane,
  AgentTechPane
} from './panes'

import styles from './AgentShowPageStyles'

export function AgentShowPage ({
  agent,
  fetchAgent,
  fetchCurrentAgent,
  destroyAgent,
  sendInvitation,
  setFlashMessage,
  push,
  match,
  location,
  classes,
  t
}) {
  const { loading } = useData(() => match.params.id ? fetchAgent(match.params.id) : fetchCurrentAgent())

  function handleDeleteAgent () {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      destroyAgent(match.params.id)
        .then(() => {
          push('/agents')
        })
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function handleSendInvitation () {
    if (window.confirm(t('invite_confirmation_prompt'))) {
      sendInvitation(match.params.id)
        .then(() => {
          push(`/agents/${match.params.id}/invitations`)
        })
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function renderActions () {
    let actions = [{
      avatar: <EditRounded />,
      content: t('edit'),
      to: match.params.id ? `/agents/${match.params.id}/edit` : '/profile/edit'
    }]

    if (match.params.id) {
      actions = [
        ...actions,

        {
          avatar: <MailOutlineRounded />,
          content: t('send_invitation'),
          onClick: handleSendInvitation
        },
        {
          avatar: <Delete />,
          content: t('delete'),
          onClick: handleDeleteAgent
        }
      ]
    }

    return actions
  }

  function renderSubNavbar () {
    const path = match.params.id ? `/agents/${match.params.id}` : `/profile`

    let menuItem = [
      <NavigationMenuItem label={t('panes.overview')} to={path} key='overview' />
    ]

    if (match.params.id) {
      menuItem = [
        ...menuItem,
        <NavigationMenuItem
          label={t('panes.invitations')}
          to={`${path}/invitations`}
          key='invitations'
        />
      ]
    }

    menuItem = [
      ...menuItem,
      <NavigationMenuItem label={t('panes.tech')} to={`${path}/tech`} key='tech' />
    ]

    return (
      <NavigationMenuBar path={location.pathname}>
        {menuItem}
      </NavigationMenuBar>
    )
  }

  return (
    <PageLayout
      title={agent.get('display_name')}
      actions={renderActions()}
      subNavbar={renderSubNavbar()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path={['/agents/:id', '/profile']} component={AgentOverviewPane} exact />
        <AuthenticatedRoute path='/agents/:id/invitations' component={AgentInvitationsPane} />
        <AuthenticatedRoute path={['/agents/:id/tech', '/profile/tech']} component={AgentTechPane} />
      </Switch>
    </PageLayout>
  )
}

AgentShowPage.propTypes = {
  agent: ImmutablePropTypes.map,
  fetchCurrentAgent: PropTypes.func,
  fetchAgent: PropTypes.func,
  destroyAgent: PropTypes.func,
  sendInvitation: PropTypes.func,
  setFlashMessage: PropTypes.func,
  push: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  classes: PropTypes.object,

  t: PropTypes.func
}

AgentShowPage.defaultProps = {
  agent: Map()
}

export default compose(
  withStyles(styles),
  withTranslation(['agents', 'profile'])
)(AgentShowPage)
