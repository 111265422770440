import moment from 'moment'
import { get } from 'lodash'

export const customersQuery = ({ search, customer_type_id, created_at }) => {
  return {
    first_name_or_last_name_or_phone_number_or_email_or_alias_or_tags_name_cont: search,
    customer_type_id_eq: customer_type_id,

    created_at_gteq: get(created_at, 'from')
      ? moment(created_at.from).startOf('day').toISOString(true)
      : undefined,
    created_at_lteq: get(created_at, 'to')
      ? moment(created_at.to).endOf('day').toISOString(true)
      : undefined
  }
}

export default customersQuery
