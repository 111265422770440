import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import LazyLoad from 'react-lazyload'
import { useTranslation } from 'react-i18next'
import { List } from 'immutable'
import { Table, Column } from 'components/tables/NewTable'
import { Paper, Button } from '@material-ui/core'

import { DateTag } from 'components/tags'

export function ProvidersListTable({ providers, handleAssignProviderUnderOrg }) {
  const { t } = useTranslation('jobs')

  return (
    <Paper>
      <Table collection={providers}>
        <Column
          name='name'
          render={(_, provider) => (
            <>
              <>
                <LazyLoad height={25}>
                  <img
                    src={provider.get('avatar_url')}
                    alt=''
                    style={{ marginRight: '10px', height: '25px' }}
                  />
                </LazyLoad>
              </>
              <>{provider.get('fullName')}</>
            </>
          )}
        />
        <Column name='org' render={(org) => <>{org && org.get('name')}</>} />
        <Column name='phoneNumber' />
        <Column name='email' />
        <Column name='rating' />
        <Column name='createdAt' component={DateTag} />
        <Column
          name='assign_job'
          render={(_, provider) => (
            <Button
              onClick={() => handleAssignProviderUnderOrg(provider.get('_id'))}
              variant='contained'
              color='primary'
            >
              {t('assign_job')}
            </Button>
          )}
        />
      </Table>
    </Paper>
  )
}

ProvidersListTable.propTypes = {
  providers: ImmutablePropTypes.list,
  handleAssignProviderUnderOrg: PropTypes.func
}

ProvidersListTable.defaultProps = {
  providers: List()
}

export default ProvidersListTable
