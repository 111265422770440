import { connect } from 'react-redux'

import { createChild, update, destroy } from 'actions/resources'
import { propertySchema } from '@seekster/schemas'

import UserPropertyFormController from './UserPropertyFormController'

const mapDispatchToProps = {
  createProperty: (data, id, userSchema) => createChild(data, userSchema, id, propertySchema),
  updateProperty: (id, data, options) => update(id, data, propertySchema, options),
  deleteProperty: (id, options) => destroy(id, propertySchema, options)
}

export default connect(null, mapDispatchToProps)(UserPropertyFormController)
