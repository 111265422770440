import { withFormik } from 'formik'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import * as Yup from 'yup'

import withFlashMessage from 'hoc/withFlashMessage'

import PromoCodeForm from './PromoCodeForm'

const formikConfig = {
  mapPropsToValues: ({ promoCode }) => promoCode ? ({
    service_ids: promoCode.get('services').map(service => service.get('id')).toJS() || [],
    package_ids: promoCode.get('packages').map(pkg => pkg.get('id')).toJS() || [],
    code: promoCode.get('code') || '',
    amount_off_satangs: promoCode.getIn(['amount_off', 'fractional']) || 0,
    amount_off_currency: promoCode.getIn(['amount_off', 'currency']) || 'thb',
    percent_off: promoCode.get('percent_off'),
    quantity: promoCode.get('quantity'),
    limit_per_user: promoCode.get('limit_per_user'),
    start_date: promoCode.get('start_date') || '',
    expiration_date: promoCode.get('expiration_date') || '',
    first_time: promoCode.get('first_time') || ''
  }) : ({}),

  validationSchema: Yup.object().shape({
    code: Yup.string().required()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    if (isNaN(values.amount_off_satangs)) {
      values.amount_off_satangs = 0
    }

    props.onSubmit(values)
      .then(response => {
        props.history.push(`/promo_codes/${response.body.id}`)
      })
      .catch(error => {
        setSubmitting(false)

        // props.setFlashMessage('error', error.response.body ? error.response.body.message : 'Error Occured!')
      })
  }
}

export default compose(
  withFlashMessage,
  withRouter,
  withFormik(formikConfig)
)(PromoCodeForm)
