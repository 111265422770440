import { makeStyles } from '@material-ui/core/styles'

function sectionWidth (sections, name, value) {
  return sections.includes(name) ? value : 0
}

export default makeStyles(({ transitions, breakpoints }) => ({
  root: {
    display: 'grid',
    flexGrow: 1,
    gridTemplateRows: 'auto',
    overflowX: 'auto',

    [breakpoints.up('sm')]: {
      gridTemplateColumns: ({ sections }) => sections.length > 0 && `
        ${sectionWidth(sections, 'left', '300px')}
        1fr
        ${sectionWidth(sections, 'right', '400px')}
      `,
      gridTemplateAreas: ({ sections }) => sections.length > 0 && `
        'page-left-content page-center-content page-right-content'
      `
    },

    [breakpoints.down('xs')]: {
      gridTemplateAreas: `
        'page-center-content'
        'page-left-content'
        'page-right-content'
      `,
      gridTemplateColumns: '1fr'
    }
  }
}))
