import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/messaging'
import 'firebase/storage'
import 'firebase/auth'

export const workforceFirebase = firebase.initializeApp(
  {
    apiKey: process.env.REACT_APP_WORKFORCE_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_WORKFORCE_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_WORKFORCE_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_WORKFORCE_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_WORKFORCE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_WORKFORCE_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_WORKFORCE_FIREBASE_APP_ID
    // measurementId: process.env.REACT_APP_WORKFORCE_FIREBASE_MESUREMENT_ID
  },
  'firebaseNoti' // this name will be used to retrieve firebase instance. E.g. first.database();
)

export function permissionToReceiveNotification () {
  if (firebase.messaging.isSupported()) {
    const messaging = workforceFirebase.messaging()

    return messaging.requestPermission()
      .then(() => {
        return messaging.getToken()
      })
      .catch(error => {
        console.log('Error Occured:', error)
      })
  }
  else {
    return Promise.reject(new Error('Push notification not supported.'))
  }
}

export const firebaseSupportPlatform = firebase.initializeApp(
  {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGEING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MESUREMENT_ID
  },
  'firebaseSupportPlatform' // this name will be used to retrieve firebase instance. E.g. second.database();
)

export const firebaseWorkforcePartner = firebase.initializeApp(
  {
    apiKey: process.env.REACT_APP_PARTNER_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_PARTNER_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PARTNER_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_PARTNER_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PARTNER_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PARTNER_FIREBASE_MESSAGEING_SENDER_ID,
    appId: process.env.REACT_APP_PARTNER_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_PARTNER_FIREBASE_MESUREMENT_ID
  },
  'firebaseWorkforcePartner' // this name will be used to retrieve firebase instance. E.g. second.database();
)

// firebaseSupportPlatform.firestore().enablePersistence()
//   .catch((err) => {
//     console.log(err)
//   })

export {
  firebase
}
