import { connect } from 'react-redux'
import { fetch, destroy } from 'actions/resources'
import { push } from 'react-router-redux'
import { getResource } from 'selectors/resources'

import { reviewSchema } from '@seekster/schemas'

import withFlashMessage from 'hoc/withFlashMessage'

import ReviewShowPage from './ReviewShowPage'

const mapStateToProps = (state, { match }) => ({
  review: getResource(state, match.params.id, reviewSchema)
})

const mapDispatchToProps = {
  getReview: (id, options) => fetch(id, reviewSchema, options),
  destroyResource: (id, options) => destroy(id, reviewSchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(withFlashMessage(ReviewShowPage))
