import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  overrides: {
    MuiCardHeader: {
      title: {
        fontWeight: 500
      }
    }
  },
  spacing: 7,

  typography: {
    fontSize: 14
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 568,
      md: 768,
      lg: 1024,
      xl: 1280
    }
  },

  palette: {
    primary: {
      main: '#1b4170'
    },

    secondary: {
      main: '#5b8ac3'
    },

    success: {
      main: '#21ba45'
    },

    pending: {
      main: '#ffcb2b'
    },

    warning: {
      main: '#f1701b'
    }
  }

})
