import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { EditRounded, Delete } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import { AppCommentsPane, AppOverviewPane } from './panes'

export function AppShowPage ({
  app,
  deletingResource,
  fetchComments,
  match,
  location,
  setFlashMessage,
  fetchApp,
  destroy,
  push,
  t
}) {
  const { loading } = useData(() => fetchApp(match.params.id))
  useData(() => fetchComments(match.params.id), [])

  function handleDeleteApp (e) {
    if (window.confirm('Are you sure you want to delete this?')) {
      destroy(match.params.id).then(response => {
        push('/apps')
      })
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function secondaryActions () {
    return [
      {
        avatar: <EditRounded />,
        content: t('edit'),
        to: `/apps/${match.params.id}/edit`
      },
      {
        avatar: <Delete />,
        content: t('delete'),
        onClick: handleDeleteApp
      }
    ]
  }

  function subNavbar () {
    const path = `/apps/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('common:navbar.overview')} to={path} />
        <NavigationMenuItem
          label={t('navbar.comments')}
          badge={app.get('comments', 0).size}
          to={`${path}/comments`}
        />
        <NavigationMenuItem label={t('timeline')} to={`${path}/timeline`} />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs () {
    return [{
      text: 'Apps',
      link: '/apps'
    }, {
      text: app.get('name')
    }]
  }

  return (
    <PageLayout
      title={app.get('name')}
      actions={secondaryActions()}
      subNavbar={subNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/apps/:id' component={AppOverviewPane} exact />
        <AuthenticatedRoute path='/apps/:id/comments' component={AppCommentsPane} />
      </Switch>
    </PageLayout>
  )
}

AppShowPage.propTypes = {
  app: ImmutablePropTypes.map,
  deletingResource: PropTypes.bool,
  match: PropTypes.object,
  location: PropTypes.object,
  fetchComments: PropTypes.func,
  setFlashMessage: PropTypes.func,
  fetchApp: PropTypes.func,
  destroy: PropTypes.func,
  push: PropTypes.func,

  t: PropTypes.func
}

AppShowPage.defaultProps = {
  app: Map(),
  fetchApp: () => {}
}

export default withTranslation(['app', 'pages', 'actions', 'common'])(AppShowPage)
