import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Button, Avatar, Typography } from '@material-ui/core'
import { components } from 'react-select'
import { withTranslation } from 'react-i18next'

import Field from 'components/fields/Field'
import DateTimeInputField from 'components/fields/DateTimeInputField'
import DropzoneInput from 'components/inputs/DropzoneInput'
import ResourceSelect from 'components/inputs/ResourceSelect'
import { bankAccountSchema } from '@seekster/schemas'

import styles from './BankTransferPaymentFormStyles'

export class BankTransferPaymentForm extends React.Component {
  static propTypes = {
    invoice: ImmutablePropTypes.map,
    onCancel: PropTypes.func,
    classes: PropTypes.object,

    t: PropTypes.func
  }

  static defaultProps = {
    invoice: Map()
  }

  render() {
    const { invoice, classes, onCancel, t } = this.props

    return (
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <Field
              name='bank_transfer_attributes.image'
              component={DropzoneInput}
              width={500}
              height={700}
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <Field
              name='amount_satangs'
              hint={`${t('outstanding_balance')}: ${invoice.getIn([
                'outstanding_balance',
                'display_value'
              ])}`}
            />

            <Field
              name='bank_account_id'
              component={ResourceSelect}
              schema={bankAccountSchema}
              getOptionLabel={({ account_name: accountName }) => accountName}
              components={{
                Option: (props) => (
                  <components.Option {...props}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Avatar
                          src={props.data.bank.logo_url}
                          alt={props.data.bank.name}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant='subtitle1'>
                          {props.data.account_name}
                        </Typography>
                        <Typography variant='caption'>
                          {props.data.formatted_account_number}
                        </Typography>
                      </Grid>
                    </Grid>
                  </components.Option>
                )
              }}
            />

            <DateTimeInputField name='captured_at' />
          </Grid>

          <Grid item xs={12}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.button}
            >
              {this.props.t('save')}
            </Button>
            <Button variant='contained' onClick={onCancel} className={classes.button}>
              {t('cancel')}
            </Button>
          </Grid>
        </Grid>
      </Form>
    )
  }
}

export default compose(
  withStyles(styles),
  withTranslation(['payments', 'actions'])
)(BankTransferPaymentForm)
