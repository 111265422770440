import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Tab } from '@material-ui/core'

import styles from './TabPaneStyles'

export class TabPane extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.number,
    classes: PropTypes.object
  }

  render() {
    const { children, label, value, classes, ...rest } = this.props
    return (
      <Tab label={label} value={value} classes={classes} {...rest}>
        {children}
      </Tab>
    )
  }
}

export default withStyles(styles)(TabPane)
