import { connect } from 'react-redux'
import { compose } from 'redux'
import { push } from 'react-router-redux'

import { withQueryParser } from 'utils/withQueryParser'
import { fetchChildren } from 'actions/collections'
import { destroy } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { companySchema, addressSchema } from '@seekster/schemas'

import CompanyAddressesPane from './CompanyAddressesPane'

const mapStateToProps = (state, { match }) => ({
  company: getResource(state, match.params.id, companySchema)
})

const mapDispatchToProps = {
  fetchCompanyAddresses: (id, options) => fetchChildren(companySchema, id, addressSchema, options),
  destroyCompanyAddresses: (id, options) => destroy(id, addressSchema, options),
  push
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(CompanyAddressesPane)
