export default ({ palette }) => ({
  button: {
    '& > div': {
      width: '100% !important',
      borderRadius: 4,
      backgroundColor: 'white',
      color: palette.grey[800],

      '&:hover': {
        backgroundColor: 'white',
        color: palette.primary.main
      }
    }
  }
})
