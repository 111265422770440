import { connect } from 'react-redux'

import { fetch } from 'actions/resources'
import { trigger } from 'actions/resourceEvent'
import { jobSchema, jobProviderSchema } from '@seekster/schemas'

import JobProviderActionsMenu from './JobProviderActionsMenu'

const mapDispatchToProps = {
  fetchJob: id => fetch(id, jobSchema),
  triggerJobProvider: (id, action) => trigger(id, jobProviderSchema, action)
}

export default connect(null, mapDispatchToProps)(JobProviderActionsMenu)
