import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { Table, Column } from 'components/tables/Table'
import Rating from 'components/Rating'
import AttributeLabel from 'components/labels/AttributeLabel'
import { DateTimeFormatter } from 'components/formatters'

import styles from './ReviewsTableStyles'

export function ReviewsTable({ data, exclude, classes }) {
  const { t } = useTranslation('reviews')

  return (
    <Paper square>
      <Table collection={data} padding='dense'>
        <Column name='id' to={(review) => `/reviews/${review.get('id')}`} />
        <Column
          name='review_type_id'
          render={(param, review) => (
            <span>{review.getIn(['review_type', 'name']) || '-'}</span>
          )}
        />
        <Column
          name='rating'
          render={(rating, review) => (
            <>
              <Rating value={rating} />

              <AttributeLabel title={t('content')}>
                <div className={classes.reviewContent}>
                  {review.get('content') || `-`}
                </div>
              </AttributeLabel>
            </>
          )}
        />
        {!exclude.job && (
          <Column
            name='job.number'
            to={(review) => `/jobs/${review.getIn(['job', 'id'])}`}
          />
        )}
        {!exclude.customer && (
          <Column
            name='customer.display_name'
            to={(review) => `/customers/${review.getIn(['customer', 'id'])}`}
            columnName={t('customer')}
          />
        )}
        {!exclude.provider && (
          <Column
            name='provider.display_name'
            to={(review) => `/providers/${review.getIn(['provider', 'id'])}`}
            columnName={t('provider')}
          />
        )}
        <Column name='created_at' component={DateTimeFormatter} />
      </Table>
    </Paper>
  )
}

ReviewsTable.propTypes = {
  data: ImmutablePropTypes.list,
  exclude: PropTypes.object,
  classes: PropTypes.object
}

ReviewsTable.defaultProps = {
  exclude: {
    job: false,
    customer: false,
    provider: false
  }
}

export default withStyles(styles)(ReviewsTable)
