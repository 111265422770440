import moment from 'moment'
import { get } from 'lodash'

export const providersQuery = ({
  search,
  badge_id,
  state,
  rating,
  created_at,
  last_active_at
}) => {
  return {
    first_name_or_last_name_or_phone_number_or_email_or_alias_or_tags_name_cont: search,

    badge_id_eq: badge_id,

    state_eq: state,

    rating_gteq: get(rating, 'min'),
    rating_lteq: get(rating, 'max'),

    created_at_gteq: get(created_at, 'from')
      ? moment(created_at.from).startOf('day').toISOString(true)
      : undefined,
    created_at_lteq: get(created_at, 'to')
      ? moment(created_at.to).endOf('day').toISOString(true)
      : undefined,

    last_active_at_gteq: get(last_active_at, 'from')
      ? moment(last_active_at.from).startOf('day').toISOString(true)
      : undefined,
    last_active_at_lteq: get(last_active_at, 'to')
      ? moment(last_active_at.to).endOf('day').toISOString(true)
      : undefined
  }
}

export default providersQuery
