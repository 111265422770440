import React from 'react'
import { Form } from 'formik'

import { Button } from 'components/buttons'
import { Field, FastField } from 'components/fields/Field'
import DateRangeField from 'components/fields/DateRangeField'
import ResourceSelect from 'components/inputs/ResourceSelect'
import SelectInput from 'components/inputs/SelectInput'
import { serviceSchema, customerSchema, providerSchema } from '@seekster/schemas'
import arrayToOptions from 'utils/arrayToOptions'
import { SLA_PENDING_STATES } from 'constants/states/slaPendingStates'

const SLAPendingStartFilterForm = (props) => {
  const handleSubmit = () => {
    props.handleSubmit()
    props.onCloseModal()
  }
  return (
    <Form>
      <DateRangeField name='jobs_start_date' />
      <DateRangeField name='created_at' />
      <FastField
        name='state'
        component={SelectInput}
        options={arrayToOptions(SLA_PENDING_STATES, {
          i18nNamespace: 'sla_pending_start',
          i18nKey: 'states'
        })}
      />

      <Field name='service_id' component={ResourceSelect} schema={serviceSchema} />
      <Field name='customer_id' component={ResourceSelect} schema={customerSchema} />
      <Field name='provider_id' component={ResourceSelect} schema={providerSchema} />

      <div>
        <Button color='primary' onClick={handleSubmit}>
          Apply
        </Button>

        <Button onClick={props.onCloseModal}>Cancel</Button>
      </div>
    </Form>
  )
}

export default SLAPendingStartFilterForm
