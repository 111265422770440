import React from 'react'
import PropTypes from 'prop-types'
import { FilterAssociationTags } from '../../FilterAssociationTags'

export class FilterBadgeTags extends React.Component {
  static propTypes = {
    badgeName: PropTypes.string,
    fetchBadge: PropTypes.func,
    query: PropTypes.object,
    onQueryPush: PropTypes.func
  }

  static defaultProps = {
    badgeName: '',

    fetchBadge: () => {}
  }

  componentDidMount () {
    const { query, fetchBadge } = this.props

    query.badge_id && fetchBadge(query.badge_id)
  }

  renderTag () {
    if (this.props.query.badge_id) {
      return (
        <FilterAssociationTags
          content={`Badge: ${this.props.badgeName}`}
          name='badge_id'
          query={this.props.query}
          onQueryPush={this.props.onQueryPush}
        />
      )
    }
    else {
      return null
    }
  }

  render () {
    return this.renderTag()
  }
}

export default FilterBadgeTags
