export default theme => ({
  contentContainer: {
    display: 'flex'
  },

  content: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  reviewer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})
