import { Map, List } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'

import CustomerForm from './CustomerForm'

const formikConfig = {
  mapPropsToValues: ({ customer = Map() }) => {
    const customerType = customer.get('customer_type') || Map()
    return {
      first_name: customer.get('first_name') || '',
      last_name: customer.get('last_name') || '',
      alias: customer.get('alias') || '',
      gender: customer.get('gender') || '',
      email: customer.get('email') || '',
      phone_number: customer.get('phone_number') || '',
      date_of_birth: customer.get('date_of_birth') || '',
      tag_ids: customer
        .get('tags', List())
        .map((tag) => tag.get('id'))
        .toJS(),
      customer_type_id: customerType.get('id'),
      support_by_id: customer.getIn(['support_by', 'id']),
      responsible_by_id: customer.getIn(['responsible_by', 'id'])
    }
  },

  validationSchema: Yup.object().shape({
    first_name: Yup.string().required(),
    email: Yup.string().email(),
    phone_number: Yup.string()
      .matches(/(^0[23456789]\d{7,8}$)/, 'Invalid phone number')
      .required()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit(values)
      .then((response) => {
        props.push(`/customers/${response.body.id}`)
      })
      .catch((error) => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(withFlashMessage, withFormik(formikConfig))(CustomerForm)
