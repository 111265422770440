import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Moment from 'moment'
import { List } from 'immutable'
import { Link } from 'react-router-dom'
import { Paper, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import { Table, Column } from 'components/tables/Table'
import { StateTag, PhoneTag } from 'components/tags'
import ButtonLoader from 'components/buttons/ButtonLoader'

function SMSRatingsTable({ jobs, onSendIndividual, smsLoading, onSendMultiple }) {
  return (
    <Paper>
      <Grid
        container
        direction='row'
        style={{
          justifyContent: 'flex-end',
          paddingRight: 30,
          paddingTop: 14
        }}
      >
        <ButtonLoader
          onClick={onSendMultiple}
          variant='contained'
          primary
          isLoading={smsLoading}
        >
          Send All
        </ButtonLoader>
      </Grid>
      <Table collection={jobs}>
        <Column
          name='number'
          render={(number, job) => <Link to={`/jobs/${job.get('id')}`}>{number}</Link>}
        />

        <Column
          name='customer'
          render={(customer, job) => (
            <>
              <p>
                <Link to={`/customers/${customer.get('id')}`}>
                  {customer.get('display_name')}
                </Link>
              </p>
              <p>
                <PhoneTag value={job.getIn(['location', 'phone_number'])} />
              </p>
            </>
          )}
        />

        <Column
          name='service'
          render={(service) => (
            <Link to={`/services/${service.get('id')}`}>{service.get('name')}</Link>
          )}
        />

        <Column
          name='orders'
          render={(orders) => (
            <ul>
              {orders.map((order) => (
                <li key={order.get('id')}>{order.get('description')}</li>
              ))}
            </ul>
          )}
        />

        <Column
          name='booking_date'
          render={(date, job) =>
            Moment(job.get('start_time')).format('ddd, D MMM YYYY - HH:mm')
          }
          isSort
        />

        <Column name='location.short_address' />

        <Column
          name='job_providers'
          render={(jobProviders, job) => {
            return jobProviders
              .filter(
                (jobProvider) =>
                  jobProvider.get('state') !== 'cancelled' &&
                  jobProvider.get('state') !== 'rejected'
              )
              .map((jobProvider) => (
                <Link
                  to={`/providers/${jobProvider.getIn(['provider', 'id'])}`}
                  key={jobProvider.getIn(['provider', 'id'])}
                >
                  <div>{jobProvider.getIn(['provider', 'display_name'])}</div>
                  <div>{jobProvider.getIn(['provider', 'phone_number'])}</div>
                </Link>
              ))
          }}
        />
        <Column
          name='is_rated'
          render={(isRated) =>
            <div style={{ width: 80 }}>
              <StateTag value={isRated ? 'rated' : 'no_rating'} />
            </div>
          }
        />
        <Column
          name='send_sms_rating_at'
          render={(sendSmsRatingAt, job) =>
            <div style={{ width: 105 }}>
              <ButtonLoader
                onClick={() => onSendIndividual(job.get('id'))}
                variant='contained'
                disabled={!!sendSmsRatingAt || job.get('is_rated') || smsLoading}
                primary
                isLoading={smsLoading}
              >
                Send SMS
              </ButtonLoader>
            </div>
          }
        />
      </Table>
    </Paper>
  )
}

SMSRatingsTable.propTypes = {
  jobs: ImmutablePropTypes.list,
  onSendIndividual: PropTypes.func,
  onSendMultiple: PropTypes.func,
  smsLoading: PropTypes.bool
}

SMSRatingsTable.defaultProps = {
  jobs: List(),
  smsLoading: false
}

export default SMSRatingsTable
