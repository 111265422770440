import bowser from 'bowser'
import pluralize from 'pluralize'

import request from 'utils/request'
import withTenant from 'utils/withTenant'
import convertJsonBodyToFormData from 'utils/convertJsonBodyToFormData'
import { getToken } from 'selectors/authentication'
import {
  SIGN_IN_SUCCEEDED,
  SIGN_OUT,
  ACCEPT_INVITATION_SUCCEEDED,
  FETCH_CURRENT_DEVICE_SUCCEEDED,
  FETCH_CURRENT_SUCCEEDED,
  CREATE_CURRENT_SUCCEEDED,
  UPDATE_CURRENT_SUCCEEDED,
  DESTROY_CURRENT_SUCCEEDED,
  UPDATE_CURRENT_DEVICE_SUCCEEDED
} from 'constants/authentication'
import { tenantSchema } from '@seekster/schemas'
import { setDatalist } from './cancellation'

const signInSucceeded = (response, options) => ({
  type: SIGN_IN_SUCCEEDED,
  response,
  options
})

const beginSignOut = () => ({
  type: SIGN_OUT
})

const acceptInvitationSucceeded = (response, options) => ({
  type: ACCEPT_INVITATION_SUCCEEDED,
  response,
  options
})

const fetchCurrentSucceeded = (response, schema) => ({
  type: FETCH_CURRENT_SUCCEEDED,
  response,
  schema
})

const fetchCurrentDeviceSucceeded = (response) => ({
  type: FETCH_CURRENT_DEVICE_SUCCEEDED,
  response
})

const createCurrentSucceeded = (response, schema) => ({
  type: CREATE_CURRENT_SUCCEEDED,
  response,
  schema
})

const updateCurrentSucceeded = (response, schema) => ({
  type: UPDATE_CURRENT_SUCCEEDED,
  response,
  schema
})

const updateCurrentDeviceSucceeded = (response) => ({
  type: UPDATE_CURRENT_DEVICE_SUCCEEDED,
  response
})

const destroyCurrentSucceeded = (response, schema) => ({
  type: DESTROY_CURRENT_SUCCEEDED,
  response,
  schema
})

const browser = bowser.getParser(navigator.userAgent).getBrowser()

let uuid, FCMToken

const devicesAttributes = () => {
  if (!uuid) uuid = window.localStorage.getItem('uuid')
  if (!FCMToken) FCMToken = window.localStorage.getItem('FCM_token')
  return {
    locale: navigator.language.slice(0, 2),
    model: browser.name,
    brand: navigator.vendor,
    os_version: browser.version,
    uuid: uuid,
    registration_token: FCMToken || undefined
  }
}

const withDevicesAttributes = (data) => ({
  ...data,
  devices_attributes: devicesAttributes()
})

export const signIn =
  (data, options = {}) =>
  (dispatch, getState) => {
    data = withTenant(data)
    data = withDevicesAttributes(data)

    return request
      .post('/sign_in')
      .send(data)
      .then((response) => {
        dispatch(signInSucceeded(response, options))
        return response
      })
  }

export const signInWithOAuth =
  (data, options = {}) =>
  (dispatch, getState) => {
    data = withTenant(data)
    data = withDevicesAttributes(data)

    return request
      .post('/oauth')
      .send(data)
      .then((response) => {
        dispatch(signInSucceeded(response, options))
        return response
      })
  }

export const signOut =
  (data, options = {}) =>
  (dispatch, getState) => {
    dispatch(beginSignOut())

    return request.del('/sign_out').authentication(getToken(getState())).send(data)
  }

export const acceptInvitationWithOauth =
  (data, invitationToken, options = {}) =>
  (dispatch) => {
    data = withTenant(data)
    data = withDevicesAttributes(data)

    return request
      .put(`/invitations/${invitationToken}/accept_with_oauth`)
      .send(data)
      .then((response) => {
        dispatch(acceptInvitationSucceeded(response, options))
        return response
      })
  }

export const acceptInvitationWithEmail =
  (data, invitationToken, options = {}) =>
  (dispatch) => {
    data = withTenant(data)
    data = withDevicesAttributes(data)

    return request
      .put(`/invitations/${invitationToken}/accept_with_email`)
      .send(data)
      .then((response) => {
        dispatch(acceptInvitationSucceeded(response, options))
        return response
      })
  }

export const fetchCurrentTenantBySlug = (schema) => (dispatch) => {
  return request
    .get('/tenant')
    .query(withTenant())
    .then((response) => {
      dispatch(fetchCurrentSucceeded(response, schema))

      return response
    })
    .catch((error) => {
      return error
    })
}

export const fetchCurrentDevice = () => (dispatch, getState) => {
  return request
    .get('/device')
    .authentication(getToken(getState()))
    .then((response) => {
      dispatch(
        setDatalist({ data: {}, status: false, tenantId: response.body.client.tenant_id })
      )
      dispatch(fetchCurrentDeviceSucceeded(response))

      return response
    })
    .catch((error) => {
      return error
    })
}

export const fetchCurrent = (schema) => (dispatch, getState) => {
  const currentType = pluralize.singular(schema._key)

  return request
    .get(`/${currentType}`)
    .authentication(getToken(getState()))
    .then((response) => {
      dispatch(fetchCurrentSucceeded(response, schema))

      return response
    })
}

export const createCurrent = (data, schema) => (dispatch, getState) => {
  const formData = convertJsonBodyToFormData(data)
  const currentType = pluralize.singular(schema._key)

  const requestBuilder = request
    .post(`/${currentType}`)
    .authentication(getToken(getState()))

  if (formData) {
    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] !== 'undefined' && formData[key] !== null) {
        requestBuilder.field(key, formData[key])
      }
    })
  }

  return requestBuilder.then((response) => {
    dispatch(createCurrentSucceeded(response, schema))

    return response
  })
}

export const updateCurrent = (data, schema) => (dispatch, getState) => {
  const formData = convertJsonBodyToFormData(data)
  const currentType = pluralize.singular(schema._key)

  const requestBuilder = request
    .patch(`/${currentType}`)
    .authentication(getToken(getState()))

  Object.keys(formData).forEach((key) => {
    if (typeof formData[key] !== 'undefined' && formData[key] !== null) {
      requestBuilder.field(key, formData[key])
    }
  })

  return requestBuilder.then((response) => {
    dispatch(updateCurrentSucceeded(response, schema))

    return response
  })
}

export const destroyCurrent = (schema) => (dispatch, getState) => {
  const currentType = pluralize.singular(schema._key)

  return request
    .delete(`/${currentType}`)
    .authentication(getToken(getState()))
    .then((response) => {
      dispatch(destroyCurrentSucceeded(response, schema))

      return response
    })
}

export const updateCurrentDevice = (data) => (dispatch, getState) => {
  const formData = convertJsonBodyToFormData({ ...devicesAttributes(), ...data })

  const requestBuilder = request.patch('/device').authentication(getToken(getState()))

  Object.keys(formData).forEach((key) => {
    if (typeof formData[key] !== 'undefined' && formData[key] !== null) {
      requestBuilder.field(key, formData[key])
    }
  })

  return requestBuilder.then((response) => {
    dispatch(updateCurrentDeviceSucceeded(response, tenantSchema))

    return response
  })
}

export const fetchCustomToken = () => (dispatch, getState) => {
  return request
    .get(process.env.REACT_APP_FIREBASE_FUNCTION_CUSTOM_TOKEN_URL)
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .authentication(getToken(getState()))
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}
