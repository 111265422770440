import { connect } from 'react-redux'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'
import { getCollection } from 'selectors/collections'
import { fetch } from 'actions/collections'
import { serviceSchema } from '@seekster/schemas'

import CategoryFormController from './CategoryFormController'

const mapStateToProps = (state) => ({
  services: getCollection(state, serviceSchema)
})

const mapDispatchToProps = {
  fetchServices: (options) => fetch(serviceSchema, options)
}

export default compose(withQueryParser, connect(mapStateToProps, mapDispatchToProps))(CategoryFormController)
