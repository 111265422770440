import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Timeline, TimelineEvent } from 'react-event-timeline'

import Panel from 'components/containers/Panel'
import GoogleRouteMap from 'components/maps/GoogleRouteMap'
import AddressFormatter from 'components/formatters/AddressFormatter'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(2),
    marginTop: spacing(2),

    '@media screen and (max-width: 767px)': {
      marginBottom: spacing(2)
    }
  },

  marker: {
    width: 20
  }
}))

export function RequestMapSection({
  origin,
  destination,
  route,
  location,
  isDelivery,
  currentLocation = null
}) {
  const classes = useStyles()
  const { t } = useTranslation(['common'])

  return (
    <Panel className={classes.root}>
      {isDelivery ? (
        <GoogleRouteMap
          route={route}
          location={location}
          currentLocation={currentLocation}
        />
      ) : (
        <div style={{ width: '100%', overflow: 'hidden', height: '400px' }}>
          <iframe
            title='customer location'
            src={`https://maps.google.com/maps/embed/v1/place?key=AIzaSyALQf_mHm2Kr5adNu_XQZDM_JBo-ZHoIHE&q=${location.get(
              'latitude'
            )},${location.get('longitude')}`}
            width='100%'
            height='400'
            frameBorder='0'
            loading='lazy'
            // style={{ border: 0, marginTop: '-150px' }}
            style={{ border: 0 }}
          />
        </div>
      )}

      {isDelivery ? (
        <Timeline lineStyle={{ height: '50%', top: 22 }}>
          <TimelineEvent
            iconColor='#0d36ff'
            icon={
              <img
                src='https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/icon/blue-marker.svg'
                alt='blue maker'
                className={classes.marker}
              />
            }
          >
            <b>{`${t('origin')} A: `}</b>{' '}
            {route.getIn(['origin', 'full_address']) ? (
              `${route.getIn(['origin', 'contact_name'])}, ${route.getIn([
                'origin',
                'phone_number'
              ])}, ${route.getIn(['origin', 'full_address'])}`
            ) : (
              <AddressFormatter value={origin && origin} />
            )}
          </TimelineEvent>
          <TimelineEvent
            iconColor='#ee3840'
            icon={
              <img
                src='https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/icon/red-marker.svg'
                alt='red maker'
                className={classes.marker}
              />
            }
          >
            <b>{`${t('destination')} B: `}</b>{' '}
            {route.getIn(['destination', 'full_address']) ? (
              `${route.getIn(['destination', 'contact_name'])}, ${route.getIn([
                'destination',
                'phone_number'
              ])}, ${route.getIn(['destination', 'full_address'])}`
            ) : (
              <AddressFormatter value={destination && destination} />
            )}
          </TimelineEvent>
        </Timeline>
      ) : (
        <Timeline>
          <TimelineEvent iconColor='#03a9f4'>
            {location.get('full_address')}
          </TimelineEvent>
        </Timeline>
      )}
    </Panel>
  )
}

export default RequestMapSection
