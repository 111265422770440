import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { issueSchema } from '@seekster/schemas'

import ProviderIssueOverviewPane from './ProviderIssueOverviewPane'

const mapStateToProps = (state, { match }) => ({
  issue: getResource(state, match.params.id, issueSchema)
})

export default connect(mapStateToProps)(ProviderIssueOverviewPane)
