import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { destroy, fetch, fetchBlobFile } from 'actions/resources'
import trigger from 'actions/resourceEvent'
import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { inquirySchema, commentSchema, subscriptionSchema } from '@seekster/schemas'

import withFlashMessage from 'hoc/withFlashMessage'

import InquiryShowPage from './InquiryShowPage'

const mapStateToProps = (state, { match }) => ({
  inquiry: getResource(state, match.params.id, inquirySchema)
})

const mapDispatchToProps = {
  fetchComments: (id, options) => fetchChildren(inquirySchema, id, commentSchema, options),
  fetchInquiry: id => fetch(id, inquirySchema),
  fetchReport: id => fetchBlobFile(inquirySchema, id, null, { childrenType: 'download' }),
  destroyInquiry: id => destroy(id, inquirySchema),
  triggerInquiry: (id, action) => trigger(id, inquirySchema, action),
  triggerSubscription: (id, action) => trigger(id, subscriptionSchema, action),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(withFlashMessage(InquiryShowPage))
