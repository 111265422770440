import React from 'react'
import PropTypes from 'prop-types'
import { map, pickBy, isString, includes, startCase } from 'lodash'
import { Chip } from '@material-ui/core'

import { withQueryParser } from 'utils/withQueryParser'

export class FilterSelectTags extends React.Component {
  static propTypes = {
    query: PropTypes.object,
    onQueryPush: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.handleRemoveTag = this.handleRemoveTag.bind(this)
  }

  handleRemoveTag (e, { queryKey }) {
    const { [queryKey]: removedValue, ...rest } = this.props.query

    this.props.onQueryPush(rest)
  }

  render () {
    const { search, page, ...query } = pickBy(
      this.props.query,
      (queryValue, queryKey) => (
        isString(queryValue) & !includes(queryKey, '_id')
      )
    )

    return map(query, (value, key) => {
      const contentValue = key === 'rating'
        ? String.fromCharCode(9733).repeat(value) : startCase(value)

      return (
        <Chip
          key={key}
          variant='outlined'
          color='primary'
          size='small'
          style={{ margin: '0 6px 6px 0' }}
          label={`${startCase(key)}: ${contentValue}`}
          onDelete={(e) => this.handleRemoveTag(e, { queryKey: key })}
        />
      )
    })
  }
}

export default withQueryParser(FilterSelectTags)
