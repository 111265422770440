import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Button as MaterialButton } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  loading: {
    color: 'green',
    position: 'absolute'
  }
}))

export function Button ({ disabled, loading, children, ...rest }) {
  const classes = useStyles()

  return (
    <MaterialButton
      disabled={disabled || loading}
      {...rest}
    >
      {children}

      {loading && (
        <CircularProgress size={24} className={classes.loading} />
      )}
    </MaterialButton>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  primary: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
}

export default Button
