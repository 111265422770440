import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { createChild } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { appSchema, commentSchema } from '@seekster/schemas'

import AppCommentsPane from './AppCommentsPane'

const mapStateToProps = (state, { match }) => ({
  app: getResource(state, match.params.id, appSchema)
})

const mapDispatchToProps = {
  loadComments: id => fetchChildren(appSchema, id, commentSchema),
  create: (data, id) => createChild(data, appSchema, id, commentSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(AppCommentsPane)
