import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getToken } from 'selectors/authentication'

import useQuery from 'hooks/useQuery'

export function AuthenticatedRoute({
  component: Component,
  redirectPath,
  componentProps,
  ...rest
}) {
  const [query, { pushQuery }] = useQuery()
  const token = useSelector(getToken)

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component
            {...rest}
            {...props}
            query={query}
            onQueryPush={pushQuery}
            {...componentProps}
          />
        ) : (
          <Redirect to={redirectPath} />
        )
      }
    />
  )
}

AuthenticatedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  token: PropTypes.string,
  redirectPath: PropTypes.string,
  query: PropTypes.object,
  onQueryPush: PropTypes.func,
  componentProps: PropTypes.node
}

AuthenticatedRoute.defaultProps = {
  component: null,
  query: {},
  redirectPath: '/sign_in'
}

export default AuthenticatedRoute
