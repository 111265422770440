export default ({ spacing, breakpoints }) => ({
  container: {
    flexDirection: 'column-reverse',

    [breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },

  nameSection: {
    marginBottom: spacing(2),
    padding: '8px 8px 0 16px'
  },

  listMuiContainer: {
    padding: '10px 16px'
  },

  gridContainer: {
    paddingLeft: 16
  },

  icon: {
    width: 55,
    height: 55
  },

  chip: {
    marginBottom: spacing(1),
    paddingLeft: 16
  },

  banner: {
    maxHeight: 300,
    maxWidth: '100%',
    marginBottom: spacing(2)
  },

  thumbnail: {
    maxHeight: 265,
    maxWidth: '100%'
  }
})
