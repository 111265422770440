import React from 'react'
import { Button } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { Form } from 'formik'
import { FastField } from 'components/fields/Field'

export class SignInForm extends React.Component {
  render () {
    const { isSubmitting, t } = this.props

    return (
      <Form>
        <FastField icon='mail' iconPosition='left' noLabel type='email' name='email' placeholder={t('email')} />
        <FastField icon='lock' iconPosition='left' noLabel type='password' name='password' placeholder={t('password')} />

        <div className='form-group'>
          <Button fullWidth variant='contained' color='primary' type='submit' loading={isSubmitting}>{t('sign_in')}</Button>
        </div>
      </Form>
    )
  }
}

export default withTranslation('authentication')(SignInForm)
