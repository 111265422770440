import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Select, InputBase, MenuItem } from '@material-ui/core'

import withLocalizedFields from 'hoc/withLocalizedFields'

import { usePush } from 'hooks/utils'
import { useResource, useResourceAction } from 'hooks/resources'
import { serviceSchema } from '@seekster/schemas'

import PageLayout from 'components/layouts/PageLayout'
import ServiceForm from 'forms/resources/ServiceForm'

export function ServiceDuplicatePage({ match, locale, setLocale, t }) {
  const [service, { loading }] = useResource(serviceSchema, match.params.id)
  const { create: createService } = useResourceAction(serviceSchema)
  const push = usePush()

  function renderActions() {
    return [
      <Select
        key='1'
        value={locale}
        onChange={(e) => setLocale(e.target.value)}
        input={<InputBase />}
      >
        <MenuItem value='en'>
          <span role='img' aria-label='GB flag'>
            🇬🇧{' '}
          </span>
          English
        </MenuItem>
        <MenuItem value='th'>
          <span role='img' aria-label='Thai flag'>
            🇹🇭{' '}
          </span>
          ภาษาไทย
        </MenuItem>
      </Select>
    ]
  }

  return (
    <PageLayout
      title={t('duplicate')}
      secondaryActions={renderActions()}
      isLoading={loading}
      back={`/services/${match.params.id}`}
    >
      <ServiceForm
        service={service}
        push={push}
        onSubmit={createService}
        withoutAssociationIds
        withoutPackageCode
        withoutSlotId
      />
    </PageLayout>
  )
}

ServiceDuplicatePage.propTypes = {
  match: PropTypes.object,
  locale: PropTypes.string,
  setLocale: PropTypes.func,

  t: PropTypes.func
}

export default compose(
  withLocalizedFields,
  withTranslation('services')
)(ServiceDuplicatePage)
