import React from 'react'
import { Grid } from '@material-ui/core'
import Panel from 'components/containers/Panel'
import AttributeLabel from 'components/labels/AttributeLabel'
import { makeStyles } from '@material-ui/core/styles'
import { usePush } from 'hooks/utils'

const useStyles = makeStyles({
  contentContainer: {
    padding: '8px 8px 8px 16px'
  },
  panel: {
    cursor: 'pointer'
  }
})

export function WebhookCardList ({ webhooks }) {
  const classes = useStyles()
  const push = usePush()

  function capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <Grid container spacing={3} style={{ padding: 16 }}>
      {
        webhooks.map(webhook =>
          <Grid
            item
            xs={12}
            sm={4}
            key={webhook.get('id')}
            className={classes.container}
            onClick={() => push(`/webhooks/${webhook.get('id')}`)}
          >
            <Panel title={webhook.get('name')} className={classes.panel}>
              <div className={classes.contentContainer}>
                <Grid item xs={12}>
                  <AttributeLabel title='Type' style={{ wordBreak: 'break-all' }}>
                    {webhook.getIn(['source', 'type'])}
                  </AttributeLabel>
                </Grid>
                <Grid item xs={12}>
                  <AttributeLabel title={`${capitalizeFirstLetter(webhook.getIn(['source', 'type']))} Name`} style={{ wordBreak: 'break-all' }}>
                    {webhook.getIn(['source', 'name'])}
                  </AttributeLabel>
                </Grid>
                <Grid item xs={12}>
                  <AttributeLabel title='Webhook URL' style={{ wordBreak: 'break-all' }}>
                    {webhook.get('webhook_url')}
                  </AttributeLabel>
                </Grid>
              </div>
            </Panel>
          </Grid>
        )
      }
    </Grid>
  )
}

export default WebhookCardList
