import moment from 'moment'
import { get } from 'lodash'

export const subscriptionsQuery = ({
  search,
  state,
  service_id,
  start_date,
  created_at
}) => {
  return {
    number_or_customer_phone_number_or_customer_first_name_or_customer_last_name_or_location_phone_number_or_location_house_number_or_location_name_or_location_street_or_location_project_name_or_location_sub_district_or_location_district_or_location_province_cont: search,

    state_eq: state,

    service_id_eq: service_id,

    start_date_gteq: get(start_date, 'from')
      ? moment(start_date.from).startOf('day').toISOString(true)
      : undefined,
    start_date_lteq: get(start_date, 'to')
      ? moment(start_date.to).endOf('day').toISOString(true)
      : undefined,

    created_at_gteq: get(created_at, 'from')
      ? moment(created_at.from).startOf('day').toISOString(true)
      : undefined,
    created_at_lteq: get(created_at, 'to')
      ? moment(created_at.to).endOf('day').toISOString(true)
      : undefined
  }
}

export default subscriptionsQuery
