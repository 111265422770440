import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Grid, Button } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import { Field, FastField } from 'components/fields/Field'
import DateRangeField from 'components/fields/DateRangeField'
import ResourceSelect from 'components/inputs/ResourceSelect'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'
import { categorySchema } from '@seekster/schemas'

export class ServicesFilterForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    onCloseModal: PropTypes.func,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit () {
    this.props.handleSubmit()

    this.props.onCloseModal()
  }

  render () {
    return (
      <Form>
        <DateRangeField name='created_at' />
        <Field name='categories_id' component={ResourceSelect} schema={categorySchema} />
        <FastField
          name='active'
          component={SelectInput}
          options={arrayToOptions(['true', 'false'], { i18nNamespace: 'common', i18nKey: 'boolean' })}
        />

        <Grid item xs={12} container justify='flex-end'>
          <Button
            variant='contained'
            type='button'
            onClick={this.props.onCloseModal}
          >
            {this.props.t('cancel')}
          </Button>

          <Button
            variant='contained'
            color='primary'
            type='submit'
            style={{ marginLeft: 8 }}
            onClick={this.handleSubmit}
          >
            {this.props.t('apply')}
          </Button>
        </Grid>
      </Form>
    )
  }
}

export default withTranslation('filters')(ServicesFilterForm)
