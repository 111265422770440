import React from 'react'
import PropTypes from 'prop-types'

export class File extends React.Component {
  static propTypes = {
    label: PropTypes.node,
    url: PropTypes.string
  }

  value () {
    if (this.props.url) {
      return <a href={this.props.url} target='_blank' rel='noopener noreferrer'>File</a>
    }
    else {
      return 'No File'
    }
  }

  render () {
    return (
      <tr>
        <th>{this.props.label}</th>
        <td>{this.value()}</td>
      </tr>
    )
  }
}

export default File
