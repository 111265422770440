import { connect } from 'react-redux'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'
import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { providerSchema, jobSchema } from '@seekster/schemas'

import ProviderJobsPane from './ProviderJobsPane'

const mapStateToProps = (state, { match }) => ({
  provider: getResource(state, match.params.id, providerSchema)
})

const mapDispatchToProps = {
  fetchProviderJobs: (id, options = {}) => fetchChildren(providerSchema, id, jobSchema, {
    ...options,
    queryParam: {
      query: {
        'job_providers_state_in][': ['pending_start', 'pending_completion', 'completed', 'heading_to_destination', 'heading_to_origin'],
        job_providers_provider_id_eq: id
      },

      order: {
        start_time: 'desc'
      }
    }
  })
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(ProviderJobsPane)
