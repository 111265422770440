import React from 'react'
import { Table, Column } from 'components/tables/Table'
import { Paper } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { StateTag } from 'components/tags'
import { DateFormatter, PriceFormatter, DateRangeFormatter } from 'components/formatters'

export function PayrollsTable ({ payrolls, isSeekster }) {
  function handleTier (code) {
    return ({
      HSEEKSTERD: 'Gold',
      HSEEKSTERB: 'Silver',
      HSEEKSTERA: 'Bronze',
      null: '-'
    })[code]
  }

  return (
    <Paper>
      <Table collection={payrolls}>
        <Column
          name='payout_date'
          render={(payout_date, payroll) =>
            <Link to={`/payrolls/${payroll.get('id')}`}>
              <DateFormatter value={payout_date} full />
            </Link>
          }
        />
        <Column
          name='amount'
          render={(amount, payroll) => <p><PriceFormatter value={payroll.getIn(['total_satangs']) / 100} /></p>}
        />
        {
          isSeekster ? (
            <Column
              name='client_code'
              render={(client_code) => <>{handleTier(client_code)}</>}
            />
          ) : (
            <Column
              name='state'
              render={(state) => <StateTag round value={state} />}
            />
          )
        }
        <Column
          name='description'
          render={(description, payroll) => <DateRangeFormatter startTime={payroll.get('cycle_start_date')} endTime={payroll.get('cycle_end_date')} />}
        />
      </Table>
    </Paper>
  )
}

export default PayrollsTable
