import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, CardActions, Button, Collapse, Typography } from '@material-ui/core'
import { ExpandMore, Delete } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { getIn } from 'formik'
import classNames from 'clsx'

import styles from './FormSectionStyles'

export const FormSection = ({ title, name, children, classes, formik, onRemove }) => {
  const [expanded, setExpanded] = useState(false)
  const values = getIn(formik.values, name)

  const handleToggleExpand = () => {
    setExpanded(!expanded)
  }

  const handleRemove = (e) => {
    const value = getIn(formik.values, name)

    onRemove && onRemove(e, { value })
  }

  return (
    <Card className={classes.root}>
      <CardContent classes={{ root: classes.title }}>
        <Button
          onClick={handleToggleExpand}
          fullWidth
          classes={{
            root: classes.clickableArea,
            label: classes.titleContent
          }}
        >
          <Typography variant='h6' className={classes.titleText}>{title}</Typography>

          <ExpandMore
            className={classNames(classes.caret, {
              [classes.expanded]: expanded
            })}
          />
        </Button>
      </CardContent>

      <Collapse in={expanded} unmountOnExit>
        <CardContent>
          {children}
        </CardContent>

        <CardActions className={classes.cardActions}>
          <Button
            className={classNames(classes.removeButton, {
              [classes.activeRemoveButton]: values._destroy
            })}
            onClick={handleRemove}
          >
            <Delete className={classes.leftIcon} />
            Remove
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  )
}

FormSection.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  classes: PropTypes.object,
  formik: PropTypes.object,
  onRemove: PropTypes.func,
  title: PropTypes.string
}

export default withStyles(styles)(FormSection)
