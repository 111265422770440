import { useState } from 'react'
import { v4 as uuid4 } from 'uuid'

export function useEventId () {
  const [eventId, setEventId] = useState(null)

  function generateEventId () {
    setEventId(uuid4())
  }

  return [eventId, generateEventId]
}

export default useEventId
