import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { compose } from 'redux'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import LocaleContext from 'contexts/LocaleContext'

import ButtonLoader from 'components/buttons/ButtonLoader'
import Panel from 'components/containers/Panel'
import { Field, LocalizedField } from 'components/fields/Field'
import DropzoneInput from 'components/inputs/DropzoneInput'
import RichTextInput from 'components/inputs/RichTextInput'
import SwitchInput from 'components/inputs/SwitchInput'
import ResourceSelect from 'components/inputs/ResourceSelect'
import DateTimePicker from 'components/inputs/DateTimePicker'
import { clientSchema, regionSchema } from '@seekster/schemas'

export function AnnouncementForm ({ values, t, isSubmitting, announcement }) {
  const locale = useContext(LocaleContext)

  return (
    <Grid container justify='center' component={Form}>
      <Grid item xs={12} sm={9}>
        <Panel title={t('details')}>
          <div style={{ padding: '10px 16px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <LocalizedField
                  name='banner_attributes.landscape'
                  component={DropzoneInput}
                  width={1600}
                  height={300}
                  initialValue={announcement.getIn(['banner', `landscape_${locale}_url`])}
                  keepPreviewWhenUnmount
                />
              </Grid>

              <Grid item xs={6}>
                <LocalizedField
                  name='banner_attributes.portrait'
                  component={DropzoneInput}
                  width={800}
                  height={250}
                  initialValue={announcement.getIn(['banner', `portrait_${locale}_url`])}
                  keepPreviewWhenUnmount
                />
              </Grid>

              <Grid item xs={12}>
                <Field name='static_banner' component={SwitchInput} noLabel />
              </Grid>

              <Grid item xs={12}>
                <Field name='link' />
              </Grid>

              <Grid item xs={12}>
                <LocalizedField name='title' />
              </Grid>

              <Grid item xs={12}>
                <LocalizedField
                  name='body'
                  component={RichTextInput}
                  disabled={Boolean(values.link)}
                />
              </Grid>

              <Grid item xs={12}>
                <LocalizedField
                  name='action_text'
                  disabled={Boolean(values.link)}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name='action_link'
                  disabled={Boolean(values.link)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field name='client_ids' component={ResourceSelect} schema={clientSchema} isMulti />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field name='region_ids' component={ResourceSelect} schema={regionSchema} isMulti />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field name='start_time' component={DateTimePicker} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field name='end_time' component={DateTimePicker} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field name='position' type='tel' />
              </Grid>

              <Grid item xs={12} container justify='flex-end'>
                <ButtonLoader
                  type='submit'
                  variant='contained'
                  color='primary'
                  isLoading={isSubmitting}
                >
                  {t('save')}
                </ButtonLoader>
              </Grid>
            </Grid>
          </div>
        </Panel>
      </Grid>
    </Grid>
  )
}

AnnouncementForm.defaultProps = {
  announcement: Map()
}

AnnouncementForm.propTypes = {
  announcement: ImmutablePropTypes.map,
  values: PropTypes.object,
  isSubmitting: PropTypes.bool,
  t: PropTypes.func
}

export default compose(
  withTranslation('common')
)(AnnouncementForm)
