import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import Panel from 'components/containers/Panel'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import PayrollsIndexTable from 'components/tables/PayrollsIndexTable'

import { payrollSchema } from '@seekster/schemas'
import PageLayout from 'components/layouts/PageLayout'
import moment from 'moment'

import { useResource } from 'hooks/resources'

const useStyles = makeStyles(({ spacing, shape }) => ({
  root: {
    backgroundColor: '#f3f8fe',
    padding: spacing(0.5, 1),
    border: '1px solid #4e8ac8',
    borderRadius: shape.borderRadius,
    color: '#4e8ac8'
  },
  searchContainer: {
    padding: 24
  },

  metaContainer: {
    padding: 24
  },
  button: {
    margin: 18,
    padding: '10px 16px'
  },
  panelShadow: {
    boxShadow: '0 2px 10px 0 rgba(182,182,182,0.4)'
  }
}))

export function PayrollOverviewPane ({ match }) {
  const classes = useStyles()
  const [payroll, { loading }] = useResource(payrollSchema, match.params.id)
  const { t } = useTranslation('payrolls')

  // function actions () {
  //   return (
  //     <Button
  //       color='primary'
  //       variant='contained'
  //       className={classes.button}
  //     >
  //       EXPORT ALL CSV
  //     </Button>
  //   )
  // }

  return (
    <PageLayout
      title={`Payroll ${moment(payroll.get('payout_date')).format('D MMMM YYYY')}`}
      isLoading={loading}
      back='/payrolls'
    >
      <Grid container>
        <Grid item xs={12} sm={12} style={{ paddingRight: 0 }}>
          <div className={classes.metaContainer}>
            <div className={classes.panelShadow}>
              <Panel
                title={t('current_payroll')}
                headerStyle={{ height: 80, maxHeight: 80 }}
                // actions={actions()}
              >
                <PayrollsIndexTable payrollId={payroll.get('id')} payroll_statements={payroll.get('payroll_statements')} />
              </Panel>
            </div>
          </div>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

PayrollOverviewPane.propTypes = {
  match: PropTypes.object
}

export default PayrollOverviewPane
