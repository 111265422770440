import React from 'react'
import { useTranslation } from 'react-i18next'
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons'

import { useModal } from 'hooks/useModal'
import ProviderAssignmentWithFilterForm from 'forms/actions/ProviderAssignmentWithFilterForm'

import './AssignProvider.scss'

export default function AssignProvider({ isHDL }) {
  const { data, onConfirm } = useModal()
  const { t } = useTranslation('modal')

  return (
    <div className='assign-provider'>
      <div className='title'>{t('assign_provider.title')}</div>
      {isHDL && (
        <>
          <div className='condition'>
            <CheckCircleIcon />
            {t('assign_provider.has_service')}
          </div>
          <div className='condition'>
            <CheckCircleIcon />
            {t('assign_provider.no_black_list')}
          </div>
          <div className='condition'>
            <CheckCircleIcon />
            {t('assign_provider.only_assign_pending_provider')}
          </div>
        </>
      )}
      <ProviderAssignmentWithFilterForm
        onSubmit={onConfirm}
        jobIds={data.jobIds}
        jobStarts={data.jobStartTimes}
        showToggle={isHDL}
      />
    </div>
  )
}
