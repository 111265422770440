import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Button } from 'components/buttons'

import { Field, FastField } from 'components/fields/Field'
import DateRangeField from 'components/fields/DateRangeField'
import ResourceSelect from 'components/inputs/ResourceSelect'
import SelectInput from 'components/inputs/SelectInput'
import { serviceSchema, customerSchema, providerSchema } from '@seekster/schemas'
import arrayToOptions from 'utils/arrayToOptions'
import { JOB_STATES } from 'constants/states/jobStates'
import { REQUEST_STATES } from 'constants/states/requestStates'

export class JobsFilterForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    onCloseModal: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    this.props.handleSubmit()

    this.props.onCloseModal()
  }

  render() {
    return (
      <Form>
        <DateRangeField name='jobs_start_date' />
        <DateRangeField name='created_at' />
        <FastField
          name='state'
          component={SelectInput}
          options={arrayToOptions(JOB_STATES, {
            i18nNamespace: 'jobs',
            i18nKey: 'states'
          })}
        />
        <FastField
          name='inquiry_state'
          component={SelectInput}
          options={arrayToOptions(REQUEST_STATES, {
            i18nNamespace: 'inquiries',
            i18nKey: 'states'
          })}
        />
        <Field name='service_id' component={ResourceSelect} schema={serviceSchema} />
        <Field name='customer_id' component={ResourceSelect} schema={customerSchema} />
        <Field name='provider_id' component={ResourceSelect} schema={providerSchema} />

        <div>
          <Button color='primary' onClick={this.handleSubmit}>
            Apply
          </Button>

          <Button onClick={this.props.onCloseModal}>Cancel</Button>
        </div>
      </Form>
    )
  }
}

export default JobsFilterForm
