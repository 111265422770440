import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { connect, getIn } from 'formik'

import { FastField } from 'components/fields/Field'
import MoneyField from 'components/fields/MoneyField'
import SortableFormSection from 'components/fields/SortableFieldArray/SortableFormSection'

export const LogisticsPricingFormSection = ({ name, formik, ...props }) => {
  const values = getIn(formik.values, name)

  let title = `${values.starting_distance}km - ${values.ending_distance}km`

  if (!values.ending_distance) {
    title = `${values.starting_distance}km +`
  }

  return (
    <SortableFormSection
      title={title}
      {...props}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FastField name={`${name}.starting_distance`} type='number' />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FastField name={`${name}.ending_distance`} type='number' />
        </Grid>

        <Grid item xs={12} sm={6}>
          <MoneyField name={`${name}.price_per_km`} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <MoneyField name={`${name}.payout_per_km`} />
        </Grid>
      </Grid>
    </SortableFormSection>
  )
}

LogisticsPricingFormSection.propTypes = {
  name: PropTypes.string,
  formik: PropTypes.object
}

const ConnectedLogisticsPricingFormSection = connect(LogisticsPricingFormSection)

export default ConnectedLogisticsPricingFormSection
