import React from 'react'
import PropTypes from 'prop-types'

import './ColorTag.scss'

export class ColorTag extends React.Component {
  static propTypes = {
    value: PropTypes.node
  }

  render () {
    if (this.props.value) {
      return (
        <div className='color-tag' style={{ backgroundColor: this.props.value }} />
      )
    }
    else {
      return null
    }
  }
}

export default ColorTag
