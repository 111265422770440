export default theme => ({
  paper: {
    padding: '10px 16px',

    '&:not(:last-child)': {
      marginBottom: 10
    }
  },

  actionSection: {
    justifyContent: 'space-between',

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    }
  },

  primaryAction: {
    alignSelf: 'center',

    [theme.breakpoints.up('sm')]: {
      alignSelf: 'flex-start'
    }
  },

  secondaryAction: {
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'flex-end'
    }
  },

  voidButton: {
    fontWeight: 600,
    color: theme.palette.error.dark,

    '&:hover': {
      backgroundColor: '#ffe0e0'
    }
  }
})
