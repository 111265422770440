import { connect } from 'react-redux'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'
import { getCollection, getTotalPages } from 'selectors/collections'
import { fetch } from 'actions/collections'

import UsersListPanel from './UsersListPanel'

const mapStateToProps = (state, { schema }) => ({
  users: getCollection(state, schema),
  totalPages: getTotalPages(state, schema)
})

const mapDispatchToProps = {
  fetchUsers: (schema, options) => fetch(schema, options)
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(UsersListPanel)
