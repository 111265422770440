import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import { Table, Column } from 'components/tables/Table'
import HtmlFormatter from 'components/formatters/HtmlFormatter'

export function ServicePackagesPane ({ service, t }) {
  return (
    <Panel title={t('packages')}>
      <Table collection={service.get('packages')} padding='dense'>
        <Column name='id' />
        <Column name='name' />
        <Column name='description' component={HtmlFormatter} />
        <Column name='price' render={price => price.get('display_value')} />
        <Column name='payout' render={payout => payout.get('display_value')} />
      </Table>
    </Panel>
  )
}

ServicePackagesPane.propTypes = {
  service: ImmutablePropTypes.map,
  t: PropTypes.func
}

ServicePackagesPane.defaultProps = {
  service: Map()
}

export default withTranslation(['services', 'common'])(ServicePackagesPane)
