import { withFormik } from 'formik'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'
import RequestsFilterForm from './RequestsFilterForm'

const formikConfig = {
  mapPropsToValues: ({ query }) => query,

  enableReinitialize: true,

  handleSubmit: (values, { props }) => {
    if (values.created_by_id) {
      values.created_by_id = props.currentAgentId
    }

    props.onQueryPush(values)
  }
}

export default compose(withQueryParser, withFormik(formikConfig))(RequestsFilterForm)
