import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Grid, Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import Panel from 'components/containers/Panel'
import CircularLoader from 'components/CircularLoader'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import { Table, Column } from 'components/tables/Table'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'

export function CustomerFeedbacksPane ({ customer, fetchCustomerFeedbacks, match, query, t }) {
  const { loading, totalCount, totalPages } = useData(() => (
    fetchCustomerFeedbacks(match.params.id, { page: query.page })
  ), [query.page])

  return (
    <Panel
      title={t('title')}
      subtitle={<ResultsCountLabel value={totalCount} unit='feedback' />}
    >
      {
        loading ? (
          <CircularLoader />
        ) : (
          <Grid container spacing={3} justify='center'>
            {
              customer.get('feedbacks', List()).isEmpty() ? (
                <Typography variant='overline'>{t('no_feedback')}</Typography>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Table collection={customer.get('feedbacks')}>
                      <Column name='id' />
                      <Column
                        name='comment'
                        to={feedback => `/feedbacks/${feedback.get('id')}`}
                        render={comment => (
                          comment.length > 100 ? `${comment.slice(0, 100)}...` : comment
                        )}
                      />
                      <Column name='created_at' component={DateTimeFormatter} />
                    </Table>
                  </Grid>

                  <Grid item xs={12} container justify='center'>
                    <Pagination totalPages={totalPages} />
                  </Grid>
                </>
              )
            }
          </Grid>
        )
      }
    </Panel>
  )
}

CustomerFeedbacksPane.propTypes = {
  customer: ImmutablePropTypes.map,
  fetchCustomerFeedbacks: PropTypes.func,
  match: PropTypes.object,
  query: PropTypes.object,

  t: PropTypes.func
}

CustomerFeedbacksPane.defaultProps = {
  customer: Map()
}

export default withTranslation('feedbacks')(CustomerFeedbacksPane)
