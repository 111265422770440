import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Button } from 'components/buttons'

import FastField from 'components/fields/Field'
import DateRangeField from 'components/fields/DateRangeField'
import SelectInput from 'components/inputs/SelectInput'
import { reviewTypeSchema } from '@seekster/schemas'
import ResourceSelect from 'components/inputs/ResourceSelect'
export class ReviewsFilterForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    onCloseModal: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    this.props.handleSubmit()

    this.props.onCloseModal()
  }

  render() {
    return (
      <Form>
        <DateRangeField name='created_at' />
        <FastField
          name='rating'
          component={SelectInput}
          options={['1', '2', '3', '4', '5'].map((rating) => ({
            label: String.fromCharCode(9733).repeat(rating),
            value: rating
          }))}
        />
        <FastField
          name='review_type'
          component={ResourceSelect}
          schema={reviewTypeSchema}
        />
        <div>
          <Button color='primary' onClick={this.handleSubmit}>
            Apply
          </Button>

          <Button onClick={this.props.onCloseModal}>Cancel</Button>
        </div>
      </Form>
    )
  }
}

export default ReviewsFilterForm
