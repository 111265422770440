import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import ClientForm from 'forms/resources/ClientForm'

export function ClientEditPage ({
  client,
  t,
  push,
  match,
  loadClient,
  updateClient
}) {
  useEffect(() => {
    loadClient(match.params.id)
  }, [loadClient, match.params.id])

  function handleSubmit (data, options) {
    return updateClient(match.params.id, data, options)
  }

  function breadcrumbs () {
    return [{
      text: t('title'),
      link: '/clients'
    }, {
      text: client.get('name'),
      link: `/clients/${match.params.id}`
    }, {
      text: t('edit')
    }]
  }

  return (
    <PageLayout
      title={t('edit')}
      breadcrumbs={breadcrumbs()}
      isLoading={client.isEmpty()}
    >
      <ClientForm
        client={client}
        push={push}
        onSubmit={handleSubmit}
      />
    </PageLayout>
  )
}

ClientEditPage.propTypes = {
  client: ImmutablePropTypes.map,
  t: PropTypes.func,
  push: PropTypes.func,
  match: PropTypes.object,
  loadClient: PropTypes.func,
  updateClient: PropTypes.func
}

ClientEditPage.defaultProps = {
  client: Map()
}

export default withTranslation('clients')(ClientEditPage)
