import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'
import { pickBy, isPlainObject, values, map, has } from 'lodash'

import { withQueryParser } from 'utils/withQueryParser'

export class FilterMoneyRangeTags extends React.Component {
  static propTypes = {
    query: PropTypes.object,
    onQueryPush: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.handleRemoveTag = this.handleRemoveTag.bind(this)
  }

  handleRemoveTag (e, { queryKey }) {
    const { [queryKey]: removedValue, ...rest } = this.props.query

    this.props.onQueryPush(rest)
  }

  render () {
    const query = pickBy(
      this.props.query,
      (queryValue) => (
        isPlainObject(queryValue) &&
        !isNaN(values(queryValue)[0] || values(queryValue)[1]) &&
        has(queryValue, 'currency')
      )
    )

    return map(query, (value, key) => {
      const minValue = value.min
        ? `From ${(value.min / 100).toLocaleString(value.currency, { style: 'currency', currency: value.currency })}`
        : ''
      const maxValue = value.max
        ? `To ${(value.max / 100).toLocaleString(value.currency, { style: 'currency', currency: value.currency })}`
        : ''

      return (
        <Chip
          key={key}
          variant='outlined'
          color='primary'
          size='small'
          style={{ margin: '0 6px 6px 0' }}
          label={`Amount: ${minValue} ${maxValue}`}
          onDelete={(e) => this.handleRemoveTag(e, { queryKey: key })}
        />
      )
    })
  }
}

export default withQueryParser(FilterMoneyRangeTags)
