import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { DateTag, BooleanTag, ColorTag } from 'components/tags'
import { Table, Column } from 'components/tables/Table'
import { Paper } from '@material-ui/core'

export function ClientsTable ({ clients }) {
  return (
    <Paper>
      <Table collection={clients}>
        <Column
          name='name'
          render={(name, client) => (
            <>
              <ColorTag value={client.get('color')} />

              <Link to={`/clients/${client.get('id')}`}>
                {client.get('name')}
              </Link>
            </>
          )}
        />
        <Column name='official' component={BooleanTag} />
        <Column name='platform' />
        <Column name='access_type' />
        <Column name='opener' />
        <Column name='minimum_supported_version' />
        <Column name='latest_version' />
        <Column name='created_at' component={DateTag} />
      </Table>
    </Paper>
  )
}

ClientsTable.propTypes = {
  clients: ImmutablePropTypes.list
}

ClientsTable.defaultProps = {
  clients: List()
}

export default withTranslation('clients')(ClientsTable)
