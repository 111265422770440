export default theme => ({
  root: {
    '@media screen and (max-width: 767px)': {
      borderRadius: 0
    }
  },

  tableContainer: {
    overflowX: 'auto'
  },

  attributeHeader: {
    fontSize: '1em'
  },

  attributeValue: {
    fontSize: '1em'
  }
})
