import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader, IconButton } from '@material-ui/core'
import { DragIndicator, Delete, Undo } from '@material-ui/icons'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import { withStyles } from '@material-ui/core/styles'
import { connect, getIn } from 'formik'
import { compose } from 'redux'
import classNames from 'clsx'

import styles from './SortableFormItemStyles'

const DragHandle = SortableHandle((props) => <DragIndicator {...props} />)

export class SortableFormItem extends Component {
  static propTypes = {
    name: PropTypes.string,
    onRemove: PropTypes.func,
    children: PropTypes.node,

    classes: PropTypes.object,
    formik: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.handleRemove = this.handleRemove.bind(this)
  }

  handleRemove(e) {
    const { name, formik, onRemove } = this.props
    const value = getIn(formik.values, name)

    onRemove && onRemove(e, { value })
  }

  render() {
    const { name, children, classes, formik } = this.props

    const values = getIn(formik.values, name)

    return (
      <Card
        className={classNames(classes.root, {
          [classes.removedItem]: values._destroy
        })}
      >
        <CardHeader
          className={classes.cardHeader}
          avatar={<DragHandle className={classes.dragable} />}
          title={React.cloneElement(children, values._destroy && { disabled: true })}
          titleTypographyProps={{ variant: 'body' }}
          action={
            <IconButton
              className={!values._destroy && classes.removeButton}
              onClick={this.handleRemove}
            >
              {values._destroy ? <Undo /> : <Delete />}
            </IconButton>
          }
        />
      </Card>
    )
  }
}

export default compose(connect, withStyles(styles), SortableElement)(SortableFormItem)
