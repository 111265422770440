import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'formik'
import moment from 'moment'
import { Grid, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import WeekdaysPicker from 'components/inputs/WeekdaysPicker'
import ContinuousDatesPicker from 'components/inputs/ContinuousDatesPicker'
import TimePickerNew from 'components/inputs/TimePickerNew'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import Tag from 'components/Tag'

import styles from './SubscriptionJobsFormSectionStyles'

export function SubscriptionJobsFormSection ({
  weekdaysName,
  startDateName,
  formik,
  readOnly,
  referenceJobs,
  classes,
  t
}) {
  const weekdays = formik.values[weekdaysName] ? Object.keys(formik.values[weekdaysName]) : []
  const startDate = formik.values[startDateName] && moment(formik.values[startDateName])
  const hour = formik.values[startDateName] && moment(formik.values[startDateName]).hour()
  const minute = formik.values[startDateName] && moment(formik.values[startDateName]).minute()

  const [currentWeekdays, setCurrentWeekdays] = useState(weekdays)
  const [currentStartDate, setCurrentStartDate] = useState(startDate)
  const [currentTime, setCurrentTime] = useState({ hour, minute })

  function handleDayChange (newWeekdays) {
    setCurrentWeekdays(newWeekdays)

    handleSetValues(newWeekdays, currentStartDate, currentTime)
  }

  function handleDateChange (newStartDate) {
    setCurrentStartDate(newStartDate)

    handleSetValues(currentWeekdays, newStartDate, currentTime)
  }

  function handleTimeChange (newTime) {
    setCurrentTime(newTime)

    handleSetValues(currentWeekdays, currentStartDate, newTime)
  }

  function handleSetValues (weekdays, startDate, { hour, minute }) {
    let weekdaysValue
    let startDateValue

    if (weekdays.length !== 0 && startDate && hour) {
      weekdaysValue = {}

      weekdays.forEach(weekday => {
        weekdaysValue[weekday] = startDate.hour(hour).minute(minute).toISOString(true)
      })

      startDateValue = startDate.hour(hour).minute(minute).toISOString(true)
    }

    formik.setFieldValue(weekdaysName, weekdaysValue)
    formik.setFieldValue(startDateName, startDateValue)
  }

  return (
    <Grid container spacing={2}>
      {
        referenceJobs && (
          <Grid item xs={12}>
            <Paper className={classes.referenceJobs}>
              <label>{t('form.date_time.previous_subscription')}</label>

              <ul>
                <li>
                  <span>{t('form.date_time.started_from')} </span>
                  <b><DateTimeFormatter value={referenceJobs.startDate} /></b>
                </li>

                <li>
                  <span>{t('form.date_time.booking_week_day')} </span>
                  {
                    referenceJobs.weekdays.map(weekday => (
                      <Tag value={moment().weekday(weekday).format('dddd')} key={weekday} />
                    ))
                  }
                </li>
              </ul>
            </Paper>
          </Grid>
        )
      }

      <Grid item xs={12}>
        <WeekdaysPicker
          value={currentWeekdays}
          onChange={handleDayChange}
          isMulti
        />
      </Grid>

      <Grid item xs={12} sm={5}>
        <ContinuousDatesPicker
          startDate={currentStartDate}
          weekdays={currentWeekdays}
          onChange={handleDateChange}
          focused={Boolean(currentStartDate)}
          disabledPastDate
          readOnly={readOnly}
        />
      </Grid>

      <Grid item xs={12} sm={7}>
        <TimePickerNew
          hour={currentTime.hour}
          minute={currentTime.minute}
          onChange={handleTimeChange}
          disabled={readOnly}
        />
      </Grid>
    </Grid>
  )
}

SubscriptionJobsFormSection.propTypes = {
  weekdaysName: PropTypes.string,
  startDateName: PropTypes.string,
  formik: PropTypes.object,
  readOnly: PropTypes.bool,
  referenceJobs: PropTypes.object,
  classes: PropTypes.object,

  t: PropTypes.func
}

export default compose(
  withStyles(styles),
  withTranslation('subscriptions'),
  connect
)(SubscriptionJobsFormSection)
