import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'

import CircularLoader from 'components/CircularLoader'

import FeedbackPreview from '../FeedbackPreview'

export function FeedbackPreviewsList ({ feedbacks, fetchFeedbacks }) {
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    fetchFeedbacks({ queryParam: { per_page: 5 } })
      .then(() => setLoading(false))
  }, [fetchFeedbacks])

  return (
    isLoading ? (
      <CircularLoader containerHeight={285} />
    ) : (
      feedbacks.map(feedback => (
        <FeedbackPreview feedback={feedback} key={feedback.get('id')} />
      ))
    )
  )
}

FeedbackPreviewsList.propTypes = {
  feedbacks: ImmutablePropTypes.list,
  fetchFeedbacks: PropTypes.func
}

FeedbackPreviewsList.defaultProps = {
  feedbacks: List()
}

export default FeedbackPreviewsList
