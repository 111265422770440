import { withFormik } from 'formik'
import { compose } from 'redux'
import { Map } from 'immutable'

import withFlashMessage from 'hoc/withFlashMessage'
import SettingsTicketForm from './SettingsTicketForm'

const formikConfig = {
  mapPropsToValues: ({ ticket = Map() }) => ({
    summary: ticket.get('summary') || null
  }),
  enableReinitialize: true,

  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true)

    props.onSubmit(props.ticketId, `tenants/${props.tenant}/tickets`, values)
      .then(() => {
        setSubmitting(false)
        props.onCloseModal()
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(SettingsTicketForm)
