import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { Paper } from '@material-ui/core'
import { SelectedTable, Column } from 'components/tables/NewSelectTable'
import { DateTag } from 'components/tags'

export function ProviderOrgnaizationTable({
  providers,
  setFieldValue,
  selectedValue,
  onSelect
}) {
  return (
    <Paper>
      <SelectedTable
        selectable
        selectedValue={selectedValue}
        setFieldValue={setFieldValue}
        collection={providers}
        onSelect={onSelect}
      >
        <Column
          name='name'
          render={(_, provider) => (
            <>
              <img
                src={provider.get('avatar_url')}
                alt=''
                style={{ marginRight: '10px', height: '25px' }}
              />
              <>{provider.get('display_name')}</>
            </>
          )}
        />
        <Column name='phone_number' />
        <Column name='email' />
        <Column name='rating' />
        <Column name='created_at' component={DateTag} />
      </SelectedTable>
    </Paper>
  )
}

ProviderOrgnaizationTable.propTypes = {
  providers: ImmutablePropTypes.list,
  setFieldValue: PropTypes.func,
  selectedValue: PropTypes.array,
  onSelect: PropTypes.func
}

ProviderOrgnaizationTable.defaultProps = {
  providers: List()
}

export default ProviderOrgnaizationTable
