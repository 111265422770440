import React from 'react'
import PropTypes from 'prop-types'
import { withMobileDialog, Grid } from '@material-ui/core'

import { ConversationsListPanel, ConversationMessagesPanel } from 'modules/conversations'

import AuthenticatedRoute from 'components/AuthenticatedRoute'
import { customerSchema } from '@seekster/schemas'

export function CustomerConversationsPane ({ fullScreen: isMobile }) {
  return (
    <Grid container>
      <Grid item xs={12} sm={5}>
        <AuthenticatedRoute
          path='/customers/:id/conversations'
          component={ConversationsListPanel}
          componentProps={{
            schema: customerSchema,
            showSource: true
          }}
          exact={isMobile}
        />
      </Grid>

      <Grid item xs={12} sm={7} style={{ marginTop: isMobile && '-24px' }}>
        <AuthenticatedRoute
          path='/customers/:id/conversations/:id'
          component={ConversationMessagesPanel}
        />
      </Grid>
    </Grid>
  )
}

CustomerConversationsPane.propTypes = {
  fullScreen: PropTypes.bool
}

export default withMobileDialog()(CustomerConversationsPane)
