import { connect } from 'react-redux'

import { fetch } from 'actions/resources'
import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { customerSchema, deviceSchema } from '@seekster/schemas'

import CustomerAccountMergesPage from './CustomerAccountMergesPage'

const mapStateToProps = (state, { match }) => ({
  customer: getResource(state, match.params.id, customerSchema)
})

const mapDispatchToProps = {
  fetchCustomer: id => fetch(id, customerSchema),
  fetchCustomerDevices: id => fetchChildren(customerSchema, id, deviceSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAccountMergesPage)
