import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { customerSchema, subscriptionSchema } from '@seekster/schemas'

import CustomerSubscriptionsPane from './CustomerSubscriptionsPane'

const mapStateToProps = (state, { match }) => ({
  customer: getResource(state, match.params.id, customerSchema)
})

const mapDispatchToProps = {
  loadSubscriptions: (id, options) => fetchChildren(customerSchema, id, subscriptionSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSubscriptionsPane)
