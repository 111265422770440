import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Grid } from '@material-ui/core'
import CircularLoader from 'components/CircularLoader'

import ProvidersTable from 'components/tables/ProvidersTable'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'

export class ServiceProvidersPane extends React.Component {
  static propTypes = {
    service: ImmutablePropTypes.map,
    match: PropTypes.object,
    fetchServiceProviders: PropTypes.func,
    query: PropTypes.object
  }

  static defaultProps = {
    service: Map(),

    fetchServiceProviders: () => {}
  }

  state = {
    loading: false,
    error: null,
    totalCount: 0,
    totalPages: 1
  }

  componentDidMount() {
    this.setState({ loading: true })

    this.props
      .fetchServiceProviders(this.props.match.params.id)
      .then((response) => {
        this.setState({
          loading: false,
          totalCount: parseInt(response.headers['total'], 10),
          totalPages: Math.ceil(response.headers['total'] / response.headers['per-page'])
        })
      })
      .catch((error) => {
        this.setState({ loading: false, error: error.message })
      })
  }

  componentDidUpdate(prevProps, prevState) {
    this.props.query.page !== prevProps.query.page && this.handleUpdateData()
  }

  handleUpdateData() {
    const { page } = this.props.query

    this.setState({ loading: true })

    this.props
      .fetchServiceProviders(this.props.match.params.id, { page })
      .then(() => {
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false, error: error.message })
      })
  }

  render() {
    const { loading, totalCount, totalPages } = this.state
    const providers = this.props.service.get('providers') || List()

    return (
      <>
        <ResultsCountLabel value={totalCount} unit='provider' />

        {loading && <CircularLoader />}

        {!loading && totalCount !== 0 && (
          <>
            <div>
              <ProvidersTable providers={providers} />
            </div>

            <Grid item xs={12} container justify='center'>
              <Pagination totalPages={totalPages} />
            </Grid>
          </>
        )}
      </>
    )
  }
}

export default ServiceProvidersPane
