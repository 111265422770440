import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Map } from 'immutable'

import {
  create,
  fetch,
  update,
  destroy,
  trigger,
  triggerWithData,
  createChild,
  destroyChild,
  fetchChildren,
  fetchResourceChildWithId,
  bulkDestroy,
  bulkUpdateProvider,
  fetchBlobFile,
  fetchNode,
  createNode,
  updateNode
} from 'actions/resources'
import { getResource } from 'selectors/resources'

export const useResource = (schema, id, effectDependencies = []) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const dispatch = useDispatch()

  const resource = useSelector((state) => getResource(state, id, schema)) || Map()

  useEffect(() => {
    if (id) {
      let isMounted = true

      setLoading(true)

      dispatch(fetch(id, schema))
        .then(() => isMounted && setLoading(false))
        .catch((error) => {
          if (isMounted) {
            setError(error)

            setLoading(false)
          }
        })
      return () => (isMounted = false)
    }
  }, [...effectDependencies]) // eslint-disable-line react-hooks/exhaustive-deps

  return [resource, { loading, error }]
}

export const useNodeResource = (schema, id, effectDependencies = []) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const dispatch = useDispatch()

  const resource = useSelector((state) => getResource(state, id, schema)) || Map()

  useEffect(() => {
    if (id) {
      let isMounted = true

      setLoading(true)

      dispatch(fetchNode(id, schema))
        .then(() => isMounted && setLoading(false))
        .catch((error) => {
          if (isMounted) {
            setError(error)

            setLoading(false)
          }
        })
      return () => (isMounted = false)
    }
  }, [...effectDependencies]) // eslint-disable-line react-hooks/exhaustive-deps

  return [resource, { loading, error }]
}

export const useResourceAction = (schema) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const dispatch = useDispatch()

  const actionResource = (thunkAction) => {
    const action = (...params) => {
      setLoading(true)

      return dispatch(thunkAction(...params, schema, {}))
        .then((response) => {
          setLoading(false)

          return response
        })
        .catch((error) => {
          setLoading(false)
          setError(error)

          throw error
        })
    }

    return [action, loading, error]
  }

  const [createResource, creating, errorFromCreating] = actionResource(create)
  const [fetchResource, fetching, errorFromFetching] = actionResource(fetch)
  const [updateResource, updating, errorFromUpdating] = actionResource(update)
  const [deleteResource, deleting, errorFromDeleting] = actionResource(destroy)
  const [triggerResource, triggering, errorFromTriggering] = actionResource(trigger)
  const [
    triggerResourceWithData,
    triggeringWithData,
    errorFromTriggeringWithData
  ] = actionResource(triggerWithData)
  const [createChildResource, childCreating, errorFromChildCreating] = actionResource(
    createChild
  )
  const [
    destroyChildResource,
    childDestroying,
    errorFromChildDestroying
  ] = actionResource(destroyChild)
  const [
    fetchChildrenResource,
    fetchingChildren,
    errorFromFetchingChildren
  ] = actionResource(fetchChildren)
  const [bulkDestroyResource, bulkDestroying, errorFromBulkDestroying] = actionResource(
    bulkDestroy
  )
  const [
    bulkUpdateProviderResource,
    bulkUpdating,
    errorFromBulkUpdating
  ] = actionResource(bulkUpdateProvider)
  const [
    fetchBlobFileResource,
    fetchingBlobFile,
    errorFromFetchingBlobFile
  ] = actionResource(fetchBlobFile)

  // NODEJS
  const [nodeCreateResource, nodeCreating, nodeErrorFromCreating] = actionResource(
    createNode
  )
  const [nodeUpdateResource, nodeUpdating, nodeErrorFromUpdating] = actionResource(
    updateNode
  )

  return {
    create: createResource,
    fetch: fetchResource,
    update: updateResource,
    delete: deleteResource,
    trigger: triggerResource,
    triggerWithData: triggerResourceWithData,
    createChild: createChildResource,
    destroyChild: destroyChildResource,
    fetchChildren: fetchChildrenResource,
    bulkDestroy: bulkDestroyResource,
    bulkUpdateProvider: bulkUpdateProviderResource,
    fetchBlobFile: fetchBlobFileResource,

    creating,
    fetching,
    updating,
    deleting,
    triggering,
    triggeringWithData,
    childCreating,
    childDestroying,
    fetchingChildren,
    bulkDestroying,
    bulkUpdating,
    fetchingBlobFile,

    errorFromCreating,
    errorFromFetching,
    errorFromUpdating,
    errorFromDeleting,
    errorFromTriggering,
    errorFromTriggeringWithData,
    errorFromChildCreating,
    errorFromChildDestroying,
    errorFromFetchingChildren,
    errorFromBulkDestroying,
    errorFromBulkUpdating,
    errorFromFetchingBlobFile,

    // NODEJS
    nodeCreate: nodeCreateResource,
    nodeUpdate: nodeUpdateResource,

    nodeCreating,
    nodeUpdating,

    nodeErrorFromCreating,
    nodeErrorFromUpdating
  }
}

export const useResourceActions = (schema) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const dispatch = useDispatch()

  const actionResource = (thunkAction) => {
    const action = (data, options) => {
      setLoading(true)

      return dispatch(thunkAction({ ...data }, schema, options))
        .then((response) => {
          setLoading(false)

          return response
        })
        .catch((error) => {
          setLoading(false)
          setError(error)

          throw error
        })
    }

    return [action, loading, error]
  }

  const [createResource, creating, errorFromCreating] = actionResource(create)
  const [fetchResource, fetching, errorFromFetching] = actionResource(fetch)
  const [updateResource, updating, errorFromUpdating] = actionResource(update)
  const [deleteResource, deleting, errorFromDeleting] = actionResource(destroy)
  const [triggerResource, triggering, errorFromTriggering] = actionResource(trigger)
  const [
    triggerResourceWithData,
    triggeringWithData,
    errorFromTriggeringWithData
  ] = actionResource(triggerWithData)

  return {
    create: createResource,
    fetch: fetchResource,
    update: updateResource,
    delete: deleteResource,
    trigger: triggerResource,
    triggerWithData: triggerResourceWithData,

    creating,
    fetching,
    updating,
    deleting,
    triggering,
    triggeringWithData,

    errorFromCreating,
    errorFromFetching,
    errorFromUpdating,
    errorFromDeleting,
    errorFromTriggering,
    errorFromTriggeringWithData
  }
}

export function useResourceActionChildWithId(
  schema,
  id,
  childrenSchema,
  childId,
  otherDependencies = []
) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const dispatch = useDispatch()

  function fetch() {
    setLoading(true)

    return dispatch(
      fetchResourceChildWithId(schema, id, childrenSchema, childId, otherDependencies)
    )
      .then((response) => {
        setError(false)
        setLoading(false)

        return response
      })
      .catch((error) => {
        setError(true)
        setLoading(false)

        return error
      })
  }

  return [fetch, { loading, error }]
}
