import { red } from '@material-ui/core/colors'

export default (theme) => ({
  root: {
    '&:last-child': {
      marginBottom: theme.spacing(2)
    }
  },

  removedItem: {
    boxShadow: 'inset 0px 0px 7px 0px rgba(252,121,121,1)'
  },

  cardHeader: {
    padding: '10px 16px 0 16px'
  },

  dragable: {
    cursor: 'grab'
  },

  removeButton: {
    color: red[300],

    '&:hover': {
      color: red[800],
      backgroundColor: 'rgba(198, 40, 40, 0.08)'
    }
  }
})
