export default ({ button, spacing }) => ({
  avatar: {
    width: 120,
    height: 120,
    marginTop: spacing(1),
    marginBottom: spacing(2)
  },
  textContainer: {
    marginTop: spacing(3),
    marginBottom: spacing(2),
    display: 'flex',
    justifyContent: 'center',
    fontSize: '22px'
  },
  text: {
    whiteSpace: 'nowrap',
    fontWeight: 600,
    marginLeft: 26
  },
  subTitle: {
    float: 'left',
    '&:first-child': {
      marginTop: 24
    }
  },
  blueSquare: {
    width: 20,
    height: 20,
    margin: 5,
    background: 'blue',
    border: '1px solid rgba(0, 0, 0, .2)'
  },
  redSquare: {
    width: 20,
    height: 20,
    margin: 5,
    background: 'red',
    border: '1px solid rgba(0, 0, 0, .2)'
  },
  greenSquare: {
    width: 20,
    height: 20,
    margin: 5,
    background: 'green',
    border: '1px solid rgba(0, 0, 0, .2)'
  },
  backButton: {
    marginLeft: `-${spacing(2)}px`
  },
  mapContainer: {
    padding: 8
  },
  dateContainer: {
    padding: 8
  },
  title: {
    fontWeight: 'bolder'
  },
  button: {
    marginRight: 16
  },
  small: {
    fontSize: '18px'
  },
  confilctContainer: {
    marginTop: spacing(2),
    paddingTop: spacing(2),
    paddingLeft: spacing(1),
    borderTop: 'solid #dadada 1px'
  },
  textBorder: {
    fontWeight: 'bolder'
  }
})
