import { Map } from 'immutable'

import { SET_OPEN_MODAL, SET_CLOSE_MODAL } from 'constants/modal'

const initialState = Map({
  open: false,
  name: null,
  message: '',
  data: {},
  onConfirm: null
})

const modalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_OPEN_MODAL:
      return Map(payload)
    case SET_CLOSE_MODAL:
      return Map(initialState)
    default:
      return state
  }
}

export default modalReducer
