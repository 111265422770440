import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { Grid } from '@material-ui/core'
import { connect, getIn } from 'formik'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import SortableFormSection from 'components/fields/SortableFieldArray/SortableFormSection'
import { LocalizedField, Field } from 'components/fields/Field'
import DropzoneInput from 'components/inputs/DropzoneInput'
import LocaleContext from 'contexts/LocaleContext'

export class ServiceDetailFormSection extends Component {
  static contextType = LocaleContext

  static propTypes = {
    service: ImmutablePropTypes.map,
    name: PropTypes.string,
    index: PropTypes.number,
    formik: PropTypes.object,
    t: PropTypes.func
  }

  static defaultProps = {
    service: Map()
  }

  render () {
    const { name, formik, service, index, t } = this.props
    const locale = this.context

    const values = getIn(formik.values, name)

    return (
      <SortableFormSection title={values[`content_${locale}`]} {...this.props}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <Field
              name={`${name}.icon`}
              component={DropzoneInput}
              width={256}
              height={256}
              initialValue={service.getIn(['details', index, 'icon_url'])}
              hint={`${t('image_dimension_hint')} 256 x 256 pixels`}
            />
          </Grid>

          <Grid item xs={12} sm={9}>
            <LocalizedField name={`${name}.content`} />
          </Grid>
        </Grid>
      </SortableFormSection>
    )
  }
}

export default compose(
  connect,
  withTranslation('services')
)(ServiceDetailFormSection)
