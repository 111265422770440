import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import ButtonLoader from 'components/buttons/ButtonLoader'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import Rating from 'components/Rating'
import CircularLoader from 'components/CircularLoader'

import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import ResourceCard from 'components/cards/ResourceCard'
import Modal from 'components/containers/Modal'
import BlacklistedProviderForm from 'forms/resources/BlacklistedProviderForm'
import { DateTag, StateTag, PhoneTag } from 'components/tags'

export class CustomerBlacklistedProvidersPane extends React.Component {
  static propTypes = {
    customer: ImmutablePropTypes.map,
    match: PropTypes.object,
    fetchCustomerBlacklistedProviders: PropTypes.func,
    createCustomerBlacklistedProvider: PropTypes.func,
    destroyCustomerBlacklistedProvider: PropTypes.func,
    query: PropTypes.object,
    t: PropTypes.func
  }

  static defaultProps = {
    customer: Map(),

    fetchCustomerBlacklistedProviders: () => {},
    createCustomerBlacklistedProvider: () => {},
    destroyCustomerBlacklistedProvider: () => {}
  }

  constructor (props) {
    super(props)

    this.handleAddBlacklistedProvider = this.handleAddBlacklistedProvider.bind(this)
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)

    this.state = {
      modalOpen: false,
      loading: false,
      error: null,
      totalCount: 0,
      totalPages: 1
    }
  }

  componentDidMount () {
    this.handleUpdateData()
  }

  componentDidUpdate (prevProps, prevState) {
    this.props.query.page !== prevProps.query.page && this.handleUpdateDataOnPageChange()
  }

  handleUpdateData () {
    this.setState({ loading: true })

    this.props.fetchCustomerBlacklistedProviders(this.props.match.params.id)
      .then(response => {
        this.setState({
          loading: false,
          totalCount: parseInt(response.headers['total'], 10),
          totalPages: Math.ceil(response.headers['total'] / response.headers['per-page'])
        })
      })
      .catch(error => {
        this.setState({ loading: false, error: error.message })
      })
  }

  handleUpdateDataOnPageChange () {
    const { page } = this.props.query

    this.setState({ loading: true })

    this.props.fetchCustomerBlacklistedProviders(this.props.match.params.id, { page })
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(error => {
        this.setState({ loading: false, error: error.message })
      })
  }

  handleAddBlacklistedProvider (values) {
    return this.props.createCustomerBlacklistedProvider(values, this.props.match.params.id)
      .then(() => {
        this.handleCloseModal()

        return this.handleUpdateData()
      })
  }

  handleOpenModal () {
    this.setState({ modalOpen: true })
  }

  handleCloseModal () {
    this.setState({ modalOpen: false })
  }

  renderProviderRating (rating) {
    return (
      <div>
        <Rating value={rating} />
      </div>
    )
  }

  renderPrimarySupportContent (state, email, phoneNumber) {
    return (
      <div>
        <StateTag value={state} />
        <div>{email}</div>
        <PhoneTag value={phoneNumber} />
      </div>
    )
  }

  renderSecondarySupportContent (blacklistedAt) {
    return (
      <div>
        <div style={{ fontSize: '0.8rem', color: '#7c7c7c' }}>
          {this.props.t('blacklisted_at')}
        </div>
        <DateTag value={blacklistedAt} />
      </div>
    )
  }

  renderActionButtons (id) {
    return (
      <ButtonLoader
        color='secondary'
        variant='contained'
        onClick={() => (
          window.confirm(this.props.t('remove_confirmation_prompt')) &&
          this.props.destroyCustomerBlacklistedProvider(id)
            .then(() => this.handleUpdateData())
        )}
      >
        {this.props.t('remove')}
      </ButtonLoader>
    )
  }

  render () {
    const { loading, totalCount, totalPages } = this.state
    const blacklistedProviders = this.props.customer.get('blacklisted_providers') || List()

    return (
      <>
        <div style={{ padding: 7 }}>
          <Grid container spacing={2}>
            <Grid item xs={10} sm={11}>
              <ResultsCountLabel value={totalCount} unit='provider' />
            </Grid>

            <Grid item xs={2} sm={1}>
              <Modal
                isOpen={this.state.modalOpen}
                onModalClose={this.handleCloseModal}
                trigger={
                  <ButtonLoader
                    color='secondary'
                    variant='contained'
                    onClick={this.handleOpenModal}
                  >
                    {this.props.t('add_provider')}
                  </ButtonLoader>
                }
              >
                <BlacklistedProviderForm
                  onSubmit={this.handleAddBlacklistedProvider}
                  onCancelForm={this.handleCloseModal}
                />
              </Modal>
            </Grid>
          </Grid>
        </div>

        {
          loading && <CircularLoader />
        }

        {
          !loading && totalCount !== 0 &&
          (
            <>
              {
                blacklistedProviders.map(blacklistedProvider => {
                  const provider = blacklistedProvider.get('provider') || Map()
                  const id = blacklistedProvider.get('id')
                  const blacklistedAt = blacklistedProvider.get('created_at')
                  const rating = blacklistedProvider.getIn(['provider', 'rating'])
                  const state = blacklistedProvider.getIn(['provider', 'state'])
                  const email = blacklistedProvider.getIn(['provider', 'email'])
                  const phoneNumber = blacklistedProvider.getIn(['provider', 'phone_number'])

                  return (
                    <ResourceCard
                      key={id}
                      title={provider.get('display_name')}
                      resourceLink={`/providers/${provider.get('id')}`}
                      avatar={provider.get('avatar_url')}
                      subtitle={this.renderProviderRating(rating)}
                      primarySupportContent={this.renderPrimarySupportContent(state, email, phoneNumber)}
                      secondarySupportContent={this.renderSecondarySupportContent(blacklistedAt)}
                      actionButtons={this.renderActionButtons(id)}
                    />
                  )
                })
              }

              <Grid item xs={12} container justify='center'>
                <Pagination totalPages={totalPages} />
              </Grid>
            </>
          )
        }

      </>
    )
  }
}

export default withTranslation(['customers', 'attributes', 'common'])(CustomerBlacklistedProvidersPane)
