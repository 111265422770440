import { connect } from 'react-redux'

import {
  fetch, createChild, update, destroy,
  trigger, fetchChildWithImplicitParent
} from 'actions/resources'
import {
  invoiceSchema, discountSchema,
  tenantSchema, accountingConfigurationSchema
} from '@seekster/schemas'

import InvoiceLineItemsTable from './InvoiceLineItemsTable'

const mapDispatchToProps = {
  fetchInvoice: (id, options) => fetch(id, invoiceSchema, options),
  createDiscount: (id, data, options) => createChild(data, invoiceSchema, id, discountSchema, options),
  updateDiscount: (id, data, options) => update(id, data, discountSchema, options),
  deleteDiscount: (id, options) => destroy(id, discountSchema, options),
  fetchAccountingConfiguration: () => fetchChildWithImplicitParent(tenantSchema, accountingConfigurationSchema),
  applyWithholdingTax: (id) => trigger(id, 'apply_withholding_tax', invoiceSchema),
  removeWithholdingTax: (id) => trigger(id, 'remove_withholding_tax', invoiceSchema)
}

export default connect(null, mapDispatchToProps)(InvoiceLineItemsTable)
