import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { ExpandMore, DragIndicator, Delete } from '@material-ui/icons'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import { connect, getIn } from 'formik'
import { compose } from 'redux'
import classNames from 'clsx'
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Collapse,
  Typography
} from '@material-ui/core'

import styles from '../SortableFormSectionStyles'

const DragHandle = SortableHandle((props) => <DragIndicator {...props} />)

export function SortableFormSection({
  name,
  formik,
  onRemove,
  title,
  classes,
  children
}) {
  const [expanded, setExpaned] = useState(false)
  const values = getIn(formik.values, name)

  function handleToggleExpand() {
    setExpaned((prevExpanded) => !prevExpanded)
  }

  function handleRemove(e) {
    const value = getIn(formik.values, name)

    onRemove && onRemove(e, { value })
  }

  return (
    <Card className={classes.root}>
      <CardContent classes={{ root: classes.title }}>
        <DragHandle className={classes.dragHandler} />

        <Button
          onClick={handleToggleExpand}
          fullWidth
          classes={{
            root: classes.clickableArea,
            label: classes.titleContent
          }}
        >
          <Typography variant='h6' className={classes.titleText}>
            {title}
          </Typography>

          <ExpandMore
            className={classNames(classes.caret, {
              [classes.expanded]: expanded
            })}
          />
        </Button>
      </CardContent>

      <Collapse in={expanded} unmountOnExit>
        <CardContent>{children}</CardContent>

        <CardActions className={classes.cardActions}>
          <Button
            className={classNames(classes.removeButton, {
              [classes.activeRemoveButton]: values._destroy
            })}
            onClick={handleRemove}
          >
            <Delete className={classes.leftIcon} />
            Remove
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  )
}

SortableFormSection.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  onRemove: PropTypes.func,
  children: PropTypes.node,
  classes: PropTypes.object,
  formik: PropTypes.object
}

export default compose(connect, withStyles(styles), SortableElement)(SortableFormSection)
