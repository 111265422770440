import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, createChild } from 'actions/resources'
import { fetchChildren } from 'actions/collections'
import { setFlashMessage } from 'actions/interface'
import { getResource } from 'selectors/resources'
import { deviceSchema, accountMergeSchema } from '@seekster/schemas'

import AccountMergingPanel from './AccountMergingPanel'

const mapStateToProps = (state, { match, schema }) => ({
  sourceUser: getResource(state, match.params.id, schema)
})

const mapDispatchToProps = {
  fetchSourceUser: (id, schema) => fetch(id, schema),
  fetchSourceUserDevices: (schema, id) => fetchChildren(schema, id, deviceSchema),
  mergeAccounts: (data, schema, id) => createChild(data, schema, id, accountMergeSchema),
  push,
  setFlashMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountMergingPanel)
