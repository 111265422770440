export default ({ spacing, palette }) => ({
  customerSection: {
    marginBottom: spacing(2)
  },

  tabs: {
    justifyContent: 'flex-start',
    backgroundColor: palette.grey[200]
  },

  tab: {
    fontSize: '1.25rem',
    fontWeight: 500
  },

  customerAvatar: {
    width: 80,
    height: 80
  },

  noAvatar: {
    display: 'none'
  },

  fieldContainer: {
    padding: '10px 16px'
  },

  labelContainer: {
    paddingLeft: 16
  },

  toggleButton: {
    fontSize: 10,
    margin: 10,
    borderRadius: 25
  },

  button: {
    margin: 8
  },

  chip: {
    display: 'flex',
    justifyContent: 'flex-start',
    border: 'none'
  }
})
