import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { withRouter } from 'react-router-dom'
import { omitBy, isPlainObject, every, isNull } from 'lodash'

export const withQueryParser = WrappedComponent => {
  class WithQueryParser extends React.Component {
    static propTypes = {
      history: PropTypes.object,
      location: PropTypes.object
    }

    constructor (props) {
      super(props)

      this.handleQueryPush = this.handleQueryPush.bind(this)
    }

    handleQueryPush (query) {
      const { history, location } = this.props
      const updatedQuery = omitBy(
        query,
        (queryValue) => (
          isNull(queryValue) ||
          (isPlainObject(queryValue) && every(queryValue, isNull))
        )
      )

      history.push({
        pathname: location.pathname,
        search: qs.stringify({ ...updatedQuery, page: undefined }, { addQueryPrefix: true })
      })
    }

    render () {
      const { search } = this.props.location
      const query = qs.parse(search, { ignoreQueryPrefix: true })

      return (
        <WrappedComponent
          {...this.props}
          onQueryPush={this.handleQueryPush}
          query={query}
        />
      )
    }
  }

  return withRouter(WithQueryParser)
}

export default withQueryParser
