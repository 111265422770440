import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import {
  withMobileDialog, Table, TableBody, TableCell, TableHead, TableRow,
  Typography, Button, Dialog, DialogTitle, DialogContent,
  FormControlLabel, Checkbox
} from '@material-ui/core'
import { AddCircleOutlined } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import { getCurrentResource } from 'selectors/authentication'
import { tenantSchema } from '@seekster/schemas'

import CustomDiscountForm from 'forms/actions/CustomDiscountForm'
import Tag from 'components/Tag'
import { INVOICE_EDITABLE_STATES } from 'constants/states/invoiceEditableStates'

import styles from './InvoiceLineItemsTableStyles'

const InvoiceLineItemsTable = ({
  invoice, classes, fullScreen,
  createDiscount, updateDiscount,
  deleteDiscount, fetchInvoice,
  fetchAccountingConfiguration,
  applyWithholdingTax, removeWithholdingTax,
  t
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleOpenModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  const [discount, setDiscount] = useState(Map())

  const tenant = useSelector(state => getCurrentResource(state, tenantSchema)) || Map()
  const accountingConfiguration = tenant.get('accounting_configuration') || Map()

  const isEditableState = INVOICE_EDITABLE_STATES.includes(invoice.get('state'))

  useEffect(() => {
    fetchAccountingConfiguration()
  }, [fetchAccountingConfiguration])

  const taxRate = (accountingConfiguration.get('tax_rate') * 100).toFixed()
  const withholdingTaxRate = (accountingConfiguration.get('withholding_tax_rate') * 100).toFixed()

  const handleAddNewDiscount = () => {
    handleDeselectDiscount()

    handleOpenModal()
  }

  const checkDiscountEditibility = (discount) => {
    if (isEditableState) {
      handleSelectDiscount(discount)
    }
  }

  const handleSelectDiscount = (discount) => {
    setDiscount(discount)

    handleOpenModal()
  }

  const handleDeselectDiscount = () => {
    setDiscount(Map())
  }

  const handleSubmitDiscount = (data) => {
    handleCloseModal()

    if (!discount.isEmpty()) {
      return (
        updateDiscount(discount.get('id'), data)
          .then(() => fetchInvoice(invoice.get('id')))
          .then(handleDeselectDiscount())
      )
    }
    else {
      return (
        createDiscount(invoice.get('id'), data)
          .then(() => fetchInvoice(invoice.get('id')))
      )
    }
  }

  const handleCloseDiscountForm = () => {
    handleCloseModal()

    handleDeselectDiscount()
  }

  const handleDeleteDiscount = () => {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      handleCloseModal()

      return (
        deleteDiscount(discount.get('id'))
          .then(() => fetchInvoice(invoice.get('id')))
          .then(handleDeselectDiscount())
      )
    }
  }

  const handleToggleWithholdingTax = (event) => {
    if (event.target.checked) {
      if (window.confirm(t('withholding_tax.apply_confirmation', { rate: withholdingTaxRate }))) {
        return (
          applyWithholdingTax(invoice.get('id'))
        )
      }
    }
    else {
      if (window.confirm(t('withholding_tax.remove_confirmation', { rate: withholdingTaxRate }))) {
        return (
          removeWithholdingTax(invoice.get('id'))
        )
      }
    }
  }

  const renderLineItemRow = (lineItem, type) => {
    return (
      <TableRow
        key={lineItem.get('id')}
        className={type === 'child' ? classes.lineItemChild : ''}
      >
        <TableCell className={type === 'child' ? classes.indented : ''}>
          {lineItem.get('description')}
        </TableCell>
        <TableCell align='right'>
          <Tag value={lineItem.get('state')} className='line-item-states' />
        </TableCell>
        <TableCell align='right'>
          {lineItem.get('quantity')}
        </TableCell>
        <TableCell align='right'>
          {lineItem.getIn(['amount', 'full_display'])}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <div className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('line_items.description')}</TableCell>
            <TableCell align='right'>{t('line_items.state')}</TableCell>
            <TableCell align='right'>{t('line_items.quantity')}</TableCell>
            <TableCell align='right'>{t('line_items.price')}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {
            invoice.get('line_items', List()).map((lineItem, index) => (
              <Fragment key={index}>
                {renderLineItemRow(lineItem)}

                {
                  lineItem.get('children', List()).map(lineItemChild => (
                    renderLineItemRow(lineItemChild, 'child')
                  ))
                }
              </Fragment>
            ))
          }

          {
            invoice.get('discounts', List()).map(discount => (
              <TableRow key={discount.get('id')}>
                <TableCell>{discount.get('description')}</TableCell>
                <TableCell colSpan={2} />
                <TableCell align='right'>
                  {
                    discount.get('source') ? (
                      `- ${discount.getIn(['amount', 'full_display'])}`
                    ) : (
                      <Button
                        className={classes.editDiscountButton}
                        onClick={() => checkDiscountEditibility(discount)}
                      >
                        - {discount.getIn(['amount', 'full_display'])}
                      </Button>
                    )
                  }
                </TableCell>
              </TableRow>
            ))
          }

          <TableRow>
            {
              isEditableState && (
                <TableCell colSpan={4} variant='footer'>
                  <Button
                    size='small'
                    className={classes.newDiscountButton}
                    onClick={handleAddNewDiscount}
                  >
                    <AddCircleOutlined className={classes.addIcon} />
                    {t('discounts.add')}
                  </Button>

                  <Dialog
                    fullWidth
                    maxWidth='xs'
                    fullScreen={fullScreen}
                    open={modalOpen}
                    onClose={handleCloseModal}
                  >
                    <DialogTitle>{t('discounts.title')}</DialogTitle>
                    <DialogContent>
                      <CustomDiscountForm
                        discount={discount}
                        outstandingBalance={invoice.getIn(['outstanding_balance', 'fractional'])}
                        defaultCurrency={invoice.getIn(['total', 'currency'])}
                        onSubmit={handleSubmitDiscount}
                        onCancel={handleCloseDiscountForm}
                        onDelete={handleDeleteDiscount}
                      />
                    </DialogContent>
                  </Dialog>
                </TableCell>
              )
            }
          </TableRow>

          <TableRow>
            <TableCell rowSpan={3} colSpan={2} variant='footer' />
            <TableCell>
              <Typography>
                {t('line_items.subtotal')}
              </Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography variant='subtitle1'>
                {invoice.getIn(['subtotal', 'full_display'])}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography>
                {t('line_items.tax', { rate: taxRate })}
              </Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography variant='subtitle1'>
                {invoice.getIn(['tax', 'full_display'])}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={(isEditableState || invoice.get('apply_withholding_tax')) ? '' : classes.lastRow}>
              <Typography variant='subtitle1' className={classes.total}>
                {t('line_items.total')}
              </Typography>
            </TableCell>
            <TableCell align='right' className={(isEditableState || invoice.get('apply_withholding_tax')) ? '' : classes.lastRow}>
              <Typography variant='subtitle1' className={classes.total}>
                {invoice.getIn(['total', 'full_display'])}
              </Typography>
            </TableCell>
          </TableRow>

          {
            isEditableState &&
              <TableRow>
                <TableCell rowSpan={1} colSpan={2} variant='footer' />
                <TableCell align='center' colSpan={2} variant='footer'>
                  <FormControlLabel
                    checked={invoice.get('apply_withholding_tax')}
                    control={<Checkbox color='primary' />}
                    label={t('withholding_tax.checkbox')}
                    labelPlacement='end'
                    onChange={handleToggleWithholdingTax}
                    className={classes.withholdingTaxCheckbox}
                  />
                </TableCell>
              </TableRow>
          }

          {
            invoice.get('apply_withholding_tax') && <>
              <TableRow className={isEditableState ? classes.withholdingTaxRow : ''}>
                <TableCell rowSpan={1} colSpan={2} variant='footer' className={isEditableState ? classes.withholdingTaxCell : ''} />
                <TableCell className={isEditableState ? classes.withholdingTaxCell : ''}>
                  <Typography>
                    {t('line_items.withholding_tax', { rate: withholdingTaxRate })}
                  </Typography>
                </TableCell>
                <TableCell align='right' className={isEditableState ? classes.withholdingTaxCell : ''}>
                  <Typography variant='subtitle1' className={classes.withholdingTax}>
                    {invoice.getIn(['withholding_tax', 'full_display'])}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={1} colSpan={2} variant='footer' />
                <TableCell className={classes.lastRow}>
                  <Typography variant='subtitle1' className={classes.grandTotal}>
                    {t('line_items.grand_total')}
                  </Typography>
                </TableCell>
                <TableCell align='right' className={classes.lastRow}>
                  <Typography variant='subtitle1' className={classes.grandTotal}>
                    {invoice.getIn(['grand_total', 'full_display'])}
                  </Typography>
                </TableCell>
              </TableRow>
            </>
          }
        </TableBody>
      </Table>
    </div>
  )
}

InvoiceLineItemsTable.propTypes = {
  invoice: ImmutablePropTypes.map,
  classes: PropTypes.object,
  fullScreen: PropTypes.bool,
  createDiscount: PropTypes.func,
  updateDiscount: PropTypes.func,
  deleteDiscount: PropTypes.func,
  fetchInvoice: PropTypes.func,
  fetchAccountingConfiguration: PropTypes.func,
  applyWithholdingTax: PropTypes.func,
  removeWithholdingTax: PropTypes.func,

  t: PropTypes.func
}

InvoiceLineItemsTable.defaultProps = {
  invoice: Map(),
  accountingConfiguration: Map()
}

export default compose(
  withStyles(styles),
  withMobileDialog(),
  withTranslation('invoice')
)(InvoiceLineItemsTable)
