import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import {
  HowToReg,
  Report,
  ReportOff,
  Work,
  WorkOff,
  EditRounded,
  MergeType,
  Delete
} from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import Tag from 'components/Tag'
import StartCaseFormatter from 'components/formatters/StartCaseFormatter'

import {
  NavigationMenuBar,
  NavigationMenuItem
} from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import {
  ProviderCommentsPane,
  ProviderConversationsPane,
  ProviderDocumentsPane,
  ProviderJobsPane,
  ProviderLeadsPane,
  ProviderOverviewPane,
  ProviderReviewsPane,
  ProviderTechPane,
  ProviderTimelinePane,
  ProviderWalletPane,
  ProviderCalendar
} from './panes'

export function ProviderShowPage({
  provider,
  fetchProvider,
  fetchProviderComments,
  updateProviderState,
  deleteProvider,
  setFlashMessage,
  push,
  match,
  location,
  t
}) {
  const { loading } = useData(() => {
    return fetchProvider(match.params.id).then(() =>
      fetchProviderComments(match.params.id)
    )
  })

  function handleUpdateProviderState(event) {
    if (window.confirm(t(`${event}_confirmation_prompt`))) {
      return updateProviderState(match.params.id, event).catch((error) => {
        setFlashMessage('error', error.response.body.message)
      })
    }
  }

  function handleDeleteProvider() {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      deleteProvider(match.params.id)
        .then(() => {
          push('/providers')
        })
        .catch((error) => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  const approveAction = {
    avatar: <HowToReg fontSize='small' />,
    content: t('approve'),
    onClick: () => handleUpdateProviderState('approve')
  }

  const suspendAction = {
    avatar: <Report fontSize='small' />,
    content: t('suspend'),
    onClick: () => handleUpdateProviderState('suspend')
  }

  const unsuspendAction = {
    avatar: <ReportOff fontSize='small' />,
    content: t('unsuspend'),
    onClick: () => handleUpdateProviderState('unsuspend')
  }

  const banAction = {
    avatar: <WorkOff fontSize='small' />,
    content: t('ban'),
    onClick: () => handleUpdateProviderState('ban')
  }

  const unbanAction = {
    avatar: <Work fontSize='small' />,
    content: t('unban'),
    onClick: () => handleUpdateProviderState('unban')
  }

  const editAction = {
    avatar: <EditRounded fontSize='small' />,
    content: t('edit'),
    to: `/providers/${match.params.id}/edit`
  }

  const mergeAction = {
    avatar: <MergeType style={{ transform: 'rotate(180deg)' }} />,
    content: t('merge'),
    to: `/providers/${match.params.id}/account_merges`
  }

  const deleteAction = {
    avatar: <Delete fontSize='small' />,
    content: t('delete'),
    onClick: handleDeleteProvider
  }

  function primaryActions() {
    switch (provider.get('state')) {
      case 'registering':
      case 'pending_approval':
        return [approveAction]
      case 'approved':
        return [suspendAction]
      case 'suspened':
        return [unsuspendAction]
      default:
        return []
    }
  }

  function secondaryActions() {
    switch (provider.get('state')) {
      case 'registering':
      case 'pending_approval':
      case 'approved':
      case 'suspened':
        return [editAction, banAction, mergeAction, deleteAction]
      case 'banned':
        return [editAction, unbanAction, mergeAction, deleteAction]
      default:
        return [editAction, mergeAction, deleteAction]
    }
  }

  function renderSubNavbar() {
    const path = `/providers/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('common:navbar.overview')} to={path} replace />
        <NavigationMenuItem label={t('documents')} to={`${path}/documents`} replace />
        <NavigationMenuItem label={t('jobs')} to={`${path}/jobs`} replace />
        <NavigationMenuItem label={t('leads')} to={`${path}/leads`} replace />
        <NavigationMenuItem
          label={t('calendar')}
          to={`${path}/provider_rest_dates`}
          replace
        />
        <NavigationMenuItem label={t('reviews')} to={`${path}/reviews`} replace />
        <NavigationMenuItem label={t('wallet')} to={`${path}/wallet`} replace />
        <NavigationMenuItem
          label={t('conversations')}
          to={`${path}/conversations`}
          replace
        />
        <NavigationMenuItem label={t('tech')} to={`${path}/tech`} replace />
        <NavigationMenuItem
          label={t('comments')}
          to={`${path}/comments`}
          badge={provider.get('comments', 0).size}
          replace
        />
        <NavigationMenuItem label={t('timeline')} to={`${path}/timeline`} replace />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs() {
    return [
      { text: t('title'), link: '/providers' },
      { text: provider.get('display_name') }
    ]
  }

  return (
    <PageLayout
      title={provider.get('display_name')}
      subtitle={<Tag value={provider.get('state')} formatter={StartCaseFormatter} />}
      primaryActions={primaryActions()}
      actions={secondaryActions()}
      subNavbar={renderSubNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute
          path='/providers/:id'
          component={ProviderOverviewPane}
          exact
        />
        <AuthenticatedRoute
          path='/providers/:id/documents'
          component={ProviderDocumentsPane}
        />
        <AuthenticatedRoute path='/providers/:id/jobs' component={ProviderJobsPane} />
        <AuthenticatedRoute path='/providers/:id/leads' component={ProviderLeadsPane} />
        <AuthenticatedRoute
          path='/providers/:id/reviews'
          component={ProviderReviewsPane}
        />
        <AuthenticatedRoute path='/providers/:id/wallet' component={ProviderWalletPane} />
        <AuthenticatedRoute
          path='/providers/:id/conversations'
          component={ProviderConversationsPane}
        />
        <AuthenticatedRoute path='/providers/:id/tech' component={ProviderTechPane} />
        <AuthenticatedRoute
          path='/providers/:id/comments'
          component={ProviderCommentsPane}
        />
        <AuthenticatedRoute
          path='/providers/:id/timeline'
          component={ProviderTimelinePane}
        />
        <AuthenticatedRoute
          path='/providers/:id/provider_rest_dates'
          component={ProviderCalendar}
        />
      </Switch>
    </PageLayout>
  )
}

ProviderShowPage.ropTypes = {
  provider: ImmutablePropTypes.map,
  fetchProvider: PropTypes.func,
  fetchProviderComments: PropTypes.func,
  updateProviderState: PropTypes.func,
  deleteProvider: PropTypes.func,
  setFlashMessage: PropTypes.func,
  push: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,

  t: PropTypes.func
}

ProviderShowPage.defaultProps = {
  provider: Map()
}

export default withTranslation(['providers', 'actions', 'common'])(ProviderShowPage)
