export default ({ palette }) => ({
  input: {
    margin: 0
  },

  disabled: {
    color: `${palette.secondary.main} !important`
  },

  bar: {
    backgroundColor: `${palette.secondary.main} !important`,
    opacity: '0.5 !important'
  }
})
