export default (theme) => ({
  root: {
    '@media screen and (max-width: 767px)': {
      borderRadius: 0
    }
  },
  attributeHeader: {
    fontSize: '1em'
  },
  attributeValue: {
    fontSize: '1em',
    padding: '0px 8px'
  },
  deleteItem: {
    color: '#565F6A',
    fontSize: '1rem',
    paddingBottom: 6,
    marginBottom: 6,
    '&:not(:last-child)': {
      borderBottom: '1px solid #ddd'
    }
  }
})
