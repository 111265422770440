import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Button } from 'components/buttons'
import { withTranslation } from 'react-i18next'

import FastField from 'components/fields/Field'
import DateRangeField from 'components/fields/DateRangeField'
import MoneyRangeField from 'components/fields/MoneyRangeField'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'
import { INVOICE_STATES } from 'constants/states/invoiceStates'

export class InvoicesFilterForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    onCloseModal: PropTypes.func,
    t: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    this.props.handleSubmit()

    this.props.onCloseModal()
  }

  render() {
    return (
      <Form>
        <DateRangeField name='created_at' />
        <MoneyRangeField name='subtotal_satangs' defaultCurrency='THB' />
        <FastField
          name='state'
          component={SelectInput}
          options={arrayToOptions(INVOICE_STATES, {
            i18nNamespace: 'invoice',
            i18nKey: 'states'
          })}
        />

        <div>
          <Button color='primary' onClick={this.handleSubmit}>
            {this.props.t('apply')}
          </Button>

          <Button onClick={this.props.onCloseModal}>{this.props.t('cancel')}</Button>
        </div>
      </Form>
    )
  }
}

export default withTranslation('filters')(InvoicesFilterForm)
