import { connect } from 'react-redux'

import { fetch, destroy, fetchChildren } from 'actions/resources'
import { trigger } from 'actions/resourceEvent'
import { fetchChildren as fetchChildrenCollection } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { invoiceSchema, customerSchema, commentSchema, walletSchema } from '@seekster/schemas'

import InvoiceShowPage from './InvoiceShowPage'

const mapStateToProps = (state, { match }) => ({
  invoice: getResource(state, match.params.id, invoiceSchema)
})

const mapDispatchToProps = {
  fetchComments: (id, options) => fetchChildrenCollection(invoiceSchema, id, commentSchema, options),
  fetchInvoice: (id, options) => fetch(id, invoiceSchema, options),
  fetchWallet: (id, options) => fetchChildren(customerSchema, id, walletSchema, options),
  expectInvoice: (id, options) => trigger(id, invoiceSchema, 'expect', options),
  destroy: (id, options) => destroy(id, invoiceSchema, options)
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceShowPage)
