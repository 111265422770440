import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { Phone, EmailOutlined } from '@material-ui/icons'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import Panel from 'components/containers/Panel'
import CircularLoader from 'components/CircularLoader'
import Search from 'components/Search'
import Pagination from 'components/Pagination'
import Item from 'components/Item'
import { PhoneNumberFormatter } from 'components/formatters'

import styles from './UsersListPanelStyles'

export function UsersListPanel ({
  schema,
  users,
  fetchUsers,
  totalPages,
  location,
  match,
  query,
  classes,
  t
}) {
  const { page, ...rest } = query
  const { loading } = useData(() => (
    fetchUsers(schema, { page, query: rest })
  ), [page, query.search])

  return (
    <Panel
      title={t(`${schema._key}.title`)}
      subtitle={<Search />}
      footer={<Pagination totalPages={totalPages} />}
      subtitleTypographyProps={{ variant: 'body' }}
      cardFooterProps={{ style: { justifyContent: 'center' } }}
      noPadding
      responsive
    >
      {
        loading ? (
          <CircularLoader />
        ) : (
          users
            .filter(user => user.get('id') !== parseInt(match.params.id, 10))
            .map(resource => (
              <Item
                name={resource.get('display_name')}
                avatar={resource.get('avatar_url')}
                lazyLoad
                key={resource.get('id')}
                button
                to={{
                  pathname: `${match.url}/${resource.get('id')}`,
                  search: location.search
                }}
                selected={location.pathname === `${match.url}/${resource.get('id')}`}
                disableGutters={false}
              >
                <Phone className={classes.phoneIcon} />
                <PhoneNumberFormatter value={resource.get('phone_number')} />

                <EmailOutlined className={classes.emailIcon} />
                {resource.get('email') || '-'}
              </Item>
            ))
        )
      }
    </Panel>
  )
}

UsersListPanel.propTypes = {
  schema: PropTypes.object,
  users: ImmutablePropTypes.list,
  fetchUsers: PropTypes.func,
  totalPages: PropTypes.number,
  location: PropTypes.object,
  match: PropTypes.object,
  query: PropTypes.object,
  classes: PropTypes.object,

  t: PropTypes.func
}

UsersListPanel.defaultProps = {
  users: List()
}

export default compose(
  withStyles(styles),
  withTranslation('users')
)(UsersListPanel)
