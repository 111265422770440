import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'

import Comments from 'modules/comments/Comments'

export function InvoiceCommentsPane ({
  invoice,
  match,
  fetchInvoiceComments,
  createInvoiceComment
}) {
  return (
    <Comments
      id={match.params.id}
      loadComments={fetchInvoiceComments}
      create={createInvoiceComment}
      comments={invoice.get('comments') || List()}
    />
  )
}

InvoiceCommentsPane.propTypes = {
  invoice: ImmutablePropTypes.map,
  match: PropTypes.object,
  fetchInvoiceComments: PropTypes.func,
  createInvoiceComment: PropTypes.func
}

InvoiceCommentsPane.defaultProps = {
  invoice: Map()
}

export default InvoiceCommentsPane
