import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router/immutable'
import { v4 as uuid4 } from 'uuid'
import WebFont from 'webfontloader'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { integrationInstanceSchema, integrationSchema } from '@seekster/schemas'
import 'react-dates/initialize'

import { permissionToReceiveNotification } from 'utils/firebase'
import theme from 'utils/theme'
import AlertModal from 'components/AlertModal'
import { fetchResourceChild } from 'actions/resources'
import { fetchCurrentDevice, updateCurrentDevice } from 'actions/authentication'
import { loadAppConfigs } from 'actions/configurations'

import Router from './Router'
import createStore, { history } from './createStore'
import './i18n'
import './App.scss'

export const store = createStore()

if (!window.localStorage.getItem('uuid')) {
  window.localStorage.setItem('uuid', uuid4())
}

function App() {
  const [enabledSupportPlatform, setEnableSupportPlatform] = useState(false)

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Roboto:300,400,500,700']
      }
    })

    if (store.getState().getIn(['authentication', 'token'])) {
      store.dispatch(fetchCurrentDevice())

      permissionToReceiveNotification()
        .then((fcmToken) => {
          if (window.localStorage.getItem('FCM_token') !== fcmToken) {
            store.dispatch(updateCurrentDevice({ registration_token: fcmToken }))
          }
        })
        .catch((error) => console.warn(error))
    }
  }, [])

  useEffect(() => {
    store.dispatch(loadAppConfigs())
    store
      .dispatch(
        fetchResourceChild(
          integrationSchema,
          'support-platform',
          integrationInstanceSchema
        )
      )
      .then((result) => {
        if (result) {
          setEnableSupportPlatform(true)
        }
      })
      .catch(() => {
        console.warn('Support Platform disabled')
      })
  }, [])

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />

      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Router isSupprtPlatform={enabledSupportPlatform} />
        </ConnectedRouter>
        <AlertModal />
      </Provider>
    </MuiThemeProvider>
  )
}

export default hot(App)
