import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ mixins }) => ({
  drawerPaper: {
    width: 250,
    flexShrink: 0
  },

  logo: {
    display: 'block',
    height: '70px',
    padding: '10px 16px 0'
  },

  toolbar: mixins.toolbar
}))
