import { Map } from 'immutable'
import { withFormik } from 'formik'
import PopularServiceForm from './PopularServiceForm'

const formikConfig = {
  mapPropsToValues: ({ popularService = Map(), index }) => ({
    id: popularService.get('id'),
    name: popularService.get('name'),
    position: popularService.get('position'),
    index: index
  }),

  enableReinitialize: true,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values)
      .then(() => {
        alert('Success')
        setSubmitting(false)
      })
      .catch(error => {
        if (error.response) {
          alert(error.response.body.message)
        }
        else if (error.message) {
          alert(error.message)
        }

        setSubmitting(false)
      })
  }
}

export default withFormik(formikConfig)(PopularServiceForm)
