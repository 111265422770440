import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import { useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'
import { feedbackSchema } from '@seekster/schemas'

import PageLayout from 'components/layouts/PageLayout'
import { Panel } from 'components/containers'
import FeedbackForm from 'forms/resources/FeedbackForm'

export function FeedbackNewPage ({ t }) {
  const { create: createFeedback } = useResourceAction(feedbackSchema)
  const push = usePush()

  return (
    <PageLayout title={t('new')} back='/feedbacks'>
      <Grid container justify='center'>
        <Grid item xs={12} sm={8}>
          <Panel>
            <FeedbackForm
              onSubmit={createFeedback}
              push={push}
            />
          </Panel>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

FeedbackNewPage.propTypes = {
  t: PropTypes.func
}

export default withTranslation('feedbacks')(FeedbackNewPage)
