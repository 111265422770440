import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { Grid, Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import ButtonLoader from 'components/buttons/ButtonLoader'
import { usePush } from 'hooks/utils'
import { useTranslation } from 'react-i18next'
import { getResource } from 'selectors/resources'
import { organizationSchema } from '@seekster/schemas'
import Panel from 'components/containers/Panel'
import { DateTag } from 'components/tags'
import BooleanChip from 'components/BooleanChip'
import { Map } from 'immutable'

import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'

const useStyles = makeStyles(() => ({
  organizationPage: {
    padding: 30
  },
  tableContainer: {
    border: '1px solid #e0e0e0'
  },
  table: {
    borderRight: '1px solid #e0e0e0'
  },
  button: {
    paddingTop: 16
  },
  attributeName: {
    width: '55%'
  },

  attributeValue: {
    width: '45%'
  },

  tableValue: {
    borderLeft: '1px solid #e0e0e0'
  },

  approveStatus: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const OrganizationOverviewPane = (props) => {
  const classes = useStyles()
  const push = usePush()
  const { t } = useTranslation(['organizations', 'attributes'])
  const organization =
    useSelector((state) =>
      getResource(state, props.match.params.id, organizationSchema)
    ) || Map()
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    setIsCopied(true)
  }

  return (
    <div className={classes.organizationPage}>
      <Grid container className={classes.tableContainer}>
        <Grid item sm={6}>
          <AttributesTable resource={organization} className={classes.table}>
            <Attribute name='number' />
            <Attribute
              name='accessKey'
              render={(value) => (
                <>
                  <span>{value}</span>
                  <b
                    style={{ marginLeft: '6px', cursor: 'pointer' }}
                    onClick={() => copyToClipboard(value)}
                  >
                    {isCopied ? t('copied') : t('copy')}
                  </b>
                </>
              )}
            />
            <Attribute name='email' />
            <Attribute name='information' />
            <Attribute
              name='created_at'
              render={(_, job) => <DateTag value={job.get('createdAt')} />}
            />
            <Attribute
              name='isRequireAdminApprove'
              render={(isRequireAdminApprove) => (
                <div className={classes.approveStatus}>
                  <BooleanChip value={isRequireAdminApprove} />
                  {isRequireAdminApprove
                    ? t('permissions_approvals')
                    : t('no_permissions_approvals')}
                </div>
              )}
            />
          </AttributesTable>
          <AttributesTable resource={organization} className={classes.table}>
            <Attribute
              name='isRequireOrgApprove'
              render={(isRequireOrgApprove) => (
                <div className={classes.approveStatus}>
                  <BooleanChip value={isRequireOrgApprove} />
                  {isRequireOrgApprove
                    ? t('permissions_approvals')
                    : t('no_permissions_approvals')}
                </div>
              )}
            />
          </AttributesTable>
        </Grid>

        <Grid item sm={6}>
          <AttributesTable resource={organization}>
            <Attribute name='name' />
            <Attribute name='phoneNumber' />
            <Attribute
              name='updated_at'
              render={(_, job) => <DateTag value={job.get('updatedAt')} />}
            />
          </AttributesTable>
        </Grid>

        <Grid item sm={12}>
          <Panel title={t('contacts')}>
            <Grid container className={classes.tableContainer}>
              <Grid item sm={6}>
                {organization.get('administrators').size > 0
                  ? organization.get('administrators').map((admin, index) => (
                      <>
                        {index % 2 === 0 && (
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell className={classes.attributeName}>
                                  {admin.get('fullName')}
                                </TableCell>

                                <TableCell className={classes.attributeValue}>
                                  {t('contact')}: {admin.get('phoneNumber')}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        )}
                      </>
                    ))
                  : 'empty'}
              </Grid>

              <Grid item sm={6} className={classes.tableValue}>
                {organization.get('administrators') &&
                  organization.get('administrators').size > 1 &&
                  organization.get('administrators').map((admin, index) => (
                    <>
                      {index % 2 === 1 && (
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classes.attributeName}>
                                {admin.get('fullName')}
                              </TableCell>

                              <TableCell className={classes.attributeValue}>
                                {t('contact')}: {admin.get('phoneNumber')}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                    </>
                  ))}
              </Grid>
            </Grid>
          </Panel>
        </Grid>

        <Grid item sm={12}>
          <Panel title={t('providers')}>
            <Grid container className={classes.tableContainer}>
              <Grid item sm={6}>
                {organization.get('providers') && organization.get('providers').size > 0
                  ? organization.get('providers').map((admin, index) => (
                      <>
                        {index % 2 === 0 && (
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell className={classes.attributeName}>
                                  {admin.get('fullName')}
                                </TableCell>

                                <TableCell className={classes.attributeValue}>
                                  {t('contact')}: {admin.get('phoneNumber')}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        )}
                      </>
                    ))
                  : 'empty'}
              </Grid>

              <Grid item sm={6} className={classes.tableValue}>
                {organization.get('providers') &&
                  organization.get('providers').size > 1 &&
                  organization.get('providers').map((admin, index) => (
                    <>
                      {index % 2 === 1 && (
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classes.attributeName}>
                                {admin.get('fullName')}
                              </TableCell>

                              <TableCell className={classes.attributeValue}>
                                {t('contact')}: {admin.get('phoneNumber')}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                    </>
                  ))}
              </Grid>
            </Grid>
          </Panel>
        </Grid>
      </Grid>

      <Grid container justify='flex-end' className={classes.button}>
        <ButtonLoader
          onClick={() => push(`/organizations/${props.match.params.id}/edit`)}
          type='submit'
          variant='contained'
          color='primary'
        >
          {t('edit')}
        </ButtonLoader>
      </Grid>
    </div>
  )
}

export default OrganizationOverviewPane
