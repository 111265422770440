import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  root: {
    gridArea: ({ position }) => `page-${position}-content`,
    borderLeft: ({ borderLeft }) => {
      if (borderLeft) {
        if (typeof borderLeft === 'boolean') {
          return '1px solid #ddd'
        }
        else {
          return borderLeft
        }
      }

      return 'none'
    },
    borderRight: ({ borderRight }) => {
      if (borderRight) {
        if (typeof borderRight === 'boolean') {
          return '1px solid #ddd'
        }
        else {
          return borderRight
        }
      }

      return 'none'
    }
  }
}))
