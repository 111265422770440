import { withFormik } from 'formik'

import UserPropertyForm from './UserPropertyForm'

const formikConfig = {
  mapPropsToValues: ({ userProperty, category }) => ({
    property_definition_attributes: {
      id: userProperty ? userProperty.propertyDefinition.id : undefined,
      name: userProperty ? userProperty.propertyDefinition.name : '',
      category
    },
    value: userProperty ? userProperty.property.value : ''
  }),

  enableReinitialize: true,

  handleSubmit: (values, { props, resetForm, setErrors }) => {
    const { userProperty, userId, userSchema, createProperty, updateProperty, deleteProperty, onUpdateData } = props

    let request = Promise.resolve(false)

    if (userProperty && !userProperty.property.id && values.value !== userProperty.property.value) {
      request = createProperty(
        { value: values.value, property_definition_id: userProperty.propertyDefinition.id },
        userId,
        userSchema
      )
    }
    else if (userProperty && userProperty.property.id && values.value !== '') {
      request = updateProperty(userProperty.property.id, values)
    }
    else if (userProperty && values.value === '') {
      request = deleteProperty(userProperty.property.id)
    }
    else if (values.property_definition_attributes.name && values.value) {
      request = createProperty(values, userId, userSchema)
        .then(resetForm())
    }

    request
      .then(response => response && onUpdateData())
      .catch(error => setErrors({
        property_definition_attributes: { name: error.response.body.message },
        value: error.response.body.message
      }))
  }
}

export default withFormik(formikConfig)(UserPropertyForm)
