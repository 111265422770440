import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { TableRow, TableCell } from '@material-ui/core'

import ResourceContext from './ResourceContext'

import useStyles from './AttributesStyles'

export function Attribute({ name, label, component: Component, to, render }) {
  const classes = useStyles()
  const { t } = useTranslation('attributes')

  function renderValue(resource) {
    const value = resource.getIn(name.split('.'))

    let returnValue = value

    if (Component) {
      returnValue = <Component value={value} />
    } else if (render) {
      returnValue = render(value, resource)
    }

    if (to) {
      return <Link to={to(resource)}>{returnValue}</Link>
    } else {
      return returnValue
    }
  }

  return (
    <ResourceContext.Consumer>
      {(resource) => (
        <TableRow>
          <TableCell className={classes.attributeName}>
            <b>{label || t(name)}</b>
          </TableCell>

          <TableCell className={classes.attributeValue}>
            {renderValue(resource)}
          </TableCell>
        </TableRow>
      )}
    </ResourceContext.Consumer>
  )
}

Attribute.propTypes = {
  name: PropTypes.string,
  label: PropTypes.node,
  component: PropTypes.func,
  render: PropTypes.func,
  to: PropTypes.func
}

export default Attribute
