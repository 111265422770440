import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import ButtonLoader from 'components/buttons/ButtonLoader'
import DropzoneInput from 'components/inputs/DropzoneInput'
import CategoryServiceFormItem from 'modules/categories/CategoryServiceFormItem'
import SortableFieldArray from 'components/fields/SortableFieldArray'
import ResourceSelect from 'components/inputs/ResourceSelect'
import { regionSchema } from '@seekster/schemas'

import { LocalizedField, Field } from 'components/fields/Field'
import { Tabs, TabPane } from 'components/Tabs'

import styles from './CategoryFormStyles'

export function CategoryForm ({ classes, category, isSubmitting, t, services, fetchServices, values }) {
  const serviceIds = values.category_services_attributes && values.category_services_attributes.map(c => c.service_id)

  useEffect(() => {
    if (serviceIds) {
      fetchServices({
        query: { region_id: values.region_id },
        queryParam: {
          query: {
            'id_in][': serviceIds
          }
        }
      })
    }
    else {
      fetchServices({ query: { region_id: values.region_id } })
    }
  }, [values.region_id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container justify='center' component={Form}>
      <Grid item xs={12} sm={9}>
        <Tabs
          contained
          scrollButtons='off'
          classes={{ flexContainer: classes.tabs }}
        >
          <TabPane label='Categories Details'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <LocalizedField name='name' />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name='region_id'
                  component={ResourceSelect}
                  schema={regionSchema}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name='banner'
                  component={DropzoneInput}
                  width={1140}
                  height={300}
                  initialValue={category.get('banner_url')}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name='icon'
                  component={DropzoneInput}
                  width={256}
                  height={256}
                  initialValue={category.get('icon_url')}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name='thumbnail'
                  component={DropzoneInput}
                  width={256}
                  height={256}
                  initialValue={category.getIn('thumbnail_url')}
                />
              </Grid>
            </Grid>
          </TabPane>

          <TabPane label='Services'>
            <SortableFieldArray
              name='category_services_attributes'
              component={CategoryServiceFormItem}
              services={services}
              noLabel
            />
          </TabPane>
        </Tabs>

        <ButtonLoader
          type='submit'
          variant='contained'
          color='primary'
          isLoading={isSubmitting}
          className={classes.saveButton}
          style={{ marginTop: '1rem' }}
        >
          {t('save')}
        </ButtonLoader>
      </Grid>
    </Grid>
  )
}

CategoryForm.propTypes = {
  category: ImmutablePropTypes.map,
  services: ImmutablePropTypes.list,
  classes: PropTypes.object,
  isSubmitting: PropTypes.bool,
  fetchServices: PropTypes.func,
  values: PropTypes.object,

  t: PropTypes.func
}

CategoryForm.defaultProps = {
  category: Map()
}

export default compose(withStyles(styles), withTranslation())(CategoryForm)
