import { withFormik } from 'formik'
import { Map } from 'immutable'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'
import JobRescheduleForm from './JobRescheduleForm'

const formikConfig = {
  mapPropsToValues: ({ job = Map() }) => ({
    start_time: job.get('start_time')
  })
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(JobRescheduleForm)
