import React from 'react'
import PropTypes from 'prop-types'
import { FilterAssociationTags } from '../../FilterAssociationTags'
import { useResource } from 'hooks/resources'
import { providerSchema } from '@seekster/schemas'
import { withQueryParser } from 'utils/withQueryParser'

export function FilterProviderTags ({ query, onQueryPush }) {
  const [provider] = useResource(providerSchema, query.provider_id && query.provider_id)

  return query.provider_id ? <FilterAssociationTags
    content={`provider: ${provider.get('display_name')}`}
    name='provider_id'
    query={query}
    onQueryPush={onQueryPush}
  />
    : null
}

FilterProviderTags.propTypes = {
  query: PropTypes.object,
  onQueryPush: PropTypes.func
}

export default (withQueryParser)(FilterProviderTags)
