export default theme => ({
  button: {
    float: 'right',
    margin: '14px 0 0 14px'
  },
  sufficientBalance: {
    color: 'green'
  },
  insufficientBalance: {
    color: theme.palette.error.main
  },
  divider: {
    margin: '14px 0'
  }
})
