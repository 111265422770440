import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Link } from 'react-router-dom'
import {
  Avatar, IconButton, Paper, Grid,
  Typography, withMobileDialog
} from '@material-ui/core'
import {
  ArrowBackIosRounded, MergeTypeRounded, Phone,
  EmailOutlined, Devices
} from '@material-ui/icons'
import classNames from 'clsx'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import Panel from 'components/containers/Panel'
import CircularLoader from 'components/CircularLoader'
import ButtonLoader from 'components/buttons/ButtonLoader'
import { PhoneNumberFormatter, DateTimeFormatter } from 'components/formatters'

import styles from './AccountMergingPanelStyles'

export function AccountMergingPanel ({
  schema,
  targetUser,
  sourceUser,
  fetchSourceUser,
  fetchSourceUserDevices,
  mergeAccounts,
  setFlashMessage,
  push,
  match,
  withoutBackButton,
  classes,
  fullScreen: isMobile,
  t
}) {
  const { loading } = useData(() => {
    return fetchSourceUser(match.params.id, schema)
      .then(() => fetchSourceUserDevices(schema, match.params.id))
  }, [match.params.id])

  const [isMerging, setMerging] = useState(false)

  function handleMergeAccounts () {
    setMerging(true)

    return mergeAccounts({ source_id: match.params.id }, schema, targetUser.get('id'))
      .then(response => {
        setMerging(false)

        return response
      })
      .then(({ body }) => push(`/${body.type}/${body.id}`))
      .catch(error => {
        setMerging(false)

        return setFlashMessage('error', error.response.body.message)
      })
  }

  function renderUserCard (user) {
    const latestUsedDevice = user.get('devices', List()).maxBy(device => device.get('last_used_at')) || Map()

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm='auto'>
          <Avatar
            src={user.get('avatar_url')}
            className={classNames(classes.userAvatar, {
              [classes.userAvatarMobile]: isMobile
            })}
          />
        </Grid>

        <Grid item xs={12} sm>
          <Typography variant='h5' gutterBottom>
            {user.get('display_name')}
          </Typography>

          <div>
            <Phone className={classes.phoneIcon} />
            <PhoneNumberFormatter value={user.get('phone_number')} />
          </div>

          <div>
            <EmailOutlined className={classes.emailIcon} />
            {user.get('email') || '-'}
          </div>

          <div>
            <Devices className={classes.devicesIcon} />
            <Typography inline>
              {t('device.last_used_at')}
              <DateTimeFormatter
                value={latestUsedDevice.get('last_used_at')}
                simple
              />

              {t('device.via')}
              {latestUsedDevice.getIn(['client', 'name'])}

              {t('device.on')} {latestUsedDevice.get('brand')} {latestUsedDevice.get('model')}
            </Typography>
          </div>

        </Grid>
      </Grid>
    )
  }

  return (
    <Panel
      title={t('title')}
      responsive
      avatar={!withoutBackButton && isMobile && (
        <IconButton
          component={Link}
          to={`/${targetUser.get('type')}/${targetUser.get('id')}/account_merges`}
          className={classes.backButton}
        >
          <ArrowBackIosRounded />
        </IconButton>
      )}
      footer={
        <ButtonLoader
          type='button'
          className={classes.button}
          onClick={handleMergeAccounts}
          isLoading={isMerging}
        >
          {t('merge')}
        </ButtonLoader>
      }
      cardFooterProps={{ style: { justifyContent: 'flex-end' } }}
    >
      <Paper className={classes.userCard}>
        {
          loading ? (
            <CircularLoader />
          ) : (
            renderUserCard(sourceUser)
          )
        }
      </Paper>

      <MergeTypeRounded className={classes.mergeIcon} fontSize='large' color='action' />

      <Paper className={classes.userCard}>
        {renderUserCard(targetUser)}
      </Paper>
    </Panel>
  )
}

AccountMergingPanel.propTypes = {
  schema: PropTypes.object,
  targetUser: ImmutablePropTypes.map,
  sourceUser: ImmutablePropTypes.map,
  fetchSourceUser: PropTypes.func,
  fetchSourceUserDevices: PropTypes.func,
  mergeAccounts: PropTypes.func,
  setFlashMessage: PropTypes.func,
  push: PropTypes.func,
  match: PropTypes.object,
  withoutBackButton: PropTypes.bool,
  classes: PropTypes.object,
  fullScreen: PropTypes.bool,

  t: PropTypes.func
}

AccountMergingPanel.defaultProps = {
  targetUser: Map(),
  sourceUser: Map()
}

export default compose(
  withStyles(styles),
  withMobileDialog(),
  withTranslation('account_merges')
)(AccountMergingPanel)
