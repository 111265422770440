import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ListSubheader, Typography, IconButton, Badge } from '@material-ui/core'
import { Notifications, ClearRounded } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ResponsiveMenu, ResponsiveMenuItem } from 'components/menus/ResponsiveMenu'
import { useCollection } from 'hooks/collections'
import { notificationRecipientSchema } from '@seekster/schemas'
import { trigger } from 'actions/resourceEvent'
import { firebase, workforceFirebase } from 'utils/firebase'

import NotificationItem from '../NotificationItem'

import styles from './NotificationsCenterStyles'

export function NotificationsCenter ({ classes }) {
  const [payload, setPayload] = useState(null)
  const [unreadCount, setUnreadCount] = useState(0)
  const { t } = useTranslation('actions')
  const [notificationRecipients, { response }] = useCollection(notificationRecipientSchema, { page: 1 }, [payload])
  const dispatch = useDispatch()

  if (firebase.messaging.isSupported()) {
    workforceFirebase.messaging().onMessage(payload => {
      setPayload(payload)
    })
  }

  useEffect(() => {
    setUnreadCount(response ? parseInt(response.header['unread-count'], 10) : 0)
  }, [response])

  function markNotificationRead (notificationRecipient) {
    dispatch(trigger(notificationRecipient.get('id'), notificationRecipientSchema, 'read'))
      .then(response => {
        setUnreadCount(response ? parseInt(response.header['unread-count'], 10) : 0)
      })
  }

  return (
    <ResponsiveMenu
      drawerAnchor='top'
      content={
        <Badge
          badgeContent={unreadCount}
          classes={{ badge: classes.badge }}
          invisible={unreadCount === 0}
        >
          <Notifications className={classes.notification} />
        </Badge>
      }
    >
      <ListSubheader className={classes.header}>
        <IconButton>
          <ClearRounded fontSize='small' />
        </IconButton>
      </ListSubheader>

      {
        notificationRecipients.isEmpty() ? (
          <ResponsiveMenuItem content={t('no_notifications')} />
        ) : (
          notificationRecipients.map(notificationRecipient => (
            <NotificationItem
              key={notificationRecipient.get('id')}
              notificationRecipient={notificationRecipient}
              onClick={e => markNotificationRead(notificationRecipient)}
            />
          ))
        )
      }

      <ResponsiveMenuItem
        content={<Typography>{t('see_all')}</Typography>}
        className={classes.footer}
        component={Link}
        to='/notifications'
      />
    </ResponsiveMenu>
  )
}

NotificationsCenter.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(NotificationsCenter)
