import React from 'react'
import { useTranslation } from 'react-i18next'

import { serviceSchema } from '@seekster/schemas'
import { useResource } from 'hooks/resources'

function withServiceSlotTimePane(Component) {
  function WithServiceSlotTimePane({ match, ...props }) {
    const { t } = useTranslation(['services', 'common'])
    const [data] = useResource(serviceSchema, match.params.id)

    const pageProps = {
      ...props,
      service: data,
      t
    }

    return <Component {...pageProps} />
  }

  return WithServiceSlotTimePane
}

export default withServiceSlotTimePane
