import { connect } from 'react-redux'

import { getCollection } from 'selectors/collections'
import { fetch } from 'actions/collections'
import { integrationSchema } from '@seekster/schemas'

import IntegrationIndexPage from './IntegrationIndexPage'

const mapStateToProps = (state) => ({
  integrations: getCollection(state, integrationSchema)
})

const mapDispatchToProps = {
  fetchIntegrations: () => fetch(integrationSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationIndexPage)
