import React from 'react'
import { Form } from 'formik'
import { Button } from 'components/buttons'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Field from 'components/fields/Field'

import DateTimeInput from 'components/inputs/DateTimeInput'

import ResourceSelect from 'components/inputs/ResourceSelect'
import { customerSchema, companySchema } from '@seekster/schemas'

export function InvoiceForm ({ isSubmitting }) {
  const { t } = useTranslation(['common'])

  return (
    <Form>
      <div style={{ padding: '10px 16px' }}>
        <Field name='customer_id' component={ResourceSelect} schema={customerSchema} />
        <Field name='company_id' component={ResourceSelect} schema={companySchema} />
        <Field name='due_at' component={DateTimeInput} />

        <Grid item xs={12} container justify='flex-end'>
          <Button
            type='submit'
            loading={isSubmitting}
            floated='right'
            color='primary'
            variant='contained'
          >
            {t('save')}
          </Button>
        </Grid>

      </div>
    </Form>
  )
}

export default InvoiceForm
