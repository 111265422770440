import { connect } from 'react-redux'

import { fetchChildWithImplicitParent, update } from 'actions/resources'
import { tenantSchema, marketingConfigurationSchema } from '@seekster/schemas'

import MarketingSettingsPane from './MarketingSettingsPane'

const mapDispatchToProps = {
  fetchMarketingConfig: () => fetchChildWithImplicitParent(tenantSchema, marketingConfigurationSchema),
  updateMarketingConfig: data => update('', data, marketingConfigurationSchema)
}

export default connect(null, mapDispatchToProps)(MarketingSettingsPane)
