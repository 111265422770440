import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { promoCodeSchema } from '@seekster/schemas'

import PromoCodeOverviewPane from './PromoCodeOverviewPane'

const mapStateToProps = (state, { match }) => ({
  promoCode: getResource(state, match.params.id, promoCodeSchema)
})

export default connect(mapStateToProps)(PromoCodeOverviewPane)
