
const googleMapsSrc = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places,drawing`

export default async function initGoogleMapApi () {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = googleMapsSrc
  script.async = true
  script.defer = true
  document.head.appendChild(script)

  return new Promise((resolve) => {
    script.onload = resolve
  })
}
