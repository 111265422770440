import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
  ListItem, ListItemAvatar, ListItemText,
  Avatar, Typography
} from '@material-ui/core'
import classNames from 'clsx'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import Panel from 'components/containers/Panel'
import CircularLoader from 'components/CircularLoader'
import Tag from 'components/Tag'

import styles from './ConversationsListPanelStyles'

export function ConversationsListPanel ({
  schema,
  fetchResourceConversations,
  getConversations,
  location,
  match,
  showSource,
  classes: {
    root, primary, primaryPlaceholder, primaryPlaceholderTall,
    secondary, stateClose, stateOpen, noConversation
  },
  t
}) {
  const { loading } = useData(() => fetchResourceConversations(schema, match.params.id))

  function renderTag (state) {
    return (
      <Tag
        value={state}
        className={classNames(stateClose, { [stateOpen]: state === 'open' })}
      />
    )
  }

  function renderTime (createdAt) {
    const endOfToday = moment().endOf('day')
    const endOfTwoDaysAgo = moment().endOf('day').subtract(2, 'days')

    if (moment(createdAt).isBetween(endOfTwoDaysAgo, endOfToday)) {
      return moment(createdAt).fromNow()
    }
    else {
      return moment(createdAt).format('D MMM YYYY')
    }
  }

  function renderContent () {
    if (getConversations(schema).isEmpty()) {
      return (
        <Typography variant='overline' classes={{ root: noConversation }}>
          {t('no_conversation')}
        </Typography>
      )
    }
    else {
      return (
        getConversations(schema).map(conversation => (
          <ListItem
            key={conversation.get('id')}
            button
            component={Link}
            to={`${match.url}/${conversation.get('id')}`}
            selected={location.pathname === `${match.url}/${conversation.get('id')}`}
          >
            {
              conversation.get('latest_message') ? (
                <>
                  <ListItemAvatar>
                    <Avatar
                      src={conversation.getIn(['latest_message', 'sender', 'avatar_url'])}
                      alt=''
                    />
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <>
                        <div>
                          {
                            showSource && (
                              <Typography variant='caption'>
                                {conversation.getIn(['source', 'inquiry', 'number'])}
                              </Typography>
                            )
                          }
                          <Typography variant='inherit' inline>
                            {conversation.getIn(['latest_message', 'sender', 'display_name'])}
                          </Typography>
                          {
                            renderTag(conversation.get('state'))
                          }
                        </div>

                        <Typography variant='caption' align='right'>
                          {renderTime(conversation.getIn(['latest_message', 'created_at']))}
                        </Typography>
                      </>
                    }
                    secondary={conversation.getIn(['latest_message', 'content'])}
                    classes={{ root, secondary, primary }}
                  />
                </>
              ) : (
                <ListItemText
                  primary={
                    <>
                      <Typography variant='overline' color='textSecondary' inline>
                        {t('no_conversation_from')} {conversation.getIn(['source', 'inquiry', 'number'])}
                      </Typography>
                      {
                        renderTag(conversation.get('state'))
                      }
                    </>
                  }
                  classes={{
                    root,
                    primary: showSource ? primaryPlaceholderTall : primaryPlaceholder
                  }}
                />
              )
            }
          </ListItem>
        ))
      )
    }
  }

  return (
    <Panel title={t('title')} responsive noPadding>
      {loading ? <CircularLoader /> : renderContent()}
    </Panel>
  )
}

ConversationsListPanel.propTypes = {
  schema: PropTypes.object,
  fetchResourceConversations: PropTypes.func,
  getConversations: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.object,
  showSource: PropTypes.bool,
  classes: PropTypes.object,

  t: PropTypes.func
}

export default compose(
  withStyles(styles),
  withTranslation('conversations')
)(ConversationsListPanel)
