import React from 'react'
import moment from 'moment'

import { Map, List } from 'immutable'
import { StateTag, PhoneTag, DateTag } from 'components/tags'

import {
  AttributesTable,
  Attribute
} from 'components/tables/AttributesTable'

export class Details extends React.Component {
  render () {
    const { subscription } = this.props

    const service = subscription.get('service') || Map()
    const client = subscription.get('client') || Map()
    const location = subscription.get('location') || Map()
    const weekdays = subscription.get('weekdays') || Map()
    const orders = subscription.get('orders') || List()

    return (
      <AttributesTable>
        <Attribute label='ID' value={subscription.get('id')} />
        <Attribute label='Number' value={subscription.get('number')} />
        <Attribute label='Start date' value={<DateTag value={subscription.get('start_date')} />} />
        <Attribute
          label='Service'
          association={service}
          path='/services'
          displayField='name'
        />
        <Attribute
          label='Orders'
          value={
            <ul>
              {orders.map((order, index) => {
                const pkg = order.get('package') || Map()

                return (
                  <li key={index}>
                    <p>
                      {pkg.get('name')} x {order.get('quantity')} {pkg.get('unit')}
                    </p>
                  </li>
                )
              })}
            </ul>
          }
        />
        <Attribute
          label='Weekdays'
          value={
            <ul>
              {
                Object.keys(weekdays.toJS()).map((weekday, index) => {
                  return (
                    <li key={index}>
                      <p>
                        {moment().weekday(weekday).format('dddd')}
                      </p>
                    </li>
                  )
                })
              }
            </ul>
          }
        />
        <Attribute label='Contact Name' value={location.get('contact_name')} />
        <Attribute
          label='Contact Phone Number'
          value={<PhoneTag value={location.get('phone_number')} />}
        />
        <Attribute label='Address' value={location.get('full_address')} />
        <Attribute
          label='Client'
          association={client}
          path='/clients'
          displayField='name'
        />
        <Attribute
          label='State'
          value={<StateTag value={subscription.get('state')} />}
        />
        <Attribute label='Reference' value={subscription.get('ref')} />

        <Attribute
          label='Updated At'
          value={<DateTag value={subscription.get('updated_at')} />}
        />
        <Attribute
          label='Created At'
          value={<DateTag value={subscription.get('created_at')} />}
        />
      </AttributesTable>
    )
  }
}

export default Details
