import { connect } from 'react-redux'
import { compose } from 'redux'

import { withQueryParser } from 'utils/withQueryParser'
import { fetchChildren, createChild } from 'actions/resources'
import {
  fetch as fetchCollection,
  fetchChildren as fetchCollectionChildren
} from 'actions/collections'
import { getResource } from 'selectors/resources'
import {
  providerSchema,
  walletSchema,
  walletTransactionSchema,
  bankAccountSchema,
  jobSchema,
  transactionCodeSchema
} from '@seekster/schemas'

import ProviderWalletPane from './ProviderWalletPane'

const mapStateToProps = (state, { match }) => ({
  provider: getResource(state, match.params.id, providerSchema)
})

const mapDispatchToProps = {
  fetchWallet: (id, options) => fetchChildren(providerSchema, id, walletSchema, options),

  fetchWalletTransactions: (id, options) =>
    fetchCollectionChildren(providerSchema, id, walletTransactionSchema, {
      ...options,
      childrenType: 'wallet/transactions'
    }),

  fetchProviderBankAccounts: (id) =>
    fetchCollectionChildren(providerSchema, id, bankAccountSchema),
  createProviderBankAccount: (id, data) =>
    createChild(data, providerSchema, id, bankAccountSchema),

  fetchProviderJobs: (id, options = {}) => {
    return fetchCollectionChildren(providerSchema, id, jobSchema, {
      queryParam: {
        query: {
          job_providers_provider_id_eq: id
        },
        order: {
          start_time: 'desc'
        },
        ...options
      }
    })
  },

  fetchTransactionCodes: (options) => fetchCollection(transactionCodeSchema, options)
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(ProviderWalletPane)
