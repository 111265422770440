export default (theme) => ({
  colorPicker: {
    borderRadius: 20,
    height: 38,
    width: '100%',
    justifyContent: 'flex-start'
  },

  colorPreview: {
    marginRight: theme.spacing(1),
    height: 36,
    width: 36,
    borderRadius: '50%'
  }
})
