import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { FileCopyRounded, EditRounded, Delete } from '@material-ui/icons'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import {
  NavigationMenuBar,
  NavigationMenuItem
} from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import {
  ServiceCommentsPane,
  ServiceOverviewPane,
  ServicePackagesPane,
  ServiceProvidersPane,
  ServiceQuestionsPane,
  ServiceRequestsPane,
  ServiceTermsPane,
  ServiceSlotTimePane,
  ServiceFeeConditions
} from './panes'

import styles from './ServiceShowPageStyles'
import { AppLayoutContext } from 'components/layouts/AppLayout'

export function ServiceShowPage({
  service,
  fetchComments,
  fetchService,
  destroyService,
  setFlashMessage,
  push,
  match,
  location,
  classes,
  t
}) {
  const { loading } = useData(() => fetchService(match.params.id))
  useData(() => fetchComments(match.params.id))
  const generalConfig = useContext(AppLayoutContext).generalConfig

  function handleDeleteService() {
    if (window.confirm(`${t('delete_confirmation_prompt')}`)) {
      destroyService(match.params.id)
        .then(push('/services'))
        .catch((error) => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function renderSubNavbar() {
    const path = `/services/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('navbar.overview')} to={path} />
        <NavigationMenuItem label={t('navbar.packages')} to={`${path}/packages`} />
        <NavigationMenuItem label={t('navbar.slot_time')} to={`${path}/slot_time`} />
        <NavigationMenuItem label={t('navbar.questions')} to={`${path}/questions`} />
        <NavigationMenuItem label={t('navbar.requests')} to={`${path}/requests`} />
        <NavigationMenuItem label={t('navbar.providers')} to={`${path}/providers`} />
        <NavigationMenuItem label={t('navbar.terms')} to={`${path}/terms`} />
        <NavigationMenuItem
          label={t('navbar.comments')}
          to={`${path}/comments`}
          badge={service.get('comments', 0).size}
        />
        <NavigationMenuItem label={t('navbar.feeview')} to={`${path}/fee_conditions`} />
      </NavigationMenuBar>
    )
  }

  function secondaryActions() {
    return [
      {
        avatar: <FileCopyRounded />,
        content: t('duplicate'),
        to: `/services/${match.params.id}/duplicate`
      },
      {
        avatar: <EditRounded />,
        content: t('edit'),
        to: `/services/${match.params.id}/edit`
      },
      {
        avatar: <Delete />,
        content: t('delete'),
        onClick: handleDeleteService
      }
    ]
  }

  return (
    <PageLayout
      title={service.get('name')}
      actions={secondaryActions()}
      subNavbar={renderSubNavbar()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/services/:id' component={ServiceOverviewPane} exact />
        <AuthenticatedRoute
          path='/services/:id/packages'
          component={ServicePackagesPane}
        />
        <AuthenticatedRoute
          path='/services/:id/slot_time'
          component={ServiceSlotTimePane}
        />
        <AuthenticatedRoute
          path='/services/:id/questions'
          component={ServiceQuestionsPane}
        />
        <AuthenticatedRoute
          path={['/services/:id/inquiries', '/services/:id/requests']}
          component={ServiceRequestsPane}
        />
        <AuthenticatedRoute
          path='/services/:id/providers'
          component={ServiceProvidersPane}
        />
        <AuthenticatedRoute path='/services/:id/terms' component={ServiceTermsPane} />
        <AuthenticatedRoute
          path='/services/:id/comments'
          component={ServiceCommentsPane}
        />
        {
          generalConfig?.is_cancellation_enabled &&
          <AuthenticatedRoute
            path='/services/:id/fee_conditions'
            component={ServiceFeeConditions}
          />
        }
      </Switch>
    </PageLayout>
  )
}

ServiceShowPage.propTypes = {
  service: ImmutablePropTypes.map,
  fetchComments: PropTypes.func,
  fetchService: PropTypes.func,
  destroyService: PropTypes.func,
  setFlashMessage: PropTypes.func,
  push: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  classes: PropTypes.object,

  t: PropTypes.func
}

ServiceShowPage.defaultProps = {
  service: Map()
}

export default compose(withStyles(styles), withTranslation('services'))(ServiceShowPage)
