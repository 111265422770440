import React, { useState, useEffect } from 'react'
import { Grid, Button } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import useQuery from 'hooks/useQuery'
import { usePush } from 'hooks/utils'
import { useCollection } from 'hooks/collections'
import moment from 'moment'
import { fromJS, List, Map } from 'immutable'
import { useTranslation } from 'react-i18next'

import withFlashMessage from 'hoc/withFlashMessage'
import Panel from 'components/containers/Panel'
import { useSelector } from 'react-redux'

import FilterTags from 'components/tags/FilterTags'
import PayrollsTable from 'components/tables/PayrollsTable'
import Pagination from 'components/Pagination'
import { useResourceAction } from 'hooks/resources'
import PayrollsCycleTable from 'components/tables/PayrollsCycleTable'
import PayrollsHistoryTable from 'components/tables/PayrollsHistoryTable'
import { getCurrentResource } from 'selectors/authentication'

import {
  payrollSchema,
  manualPayrollIntegrationSchema,
  tenantSchema
} from '@seekster/schemas'

const circleImg =
  'https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/icon/checkCircle.png'

const useStyles = makeStyles(({ spacing, shape }) => ({
  root: {
    backgroundColor: '#f3f8fe',
    padding: spacing(0.5, 1),
    border: '1px solid #4e8ac8',
    borderRadius: shape.borderRadius,
    color: '#4e8ac8'
  },
  searchContainer: {
    padding: 24
  },

  metaContainer: {
    padding: 24
  },
  button: {
    margin: 18,
    padding: '10px 16px'
  },
  panelShadow: {
    boxShadow: '0 2px 10px 0 rgba(182,182,182,0.4)'
  },
  noListContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  circleStyle: {
    width: 76,
    paddingTop: 36,
    paddingBottom: 24
  },
  titleNoList: {
    fontSize: 24,
    fontWeight: 'bold'
  },
  contentNoList: {
    fontSize: 18,
    fontWeight: 500
  }
}))

export function PayrollsOverviewPane({ setFlashMessage }) {
  const [payrollSetting, setPayrollSetting] = useState(Map())
  const classes = useStyles()
  const [query] = useQuery()
  const push = usePush()
  const { t } = useTranslation('payrolls')
  const [payrolls, { totalCount, totalPages }] = useCollection(
    payrollSchema,
    { page: query.page, query },
    [query]
  )
  const completedPayroll = payrolls.filter(
    (payroll) => payroll.get('state') === 'completed'
  )
  const pendingPayroll = payrolls.filter((payroll) => payroll.get('state') === 'pending')
  const existingPendingPayrolls = pendingPayroll.slice(1)
  const { trigger: triggerPayroll } = useResourceAction(payrollSchema)
  const { fetch: fetchPayrollSetting } = useResourceAction(manualPayrollIntegrationSchema)
  const nextPayroll =
    (payrollSetting.get('payout_cycles') &&
      payrollSetting
        .get('payout_cycles')
        .filter((cycle) =>
          moment().isBetween(cycle.get('cycle_start_date'), cycle.get('cycle_end_date'))
        )) ||
    List()
  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()
  const isfirstPage = query.page === undefined || query.page === '1'

  useEffect(() => {
    fetchPayrollSetting('').then((response) => setPayrollSetting(fromJS(response.body)))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function handleConfirmPayroll(id) {
    if (window.confirm(t('confirm_payment'))) {
      triggerPayroll(id, 'approve')
        .then(() => push('/payrolls'))
        .catch((error) => {
          let errorMessage = 'Error Occured!'

          if (error.response.body) {
            errorMessage = error.response.body.message
          }

          setFlashMessage('error', errorMessage)
        })
    }
  }

  function actions(id) {
    return (
      <Button
        color='primary'
        variant='contained'
        className={classes.button}
        onClick={() => handleConfirmPayroll(id)}
      >
        {t('confirm_payroll')}
      </Button>
    )
  }

  return (
    <>
      {totalCount === 0 ? (
        <>
          <div className={classes.noListContainer}>
            <img className={classes.circleStyle} src={circleImg} alt='circle' />
            <h2 className={classes.titleNoList}>{t('already_create_payroll')}</h2>
            <h3 className={classes.contentNoList}>
              {t('comeback_again', {
                date:
                  nextPayroll.size !== 0
                    ? nextPayroll.getIn([0, 'payout_day']) + 1
                    : payrollSetting.getIn(['payout_cycles', 0, 'payout_day'])
              })}
            </h3>
          </div>

          <div style={{ padding: 24 }}>
            <div className={classes.panelShadow}>
              <Panel
                title={t('your_payroll_cycle')}
                headerStyle={{ height: 80, maxHeight: 80 }}
              >
                <PayrollsCycleTable payrolls={payrollSetting.get('payout_cycles')} />
              </Panel>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes.metaContainer}>
            <FilterTags />

            {tenant.get('slug') === 'seekster' ? (
              <>
                {isfirstPage && pendingPayroll.size !== 0 && (
                  <div className={classes.panelShadow} style={{ marginBottom: 37 }}>
                    <Panel
                      title={t('latest_payroll')}
                      headerStyle={{ height: 80, maxHeight: 80 }}
                    >
                      <PayrollsTable isSeekster payrolls={payrolls.slice(0, 3)} />
                    </Panel>
                  </div>
                )}

                <div className={classes.panelShadow} style={{ marginBottom: 37 }}>
                  <Panel
                    title={t('history_transaction')}
                    headerStyle={{ height: 80, maxHeight: 80 }}
                  >
                    <PayrollsTable
                      isSeekster
                      payrolls={isfirstPage ? payrolls.slice(3) : payrolls}
                    />
                  </Panel>
                </div>
              </>
            ) : (
              <>
                {isfirstPage && pendingPayroll.size !== 0 && (
                  <div className={classes.panelShadow} style={{ marginBottom: 37 }}>
                    <Panel
                      title={t('current_payroll')}
                      headerStyle={{ height: 80, maxHeight: 80 }}
                      actions={
                        tenant.get('slug') !== 'seekster' &&
                        actions(pendingPayroll.first().get('id'))
                      }
                    >
                      <PayrollsTable payrolls={List([pendingPayroll.first()])} />
                    </Panel>
                  </div>
                )}

                {existingPendingPayrolls.size !== 0 &&
                  existingPendingPayrolls.map((existingPendingPayroll) => (
                    <div
                      className={classes.panelShadow}
                      style={{ marginBottom: 37 }}
                      key={existingPendingPayroll.get('id')}
                    >
                      <Panel
                        title={`${t('pending_confirm')} (${moment(
                          existingPendingPayroll.get('payout_date')
                        ).format('D MMMM YYYY')})`}
                        headerStyle={{ height: 80, maxHeight: 80, color: '#D0021B' }}
                        actions={
                          tenant.get('slug') !== 'seekster' &&
                          actions(existingPendingPayroll.get('id'))
                        }
                      >
                        <PayrollsTable payrolls={existingPendingPayroll} />
                      </Panel>
                    </div>
                  ))}

                <div className={classes.panelShadow}>
                  <Panel
                    title={t('history_transaction')}
                    headerStyle={{ height: 80, maxHeight: 80 }}
                  >
                    <PayrollsHistoryTable payrolls={completedPayroll} />
                  </Panel>
                </div>
              </>
            )}
          </div>

          <Grid item xs={12} container justify='center'>
            <Pagination totalPages={totalPages} />
          </Grid>
        </>
      )}
    </>
  )
}

export default withFlashMessage(PayrollsOverviewPane)
