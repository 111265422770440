import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import { Grid } from '@material-ui/core'

import EventTimeline from 'modules/events/EventTimeline'

export function InquiryTimelinePage ({
  inquiry,
  match,
  loadInquiryEvent
}) {
  useEffect(() => {
    loadInquiryEvent(match.params.id)
  }, [match.params.id, loadInquiryEvent])

  const events = inquiry.get('events') || List()

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <EventTimeline events={events} />
      </Grid>
    </Grid>
  )
}

InquiryTimelinePage.propTypes = {
  inquiry: ImmutablePropTypes.map,
  match: PropTypes.object,

  loadInquiryEvent: PropTypes.func
}

InquiryTimelinePage.defaultProps = {
  inquiry: Map()
}

export default InquiryTimelinePage
