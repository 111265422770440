/* eslint-disable camelcase */
/* eslint-disable space-before-function-paren */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import Modal from 'components/containers/Modal'
import SelectInput from 'components/inputs/SelectInput'
import { Button } from 'components/buttons'
import DateRangeField from 'components/fields/DateRangeField'
import axios from 'axios'
import CircularLoader from 'components/CircularLoader'
import { Formik, Form } from 'formik'

import DatePicker from 'react-multi-date-picker'
import Pagination from 'components/Pagination'
import callService from 'pages/settings/panes/HelpAndSupport/callService'
import useQuery from 'hooks/useQuery'

import { useHistory } from 'react-router-dom'

import moment from 'moment'
import { Grid } from '@material-ui/core'

import './creditlist.scss'

import renderNumber from '../../components/renderNumber'

const index = () => {
  const [query, { pushQuery }] = useQuery()
  const history = useHistory()
  const [isLoading, setIsloading] = useState(true)

  const { t } = useTranslation(['navbar', 'creditnote'])

  const primaryActions = () => {
    return [
      {
        content: t('createcreditnote'),
        to: '/creditnotes/create'
      }
    ]
  }

  const [listData, setListData] = useState([])

  const [valueSearch, setValuesSearch] = useState('')
  const [openModalFilter, setOpenModalFilter] = useState(false)
  const [statusFilter, setStatusFilter] = useState('')
  const [TimePicker, setTimePicker] = useState([])
  const [loadingZIP, setLoadingZIP] = useState(false)
  const [countZIP, setCountZIP] = useState(0)

  const datePickRef = useRef(null)

  const initialValues = {
    created_at: ''
  }

  const callList = async () => {
    let url = `${process.env.REACT_APP_CANCELLATION_BASE_API}/api/credit-notes?page=${
      query.page || 1
    }`

    if (query.search) {
      url += `&keyword=${query.search}`
    }
    if (query.status) {
      url += `&status=${query.status}`
    }
    if (query.startFromTime) {
      url += `&start_at=${query.startFromTime}`
    }
    if (query.endFromTime) {
      url += `&end_at=${query.endFromTime}`
    }

    const response = await callService('GET', `${url}`, null, null)
    if (response.status === 200 || response.status === 201) {
      setListData(response.data)
      setIsloading(false)
    }
  }

  const downloadImage = (data, filename = 'untitled.zip') => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    document.body.appendChild(link)
    link.click()
    setLoadingZIP(false)
  }

  const selectDownloadPDF = async () => {
    setLoadingZIP(true)
    let urlPDF = `${
      process.env.REACT_APP_CANCELLATION_BASE_API
    }/api/credit-notes/pdfs?limit=10&page=${query.page || 1}`

    if (query.search) {
      urlPDF += `&keyword=${query.search}`
    }
    if (query.status) {
      urlPDF += `&status=${query.status}`
    }
    if (TimePicker[0]) {
      urlPDF += `&start_at=${TimePicker[0].format('YYYY-MM-DD')}`
    }
    if (TimePicker[1]) {
      urlPDF += `&end_at=${TimePicker[1].format('YYYY-MM-DD')}`
    }

    try {
      const header = {
        responseType: 'blob'
      }
      const response = await axios.get(urlPDF, header)
      downloadImage(response.data, `${Date.now()}.zip`)
    } catch (error) {
      setLoadingZIP(false)
      alert('An error occurred while fetching the zip. Please try again later.')
    }
  }

  const applyTime = () => {
    const startTimeApply = TimePicker[0]?.format('YYYY-MM-DD')
    const endTimeApply = TimePicker[1]?.format('YYYY-MM-DD')

    if (TimePicker[0] && TimePicker[1]) {
      const result = listData.data.filter((items) => {
        const time = moment(items.created_at).format('YYYY-MM-DD')
        return time >= startTimeApply && time <= endTimeApply
      })
      setCountZIP(result.length)
    } else if (TimePicker[0] && !TimePicker[1]) {
      const result = listData.data.filter((items) => {
        const time = moment(items.created_at).format('YYYY-MM-DD')
        return time === startTimeApply
      })
      setCountZIP(result.length)
    } else {
      setCountZIP(0)
    }

    setTimePicker(TimePicker)
    datePickRef.current.openCalendar()
  }

  const changeSearch = (values) => {
    setValuesSearch(values)
  }

  const enterSearch = (e) => {
    if (e.keyCode === 13) {
      pushQuery({ ...query, search: valueSearch !== '' ? valueSearch : undefined })
    }
  }

  const clickBtnSearch = () => {
    pushQuery({ ...query, search: valueSearch !== '' ? valueSearch : undefined })
  }

  const applyFilter = (action) => {
    pushQuery({
      ...query,
      status: statusFilter || undefined,
      startFromTime: action.values.created_at.from
        ? action.values.created_at.from
        : undefined,
      endFromTime: action.values.created_at.to ? action.values.created_at.to : undefined
    })
    setOpenModalFilter(false)
  }

  const callModal = () => {
    setStatusFilter('')
    setOpenModalFilter(true)
  }

  const timeProps = (item) => {
    const firstSelect = item[0]
    const secondSelect = item[1]

    if (item.length > 0) {
      return (
        <div className='section-creditnote-select-value'>
          {firstSelect} {item[1] && <>-</>} {secondSelect}
        </div>
      )
    }
    return <>Select Date</>
  }

  useEffect(() => {
    callList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return (
    <PageLayout title={t('creditnotes')} primaryActions={primaryActions()}>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
          <div className='py-16 px-2'>
            <Grid container spacing={2}>
              <Grid item xs={12} xl={8}>
                <input
                  type='text'
                  placeholder='Search...'
                  className='input-search'
                  onChange={(e) => changeSearch(e.target.value)}
                  onKeyDown={(e) => enterSearch(e)}
                />
              </Grid>

              <Grid item xs={12} xl={4}>
                <div className='section-btn-search-and-filter'>
                  <button className='btn-1 btn-search' onClick={() => clickBtnSearch()}>
                    Search
                  </button>
                  <button
                    className='btn-1 btn-filter-credit-note'
                    onClick={() => callModal()}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='27'
                      height='23'
                      viewBox='0 0 27 23'
                    >
                      <g id='options-outline' transform='translate(1 0.58)'>
                        <line
                          id='Line_21'
                          data-name='Line 21'
                          x2='5'
                          transform='translate(20 2.42)'
                          fill='none'
                          stroke='#5b8ac3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                        />
                        <line
                          id='Line_22'
                          data-name='Line 22'
                          x2='16'
                          transform='translate(0 2.42)'
                          fill='none'
                          stroke='#5b8ac3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                        />
                        <line
                          id='Line_23'
                          data-name='Line 23'
                          x2='5'
                          transform='translate(20 19.42)'
                          fill='none'
                          stroke='#5b8ac3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                        />
                        <line
                          id='Line_24'
                          data-name='Line 24'
                          x2='16'
                          transform='translate(0 19.42)'
                          fill='none'
                          stroke='#5b8ac3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                        />
                        <line
                          id='Line_25'
                          data-name='Line 25'
                          x2='16'
                          transform='translate(9 10.42)'
                          fill='none'
                          stroke='#5b8ac3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                        />
                        <line
                          id='Line_26'
                          data-name='Line 26'
                          x2='6'
                          transform='translate(0 10.42)'
                          fill='none'
                          stroke='#5b8ac3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                        />
                        <circle
                          id='Ellipse_107'
                          data-name='Ellipse 107'
                          cx='2'
                          cy='2'
                          r='2'
                          transform='translate(16 0.42)'
                          fill='none'
                          stroke='#5b8ac3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                        />
                        <circle
                          id='Ellipse_108'
                          data-name='Ellipse 108'
                          cx='1.5'
                          cy='1.5'
                          r='1.5'
                          transform='translate(6 9.42)'
                          fill='none'
                          stroke='#5b8ac3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                        />
                        <circle
                          id='Ellipse_109'
                          data-name='Ellipse 109'
                          cx='2'
                          cy='2'
                          r='2'
                          transform='translate(16 17.42)'
                          fill='none'
                          stroke='#5b8ac3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                        />
                      </g>
                    </svg>
                    <p>Filters</p>
                  </button>
                </div>
              </Grid>
            </Grid>
            <div className='py-16'>
              <div className='section-select-pdf-and-date'>
                <div className='text-list-table-credit-note'>Credit note reports</div>
                <div className='text-report-list-pdf'>{countZIP} Reports</div>
                <div className='custom-section-calendar-credit-note'>
                  <DatePicker
                    ref={datePickRef}
                    value={TimePicker}
                    format='DD/MM/YYYY'
                    onChange={setTimePicker}
                    className='section-carenda-custom-credit-note'
                    showOtherDays
                    render={(value, openCalendar) => {
                      return (
                        <div
                          onClick={() => openCalendar()}
                          className='btn-select-date-credit-note pointer'
                        >
                          {timeProps(value)}
                        </div>
                      )
                    }}
                    mapDays={({ date, today, isSameDate }) => {
                      if (isSameDate(date, today)) {
                        return {
                          children: (
                            <div className='custom-today'>
                              <div
                                style={{
                                  width: '7px',
                                  height: '7px',
                                  backgroundColor: 'red',
                                  borderRadius: '50%',
                                  position: 'absolute'
                                }}
                              />
                              <div className='text-today'>{today.day}</div>
                            </div>
                          )
                        }
                      }
                    }}
                    range
                  >
                    <div className='section-dot-and-button-credit-note'>
                      <div className='today-and-dot custom-padding-creditnote-calendar'>
                        <div className='dot' />
                        <div className='today-text'>Today</div>
                      </div>
                      <div className='section-btn-clear-and-apply'>
                        <div
                          className='pointer btn-calendar-creditnote btn-clear'
                          onClick={() => setTimePicker([])}
                        >
                          Clear
                        </div>
                        <div
                          className='pointer btn-calendar-creditnote btn-apply'
                          onClick={() => applyTime()}
                        >
                          Apply
                        </div>
                      </div>
                    </div>
                  </DatePicker>
                </div>

                <div
                  className='btn-pdf-list-credit-note pointer'
                  onClick={() => selectDownloadPDF()}
                >
                  {loadingZIP ? (
                    <CircularLoader />
                  ) : (
                    <>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='17.603'
                        height='17.603'
                        viewBox='0 0 17.603 17.603'
                      >
                        <g id='icon' transform='translate(0.8 0.8)'>
                          <g id='Group_157469' data-name='Group 157469'>
                            <g id='Group_157466' data-name='Group 157466'>
                              <g
                                id='Icon_feather-download'
                                data-name='Icon feather-download'
                                transform='translate(0)'
                              >
                                <path
                                  id='Path_150601'
                                  data-name='Path 150601'
                                  d='M20.5,22.5v3.556a1.778,1.778,0,0,1-1.778,1.778H6.278A1.778,1.778,0,0,1,4.5,26.056V22.5'
                                  transform='translate(-4.5 -11.831)'
                                  fill='none'
                                  stroke='#2d95f4'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='1.6'
                                />
                                <path
                                  id='Path_150602'
                                  data-name='Path 150602'
                                  d='M10.5,15l4.445,4.445L19.391,15'
                                  transform='translate(-6.941 -8.777)'
                                  fill='none'
                                  stroke='#2d95f4'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='1.6'
                                />
                                <path
                                  id='Path_150603'
                                  data-name='Path 150603'
                                  d='M18,15.169V4.5'
                                  transform='translate(-9.997 -4.5)'
                                  fill='none'
                                  stroke='#2d95f4'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='1.6'
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <p>Download PDF</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='pb-16 section-table-get-credit-note-all'>
            <table className='section-table-credit-note-custom' cellSpacing='0'>
              <thead>
                <tr>
                  <th>Cridit note no.</th>
                  <th>Invoice no.</th>
                  <th>Customer</th>
                  <th>Job no.</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Creat at</th>
                </tr>
              </thead>
              <tbody>
                {listData.data?.map((data, k) => {
                  return (
                    <tr key={k}>
                      <td>
                        <span
                          className='pointer under-line'
                          onClick={() => history.push(`/creditnotes/${data.id}`)}
                        >
                          {data.credit_note_number}
                        </span>
                      </td>
                      <td>
                        <span
                          className='pointer under-line'
                          onClick={() => history.push(`/invoices/${data.invoice_id}`)}
                        >
                          {data.invoice_no}
                        </span>
                      </td>
                      <td>
                        <div>
                          <span className='mb-0 mt-0 under-linespan'>
                            {data.customer_first_name}
                          </span>
                        </div>
                        <div>
                          <span className='mb-0 mt-0 under-line'>
                            {data.contact_number}
                          </span>
                        </div>
                      </td>
                      <td>
                        {data.refund_jobs?.map((item, l) => {
                          return (
                            <p
                              key={l}
                              className='pointer text-job under-line m-0'
                              onClick={() => history.push(`/jobs/${item?.job_id}`)}
                            >
                              {item.job_number}
                            </p>
                          )
                        })}
                      </td>
                      <td>
                        <p className='total'>{renderNumber(data.net_amount)}</p>
                      </td>
                      <td>
                        <p className={`default-status status-${data.status}`}>
                          {t(`creditnote_filter.${data.status}`)}
                        </p>
                      </td>
                      <td>
                        <p className='time'>
                          {moment(data.created_at).format('ddd, DD MMM YYYY - HH:mm:ss')}
                        </p>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className='py-16'>
              <Grid item xs={12} container justify='center'>
                <Pagination totalPages={listData?.meta?.last_page || 1} />
              </Grid>
            </div>
            <Modal
              isOpen={openModalFilter}
              onCloseModal={() => setOpenModalFilter(false)}
              className='section-modal-filter-list-credit-note-custom'
            >
              <Formik enableReinitialize initialValues={initialValues}>
                {(formik) => (
                  <Form>
                    <div className='inside-modal-filter-list-credit-note'>
                      <div>
                        <DateRangeField name='created_at' />
                      </div>
                      <div className='mt-3'>
                        <SelectInput
                          options={[
                            {
                              label: `${t('creditnote_filter.completed')}`,
                              value: 'completed'
                            },
                            {
                              label: `${t('creditnote_filter.voided')}`,
                              value: 'voided'
                            },
                            {
                              label: `${t('creditnote_filter.pending_refund')}`,
                              value: 'pending_refund'
                            }
                          ]}
                          values={statusFilter}
                          onChange={(e) => setStatusFilter(e)}
                          className='select-input-custom-credit-note'
                        />
                      </div>
                      <div className='mt-3'>
                        <Button color='primary' onClick={() => applyFilter(formik)}>
                          {t('apply')}
                        </Button>

                        <Button onClick={() => setOpenModalFilter(false)}>
                          {t('cancel')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </>
      )}
    </PageLayout>
  )
}

export default index
