import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { connect } from 'formik'

import { LocalizedField } from 'components/fields/Field'
import RichTextInput from 'components/inputs/RichTextInput'

export function TenantGeneralConfigFormSection({ formik, name }) {
  const {
    terms_of_use_url_en: termsOfUseUrlEn,
    terms_of_use_url_th: termsOfUseUrlTh,
    privacy_policy_url_en: privacyPolicyUrlEn,
    privacy_policy_url_th: privacyPolicyUrlTh
  } = formik.values.general_configuration_attributes

  return (
    <>
      <Grid item xs={12}>
        <LocalizedField name={`${name}.terms_of_use_url`} />
      </Grid>

      <Grid item xs={12}>
        <LocalizedField
          name={`${name}.terms_of_use`}
          component={RichTextInput}
          disabled={Boolean(termsOfUseUrlEn || termsOfUseUrlTh)}
        />
      </Grid>

      <Grid item xs={12}>
        <LocalizedField name={`${name}.privacy_policy_url`} />
      </Grid>

      <Grid item xs={12}>
        <LocalizedField
          name={`${name}.privacy_policy`}
          component={RichTextInput}
          disabled={Boolean(privacyPolicyUrlEn || privacyPolicyUrlTh)}
        />
      </Grid>
    </>
  )
}

TenantGeneralConfigFormSection.propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string
}

export default connect(TenantGeneralConfigFormSection)
