import { withFormik } from 'formik'
import { Map } from 'immutable'
import * as Yup from 'yup'

import ProviderStrengthForm from './ProviderStrengthForm'

const formikConfig = {
  mapPropsToValues: ({ providerStrength = Map() }) => ({
    name_en: providerStrength.get('name_en', ''),
    name_th: providerStrength.get('name_th', '')
  }),

  validationSchema: Yup.object().shape({
    name_en: Yup.string(),
    name_th: Yup.string()
  }),

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values)
      .then(({ body }) => {
        props.push(`/provider_strengths/${body.id}`)
      })
      .catch(error => {
        setSubmitting(false)

        setErrors({
          name_en: error.response.body.message,
          name_th: error.response.body.message
        })
      })
  }
}

export default withFormik(formikConfig)(ProviderStrengthForm)
