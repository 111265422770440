import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import {
  withMobileDialog, Dialog, DialogContent, DialogTitle,
  IconButton, Divider, CircularProgress
} from '@material-ui/core'
import { MoreVert, OpenInNew, Edit, Delete, DoneRounded } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import { ResponsiveMenu, ResponsiveMenuItem } from 'components/menus/ResponsiveMenu'
import BankAccountForm from 'forms/resources/BankAccountForm'

import styles from './BankAccountActionsMenuStyles'

export class BankAccountActionsMenu extends React.Component {
  static propTypes = {
    bankAccount: ImmutablePropTypes.map,
    bankAccountId: PropTypes.number,
    fullScreen: PropTypes.bool,
    classes: PropTypes.object,
    fetchBankAccount: PropTypes.func,
    fetchBankAccounts: PropTypes.func,
    updateBankAccount: PropTypes.func,
    deleteBankAccount: PropTypes.func,
    fetchResourceBankAccounts: PropTypes.func,
    approveBankAccount: PropTypes.func,
    parentSchema: PropTypes.object,
    parentId: PropTypes.number,

    t: PropTypes.func
  }

  static defaultProps = {
    bankAccount: Map(),
    bankAccountId: ''
  }

  constructor (props) {
    super(props)

    this.handleOpenMenu = this.handleOpenMenu.bind(this)
    this.handleCloseMenu = this.handleCloseMenu.bind(this)
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleEditBankAccount = this.handleEditBankAccount.bind(this)
    this.handleUpdateBankAccount = this.handleUpdateBankAccount.bind(this)
    this.handleDeleteBankAccount = this.handleDeleteBankAccount.bind(this)
    this.handleApproveBankAccount = this.handleApproveBankAccount.bind(this)
    this.handleAfterAction = this.handleAfterAction.bind(this)

    this.state = {
      anchorEl: null,
      menuOpen: false,
      modalOpen: false,
      isLoading: false
    }
  }

  handleOpenMenu (e) {
    this.setState({ menuOpen: true, anchorEl: e.currentTarget })
  }

  handleCloseMenu () {
    this.setState({ menuOpen: false, anchorEl: null })
  }

  handleOpenModal () {
    this.setState({ modalOpen: true })
  }

  handleCloseModal () {
    this.setState({ modalOpen: false })
  }

  handleEditBankAccount () {
    this.setState({ isLoading: true })

    this.handleCloseMenu()

    this.handleOpenModal()

    this.props.fetchBankAccount(this.props.bankAccountId)
      .then(() => this.setState({ isLoading: false }))
  }

  handleUpdateBankAccount (data) {
    const { updateBankAccount, bankAccountId } = this.props

    this.handleCloseMenu()

    return (
      updateBankAccount(bankAccountId, data)
        .then(this.handleAfterAction)
        .then(this.handleCloseModal())
    )
  }

  handleDeleteBankAccount () {
    const { deleteBankAccount, bankAccountId, t } = this.props

    this.handleCloseMenu()

    if (window.confirm(t('delete_confirmation_prompt'))) {
      return deleteBankAccount(bankAccountId).then(this.handleAfterAction)
    }
  }

  handleApproveBankAccount () {
    const { approveBankAccount, bankAccountId, t } = this.props

    this.handleCloseMenu()

    if (window.confirm(t('approve_confirmation_prompt'))) {
      return approveBankAccount(bankAccountId).then(this.handleAfterAction)
    }
  }

  handleAfterAction () {
    const {
      parentId,
      parentSchema,
      fetchResourceBankAccounts,
      fetchBankAccounts
    } = this.props

    if (parentId && parentSchema) {
      return fetchResourceBankAccounts(parentSchema, parentId)
    }
    else {
      return fetchBankAccounts()
    }
  }

  render () {
    const { bankAccount, fullScreen, classes, t } = this.props

    return (
      <Fragment>
        <ResponsiveMenu
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
          anchorEl={this.state.anchorEl}
          trigger={
            <IconButton onClick={this.handleOpenMenu} className={classes.iconButton}>
              <MoreVert fontSize='small' />
            </IconButton>
          }
        >
          <ResponsiveMenuItem
            avatar={<OpenInNew />}
            content={t('view_on_omise')}
            component='a'
            href={`https://dashboard.omise.co/live/recipients/${bankAccount.get('omise_object_id')}`}
            target='_blank'
          />

          <Divider className={classes.divider} />

          {
            bankAccount.get('state') === 'pending_approval' && (
              <ResponsiveMenuItem
                avatar={<DoneRounded />}
                content={t('approve')}
                onClick={this.handleApproveBankAccount}
              />
            )
          }

          <ResponsiveMenuItem
            avatar={<Edit />}
            content={t('edit')}
            onClick={this.handleEditBankAccount}
          />
          <ResponsiveMenuItem
            avatar={<Delete />}
            content={t(`delete`)}
            onClick={this.handleDeleteBankAccount}
          />
        </ResponsiveMenu>

        <Dialog
          fullWidth
          maxWidth='xs'
          fullScreen={fullScreen}
          open={this.state.modalOpen}
          onClose={this.handleCloseModal}
        >
          <DialogTitle>{t('form_title.edit')}</DialogTitle>
          <DialogContent className={classes.modalContent}>
            {
              this.state.isLoading ? (
                <CircularProgress color='secondary' className={classes.circularProgress} />
              ) : (
                <BankAccountForm
                  bankAccount={bankAccount}
                  onSubmit={this.handleUpdateBankAccount}
                  onCancel={this.handleCloseModal}
                />
              )
            }

          </DialogContent>
        </Dialog>
      </Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withMobileDialog(),
  withTranslation(['bank_accounts', 'actions'])
)(BankAccountActionsMenu)
