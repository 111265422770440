export default ({ palette }) => ({
  container: {
    '& > div': {
      padding: 0
    }
  },

  content: {
    backgroundColor: palette.grey[50],
    borderTop: `3px solid ${palette.primary.main}`
  },

  message: {
    padding: 0,
    width: '85%'
  },

  textContent: {
    padding: 0,
    color: 'rgba(0, 0, 0, 0.87)'
  },

  action: {
    width: '15%',
    justifyContent: 'center',
    margin: 0,
    padding: 0
  },

  closeButton: {
    padding: 11
  }
})
