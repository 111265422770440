import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Field } from 'formik'
import { DateRangePicker } from 'react-dates'
import { withTranslation } from 'react-i18next'
import { get } from 'lodash'

import './DateRangeField.css'

export class DateRangeField extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.handleFocusChange = this.handleFocusChange.bind(this)

    this.state = {
      focusedInput: null
    }
  }

  handleFocusChange (focusedInput) {
    this.setState({ focusedInput })
  }

  render () {
    const { name, label, t } = this.props

    return (
      <Field
        name={name}
        render={({
          form: { setFieldValue, values }
        }) => (
          <div className='form-field'>
            <label>{label || t(`label.${name}`)}</label>

            <DateRangePicker
              focusedInput={this.state.focusedInput}
              onFocusChange={this.handleFocusChange}
              startDate={
                get(values, [name, 'from'])
                  ? moment(get(values, [name, 'from']))
                  : null
              }
              endDate={
                get(values, [name, 'to'])
                  ? moment(get(values, [name, 'to']))
                  : null
              }
              onDatesChange={({ startDate, endDate }) => (
                setFieldValue(
                  name,
                  {
                    from: startDate
                      ? moment(startDate).startOf('day').format('YYYY-MM-DD')
                      : null,
                    to: endDate
                      ? moment(endDate).endOf('day').format('YYYY-MM-DD')
                      : null
                  }
                )
              )}
              startDateId='startDateId'
              endDateId='endDateId'
              isOutsideRange={() => false}
              numberOfMonths={1}
              minimumNights={0}
              showClearDates
              small block
            />
          </div>
        )}
      />
    )
  }
}

export default withTranslation('fields')(DateRangeField)
