import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Moment from 'moment'
import LazyLoad from 'react-lazyload'
import { List } from 'immutable'
import { Link } from 'react-router-dom'
import { Table, Column } from 'components/tables/Table'
import { Paper } from '@material-ui/core'

import { useTranslation } from 'react-i18next'

import Tag from 'components/Tag'
import CapitalizeFormatter from 'components/formatters/CapitalizeFormatter'
import { DateTag, StateTag, PhoneTag } from 'components/tags'

const logoSubscription = 'https://s3-ap-southeast-1.amazonaws.com/seekster-web/assets/images/logo_subscription.png'

export const InquiriesTable = ({ inquiries }) => {
  const { t } = useTranslation()

  return (
    <Paper>
      <Table collection={inquiries}>
        <Column
          name='number'
          render={(number, inquiry) => (
            <p>
              <Link to={`/inquiries/${inquiry.get('id')}`}>
                {number}
              </Link>

              {
                inquiry.get('subscription_month') !== null && (
                  <LazyLoad height={30}>
                    <img src={logoSubscription} alt='' className='ui left spaced image' style={{ height: '30px' }} />
                  </LazyLoad>
                )
              }

              {
                inquiry.get('manual_distribution') && (
                  <div>
                    <Tag
                      value={t('manual')}
                      backgroundColor='#f5474f'
                      color='white'
                      formatter={CapitalizeFormatter}
                      key='manual'
                    />
                  </div>
                )
              }
            </p>
          )}
        />
        <Column
          name='customer'
          render={(customer, inquiry) => (
            <>
              <p>
                <Link to={`/customers/${customer.get('id')}`}>{customer.get('display_name')}</Link>
              </p>
              <p>
                <PhoneTag value={inquiry.getIn(['location', 'phone_number'])} />
              </p>
            </>
          )}
        />

        <Column
          name='company'
          render={(company) => company && <Link to={`/companies/${company.get('id')}`}>{company.get('name')}</Link>}
        />

        <Column
          name='service'
          render={(service) => <Link to={`/services/${service.get('id')}`}>{service.get('name')}</Link>}
        />

        <Column
          name='orders'
          render={(orders) =>
            <ul>
              {
                orders.map((order) =>
                  <li key={order.get('id')}>{order.get('description')}</li>
                )
              }
            </ul>}
        />

        <Column
          name='Booking Date'
          render={(date, inquiry) => {
            const startTime = Moment(inquiry.getIn(['first_job', 'start_time'])).format('ddd, D MMM YYYY')

            if (inquiry.getIn(['first_job', 'id']) === inquiry.getIn(['last_job', 'id'])) {
              return startTime
            }
            else {
              const endTime = inquiry.getIn(['last_job', 'start_time'])

              return `${startTime} - ${Moment(endTime).format('ddd, D MMM YYYY')}`
            }
          }}
        />

        <Column name='location.short_address' />
        <Column name='state' component={StateTag} />
        <Column name='created_at' component={DateTag} />
      </Table>
    </Paper>
  )
}

InquiriesTable.propTypes = {
  inquiries: ImmutablePropTypes.list
}

InquiriesTable.defaultProps = {
  inquiries: List()
}

export default InquiriesTable
