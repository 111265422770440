import React from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { useAppConfigs } from 'hooks/configurations'

import styles from './TimePickerToggleButtonStyles'

export function TimePickerToggleButton ({ classes }) {
  const [{ timePickerType }, setAppConfig] = useAppConfigs()
  const { t } = useTranslation('common')

  const styleProperties = {
    variant: 'outlined',
    size: 'small',
    className: classes.toggleButton
  }

  if (timePickerType === 'custom') {
    return (
      <Button
        onClick={() => setAppConfig({ timePickerType: 'preset' })}
        {...styleProperties}
      >
        {t('switch_to_preset_time_picker')}
      </Button>
    )
  }
  else {
    return (
      <Button
        onClick={() => setAppConfig({ timePickerType: 'custom' })}
        {...styleProperties}
      >
        {t('switch_to_custom_time_select')}
      </Button>
    )
  }
}

export default withStyles(styles)(TimePickerToggleButton)
