import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { destroy, fetch } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { announcementSchema } from '@seekster/schemas'

import withFlashMessage from 'hoc/withFlashMessage'

import AnnouncementShowPage from './AnnouncementShowPage'

const mapStateToProps = (state, { match }) => ({
  announcement: getResource(state, match.params.id, announcementSchema)
})

const mapDispatchToProps = {
  deleteAnnouncement: (id, options) => destroy(id, announcementSchema, options),
  fetchAnnouncement: (id, options) => fetch(id, announcementSchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(withFlashMessage(AnnouncementShowPage))
