import React, { useState } from 'react'
import {
  Snackbar, SnackbarContent, IconButton,
  List, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import { Info, Close } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import { firebase, workforceFirebase } from 'utils/firebase'

import styles from './NotificationSnackbarStyles'

export function NotificationSnackbar ({ classes, ...rest }) {
  const [payload, setPayload] = useState(null)

  if (firebase.messaging.isSupported()) {
    workforceFirebase.messaging().onMessage(payload => {
      setPayload(payload)
    })
  }

  function handleClose () {
    setPayload(null)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={Boolean(payload)}
      autoHideDuration={5000}
      onClose={handleClose}
      {...rest}
      classes={{ root: classes.container }}
    >
      <SnackbarContent
        classes={{
          root: classes.content,
          message: classes.message,
          action: classes.action
        }}
        message={payload && (
          <List disablePadding>
            <ListItem
              button
              component={Link}
              to={payload.notification.click_action}
              onClick={handleClose}
            >
              <ListItemIcon classes={{ root: classes.icon }}>
                <Info />
              </ListItemIcon>

              <ListItemText
                primary={payload.notification.title}
                secondary={payload.notification.body}
                classes={{ root: classes.textContent }}
              />
            </ListItem>
          </List>
        )}
        action={
          <IconButton onClick={handleClose} className={classes.closeButton}>
            <Close />
          </IconButton>
        }
      />
    </Snackbar>
  )
}

export default withStyles(styles)(NotificationSnackbar)
