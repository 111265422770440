export default ({ palette }) => ({
  referenceJobs: {
    padding: 13,
    color: palette.grey[800],
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2)'
  },

  jobsFormContainer: {
    padding: '10px 16px'
  }
})
