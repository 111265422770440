import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import { EditRounded, Delete } from '@material-ui/icons'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import withFlashMessage from 'hoc/withFlashMessage'
import { useResource, useResourceAction } from 'hooks/resources'
import { useCollectionChildren } from 'hooks/collections'
import { usePush } from 'hooks/utils'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import { feedbackSchema, commentSchema } from '@seekster/schemas'

import { FeedbackOverviewPane, FeedbackCommentsPane } from './panes'
import styles from './FeedbackShowPageStyles'

export function FeedbackShowPage ({
  setFlashMessage,
  match,
  location,
  classes,
  t
}) {
  const [feedback, { loading }] = useResource(feedbackSchema, match.params.id)
  const { delete: deleteFeedback } = useResourceAction(feedbackSchema)
  const push = usePush()
  useCollectionChildren(feedbackSchema, match.params.id, commentSchema)

  const commentPreview = feedback.get('comment', '').length > 20
    ? `${feedback.get('comment').slice(0, 20)}...`
    : feedback.get('comment')

  const actions = [
    {
      avatar: <EditRounded />,
      content: t('edit'),
      to: `/feedbacks/${match.params.id}/edit`
    },
    {
      avatar: <Delete />,
      content: t('delete'),
      onClick: handleDeleteFeedback
    }
  ]

  function handleDeleteFeedback () {
    if (window.confirm(`${t('delete_confirmation_prompt')}`)) {
      deleteFeedback(match.params.id)
        .then(push(`/feedbacks`))
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function renderSubNavbar () {
    const path = `/feedbacks/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('panes.overview')} to={path} />
        <NavigationMenuItem
          label={t('navbar.comments')}
          to={`${path}/comments`}
          badge={feedback.get('comments', 0).size}
        />
      </NavigationMenuBar>
    )
  }

  return (
    <PageLayout
      title={commentPreview}
      actions={actions}
      subNavbar={renderSubNavbar()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/feedbacks/:id' component={FeedbackOverviewPane} exact />
        <AuthenticatedRoute path='/feedbacks/:id/comments' component={FeedbackCommentsPane} />
      </Switch>
    </PageLayout>
  )
}

FeedbackShowPage.propTypes = {
  setFlashMessage: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  classes: PropTypes.object,
  t: PropTypes.func
}

export default compose(
  withStyles(styles),
  withTranslation(['feedbacks', 'actions']),
  withFlashMessage
)(FeedbackShowPage)
