import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { LocalOffer } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import { useCollection } from 'hooks/collections'
import useQuery from 'hooks/useQuery'

import PageLayout from 'components/layouts/PageLayout'
import Search from 'components/Search'
import FilterModal from 'components/FilterModal'
import FilterTags from 'components/tags/FilterTags'
import PromoCodesFilterForm from 'forms/filters/PromoCodesFilterForm'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import PromoCodesTable from 'components/tables/PromoCodesTable'
import Pagination from 'components/Pagination'

import { promoCodeSchema } from '@seekster/schemas'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

export function PromoCodeIndexPage () {
  const classes = useStyles()
  const [query] = useQuery()
  const { t } = useTranslation('promo_codes')
  const [promoCodes, {
    loading, totalCount, totalPages
  }] = useCollection(promoCodeSchema, { page: query.page, query }, [query])

  function primaryActions () {
    return [{
      avatar: <LocalOffer fontSize='small' />,
      content: t('new'),
      to: '/promo_codes/new'
    }]
  }

  return (
    <PageLayout title={t('title')} primaryActions={primaryActions()} isLoading={loading}>
      <div className={classes.searchContainer}>
        <Grid container spacing={3}>
          <Grid item xs={9} sm={10}>
            <Search />
          </Grid>

          <Grid item xs={3} sm={2}>
            <FilterModal component={PromoCodesFilterForm} />
          </Grid>
        </Grid>
      </div>

      <div className={classes.metaContainer}>
        <Grid item xs={12}>
          <FilterTags />

          <ResultsCountLabel value={totalCount} unit='promo_code' />

          <PromoCodesTable promoCodes={promoCodes} />
        </Grid>

        <Grid item xs={12} container justify='center'>
          <Pagination totalPages={totalPages} />
        </Grid>
      </div>
    </PageLayout>
  )
}

export default PromoCodeIndexPage
