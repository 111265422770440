import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { Field } from 'components/fields/Field'
import DateTimePicker from 'components/inputs/DateTimePickerNew'
import ButtonLoader from 'components/buttons/ButtonLoader'
import DateSlotTimePicker from 'components/inputs/DateSlotTimePicker'

const useStyles = makeStyles(() => ({
  root: {
    padding: 16
  }
}))

export function JobForm({ isSubmitting, job, values, setFieldValue }) {
  const { t } = useTranslation('actions')
  const classes = useStyles()
  const slotEnabled = job.getIn(['service', 'slot_enabled'])
  const serviceId = job.getIn(['service', 'id'])

  return (
    <div className={classes.root}>
      <Grid container spacing={3} component={Form}>
        <Grid item xs={12}>
          {slotEnabled ? (
            <DateSlotTimePicker
              job={job}
              serviceId={serviceId}
              value={values}
              setFieldValue={setFieldValue}
            />
          ) : (
            <Field name='start_time' noLabel component={DateTimePicker} />
          )}
        </Grid>

        <Grid item xs={12} container justify='flex-end'>
          <ButtonLoader
            type='submit'
            variant='contained'
            primary
            isLoading={isSubmitting}
          >
            {t('save')}
          </ButtonLoader>
        </Grid>
      </Grid>
    </div>
  )
}

JobForm.propTypes = {
  isSubmitting: PropTypes.bool,
  job: PropTypes.object,
  values: PropTypes.object,
  setFieldValue: PropTypes.func
}

export default JobForm
