import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { customerSchema, companyTypeSchema } from '@seekster/schemas'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Map } from 'immutable'

import { getResource } from 'selectors/resources'
import ResourceSelect from 'components/inputs/ResourceSelect'
import Field from 'components/fields/Field'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputBase from '@material-ui/core/InputBase'

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase)

const useStyles = makeStyles(() => ({
  customSelectField: {
    marginTop: '26px',
    width: '100%'
  }
}))

function BookingCompanyFormSection({
  attributeScope,
  setFieldValue,
  companyAttributes = {}
}) {
  const classes = useStyles()
  let selectedCompanyType = useSelector((state) => {
    return (
      getResource(state, companyAttributes.parent_company_type_id, companyTypeSchema) ||
      Map()
    )
  })

  selectedCompanyType = selectedCompanyType.toJS()

  return (
    <>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <Field name={`${attributeScope}.name`} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Field name={`${attributeScope}.tax_number`} minLength={13} maxLength={13} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Field name={`${attributeScope}.billing_email`} type='email' />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Field name={`${attributeScope}.phone_number`} type='tel' maxLength={10} />
        </Grid>

        <Grid item xs={12}>
          <Field
            name={`${attributeScope}.contact_person_id`}
            component={ResourceSelect}
            schema={customerSchema}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Field
            name={`${attributeScope}.parent_company_type_id`}
            component={ResourceSelect}
            schema={companyTypeSchema}
            value={companyAttributes.parent_company_type_id}
          />
        </Grid>
        <Grid item xs={6}>
          <div className='form-field'>
            <NativeSelect
              className={classes.customSelectField}
              value={companyAttributes.company_type_id}
              onChange={(e) =>
                setFieldValue(`${attributeScope}.company_type_id`, e.target.value)
              }
              input={<BootstrapInput />}
              disabled={!companyAttributes.parent_company_type_id}
            >
              <option value=''>-- choose --</option>
              {selectedCompanyType?.children?.map((subCompanyType, key) => (
                <option key={key} value={subCompanyType.id}>
                  {subCompanyType.name}
                </option>
              ))}
            </NativeSelect>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default BookingCompanyFormSection
