import moment from 'moment'
import { get } from 'lodash'

export const reviewsQuery = ({ search, rating, review_type, created_at }) => {
  return {
    job_number_or_customer_first_name_or_customer_last_name_or_provider_first_name_or_provider_last_name_cont: search,

    rating_eq: rating,

    review_type_id_eq: review_type,

    created_at_gteq: get(created_at, 'from')
      ? moment(created_at.from).startOf('day').toISOString(true)
      : undefined,
    created_at_lteq: get(created_at, 'to')
      ? moment(created_at.to).endOf('day').toISOString(true)
      : undefined
  }
}

export default reviewsQuery
