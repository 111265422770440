import { connect } from 'react-redux'

import { fetch, destroy } from 'actions/resources'
import { trigger } from 'actions/resourceEvent'
import { push } from 'react-router-redux'
import { getResource } from 'selectors/resources'
import { subscriptionSchema } from '@seekster/schemas'

import withFlashMessage from 'hoc/withFlashMessage'

import SubscriptionShowPage from './SubscriptionShowPage'

const mapStateToProps = (state, { match }) => ({
  subscription: getResource(state, match.params.id, subscriptionSchema)
})

const mapDispatchToProps = {
  fetchSubscription: (id, options) => fetch(id, subscriptionSchema, options),
  destroy: (id, options) => destroy(id, subscriptionSchema, options),
  cancel: (id, event, options) => trigger(id, subscriptionSchema, event, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(withFlashMessage(SubscriptionShowPage))
