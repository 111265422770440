import { connect } from 'react-redux'

import { fetchChildWithImplicitParent } from 'actions/resources'
import { tenantSchema, providerConfigurationSchema } from '@seekster/schemas'

import ProviderSettingsPane from './ProviderSettingsPane'

const mapDispatchToProps = {
  fetchProviderConfig: () => fetchChildWithImplicitParent(tenantSchema, providerConfigurationSchema)
}

export default connect(null, mapDispatchToProps)(ProviderSettingsPane)
