import React from 'react'
import PropTypes from 'prop-types'
import { FilterAssociationTags } from '../../FilterAssociationTags'
import { withQueryParser } from 'utils/withQueryParser'

export function FilterCreatedByTags({ query, onQueryPush }) {
  return query.created_by_id ? (
    <FilterAssociationTags
      content='My Inquiries'
      name='created_by_id'
      query={query}
      onQueryPush={onQueryPush}
    />
  ) : null
}

FilterCreatedByTags.propTypes = {
  query: PropTypes.object,
  onQueryPush: PropTypes.func
}

export default withQueryParser(FilterCreatedByTags)
