export const PAYMENT_STATES = [
  'pending',
  'reserved',
  'voided',
  'captured',
  'rejected',
  'failed',
  'partially_refunded',
  'fully_refunded'
]
