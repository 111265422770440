import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import {
  withMobileDialog,
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useCollection } from 'hooks/collections'
import { useResourceAction } from 'hooks/resources'
import Panel from 'components/containers/Panel'
import TenantAccountingConfigForm from 'forms/actions/TenantAccountingConfigForm'
import BankAccountList from 'modules/bankAccounts/BankAccountList'
import BankAccountForm from 'forms/resources/BankAccountForm'

import { getCurrentResource } from 'selectors/authentication'

import {
  tenantSchema,
  accountingConfigurationSchema,
  bankAccountSchema
} from '@seekster/schemas'

export function AccountingSettingsPane({
  fetchAccountingConfig,
  fetchBankAccounts,
  fullScreen
}) {
  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()
  const [bankAccounts] = useCollection(bankAccountSchema)
  const [accountingConfig] = useCollection(accountingConfigurationSchema)
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation(['bank_accounts', 'settings'])
  const { create: createBankAccount } = useResourceAction(bankAccountSchema)
  const { create: createAccountConfig, update: updateAccountConfig } = useResourceAction(
    accountingConfigurationSchema
  )

  useEffect(() => {
    fetchAccountingConfig()
  }, [fetchAccountingConfig])

  function handleOpenModal() {
    setModalOpen(true)
  }

  function handleCloseModal() {
    setModalOpen(false)
  }

  function handleCreateBankAccount(data) {
    return createBankAccount(data)
      .then(() => fetchBankAccounts())
      .then(handleCloseModal)
  }

  function handleUpdateAccount(data) {
    return updateAccountConfig('', data).then(() => fetchAccountingConfig)
  }

  function handleCreateAccount(data) {
    return createAccountConfig(data).then(() => fetchAccountingConfig)
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={8}>
        <TenantAccountingConfigForm
          accountingConfig={tenant.get('accounting_configuration')}
          onSubmit={
            accountingConfig.isEmpty() ? handleCreateAccount : handleUpdateAccount
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Panel
          title={t('title')}
          actions={
            <IconButton onClick={handleOpenModal}>
              <AddCircle />
            </IconButton>
          }
        >
          <BankAccountList bankAccounts={bankAccounts} />

          <Dialog
            fullWidth
            maxWidth='xs'
            fullScreen={fullScreen}
            open={modalOpen}
            onClose={handleCloseModal}
          >
            <DialogTitle>{t('form_title.new')}</DialogTitle>
            <DialogContent>
              <BankAccountForm
                onSubmit={handleCreateBankAccount}
                onCancel={handleCloseModal}
              />
            </DialogContent>
          </Dialog>
        </Panel>
      </Grid>
    </Grid>
  )
}

AccountingSettingsPane.propTypes = {
  fetchAccountingConfig: PropTypes.func,
  fetchBankAccounts: PropTypes.func,
  fullScreen: PropTypes.bool
}

export default withMobileDialog()(AccountingSettingsPane)
