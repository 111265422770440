import React, { useState } from 'react'
import { Map } from 'immutable'
import { Link } from 'react-router-dom'
import {
  AppBar as MaterialAppBar,
  Toolbar,
  IconButton,
  Avatar,
  Menu,
  MenuItem
} from '@material-ui/core'
import { Menu as MenuIcon, Settings } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { tenantSchema, agentSchema } from '@seekster/schemas'

import { signOut } from 'actions/authentication'
import { getCurrentResource } from 'selectors/authentication'
import { useSidebar } from 'hooks/interface'
import NotificationsCenter from 'modules/notifications/NotificationsCenter'
import NotificationSnackbar from 'modules/notifications/NotificationSnackbar'

import useStyles from './styles'

export function AppBar() {
  const [anchorEl, setAnchorEl] = useState(null)
  const { toggleSidebar } = useSidebar()
  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()
  const agent = useSelector((state) => getCurrentResource(state, agentSchema)) || Map()
  const dispatch = useDispatch()
  const { t } = useTranslation('navbar')
  const classes = useStyles()

  function handleOpenProfileMenu(e) {
    setAnchorEl(e.currentTarget)
  }

  function handleCloseProfileMenu() {
    setAnchorEl(null)
  }

  function handleUserLogout() {
    handleCloseProfileMenu()

    window.confirm(t('sign_out_confirmation_prompt')) && dispatch(signOut())
  }

  return (
    <MaterialAppBar
      color='primary'
      position='fixed'
      className={classes.appBar}
      id='app-bar'
    >
      <Toolbar>
        <IconButton
          color='inherit'
          className={classes.menuButton}
          edge='start'
          onClick={toggleSidebar}
        >
          <MenuIcon />
        </IconButton>

        <img
          src={tenant.get('light_logo_url')}
          alt={tenant.get('name')}
          className={classes.logo}
        />

        <div className={classes.grow} />

        <NotificationsCenter />
        <NotificationSnackbar />

        {agent.get('admin') && (
          <IconButton color='inherit' component={Link} to='/settings'>
            <Settings />
          </IconButton>
        )}

        <IconButton onClick={handleOpenProfileMenu} color='inherit'>
          <Avatar
            src={agent.get('avatar_url')}
            alt={agent.getIn(['avatar', 'first_name'])}
            className={classes.avatar}
          />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseProfileMenu}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem onClick={handleCloseProfileMenu} component={Link} to='/profile'>
            {t('profile')}
          </MenuItem>

          <MenuItem onClick={handleCloseProfileMenu} component={Link} to='/preferences'>
            {t('preferences')}
          </MenuItem>

          <MenuItem onClick={handleUserLogout}>{t('sign_out')}</MenuItem>
        </Menu>
      </Toolbar>
    </MaterialAppBar>
  )
}

export default AppBar
