import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import AppForm from 'forms/resources/AppForm'

export function AppEditPage ({
  app,
  match,
  fetchApp,
  updateApp,
  push,
  t
}) {
  useEffect(() => {
    fetchApp(match.params.id)
  }, [fetchApp, match.params.id])

  function renderBreadcrumbs () {
    return [
      { text: t('title'), link: '/apps' },
      { text: app.get('name'), link: `/apps/${match.params.id}` },
      { text: t('edit') }
    ]
  }

  function handleSubmit (data) {
    return updateApp(match.params.id, data)
  }

  return (
    <PageLayout
      title={t('new')}
      breadcrumbs={renderBreadcrumbs()}
    >
      <AppForm app={app} onSubmit={handleSubmit} push={push} />
    </PageLayout>
  )
}

AppEditPage.propTypes = {
  app: ImmutablePropTypes.map,
  match: PropTypes.object,
  fetchApp: PropTypes.func,
  updateApp: PropTypes.func,
  push: PropTypes.func,
  t: PropTypes.func
}

AppEditPage.defaultProps = {
  app: Map()
}

export default withTranslation('apps')(AppEditPage)
