import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import { AttributesTable, Attribute, LocalizedAttribute } from 'components/tables/NewAttributesTable'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'

export function ProviderIssueOverviewPane ({ issue, t }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Panel title={t('details')}>
          <AttributesTable resource={issue} basic='very'>
            <Attribute name='id' />
            <LocalizedAttribute name='name' />
            <Attribute name='updated_at' component={DateTimeFormatter} />
            <Attribute name='created_at' component={DateTimeFormatter} />
          </AttributesTable>
        </Panel>
      </Grid>
    </Grid>
  )
}

ProviderIssueOverviewPane.propTypes = {
  issue: ImmutablePropTypes.map,

  t: PropTypes.func
}

ProviderIssueOverviewPane.defaultProps = {
  issue: Map()
}

export default withTranslation('issues')(ProviderIssueOverviewPane)
