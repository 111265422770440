import { connect } from 'react-redux'
import { compose } from 'redux'

import { fetch } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { withQueryParser } from 'utils/withQueryParser'
import { categorySchema } from '@seekster/schemas'
import FilterCategoryTags from './FilterCategoryTags'

const mapStateToProps = (state, { query }) => {
  const category = getResource(state, query.categories_id, categorySchema)

  return ({
    categoryName: category && category.get('name')
  })
}

const mapDispatchToProps = {
  fetchCategory: (id, options) => fetch(id, categorySchema, options)
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(FilterCategoryTags)
