import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { compose } from 'redux'
import { withMobileDialog, Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import AccountMergingPanel from 'modules/accountMerges/AccountMergingPanel'
import UsersListPanel from 'modules/users/UsersListPanel'
import { providerSchema } from '@seekster/schemas'

export function ProviderAccountMergesPage ({
  provider,
  fetchProvider,
  fetchProviderDevices,
  match,
  fullScreen: isMobile,
  t
}) {
  const { loading } = useData(() => {
    return fetchProvider(match.params.id)
      .then(() => fetchProviderDevices(match.params.id))
  })

  return (
    <PageLayout
      title={t('merge')}
      back={`/providers/${match.params.id}`}
      isLoading={loading}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <AuthenticatedRoute
            path='/providers/:id/account_merges'
            component={UsersListPanel}
            componentProps={{
              schema: providerSchema
            }}
            exact={isMobile}
          />
        </Grid>

        <Grid item xs={12} sm={6} style={{ marginTop: isMobile && '-24px' }}>
          <AuthenticatedRoute
            path='/providers/:id/account_merges/:id'
            component={AccountMergingPanel}
            componentProps={{
              schema: providerSchema,
              targetUser: provider
            }}
          />
        </Grid>
      </Grid>
    </PageLayout>
  )
}

ProviderAccountMergesPage.propTypes = {
  provider: ImmutablePropTypes.map,
  fetchProvider: PropTypes.func,
  fetchProviderDevices: PropTypes.func,
  match: PropTypes.object,
  fullScreen: PropTypes.bool,

  t: PropTypes.func
}

ProviderAccountMergesPage.defaultProps = {
  provider: Map()
}

export default compose(
  withMobileDialog(),
  withTranslation('providers')
)(ProviderAccountMergesPage)
