import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Switch } from 'react-router-dom'
import { Map, fromJS } from 'immutable'

import { useCollection } from 'hooks/collections'
import useQuery from 'hooks/useQuery'
import { usePush } from 'hooks/utils'
import { useResourceAction } from 'hooks/resources'
import { useSelector } from 'react-redux'

import withFlashMessage from 'hoc/withFlashMessage'

import PageLayout from 'components/layouts/PageLayout'
import CircularLoader from 'components/CircularLoader'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import { getCurrentResource } from 'selectors/authentication'

import { payrollSchema, manualPayrollIntegrationSchema, tenantSchema } from '@seekster/schemas'

import {
  PayrollsCyclePane,
  PayrollsOverviewPane
} from './panes'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: 24
  },

  metaContainer: {
    padding: 24
  },
  button: {
    margin: 18,
    padding: '10px 16px'
  },
  notFound: {
    textAlign: 'center'
  },
  notFoundButton: {
    marginBottom: 30
  }
}))

export function PayrollIndexPage ({ location }) {
  const classes = useStyles()
  const [query] = useQuery()
  const push = usePush()
  const [payrollSetting, setPayrollSetting] = useState(Map())
  const [isLoading, setIsloading] = useState(true)
  const { t } = useTranslation('payrolls')
  const [{ totalCount }] = useCollection(payrollSchema, { page: query.page, query }, [query])
  const tenant = useSelector(state => getCurrentResource(state, tenantSchema)) || Map()
  const { fetch: fetchPayrollSetting } = useResourceAction(manualPayrollIntegrationSchema)

  useEffect(() => {
    fetchPayrollSetting('').then(response => {
      setPayrollSetting(fromJS(response.body))
      setIsloading(false)
    })
      .catch(() => {
        setIsloading(false)
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function subNavbar () {
    const path = '/payrolls'

    if (payrollSetting.isEmpty() || totalCount === 0) {
      return null
    }

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('overview')} to={path} />
        <NavigationMenuItem label={t('payroll_cycle')} to={`${path}/payroll_cycle`} />
      </NavigationMenuBar>
    )
  }

  return (
    <PageLayout
      title={t('title')}
      subNavbar={subNavbar()}
    >
      {
        isLoading ? (
          <CircularLoader />
        ) : (
          <>
            {
              payrollSetting.isEmpty() && tenant.get('slug') !== 'seekster' ? (
                <div className={classes.notFound}>
                  <h2>
                    {t('payroll_not_found')}
                  </h2>
                  <Button
                    type='button'
                    color='primary'
                    variant='contained'
                    onClick={() => push('/payrolls/new')}
                    className={classes.notFoundButton}
                  >
                    {t('create_payroll')}
                  </Button>
                </div>
              ) : (
                <Switch>
                  <AuthenticatedRoute path='/payrolls' component={PayrollsOverviewPane} exact />
                  <AuthenticatedRoute path='/payrolls/payroll_cycle' component={PayrollsCyclePane} />
                </Switch>
              )
            }
          </>
        )
      }
    </PageLayout>
  )
}

export default withFlashMessage(PayrollIndexPage)
