import React, { useState, Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
// import DeleteIcon from '@material-ui/icons/Delete'
import AssignmentIcon from '@material-ui/icons/Assignment'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { fromJS } from 'immutable'

import { useResourceAction } from 'hooks/resources'
import { useModal } from 'hooks/useModal'
import ConfirmDialog from 'components/dialog/ConfirmDialog'
import CircularLoader from 'components/CircularLoader'
import { isMobile } from 'utils/deviceDetection'
import { ASSIGN_PROVIDER } from 'constants/modal'
import styles from './TableStyles'

function EnhancedTableHead(props) {
  const { t } = useTranslation('attributes')
  const {
    filteredChildren,
    classes,
    numSelected,
    rowCount,
    onSelectAllClick,
    selectable
  } = props

  return (
    <TableHead>
      <TableRow>
        {selectable && (
          <TableCell padding='checkbox'>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected !== rowCount}
              checked={numSelected === rowCount && rowCount !== 0}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        )}
        {Children.map(filteredChildren, (child, index) => {
          const { name, value, columnName, component, ...customProps } = child.props

          return (
            <TableCell className={classes.attributeHeader} {...customProps} key={index}>
              {columnName || t(child.props.name)}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  filteredChildren: PropTypes.func.isRequired,
  classes: PropTypes.object,
  selectable: PropTypes.bool
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    top: 160,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: 48,
    zIndex: 1
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%'
  }
}))

const EnhancedTableToolbar = ({ numSelected, handleAssign }) => {
  const classes = useToolbarStyles()
  const { t } = useTranslation('common')

  return (
    <Toolbar
      className={[
        clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        }),
        classes.toolbar
      ]}
    >
      {numSelected > 0 && (
        <>
          <Typography
            className={classes.title}
            color='inherit'
            variant='subtitle1'
            component='div'
          >
            {t('row_suffix', { count: numSelected })}
          </Typography>
          <Tooltip title='Assign'>
            <IconButton aria-label='assign' onClick={handleAssign}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title='Delete'>
            <IconButton aria-label='delete' onClick={handleDestroy}>
              <DeleteIcon />
            </IconButton>
          </Tooltip> */}
        </>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  // handleDestroy: PropTypes.func,
  handleAssign: PropTypes.func
}

function SelectedTable({ classes, collection, schema, selectable, loading, ...rest }) {
  const filteredChildren = rest.children.filter((child) => child instanceof Object)
  const [selected, setSelected] = useState([])
  const [modalOpen, setModalOpen] = useState(null)
  const { t } = useTranslation(['jobs', 'common', 'actions'])
  const { openModal, closeModal } = useModal()
  const { bulkDestroy, bulkUpdateProvider } = useResourceAction(schema)
  const jobPendingProviderList = selected.filter(
    (item) => item.state === 'pending_provider'
  )

  function handleDestroy() {
    bulkDestroy({ job_ids: selected.map((item) => item.id) }).then(() => {
      setSelected([])
      setModalOpen(null)
    })
  }

  function handleAssignProvider({ provider_id }) {
    return bulkUpdateProvider({
      job_ids: jobPendingProviderList.map((item) => item.id),
      provider_id: provider_id
    }).then(() => {
      setSelected([])
      closeModal()
    })
  }

  function handleSelectAllClick() {
    if (selected.length) {
      setSelected([])
      return
    }
    const newSelecteds = collection
      .toJS()
      .filter((item) => !item.disabled)
      .map((row) => {
        return {
          id: row.id,
          number: row.number,
          start_time: row.start_time,
          state: row.state
        }
      })
    setSelected(newSelecteds)
  }

  function handleClick(event, job) {
    const selectedIndex = selected.findIndex((item) => job.get('id') === item.id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, {
        id: job.get('id'),
        number: job.get('number'),
        start_time: job.get('start_time'),
        state: job.get('state')
      })
    } else {
      newSelected = [...selected]
      newSelected.splice(selectedIndex, 1)
    }

    setSelected(newSelected)
  }

  function handleOpenAssignProviderModal() {
    openModal({
      name: ASSIGN_PROVIDER,
      data: {
        jobIds: jobPendingProviderList.map((item) => item.id),
        jobStartTimes: jobPendingProviderList.map((item) => item.start_time)
      },
      onConfirm: handleAssignProvider
    })
  }

  function checkSelected(id) {
    return selected.findIndex((item) => item.id === id) !== -1
  }

  return (
    <div>
      {selectable && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleDestroy={() => setModalOpen('confirm_delete_jobs')}
          handleAssign={handleOpenAssignProviderModal}
        />
      )}
      <TableContainer>
        <Table className={classes.root} {...rest}>
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={collection.size}
            filteredChildren={filteredChildren}
            classes={classes}
            selectable={selectable}
          />
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align='center' colSpan={10}>
                  <CircularLoader />
                </TableCell>
              </TableRow>
            ) : (
              fromJS(collection).map((resource, index) => {
                const isItemSelected = checkSelected(resource.get('id'))
                const isDisabled = resource.get('disabled') || false

                return (
                  <TableRow
                    hover
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={resource.get('id')}
                    selected={isItemSelected}
                  >
                    {selectable && (
                      <TableCell padding='checkbox'>
                        <Checkbox
                          onClick={(event) => handleClick(event, resource)}
                          checked={isItemSelected}
                          disabled={isDisabled}
                          inputProps={{
                            'aria-labelledby': `enhanced-table-checkbox-${index}`
                          }}
                        />
                      </TableCell>
                    )}
                    {React.Children.map(filteredChildren, (child) => {
                      return cloneElement(child, {
                        resource,
                        className: classes.attributeValue,
                        index
                      })
                    })}
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmDialog
        title={t('delete_confirmation_title')}
        isOpen={modalOpen === 'confirm_delete_jobs'}
        onClose={() => setModalOpen(null)}
        onSave={handleDestroy}
      >
        {!isMobile() &&
          selected.map((item, index) => (
            <div className={classes.deleteItem} key={item.id}>
              {index + 1}. {item.number}{' '}
              {moment(item.start_time).format('ddd, DD MMM YYYY - HH:mm')}
            </div>
          ))}
      </ConfirmDialog>
    </div>
  )
}

export default withStyles(styles)(SelectedTable)
