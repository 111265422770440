import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { EditRounded, Delete } from '@material-ui/icons'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import { ReviewOverviewPane } from './panes'

export function ReviewShowPage ({
  review,
  setFlashMessage,
  match,
  getReview,
  destroyResource,
  push,
  t
}) {
  const { loading } = useData(() => getReview(match.params.id))

  function handleDeleteJob (e) {
    if (window.confirm('Are you sure you want to delete this?')) {
      destroyResource(match.params.id)
        .then(() => push('/reviews'))
        .catch(error => setFlashMessage('error', error.response.body.message))
    }
  }

  function secondaryActions () {
    const actions = [{
      avatar: <EditRounded />,
      content: t('edit'),
      to: `/reviews/${match.params.id}/edit`
    },
    {
      avatar: <Delete />,
      content: t('delete'),
      onClick: handleDeleteJob
    }]

    return actions
  }

  function breadcrumbs () {
    const job = review.get('job') || Map()

    return [{
      text: 'Reviews',
      link: '/reviews'
    }, {
      text: job.get('number')
    }]
  }

  const job = review.get('job') || Map()

  return (
    <PageLayout
      title={job.get('number')}
      actions={secondaryActions()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/reviews/:id' component={ReviewOverviewPane} exact />
      </Switch>
    </PageLayout>
  )
}

ReviewShowPage.propTypes = {
  review: ImmutablePropTypes.map,
  match: PropTypes.object,
  setFlashMessage: PropTypes.func,
  getReview: PropTypes.func,
  push: PropTypes.func,
  destroyResource: PropTypes.func,
  t: PropTypes.func
}

ReviewShowPage.defaultProps = {
  review: Map()
}

export default withTranslation('actions')(ReviewShowPage)
