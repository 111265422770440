import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  MoneyOffRounded,
  Sync,
  SyncDisabled,
  FileCopyRounded,
  EditRounded,
  Cancel,
  Delete
} from '@material-ui/icons'

import useData from 'hooks/useData'
import { useResourceAction } from 'hooks/resources'
import PageLayout from 'components/layouts/PageLayout'
import {
  NavigationMenuBar,
  NavigationMenuItem
} from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import Tag from 'components/Tag'
import CapitalizeFormatter from 'components/formatters/CapitalizeFormatter'
import { REQUEST_EDITABLE_STATES } from 'constants/states/requestEditableStates'
import RequestCancelModal from 'modules/requests/RequestCancelModal'
import { inquirySchema, cancelReasonSchema } from '@seekster/schemas'
import { makeStyles } from '@material-ui/core/styles'

import {
  InquiryAssignProviderPane,
  InquiryCommentsPane,
  InquiryConversationsPane,
  InquiryJobsPane,
  InquiryLeadsPane,
  InquiryOverviewPane,
  InquiryReviewsPane,
  InquiryTimeline
} from './panes'

const useStyles = makeStyles(
  {
    root: { zIndex: 0 }
  },
  { name: 'MuiToolbar' }
)
export function InquiryShowPage({
  inquiry,
  fetchComments,
  fetchInquiry,
  fetchReport,
  destroyInquiry,
  triggerInquiry,
  triggerSubscription,
  setFlashMessage,
  push,
  location,
  match
}) {
  const { t } = useTranslation(['inquiries', 'inquiry', 'actions', 'jobs'])
  const { loading } = useData(() => fetchInquiry(match.params.id))
  useData(() => fetchComments(match.params.id), [])
  const { fetchBlobFile, fetchingBlobFile } = useResourceAction(inquirySchema)
  const classes = useStyles()
  const [cancelModalOpen, setCancelModalOpen] = useState(false)

  const { create: createCancelReason } = useResourceAction(cancelReasonSchema)

  const handleCloseCancelModal = () => {
    setCancelModalOpen(false)
  }

  function handleDeleteInquiry() {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      destroyInquiry(match.params.id)
        .then(() => {
          push('/inquiries')
        })
        .catch((error) => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function handleSkipPayment() {
    if (window.confirm(t('skip_payment_confirmation_prompt'))) {
      triggerInquiry(match.params.id, 'skip_payment').catch((error) => {
        setFlashMessage('error', error.response.body.message)
      })
    }
  }

  function handleEnableAutoDistribution() {
    if (window.confirm(t('enable_auto_distribution_confirmation_prompt'))) {
      triggerInquiry(match.params.id, 'enable_auto_distribution').catch((error) => {
        setFlashMessage('error', error.response.body.message)
      })
    }
  }

  function handleEnableManualDistribution() {
    if (window.confirm(t('enable_manual_distribution_confirmation_prompt'))) {
      triggerInquiry(match.params.id, 'enable_manual_distribution').catch((error) => {
        setFlashMessage('error', error.response.body.message)
      })
    }
  }

  function handleCancelInquiry(values) {
    let basePromise = Promise.resolve()

    if (values.cancel_reason_type_id) {
      basePromise = basePromise.then(() => {
        return createCancelReason(values)
      })
    }

    basePromise = basePromise.then(() => {
      return triggerInquiry(match.params.id, 'cancel')
        .then(() => {
          inquiry.get('subscription_month') && handleCancelSubscription()
        })
    })

    return basePromise.catch((error) => {
      setFlashMessage('error', error.response.body.message)
    })
  }

  function handleCancelSubscription() {
    if (window.confirm(t('subscription_cancel_confirmation_prompt'))) {
      triggerSubscription(
        inquiry.getIn(['subscription_month', 'subscription', 'id']),
        'cancel'
      ).catch((error) => {
        setFlashMessage('error', error.response.body.message)
      })
    }
  }

  function handleDownloadReport() {
    fetchBlobFile(inquirySchema, match.params.id, null, {
      childrenType: 'download'
    }).then(({ body }) => {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(body)
      link.download = `${inquiry.get('number')}.pdf`
      link.click()
    })
  }

  function renderSubtitle() {
    let subtitle = [
      <Tag
        value={inquiry.get('state')}
        className='inquiry-states'
        formatter={CapitalizeFormatter}
        key='inquiry-state'
      />
    ]

    if (inquiry.get('deleted_at')) {
      subtitle = [
        ...subtitle,
        <Tag
          value={t('deleted')}
          backgroundColor='#f5474f'
          color='white'
          formatter={CapitalizeFormatter}
          key='deleted'
        />
      ]
    }

    if (inquiry.get('manual_distribution')) {
      subtitle = [
        ...subtitle,
        <Tag
          value={t('manual')}
          backgroundColor='#f5474f'
          color='white'
          formatter={CapitalizeFormatter}
          key='manual'
        />
      ]
    } else if (inquiry.get('manual_distribution') === false) {
      subtitle = [
        ...subtitle,
        <Tag
          value={t('auto')}
          backgroundColor='#18cb88'
          color='white'
          formatter={CapitalizeFormatter}
          key='auto'
        />
      ]
    }

    return subtitle
  }

  function primaryActions() {
    if (!inquiry.get('has_logistics')) {
      return [
        {
          content: 'Download PDF',
          onClick: handleDownloadReport,
          loading: fetchingBlobFile
        }
      ]
    }

    return []
  }

  function secondaryActions() {
    let actions = [
      {
        avatar: <FileCopyRounded />,
        content: t('duplicate'),
        to: `/inquiries/${match.params.id}/duplicate`
      },
      {
        avatar: <EditRounded />,
        content: t('edit'),
        to: `/inquiries/${match.params.id}/edit`
      }
    ]

    if (inquiry.get('state') === 'pending_payment') {
      actions = [
        ...actions,
        {
          avatar: <MoneyOffRounded fontSize='small' />,
          content: t('skip_payment'),
          onClick: handleSkipPayment
        }
      ]
    }

    if (inquiry.get('manual_distribution')) {
      actions = [
        ...actions,
        {
          avatar: <Sync fontSize='small' />,
          content: t('enable_auto_distribution'),
          onClick: handleEnableAutoDistribution
        }
      ]
    } else {
      actions = [
        ...actions,
        {
          avatar: <SyncDisabled fontSize='small' />,
          content: t('enable_manual_distribution'),
          onClick: handleEnableManualDistribution
        }
      ]
    }

    if (REQUEST_EDITABLE_STATES.includes(inquiry.get('state'))) {
      actions = [
        ...actions,
        {
          avatar: <Cancel />,
          content: t('cancel'),
          onClick: () => setCancelModalOpen(true)
        }
      ]
    }

    actions = [
      ...actions,
      {
        avatar: <Delete />,
        content: t('delete'),
        onClick: handleDeleteInquiry
      }
    ]

    return actions
  }

  function renderSubNavbar() {
    const path = `/inquiries/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname} classes={classes.root}>
        <NavigationMenuItem label={t('navbar.overview')} to={path} replace />
        <NavigationMenuItem label={t('navbar.jobs')} to={`${path}/jobs`} replace />
        <NavigationMenuItem label={t('navbar.leads')} to={`${path}/leads`} replace />
        <NavigationMenuItem label={t('navbar.reviews')} to={`${path}/reviews`} replace />
        <NavigationMenuItem
          label={t('navbar.comments')}
          to={`${path}/comments`}
          badge={inquiry.get('comments', 0).size}
          replace
        />
        <NavigationMenuItem
          label={t('navbar.conversations')}
          to={`${path}/conversations`}
          replace
        />
        <NavigationMenuItem
          label={t('navbar.timeline')}
          to={`${path}/timeline`}
          replace
        />
      </NavigationMenuBar>
    )
  }

  return (
    <PageLayout
      title={inquiry.get('number')}
      subtitle={renderSubtitle()}
      actions={secondaryActions()}
      subNavbar={renderSubNavbar()}
      isLoading={loading}
      primaryActions={primaryActions()}
    >
      <Switch>
        <AuthenticatedRoute path='/inquiries/:id' component={InquiryOverviewPane} exact />
        <AuthenticatedRoute
          path='/inquiries/:id/assign_provider'
          component={InquiryAssignProviderPane}
        />
        <AuthenticatedRoute path='/inquiries/:id/jobs' component={InquiryJobsPane} />
        <AuthenticatedRoute path='/inquiries/:id/leads' component={InquiryLeadsPane} />
        <AuthenticatedRoute
          path='/inquiries/:id/reviews'
          component={InquiryReviewsPane}
        />
        <AuthenticatedRoute
          path='/inquiries/:id/comments'
          component={InquiryCommentsPane}
        />
        <AuthenticatedRoute
          path='/inquiries/:id/conversations'
          component={InquiryConversationsPane}
        />
        <AuthenticatedRoute path='/inquiries/:id/timeline' component={InquiryTimeline} />
      </Switch>

      <RequestCancelModal
        isOpen={cancelModalOpen}
        onClose={handleCloseCancelModal}
        owner='Inquiry'
        owner_id={match.params.id}
        onSubmit={handleCancelInquiry}
      />
    </PageLayout>
  )
}

InquiryShowPage.propTypes = {
  inquiry: ImmutablePropTypes.map,
  fetchComments: PropTypes.func,
  fetchInquiry: PropTypes.func,
  fetchReport: PropTypes.func,
  destroyInquiry: PropTypes.func,
  triggerInquiry: PropTypes.func,
  triggerSubscription: PropTypes.func,
  setFlashMessage: PropTypes.func,
  push: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.object
}

InquiryShowPage.defaultProps = {
  inquiry: Map()
}

export default InquiryShowPage
