import { Map } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'

import WalletPaymentForm from './WalletPaymentForm'

const formikConfig = {
  mapPropsToValues: ({ invoice = Map() }) => ({
    amount_satangs: invoice.getIn(['outstanding_balance', 'fractional']) / 100 || '',
    amount_currency: invoice.getIn(['total', 'currency']) || ''
  }),

  validationSchema: Yup.object().shape({
    amount_satangs: Yup.string().required(),
    amount_currency: Yup.string().required()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    values = { ...values, amount_satangs: parseInt((values.amount_satangs * 100).toFixed(0)) }

    props.onSubmit(values)
      .catch(error => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(WalletPaymentForm)
