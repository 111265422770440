import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Paper } from '@material-ui/core'
import { Table, Column } from 'components/tables/Table'
import { DateTag, StateTag } from 'components/tags'

export const JobsOrgTable = ({ jobs }) => {
  const { t, i18n } = useTranslation('organizations')

  return (
    <Paper>
      <Table collection={jobs}>
        <Column
          name='number'
          render={(number, job) => (
            <Link to={`/jobs/${job.get('wfJobId')}`}>{number}</Link>
          )}
        />

        <Column
          name={t('assigned_at')}
          render={(_, job) => <DateTag value={job.get('createdAt')} />}
        />

        <Column
          name='customer'
          render={(_, job) => <p>{job.getIn(['customer', 'fullName'])}</p>}
        />

        <Column
          name='orders'
          render={(_, job) =>
            job
              .get('orders')
              .map((order, key) => (
                <li key={key}>
                  {`${order.get(`name${i18n.language.toUpperCase()}`)} x ${order.get(
                    'qty'
                  )}`}
                </li>
              ))
          }
        />

        <Column name='address' render={(_, job) => <p>{job.get('address')}</p>} />

        <Column
          name={t('start_time')}
          render={(_, job) => <DateTag value={job.get('startTime')} />}
        />

        <Column
          name='payout.display_value'
          render={(_, job) => <p>{job.get('payout')}</p>}
        />

        <Column
          name={t('status')}
          render={(_, job) => <StateTag value={job.get('status')} />}
        />
      </Table>
    </Paper>
  )
}

export default JobsOrgTable
