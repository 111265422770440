import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { CheckCircle, Cancel, EditRounded, Delete } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import Tag from 'components/Tag'
import { CapitalizeFormatter } from 'components/formatters'

import {
  CompanyAddressesPane,
  CompanyCommentsPane,
  CompanyCustomersPane,
  CompanyInquiriesPane,
  CompanyOverviewPane,
  CompanyBranchesPane
  // CompanySubscriptionsPane
} from './panes'

export function CompanyShowPage ({
  company,
  location,
  match,
  t,
  fetchCompany,
  setFlashMessage,
  destroy,
  push,
  updateCompanyState
}) {
  const { loading } = useData(() => fetchCompany(match.params.id))

  function handleDestroy () {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      destroy(match.params.id)
        .then(response => {
          push(`/companies`)
        })
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function handleUpdateCompanyState (event) {
    if (window.confirm(t(`${event}_confirmation_prompt`))) {
      return updateCompanyState(match.params.id, event)
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function secondaryActions () {
    const editAction = {
      avatar: <EditRounded />,
      content: t('edit'),
      to: `/companies/${match.params.id}/edit`
    }

    const deleteAction = {
      avatar: <Delete />,
      content: t('delete'),
      onClick: handleDestroy
    }

    const approveAction = {
      avatar: <CheckCircle fontSize='small' />,
      content: t('approve'),
      onClick: () => handleUpdateCompanyState('approve')
    }

    const rejectAction = {
      avatar: <Cancel fontSize='small' />,
      content: t('reject'),
      onClick: () => handleUpdateCompanyState('reject')
    }

    switch (company.get('state')) {
      case 'pending': return [editAction, approveAction, rejectAction, deleteAction]
      case 'approved': return [editAction, rejectAction, deleteAction]
      case 'rejected': return [editAction, approveAction, deleteAction]
      default: return [editAction, deleteAction]
    }
  }

  function subNavbar () {
    const path = `/companies/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('common:navbar.overview')} to={path} />
        <NavigationMenuItem label={t('inquiries')} to={`${path}/inquiries`} />
        <NavigationMenuItem label={t('addresses')} to={`${path}/addresses`} />
        <NavigationMenuItem label={t('customers')} to={`${path}/customers`} />
        <NavigationMenuItem label={t('branches')} to={`${path}/branches`} />
        {/* <NavigationMenuItem label={t('subscriptions')} to={`${path}/subscriptions`} /> */}
        <NavigationMenuItem label={t('comments')} to={`${path}/comments`} />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs () {
    return [{
      text: t('title'),
      link: '/companies'
    }, {
      text: company.get('name')
    }]
  }

  return (
    <PageLayout
      title={company.get('name')}
      subtitle={
        <Tag
          value={company.get('state')}
          className='company-states'
          formatter={CapitalizeFormatter}
        />
      }
      actions={secondaryActions()}
      subNavbar={subNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/companies/:id' component={CompanyOverviewPane} exact />
        <AuthenticatedRoute path='/companies/:id/inquiries' component={CompanyInquiriesPane} />
        <AuthenticatedRoute path='/companies/:id/addresses' component={CompanyAddressesPane} />
        <AuthenticatedRoute path='/companies/:id/customers' component={CompanyCustomersPane} />
        <AuthenticatedRoute path='/companies/:id/branches' component={CompanyBranchesPane} />
        {/* <AuthenticatedRoute path='/companies/:id/subscriptions' component={CompanySubscriptionsPane} /> */}
        <AuthenticatedRoute path='/companies/:id/comments' component={CompanyCommentsPane} />
      </Switch>
    </PageLayout>
  )
}

CompanyShowPage.propTypes = {
  company: ImmutablePropTypes.map,
  location: PropTypes.object,
  match: PropTypes.object,
  t: PropTypes.func,
  fetchCompany: PropTypes.func,
  setFlashMessage: PropTypes.func,
  destroy: PropTypes.func,
  push: PropTypes.func,
  updateCompanyState: PropTypes.func
}

CompanyShowPage.defaultProps = {
  company: Map()
}

export default withTranslation(['companies', 'actions', 'common'])(CompanyShowPage)
