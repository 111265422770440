import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Map, List } from 'immutable'

import {
  fetch as fetchCollection,
  fetchNode as fetchNodeCollection,
  fetchChildren,
  fetchNodeChildren
} from 'actions/collections'
import { getCollection, getTotalCount, getTotalPages } from 'selectors/collections'
import { getResource } from 'selectors/resources'

export const useCollection = (schema, queryParams = {}, effectDependencies = []) => {
  const [response, setResponse] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const dispatch = useDispatch()

  const collection = useSelector((state) => getCollection(state, schema)) || List()
  const totalCount = useSelector((state) => getTotalCount(state, schema)) || 0
  const totalPages = useSelector((state) => getTotalPages(state, schema)) || 1

  useEffect(() => {
    let isMounted = true

    setLoading(true)

    const request = dispatch(fetchCollection(schema, queryParams))

    request
      .then((response) => {
        if (isMounted) {
          setResponse(response)
          setLoading(false)
        }
      })
      .catch((error) => {
        if (isMounted) {
          setError(error)

          setLoading(false)
        }
      })

    return () => (isMounted = false)
  }, [...effectDependencies]) // eslint-disable-line react-hooks/exhaustive-deps

  return [collection, { loading, error, response, totalCount, totalPages }]
}

export const useNodeCollection = (
  schema,
  queryParams = {},
  effectDependencies = [],
  options = {}
) => {
  const [response, setResponse] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const dispatch = useDispatch()

  const collection =
    useSelector((state) => getCollection(state, schema, options.cacheKey)) || List()
  const totalCount = useSelector((state) => getTotalCount(state, schema)) || 0
  const totalPages =
    useSelector((state) => getTotalPages(state, schema, options.cacheKey)) || 1

  useEffect(() => {
    let isMounted = true

    setLoading(true)

    const request = dispatch(fetchNodeCollection(schema, queryParams, options.cacheKey))

    request
      .then((response) => {
        if (isMounted) {
          setResponse(response)
          setLoading(false)
        }
      })
      .catch((error) => {
        if (isMounted) {
          setError(error)

          setLoading(false)
        }
      })

    return () => (isMounted = false)
  }, [...effectDependencies]) // eslint-disable-line react-hooks/exhaustive-deps

  return [collection, { loading, error, response, totalPages, totalCount }]
}

export const useCollectionChildren = (
  parentSchema,
  parentId,
  childrenSchema,
  queryParams = {},
  effectDependencies = []
) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [totalCount, setTotalCount] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const dispatch = useDispatch()

  const parentResource =
    useSelector((state) => getResource(state, parentId, parentSchema)) || Map()
  const collectionChildren = parentResource.get(childrenSchema._key) || List()

  useEffect(() => {
    let isMounted = true

    setLoading(true)
    dispatch(fetchChildren(parentSchema, parentId, childrenSchema, queryParams))
      .then(({ headers }) => {
        if (isMounted) {
          setTotalCount(parseInt(headers.total, 10))
          setTotalPages(Math.ceil(headers.total / headers['per-page']))

          setLoading(false)
        }
      })
      .catch((error) => {
        if (isMounted) {
          setError(error)

          setLoading(false)
        }
      })

    return () => (isMounted = false)
  }, [...effectDependencies]) // eslint-disable-line react-hooks/exhaustive-deps

  return [collectionChildren, { loading, error, totalCount, totalPages }]
}

export const useCollectionNodeChildren = (
  parentSchema,
  parentId,
  childrenSchema,
  queryParams = {},
  effectDependencies = []
) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [totalCount, setTotalCount] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const dispatch = useDispatch()

  const parentResource =
    useSelector((state) => getResource(state, parentId, parentSchema)) || Map()
  const collectionChildren = parentResource.get(childrenSchema._key) || List()

  useEffect(() => {
    let isMounted = true

    setLoading(true)
    dispatch(fetchNodeChildren(parentSchema, parentId, childrenSchema, queryParams))
      .then(({ headers }) => {
        if (isMounted) {
          setTotalCount(parseInt(headers.total, 10))
          setTotalPages(Math.ceil(headers.total / headers['per-page']))

          setLoading(false)
        }
      })
      .catch((error) => {
        if (isMounted) {
          setError(error)

          setLoading(false)
        }
      })

    return () => (isMounted = false)
  }, [...effectDependencies]) // eslint-disable-line react-hooks/exhaustive-deps

  return [collectionChildren, { loading, error, totalCount, totalPages }]
}
