export default ({ typography, spacing }) => {
  const primary = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }

  const primaryPlaceholder = {
    ...primary,
    justifyContent: 'center',
    height: 40
  }

  return {
    root: {
      paddingRight: 0
    },

    primary,

    primaryPlaceholder,

    primaryPlaceholderTall: {
      ...primaryPlaceholder,
      height: 52
    },

    secondary: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },

    stateClose: {
      ...typography.caption,
      padding: `${spacing(0.25)}px ${spacing(0.5)}px`,
      marginLeft: spacing(1)
    },

    stateOpen: {
      color: 'green'
    },

    noConversation: {
      height: 140,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}
