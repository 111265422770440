import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { BusinessRounded } from '@material-ui/icons'

import { useNodeCollection } from 'hooks/collections'
import useQuery from 'hooks/useQuery'

import PageLayout from 'components/layouts/PageLayout'
import CircularLoader from 'components/CircularLoader'
import Search from 'components/Search'
import OrganizationsTable from 'components/tables/OrganizationsTable'
import Pagination from 'components/Pagination'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'

import { organizationSchema } from '@seekster/schemas'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  },
  button: {
    height: '48px'
  }
}))

export function OrganizationIndexPage() {
  const classes = useStyles()
  const [query] = useQuery()
  const { t } = useTranslation('organizations')

  const [
    organizations,
    { loading, totalPages, totalCount }
  ] = useNodeCollection(
    organizationSchema,
    { url: '/organizations', page: query.page, query },
    [query]
  )

  function primaryActions() {
    return [
      {
        avatar: <BusinessRounded fontSize='small' />,
        content: t('new'),
        to: '/organizations/new'
      }
    ]
  }

  return (
    <PageLayout
      title={t('title')}
      subtitle={t('sub_title')}
      primaryActions={primaryActions()}
    >
      <div className={classes.searchContainer}>
        <Search withButton />
      </div>

      {loading ? (
        <CircularLoader />
      ) : (
          <>
            <div className={classes.metaContainer}>
              <ResultsCountLabel value={totalCount} unit='organization' />
              <OrganizationsTable organizations={organizations} />
            </div>

            <Grid item xs={12} container justify='center'>
              <Pagination totalPages={totalPages} />
            </Grid>
          </>
        )}
    </PageLayout>
  )
}

export default OrganizationIndexPage
