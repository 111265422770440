import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { Table, Column } from 'components/tables/Table'
import { Paper, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment-duration-format'
import { DateTag, PhoneTag, StateTag } from 'components/tags'

const SLAPendingStartTable = ({ pendingProvider, t }) => {
  return (
    <Paper>
      <Table collection={pendingProvider}>
        <Column
          name='overdue_sla'
          render={(_, job) => {
            const leadCreatedAt = job.get('lead_created_at')
            const leadAcceptedAt = job.get('lead_accepted_at')
            let overdueTime = 0
            if (!leadAcceptedAt) {
              overdueTime = moment().diff(leadCreatedAt, 'minutes')
            } else if (moment(leadAcceptedAt).isValid()) {
              overdueTime = moment(leadAcceptedAt).diff(leadCreatedAt, 'minutes')
            }
            return (
              <Typography color='error' variant='inherit'>
                {moment
                  .duration(overdueTime, 'minutes')
                  .format(`d [${t('day')}] h [${t('hr')}]  m [${t('min')}]`)}
              </Typography>
            )
          }}
        />
        <Column
          name='booking_date'
          render={(_, job) =>
            moment(job.get('start_time')).format('ddd, D MMM YYYY - HH:mm')
          }
        />
        <Column name='state' component={StateTag} />

        <Column
          name='number'
          render={(number, job) => (
            <p>
              <Link to={`/sla_pending_start/${job.get('id')}`}>{number}</Link>
            </p>
          )}
        />
        <Column
          name='customer'
          render={(customer, job) => (
            <>
              <p>
                <Link to={`/customers/${customer.get('id')}`}>
                  {customer.get('display_name')}
                </Link>
              </p>
              <p>
                <PhoneTag value={job.getIn(['location', 'phone_number'])} />
              </p>
            </>
          )}
        />
        <Column name='location.short_address' />
        <Column
          name='job_providers'
          render={(jobProviders, job) => {
            return jobProviders
              .filter(
                (jobProvider) =>
                  jobProvider.get('state') !== 'cancelled' &&
                  jobProvider.get('state') !== 'rejected'
              )
              .map((jobProvider) => (
                <Link
                  to={`/providers/${jobProvider.getIn(['provider', 'id'])}`}
                  key={jobProvider.getIn(['provider', 'id'])}
                >
                  <div>{jobProvider.getIn(['provider', 'display_name'])}</div>
                  <div>{jobProvider.getIn(['provider', 'phone_number'])}</div>
                </Link>
              ))
          }}
        />
        <Column name='created_at' component={DateTag} />
      </Table>
    </Paper>
  )
}

SLAPendingStartTable.propTypes = {
  pendingProvider: ImmutablePropTypes.list,
  t: PropTypes.func
}

SLAPendingStartTable.defaultProps = {
  pendingProvider: List(),
  t: () => {}
}
export default SLAPendingStartTable
