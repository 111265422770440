import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Paper } from '@material-ui/core'

import { Table, Column } from 'components/tables/Table'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'

export function AgentInvitationsPane ({ agent, fetchInvitations, match }) {
  useEffect(() => {
    fetchInvitations(match.params.id)
  }, [fetchInvitations, match.params.id])

  return (
    <Paper>
      <Table collection={agent.get('invitations')}>
        <Column
          name='link'
          style={{ maxWidth: 300, overflowWrap: 'break-word' }}
          render={link => (
            <a href={link} target='_blank' rel='noopener noreferrer'>{link}</a>
          )}
        />
        <Column name='token' style={{ maxWidth: 300, overflowWrap: 'break-word' }} />
        <Column name='expires_at' component={DateTimeFormatter} />
        <Column name='claimed_at' component={DateTimeFormatter} />
        <Column name='created_at' component={DateTimeFormatter} />
      </Table>
    </Paper>
  )
}

AgentInvitationsPane.propTypes = {
  agent: ImmutablePropTypes.map,
  fetchInvitations: PropTypes.func,
  match: PropTypes.object
}

AgentInvitationsPane.defaultProps = {
  agent: Map(),

  fetchInvitations: () => {}
}

export default AgentInvitationsPane
