import { connect } from 'react-redux'

import { fetch as fetchCollection, fetchChildren } from 'actions/collections'
import { create } from 'actions/resources'

import ResourceCreatableSelect from './ResourceCreatableSelect'

const mapDispatchToProps = {
  fetchOptions: (schema, query) => fetchCollection(schema, { query: { search: query } }),
  fetchChildren: (parentSchema, parentId, schema, options) => fetchChildren(parentSchema, parentId, schema, options),
  createResource: (data, schema, options) => create(data, schema, options)
}

export default connect(null, mapDispatchToProps)(ResourceCreatableSelect)
