import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  appBar: {
    backgroundColor: 'inherit',
    marginBottom: '1rem'
  },

  logo: {
    display: 'block',
    height: '30px'
  },

  NavigationBar: {
    position: 'fixed',
    right: '1rem'
  },

  text: {
    color: '#000 !important',
    textTransform: 'none'
  }
}))
