import React, { useEffect, useRef, useState } from 'react'

import './GoogleMapRoute.scss'

const riderHead = 'https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/head-rider.png'

const customMapStyles = [
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 17
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2'
      },
      {
        lightness: 19
      }
    ]
  }
]

export function GoogleRouteMap ({ zoom, route, location, currentLocation }) {
  const [map, setMap] = useState()
  const mapRef = useRef()

  useEffect(() => {
    const renderGoogleMap = () => {
      setMap(new window.google.maps.Map(mapRef.current, {
        zoom,
        styles: customMapStyles,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false
      }))
    }

    if (renderGoogleMap.google && window.google) {
      renderGoogleMap()
    }
    else {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places,drawing,geometry`
      document.head.append(script)
      script.addEventListener('load', renderGoogleMap)
      return () => script.removeEventListener('load', renderGoogleMap)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds()

      if (!route.isEmpty()) {
        // decoded and draw polyline
        const decodningPath = window.google.maps.geometry.encoding.decodePath(route.get('encoded_polyline'))

        const line = new window.google.maps.Polyline({
          path: decodningPath,
          strokeColor: '#3682EF',
          strokeOpacity: 1.0,
          strokeWeight: 4
        })

        line.setMap(map)

        const originIcon = {
          url: 'https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/icon/blue-marker.svg',
          scaledSize: new window.google.maps.Size(40, 40),
          origin: new window.google.maps.Point(0, 0),
          labelOrigin: new window.google.maps.Point(20, 16)
        }

        // draw markers
        const originMaker = new window.google.maps.Marker({
          position: { lat: route.getIn(['origin', 'latitude']), lng: route.getIn(['origin', 'longitude']) },
          map,
          animation: window.google.maps.Animation.DROP,
          icon: originIcon,
          label: {
            text: 'A',
            color: 'GREEN',
            fontSize: '10px',
            fontWeight: 'bold'
          }
        })

        originMaker.addListener('click', () => {
          alert(`origin : ${route.getIn(['origin', 'full_address'])}`)
        })

        const destinationIcon = {
          url: 'https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/icon/red-marker.svg',
          scaledSize: new window.google.maps.Size(40, 40),
          origin: new window.google.maps.Point(0, 0),
          labelOrigin: new window.google.maps.Point(20, 16)
        }

        const destinationMaker = new window.google.maps.Marker({
          position: { lat: route.getIn(['destination', 'latitude']), lng: route.getIn(['destination', 'longitude']) },
          map,
          animation: window.google.maps.Animation.DROP,
          icon: destinationIcon,
          label: {
            text: 'B',
            color: 'GREEN',
            fontSize: '10px',
            fontWeight: 'bold'
          }
        })

        destinationMaker.addListener('click', () => {
          alert(`destination : ${route.getIn(['destination', 'full_address'])}`)
        })

        bounds.extend(new window.google.maps.LatLng(route.getIn(['bounds', 'northeast', 'lat']), route.getIn(['bounds', 'northeast', 'lng'])))
        bounds.extend(new window.google.maps.LatLng(route.getIn(['bounds', 'southwest', 'lat']), route.getIn(['bounds', 'southwest', 'lng'])))

        map.fitBounds(bounds)
      }
      else {
        const originMaker = new window.google.maps.Marker({
          position: { lat: location.get('latitude'), lng: location.get('longitude') },
          map
        })

        originMaker.addListener('click', () => {
          alert(`location : ${location.get('full_address')}`)
        })

        const center = new window.google.maps.LatLng(location.get('latitude'), location.get('longitude'))

        map.setCenter(center)
      }
    }
  }, [map]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentLocation !== null) {
      const currentLocationIcon = {
        url: riderHead,
        scaledSize: new window.google.maps.Size(40, 40),
        origin: new window.google.maps.Point(0, 0),
        labelOrigin: new window.google.maps.Point(20, 16)
      }

      const currentLocationIconMaker = new window.google.maps.Marker({
        position: { lat: currentLocation.latitude, lng: currentLocation.longitude },
        map,
        animation: window.google.maps.Animation.DROP,
        icon: currentLocationIcon
      })

      currentLocationIconMaker.addListener('click', () => {
        alert('provider location')
      })
    }
  }, [currentLocation]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='google-map-container'>
      <div className='google-map' ref={mapRef} id='map' />
    </div>
  )
}

GoogleRouteMap.defaultProps = {
  zoom: 12
}

export default GoogleRouteMap
