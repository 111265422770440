import { connect } from 'react-redux'

import { getResource } from 'selectors/resources'
import { create, fetch as fetchResource } from 'actions/resources'
import {
  addressSchema,
  customerSchema,
  companySchema,
  serviceSchema
} from '@seekster/schemas'

import InquiryFormController from './InquiryFormController'

const mapStateToProps = (state) => ({
  getLineItems: (id) => {
    if (getResource(state, id, serviceSchema)) {
      return getResource(state, id, serviceSchema).get('packages')
    }
  }
})

const mapDispatchToProps = {
  createCustomerAddress: (data, id, options) =>
    create(data, customerSchema, { type: `customers/${id}/addresses` }),
  createCompanyAddress: (data, id, options) =>
    create(data, companySchema, { type: `companies/${id}/addresses` }),
  fetchAddress: (id) => fetchResource(id, addressSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(InquiryFormController)
