import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { EditRounded, Delete } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import { ProviderStrengthOverviewPane } from './panes'

export function ProviderStrengthShowPage ({
  providerStrength,
  fetchProviderStrength,
  deleteProviderStrength,
  setFlashMessage,
  push,
  match,
  location,
  t
}) {
  const { loading } = useData(() => fetchProviderStrength(match.params.id))

  function handleDeleteProviderStrength () {
    if (window.confirm(`${t('delete_confirmation_prompt')}`)) {
      deleteProviderStrength(match.params.id)
        .then(push(`/provider_strengths`))
        .catch(error => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function secondaryActions () {
    return [{
      avatar: <EditRounded />,
      content: t('edit'),
      to: `/provider_strengths/${match.params.id}/edit`
    }, {
      avatar: <Delete />,
      content: t('delete'),
      onClick: handleDeleteProviderStrength
    }]
  }

  function renderSubNavbar () {
    const path = `/provider_strengths/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('panes.overview')} to={path} />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs () {
    return [
      { text: t('title'), link: '/provider_strengths' },
      { text: providerStrength.get('name') }
    ]
  }

  return (
    <PageLayout
      title={providerStrength.get('name')}
      actions={secondaryActions()}
      subNavbar={renderSubNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute
          path='/provider_strengths/:id'
          component={ProviderStrengthOverviewPane}
          exact
        />
      </Switch>
    </PageLayout>
  )
}

ProviderStrengthShowPage.propTypes = {
  providerStrength: ImmutablePropTypes.map,
  fetchProviderStrength: PropTypes.func,
  deleteProviderStrength: PropTypes.func,
  setFlashMessage: PropTypes.func,
  push: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,

  t: PropTypes.func
}

ProviderStrengthShowPage.defaultProps = {
  providerStrength: Map()
}

export default withTranslation('provider_strengths')(ProviderStrengthShowPage)
