import React from 'react'
import { DomainRounded } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useQuery from 'hooks/useQuery'
import { useCollection } from 'hooks/collections'
import PageLayout from 'components/layouts/PageLayout'
import Search from 'components/Search'
import { Paper, CircularProgress, Grid } from '@material-ui/core'
import { departmentSchema } from '@seekster/schemas'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import { Table, Column } from 'components/tables/Table'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

export function DepartmentIndexPage () {
  const classes = useStyles()
  const [query] = useQuery()
  const { t } = useTranslation('departments')
  const [departments, {
    loading, totalCount, totalPages
  }] = useCollection(departmentSchema, { page: query.page, query }, [query])

  function primaryActions () {
    return [{
      avatar: <DomainRounded fontSize='small' />,
      content: t('new'),
      to: '/departments/new'
    }]
  }

  return (
    <PageLayout title={t('title')} primaryActions={primaryActions()}>
      <div className={classes.searchContainer}>
        <Search />
      </div>

      {
        loading ? (
          <CircularProgress color='secondary' />
        ) : (
          <>
            <div className={classes.metaContainer}>
              <ResultsCountLabel value={totalCount} unit='subscription' style={{ margin: 0 }} />

              <Paper square>
                <Table collection={departments}>
                  <Column name='id' />
                  <Column
                    name='name'
                    render={(name, department) => (
                      <p>
                        <Link to={`/departments/${department.get('id')}`}>{name}</Link>
                      </p>
                    )}
                  />
                  <Column name='created_at' component={DateTimeFormatter} />
                </Table>
              </Paper>
            </div>
            <Grid item xs={12} container justify='center'>
              <Pagination totalPages={totalPages} />
            </Grid>
          </>
        )
      }
    </PageLayout>
  )
}

export default DepartmentIndexPage
