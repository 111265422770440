import React from 'react'
import { Map } from 'immutable'

import { Drawer, withMobileDialog } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { tenantSchema } from '@seekster/schemas'

import { getCurrentResource } from 'selectors/authentication'
import { useSidebar } from 'hooks/interface'

import DrawerMenus from './DrawerMenus'
import useStyles from './SideNavDrawerStyles'

export function SideNavDrawer({ fullScreen: isMobile }) {
  const { sidebarOpen, closeSidebar } = useSidebar()
  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()

  const classes = useStyles()

  return (
    <Drawer
      className={isMobile ? classes.drawerMobile : classes.drawer}
      variant={isMobile ? 'temporary' : 'persistent'}
      anchor='left'
      open={sidebarOpen}
      classes={{ paper: classes.drawerPaper }}
      onClose={closeSidebar}
    >
      {isMobile ? (
        <img
          src={tenant.get('dark_logo_url')}
          alt={tenant.get('name')}
          className={classes.logo}
        />
      ) : (
        <div className={classes.toolbar} />
      )}
      <DrawerMenus closeDrawerOnSelect={isMobile} />
    </Drawer>
  )
}

export default withMobileDialog()(SideNavDrawer)
