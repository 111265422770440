import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageLayout, PageContent } from 'components/layouts'

import { NavigationMenuBar, NavigationMenuItem } from 'components/layouts/NavigationMenuBar'

export function TestPage ({ location }) {
  const { t } = useTranslation('navbar')

  function renderSubNavbar () {
    const path = `/test`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('navbar.overview')} to={path} replace />
        <NavigationMenuItem label={t('navbar.jobs')} to={`${path}/jobs`} replace />
        <NavigationMenuItem label={t('navbar.leads')} to={`${path}/leads`} replace />
        <NavigationMenuItem label={t('navbar.reviews')} to={`${path}/reviews`} replace />
        <NavigationMenuItem label={t('navbar.conversations')} to={`${path}/conversations`} replace />
        <NavigationMenuItem label={t('navbar.timeline')} to={`${path}/timeline`} replace />
      </NavigationMenuBar>
    )
  }

  return (
    <PageLayout title='Test Page' subNavbar={renderSubNavbar()}>
      <PageContent position='left'>
        Left Content
      </PageContent>

      <PageContent position='center'>
        Center Content
      </PageContent>

      <PageContent position='right'>
        Right Content
      </PageContent>
    </PageLayout>
  )
}

export default TestPage
