import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Map, fromJS } from 'immutable'

import { makeStyles } from '@material-ui/core/styles'

import PayrollsStatementTable from 'components/tables/PayrollsStatementTable'

import { payrollSchema, payrollStatementSchema } from '@seekster/schemas'
import PageLayout from 'components/layouts/PageLayout'

import { useResourceActionChildWithId } from 'hooks/resources'

const useStyles = makeStyles(({ spacing, shape }) => ({
  root: {
    backgroundColor: '#f3f8fe',
    padding: spacing(0.5, 1),
    border: '1px solid #4e8ac8',
    borderRadius: shape.borderRadius,
    color: '#4e8ac8'
  },
  searchContainer: {
    padding: 24
  },

  metaContainer: {
    padding: 24
  },
  button: {
    margin: 18,
    padding: '10px 16px'
  },
  panelShadow: {
    boxShadow: '0 2px 10px 0 rgba(182,182,182,0.4)'
  }
}))

export function PayrollStatementPane ({ match }) {
  const classes = useStyles()
  const [fetchPayroll, { loading }] = useResourceActionChildWithId(payrollSchema, match.params.id, payrollStatementSchema, match.params.statement_id)
  const [payrollStatement, setPayrollStatement] = useState(Map())

  useEffect(() => {
    fetchPayroll()
      .then(response => setPayrollStatement(fromJS(response.body)))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageLayout
      title={payrollStatement.getIn(['wallet_transactions', 0, 'bank_account', 'account_name'])}
      isLoading={loading}
      back={`/payrolls/${match.params.id}`}
    >
      <Grid container>
        <Grid item xs={12} sm={12} style={{ paddingRight: 0 }}>
          <div className={classes.metaContainer}>
            <div className={classes.panelShadow}>
              <PayrollsStatementTable walletTransactions={payrollStatement.get('wallet_transactions')} />
            </div>
          </div>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

PayrollStatementPane.propTypes = {
  match: PropTypes.object
}

export default PayrollStatementPane
