import React from 'react'
import PropTypes from 'prop-types'

export class PhoneTag extends React.Component {
  static propTypes = {
    value: PropTypes.string
  }

  render () {
    if (this.props.value) {
      return (
        <a href={`tel:${this.props.value.replace(/-/g, '')}`}>
          {this.props.value}
        </a>
      )
    }
    else {
      return null
    }
  }
}

export default PhoneTag
