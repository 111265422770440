import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { cancelReasonTypeSchema } from '@seekster/schemas'
import { useCollection } from 'hooks/collections'
import { useResourceAction } from 'hooks/resources'

import CancellationForm from 'forms/resources/CancellationForm'

export const CancellationSettingsPane = () => {
  const [update, setUpdate] = useState(false)
  const [cancelReasonTypes] = useCollection(cancelReasonTypeSchema, {}, [update])
  const { create: createCancelReasonType } = useResourceAction(cancelReasonTypeSchema)

  const handleSubmit = (values, { setSubmitting }) => {
    var i
    for(i = 0; i < values.cancel_reason_types_attributes.length; i++){
        values.cancel_reason_types_attributes[i].cancel_reason_values_attributes = values.cancel_reason_types_attributes[i]['cancel_reason_values']
        delete values.cancel_reason_types_attributes[i].cancel_reason_values
    }
    const result = createCancelReasonType(values).then(() => setUpdate(!update))
    setUpdate(!update)
    alert('Update Success')

    return result
  }

  return (
    <>
      <Grid container>
        <CancellationForm cancelReasonTypes={cancelReasonTypes} onSubmit={handleSubmit} />
      </Grid>
    </>
  )
}

export default CancellationSettingsPane
