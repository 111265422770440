import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Grid } from '@material-ui/core'

import Field from 'components/fields/Field'

import './UserPropertyForm.scss'

export class UserPropertyForm extends React.Component {
  static propTypes = {
    userProperty: PropTypes.object,

    dirty: PropTypes.bool,
    submitForm: PropTypes.func
  }

  static defaultProps = {
    userProperty: {}
  }

  constructor (props) {
    super(props)

    this.handleSubmitForm = this.handleSubmitForm.bind(this)
    this.handlePressEnter = this.handlePressEnter.bind(this)
  }

  handleSubmitForm () {
    this.props.dirty && this.props.submitForm()
  }

  handlePressEnter (e) {
    if (e.keyCode === 13) {
      e.preventDefault()

      // handleSubmitForm gets called onBlur
      e.target.blur()
    }
  }

  render () {
    return (
      <Form>
        <Grid container className='user-property'>
          <Grid item xs={5}>
            <Field
              name='property_definition_attributes.name'
              noLabel
              onBlur={this.handleSubmitForm}
              onKeyDown={this.handlePressEnter}
              disabled={!!this.props.userProperty.property}
            />
          </Grid>

          <Grid item xs={7}>
            <Field
              name='value'
              noLabel
              onBlur={this.handleSubmitForm}
              onKeyDown={this.handlePressEnter}
            />
          </Grid>
        </Grid>
      </Form>
    )
  }
}

export default UserPropertyForm
