import React from 'react'
import { useTranslation } from 'react-i18next'

import { useResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'
import { departmentSchema } from '@seekster/schemas'

import PageLayout from 'components/layouts/PageLayout'
import DepartmentForm from 'forms/resources/DepartmentForm'

export function DepartmentEditPage ({ match }) {
  const { t } = useTranslation('departments')
  const [department, { loading }] = useResource(departmentSchema, match.params.id)
  const { update: updateDepartment } = useResourceAction(departmentSchema)
  const push = usePush()

  function handleSubmit (data) {
    return updateDepartment(match.params.id, data)
  }

  return (
    <PageLayout
      title={t('edit')}
      isLoading={loading}
      back={`/departments/${match.params.id}`}
    >
      <DepartmentForm
        department={department}
        onSubmit={handleSubmit}
        push={push}
      />
    </PageLayout>
  )
}

export default DepartmentEditPage
