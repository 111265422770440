import React from 'react'
import { getIn, connect } from 'formik'

import { Grid } from '@material-ui/core'
import { Field } from 'components/fields/Field'
import FormSection from 'components/fields/FieldArray/FormSection'
import ResourceSelect from 'components/inputs/ResourceSelect'
import { customerSchema } from '@seekster/schemas'

export function CompanyBranchFormSection (props) {
  const { name, formik } = props
  const values = getIn(formik.values, name)

  return (
    <FormSection title={values.name} {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field noLabel name={`${name}.name`} required />
        </Grid>

        <Grid item xs={12}>
          <Field
            name={`${name}.customer_ids`}
            component={ResourceSelect}
            schema={customerSchema}
            isMulti
          />
        </Grid>
      </Grid>
    </FormSection>
  )
}

export default connect(CompanyBranchFormSection)
