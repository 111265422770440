import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router-dom'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton
} from '@material-ui/core'
import { DragIndicator } from '@material-ui/icons'

const DragHandle = SortableHandle((props) => <DragIndicator {...props} />)

const SortableItem = SortableElement(({ resource, title, subtitle, secondaryTitle }) => (
  <ListItem id={resource.get('id')}>
    <ListItemIcon>
      <IconButton style={{ cursor: 'grab' }}>
        <DragHandle />
      </IconButton>
    </ListItemIcon>

    <ListItemText
      primary={<Link to={`/${resource.get('type')}/${resource.get('id')}`}>{title}</Link>}
      secondary={subtitle}
    />

    {secondaryTitle}
  </ListItem>
))

function renderValue(value, resource) {
  if (typeof value === 'string') {
    return resource.get(value, value)
  } else if (typeof value === 'function') {
    return value(resource)
  } else if (React.isValidElement(value)) {
    return value
  }
}

function SortableList({ collection, title, subtitle, secondaryTitle }) {
  return (
    <MuiList>
      {collection.map((resource, i) => (
        <SortableItem
          key={resource.get('id')}
          index={i}
          resource={resource}
          title={renderValue(title, resource)}
          subtitle={renderValue(subtitle, resource)}
          secondaryTitle={renderValue(secondaryTitle, resource)}
        />
      ))}
    </MuiList>
  )
}

SortableList.propTypes = {
  collection: ImmutablePropTypes.list,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
  secondaryTitle: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.element
  ])
}

export default SortableContainer(SortableList)
