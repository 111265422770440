import React, { useState } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router-dom'
import { List } from 'immutable'
import { Table, Column } from 'components/tables/Table'
import Tag from 'components/Tag'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import Modal from 'components/containers/Modal'
import { ResponsiveMenu, ResponsiveMenuItem } from 'components/menus/ResponsiveMenu'
import { MoreVert, OpenInNew } from '@material-ui/icons'

import VoidTransactionForm from 'forms/actions/VoidTransactionForm'
import ApproveTransactionForm from 'forms/actions/ApproveTransactionForm'
import { IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useResourceAction } from 'hooks/resources'
import { transactionSchema } from '@seekster/schemas'
import { LightboxImage } from 'components/images'

export function WalletTransactionList({ transactions, isProvider }) {
  const [voidModalOpen, setVoidModalOpen] = useState(false)
  const [approveModalOpen, setApproveModalOpen] = useState(false)
  const [transactionId, setTransactionId] = useState()
  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { t } = useTranslation(['wallets'])
  const { triggerWithData: triggerTransaction } = useResourceAction(transactionSchema)
  const stateWithActions = ['holding', 'processing', 'withdraw']
  const stateWithVoidActions = ['holding', 'processing']
  const stateWithManualApproveActions = ['withdraw', 'processing']

  function handleCloseMenu() {
    setMenuOpen(false)
    setAnchorEl(null)
  }

  function handleOpenMenu(e) {
    setMenuOpen(true)
    setAnchorEl(e.currentTarget)
  }

  function handleOpenVoidModal(id) {
    setTransactionId(id)
    setVoidModalOpen(true)
  }

  function handleOpenAppoveModal(id) {
    setTransactionId(id)
    setApproveModalOpen(true)
  }

  return (
    <>
      <Table collection={transactions}>
        <Column name='id' />
        <Column name='type' />
        {isProvider && (
          <Column
            name='job_id'
            render={(jobId) => <Link to={`/jobs/${jobId}`}>{jobId}</Link>}
          />
        )}
        <Column name='transaction_type' />
        <Column
          name='transaction_code'
          render={(transactionCode) => transactionCode?.get('code')}
        />
        <Column
          name='state'
          render={(value) => <Tag value={value} className='wallet-transaction-states' />}
        />
        <Column
          name='amount'
          textAlign='right'
          render={(amount) => amount.get('full_display')}
        />
        <Column name='fee' textAlign='right' render={(fee) => fee.get('full_display')} />
        <Column
          name='description'
          render={(description, transaction) => (
            <>
              {description}

              {transaction.get('image_url') && (
                <LightboxImage
                  source={{
                    src: transaction.get('image_url'),
                    thumbnail: transaction.get('image_url')
                  }}
                />
              )}
            </>
          )}
        />
        <Column
          name='bank_account'
          render={(bankAccount) =>
            bankAccount &&
            `${bankAccount.get('account_name')} [${bankAccount.get(
              'formatted_account_number'
            )}]`
          }
        />
        <Column
          name='issued_by'
          render={(agent, transaction) =>
            agent && !agent.isEmpty()
              ? `${agent.get('display_name')} (${transaction.get('issued_by_type')})`
              : 'System'
          }
        />
        <Column name='released_at' component={DateTimeFormatter} />
        <Column name='created_at' component={DateTimeFormatter} />
        <Column name='captured_at' component={DateTimeFormatter} />
        <Column
          name='state'
          columnName='Actions'
          render={(state, transaction) =>
            stateWithActions.includes(state) && (
              <div>
                <ResponsiveMenu
                  open={menuOpen}
                  onClose={handleCloseMenu}
                  anchorEl={anchorEl}
                  trigger={
                    <IconButton onClick={handleOpenMenu}>
                      <MoreVert fontSize='small' />
                    </IconButton>
                  }
                >
                  {stateWithVoidActions.includes(state) && (
                    <ResponsiveMenuItem
                      avatar={<OpenInNew />}
                      content={t('transactions.void')}
                      onClick={() => handleOpenVoidModal(transaction.get('id'))}
                    />
                  )}

                  {stateWithManualApproveActions.includes(state) && (
                    <ResponsiveMenuItem
                      avatar={<OpenInNew />}
                      content={t('transactions.manual_approve')}
                      onClick={() => handleOpenAppoveModal(transaction.get('id'))}
                    />
                  )}
                </ResponsiveMenu>
              </div>
            )
          }
        />
      </Table>

      <Modal isOpen={voidModalOpen} onCloseModal={() => setVoidModalOpen(false)}>
        <VoidTransactionForm
          onCloseModal={() => setVoidModalOpen(false)}
          id={transactionId}
          onSubmit={triggerTransaction}
        />
      </Modal>

      <Modal isOpen={approveModalOpen} onCloseModal={() => setApproveModalOpen(false)}>
        <ApproveTransactionForm
          onCloseModal={() => setApproveModalOpen(false)}
          id={transactionId}
          onSubmit={triggerTransaction}
        />
      </Modal>
    </>
  )
}

WalletTransactionList.propTypes = {
  transactions: ImmutablePropTypes.list,
  isProvider: ImmutablePropTypes.bool
}

WalletTransactionList.defaultProps = {
  transactions: List()
}

export default WalletTransactionList
