import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import AppForm from 'forms/resources/AppForm'

export function AppNewPage ({ createApp, push, t }) {
  function renderBreadcrumbs () {
    return [
      { text: t('title'), link: '/apps' },
      { text: t('new') }
    ]
  }

  return (
    <PageLayout
      title={t('new')}
      breadcrumbs={renderBreadcrumbs()}
    >
      <AppForm onSubmit={createApp} push={push} />
    </PageLayout>
  )
}

AppNewPage.propTypes = {
  push: PropTypes.func,
  createApp: PropTypes.func,

  t: PropTypes.func
}

export default withTranslation('apps')(AppNewPage)
