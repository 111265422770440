import moment from 'moment'

export function DateRangeFormatter ({ startTime, endTime }) {
  const isSameMonth = moment(startTime).isSame(endTime, 'month')

  if (isSameMonth) {
    return `${moment(startTime).format('DD')} - ${moment(endTime).format('DD MMMM YYYY')}`
  }
  else {
    return `${moment(startTime).format('DD MMMM YYYY')} - ${moment(endTime).format('DD MMMM YYYY')}`
  }
}

export default DateRangeFormatter
