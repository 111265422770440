import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, fromJS } from 'immutable'
import { withTranslation } from 'react-i18next'
import PageContent, { PageSection } from 'components/layouts/PageContent'

import useData from 'hooks/useData'

import Panel from 'components/containers/Panel'
import AttributeLabel from 'components/labels/AttributeLabel'
import CircularLoader from 'components/CircularLoader'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import GoogleGeoMap from 'components/maps/GoogleGeoMap'

export function RegionOverviewPane ({ region, match, t, fetchGeoJson, query }) {
  const [geoJson, setGeoJson] = useState(Map())

  const { loading } = useData(() =>
    fetchGeoJson(match.params.id, { page: query.page, query }).then(result => setGeoJson(fromJS(result.body)))
  , [query.search])

  return (
    <PageContent>
      <PageSection position='center' borderRight>
        <Panel title={t('map')}>
          {
            loading ? <CircularLoader /> : <GoogleGeoMap geo={geoJson} />
          }
        </Panel>
      </PageSection>

      <PageSection position='right'>
        <Panel title={t('details')}>
          <div style={{ padding: '10px 16px' }}>
            <AttributeLabel title={`${t('id')} ${region.get('id')}`}>
              {region.get('name_en', '-')} / {region.get('name_th', '-')}
            </AttributeLabel>

            <AttributeLabel title={t('updated_at')}>
              <DateTimeFormatter value={region.get('updated_at')} />
            </AttributeLabel>

            <AttributeLabel title={t('created_at')}>
              <DateTimeFormatter value={region.get('created_at')} />
            </AttributeLabel>
          </div>
        </Panel>
      </PageSection>
    </PageContent>
  )
}

RegionOverviewPane.propTypes = {
  region: ImmutablePropTypes.map,
  match: PropTypes.object,
  fetchGeoJson: PropTypes.func,
  query: PropTypes.object,
  t: PropTypes.func
}

RegionOverviewPane.defaultProps = {
  region: Map()
}

export default withTranslation('regions')(RegionOverviewPane)
