export default (theme) => ({
  flexContainer: {
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[100]
  },

  scrollButtonsDesktop: {
    width: theme.spacing(2)
  }
})
