import { withFormik } from 'formik'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'

import BlacklistedProviderForm from './BlacklistedProviderForm'

const formikConfig = {
  mapPropsToValues: () => ({}),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values)
      .catch(error => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(BlacklistedProviderForm)
