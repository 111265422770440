export default ({ spacing, palette }) => {
  const messageContentRoot = {
    flexGrow: 0,
    maxWidth: '75%',
    paddingRight: 0,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    display: 'flex',
    flexDirection: 'column-reverse'
  }

  const messageContent = {
    padding: `${spacing(0.5)}px ${spacing(1)}px`,
    marginTop: spacing(0.25),
    backgroundColor: palette.grey[100],
    borderRadius: `0 12px 12px 12px`,
    width: 'fit-content'
  }

  return {
    backButton: {
      marginLeft: `-${spacing(2)}px`
    },

    messageSelf: {
      justifyContent: 'flex-end'
    },

    avatarContainer: {
      marginTop: spacing(2)
    },

    avatar: {
      width: 25,
      height: 25
    },

    messageContentRoot,

    messageContentRootSelf: {
      ...messageContentRoot,
      alignItems: 'flex-end'
    },

    messageContent,

    messageContentSelf: {
      ...messageContent,
      borderRadius: `12px 12px 0 12px`,
      backgroundColor: palette.primary.main,
      color: 'white'
    },

    placeholderText: {
      margin: spacing(2)
    }
  }
}
