export default ({ spacing, button }) => ({
  userCard: {
    padding: spacing(2)
  },

  userAvatar: {
    width: 120,
    height: 120
  },

  userAvatarMobile: {
    margin: '0 auto',
    width: 220,
    height: 220
  },

  phoneIcon: {
    fontSize: 14,
    margin: `0 6px -2px 0`
  },

  emailIcon: {
    fontSize: 16,
    margin: `0 6px -4px 0`
  },

  devicesIcon: {
    fontSize: 16,
    margin: `12px 6px -4px 0`
  },

  mergeIcon: {
    transform: 'rotate(180deg)',
    display: 'block',
    margin: '10px auto'
  },

  button: {
    float: 'right'
  },

  backButton: {
    marginLeft: `-${spacing(2)}px`
  }
})
