export default ({ palette }) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 50%)',
    gridTemplateRows: 'repeat(8, 12.5%)',
    gridAutoFlow: 'column',
    width: '100%'
  },

  hour: {
    marginBottom: 6,

    '&:nth-child(-n+8)': {
      marginRight: 16
    },

    '&:nth-child(n+9)': {
      marginLeft: 16
    },

    '& > button': {
      width: '50%',
      fontSize: 14,

      '&:first-child': {
        borderRadius: '4px 0 0 4px',
        borderRight: 'none'
      },

      '&:last-child': {
        borderRadius: '0 4px 4px 0'
      }
    }
  },

  defaultButton: {
    color: palette.primary.main
  },

  selectedButton: {
    color: palette.grey[50],
    backgroundColor: palette.secondary.main,

    '&:hover': {
      color: palette.grey[50],
      backgroundColor: palette.secondary.main
    }
  },

  disabledButton: {
    color: `${palette.grey[50]} !important`
  },

  highlightDot: {
    position: 'absolute',
    left: '15%',
    backgroundColor: '#eaa844',
    width: 7,
    height: 7,
    borderRadius: '50%'
  },

  isInDuration: {
    backgroundColor: '#bcd5f3',

    '&:hover': {
      backgroundColor: '#bcd5f3'
    }
  },

  durationDot: {
    position: 'absolute',
    right: '15%',
    backgroundColor: palette.secondary.main,
    width: 7,
    height: 7,
    borderRadius: '50%'
  }
})
