import React from 'react'
import PropTypes from 'prop-types'
import { SortableContainer } from 'react-sortable-hoc'
import { Button, Grid } from '@material-ui/core'
import { AddCircleOutlineRounded } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { FieldArray, connect, getIn } from 'formik'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import SwitchInputField from '../SwitchInputField'
import styles from './SortableFieldArrayStyles'
import './newStyle.scss'

const SortableList = SortableContainer(({ children, ...rest }) => (
  <div>
    {React.Children.map(children, (child) => React.cloneElement(child, { ...rest }))}
  </div>
))

function SortableListContainer({ classes, ...props }) {
  const { name, label, noLabel, newItemText, formik, t, customBtn, switchUpdate } = props
  const labelName = name.split('.').pop()

  const fieldArrayAttributes = getIn(formik.values, name) || []

  function renderField(arrayHelpers, index) {
    const {
      name,
      formik,
      render,
      component: Component,
      t,
      tReady,
      i18nOptions,
      defaultNS,
      reportNS,
      ...rest
    } = props

    const helperBag = {
      name: `${name}[${index}]`,
      index,
      collection: name,
      onRemove: (e, { value }) => {
        // eslint-disable-next-line no-prototype-builtins
        value.hasOwnProperty('id')
          ? formik.setFieldValue(`${name}[${index}]._destroy`, true)
          : arrayHelpers.remove(index)
      }
    }

    if (render) {
      return render(helperBag, { arrayHelpers, index })
    } else if (Component) {
      return <Component {...helperBag} {...rest} key={helperBag.name} />
    }
  }

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {!noLabel && <label>{label || t([`label.${labelName}`, labelName])}</label>}

          <SortableList
            useDragHandle
            onSortEnd={({ oldIndex, newIndex, collection }) => {
              arrayHelpers.move(oldIndex, newIndex)
            }}
          >
            {fieldArrayAttributes
              .map((item, index) => ({ ...item, index }))
              .filter((item) => !item._destroy)
              .map((lineItem) => renderField(arrayHelpers, lineItem.index))}
          </SortableList>

          <Grid container justify='space-between'>
            {customBtn ? (
              <div
                className='btn-custom-reasons pointer'
                onClick={() => {
                  formik.values.create_at && switchUpdate(formik)
                  arrayHelpers.push({})
                }}
              >
                +
                <div className='text-btn-reasons'>
                  {newItemText || t([`new_item.${labelName}`, labelName])}
                </div>
              </div>
            ) : (
              <Button
                className={classes.addButton}
                variant='outlined'
                color='secondary'
                onClick={() => {
                  arrayHelpers.push({})
                }}
              >
                <AddCircleOutlineRounded className={classes.icon} />
                {newItemText || t([`new_item.${labelName}`, labelName])}
              </Button>
            )}

            {name === 'slots_attributes' ? (
              <SwitchInputField
                name='slot_enabled'
                switchLabel={t(`slot_enabled.${formik.values.slot_enabled}`)}
              />
            ) : null}
          </Grid>
        </>
      )}
    />
  )
}

SortableListContainer.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  noLabel: PropTypes.bool,
  newItemText: PropTypes.string,
  component: PropTypes.func,
  render: PropTypes.func,
  formik: PropTypes.object,
  t: PropTypes.func,
  tReady: PropTypes.bool,
  i18nOptions: PropTypes.object,
  defaultNS: PropTypes.string,
  reportNS: PropTypes.string,
  classes: PropTypes.object,
  customBtn: PropTypes.bool,
  switchUpdate: PropTypes.func
}

SortableListContainer.defaultProps = {
  noLabel: false,
  customBtn: false,
  switchUpdate: () => {}
}

export default compose(
  connect,
  withTranslation(['fields', 'attributes']),
  withStyles(styles)
)(SortableListContainer)
