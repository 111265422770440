import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { fetch, destroy } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { categorySchema } from '@seekster/schemas'

import withFlashMessage from 'hoc/withFlashMessage'

import CategoryShowPage from './CategoryShowPage'

const mapStateToProps = (state, { match }) => ({
  category: getResource(state, match.params.id, categorySchema)
})

const mapDispatchToProps = {
  fetchCategory: (id, options) => fetch(id, categorySchema, options),
  destroy: (id, options) => destroy(id, categorySchema, options),
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(withFlashMessage(CategoryShowPage))
