import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Map } from 'immutable'

import { AttributesTable, Attribute } from 'components/tables/AttributesTable'

export class Subscription extends React.Component {
  static propTypes = {
    inquiry: ImmutablePropTypes.map
  }

  static defaultProps = {
    inquiry: Map()
  }

  render () {
    const { inquiry } = this.props

    const subscriptionMonth = inquiry.get('subscription_month') || Map()
    const subscription = subscriptionMonth.get('subscription') || Map()

    return (
      <div>
        <AttributesTable>
          <Attribute label='Number' value={
            <Link to={`/subscriptions/${subscription.get('id')}`}>{subscription.get('number')}</Link>
          } />
          <Attribute label='Month' value={moment(subscriptionMonth.get('date')).format('MMMM YYYY')} />
          <Attribute label='State' value={subscription.get('state')} />
        </AttributesTable>
      </div>
    )
  }
}

export default Subscription
