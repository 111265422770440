import React from 'react'
import { AddCommentRounded } from '@material-ui/icons'
import { Paper, CircularProgress, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { feedbackSchema } from '@seekster/schemas'

import useQuery from 'hooks/useQuery'
import { useCollection } from 'hooks/collections'
import PageLayout from 'components/layouts/PageLayout'
import Search from 'components/Search'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import { Table, Column } from 'components/tables/Table'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import Tag from 'components/Tag'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

export function FeedbackIndexPage() {
  const classes = useStyles()
  const [query] = useQuery()
  const { t } = useTranslation('feedbacks')
  const [feedbacks, { loading, totalCount, totalPages }] = useCollection(
    feedbackSchema,
    { page: query.page, query },
    [query]
  )

  function primaryActions() {
    return [
      {
        avatar: <AddCommentRounded fontSize='small' />,
        content: t('new'),
        to: '/feedbacks/new'
      }
    ]
  }

  return (
    <PageLayout title={t('title')} primaryActions={primaryActions()}>
      <div className={classes.searchContainer}>
        <Search />
      </div>

      {loading ? (
        <CircularProgress color='secondary' />
      ) : (
        <>
          <div className={classes.metaContainer}>
            <ResultsCountLabel
              value={totalCount}
              unit='subscription'
              style={{ margin: 0 }}
            />
          </div>

          <Paper square>
            <Table collection={feedbacks}>
              <Column name='id' />
              <Column
                name='comment'
                to={(feedback) => `/feedbacks/${feedback.get('id')}`}
                // render={comment => (
                //   comment.length > 60 ? `${comment.slice(0, 60)}...` : comment
                // )}
              />
              <Column
                name='feedback_type.name'
                render={(param, feedbacks) => (
                  <span>{feedbacks.getIn(['feedback_type', 'name']) || '-'}</span>
                )}
              />
              <Column
                name='created_by'
                to={(feedback) =>
                  `/${feedback.getIn(['created_by', 'type'])}/${feedback.getIn([
                    'created_by',
                    'id'
                  ])}`
                }
                render={(creator, feedback) => {
                  if (creator) {
                    return (
                      <>
                        {creator.get('display_name')}{' '}
                        <Tag value={feedback.get('created_by_type')} />
                      </>
                    )
                  } else {
                    return `[ Unidentified ${feedback.get('created_by_type')} ]`
                  }
                }}
              />
              <Column
                name='tags'
                render={(tags) =>
                  tags &&
                  tags.map((tag) => <Tag key={tag.get('id')} value={tag.get('name')} />)
                }
              />
              <Column name='created_at' component={DateTimeFormatter} />
            </Table>
          </Paper>

          <Grid item xs={12} container justify='center'>
            <Pagination totalPages={totalPages} />
          </Grid>
        </>
      )}
    </PageLayout>
  )
}

export default FeedbackIndexPage
