import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  attributeName: {
    fontSize: '1em',
    width: '35%'
  },

  attributeValue: {
    fontSize: '1em',
    width: '65%'
  }
})
