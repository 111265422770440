import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Grid, Button } from '@material-ui/core'

import CircularLoader from 'components/CircularLoader'

import JobsTable from 'components/tables/JobsTable'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'

export class InquiryJobsPane extends React.Component {
  static propTypes = {
    inquiry: ImmutablePropTypes.map,
    fetchInquiryJobs: PropTypes.func,
    match: PropTypes.object,
    query: PropTypes.object,
    t: PropTypes.func
  }

  static defaultProps = {
    inquiry: Map(),
    providers: List(),
    jobs: List()
  }

  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      error: null,
      totalCount: 0,
      totalPages: 1
    }
  }

  componentDidMount () {
    this.setState({ loading: true })

    this.props.fetchInquiryJobs(this.props.match.params.id)
      .then(response => {
        this.setState({
          loading: false,
          totalCount: parseInt(response.headers['total'], 10),
          totalPages: Math.ceil(response.headers['total'] / response.headers['per-page'])
        })
      })
      .catch(error => {
        this.setState({ loading: false, error: error.message })
      })
  }

  componentDidUpdate (prevProps, prevState) {
    this.props.query.page !== prevProps.query.page && this.handleUpdateData()
  }

  handleUpdateData () {
    const { page } = this.props.query

    this.setState({ loading: true })

    this.props.fetchInquiryJobs(this.props.match.params.id, { page })
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(error => {
        this.setState({ loading: false, error: error.message })
      })
  }

  render () {
    const { loading, totalCount, totalPages } = this.state
    const jobs = this.props.inquiry.get('jobs') || List()

    return (
      <div style={{ padding: '14px 0' }}>
        <Grid container>
          <Grid
            container
            xs={6}
            direction='row'
            justify='flex-start'
            alignItems='center'
          >
            <ResultsCountLabel value={totalCount} unit='job' />
          </Grid>

          <Grid
            container
            xs={6}
            direction='row'
            justify='flex-end'
            alignItems='center'
          >
            <Link to={`/inquiries/${this.props.match.params.id}/assign_provider`}>
              <Button variant='outlined' color='primary'>
                {this.props.t('assign_provider')}
              </Button>
            </Link>
          </Grid>
        </Grid>

        {
          loading && <CircularLoader />
        }

        {
          !loading && totalCount !== 0 && (
            <>
              <JobsTable jobs={jobs} />

              <Grid item xs={12} container justify='center'>
                <Pagination totalPages={totalPages} />
              </Grid>
            </>
          )
        }
      </div>
    )
  }
}

export default withTranslation('actions')(InquiryJobsPane)
