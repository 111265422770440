import React from 'react'
import PropTypes from 'prop-types'
import S from 'string'
import classNames from 'clsx'

import './GenderTag.scss'

export class GenderTag extends React.Component {
  static propTypes = {
    value: PropTypes.string
  }

  render () {
    if (this.props.value) {
      return (
        <span className={classNames(['gender-tag', this.props.value])}>
          {S(this.props.value).humanize().capitalize().s}
        </span>
      )
    }
    else {
      return null
    }
  }
}

export default GenderTag
