import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import { useCollectionChildren } from 'hooks/collections'

import ReviewsTable from 'modules/reviews/ReviewsTable'
import CircularLoader from 'components/CircularLoader'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import { customerSchema, reviewSchema } from '@seekster/schemas'

export function CustomerReviewsPane ({ match, query }) {
  const [reviews, { loading, totalCount, totalPages }] = useCollectionChildren(
    customerSchema,
    match.params.id,
    reviewSchema,
    { page: query.page },
    [query.page]
  )

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ResultsCountLabel value={totalCount} unit='review' />
      </Grid>

      <Grid item xs={12}>
        {
          loading ? (
            <CircularLoader />
          ) : (
            !reviews.isEmpty() && (
              <ReviewsTable data={reviews} exclude={{ customer: true }} />
            )
          )
        }
      </Grid>

      {
        !reviews.isEmpty() && (
          <Grid item xs={12} container justify='center'>
            <Pagination totalPages={totalPages} />
          </Grid>
        )
      }
    </Grid>
  )
}

CustomerReviewsPane.propTypes = {
  match: PropTypes.object,
  query: PropTypes.object
}

export default CustomerReviewsPane
