export default ({ spacing, palette }) => {
  const messageContentRoot = {
    flexGrow: 0,
    maxWidth: '75%',
    paddingRight: 0,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    display: 'flex',
    flexDirection: 'column'
  }

  const messageContent = {
    padding: `${spacing.unit / 2}px ${spacing.unit}px`,
    marginTop: spacing.unit / 4,
    backgroundColor: palette.grey[100],
    borderRadius: '0 12px 12px 12px',
    width: 'fit-content'
  }

  return {
    backButton: {
      marginLeft: `-${spacing.unit * 2}px`
    },

    chipContanier: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 8,
      marginTop: 8
    },

    titleContainer: {
      margin: 0,
      fontSize: 18
    },

    panelList: {
      overflowY: 'auto',
      paddingBottom: '16px',
      height: 'calc(100vh - 252px)'
    },
    messageSelf: {
      justifyContent: 'flex-end'
    },

    avatarContainer: {
      marginTop: spacing.unit * 2
    },

    avatar: {
      width: 25,
      height: 25
    },

    messageContentRoot,

    messageContentRootSelf: {
      ...messageContentRoot,
      alignItems: 'flex-end'
    },

    messageContent,

    messageContentSelf: {
      ...messageContent,
      borderRadius: '12px 12px 0 12px',
      backgroundColor: palette.primary.main,
      color: 'white'
    },

    placeholderText: {
      marginTop: spacing.unit * 3
    },

    icon: {
      marginRight: 4
    },

    tagAction: {
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: 'red !important'
      }
    },

    followButton: {
      fontSize: 10,
      margin: 10,
      borderRadius: 25
    }
  }
}
