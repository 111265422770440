import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
import { ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  name: {
    whiteSpace: 'normal'
  },

  noTextUnderline: {
    '&:hover': {
      textDecoration: 'none'
    }
  }
})

export function Item ({
  name,
  avatar,
  children,
  nameTypographyProps,
  childrenTypographyProps,
  avatarProps,
  to,
  button,
  lazyLoad,
  ...rest
}) {
  const classes = useStyles()

  function renderItem () {
    return (
      <ListItem disableGutters button={button} {...rest}>
        <ListItemAvatar>
          {
            lazyLoad ? (
              <LazyLoad once offset={200}>
                <Avatar src={avatar} alt={name || 'avatar'} {...avatarProps} />
              </LazyLoad>
            ) : (
              <Avatar src={avatar} alt={name || 'avatar'} {...avatarProps} />
            )
          }
        </ListItemAvatar>

        <ListItemText
          primary={name}
          primaryTypographyProps={{
            className: classes.name,
            ...nameTypographyProps
          }}
          secondary={children}
          secondaryTypographyProps={childrenTypographyProps}
        />
      </ListItem>
    )
  }

  if (to) {
    return (
      <Link to={to} className={button && classes.noTextUnderline}>
        {renderItem()}
      </Link>
    )
  }
  else {
    return renderItem()
  }
}

Item.propTypes = {
  name: PropTypes.node.isRequired,
  avatar: PropTypes.string,
  children: PropTypes.node,
  avatarProps: PropTypes.object,
  nameTypographyProps: PropTypes.object,
  childrenTypographyProps: PropTypes.object,
  classes: PropTypes.object
}

export default Item
