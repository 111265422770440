import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { create } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { providerSchema, commentSchema } from '@seekster/schemas'

import ProviderCommentsPane from './ProviderCommentsPane'

const mapStateToProps = (state, { match }) => ({
  provider: getResource(state, match.params.id, providerSchema)
})

const mapDispatchToProps = {
  loadComments: (id, options) => fetchChildren(providerSchema, id, commentSchema, options),
  create: (data, id, options) => create(data, providerSchema, { type: `providers/${id}/comments` })
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderCommentsPane)
