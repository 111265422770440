import { connect } from 'react-redux'

import { fetch } from 'actions/resources'
import { fetchChildren, fetch as fetchCollection } from 'actions/collections'
import { getResource } from 'selectors/resources'
import { getCollection } from 'selectors/collections'
import { propertySchema, propertyDefinitionSchema } from '@seekster/schemas'

import UserProperties from './UserProperties'

const mapStateToProps = (state, { userId, userSchema }) => ({
  user: getResource(state, userId, userSchema),
  propertyDefinitions: getCollection(state, propertyDefinitionSchema)
})

const mapDispatchToProps = {
  fetchUser: (id, userSchema, options) => fetch(id, userSchema, options),
  fetchUserProperties: (id, userSchema, options) => fetchChildren(userSchema, id, propertySchema, options),
  fetchPropertyDefinitions: (category) => fetchCollection(propertyDefinitionSchema, { queryParam: { category } })
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProperties)
