import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { useTranslation } from 'react-i18next'
import { Select, InputBase, MenuItem } from '@material-ui/core'

import PageLayout from 'components/layouts/PageLayout'
import ServiceForm from 'forms/resources/ServiceForm'
import withLocalizedFields from 'hoc/withLocalizedFields'

export function ServiceNewPage ({
  createService,
  push,
  locale,
  setLocale
}) {
  const { t } = useTranslation('services')

  function handleSetLocale (e) {
    setLocale(e.target.value)
  }

  function actions () {
    return [
      <Select
        key='1'
        value={locale}
        onChange={handleSetLocale}
        input={<InputBase />}
      >
        <MenuItem value='en'><span role='img' aria-label='GB flag'>🇬🇧 </span>English</MenuItem>
        <MenuItem value='th'><span role='img' aria-label='Thai flag'>🇹🇭 </span>ภาษาไทย</MenuItem>
      </Select>
    ]
  }
  return (
    <PageLayout
      title={t('new')}
      secondaryActions={actions()}
      back='/services'
    >
      <ServiceForm
        push={push}
        onSubmit={createService}
      />
    </PageLayout>
  )
}

ServiceNewPage.propTypes = {
  createService: PropTypes.func,
  push: PropTypes.func,
  locale: PropTypes.string,
  setLocale: PropTypes.func
}

ServiceNewPage.defaultProps = {
  service: Map()
}

export default withLocalizedFields(ServiceNewPage)
