import React from 'react'
import { Grid } from '@material-ui/core'

import Field from 'components/fields/Field'

function BookingBranchFromSection ({ attributeScope }) {
  return (
    <Grid container spacing={16}>
      <Grid item xs={12} sm={6}>
        <Field label='Branch Name' name={`${attributeScope}.name`} />
      </Grid>
    </Grid>
  )
}

export default BookingBranchFromSection
