import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { create } from 'actions/resources'
import { categorySchema } from '@seekster/schemas'

import CategoryNewPage from './CategoryNewPage'

const mapDispatchToProps = {
  createCategory: (data, options) => create(data, categorySchema, options),
  push
}

export default connect(null, mapDispatchToProps)(CategoryNewPage)
