import { red } from '@material-ui/core/colors'

export default ({ button, typography }) => ({
  hideButton: {
    ...button,
    color: red[300]
  },

  content: typography.body2
})
