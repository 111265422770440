import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

export class DateTimeFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.node,
    simple: PropTypes.bool
  }

  render () {
    const { value, simple, ...rest } = this.props

    return (
      <span {...rest}>
        {
          value
            ? moment(value).format(simple ? 'D MMM YYYY - HH:mm' : 'ddd, DD MMM YYYY - HH:mm')
            : '-'
        }
      </span>
    )
  }
}

export default DateTimeFormatter
