import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Avatar,
  List as MaterialList,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core'
import { capitalize } from 'lodash'
import { nodeRequest } from 'utils/request'

import { providerSchema } from '@seekster/schemas'
import Panel from 'components/containers/Panel'
import AttributeLabel from 'components/labels/AttributeLabel'
import DateFormatter from 'components/formatters/DateFormatter'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import PhoneNumberFormatter from 'components/formatters/PhoneNumberFormatter'
import MailFormatter from 'components/formatters/MailFormatter'
import CapitalizeFormatter from 'components/formatters/CapitalizeFormatter'
import Rating from 'components/Rating'
import Tag from 'components/Tag'
import UserProperties from 'components/UserProperties'
import BadgeIcon from 'modules/badges/BadgeIcon'
import GoogleDynamicMap from 'components/maps/GoogleDynamicMap'
import { Phone, Mail } from '@material-ui/icons'
import { firebaseWorkforcePartner } from 'utils/firebase'

import './ProviderOverviewPane.scss'

const db = firebaseWorkforcePartner.firestore()

export function ProviderOverviewPane({ provider, match }) {
  const { t } = useTranslation(['providers', 'attributes'])
  const [user, setUser] = useState()
  const [currentLocation, setCurrentLocation] = useState(null)

  const badges = provider.get('badges') || List()
  const pathCurrentLocation =
    process.env.REACT_APP_ENV_NAME === 'staging'
      ? 'staging_current_locations'
      : 'current_locations'

  useEffect(() => {
    const docRef = db.collection(`${pathCurrentLocation}`).doc(`${match.params.id}`)
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setCurrentLocation(doc.data())
          return doc.data()
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [match.params.id, pathCurrentLocation])

  useEffect(() => {
    nodeRequest
      .get(`/providers/${match.params.id}`)
      .then((response) => {
        setUser(response.body)
      })
      .catch((error) => console.warn(error))
  }, [match.params.id])

  return (
    <Grid container>
      <Grid item xs={12} sm={8}>
        <Panel title={t('details.properties')}>
          <UserProperties userId={match.params.id} userSchema={providerSchema} />
        </Panel>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Panel title={t('details.provider')}>
          <Grid container className='details-content'>
            <Grid item xs={12} container justify='center'>
              <Avatar src={provider.get('avatar_url') || ''} className='avatar' />
            </Grid>

            <Grid item xs={6}>
              <AttributeLabel title={`${t('id')} ${provider.get('id')}`}>
                <p style={{ wordBreak: 'break-word' }}>
                  {provider.get('display_name')}
                  {provider.get('alias') && ` (${provider.get('alias')})`}
                </p>
              </AttributeLabel>
            </Grid>

            {user?.org !== null && (
              <Grid item xs={6}>
                <AttributeLabel title='&nbsp;'>
                  <span className='org-container'>
                    <Avatar src={user?.org?.picture || ''} className='avatar-org' />

                    <p style={{ wordBreak: 'break-word' }}>สังกัด {user?.org?.name}</p>
                  </span>
                </AttributeLabel>
              </Grid>
            )}
            <Grid item xs={12}>
              <Phone fontSize='small' />
              <PhoneNumberFormatter value={provider.get('phone_number')} />
            </Grid>

            <Grid item xs={12}>
              <Mail fontSize='small' />
              <MailFormatter value={provider.get('email') || '-'} />
            </Grid>

            <Grid item xs={6}>
              <AttributeLabel title={t('date_of_birth')} divided>
                {provider.get('date_of_birth') ? (
                  <DateFormatter value={provider.get('date_of_birth')} simple />
                ) : (
                  '-'
                )}
              </AttributeLabel>
            </Grid>

            <Grid item xs={6}>
              <AttributeLabel title={t('gender')} divided>
                {provider.get('gender') ? (
                  <Tag value={provider.get('gender')} formatter={CapitalizeFormatter} />
                ) : (
                  '-'
                )}
              </AttributeLabel>
            </Grid>

            <Grid item xs={12}>
              <AttributeLabel title={t('rating')}>
                <Rating value={provider.get('rating')} />
              </AttributeLabel>
            </Grid>

            <Grid item xs={12}>
              <AttributeLabel
                title={t('public_rating')}
                // subtitle={
                //   <Popup
                //     trigger={<Icon name='info circle' />}
                //     content={t('public_rating_info')}
                //     inverted
                //     style={{ opacity: 0.6, padding: '7px' }}
                //   />
                // }
              >
                <Rating value={provider.get('public_rating')} />
              </AttributeLabel>
            </Grid>

            <Grid item xs={12}>
              <AttributeLabel title={t('badges')}>
                {!badges.isEmpty()
                  ? badges.map((badge) => (
                      <BadgeIcon badge={badge} key={badge.get('id')} />
                    ))
                  : '-'}
              </AttributeLabel>
            </Grid>

            <Grid item xs={12}>
              <AttributeLabel title='Start training'>
                <DateTimeFormatter value={provider.get('date_of_start')} />
              </AttributeLabel>
            </Grid>

            <Grid item xs={12}>
              <AttributeLabel title='Latest covid-19 result date'>
                <DateTimeFormatter value={provider.get('lastest_covid_result_date')} />
              </AttributeLabel>
            </Grid>

            <Grid item xs={12}>
              <AttributeLabel title={t('updated_at')}>
                <DateTimeFormatter value={provider.get('updated_at')} />
              </AttributeLabel>
            </Grid>

            <Grid item xs={12}>
              <AttributeLabel title={t('created_at')}>
                <DateTimeFormatter value={provider.get('created_at')} />
              </AttributeLabel>
            </Grid>
          </Grid>
        </Panel>

        {provider.get('tags', List()).map((tag) => (
          <Tag key={tag.get('id')} value={tag.get('name')} />
        ))}

        <Panel title={t('services')}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MaterialList dense>
                {provider.get('services', List()).map((service) => {
                  const region = service.get('regional_slug', '').split('-').shift()

                  return (
                    <ListItem key={service.get('id')}>
                      <ListItemAvatar>
                        <Link to={`/${service.get('type')}/${service.get('id')}`}>
                          <Avatar src={service.get('icon_url')} />
                        </Link>
                      </ListItemAvatar>
                      <Link to={`/${service.get('type')}/${service.get('id')}`}>
                        <ListItemText
                          primary={`${service.get('name')} (${capitalize(region)})`}
                        />
                      </Link>
                    </ListItem>
                  )
                })}
              </MaterialList>
            </Grid>
          </Grid>
        </Panel>

        <Panel title='Provider Location'>
          {currentLocation !== null ? (
            <div>
              <GoogleDynamicMap
                center={{
                  lat: currentLocation.latitude,
                  lng: currentLocation.longitude
                }}
                markers={[
                  {
                    lat: currentLocation.latitude,
                    lng: currentLocation.longitude,
                    color: 'red'
                  }
                ]}
                fullscreenControl
              />
              <AttributeLabel title={t('updated_at')}>
                <DateTimeFormatter value={currentLocation.updated_at.toDate()} />
              </AttributeLabel>
            </div>
          ) : (
            'ยังไม่สามารถระบุตำแหน่งปัจจุบันได้'
          )}
        </Panel>
      </Grid>
    </Grid>
  )
}

ProviderOverviewPane.propTypes = {
  provider: ImmutablePropTypes.map,
  match: PropTypes.object
}

ProviderOverviewPane.defaultProps = {
  provider: Map()
}

export default ProviderOverviewPane
