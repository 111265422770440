import { red, green } from '@material-ui/core/colors'

export default theme => ({
  container: {
    textAlign: 'center'
  },

  button: {
    flexBasis: '30%',
    maxWidth: '30%'
  },

  counter: {
    flexBasis: '40%',
    maxWidth: '40%',
    position: 'relative'
  },

  removeButton: {
    color: red[800]
  },

  addButton: {
    color: green[800]
  },

  errorMessage: {
    position: 'absolute',
    minWidth: 120,
    left: '50%',
    transform: 'translateX(-50%)',
    color: red[800]
  }
})
