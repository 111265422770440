import { connect } from 'react-redux'

import { fetchChildren } from 'actions/collections'
import { createChild } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { serviceSchema, commentSchema } from '@seekster/schemas'

import ServiceCommentsPane from './ServiceCommentsPane'

const mapStateToProps = (state, { match }) => ({
  service: getResource(state, match.params.id, serviceSchema)
})

const mapDispatchToProps = {
  fetchServiceComments: (id) => fetchChildren(serviceSchema, id, commentSchema),
  createServiceComment: (data, id) => createChild(data, serviceSchema, id, commentSchema)
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCommentsPane)
