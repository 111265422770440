import { connect } from 'react-redux'
import { compose } from 'redux'

import { fetch } from 'actions/resources'
import { getResource } from 'selectors/resources'
import { withQueryParser } from 'utils/withQueryParser'
import { regionSchema } from '@seekster/schemas'
import FilterRegionTags from './FilterRegionTags'

const mapStateToProps = (state, { query }) => {
  const region = getResource(state, query.region_id, regionSchema)

  return ({
    regionName: region && region.get('name')
  })
}

const mapDispatchToProps = {
  fetchRegion: (id, options) => fetch(id, regionSchema, options)
}

export default compose(
  withQueryParser,
  connect(mapStateToProps, mapDispatchToProps)
)(FilterRegionTags)
