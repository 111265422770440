import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useResource } from 'hooks/resources'

import Panel from 'components/containers/Panel'
import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import { departmentSchema } from '@seekster/schemas'

export function DepartmentOverviewPane ({ match }) {
  const [department] = useResource(departmentSchema, match.params.id)
  const { t } = useTranslation('departments')

  return (
    <Grid container>
      <Grid item xs={12}>
        <Panel title={t('details')}>
          <AttributesTable resource={department} basic='very'>
            <Attribute name='id' />
            <Attribute name='name' />
            <Attribute name='created_at' component={DateTimeFormatter} />
            <Attribute name='updated_at' component={DateTimeFormatter} />
          </AttributesTable>
        </Panel>
      </Grid>
    </Grid>
  )
}

export default DepartmentOverviewPane
