import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, getIn } from 'formik'

import { Grid, TextareaAutosize } from '@material-ui/core'
import { LocalizedField, Field } from 'components/fields/Field'
import FormSection from 'components/fields/FieldArray/FormSection'
import ResourceSelect from 'components/inputs/ResourceSelect'
import { areaTemplateSchema } from '@seekster/schemas'

export function RegionAreasForm (props) {
  const { name, formik, fetchAreaTemplate } = props
  const values = getIn(formik.values, name)
  const locale = props.i18n.language

  useEffect(() => {
    if (!values.id && values.area_template) {
      fetchAreaTemplate(values.area_template).then(response => {
        formik.setFieldValue(`${name}.geometry_json`, JSON.stringify(response.body.geometry_json))
      })
    }
  }, [values.area_template]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormSection title={values[`name_${locale}`]} {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <LocalizedField name={`${name}.name`} required />
        </Grid>

        {
          (!values.id) &&
            <Grid item xs={12}>
              <Field
                name={`${name}.area_template`}
                component={ResourceSelect}
                schema={areaTemplateSchema}
                getOptionLabel={({ name, region_template: region }) => `${name} (${region.name})` }
              />
            </Grid>
        }

        <Grid item xs={12}>
          <Field name={`${name}.geometry_json`} component={TextareaAutosize} required />
        </Grid>
      </Grid>
    </FormSection>
  )
}

RegionAreasForm.propTypes = {
  name: PropTypes.string,
  formik: PropTypes.object
}

export default connect(RegionAreasForm)
