import React, { useEffect, useState } from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import { withMobileDialog } from '@material-ui/core'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { withQueryParser } from 'utils/withQueryParser'
import { tenantSchema } from '@seekster/schemas'
import { useSelector } from 'react-redux'
import CircularLoader from 'components/CircularLoader'
import PageLayout from 'components/layouts/PageLayout'
import PageContent, { PageSection } from 'components/layouts/PageContent'
import { BuildRounded } from '@material-ui/icons'
import { useCustomToken } from 'hooks/authentication'

import { TicketsListPanel, TicketChatPanel, UserInfoPanel } from 'modules/tickets'
import { getCurrentResource } from 'selectors/authentication'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

export function TicketIndexPage ({
  query,
  fullScreen: isMobile,
  t,
  location
}) {
  const [link, setLink] = useState(null)
  const tenant = useSelector(state => getCurrentResource(state, tenantSchema)) || Map()
  const [{ loading }] = useCustomToken()

  useEffect(() => {
    if (isMobile) {
      const path = location.pathname.split('/')
      const pathLength = path.length

      if (pathLength === 2) {
        setLink(null)
      }
      else if (pathLength === 3) {
        setLink({ prev: '/tickets', next: `${location.pathname}/user_info` })
      }
      else if (pathLength === 4) {
        setLink({ prev: `/tickets/${path[2]}` })
      }
      else {
        setLink({ prev: '/tickets' })
      }
    }
    else {
      setLink(null)
    }
  }, [isMobile, location])

  function primaryActions () {
    return [{
      avatar: <BuildRounded fontSize='small' />,
      content: t('settings'),
      to: '/ticket/settings'
    }]
  }

  return (
    <PageLayout isLoading={loading} title={t('title')} linkAction={link} primaryActions={!isMobile && primaryActions()}>
      {
        tenant === undefined ? <CircularLoader />
          : (
            <PageContent>
              <PageSection position='left' borderRight>
                <AuthenticatedRoute
                  path='/tickets'
                  component={TicketsListPanel}
                  tenant={tenant.get('slug')}
                  exact={isMobile}
                />
              </PageSection>

              <PageSection position='center' borderRight>
                <AuthenticatedRoute
                  path='/tickets/:id'
                  component={TicketChatPanel}
                  tenant={tenant.get('slug')}
                  exact={isMobile}
                />
              </PageSection>

              <PageSection position='right'>
                <AuthenticatedRoute
                  path={isMobile ? '/tickets/:id/user_info' : '/tickets/:id'}
                  component={UserInfoPanel}
                  tenant={tenant.get('slug')}
                />
              </PageSection>
            </PageContent>
          )
      }
    </PageLayout>
  )
}

TicketIndexPage.propTypes = {
  query: PropTypes.object,
  location: PropTypes.object,
  fullScreen: PropTypes.bool,
  t: PropTypes.func
}

export default compose(
  withMobileDialog(),
  withQueryParser,
  withTranslation('tickets')
)(TicketIndexPage)
