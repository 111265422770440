import React from 'react'
import { MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './ResponsiveMenuItemStyles'

export function ResponsiveMenuItem ({ avatar, content, onClick, classes, ...rest }) {
  return (
    <MenuItem onClick={onClick} className={classes.menuItem} {...rest}>
      <span className={classes.menuAvatar}>{avatar}</span>
      <span className={classes.menuContent}>{content}</span>
    </MenuItem>
  )
}

export default withStyles(styles)(ResponsiveMenuItem)
