import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import { Grid } from '@material-ui/core'

import EventTimeline from 'modules/events/EventTimeline'
export function JobTimelinePage({ job, match, loadJobEvent }) {
  useEffect(() => {
    loadJobEvent(match.params.id, { childrenType: 'timeline' })
  }, [match.params.id, loadJobEvent])

  const events = job.get('events') || List()

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <EventTimeline events={events} />
      </Grid>
    </Grid>
  )
}

JobTimelinePage.propTypes = {
  job: ImmutablePropTypes.map,
  match: PropTypes.object,

  loadJobEvent: PropTypes.func
}

JobTimelinePage.defaultProps = {
  job: Map()
}

export default JobTimelinePage
