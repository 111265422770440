import { connect } from 'react-redux'

import { create } from 'actions/resources'
import { push } from 'react-router-redux'
import { issueSchema } from '@seekster/schemas'

import ProviderIssueNewPage from './ProviderIssueNewPage'

const mapDispatchToProps = {
  createIssue: data => create(data, issueSchema),
  push
}

export default connect(null, mapDispatchToProps)(ProviderIssueNewPage)
