export default theme => ({
  card: {
    marginBottom: theme.spacing(1)
  },

  cardContent: {
    paddingTop: 0,
    paddingBottom: '0 !important'
  },

  avatar: {
    width: 60,
    height: 60
  },

  bottomMargin: {
    marginBottom: 14
  }
})
