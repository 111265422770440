import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import LazyLoad from 'react-lazyload'
import { List } from 'immutable'
import { Link } from 'react-router-dom'
import { Table, Column } from 'components/tables/Table'
import { Paper } from '@material-ui/core'
import { DateTag } from 'components/tags'

export const BadgesTable = ({ badges }) => {
  return (
    <Paper>
      <Table collection={badges}>
        <Column
          name='icon'
          render={(icon, badge) => (
            <Link to={`/badges/${badge.get('id')}`}>
              <LazyLoad height={25}>
                <img src={badge.get('icon_url')} alt='' style={{ marginRight: '10px', height: '25px' }} />
              </LazyLoad>
            </Link>
          )}
        />
        <Column
          name='name'
          render={(name, badge) => (
            <Link to={`/badges/${badge.get('id')}`}>{badge.get('name')}</Link>
          )}
        />
        <Column name='created_at' component={DateTag} />
        <Column name='updated_at' component={DateTag} />
      </Table>
    </Paper>
  )
}

BadgesTable.propTypes = {
  badges: ImmutablePropTypes.list
}

BadgesTable.defaultProps = {
  badges: List()
}

export default BadgesTable
