import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'

import { withStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Card,
  CardContent,
  CardActionArea,
  Grid,
  Typography
} from '@material-ui/core'

import { ChevronRight } from '@material-ui/icons'
import distanceDifferent from 'utils/distanceDifferent'
import { StateTag } from 'components/tags'

import styles from './ProvidersListStyles'
import { AssignProvidersListPanelContext } from 'modules/assignProviders/AssignProvidersListPanel/AssignProvidersListPanel'
import { useContext } from 'react'

export function ProvidersList({ classes, match, inquiry }) {
  const { providers } = useContext(AssignProvidersListPanelContext)

  return (
    <>
      {providers.map((provider) => {
        return (
          <Card className={classes.card} key={provider.id}>
            <CardActionArea component={Link} to={`${match.url}/${provider.id}`}>
              <div className={classes.details}>
                <CardContent className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item xs={2}>
                      <Avatar
                        src={provider.avatar_url}
                        alt={provider.display_name}
                        className={classes.avatar}
                      />
                    </Grid>
                    <Grid item xs={9} sm container>
                      <Grid item xs container direction='column'>
                        <Grid item xs>
                          <Typography gutterBottom variant='subtitle1'>
                            {provider.full_name}
                          </Typography>
                          <Typography variant='body2' gutterBottom>
                            <StateTag value={provider.state} />{' '}
                            {provider?.home_address?.latitude &&
                              inquiry.getIn(['location', 'latitude'])
                              ? distanceDifferent(
                                provider?.home_address?.latitude,
                                provider?.home_address?.longitude,
                                inquiry.getIn(['location', 'latitude']),
                                inquiry.getIn(['location', 'longitude'])
                              )
                              : '-'}{' '}
                            KM
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={1} className={classes.icon}>
                        <ChevronRight />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </div>
            </CardActionArea>
          </Card>
        )
      })}
    </>
  )
}

ProvidersList.propTypes = {
  providers: ImmutablePropTypes.list,
  inquiry: ImmutablePropTypes.map,
  match: PropTypes.object,
  classes: PropTypes.object
}

ProvidersList.defaultProps = {
  providers: List(),
  inquiry: Map()
}

export default withStyles(styles)(ProvidersList)
