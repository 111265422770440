import { withFormik } from 'formik'
import { compose } from 'redux'

import withFlashMessage from 'hoc/withFlashMessage'

import ApproveTransactionForm from './ApproveTransactionForm'

const formikConfig = {
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(props.id, 'manual_approve', values)
      .then(() => {
        props.onCloseModal()
        setSubmitting(false)
      })
      .catch(error => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(
  withFlashMessage,
  withFormik(formikConfig)
)(ApproveTransactionForm)
