import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Form } from 'formik'
import { Grid } from '@material-ui/core'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import { Field, LocalizedField } from 'components/fields/Field'
import DropzoneInput from 'components/inputs/DropzoneInput'
import ButtonLoader from 'components/buttons/ButtonLoader'

import styles from './ProviderStrengthFormStyles'

export function ProviderStrengthForm({ providerStrength, classes, t, isSubmitting }) {
  return (
    <Grid container justify='center' component={Form}>
      <Grid item xs={12} sm={9}>
        <Panel title={t('details')}>
          <div className={classes.formContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Field
                  name='icon'
                  component={DropzoneInput}
                  width={250}
                  height={250}
                  initialValue={providerStrength.get('icon_url')}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <LocalizedField name='name' />
              </Grid>

              <Grid item xs={12} container justify='flex-end'>
                <ButtonLoader
                  type='submit'
                  variant='contained'
                  className={classes.button}
                  isLoading={isSubmitting}
                >
                  {t('save')}
                </ButtonLoader>
              </Grid>
            </Grid>
          </div>
        </Panel>
      </Grid>
    </Grid>
  )
}

ProviderStrengthForm.propTypes = {
  providerStrength: ImmutablePropTypes.map,
  classes: PropTypes.object,
  isSubmitting: PropTypes.bool,

  t: PropTypes.func
}

ProviderStrengthForm.defaultProps = {
  providerStrength: Map()
}

export default compose(
  withStyles(styles),
  withTranslation('provider_strengths')
)(ProviderStrengthForm)
