import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid, IconButton, TextField, Typography } from '@material-ui/core'
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import styles from './CounterInputStyles'

export function CounterInput({
  value,
  onChange,
  min,
  max,
  classes,
  t,
  handleOnBlur,
  ...rest
}) {
  const [limitMessage, setLimitMessage] = useState(null)

  function getNumberOfDecimal(str) {
    return String(str).split('.')[1]?.length || 0
  }

  function handleIncrementCounter() {
    const tergetValue = Number(value)
    if (!max || tergetValue < max) {
      if (value % 1 === 0) {
        onChange && onChange(tergetValue + 1)
      } else {
        const numberOfDecimal = getNumberOfDecimal(value)
        onChange && onChange((tergetValue + 1).toFixed(numberOfDecimal || 0))
      }
    }
  }

  function handleDecrementCounter() {
    let targetValue
    if (value === 1) {
      targetValue = null
    } else if (value % 1 === 0) {
      targetValue = value - 1
    } else {
      const numberOfDecimal = getNumberOfDecimal(value)
      targetValue = (value - 1).toFixed(numberOfDecimal || 0)
    }

    if (min && targetValue < min) {
      targetValue = null
    }

    onChange && onChange(targetValue)
  }

  function handleChangeValue(e) {
    // let targetValue = parseInt(e.target.value, 10)

    onChange && onChange(e.target.value)

    handleValidateValueLimit(e.target.value)
  }

  function handleValidateValueLimit(targetValue) {
    if (max && targetValue > max) {
      setLimitMessage(`${t('maximum')}: ${max} ${t('unit')}`)
    } else if (min && targetValue < min) {
      setLimitMessage(`${t('minimum')}: ${min} ${t('unit')}`)
    } else {
      setLimitMessage(null)
    }
  }

  return (
    <Grid container alignItems='center' className={classes.container}>
      <Grid item className={classes.button}>
        <IconButton
          onClick={handleDecrementCounter}
          disabled={value <= 0}
          className={classes.removeButton}
        >
          <RemoveCircleOutline />
        </IconButton>
      </Grid>

      <Grid item className={classes.counter}>
        <TextField
          value={value}
          onChange={handleChangeValue}
          margin='dense'
          variant='outlined'
          inputProps={{
            style: { textAlign: 'center', fontSize: 16 },
            // pattern: '[0-9]*',
            // pattern: '/d+.?d*/',
            inputMode: 'decimal'
          }}
          error={Boolean(limitMessage)}
          onBlur={handleOnBlur}
          {...rest}
        />

        {limitMessage && (
          <Typography variant='caption' className={classes.errorMessage}>
            {limitMessage}
          </Typography>
        )}
      </Grid>

      <Grid item className={classes.button}>
        <IconButton
          onClick={handleIncrementCounter}
          disabled={max && value >= max}
          className={classes.addButton}
        >
          <AddCircleOutline />
        </IconButton>
      </Grid>
    </Grid>
  )
}

CounterInput.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  classes: PropTypes.object,

  t: PropTypes.func
}

CounterInput.defaultProps = {
  value: 0
}

export default compose(withStyles(styles), withTranslation('common'))(CounterInput)
