import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'formik'
import moment from 'moment'
import { Grid, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import withFlashMessage from 'hoc/withFlashMessage'

import DatePicker from 'components/inputs/DatePicker'
import TimePickerPreset from 'components/inputs/TimePickerNew'
import TimeCustomSelect from 'components/inputs/TimeSelect'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import SlotTimeField from 'components/fields/SlotTimeField'

import styles from './RequestJobsFormSectionStyles'

function TimePicker({
  type,
  currentTime,
  handleTimeChange,
  readOnly,
  handleSetCurrentDate
}) {
  const pickerProps = {
    hour: currentTime.hour,
    minute: currentTime.minute,
    onChange: handleTimeChange,
    disabled: readOnly,
    handleCheckbox: handleSetCurrentDate,
    todayCheckbox: true
  }

  switch (type) {
    case 'preset':
      return <TimePickerPreset {...pickerProps} />
    case 'custom':
      return <TimeCustomSelect {...pickerProps} />
    default:
      return <TimePickerPreset {...pickerProps} />
  }
}

export function RequestJobsFormSection({
  name,
  formik,
  timePickerType,
  readOnly,
  referenceJobs,
  classes,
  t,
  slotEnabled,
  serviceId,
  setFlashMessage
}) {
  const dates = formik.values[name]
    ? formik.values[name].map((date) => moment(date.start_time))
    : []
  const hour = formik.values[name] && moment(formik.values[name][0].start_time).hour()
  const minute = formik.values[name] && moment(formik.values[name][0].start_time).minute()

  const [currentTime, setCurrentTime] = useState({ hour, minute })
  const [currentDates, setCurrentDates] = useState(dates)

  function handleDatesChange(newDates) {
    if (slotEnabled) {
      setCurrentDates([newDates])
      handleSetValues([newDates], currentTime)
    } else {
      setCurrentDates(newDates)
      handleSetValues(newDates, currentTime)
    }
  }

  function handleTimeChange(newTime) {
    setCurrentTime(newTime)

    handleSetValues(currentDates, newTime)
  }

  function handleSetCurrentDate(e) {
    let currentDate = []
    let cureentTime = { hour: undefined, minute: undefined }
    if (e.target.checked) {
      currentDate = [moment()]
      cureentTime = { hour: moment().hour(), minute: moment().minute() }
    }

    setCurrentDates(currentDate)
    setCurrentTime(cureentTime)
    handleSetValues(currentDate, cureentTime)
  }

  function handleSlotChange(startTime, endTime) {
    const [startHour, startMin] = startTime.split(':')
    const [endHour, endMin] = endTime.split(':')

    const newSlots = currentDates.map((date) => ({
      start_time: date.hour(startHour).minute(startMin).toISOString(true),
      end_time: date.hour(endHour).minute(endMin).toISOString(true)
    }))

    if (currentDates && currentDates.length !== 0) {
      formik.setFieldValue(name, newSlots)
    } else {
      setFlashMessage('error', 'Please pick a date before selecting time slot')
    }
  }

  function handleSetValues(dates, { hour, minute }) {
    let jobsStartTime

    if (
      dates &&
      dates.length !== 0 &&
      typeof hour === 'number' &&
      typeof minute === 'number'
    ) {
      jobsStartTime = dates.map((date) => ({
        start_time: date.hour(hour).minute(minute).toISOString(true)
      }))
    }
    formik.setFieldValue(name, jobsStartTime)
  }

  return (
    <div className={classes.jobsFormContainer}>
      <Grid container spacing={2}>
        {referenceJobs && (
          <Grid item xs={12}>
            <Paper className={classes.referenceJobs}>
              <label>{t('form.date_time.previous_booking')}</label>

              <ul>
                {referenceJobs.map((job) => (
                  <li key={job.get('id')}>
                    <DateTimeFormatter value={job.get('start_time')} />
                  </li>
                ))}
              </ul>
            </Paper>
          </Grid>
        )}

        <Grid item xs={12} sm={5}>
          <DatePicker
            isMulti={!slotEnabled}
            {...(slotEnabled ? { date: currentDates[0] } : { dates: currentDates })}
            onChange={handleDatesChange}
            focused={Boolean(currentDates)}
            initialVisibleMonth={() => moment.min(currentDates)}
            disabledPastDate
            readOnly={readOnly}
          />
        </Grid>

        <Grid item xs={12} sm={7}>
          {slotEnabled ? (
            <SlotTimeField
              value={(formik.values[name] && formik.values[name][0]) || {}}
              currentDate={currentDates[0]}
              handleSlotChange={handleSlotChange}
              serviceId={serviceId}
              readOnly={readOnly}
            />
          ) : (
            <TimePicker
              type={timePickerType}
              currentTime={currentTime}
              handleTimeChange={handleTimeChange}
              readOnly={readOnly}
              handleSetCurrentDate={handleSetCurrentDate}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

RequestJobsFormSection.propTypes = {
  name: PropTypes.string,
  formik: PropTypes.object,
  timePickerType: PropTypes.string,
  readOnly: PropTypes.bool,
  referenceJobs: PropTypes.bool,
  classes: PropTypes.object,
  t: PropTypes.func,
  slotEnabled: PropTypes.bool,
  serviceId: PropTypes.number,
  setFlashMessage: PropTypes.func
}

export default compose(
  withStyles(styles),
  withTranslation('inquiries'),
  connect,
  withFlashMessage
)(RequestJobsFormSection)
