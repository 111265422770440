import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Link } from 'react-router-dom'

import Panel from 'components/containers/Panel'
import { DateTag } from 'components/tags'
import { AttributesTable, Attribute } from 'components/tables/AttributesTable'

export class ReviewOverviewPane extends React.Component {
  static propTypes = {
    review: ImmutablePropTypes.map
  }

  static defaultProps = {
    review: Map()
  }

  render() {
    const { review } = this.props
    const job = review.get('job') || Map()
    const provider = review.get('provider') || Map()
    const customer = review.get('customer') || Map()
    const reviewType = review.get('review_type') || Map()

    return (
      <Panel title='Review Details'>
        <AttributesTable>
          <Attribute label='ID' value={review.get('id')} />
          <Attribute
            label='Order Number'
            value={<Link to={`/jobs/${job.get('id')}`}>{job.get('number')}</Link>}
          />
          <Attribute label='Rating' value={review.get('rating')} />
          <Attribute label='Content' value={review.get('content')} />
          <Attribute label='Type' value={reviewType.get('name') || '-'} />
          <Attribute
            label='Reviewable'
            value={
              <Link to={`/providers/${provider.get('id')}`}>
                {provider.get('full_name')}
              </Link>
            }
          />
          <Attribute
            label='Reviewed By'
            value={
              <Link to={`/customers/${customer.get('id')}`}>
                {customer.get('full_name')}
              </Link>
            }
          />
          <Attribute
            label='Updated At'
            value={<DateTag value={review.get('updated_at')} />}
          />
          <Attribute
            label='Created At'
            value={<DateTag value={review.get('created_at')} />}
          />
        </AttributesTable>
      </Panel>
    )
  }
}

export default ReviewOverviewPane
