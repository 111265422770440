import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'

export class FilterAssociationTags extends React.Component {
  static propTypes = {
    content: PropTypes.string,
    name: PropTypes.string,
    query: PropTypes.object,
    onQueryPush: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.handleRemoveTag = this.handleRemoveTag.bind(this)
  }

  handleRemoveTag (e, { queryKey }) {
    const { [queryKey]: removedValue, ...rest } = this.props.query

    this.props.onQueryPush(rest)
  }

  render () {
    return (
      <Chip
        variant='outlined'
        color='primary'
        size='small'
        style={{ margin: '0 6px 6px 0' }}
        label={this.props.content}
        onDelete={(e) => this.handleRemoveTag(e, { queryKey: this.props.name })}
      />
    )
  }
}

export default FilterAssociationTags
