import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useCollection } from 'hooks/collections'
import useQuery from 'hooks/useQuery'

import PageLayout from 'components/layouts/PageLayout'
import CircularLoader from 'components/CircularLoader'
import Search from 'components/Search'
import FilterModal from 'components/FilterModal'
import FilterTags from 'components/tags/FilterTags'
import PaymentsFilterFrom from 'forms/filters/PaymentsFilterFrom'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import PaymentsTable from 'components/tables/PaymentsTable'
import Pagination from 'components/Pagination'

import { paymentSchema } from '@seekster/schemas'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

export function PaymentIndexPage() {
  const classes = useStyles()
  const [query] = useQuery()
  const { t } = useTranslation(['payments', 'invoice'])
  const [payments, { loading, totalCount, totalPages }] = useCollection(
    paymentSchema,
    { page: query.page, query },
    [query]
  )

  return (
    <PageLayout title={t('title')}>
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={10}>
            <Search />
          </Grid>

          <Grid item xs={3} sm={2}>
            <FilterModal component={PaymentsFilterFrom} />
          </Grid>
        </Grid>
      </div>

      {loading ? (
        <CircularLoader />
      ) : (
        <>
          <div className={classes.metaContainer}>
            <FilterTags />

            <ResultsCountLabel value={totalCount} unit='payment' />

            <PaymentsTable payments={payments} />
          </div>

          <Grid item xs={12} container justify='center'>
            <Pagination totalPages={totalPages} />
          </Grid>
        </>
      )}
    </PageLayout>
  )
}

export default PaymentIndexPage
