import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Avatar, ListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { ResponsiveMenuItem } from 'components/menus/ResponsiveMenu'

import styles from './NotificationItemStyles'

export function NotificationItem ({ notificationRecipient, onClick, classes }) {
  const { t } = useTranslation(['notification'])
  const notification = notificationRecipient.get('notification')

  return (
    <ResponsiveMenuItem
      component={Link}
      to={notification.get('url')}
      avatar={<Avatar src={notification.getIn(['actor', 'avatar_url'])} />}
      content={
        <ListItemText
          primary={t(notification.getIn(['template', 'name']))}
          primaryTypographyProps={{
            style: {
              fontWeight: notificationRecipient.get('read') ? 'normal' : 'bold'
            }
          }}
          secondary={moment(notification.get('created_at')).fromNow()}
        />
      }
      className={classes.item}
      onClick={onClick}
    />
  )
}

NotificationItem.propTypes = {
  notificationRecipient: ImmutablePropTypes.map,
  onClick: PropTypes.func,
  classes: PropTypes.object
}

NotificationItem.defaultProps = {
  notificationRecipient: Map()
}

export default withStyles(styles)(NotificationItem)
