import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { useTranslation } from 'react-i18next'

import { useResource, useResourceAction } from 'hooks/resources'
import { usePush } from 'hooks/utils'
import { inquirySchema } from '@seekster/schemas'
import PageLayout from 'components/layouts/PageLayout'

import InquiryForm from 'forms/resources/InquiryForm'

export function InquiryDuplicatePage ({ match }) {
  const { t } = useTranslation('inquiries')
  const [inquiry, { loading }] = useResource(inquirySchema, match.params.id)
  const { create: createInquiry } = useResourceAction(inquirySchema)
  const push = usePush()

  return (
    <PageLayout
      title={t('duplicate')}
      isLoading={loading}
      back={`/inquiries/${match.params.id}`}
    >
     <InquiryForm
        inquiry={inquiry}
        push={push}
        onSubmit={createInquiry}
        withoutAssociationIds
        withoutJobs
        showReferenceValues
      />
    </PageLayout>
  )
}

InquiryDuplicatePage.propTypes = {
  match: PropTypes.object
}

InquiryDuplicatePage.defaultProps = {
  inquiry: Map()
}

export default InquiryDuplicatePage
