import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import Ticket from './Ticket'

export function TicketsList ({ tickets, match, location }) {
  return (
    <>
      {
        tickets.map(ticket => {
          return <Ticket
            key={ticket.get('id')}
            ticket={ticket}
            match={match}
            location={location}
          />
        })
      }
    </>
  )
}

TicketsList.propTypes = {
  tickets: ImmutablePropTypes.list,
  match: PropTypes.object,
  location: PropTypes.object
}

TicketsList.defaultProps = {
  tickets: List()
}

export default TicketsList
