import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'formik'
import { Menu, MenuItem, Paper, InputBase, IconButton, Divider } from '@material-ui/core'
import NestedMenuItem from 'components/menus/NestedMenu'
import SendIcon from '@material-ui/icons/Send'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import AddPhotoAlternateRounded from '@material-ui/icons/AddPhotoAlternateRounded'
import { Field } from 'components/fields/Field'
import { firebaseSupportPlatform } from 'utils/firebase'

import styles from './TicketChatFromStyles'

const storageRef = firebaseSupportPlatform.storage().ref()

export function TicketChatFrom ({
  classes,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  disabled,
  values,
  macros
}) {
  const [menuPosition, setMenuPosition] = useState(null)

  const handleClick = (event) => {
    if (menuPosition) {
      return
    }
    event.preventDefault()
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    })
  }

  function handleItemClick (value) {
    setMenuPosition(null)
    setFieldValue('value', value)
    handleSubmit()
  }

  function handlePressEnter (e) {
    if (e.keyCode === 13) {
      e.preventDefault()
      // handleSubmitForm gets called onBlur
      handleSubmitForm()
    }
  }

  function handleSubmitForm () {
    if (values.value) {
      handleSubmit()
    }
  }

  function handleChangeFile (event) {
    storageRef.child(`${event.target.files[0].name}`).put(event.target.files[0]).then((snapshot) => {
      setFieldValue('type', 'image')
      snapshot.ref.getDownloadURL().then(url => {
        setFieldValue('value', url)
        handleSubmit()
      })
    })
  }

  return (
    <Form className={classes.disabledForm}>
      <Paper className={classes.root}>
        <Field
          name='value'
          autoComplete='off'
          className={classes.input}
          component={InputBase}
          placeholder='Enter your message Here'
          fluid
          noLabel
          disabled={disabled}
          onKeyDown={handlePressEnter}
        />

        {
          !disabled && <>
            <div onClick={handleClick}>
              <IconButton className={classes.iconButton} aria-label='search' component='span'>
                <AddCircleOutlineIcon />
              </IconButton>

              <Menu
                open={!!menuPosition}
                onClose={() => setMenuPosition(null)}
                anchorReference='anchorPosition'
                anchorPosition={menuPosition}
              >
                {
                  macros.map((marco, index) => {
                    return (
                      <NestedMenuItem
                        label={marco.get('group')}
                        parentMenuOpen={!!menuPosition}
                        key={index}
                      >
                        {
                          marco.get('value').map((child, index) => {
                            return <MenuItem key={index} onClick={() => handleItemClick(child.get('group'))}>{child.get('group')}</MenuItem>
                          })
                        }
                      </NestedMenuItem>
                    )
                  })
                }
              </Menu>
            </div>

            <input id='icon-button-file' type='file' className={classes.inputFile} onChange={handleChangeFile} />

            <label htmlFor='icon-button-file' className={classes.labelInputFile}>
              <IconButton className={classes.iconButton} aria-label='search' component='span'>
                <AddPhotoAlternateRounded />
              </IconButton>
            </label>

            <Divider className={classes.divider} orientation='vertical' />

            <IconButton color='primary' className={classes.iconButton} aria-label='directions' onClick={handleSubmitForm} >
              <SendIcon />
            </IconButton>
          </>
        }
      </Paper>
    </Form>
  )
}

TicketChatFrom.propTypes = {
  macros: ImmutablePropTypes.list,
  classes: PropTypes.object,
  values: PropTypes.object,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func
}

TicketChatFrom.defaultProps = {
  macros: List()
}

export default withStyles(styles)(TicketChatFrom)
