import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper } from '@material-ui/core'
import { DomainRounded } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useCollection } from 'hooks/collections'
import useQuery from 'hooks/useQuery'

import PageLayout from 'components/layouts/PageLayout'
import CircularLoader from 'components/CircularLoader'
import { Table, Column } from 'components/tables/Table'
import Search from 'components/Search'
import FilterModal from 'components/FilterModal'
import FilterTags from 'components/tags/FilterTags'
import CompaniesFilterForm from 'forms/filters/CompaniesFilterForm'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
import Pagination from 'components/Pagination'
import Tag from 'components/Tag'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'

import { companySchema } from '@seekster/schemas'

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

export function CompanyIndexPage() {
  const classes = useStyles()
  const [query] = useQuery()
  const { t } = useTranslation('companies')
  const [companies, { loading, totalCount, totalPages }] = useCollection(
    companySchema,
    { page: query.page, query },
    [query]
  )

  function primaryActions() {
    return [
      {
        avatar: <DomainRounded fontSize='small' />,
        content: t('new'),
        to: '/companies/new'
      }
    ]
  }

  return (
    <PageLayout title={t('title')} primaryActions={primaryActions()}>
      <div className={classes.searchContainer}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={10}>
            <Search />
          </Grid>

          <Grid item xs={3} sm={2}>
            <FilterModal component={CompaniesFilterForm} />
          </Grid>

          <Grid item xs={12}>
            <FilterTags />

            <ResultsCountLabel value={totalCount} unit='company' />
          </Grid>
        </Grid>
      </div>

      {loading ? (
        <CircularLoader />
      ) : (
        <div className={classes.metaContainer}>
          <Paper>
            <Table collection={companies}>
              <Column name='id' />
              <Column
                name='name'
                render={(name, company) => (
                  <p>
                    <Link to={`/${company.get('type')}/${company.get('id')}`}>
                      {name}
                    </Link>{' '}
                    &nbsp;
                    <Tag value={company.get('state')} className='company-states' />
                  </p>
                )}
              />
              <Column
                name='company_type'
                render={(companyType) => (
                  <p>{companyType ? companyType.get('name') : '-'}</p>
                )}
              />
              <Column name='phone_number' />
              <Column name='tax_number' />
              <Column name='created_at' component={DateTimeFormatter} />
            </Table>
          </Paper>
        </div>
      )}

      <Grid item xs={12} container justify='center'>
        <Pagination totalPages={totalPages} />
      </Grid>
    </PageLayout>
  )
}

export default CompanyIndexPage
