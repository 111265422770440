import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import PromoCodeForm from 'forms/resources/PromoCodeForm'

export class PromoCodeNewPage extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    createPromoCode: PropTypes.func,
    t: PropTypes.func
  }


  render () {
    return (
      <PageLayout
        title={this.props.t('new')}
        back='/promo_codes'
      >
        <PromoCodeForm
          push={this.props.push}
          onSubmit={this.props.createPromoCode}
        />
      </PageLayout>
    )
  }
}

export default withTranslation('promo_codes')(PromoCodeNewPage)
