import moment from 'moment'

export function TimeFormatter ({ value }) {
  const dayToUpdate = moment().diff(moment(value), 'days')

  if (dayToUpdate === 0) {
    return moment(value).format('HH:mm')
  }
  else if (dayToUpdate === 1) {
    return 'yesterday'
  }
  else {
    return `${dayToUpdate} days ago`
  }
}

export default TimeFormatter
