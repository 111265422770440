export default theme => ({
  root: {
    padding: 14
  },

  avatar: {
    margin: '0 auto',
    width: 220,
    height: 220
  },

  displayName: {
    wordBreak: 'break-word'
  },

  icon: {
    margin: '0 4px -5px 0'
  }
})
