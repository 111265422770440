export default theme => ({
  container: {
    alignItems: 'flex-end',
    padding: '10px 16px'
  },

  button: {
    float: 'right',
    margin: '14px 0 0 14px'
  },

  select: {
    padding: '7.5px'
  }
})
