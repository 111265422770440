import React from 'react'
import { useField } from 'formik'
import { TextField, FormControl } from '@material-ui/core'

function InputField(props) {
  const [field] = useField(props.name)

  return (
    <FormControl fullWidth>
      <TextField {...field} {...props} />
    </FormControl>
  )
}

export default InputField
