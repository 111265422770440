import { withFormik } from 'formik'
import * as Yup from 'yup'

import ProviderAssignmentWithFilterForm from './ProviderAssignmentWithFilterForm'

const formikConfig = {
  validationSchema: Yup.object().shape({
    provider_id: Yup.number().required()
  }),

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values)
      .catch(({ response }) => {
        setSubmitting(false)

        setErrors({ provider_id: response.body.message })
      })
  }
}

export default withFormik(formikConfig)(ProviderAssignmentWithFilterForm)
