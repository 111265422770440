import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { useTranslation } from 'react-i18next'
import Moment from 'moment'
import { List, fromJS } from 'immutable'
import { Link } from 'react-router-dom'
import { Paper } from '@material-ui/core'
import { jobSchema } from '@seekster/schemas'
import PropTypes from 'prop-types'

import { SelectedTable, Column } from 'components/tables/SelectTable'
import { DateTag, StateTag, PhoneTag } from 'components/tags'

export const JobsTable = ({ jobs, selectable, loading }) => {
  const { t } = useTranslation('organizations')

  const filterJobsByState = (state = 'pending_provider') => {
    return fromJS(jobs).map((job) => {
      if (job.get('state') === state) return job
      return job.merge({ disabled: true })
    })
  }

  return (
    <Paper>
      <SelectedTable
        collection={filterJobsByState()}
        schema={jobSchema}
        selectable
        loading={loading}
      >
        <Column
          name='number'
          render={(number, job) => <Link to={`/jobs/${job.get('id')}`}>{number}</Link>}
        />

        <Column
          name='customer'
          render={(customer, job) =>
            customer ? (
              <>
                <p>
                  <Link to={`/customers/${customer.get('id')}`}>
                    {customer.get('display_name')}
                  </Link>
                </p>
                <p>
                  <PhoneTag value={job.getIn(['location', 'phone_number'])} />
                </p>
              </>
            ) : (
              <p>ไม่พบข้อมูลลูกค้า</p>
            )
          }
        />

        <Column
          name='service'
          render={(service) => (
            <Link to={`/services/${service.get('id')}`}>{service.get('name')}</Link>
          )}
        />

        <Column
          name='orders'
          render={(orders) => (
            <ul>
              {orders.map((order) => (
                <li key={order.get('id')}>{order.get('description')}</li>
              ))}
            </ul>
          )}
        />

        <Column
          name='booking_date'
          render={(date, job) =>
            Moment(job.get('start_time')).format('ddd, D MMM YYYY - HH:mm')
          }
        />

        <Column name='location.short_address' />

        <Column
          name='job_providers'
          render={(jobProviders, job) => {
            return jobProviders
              .filter(
                (jobProvider) =>
                  jobProvider.get('state') !== 'cancelled' &&
                  jobProvider.get('state') !== 'rejected'
              )
              .map((jobProvider) => (
                <Link
                  to={`/providers/${jobProvider.getIn(['provider', 'id'])}`}
                  key={jobProvider.getIn(['provider', 'id'])}
                >
                  <div>{jobProvider.getIn(['provider', 'display_name'])}</div>
                  <div>{jobProvider.getIn(['provider', 'phone_number'])}</div>
                </Link>
              ))
          }}
        />
        <Column name='state' component={StateTag} />
        <Column
          name='org_id'
          render={(orgId) => {
            if (orgId !== null) {
              return (
                <Link to={`/organizations/${orgId}`} key={orgId}>
                  {t('org')}
                </Link>
              )
            }
          }}
        />
        <Column name='created_at' component={DateTag} />
      </SelectedTable>
    </Paper>
  )
}

JobsTable.propTypes = {
  jobs: ImmutablePropTypes.list,
  selectable: PropTypes.bool,
  loading: PropTypes.bool
}

JobsTable.defaultProps = {
  jobs: List(),
  selectable: false,
  loading: false
}

export default JobsTable
