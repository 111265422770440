import { Map } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import { permissionToReceiveNotification } from 'utils/firebase'

import SignInForm from './SignInForm'

const formikConfig = {
  mapPropsToValues: ({ agent = Map() }) => ({
    email: agent.get('email') || '',
    password: agent.get('password') || ''
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values)
      .then(() => {
        props.replace('/')

        return permissionToReceiveNotification()
      })
      .then(fcmToken => {
        if (window.localStorage.getItem('FCM_token') !== fcmToken) {
          props.updateCurrentDevice({ registration_token: fcmToken })
        }
      })
      .catch(error => {
        if (error.response) {
          alert(error.response.body.message)
        }
        else if (error.message) {
          alert(error.message)
        }

        setSubmitting(false)
      })
  }
}

export default withFormik(formikConfig)(SignInForm)
