import React from 'react'
import PropTypes from 'prop-types'
import MaterialRating from '@material-ui/lab/Rating'
import StarBorderIcon from '@material-ui/icons/StarBorder'

import './Rating.scss'

export class Rating extends React.Component {
  static propTypes = {
    value: PropTypes.number
  }

  render () {
    const { value, ...rest } = this.props

    return (
      <span className='user-rating'>
        <MaterialRating
          defaultValue={value}
          size='small'
          readOnly
          precision={0.5}
          className='rating'
          emptyIcon={<StarBorderIcon fontSize='inherit' />}
          {...rest}
        />
        ({value || 'no rating'})
      </span>
    )
  }
}

export default Rating
