import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import Panel from 'components/containers/Panel'
import { AttributesTable, Attribute } from 'components/tables/NewAttributesTable'

export class BadgeOverview extends React.Component {
  static propTypes = {
    badge: ImmutablePropTypes.map
  }

  static defaultProps = {
    badge: Map()
  }

  render () {
    const { badge } = this.props

    return (
      <Panel title='Badge'>
        <AttributesTable resource={badge}>
          <Attribute name='id' />
          <Attribute name='icon_url' render={iconUrl => <img src={iconUrl} alt='Badge Icon' />} />
          <Attribute name='name' />
        </AttributesTable>
      </Panel>
    )
  }
}

export default BadgeOverview
