import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { Link } from 'react-router-dom'
import { Grid, Card, CardActionArea, CardHeader, Avatar } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import useData from 'hooks/useData'

import CircularLoader from 'components/CircularLoader'

import styles from './IntegrationIndexPageStyles'

export function IntegrationIndexPage({ integrations, fetchIntegrations, classes }) {
  const { loading } = useData(() => fetchIntegrations())

  const allIntegrations = integrations.concat([
    new Map(
      Object.entries({
        id: 'seektown',
        icon_url:
          'https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/seektown_logo.png',
        name: 'Seektown'
      })
    ),

    new Map(
      Object.entries({
        id: 'cocoro',
        icon_url:
          'https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/seektown_logo.png',
        name: 'Cocoro'
      })
    ),

    new Map(
      Object.entries({
        id: 'scb_qr_code',
        icon_url:
          'https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/qr_icon_payment_method.png',
        name: 'SCB QR Code'
      })
    )
  ])

  return loading ? (
    <CircularLoader />
  ) : (
    <Grid container spacing={3}>
      {allIntegrations.map((integration) => (
        <Grid key={integration.get('id')} item xs={12} sm={4}>
          <Card>
            <CardActionArea
              component={Link}
              to={`/settings/integrations/${integration.get('id')}`}
              className={classes.integrationCard}
            >
              <CardHeader
                avatar={
                  <Avatar
                    src={integration.get('icon_url')}
                    alt={`${integration.get('name')} icon`}
                    className={classes.icon}
                  />
                }
                title={integration.get('name')}
                titleTypographyProps={{ variant: 'h5' }}
              />
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

IntegrationIndexPage.propTypes = {
  integrations: ImmutablePropTypes.list,
  fetchIntegrations: PropTypes.func,
  classes: PropTypes.object
}

IntegrationIndexPage.defaultProps = {
  integrations: List()
}

export default withStyles(styles)(IntegrationIndexPage)
