import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router/immutable'

import modal from './modal'
import cancellation from './cancellation'

export const makeRootReducer = (history, asyncReducers) => {
  return combineReducers({
    router: connectRouter(history),
    interface: require('reducers/interface').default,
    entities: require('reducers/entities').default,
    authentication: require('reducers/authentication').default,
    collections: require('reducers/collections').default,
    analytics: require('reducers/analytics').default,
    configurations: require('reducers/configurations').default,
    modal,
    cancellation,
    ...asyncReducers
  })
}

export default makeRootReducer
