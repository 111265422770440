import React from 'react'

import PageLayout from 'components/layouts/PageLayout'

import './NotFoundPage.scss'

const logoLink = 'https://s3-ap-southeast-1.amazonaws.com/seekster-web/assets/images/seekster_logo_107.png'

export class NotFoundPage extends React.Component {
  render () {
    return (
      <PageLayout noPageHeader>
        <div className='page-container' style={{ overflow: 'hidden' }}>
          <div className='not-found'>
            <img src={logoLink} alt='' />

            <h1>404</h1>

            <h3>Page not found</h3>
            <h4>ไม่พบหน้าที่คุณต้องการ</h4>
          </div>
        </div>
      </PageLayout>
    )
  }
}

export default NotFoundPage
