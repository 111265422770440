import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-xhr-backend'
import moment from 'moment'

i18next
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    fallbackNS: 'common',
    whitelist: ['en', 'th'],

    // debug: true,

    react: {
      wait: true,
      nsMode: 'fallback',
      useSuspense: false
    },

    interpolation: {
      format: (value, format) => {
        if (format === 'number') {
          return value.toLocaleString()
        } else {
          return value
        }
      }
    }
  })
  .then(() => moment.locale(i18next.language))

export default i18next
