import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import { MoreVert, PersonAddDisabledRounded, HighlightOffRounded } from '@material-ui/icons'
import { compose } from 'redux'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import { ResponsiveMenu, ResponsiveMenuItem } from 'components/menus/ResponsiveMenu'

import styles from './JobProviderActionsMenuStyles'

export function JobProviderActionsMenu({
  jobProviderId, jobId, triggerJobProvider, fetchJob, disabled, classes, t
}) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  function handleOpenMenu(e) {
    setMenuOpen(true)

    setAnchorEl(e.currentTarget)
  }

  function handleCloseMenu() {
    setMenuOpen(false)

    setAnchorEl(null)
  }

  async function handleCancelProvider() {
    const job = await fetchJob(jobId)
    if (moment(job.body.start_time).isSameOrAfter(moment().valueOf())) {
      if (window.confirm(t('cancel_confirmation_prompt'))) {
        triggerJobProvider(jobProviderId, 'cancel')
          .then(() => fetchJob(jobId))
      }
    } else {
      alert(t('alert_cancel_provider'))
    }

    handleCloseMenu()
  }

  async function handleRejectProvider() {
    const job = await fetchJob(jobId)
    if (moment(job.body.start_time).isSameOrAfter(moment().valueOf())) {
      if (window.confirm(t('reject_confirmation_prompt'))) {
        triggerJobProvider(jobProviderId, 'reject')
        .then(() => fetchJob(jobId))
      }
    } else {
      alert(t('alert_reject_provider'))
    }

    handleCloseMenu()
  }

  return (
    <ResponsiveMenu
      open={menuOpen}
      onClose={handleCloseMenu}
      anchorEl={anchorEl}
      trigger={
        <IconButton
          onClick={handleOpenMenu}
          className={classes.iconButton}
          disabled={disabled}
        >
          <MoreVert fontSize='small' />
        </IconButton>
      }
    >
      <ResponsiveMenuItem
        avatar={<PersonAddDisabledRounded />}
        content={t('cancel')}
        onClick={handleCancelProvider}
      />

      <ResponsiveMenuItem
        avatar={<HighlightOffRounded />}
        content={t('reject')}
        onClick={handleRejectProvider}
        className={classes.reject}
      />
    </ResponsiveMenu>
  )
}

JobProviderActionsMenu.propTypes = {
  jobProviderId: PropTypes.number,
  jobId: PropTypes.number,
  triggerJobProvider: PropTypes.func,
  fetchJob: PropTypes.func,
  disabled: PropTypes.bool,
  classes: PropTypes.object,

  t: PropTypes.func
}

export default compose(
  withStyles(styles),
  withTranslation(['actions', 'jobs'])
)(JobProviderActionsMenu)
