import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Lightbox from 'react-images'
import { withStyles } from '@material-ui/core/styles'
import { ButtonBase, Avatar } from '@material-ui/core'

import styles from './LightboxImageStyles'

export class LightboxImage extends React.Component {
  static propTypes = {
    source: PropTypes.oneOfType([
      PropTypes.object,
      ImmutablePropTypes.list
    ]).isRequired,
    isMulti: PropTypes.bool,
    isAvatar: PropTypes.bool,
    classes: PropTypes.object,
    t: PropTypes.func
  }

  static defaultProps = {
    isMulti: false,
    isAvatar: false
  }

  constructor (props) {
    super(props)

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0
    }

    this.closeLightbox = this.closeLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)
    this.gotoImage = this.gotoImage.bind(this)
    this.handleClickImage = this.handleClickImage.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
  }

  openLightbox (i, e) {
    e.preventDefault()

    this.setState({
      currentImage: i,
      lightboxIsOpen: true
    })
  }

  closeLightbox () {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    })
  }

  gotoPrevious () {
    this.setState({ currentImage: this.state.currentImage - 1 })
  }

  gotoNext () {
    this.setState({ currentImage: this.state.currentImage + 1 })
  }

  gotoImage (i) {
    this.setState({ currentImage: i })
  }

  handleClickImage () {
    const { source, isMulti } = this.props

    if (isMulti && this.state.currentImage !== source.size - 1) {
      this.gotoNext()
    }
  }

  renderGallery () {
    const { source, isMulti, classes = {}, isAvatar } = this.props

    if (isMulti) {
      return (
        source.map((image, i) => (
          <ButtonBase
            href={image.src}
            key={i}
            onClick={e => this.openLightbox(i, e)}
            className={classes.thumbnailContainer}
          >
            <img src={image.thumbnail} alt='image_thumbnail' className={classes.thumbnail} />
          </ButtonBase>
        ))
      )
    }
    else if (isAvatar) {
      return (
        <ButtonBase href={source.src} onClick={e => this.openLightbox(0, e)}>
          <Avatar
            src={source.thumbnail}
            alt='image_thumbnail'
            className={classes.avatar}
          />
        </ButtonBase>
      )
    }
    else {
      return (
        <ButtonBase href={source.src} onClick={e => this.openLightbox(0, e)}>
          <img src={source.thumbnail} alt='image_thumbnail' className={classes.thumbnail} />
        </ButtonBase>
      )
    }
  }

  render () {
    const { source, isMulti, classes = {}, ...rest } = this.props

    return (
      <div className={classes.gallery}>
        {this.renderGallery()}

        <Lightbox
          currentImage={this.state.currentImage}
          images={isMulti ? source.toJS() : [source]}
          isOpen={this.state.lightboxIsOpen}
          onClickImage={this.handleClickImage}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoImage}
          onClose={this.closeLightbox}
          showImageCount={isMulti}
          showThumbnails={isMulti}
          backdropClosesModal
          {...rest}
        />
      </div>
    )
  }
}

export default withStyles(styles)(LightboxImage)
