import React from 'react'
import { Link } from 'react-router-dom'
import { Table, Column } from 'components/tables/Table'
import { Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { DateTag, StateTag } from 'components/tags'

export function PaymentsTable ({ payments }) {
  const { t } = useTranslation('payments')

  return (
    <Paper>
      <Table collection={payments}>
        <Column
          name='id'
          to={payment => `/payments/${payment.get('id')}`}
        />
        <Column
          columnName={t('amount')}
          name='amount.full_display'
        />
        <Column
          name='invoice'
          render={(invoice, payment) => (
            <Link to={`/invoices/${payment.getIn(['invoice', 'id'])}`}>
              {payment.getIn(['invoice', 'number'])}
            </Link>
          )}
        />
        <Column
          name='payment_method_id'
          render={(amount, payment) => <p>{payment.getIn(['payment_method', 'name'])}</p>}
        />
        <Column name='state' component={StateTag} />
        <Column name='created_at' component={DateTag} />
      </Table>
    </Paper>
  )
}

export default PaymentsTable
