import React from 'react'
import { Route, Switch } from 'react-router-dom'

import AppLayout from 'components/layouts/AppLayout'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

// UNIQUE ROUTES

import AcceptInvitationPage from 'pages/accept_invitation'
import DashboardPage from 'pages/dashboard'
import NotFoundPage from 'pages/not_found'
import PreferencesPage from 'pages/preferences'
import SettingsPage from 'pages/settings'
import SignInPage from 'pages/sign_in'
import TestPage from 'pages/test'

// RESOURCEFUL ROUTES

import AddressIndexPage from 'pages/addresses'
import AddressEditPage from 'pages/addresses/[id]/edit'

import AgentIndexPage from 'pages/agents'
import AgentNewPage from 'pages/agents/new'
import AgentShowPage from 'pages/agents/[id]'
import AgentEditPage from 'pages/agents/[id]/edit'

import AnnouncementIndexPage from 'pages/announcements'
import AnnouncementNewPage from 'pages/announcements/new'
import AnnouncementShowPage from 'pages/announcements/[id]'
import AnnouncementEditPage from 'pages/announcements/[id]/edit'

import AppIndexPage from 'pages/apps'
import AppNewPage from 'pages/apps/new'
import AppShowPage from 'pages/apps/[id]'
import AppEditPage from 'pages/apps/[id]/edit'

import BadgeIndexPage from 'pages/badges'
import BadgeNewPage from 'pages/badges/new'
import BadgeShowPage from 'pages/badges/[id]'
import BadgeEditPage from 'pages/badges/[id]/edit'

import CategoryIndexPage from 'pages/categories'
import CategoryNewPage from 'pages/categories/new'
import CategoryShowPage from 'pages/categories/[id]'
import CategoryEditPage from 'pages/categories/[id]/edit'

import ClientIndexPage from 'pages/clients'
import ClientNewPage from 'pages/clients/new'
import ClientShowPage from 'pages/clients/[id]'
import ClientEditPage from 'pages/clients/[id]/edit'

import CompanyIndexPage from 'pages/companies'
import CompanyNewPage from 'pages/companies/new'
import CompanyShowPage from 'pages/companies/[id]'
import CompanyEditPage from 'pages/companies/[id]/edit'
import CompanyNewAddressPage from 'pages/companies/[id]/addresses/new'

import CustomerIndexPage from 'pages/customers'
import CustomerNewPage from 'pages/customers/new'
import CustomerShowPage from 'pages/customers/[id]'
import CustomerEditPage from 'pages/customers/[id]/edit'
import CustomerAccountMergesPage from 'pages/customers/[id]/account_merges'
import CustomerNewAddressPage from 'pages/customers/[id]/addresses/new'
import GenerateCustomerPage from 'pages/customers/generate'

import DepartmentIndexPage from 'pages/departments'
import DepartmentNewPage from 'pages/departments/new'
import DepartmentShowPage from 'pages/departments/[id]'
import DepartmentEditPage from 'pages/departments/[id]/edit'

import FeedbackIndexPage from 'pages/feedbacks'
import FeedbackNewPage from 'pages/feedbacks/new'
import FeedbackShowPage from 'pages/feedbacks/[id]'
import FeedbackEditPage from 'pages/feedbacks/[id]/edit'

import InquiryIndexPage from 'pages/inquiries'
import InquiryNewPage from 'pages/inquiries/new'
import InquiryShowPage from 'pages/inquiries/[id]'
import InquiryEditPage from 'pages/inquiries/[id]/edit'
import InquiryDuplicatePage from 'pages/inquiries/[id]/duplicate'
import GenerateInquiryPage from 'pages/inquiries/generate'
import UploadInquiriesPage from 'pages/inquiries/upload'

import InvoiceIndexPage from 'pages/invoices'
import InvoiceNewPage from 'pages/invoices/new'
import InvoiceShowPage from 'pages/invoices/[id]'
import InvoiceEditPage from 'pages/invoices/[id]/edit'

import JobIndexPage from 'pages/jobs'
import JobShowPage from 'pages/jobs/[id]'
import JobEditPage from 'pages/jobs/[id]/edit'
import JobReschedulePage from 'pages/jobs/[id]/reschedule'

import OrganizationIndexPage from 'pages/organization'
import OrganizationNewPage from 'pages/organization/new'
import OrganizationShowPage from 'pages/organization/[id]'
import OrganizationEditPage from 'pages/organization/[id]/edit'

import ReportIndexPage from 'pages/reports'
import ReportShowPage from 'pages/reports/[id]'
// import OrganizationNewPage from 'pages/organization/new'
// import OrganizationEditPage from 'pages/organization/[id]/edit'

import PromoCodeIndexPage from 'pages/promo_codes'
import PromoCodeNewPage from 'pages/promo_codes/new'
import PromoCodeShowPage from 'pages/promo_codes/[id]'
import PromoCodeEditPage from 'pages/promo_codes/[id]/edit'

import ProviderIssueIndexPage from 'pages/provider_issues'
import ProviderIssueNewPage from 'pages/provider_issues/new'
import ProviderIssueShowPage from 'pages/provider_issues/[id]'
import ProviderIssueEditPage from 'pages/provider_issues/[id]/edit'

import ProviderStrengthIndexPage from 'pages/provider_strengths'
import ProviderStrengthNewPage from 'pages/provider_strengths/new'
import ProviderStrengthShowPage from 'pages/provider_strengths/[id]'
import ProviderStrengthEditPage from 'pages/provider_strengths/[id]/edit'

import ProviderIndexPage from 'pages/providers'
import ProviderNewPage from 'pages/providers/new'
import ProviderShowPage from 'pages/providers/[id]'
import ProviderEditPage from 'pages/providers/[id]/edit'
import ProviderAccountMergesPage from 'pages/providers/[id]/account_merges'
import GenerateProviderPage from 'pages/providers/generate'

import RegionIndexPage from 'pages/regions'
import RegionNewPage from 'pages/regions/new'
import RegionShowPage from 'pages/regions/[id]'
import RegionEditPage from 'pages/regions/[id]/edit'

import ReviewIndexPage from 'pages/reviews'
import ReviewShowPage from 'pages/reviews/[id]'
import ReviewEditPage from 'pages/reviews/[id]/edit'

import ServiceIndexPage from 'pages/services'
import ServiceNewPage from 'pages/services/new'
import ServiceShowPage from 'pages/services/[id]'
import ServiceEditPage from 'pages/services/[id]/edit'
import ServiceDuplicatePage from 'pages/services/[id]/duplicate'

import SLAPendingProviderPage from 'pages/sla_pending_start'
import SLAPendingJobDetailPage from 'pages/sla_pending_start/[id]'

import SLAStartedPage from 'pages/sla_started'

import SubscriptionIndexPage from 'pages/subscriptions'
import SubscriptionNewPage from 'pages/subscriptions/new'
import SubscriptionShowPage from 'pages/subscriptions/[id]'
import SubscriptionDuplicatePage from 'pages/subscriptions/[id]/duplicate'

import TicketIndexPage from 'pages/tickets'

import TicketSettingsPage from 'pages/ticketSettings'

import NotificationIndexPage from 'pages/notifications'

import PaymentsIndexPage from 'pages/payments'
import PaymentShowPage from 'pages/payments/[id]'

import PayoutsIndexPage from 'pages/payouts'
import PayoutShowPage from 'pages/payouts/[id]'

import PayrollsIndexPage from 'pages/payrolls'
import PayrollsNewPage from 'pages/payrolls/new'
import PayrollsShowPage from 'pages/payrolls/[id]'

import WebhookNewPage from 'pages/webhooks/new'
import WebhookShowPage from 'pages/webhooks/[id]'
import WebhookEditPage from 'pages/webhooks/[id]/edit'
import Creditnotes from 'pages/creditnotes'
import CreateCreditnotes from 'pages/creditnotes/create'
import CreditnotesId from 'pages/creditnotes/[id]'
import SMSRatingIndexPage from 'pages/sms_rating'
import JobCancellationPage from 'pages/jobs/[id]/cancel'

export function Router({ isSupprtPlatform }) {
  return (
    <Switch>
      <Route path='/sign_in' component={SignInPage} />
      <Route path='/accept_invitation' component={AcceptInvitationPage} />

      <AppLayout>
        <Switch>
          <AuthenticatedRoute path='/' component={DashboardPage} exact />

          <AuthenticatedRoute
            exact
            path={['/inquiries', '/requests']}
            component={InquiryIndexPage}
          />
          <AuthenticatedRoute
            path={['/inquiries/new', '/requests/new']}
            component={InquiryNewPage}
          />
          <AuthenticatedRoute
            path={['/inquiries/generate', '/requests/generate']}
            component={GenerateInquiryPage}
          />
          <AuthenticatedRoute
            path={['/inquiries/upload', '/requests/upload']}
            component={UploadInquiriesPage}
          />
          <AuthenticatedRoute
            path={['/inquiries/:id/edit', '/requests/:id/edit']}
            component={InquiryEditPage}
          />
          <AuthenticatedRoute
            path={['/inquiries/:id/duplicate', '/requests/:id/duplicate']}
            component={InquiryDuplicatePage}
          />
          <AuthenticatedRoute
            path={['/inquiries/:id', '/requests/:id']}
            component={InquiryShowPage}
          />

          <AuthenticatedRoute path='/jobs' component={JobIndexPage} exact />
          <AuthenticatedRoute path='/jobs/:id/edit' component={JobEditPage} />
          <AuthenticatedRoute path='/jobs/:id/cancel' component={JobCancellationPage} />
          <AuthenticatedRoute path='/jobs/:id/reschedule' component={JobReschedulePage} />
          <AuthenticatedRoute path='/jobs/:id' component={JobShowPage} />

          <AuthenticatedRoute
            path='/sla_pending_start'
            component={SLAPendingProviderPage}
            exact
          />
          <AuthenticatedRoute
            path='/sla_pending_start/:id'
            component={SLAPendingJobDetailPage}
            exact
          />
          <AuthenticatedRoute path='/sla_started' component={SLAStartedPage} exact />

          <AuthenticatedRoute path='/sms_ratings' component={SMSRatingIndexPage} exact />

          <AuthenticatedRoute path='/reviews' component={ReviewIndexPage} exact />
          <AuthenticatedRoute path='/reviews/:id/edit' component={ReviewEditPage} />
          <AuthenticatedRoute path='/reviews/:id' component={ReviewShowPage} />

          <AuthenticatedRoute
            path='/subscriptions'
            component={SubscriptionIndexPage}
            exact
          />
          <AuthenticatedRoute path='/subscriptions/new' component={SubscriptionNewPage} />
          <AuthenticatedRoute
            path='/subscriptions/:id/duplicate'
            component={SubscriptionDuplicatePage}
          />
          <AuthenticatedRoute
            path='/subscriptions/:id'
            component={SubscriptionShowPage}
          />

          <AuthenticatedRoute path='/feedbacks' component={FeedbackIndexPage} exact />
          <AuthenticatedRoute path='/feedbacks/new' component={FeedbackNewPage} />
          <AuthenticatedRoute path='/feedbacks/:id/edit' component={FeedbackEditPage} />
          <AuthenticatedRoute path='/feedbacks/:id' component={FeedbackShowPage} />

          <AuthenticatedRoute path='/customers' component={CustomerIndexPage} exact />
          <AuthenticatedRoute
            path='/customers/generate'
            component={GenerateCustomerPage}
          />
          <AuthenticatedRoute path='/customers/new' component={CustomerNewPage} />
          <AuthenticatedRoute path='/customers/:id/edit' component={CustomerEditPage} />
          <AuthenticatedRoute
            path='/customers/:id/account_merges'
            component={CustomerAccountMergesPage}
          />
          <AuthenticatedRoute
            path='/customers/:id/addresses/new'
            component={CustomerNewAddressPage}
          />
          <AuthenticatedRoute path='/customers/:id' component={CustomerShowPage} />

          <AuthenticatedRoute path='/companies' component={CompanyIndexPage} exact />
          <AuthenticatedRoute path='/companies/new' component={CompanyNewPage} />
          <AuthenticatedRoute path='/companies/:id/edit' component={CompanyEditPage} />
          <AuthenticatedRoute
            path='/companies/:id/addresses/new'
            component={CompanyNewAddressPage}
          />
          <AuthenticatedRoute path='/companies/:id' component={CompanyShowPage} />

          <AuthenticatedRoute path='/providers' component={ProviderIndexPage} exact />
          <AuthenticatedRoute
            path='/providers/generate'
            component={GenerateProviderPage}
          />
          <AuthenticatedRoute path='/providers/new' component={ProviderNewPage} />
          <AuthenticatedRoute path='/providers/:id/edit' component={ProviderEditPage} />
          <AuthenticatedRoute
            path='/providers/:id/account_merges'
            component={ProviderAccountMergesPage}
          />
          <AuthenticatedRoute path='/providers/:id' component={ProviderShowPage} />

          <AuthenticatedRoute path='/badges' component={BadgeIndexPage} exact />
          <AuthenticatedRoute path='/badges/new' component={BadgeNewPage} />
          <AuthenticatedRoute path='/badges/:id/edit' component={BadgeEditPage} />
          <AuthenticatedRoute path='/badges/:id' component={BadgeShowPage} />

          <AuthenticatedRoute
            path='/provider_issues'
            component={ProviderIssueIndexPage}
            exact
          />
          <AuthenticatedRoute
            path='/provider_issues/new'
            component={ProviderIssueNewPage}
          />
          <AuthenticatedRoute
            path='/provider_issues/:id/edit'
            component={ProviderIssueEditPage}
          />
          <AuthenticatedRoute
            path='/provider_issues/:id'
            component={ProviderIssueShowPage}
          />

          <AuthenticatedRoute
            path='/provider_strengths'
            component={ProviderStrengthIndexPage}
            exact
          />
          <AuthenticatedRoute
            path='/provider_strengths/new'
            component={ProviderStrengthNewPage}
          />
          <AuthenticatedRoute
            path='/provider_strengths/:id/edit'
            component={ProviderStrengthEditPage}
          />
          <AuthenticatedRoute
            path='/provider_strengths/:id'
            component={ProviderStrengthShowPage}
          />

          <AuthenticatedRoute path='/regions' component={RegionIndexPage} exact />
          <AuthenticatedRoute path='/regions/new' component={RegionNewPage} />
          <AuthenticatedRoute path='/regions/:id/edit' component={RegionEditPage} />
          <AuthenticatedRoute path='/regions/:id' component={RegionShowPage} />

          <AuthenticatedRoute path='/categories' component={CategoryIndexPage} exact />
          <AuthenticatedRoute path='/categories/new' component={CategoryNewPage} />
          <AuthenticatedRoute path='/categories/:id/edit' component={CategoryEditPage} />
          <AuthenticatedRoute path='/categories/:id' component={CategoryShowPage} />

          <AuthenticatedRoute path='/services' component={ServiceIndexPage} exact />
          <AuthenticatedRoute path='/services/new' component={ServiceNewPage} />
          <AuthenticatedRoute path='/services/:id/edit' component={ServiceEditPage} />
          <AuthenticatedRoute
            path='/services/:id/duplicate'
            component={ServiceDuplicatePage}
          />
          <AuthenticatedRoute path='/services/:id' component={ServiceShowPage} />

          <AuthenticatedRoute path='/promo_codes' component={PromoCodeIndexPage} exact />
          <AuthenticatedRoute path='/promo_codes/new' component={PromoCodeNewPage} />
          <AuthenticatedRoute
            path='/promo_codes/:id/edit'
            component={PromoCodeEditPage}
          />
          <AuthenticatedRoute path='/promo_codes/:id' component={PromoCodeShowPage} />

          <AuthenticatedRoute
            path='/announcements'
            component={AnnouncementIndexPage}
            exact
          />
          <AuthenticatedRoute path='/announcements/new' component={AnnouncementNewPage} />
          <AuthenticatedRoute
            path='/announcements/:id/edit'
            component={AnnouncementEditPage}
          />
          <AuthenticatedRoute
            path='/announcements/:id'
            component={AnnouncementShowPage}
          />

          <AuthenticatedRoute path='/invoices' component={InvoiceIndexPage} exact />
          <AuthenticatedRoute path='/invoices/new' component={InvoiceNewPage} />
          <AuthenticatedRoute path='/invoices/:id/edit' component={InvoiceEditPage} />
          <AuthenticatedRoute path='/invoices/:id' component={InvoiceShowPage} />

          <AuthenticatedRoute path='/apps' component={AppIndexPage} exact />
          <AuthenticatedRoute path='/apps/new' component={AppNewPage} />
          <AuthenticatedRoute path='/apps/:id/edit' component={AppEditPage} />
          <AuthenticatedRoute path='/apps/:id' component={AppShowPage} />

          <AuthenticatedRoute path='/clients' component={ClientIndexPage} exact />
          <AuthenticatedRoute path='/clients/new' component={ClientNewPage} />
          <AuthenticatedRoute path='/clients/:id/edit' component={ClientEditPage} />
          <AuthenticatedRoute path='/clients/:id' component={ClientShowPage} />

          <AuthenticatedRoute path='/departments' component={DepartmentIndexPage} exact />
          <AuthenticatedRoute path='/departments/new' component={DepartmentNewPage} />
          <AuthenticatedRoute
            path='/departments/:id/edit'
            component={DepartmentEditPage}
          />
          <AuthenticatedRoute path='/departments/:id' component={DepartmentShowPage} />

          <AuthenticatedRoute path='/settings' component={SettingsPage} />

          <AuthenticatedRoute path='/preferences' component={PreferencesPage} />

          <AuthenticatedRoute path='/agents' component={AgentIndexPage} exact />
          <AuthenticatedRoute path='/agents/new' component={AgentNewPage} />
          <AuthenticatedRoute
            path={['/agents/:id/edit', '/profile/edit']}
            component={AgentEditPage}
          />
          <AuthenticatedRoute
            path={['/agents/:id', '/profile']}
            component={AgentShowPage}
          />

          <AuthenticatedRoute
            path='/notifications'
            component={NotificationIndexPage}
            exact
          />

          <AuthenticatedRoute path='/addresses' component={AddressIndexPage} exact />
          <AuthenticatedRoute
            path='/addresses/:id/edit'
            component={AddressEditPage}
            exact
          />

          <AuthenticatedRoute path='/test' component={TestPage} exact />

          <AuthenticatedRoute
            path='/organizations'
            component={OrganizationIndexPage}
            exact
          />
          <AuthenticatedRoute path='/organizations/new' component={OrganizationNewPage} />
          <AuthenticatedRoute
            path='/organizations/:id/edit'
            component={OrganizationEditPage}
          />
          <AuthenticatedRoute
            path='/organizations/:id'
            component={OrganizationShowPage}
          />

          <AuthenticatedRoute path='/reports' component={ReportIndexPage} exact />
          <AuthenticatedRoute path='/reports/:id' component={ReportShowPage} />

          <AuthenticatedRoute path='/payments' component={PaymentsIndexPage} exact />
          <AuthenticatedRoute path='/payments/:id' component={PaymentShowPage} />

          <AuthenticatedRoute path='/creditnotes' component={Creditnotes} exact />
          <AuthenticatedRoute path='/creditnotes/create' component={CreateCreditnotes} />
          <AuthenticatedRoute path='/creditnotes/:id' component={CreditnotesId} />

          <AuthenticatedRoute path='/payouts' component={PayoutsIndexPage} exact />
          <AuthenticatedRoute path='/payouts/:id' component={PayoutShowPage} />

          <AuthenticatedRoute path='/payrolls/new' component={PayrollsNewPage} />
          <AuthenticatedRoute path='/payrolls/:id(\d+)' component={PayrollsShowPage} />
          <AuthenticatedRoute path='/payrolls' component={PayrollsIndexPage} />

          <AuthenticatedRoute path='/webhooks/new' component={WebhookNewPage} />
          <AuthenticatedRoute path='/webhooks/:id' component={WebhookShowPage} exact />
          <AuthenticatedRoute path='/webhooks/:id/edit' component={WebhookEditPage} />

          {isSupprtPlatform && (
            <>
              <AuthenticatedRoute path='/tickets' component={TicketIndexPage} />
              <AuthenticatedRoute
                path='/ticket/settings'
                component={TicketSettingsPage}
              />
            </>
          )}

          <AuthenticatedRoute component={NotFoundPage} />
        </Switch>
      </AppLayout>
    </Switch>
  )
}

export default Router
