import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Card, CardContent, Collapse, Grid, Typography } from '@material-ui/core'
import { DragIndicator, ExpandMore, LibraryAddRounded } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import { useCollection } from 'hooks/collections'
import useQuery from 'hooks/useQuery'

import PageLayout from 'components/layouts/PageLayout'
import CircularLoader from 'components/CircularLoader'
import Search from 'components/Search'
import FilterModal from 'components/FilterModal'
import FilterTags from 'components/tags/FilterTags'
import CategoriesFilterForm from 'forms/filters/CategoriesFilterForm'
import ResultsCountLabel from 'components/labels/ResultsCountLabel'
// import CategoriesTable from 'components/tables/CategoriesTable'
// import Pagination from 'components/Pagination'
import { SortableHandle, SortableElement, SortableContainer, arrayMove } from 'react-sortable-hoc'

import { categorySchema } from '@seekster/schemas'
import './categories.scss'
// import { fromJS } from 'immutable'
import { callService } from 'utils/callService'
import { Link, useHistory } from 'react-router-dom'
import { BooleanTag } from 'components/tags'
import moment from 'moment'

const CategoriesContext = React.createContext()

const useStyles = makeStyles(({ spacing }) => ({
  searchContainer: {
    padding: spacing(2)
  },

  metaContainer: {
    padding: spacing(0, 2)
  }
}))

const DragHandle = SortableHandle(props => (
  <DragIndicator {...props} />
))

const SortableItem = SortableElement(({ value }) => {
  const history = useHistory()
  const { openCollapseItem, setOpenCollapseItem } = React.useContext(CategoriesContext)

  const toggleCollapse = (id) => {
    if (openCollapseItem.some(value => value === id)) {
      setOpenCollapseItem(openCollapseItem.filter(value => value !== id))
    } else {
      setOpenCollapseItem([...openCollapseItem, id])
    }
  }

  const isCollapse = () => {
    if (openCollapseItem.some(item => item === value.id)) {
      return true
    } else {
      return false
    }
  }

  return (
    <Card className='category-card-root '>
      <CardContent className='category-cardContent'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DragHandle className='drag-handle' />
        </div>
        <div className='category-title-container'>
          <Button fullWidth onClick={() => toggleCollapse(value.id)}>
            <Typography variant='h6'>{value.name}</Typography>
            <ExpandMore className={isCollapse() && `category-expanded`} />
          </Button>
        </div>
      </CardContent>
      <Collapse in={openCollapseItem.some(item => item === value.id)} unmountOnExit>
        <CardContent>
          <Grid container>
            <Grid item xs={3}>
              <div><b>พื้นที่</b></div>
              <Link to={`/regions/${value.id}`}>
                {value.region?.name ?? `-`}
              </Link>
            </Grid>
            <Grid item xs={1}>
              <div><b>Active</b></div>
              <div>
                <BooleanTag value={value.active} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div><b>บริการ</b></div>
              {
                value.services.length === 0 ? `-` :
                  <ul>
                    {value.services.map((service, index) =>
                      <li key={index}>
                        <Link to={`/services/${service.id}`}>{service.name}</Link>
                      </li>
                    )}
                  </ul>
              }
            </Grid>
            <Grid item xs={2}>
              <div><b>วันที่สร้าง</b></div>
              <span>
                {value.created_at ? moment(value.created_at).format('ddd, DD MMM YYYY - HH:mm') : `-`}
              </span>
            </Grid>
            <Grid item xs={2}>
              <span>
                <Button variant='contained' color='primary' onClick={() => history.push(`/categories/${value.id}`)}> แก้ไข</Button>
              </span>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  )
});

const SortableList = SortableContainer(({ items }) => {

  return (
    <ul style={{ padding: 0 }}>
      {items.map((value, index) => (
        <SortableItem key={`item-${value}`} index={index} value={value} />
      ))}
    </ul>
  );
});

export function CategoryIndexPage() {
  const classes = useStyles()
  const [query] = useQuery()
  const { t } = useTranslation('categories')
  const [categories, {
    loading, totalCount
  }] = useCollection(categorySchema, { page: query.page, query }, [query])
  const [categoryArray, setCategoryArray] = useState([])
  const [isShowSaveButton, setIsShowSaveButton] = useState(false)
  const [openCollapseItem, setOpenCollapseItem] = useState([])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setCategoryArray(arrayMove(categoryArray, oldIndex, newIndex))
    setIsShowSaveButton(true)
  };

  const saveCategorySorting = async () => {
    try {
      const categoriesWithNewPosition = categoryArray.map((item, index) => { return { id: item.id, position: index + 1 } })
      const response = await callService.post(
        `${process.env.REACT_APP_BASE_API}/categories/sort`,
        categoriesWithNewPosition)
      if (response.status === 200) {
        setIsShowSaveButton(prev => !prev)
      }
    } catch (error) {
      alert(error.response.data.message ?? 'Something wrong!')
    }
  }

  useEffect(() => {
    setCategoryArray([...categories.toJS()])
  }, [categories])

  function primaryActions() {
    return [{
      avatar: <LibraryAddRounded fontSize='small' />,
      content: t('new'),
      to: '/categories/new'
    }]
  }

  return (
    <PageLayout title={t('title')} primaryActions={primaryActions()}>
      <CategoriesContext.Provider value={{ openCollapseItem, setOpenCollapseItem }}>
        <div className={classes.searchContainer}>
          <Grid container spacing={2}>
            <Grid item xs={9} sm={10}>
              <Search />
            </Grid>
            <Grid item xs={3} sm={2}>
              <FilterModal component={CategoriesFilterForm} />
            </Grid>
          </Grid>
        </div>
        {
          loading ? (
            <Grid item xs={12}>
              <CircularLoader />
            </Grid>
          ) : (
            <>
              <div className={classes.metaContainer}>
                <Grid item xs={12}>
                  <FilterTags />
                  <ResultsCountLabel value={totalCount} unit='category' />
                  {
                    isShowSaveButton &&
                    <Button onClick={saveCategorySorting} variant='contained' color='primary'>Save</Button>
                  }
                  <SortableList items={categoryArray} onSortEnd={onSortEnd} useDragHandle collapse={{ openCollapseItem, setOpenCollapseItem }} />
                </Grid>
                {/* 
              ---------- old version categories -------------------------------
              <Grid item xs={12}>
                <FilterTags />
                <ResultsCountLabel value={totalCount} unit='category' />
                <CategoriesTable categories={categories} />
              </Grid> 
              */}
              </div>
              {/* <Grid item xs={12} container justify='center'>
              <Pagination totalPages={totalPages} />
            </Grid> */}
            </>
          )
        }
      </CategoriesContext.Provider>
    </PageLayout>
  )
}

export default CategoryIndexPage
