import axios from "axios";
import createStore from "createStore";

export const store = createStore()

const callService = axios.create();
const token = store.getState().getIn(['authentication', 'token'])

// Request interceptor for API calls
callService.interceptors.request.use(
  async config => {
    if (token) {
      config.headers = {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      }
      return config;
    } else {
      window.location.href('/sign_in')
    }
  },
  error => {
    Promise.reject(error)
  });

export { callService }
