export default () => ({
  title: {
    fontSize: '1.25rem'
  },

  topbarContainer: {
    padding: 4
  },
  searchContainer: {
    padding: 8
  }
})
