import { connect } from 'react-redux'

import { fetch, update, destroy } from 'actions/resources'
import {
  fetch as fetchCollections,
  fetchChildren as fetchCollectionChildren
} from 'actions/collections'
import { getResource } from 'selectors/resources'
import { trigger } from 'actions/resourceEvent'
import { bankAccountSchema } from '@seekster/schemas'

import BankAccountActionsMenu from './BankAccountActionsMenu'

const mapStateToProps = (state, { bankAccountId }) => ({
  bankAccount: getResource(state, bankAccountId, bankAccountSchema)
})

const mapDispatchToProps = {
  fetchBankAccount: (id, options) => fetch(id, bankAccountSchema, options),
  fetchBankAccounts: (options) => fetchCollections(bankAccountSchema, options),
  updateBankAccount: (id, data, options) => update(id, data, bankAccountSchema, options),
  deleteBankAccount: (id, options) => destroy(id, bankAccountSchema, options),
  fetchResourceBankAccounts: (schema, id) => fetchCollectionChildren(schema, id, bankAccountSchema),
  approveBankAccount: (id) => trigger(id, bankAccountSchema, 'approve')
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountActionsMenu)
